import React, { useState, useEffect } from 'react'
import { Header, Grid, Table, Loader, Modal, Segment, Button } from 'semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getData } from '../../service/DataService'
import { getDollarString, getPercentString } from '../Common/TableCellUtils'
import { TimeframeDropdownShrink } from '../Common/TimeframeDropdownShrink'

export const ShrinkOhEmptPkgDept = withRouter(({ timeframe, settimeframe, fpToggle, location }) => {

  const search = new URLSearchParams(location.search)
  const strNbr = search.has('strNbr') ? search.get('strNbr') : 0
  const deptNbrInt = search.has('deptNbr') ? search.get('deptNbr') : 0

  const [sortColumn, setSortColumn] = useState(timeframe + "_OH_EMPT_PKG")
  const [sortDirection, setSortDirection] = useState("descending")

  const [pageNum, setPageNum] = useState(1)
  let rowsPerPage = 10

  const [open, setOpen] = useState(false)
  const [modalData, setModalData] = useState([])

  const skusByStrDeptQuery = useQuery(['queryShrinkOhEmptPkgSkusByStrDept', { strNbr, deptNbrInt, fpToggle }], getData)


  useEffect(() => {
    setSortColumn(timeframe + '_OH_EMPT_PKG')
    setPageNum(1)
  }, [timeframe])

  if (skusByStrDeptQuery.isLoading) {
    return (<Loader active>Loading...</Loader>)
  }

  if (!skusByStrDeptQuery.data) {
    return (<Header textAlign='center'>Invalid Store or Dept Number</Header>)
  }

  const skusByStrDeptData = skusByStrDeptQuery.data

  let skuDataSorted = skusByStrDeptData.sort((left, right) => {
    let result = left[sortColumn] - right[sortColumn]
    if (sortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  let skuDataPagination = skuDataSorted.slice(rowsPerPage * (pageNum - 1), parseInt(Math.min(rowsPerPage * pageNum, skuDataSorted.length)))

  return (
    <>
      <Grid>
        <Grid.Column width={8} textAlign='left'>
          <Grid.Row><Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/shrink/ohemptpkg?strNbr=${strNbr}`} data-test='b2o-link'>Back to OH Empt Pkg</Link></Grid.Row>
        </Grid.Column>
        <Grid.Column textAlign='justified' width={8}>
          <TimeframeDropdownShrink timeframe={timeframe} settimeframe={settimeframe} />
        </Grid.Column>
      </Grid>
      <Header textAlign='center'>On Hand Adjustments & Empty Packages - Store {strNbr} </Header>
      <Header textAlign='center'> D{deptNbrInt} Top SKUs</Header>
      <Table textAlign='center' unstackable celled sortable size='small' style={{ fontSize: 12 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>SKU #</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>SKU Description</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>OHA + Empty Pkg $</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>OHA + Empty Pkg %</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Empty Pkg Retail Amt</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Net OHA Retail Amt</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {skuDataPagination.map((data) => {
            return (
              <Table.Row>
                <Table.Cell
                  style={{ color: '#EE7125', textDecoration: 'underline' }}
                  onClick={() => {
                    setOpen(true)
                    setModalData(data)
                  }}>
                  {data['SKU_NBR'].toString().length > 6 ? // Format SKUs, 10-digit (xxxx-xxx-xxx) versus 6-digit (xxx-xxx)
                    data['SKU_NBR'].toString().substring(0, 4) + '-' + data['SKU_NBR'].toString().substring(4, 7) + '-' + data['SKU_NBR'].toString().substring(7) :
                    data['SKU_NBR'].toString().substring(0, 3) + '-' + data['SKU_NBR'].toString().substring(3)}
                </Table.Cell>
                <Table.Cell>{data['SKU_DESC']}</Table.Cell>
                <Table.Cell>{getDollarString(data[timeframe + '_OH_EMPT_PKG'])}</Table.Cell>
                <Table.Cell>{data[timeframe + '_SLS_AMT'] !== 0 ? getPercentString(data[timeframe + '_OH_EMPT_PKG'] / data[timeframe + '_SLS_AMT'], 2) : '-'}</Table.Cell>
                <Table.Cell>{getDollarString(data[timeframe + '_EMP_PKG_RETL_AMT'])}</Table.Cell>
                <Table.Cell>{getDollarString(data[timeframe + '_NET_OH_RETL_AMT'])}</Table.Cell>

              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
      {open && <Modal
        closeIcon
        open={open}
        onClose={() => { setOpen(false) }}
      >
        <Segment color='orange'>
          <Grid>
            <Grid.Column width={16}>
              <Grid.Row>
                <Header textAlign='center'>
                  {modalData['SKU_NBR'].toString().length > 6 ? // Format SKUs, 10-digit (xxxx-xxx-xxx) versus 6-digit (xxx-xxx)
                    modalData['SKU_NBR'].toString().substring(0, 4) + '-' + modalData['SKU_NBR'].toString().substring(4, 7) + '-' + modalData['SKU_NBR'].toString().substring(7) :
                    modalData['SKU_NBR'].toString().substring(0, 3) + '-' + modalData['SKU_NBR'].toString().substring(3)}
                </Header>
              </Grid.Row>
              <Grid.Row>&nbsp;</Grid.Row>
              <Grid.Row><Header>Class</Header></Grid.Row>
              <Grid.Row><p textAlign='center'>{modalData['EXT_CLASS_NBR'] + '-' + modalData['CLASS_DESC']}</p></Grid.Row>
              <Grid.Row>&nbsp;</Grid.Row>
              <Grid.Row><Header>Subclass</Header></Grid.Row>
              <Grid.Row><p textAlign='center'>{modalData['EXT_SUB_CLASS_NBR'] + '-' + modalData['SUB_CLASS_DESC']}</p></Grid.Row>
              <Grid.Row>&nbsp;</Grid.Row>
              <Grid.Row><Header>SKU Description</Header></Grid.Row>
              <Grid.Row><p textAlign='center'>{modalData['SKU_DESC']}</p></Grid.Row>
            </Grid.Column>
            &nbsp;
            &nbsp;

            <Grid.Column>
              <Grid.Row>&nbsp;</Grid.Row>
              <Grid.Row>
                <Button
                  onClick={() => { setOpen(false) }}
                  style={{ color: 'white', backgroundColor: '#EE7125' }}
                  content='Close'
                />
              </Grid.Row>
              <Grid.Row>&nbsp;</Grid.Row>
            </Grid.Column>
          </Grid>
        </Segment>
      </Modal>}
    </>
  )
})