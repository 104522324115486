import React, { useState } from 'react'
import { Header, Grid, Table, Loader, Icon } from 'semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getData } from '../../service/DataService'
import { getDollarString, getPercentString } from '../Common/TableCellUtils'
import { TimeframeDropdownShrink } from '../Common/TimeframeDropdownShrink'

export const ShrinkRtvCredit = withRouter(({ timeframe, settimeframe, fpToggle, location }) => {

  const search = new URLSearchParams(location.search)
  const strNbr = search.has('strNbr') ? search.get('strNbr') : 0

  const [sortColumn, setSortColumn] = useState("EXT_CLASS_NBR")
  const [sortDirection, setSortDirection] = useState("ascending")

  const shrinkRtvCreditClsByStrQuery = useQuery(['queryShrinkRtvCreditClsByStr', { strNbr, fpToggle }], getData)


  if (shrinkRtvCreditClsByStrQuery.isLoading) {
    return (<Loader active>Loading...</Loader>)
  }

  if (!shrinkRtvCreditClsByStrQuery.data) {
    return (<Header textAlign='center'>Invalid Store Number</Header>)
  }

  const data = shrinkRtvCreditClsByStrQuery.data

  let dataFilterNulls = data.filter((row) => {
    return row && row['EXT_CLASS_NBR'] !== null
  })

  let dataSorted = dataFilterNulls.sort((left, right) => {
    let result = left[sortColumn] - right[sortColumn]
    if (sortColumn === 'EXT_CLASS_NBR') {
      result = left.EXT_CLASS_NBR > right.EXT_CLASS_NBR ? 1 : -1
    }
    if (sortColumn === 'CLASS_DESC') {
      result = left.CLASS_DESC > right.CLASS_DESC ? 1 : -1
    }
    if (sortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  return (
    <>
      <Grid>
        <Grid.Column width={8} textAlign='left'>
          <Grid.Row><Link style={{ color: '#EE7125' }} to={`/store/shrink?strNbr=${strNbr}`} data-test='b2o-link'>Back to Shrink Overview</Link></Grid.Row>
        </Grid.Column>
        <Grid.Column width={8} textAlign='left'>
          <TimeframeDropdownShrink timeframe={timeframe} settimeframe={settimeframe} />
        </Grid.Column>
      </Grid>

      <Header textAlign='center'>RTV Credits - Store {strNbr}</Header>
      <Table textAlign='center' unstackable celled sortable size='small' style={{ fontSize: 12 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={sortColumn === 'EXT_CLASS_NBR' ? sortDirection : null}
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumn !== 'EXT_CLASS_NBR') {
                  setSortColumn('EXT_CLASS_NBR')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>Class Number {sortColumn !== 'EXT_CLASS_NBR' && <Icon fitted name='sort' />}</Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'CLASS_DESC' ? sortDirection : null}
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumn !== 'CLASS_DESC') {
                  setSortColumn('CLASS_DESC')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>Class Description {sortColumn !== 'CLASS_DESC' && <Icon fitted name='sort' />}</Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === timeframe+'_RTV_CREDIT_AMT' ? sortDirection : null}
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumn !== timeframe+'_RTV_CREDIT_AMT') {
                  setSortColumn(timeframe+'_RTV_CREDIT_AMT')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>RTV Credits $ {sortColumn !== timeframe+'_RTV_CREDIT_AMT' && <Icon fitted name='sort' />}</Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === timeframe+'_RTV_CREDIT_AMT' ? sortDirection : null}
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumn !== timeframe+'_RTV_CREDIT_AMT') {
                  setSortColumn(timeframe+'_RTV_CREDIT_AMT')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>RTV Credits % {sortColumn !== timeframe+'_RTV_CREDIT_AMT' && <Icon fitted name='sort' />}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {dataSorted.map((data) => {
            return (
              <Table.Row>
                <Table.Cell>{data['EXT_CLASS_NBR']}</Table.Cell>
                <Table.Cell>{data['CLASS_DESC']}</Table.Cell>
                <Table.Cell>{getDollarString(data[timeframe + '_RTV_CREDIT_AMT'])}</Table.Cell>
                <Table.Cell>{getPercentString(data[timeframe + '_RTV_CREDIT_AMT'] / data[timeframe + '_SLS_AMT'], 2)}</Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </>
  )

})