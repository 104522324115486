import { useMemo } from "react";
import useQueryApi from "./useQueryApi";

const NIGHT_CREW_DEPT = '38';

export const useOsaSideKickSkuBayQuery = ({ level, locNbr, fpToggle }) => {
  const { data = [], isLoading, isError } = useQueryApi(`query/queryOSASidekickSkuBayDst/${level}`, { locNbr, fpToggle });
  const dataFiltered = useMemo(() => {
    if (isLoading) return [];

    return data.filter(({ DEPT }) => DEPT !== NIGHT_CREW_DEPT);
  }, [isLoading, data]);

  return { data: dataFiltered, isLoading, isError };
}
