import { Tab } from "semantic-ui-react";
import { useState } from "react";
import { DataView } from "../../../../../../components/Common/DataView";
import { MetricTopBottomDropdown } from "../../../../../../components/Common/MetricTopBottomDropdown";
import { FORMATMETRIC } from "../../../../../../constants/deptDrilldown";
import { getTopBtmRecords } from "../../../../../../utils/topBottomFormat";

export const CoreTopBtmClasses = ({ topBtmClasses }) => {
  const [metric, setMetric] = useState('SLS_AMT');
  const { bottomRecords, topRecords } = getTopBtmRecords({ rows: topBtmClasses, metric, link: '/core/sales/class', tab: 'topBtmClasses' })

  return (
    <Tab.Pane attached={false}>
      <MetricTopBottomDropdown metric={metric} setMetric={setMetric} />
      <DataView>
        <DataView.Title position="center">
          Top Classes By {FORMATMETRIC[metric]}
        </DataView.Title>
        <DataView.DataTable data={topRecords} />
      </DataView>
      <DataView className='mt-4'>
        <DataView.Title position="center">
          Bottom Classes By {FORMATMETRIC[metric]}
        </DataView.Title>
        <DataView.DataTable data={bottomRecords} />
      </DataView>
    </Tab.Pane>
  );
}
