import React, { useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import {
  Table,
  Header,
  Grid,
  Loader,
  Segment,
  Menu
} from 'semantic-ui-react'
import { TimeframeDropdown } from '../Common/TimeframeDropdown'
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon'

import { useQuery } from 'react-query'
import {
  getData
} from '../../service/DataService'

import { getDollarString, getPercentString, getCalloutStyle, getCompareStyle } from '../Common/TableCellUtils'

export const ServicesDtlStr = withRouter(({ timeframe, settimeframe, fpToggle, location }) => {

  const search = new URLSearchParams(location.search)
  const strNbr = search.has('strNbr') ? search.get('strNbr') : 0

  const [salesPipeline, setSalesPipeline] = useState('Sales')
  const [focus6AllPrograms, setFocus6AllPrograms] = useState('Focus 6')
  const [pipelineFilter, setPipelineFilter] = useState('TOTAL')

  const servicesDtlQuery = useQuery(['queryServicesDtlByStr', { strNbr, fpToggle }], getData)

  if (servicesDtlQuery.isLoading) {
    return <Loader active>Loading...</Loader>
  }

  if (!servicesDtlQuery.data[0]) {
    return (<Header textAlign='center'>Invalid or Unauthorized Store Number</Header>)
  }

  let servicesDtlData = servicesDtlQuery.data

  let allPrgmSorted = servicesDtlData.sort((left, right) => {
    return left.MER_DEPT_CD + left.SUMMARY_PROGRAM_NAME > right.MER_DEPT_CD + right.SUMMARY_PROGRAM_NAME ? 1 : -1
  })

  let focus6Filter = servicesDtlData.filter(row => {
    return row && row.TOP_6_CATEGORY <= 6
  })

  let focus6Sorted = focus6Filter.sort((left, right) => {
    return left.SUMMARY_PROGRAM_NAME > right.SUMMARY_PROGRAM_NAME ? 1 : -1
  })

  let focus6Reduced = focus6Filter.reduce((accumulator, item) => {
    Object.keys(item).forEach((key) => {
      accumulator[key] = (accumulator[key] || 0) + item[key]
    })
    return accumulator
  }, {})

  let allPrgmReduced = servicesDtlData.reduce((accumulator, item) => {
    Object.keys(item).forEach((key) => {
      accumulator[key] = (accumulator[key] || 0) + item[key]
    })
    return accumulator
  }, {})

  return (
    <>
      <Header textAlign='center'>Services - Store #{strNbr}</Header>
      <Grid>
      <Grid.Column width={8}>
          <Grid.Row>&nbsp;</Grid.Row>
          <Grid.Row>
            <Link style={{ color: '#EE7125' }} to={`/store/specialtyprorental?strNbr=${strNbr}`}>Back to Specialty/Pro/Rental</Link>
          </Grid.Row>
        </Grid.Column>
        <Grid.Column width={8} textAlign='right'>
          Timeframe:
          {new Date().getDay() === 1 ?
            <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} /> :
            <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />}
          &nbsp;
        </Grid.Column>
      </Grid>


      <Menu tabular widths={2} attached='top' size='small' color='orange'>
        <Menu.Item
          name='Focus 6'
          active={focus6AllPrograms === 'Focus 6'}
          content='Focus 6'
          onClick={() => {
            setFocus6AllPrograms('Focus 6')
          }}
        />
        <Menu.Item
          name='All Programs'
          active={focus6AllPrograms === 'All Programs'}
          content='All Programs'
          onClick={() => {
            setFocus6AllPrograms('All Programs')
          }}
        />
      </Menu>
      <Segment attached='bottom'>
      <Menu widths={2} size='small' color='orange'>
        <Menu.Item
          name='Sales'
          active={salesPipeline === 'Sales'}
          content='Sales'
          onClick={() => {
            setSalesPipeline('Sales')
          }}
        />
        <Menu.Item
          name='Pipeline'
          active={salesPipeline === 'Pipeline'}
          content='Pipeline'
          onClick={() => {
            setSalesPipeline('Pipeline')
          }}
        />
      </Menu>
      
        <Header textAlign='center'>{focus6AllPrograms}</Header>
        {salesPipeline === 'Pipeline' && <Grid>
          <Grid.Column width={8}></Grid.Column>
          <Grid.Column width={8} textAlign='right'>
            <div>
              <select
                name="pipeline filter"
                onChange={e => {
                  setPipelineFilter(e.target.value)
                }}
                value={pipelineFilter}
                style={{ padding: 10 }}
              >
                <option value="TOTAL" selected>All</option>
                <option value="STR">Store</option>
                <option value="WEB">Web</option>
                <option value="SP">Service Provider</option>
                <option value="OTHER">Other</option>
              </select>
            </div>
            &nbsp;
          </Grid.Column>
        </Grid>}
        <Table textAlign='center' celled unstackable size='small' compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Program</Table.HeaderCell>
              {salesPipeline === 'Sales' && <Table.HeaderCell>Actual</Table.HeaderCell>}
              {salesPipeline === 'Sales' && <Table.HeaderCell>vP $</Table.HeaderCell>}
              {salesPipeline === 'Pipeline' && <Table.HeaderCell>Lead</Table.HeaderCell>}
              {salesPipeline === 'Pipeline' && <Table.HeaderCell>Meas</Table.HeaderCell>}
              <Table.HeaderCell>Comp %</Table.HeaderCell>
              {salesPipeline === 'Pipeline' && <Table.HeaderCell>Conv %</Table.HeaderCell>}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {(focus6AllPrograms === 'Focus 6' ? focus6Sorted : allPrgmSorted).map(data => {
              return (
                <Table.Row>
                  <Table.Cell>{focus6AllPrograms === 'All Programs' && (data.MER_DEPT_CD + '-')}{data.SUMMARY_PROGRAM_NAME}</Table.Cell>
                  {salesPipeline === 'Sales' && <Table.Cell style={getCalloutStyle(data[timeframe + '_TY_SLS_AMT'])}>{getDollarString(data[timeframe + '_TY_SLS_AMT'])}</Table.Cell>}
                  {salesPipeline === 'Sales' && <Table.Cell style={getCompareStyle(data[timeframe + '_TY_SLS_AMT'] - data[timeframe + '_SLS_PLN_AMT'])}>{getDollarString(data[timeframe + '_TY_SLS_AMT'] - data[timeframe + '_SLS_PLN_AMT'])}</Table.Cell>}
                  {salesPipeline === 'Sales' && <Table.Cell style={getCompareStyle(data[timeframe + '_TY_COMP_SLS_AMT'] - data[timeframe + '_LY_COMP_SLS_AMT'])}>{getPercentString((data[timeframe + '_TY_COMP_SLS_AMT'] - data[timeframe + '_LY_COMP_SLS_AMT']) / data[timeframe + '_LY_COMP_SLS_AMT'])}</Table.Cell>}
                  {salesPipeline === 'Pipeline' && <Table.Cell>{data[timeframe + '_LEAD_COUNT_' + pipelineFilter] ? data[timeframe + '_LEAD_COUNT_' + pipelineFilter] : 0}</Table.Cell>}
                  {salesPipeline === 'Pipeline' && <Table.Cell>{data[timeframe + '_MEASURE_COUNT_' + pipelineFilter] ? data[timeframe + '_MEASURE_COUNT_' + pipelineFilter] : 0}</Table.Cell>}
                  {salesPipeline === 'Pipeline' && <Table.Cell style={getCompareStyle(data[timeframe + '_LEAD_COUNT_' + pipelineFilter] + data[timeframe + '_MEASURE_COUNT_' + pipelineFilter] - data[timeframe + '_LY_LEAD_COUNT_' + pipelineFilter] - data[timeframe + '_LY_MEASURE_COUNT_' + pipelineFilter])}>{getPercentString((data[timeframe + '_LEAD_COUNT_' + pipelineFilter] + data[timeframe + '_MEASURE_COUNT_' + pipelineFilter] - data[timeframe + '_LY_LEAD_COUNT_' + pipelineFilter] - data[timeframe + '_LY_MEASURE_COUNT_' + pipelineFilter]) / (data[timeframe + '_LY_LEAD_COUNT_' + pipelineFilter] + data[timeframe + '_LY_MEASURE_COUNT_' + pipelineFilter]))}</Table.Cell>}
                  {salesPipeline === 'Pipeline' && <Table.Cell>{getPercentString((data[timeframe + '_LEAD_CONV_NUM_' + pipelineFilter] + data[timeframe + '_MEASURE_CONV_NUM_' + pipelineFilter]) / (data[timeframe + '_LEAD_CONV_DEN_' + pipelineFilter] + data[timeframe + '_MEASURE_CONV_DEN_' + pipelineFilter]))}</Table.Cell>}
                </Table.Row>
              )
            })}
            {focus6AllPrograms === 'Focus 6' && <Table.Row style={{ fontWeight: 'bold' }}>
              <Table.Cell>Total Focus 6 Programs</Table.Cell>
              {salesPipeline === 'Sales' && <Table.Cell style={getCalloutStyle(focus6Reduced[timeframe + '_TY_SLS_AMT'])}>{getDollarString(focus6Reduced[timeframe + '_TY_SLS_AMT'])}</Table.Cell>}
              {salesPipeline === 'Sales' && <Table.Cell style={getCompareStyle(focus6Reduced[timeframe + '_TY_SLS_AMT'] - focus6Reduced[timeframe + '_SLS_PLN_AMT'])}>{getDollarString(focus6Reduced[timeframe + '_TY_SLS_AMT'] - focus6Reduced[timeframe + '_SLS_PLN_AMT'])}</Table.Cell>}
              {salesPipeline === 'Sales' && <Table.Cell style={getCompareStyle(focus6Reduced[timeframe + '_TY_COMP_SLS_AMT'] - focus6Reduced[timeframe + '_LY_COMP_SLS_AMT'])}>{getPercentString((focus6Reduced[timeframe + '_TY_COMP_SLS_AMT'] - focus6Reduced[timeframe + '_LY_COMP_SLS_AMT']) / focus6Reduced[timeframe + '_LY_COMP_SLS_AMT'])}</Table.Cell>}
              {salesPipeline === 'Pipeline' && <Table.Cell>{focus6Reduced[timeframe + '_LEAD_COUNT_' + pipelineFilter] ? focus6Reduced[timeframe + '_LEAD_COUNT_' + pipelineFilter] : 0}</Table.Cell>}
              {salesPipeline === 'Pipeline' && <Table.Cell>{focus6Reduced[timeframe + '_MEASURE_COUNT_' + pipelineFilter] ? focus6Reduced[timeframe + '_MEASURE_COUNT_' + pipelineFilter] : 0}</Table.Cell>}
              {salesPipeline === 'Pipeline' && <Table.Cell style={getCompareStyle(focus6Reduced[timeframe + '_LEAD_COUNT_' + pipelineFilter] + focus6Reduced[timeframe + '_MEASURE_COUNT_' + pipelineFilter] - focus6Reduced[timeframe + '_LY_LEAD_COUNT_' + pipelineFilter] - focus6Reduced[timeframe + '_LY_MEASURE_COUNT_' + pipelineFilter])}>{getPercentString((focus6Reduced[timeframe + '_LEAD_COUNT_' + pipelineFilter] + focus6Reduced[timeframe + '_MEASURE_COUNT_' + pipelineFilter] - focus6Reduced[timeframe + '_LY_LEAD_COUNT_' + pipelineFilter] - focus6Reduced[timeframe + '_LY_MEASURE_COUNT_' + pipelineFilter]) / (focus6Reduced[timeframe + '_LY_LEAD_COUNT_' + pipelineFilter] + focus6Reduced[timeframe + '_LY_MEASURE_COUNT_' + pipelineFilter]))}</Table.Cell>}
              {salesPipeline === 'Pipeline' && <Table.Cell>{getPercentString((focus6Reduced[timeframe + '_LEAD_CONV_NUM_' + pipelineFilter] + focus6Reduced[timeframe + '_MEASURE_CONV_NUM_' + pipelineFilter]) / (focus6Reduced[timeframe + '_LEAD_CONV_DEN_' + pipelineFilter] + focus6Reduced[timeframe + '_MEASURE_CONV_DEN_' + pipelineFilter]))}</Table.Cell>}
            </Table.Row>}
            {focus6AllPrograms === 'Focus 6' && <Table.Row style={{ fontWeight: 'bold' }}>
              <Table.Cell>Total Programs</Table.Cell>
              {salesPipeline === 'Sales' && <Table.Cell style={getCalloutStyle(allPrgmReduced[timeframe + '_TY_SLS_AMT'])}>{getDollarString(allPrgmReduced[timeframe + '_TY_SLS_AMT'])}</Table.Cell>}
              {salesPipeline === 'Sales' && <Table.Cell style={getCompareStyle(allPrgmReduced[timeframe + '_TY_SLS_AMT'] - allPrgmReduced[timeframe + '_SLS_PLN_AMT'])}>{getDollarString(allPrgmReduced[timeframe + '_TY_SLS_AMT'] - allPrgmReduced[timeframe + '_SLS_PLN_AMT'])}</Table.Cell>}
              {salesPipeline === 'Sales' && <Table.Cell style={getCompareStyle(allPrgmReduced[timeframe + '_TY_COMP_SLS_AMT'] - allPrgmReduced[timeframe + '_LY_COMP_SLS_AMT'])}>{getPercentString((allPrgmReduced[timeframe + '_TY_COMP_SLS_AMT'] - allPrgmReduced[timeframe + '_LY_COMP_SLS_AMT']) / allPrgmReduced[timeframe + '_LY_COMP_SLS_AMT'])}</Table.Cell>}
              {salesPipeline === 'Pipeline' && <Table.Cell>{allPrgmReduced[timeframe + '_LEAD_COUNT_' + pipelineFilter] ? allPrgmReduced[timeframe + '_LEAD_COUNT_' + pipelineFilter] : 0}</Table.Cell>}
              {salesPipeline === 'Pipeline' && <Table.Cell>{allPrgmReduced[timeframe + '_MEASURE_COUNT_' + pipelineFilter] ? allPrgmReduced[timeframe + '_MEASURE_COUNT_' + pipelineFilter] : 0}</Table.Cell>}
              {salesPipeline === 'Pipeline' && <Table.Cell style={getCompareStyle(allPrgmReduced[timeframe + '_LEAD_COUNT_' + pipelineFilter] + allPrgmReduced[timeframe + '_MEASURE_COUNT_' + pipelineFilter] - allPrgmReduced[timeframe + '_LY_LEAD_COUNT_' + pipelineFilter] - allPrgmReduced[timeframe + '_LY_MEASURE_COUNT_' + pipelineFilter])}>{getPercentString((allPrgmReduced[timeframe + '_LEAD_COUNT_' + pipelineFilter] + allPrgmReduced[timeframe + '_MEASURE_COUNT_' + pipelineFilter] - allPrgmReduced[timeframe + '_LY_LEAD_COUNT_' + pipelineFilter] - allPrgmReduced[timeframe + '_LY_MEASURE_COUNT_' + pipelineFilter]) / (allPrgmReduced[timeframe + '_LY_LEAD_COUNT_' + pipelineFilter] + allPrgmReduced[timeframe + '_LY_MEASURE_COUNT_' + pipelineFilter]))}</Table.Cell>}
              {salesPipeline === 'Pipeline' && <Table.Cell>{getPercentString((allPrgmReduced[timeframe + '_LEAD_CONV_NUM_' + pipelineFilter] + allPrgmReduced[timeframe + '_MEASURE_CONV_NUM_' + pipelineFilter]) / (allPrgmReduced[timeframe + '_LEAD_CONV_DEN_' + pipelineFilter] + allPrgmReduced[timeframe + '_MEASURE_CONV_DEN_' + pipelineFilter]))}</Table.Cell>}
            </Table.Row>}
          </Table.Body>
        </Table>

        <Header textAlign='center'>Leads & Measures Performance</Header>
        <Table textAlign='center' celled fixed unstackable size='small' compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell><Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/leads?strNbr=${strNbr}`}>Lead Comp</Link></Table.HeaderCell>
              <Table.HeaderCell><Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/meas?strNbr=${strNbr}`}>Measure Comp</Link></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              {focus6AllPrograms === 'Focus 6' && <Table.Cell style={getCompareStyle(focus6Reduced[timeframe + '_LEAD_COUNT_' + pipelineFilter] - focus6Reduced[timeframe + '_LY_LEAD_COUNT_' + pipelineFilter])}>{getPercentString((focus6Reduced[timeframe + '_LEAD_COUNT_' + pipelineFilter] - focus6Reduced[timeframe + '_LY_LEAD_COUNT_' + pipelineFilter]) / focus6Reduced[timeframe + '_LY_LEAD_COUNT_' + pipelineFilter])}</Table.Cell>}
              {focus6AllPrograms === 'Focus 6' && <Table.Cell style={getCompareStyle(focus6Reduced[timeframe + '_MEASURE_COUNT_' + pipelineFilter] - focus6Reduced[timeframe + '_LY_MEASURE_COUNT_' + pipelineFilter])}>{getPercentString((focus6Reduced[timeframe + '_MEASURE_COUNT_' + pipelineFilter] - focus6Reduced[timeframe + '_LY_MEASURE_COUNT_' + pipelineFilter]) / focus6Reduced[timeframe + '_LY_MEASURE_COUNT_' + pipelineFilter])}</Table.Cell>}
              {focus6AllPrograms === 'All Programs' && <Table.Cell style={getCompareStyle(allPrgmReduced[timeframe + '_LEAD_COUNT_' + pipelineFilter] - allPrgmReduced[timeframe + '_LY_LEAD_COUNT_' + pipelineFilter])}>{getPercentString((allPrgmReduced[timeframe + '_LEAD_COUNT_' + pipelineFilter] - allPrgmReduced[timeframe + '_LY_LEAD_COUNT_' + pipelineFilter]) / allPrgmReduced[timeframe + '_LY_LEAD_COUNT_' + pipelineFilter])}</Table.Cell>}
              {focus6AllPrograms === 'All Programs' && <Table.Cell style={getCompareStyle(allPrgmReduced[timeframe + '_MEASURE_COUNT_' + pipelineFilter] - allPrgmReduced[timeframe + '_LY_MEASURE_COUNT_' + pipelineFilter])}>{getPercentString((allPrgmReduced[timeframe + '_MEASURE_COUNT_' + pipelineFilter] - allPrgmReduced[timeframe + '_LY_MEASURE_COUNT_' + pipelineFilter]) / allPrgmReduced[timeframe + '_LY_MEASURE_COUNT_' + pipelineFilter])}</Table.Cell>}
            </Table.Row>
          </Table.Body>
        </Table>
      </Segment>
    </>

  )

})