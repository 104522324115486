import { DataView } from '../../../../../../../../components/Common/DataView';
import { Grid } from 'semantic-ui-react';
import { UnitMenu } from '../../../../../../../../components/Common/UnitMenu';
import { SKUS_TABLE_HEADER_NOM, SKUS_TABLE_HEADER_PCT, propsByMetric } from '../../../../../../utils/sales';
import { useState } from 'react';
import { formatSku } from '../../../../../../../../utils/formatter';
import { SkuDetailsModal } from '../../../../../../../../components/Sku/SkuDetailsModal';

export const SkuTable = ({ metric, skuList }) => {
  const [unit, setUnit] = useState('NOM');
  const [modalData, setModalData] = useState(null);
  const isModalOpen = !!modalData;
  const tableHeaders = unit === 'NOM' ? SKUS_TABLE_HEADER_NOM[metric] : SKUS_TABLE_HEADER_PCT[metric];
  const dataObj = {
    sortable: true,
    sortableDefault: 0,
    sortDirection: 'descending',
    pagination: true,
    headers: [{ name: 'SKU' }, { name: 'SKU Description' }, ...tableHeaders],

    data: skuList.map(data => [
      {
        onClick: () => setModalData(data),
        stringValue: formatSku(data['SKU_NBR']),
        customStyle: { color: '#EE7125', textDecoration: 'underline' }
      },
      {
        stringValue: data['SKU_DESC']
      },
      {
        dollarValue: data[propsByMetric[metric][0]]
      },
      unit === 'NOM'
        ? {
            compareStyle: true,
            dollarValue: metric === 'SLS' || metric === 'AVG_TCKT' ? data[propsByMetric[metric][1]] : undefined,
            stringValue: metric === 'TXN' || metric === 'UNT' ? data[propsByMetric[metric][1]].toLocaleString() : undefined
          }
        : {
            compareStyle: true,
            pctValue: data[propsByMetric[metric][2]]
          }
    ])
  };

  const onCloseModal = () => setModalData(null);
  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column width={2} textAlign='justified'>
            <Grid.Row>Units:</Grid.Row>
            <UnitMenu unit={unit} setUnit={setUnit} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <DataView.DataTable data={dataObj} />
      <SkuDetailsModal isOpen={isModalOpen} onClose={onCloseModal} skuData={modalData} />
    </>
  );
};
