import React, { useState } from 'react'
import {
  Table,
  Tab,
  Grid,
  Icon,
} from 'semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'
import { UnitMenu } from '../Common/UnitMenu'
import { getCompareStyle, getThousandDollarString, getPercentString } from '../Common/TableCellUtils'

export const DstInventoryTab = withRouter(({ data, location }) => {

  const [sortColumn, setSortColumn] = useState("DEPT_NBR")
  const [sortDirection, setSortDirection] = useState("ascending")

  let dataSorted = data.sort((left, right) => {
    let result = left[sortColumn] - right[sortColumn]
    if (sortColumn === 'DEPT_NBR') {
      result = left.DEPT_NBR > right.DEPT_NBR ? 1 : -1
    }
    if (sortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  const search = new URLSearchParams(location.search)
  const dstNbr = search.has('dstNbr') ? search.get('dstNbr') : 0

  const [unit, setUnit] = useState("NOM")

  return (
    <Tab.Pane>
      <Grid>
        <Grid.Column width={8}>
          <Grid.Row>Units:</Grid.Row>
          <UnitMenu unit={unit} setUnit={setUnit} />
        </Grid.Column>
        <Grid.Column width={8}>
          <Grid.Row>&nbsp;</Grid.Row>
          <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/district/shrink?dstNbr=${dstNbr}`}>
            Click for Shrink Metrics
          </Link>
        </Grid.Column>
      </Grid>
      <Grid>
        <Grid.Column>
          <Grid.Row>
            <Table textAlign='center' unstackable celled sortable size='small' style={{ fontSize: 12 }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    sorted={sortColumn === 'DEPT_NBR' ? sortDirection : null}
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    onClick={() => {
                      if (sortColumn !== 'DEPT_NBR') {
                        setSortColumn('DEPT_NBR')
                        setSortDirection('ascending')
                      } else {
                        sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                      }
                    }}>
                    DEPT {sortColumn !== 'DEPT_NBR' && <Icon fitted name='sort' />}
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={sortColumn === 'INV_AMT' ? sortDirection : null}
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    onClick={() => {
                      if (sortColumn !== 'INV_AMT') {
                        setSortColumn('INV_AMT')
                        setSortDirection('ascending')
                      } else {
                        sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                      }
                    }}>
                    Inv $ {sortColumn !== 'INV_AMT' && <Icon fitted name='sort' />}
                  </Table.HeaderCell>
                  {unit === 'NOM' && <Table.HeaderCell
                    sorted={sortColumn === 'INV_VP' ? sortDirection : null}
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    onClick={() => {
                      if (sortColumn !== 'INV_VP') {
                        setSortColumn('INV_VP')
                        setSortDirection('ascending')
                      } else {
                        sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                      }
                    }}>
                    vP {sortColumn !== 'INV_VP' && <Icon fitted name='sort' />}
                  </Table.HeaderCell>}
                  {unit === 'PCT' && <Table.HeaderCell
                    sorted={sortColumn === 'INV_VP_PCT' ? sortDirection : null}
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    onClick={() => {
                      if (sortColumn !== 'INV_VP_PCT') {
                        setSortColumn('INV_VP_PCT')
                        setSortDirection('ascending')
                      } else {
                        sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                      }
                    }}>
                    vP % {sortColumn !== 'INV_VP_PCT' && <Icon fitted name='sort' />}
                  </Table.HeaderCell>}
                  <Table.HeaderCell
                    sorted={sortColumn === 'ASW' ? sortDirection : null}
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    onClick={() => {
                      if (sortColumn !== 'ASW') {
                        setSortColumn('ASW')
                        setSortDirection('ascending')
                      } else {
                        sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                      }
                    }}>
                    ASW {sortColumn !== 'ASW' && <Icon fitted name='sort' />}
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={sortColumn === 'WOS' ? sortDirection : null}
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    onClick={() => {
                      if (sortColumn !== 'WOS') {
                        setSortColumn('WOS')
                        setSortDirection('ascending')
                      } else {
                        sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                      }
                    }}>
                    WOS {sortColumn !== 'WOS' && <Icon fitted name='sort' />}
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={sortColumn === 'ICE_INV_AMT' ? sortDirection : null}
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    onClick={() => {
                      if (sortColumn !== 'ICE_INV_AMT') {
                        setSortColumn('ICE_INV_AMT')
                        setSortDirection('ascending')
                      } else {
                        sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                      }
                    }}>
                    ICE $ {sortColumn !== 'ICE_INV_AMT' && <Icon fitted name='sort' />}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {dataSorted.map((data) => {
                  return (
                    <Table.Row>
                      <Table.Cell>
                        <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/district/inventory/dept?dstNbr=${dstNbr}&deptNbr=${data['DEPT_NBR']}`}>
                          {data['DEPT_NM'].replaceAll('/', '/\u200b')}
                        </Link>
                      </Table.Cell>
                      <Table.Cell singleLine>
                        {getThousandDollarString(data['INV_AMT'])}
                      </Table.Cell>
                      {unit === 'NOM' && <Table.Cell singleLine style={getCompareStyle(data['INV_VP'])}>
                        {getThousandDollarString(data['INV_VP'])}
                      </Table.Cell>}
                      {unit === 'PCT' && <Table.Cell singleLine style={getCompareStyle(data['INV_VP_PCT'])}>
                        {getPercentString(data['INV_VP_PCT'])}
                      </Table.Cell>}
                      <Table.Cell singleLine>
                        {getThousandDollarString(data['ASW'])}
                      </Table.Cell>
                      <Table.Cell singleLine>
                        {data['WOS'].toLocaleString(undefined, { maximumFractionDigits: 1 })}
                      </Table.Cell>
                      <Table.Cell singleLine>
                        {getThousandDollarString(data['ICE_INV_AMT'])}
                      </Table.Cell>
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>
          </Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
        </Grid.Column>
      </Grid>
    </Tab.Pane>
  )
}
)