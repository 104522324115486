import { Header, Icon, Popup } from 'semantic-ui-react';
import { OSA_SIDEKICK_PURGE_GOAL } from '../../constants/goals';
import { DataView } from '../Common/DataView';
import { TimeframeDropdownLimited } from '../Common/TimeframeDropdownLimited';
import { useFetchLevelName } from '../../hooks/useFetchLevelName';

const headerLevel = {
  district: 'Store',
  region: 'District',
  division: 'Region'
};

const linkConfig = {
  district: { to: 'store', paramKey: 'strNbr' },
  region: { to: 'district', paramKey: 'dstNbr' },
  division: { to: 'region', paramKey: 'rgnNbr' }
};

export const SidekickExcecutionPurge = ({
  data,
  dataScores,
  entityField,
  findItem,
  fpToggle,
  level,
  locNbr,
  ltf,
  settimeframe,
  timeframe
}) => {
  const { currentLevelName } = useFetchLevelName({ fpToggle, level, locNbr });
  const sideKickPurgeData = data.reduce((acc, curr) => {
    const sideExeData = dataScores?.find(r => r[entityField] === curr[entityField]);

    if (!sideExeData) return acc;

    let item = findItem(acc, entityField, curr);

    if (item) {
      updateItem(item, curr, timeframe, sideExeData, ltf);
    } else {
      acc.push(createNewItem(entityField, curr, timeframe, sideExeData, ltf));
    }

    return acc;
  }, []);

  const dataObj = {
    headers: [{ name: headerLevel[level] }, { name: 'Actual' }, { name: 'Goal' }, { name: `v${ltf} bps` }],
    data: sideKickPurgeData.map(data => [
      {
        stringValue: data[entityField],
        link: {
          to: `/osa/Purge/${linkConfig[level]?.to}`,
          params: { [linkConfig[level]?.paramKey]: data[entityField] }
        }
      },
      {
        pctValue: data.ACTUAL,
        customStyle: { color: data.ACTUAL < OSA_SIDEKICK_PURGE_GOAL ? 'red' : 'green' }
      },
      {
        pctValue: OSA_SIDEKICK_PURGE_GOAL
      },
      {
        numValue:
          data.BPS === '-' || isNaN(data.BPS)
            ? '-'
            : ((data.ACTUAL - data.BPS) * 10000).toLocaleString(undefined, { maximumFractionDigits: 0 })
      }
    ]),
    sortable: true,
    sortableDefault: 0
  };

  return (
    <DataView className='mt-4'>
      <DataView.Title>
        <span>
          Sidekick Execution - Purge - {level} {locNbr}, {currentLevelName}
          <Popup
            trigger={<Icon name='info circle' color='blue' />}
            content='Execution Rate = Purge Tasks Completed/60. A store is given 60 purge tasks to execute each week.'
            position='right center'
            on='click'
            textAlign='center'
          />
        </span>
        <Header.Subheader>Data as of {data[0]?.TRNSFM_DT}</Header.Subheader>
      </DataView.Title>

      <DataView.Controls className='align-items-center d-flex flex-column'>
        Timeframe:
        <TimeframeDropdownLimited timeframe={timeframe} settimeframe={settimeframe} />
      </DataView.Controls>

      <DataView.DataTable data={dataObj} />
    </DataView>
  );
};

const createNewItem = (entityField, curr, timeframe, sideExeData, ltf) => {
  const totalTaskCount = curr[timeframe + '_TOTAL_TASK_COUNT'];
  const completedTaskCount = sideExeData[timeframe + '_STR_PURGE_COMPLETED_TASK_COUNT'];
  const actualPurgeRate = completedTaskCount / totalTaskCount;

  const prevTotalTaskCount = curr[ltf + '_TOTAL_TASK_COUNT'];
  const prevCompletedTaskCount = sideExeData[ltf + '_STR_PURGE_COMPLETED_TASK_COUNT'];
  const bps = prevTotalTaskCount !== 0 ? prevCompletedTaskCount / prevTotalTaskCount : '-';

  return {
    [entityField]: curr[entityField],
    [timeframe + '_TASK_COUNT']: totalTaskCount,
    [timeframe + '_STR_PURGE_COMPLETED_TASK_COUNT']: completedTaskCount,
    ACTUAL: actualPurgeRate,
    [ltf + '_STR_PURGE_TASK_COUNT']: curr[ltf + '_STR_PURGE_TASK_COUNT'],
    [ltf + '_STR_PURGE_COMPLETED_TASK_COUNT']: prevCompletedTaskCount,
    BPS: bps
  };
};

const updateItem = (item, curr, timeframe, sideExeData, ltf) => {
  const totalTaskCount = curr[timeframe + '_TOTAL_TASK_COUNT'];
  const completedTaskCount = sideExeData[timeframe + '_STR_PURGE_COMPLETED_TASK_COUNT'];
  const prevTotalTaskCount = curr[ltf + '_TOTAL_TASK_COUNT'];
  const prevCompletedTaskCount = sideExeData[ltf + '_STR_PURGE_COMPLETED_TASK_COUNT'];

  item[timeframe + '_TASK_COUNT'] += totalTaskCount;
  item[timeframe + '_STR_PURGE_COMPLETED_TASK_COUNT'] = completedTaskCount;
  item.ACTUAL = item[timeframe + '_STR_PURGE_COMPLETED_TASK_COUNT'] / item[timeframe + '_TOTAL_TASK_COUNT'];

  item[ltf + '_LY_STR_PURGE_TASK_COUNT'] += curr[ltf + '_LY_STR_PURGE_TASK_COUNT'];
  item[ltf + '_STR_PURGE_COMPLETED_TASK_COUNT'] = prevCompletedTaskCount;

  item.BPS = prevTotalTaskCount !== 0 ? prevCompletedTaskCount / prevTotalTaskCount : '-';
};
