import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Header, Loader, Table } from 'semantic-ui-react';

import { useQuery } from 'react-query';
import { useQueryParams } from '../../hooks/useQueryParams';
import { getData } from '../../service/DataService';
import { HeaderComponent } from '../Common/HeaderComponent';
import { DailyViewComponent } from './DailyViewComponent';

const HOURLYDAY_CONSTANTS = {
  store: {
    wtdQuery: 'queryWtdDeptSlsByStr',
    dailyQuery: 'queryDailyDeptSlsByStr',
    METRIC_LEVEL: 'STR',
    urlParam: 'strNbr'
  },
  district: {
    wtdQuery: 'queryWtdDeptSlsByDst',
    dailyQuery: 'queryDailyDeptSlsByDst',
    METRIC_LEVEL: 'DST',
    urlParam: 'dstNbr'
  },
  region: {
    wtdQuery: 'queryWtdDeptSlsByRgn',
    dailyQuery: 'queryDailyDeptSlsByRgn',
    METRIC_LEVEL: 'RGN',
    urlParam: 'rgnNbr'
  },
  division: {
    wtdQuery: 'queryWtdDeptSlsByDiv',
    dailyQuery: 'queryDailyDeptSlsByDiv',
    METRIC_LEVEL: 'DIV',
    urlParam: 'divNbr'
  }
};

export const DailyDeptView = ({ fpToggle, level }) => {
  const { strNbr = 0, deptNbr = 0, dstNbr = 0, rgnNbr = 0, divNbr = 0 } = useQueryParams();
  const queryParams = {
    store: { strNbr },
    district: { dstNbr },
    region: { locNbr: rgnNbr },
    division: { locNbr: divNbr }
  };

  const wtdQuery = useQuery(
    [HOURLYDAY_CONSTANTS[level]['wtdQuery'], { ...queryParams[level], deptNbrInt: deptNbr, fpToggle }],
    getData
  );
  const dailyQuery = useQuery(
    [HOURLYDAY_CONSTANTS[level]['dailyQuery'], { ...queryParams[level], deptNbrInt: deptNbr, fpToggle }],
    getData
  );
  if (wtdQuery.isLoading || dailyQuery.isLoading) {
    return <Loader active>Loading...</Loader>;
  }
  const wtdData = wtdQuery.data[0];

  return (
    <>
      <Grid>
        <Grid.Column width={8} textAlign='left'>
          <Grid.Row>
            <Link
              style={{ color: '#EE7125' }}
              to={`/${level}/daily?${HOURLYDAY_CONSTANTS[level]['urlParam']}=${strNbr || rgnNbr || dstNbr || divNbr}`}>
              Back to Daily
            </Link>
          </Grid.Row>
        </Grid.Column>
      </Grid>
      <HeaderComponent
        level={level}
        deptNbr={deptNbr}
        levelNumber={strNbr || dstNbr || rgnNbr || divNbr}
        rgnName={(dailyQuery?.data[0] && dailyQuery.data[0]['RGN_NM']) || dailyQuery.data[0]['DIV_NM']}
      />
      <Header textAlign='center'>WTD Sales</Header>
      <Table textAlign='center' celled sortable unstackable size='small' style={{ fontSize: 10 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>As Of</Table.HeaderCell>
            <Table.HeaderCell>
              WTD Sales
              <br />$ (k)
            </Table.HeaderCell>
            <Table.HeaderCell>
              $ vPlan
              <br />
              WTD (k)
            </Table.HeaderCell>
            <Table.HeaderCell>
              % to
              <br />
              WTD Plan
            </Table.HeaderCell>
            <Table.HeaderCell>
              WTD
              <br />
              Comp %
            </Table.HeaderCell>
            <Table.HeaderCell>
              WTD Planned
              <br />
              Comp %
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>{wtdData['ABBR_DAY_NM']}</Table.Cell>
            <Table.Cell style={{ color: wtdData['WTD_SLS_AMT'] < 0 ? 'red' : 'black' }}>
              {wtdData['WTD_SLS_AMT'] != null
                ? wtdData['WTD_SLS_AMT'] < 1000
                  ? wtdData['WTD_SLS_AMT'] < 0
                    ? wtdData['WTD_SLS_AMT'] <= -1000
                      ? '($' +
                        (wtdData['WTD_SLS_AMT'] / -1000).toLocaleString(undefined, { maximumFractionDigits: 1 }) +
                        'K)'
                      : '($' + (-1 * wtdData['WTD_SLS_AMT']).toLocaleString(undefined, { maximumFractionDigits: 0 }) + ')'
                    : '$' + wtdData['WTD_SLS_AMT'].toLocaleString(undefined, { maximumFractionDigits: 0 })
                  : '$' + (wtdData['WTD_SLS_AMT'] / 1000).toLocaleString(undefined, { maximumFractionDigits: 1 }) + 'K'
                : '-'}
            </Table.Cell>
            <Table.Cell style={{ color: wtdData['DOL_TO_WTD_PLN'] < 0 ? 'red' : 'black' }}>
              {wtdData['DOL_TO_WTD_PLN'] != null
                ? wtdData['DOL_TO_WTD_PLN'] < 1000
                  ? wtdData['DOL_TO_WTD_PLN'] < 0
                    ? wtdData['DOL_TO_WTD_PLN'] <= -1000
                      ? '($' +
                        (wtdData['DOL_TO_WTD_PLN'] / -1000).toLocaleString(undefined, { maximumFractionDigits: 1 }) +
                        'K)'
                      : '($' + (-1 * wtdData['DOL_TO_WTD_PLN']).toLocaleString('en-US', { maximumFractionDigits: 0 }) + ')'
                    : '$' + wtdData['DOL_TO_WTD_PLN'].toLocaleString(undefined, { maximumFractionDigits: 0 })
                  : '$' + (wtdData['DOL_TO_WTD_PLN'] / 1000).toLocaleString(undefined, { maximumFractionDigits: 1 }) + 'K'
                : '-'}
            </Table.Cell>
            <Table.Cell style={{ color: wtdData['PCT_TO_WTD_PLN'] < 0 ? 'red' : 'black' }}>
              {wtdData['PCT_TO_WTD_PLN'] != null
                ? wtdData['PCT_TO_WTD_PLN'] < 0
                  ? '(' +
                    (-100 * wtdData['PCT_TO_WTD_PLN']).toLocaleString(undefined, {
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1
                    }) +
                    '%)'
                  : (100 * wtdData['PCT_TO_WTD_PLN']).toLocaleString(undefined, {
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1
                    }) + '%'
                : '-'}
            </Table.Cell>
            <Table.Cell style={{ color: wtdData['WTD_COMP_PCT'] < 0 ? 'red' : 'black' }}>
              {wtdData['WTD_COMP_PCT'] != null
                ? wtdData['WTD_COMP_PCT'] < 0
                  ? '(' +
                    (-100 * wtdData['WTD_COMP_PCT']).toLocaleString(undefined, {
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1
                    }) +
                    '%)'
                  : (100 * wtdData['WTD_COMP_PCT']).toLocaleString(undefined, {
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1
                    }) + '%'
                : '-'}
            </Table.Cell>
            <Table.Cell style={{ color: wtdData['WTD_PLANNED_COMP_PCT'] < 0 ? 'red' : 'black' }}>
              {wtdData['WTD_PLANNED_COMP_PCT'] != null
                ? wtdData['WTD_PLANNED_COMP_PCT'] < 0
                  ? '(' +
                    (-100 * wtdData['WTD_PLANNED_COMP_PCT']).toLocaleString(undefined, {
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1
                    }) +
                    '%)'
                  : (100 * wtdData['WTD_PLANNED_COMP_PCT']).toLocaleString(undefined, {
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1
                    }) + '%'
                : '-'}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <DailyViewComponent dataQuery={dailyQuery.data} />
    </>
  );
};
