import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Header, Loader } from 'semantic-ui-react';
import { useQueryParams } from '../../hooks/useQueryParams';
import { MenuItems } from '../Common/MenuItems';
import { WTDSalesByDepartment } from './DailyView/WTDSalesByDepartment';
import { WTDSales } from './DailyView/WTDSales';
import { SalesScorecard } from './DailyView/SalesScorecard';
import useQueryApi from '../../hooks/useQueryApi';

const WTD_QUERIES = {
  store: 'queryWtdSlsByStr',
  district: 'queryWtdSlsByDst',
  region: 'queryWtdSlsByRgn',
  division: 'queryWtdSlsByDiv'
};

const WTD_OR_SCORECARD_TABS = ['WTD Sales', 'Sales Scorecard'];
const [WTD_SALES_TAB, SALES_SCORECARD_TAB] = WTD_OR_SCORECARD_TABS;

export const DailyView = ({ fpToggle, levelName }) => {
  const { level } = useParams();
  const { divNbr, rgnNbr, dstNbr, strNbr } = useQueryParams();
  const locNbr = divNbr || rgnNbr || dstNbr || strNbr;
  const [wtdOrScorecardTab, setWtdOrScorecardTab] = useState(WTD_SALES_TAB);

  const { data: wtdSalesData, isLoading: wtdSalesIsLoading } = useQueryApi(WTD_QUERIES[level], { locNbr, fpToggle });
  const { data: dailySalesData, isLoading: dailySalesIsLoading } = useQueryApi(`query/wtdDailySalesByDepartment/${level}`, {
    locNbr,
    fpToggle
  });
  const { data: wtdSalesByDepartmentData, isLoading: wtdSalesByDepartmentIsLoading } = useQueryApi(
    `query/wtdSalesByDepartment/${level}`,
    { locNbr, fpToggle }
  );
  const { data: salesHistoryData, isLoading: salesHistoryIsLoading } = useQueryApi(`query/salesHistory/${level}`, {
    locNbr,
    fpToggle
  });

  const isLoading = wtdSalesIsLoading || dailySalesIsLoading || wtdSalesByDepartmentIsLoading || salesHistoryIsLoading;
  if (isLoading) return <Loader active>Loading...</Loader>;

  const isData = wtdSalesData.length || dailySalesData.length || wtdSalesByDepartmentData.length;
  if (!isData) return <Header textAlign='center'>Invalid Store Number</Header>;

  return (
    <>
      <Header textAlign='center' style={{ textTransform: 'capitalize' }}>
        Daily Sales - {level} {locNbr}, {levelName}
      </Header>

      <MenuItems activeItem={wtdOrScorecardTab} items={WTD_OR_SCORECARD_TABS} setItem={setWtdOrScorecardTab} size='small' />

      {wtdOrScorecardTab === WTD_SALES_TAB && (
        <>
          <WTDSales wtdSalesData={wtdSalesData} dailySalesData={dailySalesData} />
          <WTDSalesByDepartment data={wtdSalesByDepartmentData} level={level} locNbr={locNbr} />
        </>
      )}
      {wtdOrScorecardTab === SALES_SCORECARD_TAB && <SalesScorecard data={salesHistoryData} />}
    </>
  );
};
