import { useState } from 'react';
import { Header, Loader, Segment } from 'semantic-ui-react';
import { DataView } from '../../../../../components/Common/DataView';
import { MenuItems } from '../../../../../components/Common/MenuItems';
import {
  dailySalesColumns,
  dailySalesHeaders,
  SALES_SCORECARD,
  textCapitalizedStyle,
  WTD_OR_SCORECARD_TABS,
  WTD_SALES,
  wtdSalesColumns,
  wtdSalesColumnsByDepartmentHeaders,
  wtdSalesHeaders
} from '../utils/dailySales';
import { WtdSales } from '../components/WtdSales';
import { WTDSalesByDepartment } from '../components/WTDSalesByDepartment';
import { SalesScorecard } from '../components/SalesScorecard';
import { useDailySalesOverviewQueries } from '../hooks/useDailySalesOverviewQueries';

export const DailySalesOverview = ({ fpToggle }) => {
  const { isLoading, isError, queryWtdSlsByCore, wtdDailySalesByDepartment, wtdSalesByDepartment, salesHistoryByCore } =
    useDailySalesOverviewQueries(fpToggle);

  const [wtdOrScorecardTab, setWtdOrScorecardTab] = useState(WTD_SALES);

  if (isLoading) return <Loader active>Loading...</Loader>;

  if (isError) return <Header textAlign='center'>Invalid Store Number</Header>;

  const wtdSalesData = {
    headers: wtdSalesHeaders,
    data: queryWtdSlsByCore.map(row => wtdSalesColumns(row))
  };

  const dailySalesData = {
    sortable: true,
    sortableDefault: 0,
    headers: dailySalesHeaders,
    data: wtdDailySalesByDepartment.map(row => dailySalesColumns(row))
  };

  const wtdSalesByDepartmentData = {
    sortable: true,
    sortableDefault: 0,
    headers: wtdSalesColumnsByDepartmentHeaders,
    data: wtdSalesByDepartment.map(row => wtdSalesColumns(row, 'departments'))
  };

  return (
    <section className='mb-4'>
      <Segment>
        <DataView.Controls className='mt-2'>
          <Header textAlign='center' style={textCapitalizedStyle}>
            Daily Sales - Core
          </Header>

          <MenuItems
            activeItem={wtdOrScorecardTab}
            items={WTD_OR_SCORECARD_TABS}
            setItem={setWtdOrScorecardTab}
            size='small'
          />
        </DataView.Controls>

        <DataView.Controls className='mt-2'>
          {wtdOrScorecardTab === WTD_SALES && (
            <>
              <WtdSales wtdSalesData={wtdSalesData} dailySalesData={dailySalesData} />
              <WTDSalesByDepartment data={wtdSalesByDepartmentData} />
            </>
          )}
          {wtdOrScorecardTab === SALES_SCORECARD && <SalesScorecard data={salesHistoryByCore} />}
        </DataView.Controls>
      </Segment>
    </section>
  );
};
