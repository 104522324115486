import { useState, useMemo } from 'react';
import { useCoreOverviewContext } from '../../../../../hooks/useCoreOverviewContext';
import { Dropdown, Tab } from 'semantic-ui-react';
import { DataView } from '../../../../../../../components/Common/DataView';
import { timeframeCode } from '../../../../../../../components/Common/timeframeCode';
import { SUBCLASS_HEADERS_BY_METRIC, CLASSES_DEPARTMENT_OPTIONS, CLASSES_METRIC_OPTIONS } from '../../../../../utils/sales';
import { getCalloutStyle } from '../../../../../../../components/Common/TableCellUtils';

const propsByMetric = {
  SLS: ['TY_SLS_AMT', 'SLS_COMP_NOM', 'SLS_COMP_PCT'],
  UNT: ['TY_UNT_SLS', 'UNT_COMP_NOM', 'UNT_COMP_PCT'],
  TXN: ['TY_TXN_CNT', 'TXN_COMP_NOM', 'TXN_COMP_PCT'],
  AVG_TCKT: ['TY_AVG_TCKT', 'AVG_TCKT_COMP_NOM', 'AVG_TCKT_COMP_PCT']
};
export const SalesSubClassesTab = () => {
  const [dept, setDept] = useState('All');
  const [metric, setMetric] = useState('SLS');
  const { salesSubClassesData, timeframe } = useCoreOverviewContext();

  const dataFiltered = useMemo(() => {
    return salesSubClassesData.filter(row => {
      return (
        row &&
        row['MER_SUB_CLASS_NM'] !== null &&
        row['TRNSFM_TYP_SORT_ID'] === timeframeCode[timeframe] &&
        (dept === 'All' || row['DEPT_NBR'].toString() === dept)
      );
    });
  }, [dept, salesSubClassesData, timeframe]);

  const tableHeaders = SUBCLASS_HEADERS_BY_METRIC[metric];
  const dataObj = {
    sortable: true,
    sortableDefault: 1,
    sortDirection: 'ascending',
    pagination: true,
    headers: [{ name: 'Subclass' }, ...tableHeaders],
    data: dataFiltered.map(row => [
      {
        link: {
          to: '/core/sales/subclass',
          params: {
            subClassNbr: row['EXT_SUB_CLASS_NBR']
          }
        },
        stringValue: row['MER_SUB_CLASS_NM'].replaceAll('/', '/\u200b')
      },
      {
        dollarValue: metric === 'SLS' || metric === 'AVG_TCKT' ? row[propsByMetric[metric][0]] : undefined,
        numValue:
          metric === 'TXN' || metric === 'UNT'
            ? row[propsByMetric[metric][0]].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
            : undefined,
        orderBy: row[propsByMetric[metric][0]],
        customStyle: getCalloutStyle(row[propsByMetric[metric][0]])
      },
      {
        dollarValue: metric === 'SLS' || metric === 'AVG_TCKT' ? row[propsByMetric[metric][1]] : undefined,
        numValue:
          metric === 'TXN' || metric === 'UNT'
            ? row[propsByMetric[metric][1]].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
            : undefined,
        orderBy: row[propsByMetric[metric][1]],
        compareStyle: true
      },
      {
        pctValue: row[propsByMetric[metric][2]],
        compareStyle: true
      }
    ])
  };

  return (
    <Tab.Pane className='mt-3'>
      <DataView>
        <DataView.Title>Sales by SubClasses</DataView.Title>
        <DataView.Controls>
          <Dropdown
            className='mx-3'
            compact
            defaultValue='SLS'
            options={CLASSES_METRIC_OPTIONS}
            onChange={(_, { value }) => setMetric(value)}
            selection
            scrolling
            size='tiny'
          />
          <Dropdown
            compact
            defaultValue='All'
            onChange={(_, { value }) => setDept(value)}
            options={CLASSES_DEPARTMENT_OPTIONS}
            selection
            size='tiny'
          />
        </DataView.Controls>
        <DataView.DataTable data={dataObj} />
      </DataView>
    </Tab.Pane>
  );
};
