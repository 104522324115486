import React from 'react'
import {
  Tab,
} from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import { CompTab } from './CompTab'
import { SalesTab } from './SalesTab'
import { AisleBayTab } from './AisleBayTab'
import { InventoryTab } from './InventoryTab'

export const OverviewTabs = withRouter(({ dataSlsDeptByStr, dataSlsClsByStr, dataSlsSclsByStr, aisleData, Top40Btm40Query, deptInventory, timeframe, fpToggle, strNm }) => {

  const aisleBayView = <AisleBayTab
                          aisleData={aisleData} 
                          dataSlsDeptByStr={dataSlsDeptByStr}
                          timeframe={timeframe}
                          fpToggle={fpToggle}  />

  const salesView = <SalesTab
                      dataSlsDeptByStr={dataSlsDeptByStr}
                      dataSlsClsByStr={dataSlsClsByStr}
                      dataSlsSclsByStr={dataSlsSclsByStr}
                      Top40Btm40Query={Top40Btm40Query} 
                      timeframe={timeframe} 
                      strNm={strNm} />

  const compView = <CompTab 
                      data={dataSlsDeptByStr} 
                      timeframe={timeframe} />

  const inventoryView = <InventoryTab 
                      data={deptInventory} />


  const tabs = [
    {
      menuItem: 'Sales',
      render: () => salesView
    },

    {
      menuItem: 'Comp',
      render: () => compView,
    },

    {
      menuItem: 'Aisle-Bay',
      render: () => aisleBayView,
    },

    {
      menuItem: 'Inventory',
      render: () => inventoryView,
    }
  ]

  return <Tab panes={tabs} />
})