import { Tab, Loader, Header } from "semantic-ui-react";
import { capitalizeFirstLetter } from "../../utils/string";
import { METRIC_CONSTANTS } from "../../utils/constantsByLevel";
import { IntradayView } from "./IntradayView";
import { IntradayTabList } from "./IntradayTabList";
import { useIntradayContext } from "../../hooks/useIntradayContext";

const tabsMenu = ({ level }) => {
  return [
    {
      menuItem: capitalizeFirstLetter(level),
      render: () => <IntradayView />
    },
    {
      menuItem: capitalizeFirstLetter(METRIC_CONSTANTS[level]['urlLinkLevel']) + ' List',
      render: () => <IntradayTabList level={level} />
    }
  ]
}

export const IntradayMenu = () => {

  const { level, isLoading, isError, hourlySalesData, deptOverviewQuery, currentLevelName } = useIntradayContext()

  if (isError) return <Header>Something went wrong</Header>;
  if (isLoading) return <Loader active>Loading</Loader>;

  if (!hourlySalesData[0] || !deptOverviewQuery[0] || !currentLevelName) {
    return <Header textAlign='center' style={{ textTransform: 'capitalize' }}>Invalid or Unauthorized {level}</Header>;
  }

  if (level === 'store') {
    return <IntradayView />
  }

  return <Tab panes={tabsMenu({ level })} menu={{ secondary: true, pointing: true }} style={{ marginBottom: '1em' }} />;
}
