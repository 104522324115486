import React, { useState } from 'react'
import {
  Table,
  Header,
  Tab,
} from 'semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'
import { getBpsString, getCompareStyle, getPercentString, getThousandDollarString } from '../Common/TableCellUtils'

export const DrilldownTabs = withRouter(({ drilldownPerfData, drilldownSlsPenData, timeframe }) => {

  const [perfSortColumn, setPerfSortColumn] = useState('STR_NBR')
  const [perfSortDirection, setPerfSortDirection] = useState('ascending')
  const [slsPenSortColumn, setSlsPenSortColumn] = useState('STR_NBR')
  const [slsPenSortDirection, setSlsPenSortDirection] = useState('ascending')


  let perfDataSorted = drilldownPerfData.sort((left, right) => {
    let result = left[perfSortColumn] - right[perfSortColumn]
    if (perfSortColumn === timeframe + '_TOT_VG') {
      result = left[timeframe + '_TOT_APPS'] / left[timeframe + '_TOT_APP_GOAL'] - right[timeframe + '_TOT_APPS'] / right[timeframe + '_TOT_APP_GOAL']
    }
    if (perfSortColumn === timeframe + '_CONS_VG') {
      result = left[timeframe + '_CONS_APPS'] / left[timeframe + '_CONS_APP_GOAL'] - right[timeframe + '_CONS_APPS'] / right[timeframe + '_CONS_APP_GOAL']
    }
    if (perfSortColumn === timeframe + '_COMM_VG') {
      result = left[timeframe + '_COMM_APPS'] / left[timeframe + '_COMM_APP_GOAL'] - right[timeframe + '_COMM_APPS'] / right[timeframe + '_COMM_APP_GOAL']
    }
    if (perfSortColumn === timeframe + '_TOT_APP_EFF') {
      result = left[timeframe + '_TXN_COUNT'] / left[timeframe + '_TOT_APPS'] - right[timeframe + '_TXN_COUNT'] / right[timeframe + '_TOT_APPS']
    }
    if (perfSortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  let slsPenDataSorted = drilldownSlsPenData.sort((left, right) => {
    let result = left[slsPenSortColumn] - right[slsPenSortColumn]
    if (slsPenSortColumn === timeframe + '_TOT_USG') {
      result = left[timeframe + '_CRED_SLS_AMT'] / left[timeframe + '_TOT_SLS_AMT'] - right[timeframe + '_CRED_SLS_AMT'] / right[timeframe + '_TOT_SLS_AMT']
    }
    if (slsPenSortColumn === timeframe + '_CONS_USG') {
      result = left[timeframe + '_CONS_SLS_AMT'] / left[timeframe + '_TOT_SLS_AMT'] - right[timeframe + '_CONS_SLS_AMT'] / right[timeframe + '_TOT_SLS_AMT']
    }
    if (slsPenSortColumn === timeframe + '_COMM_USG') {
      result = left[timeframe + '_COMM_SLS_AMT'] / left[timeframe + '_TOT_SLS_AMT'] - right[timeframe + '_COMM_SLS_AMT'] / right[timeframe + '_TOT_SLS_AMT']
    }
    if (slsPenSortColumn === timeframe + '_TOT_VP') {
      result = (left[timeframe + '_CRED_SLS_AMT'] / left[timeframe + '_TOT_SLS_AMT'] - left[timeframe + '_TOT_CRED_SLS_PLN'] / left[timeframe + '_TOT_SLS_PLN'])
        - (right[timeframe + '_CRED_SLS_AMT'] / right[timeframe + '_TOT_SLS_AMT'] - right[timeframe + '_TOT_CRED_SLS_PLN'] / right[timeframe + '_TOT_SLS_PLN'])
    }
    if (slsPenSortColumn === timeframe + '_CONS_VP') {
      result = (left[timeframe + '_CONS_SLS_AMT'] / left[timeframe + '_TOT_SLS_AMT'] - left[timeframe + '_CONS_CRED_SLS_PLN'] / left[timeframe + '_TOT_SLS_PLN'])
        - (right[timeframe + '_CONS_SLS_AMT'] / right[timeframe + '_TOT_SLS_AMT'] - right[timeframe + '_CONS_CRED_SLS_PLN'] / right[timeframe + '_TOT_SLS_PLN'])
    }
    if (slsPenSortColumn === timeframe + '_COMM_VP') {
      result = (left[timeframe + '_COMM_SLS_AMT'] / left[timeframe + '_TOT_SLS_AMT'] - left[timeframe + '_COMM_CRED_SLS_PLN'] / left[timeframe + '_TOT_SLS_PLN'])
        - (right[timeframe + '_COMM_SLS_AMT'] / right[timeframe + '_TOT_SLS_AMT'] - right[timeframe + '_COMM_CRED_SLS_PLN'] / right[timeframe + '_TOT_SLS_PLN'])
    }
    if (slsPenSortDirection === 'descending') {
      result *= -1
    }
    return result
  })


  const totalView = <Tab.Pane>
    <Header textAlign='center'>Applications by Store</Header>
    <Table textAlign='center' celled unstackable sortable size='small' style={{ fontSize: 12 }}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            sorted={perfSortColumn === 'STR_NBR' ? perfSortDirection : null}
            onClick={() => {
              if (perfSortColumn !== 'STR_NBR') {
                setPerfSortColumn('STR_NBR')
                setPerfSortDirection('ascending')
              } else {
                perfSortDirection === 'ascending' ? setPerfSortDirection('descending') : setPerfSortDirection('ascending')
              }
            }}>Store</Table.HeaderCell>
          <Table.HeaderCell
            sorted={perfSortColumn === timeframe + '_TOT_APPS' ? perfSortDirection : null}
            onClick={() => {
              if (perfSortColumn !== timeframe + '_TOT_APPS') {
                setPerfSortColumn(timeframe + '_TOT_APPS')
                setPerfSortDirection('ascending')
              } else {
                perfSortDirection === 'ascending' ? setPerfSortDirection('descending') : setPerfSortDirection('ascending')
              }
            }}>Apps</Table.HeaderCell>
          <Table.HeaderCell
            sorted={perfSortColumn === timeframe + '_TOT_VG' ? perfSortDirection : null}
            onClick={() => {
              if (perfSortColumn !== timeframe + '_TOT_VG') {
                setPerfSortColumn(timeframe + '_TOT_VG')
                setPerfSortDirection('ascending')
              } else {
                perfSortDirection === 'ascending' ? setPerfSortDirection('descending') : setPerfSortDirection('ascending')
              }
            }}>% vGoal</Table.HeaderCell>
          <Table.HeaderCell
            sorted={perfSortColumn === timeframe + '_TOT_APP_EFF' ? perfSortDirection : null}
            onClick={() => {
              if (perfSortColumn !== timeframe + '_TOT_APP_EFF') {
                setPerfSortColumn(timeframe + '_TOT_APP_EFF')
                setPerfSortDirection('ascending')
              } else {
                perfSortDirection === 'ascending' ? setPerfSortDirection('descending') : setPerfSortDirection('ascending')
              }
            }}>App Efficiency</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {
          perfDataSorted.map((data) => {
            return (
              <Table.Row>
                <Table.Cell>
                  <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/credit?strNbr=${data['STR_NBR']}`}>STR {data['STR_NBR']}</Link>
                </Table.Cell>
                <Table.Cell>{data[timeframe + '_TOT_APPS']}</Table.Cell>
                <Table.Cell
                  style={getCompareStyle(data[timeframe + '_TOT_APPS'] / data[timeframe + '_TOT_APP_GOAL'] - 1)}
                >{getPercentString(data[timeframe + '_TOT_APPS'] / data[timeframe + '_TOT_APP_GOAL'] - 1)}</Table.Cell>
                <Table.Cell>{(data[timeframe + '_TXN_COUNT'] / data[timeframe + '_TOT_APPS']).toLocaleString(undefined, { maximumFractionDigits: 0 })}</Table.Cell>
              </Table.Row>
            )
          })
        }
      </Table.Body>
    </Table>
    <Header textAlign='center'>Usage by Store</Header>
    <Table textAlign='center' celled unstackable sortable size='small' style={{ fontSize: 12 }}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            sorted={slsPenSortColumn === 'STR_NBR' ? slsPenSortDirection : null}
            onClick={() => {
              if (slsPenSortColumn !== 'STR_NBR') {
                setSlsPenSortColumn('STR_NBR')
                setSlsPenSortDirection('ascending')
              } else {
                slsPenSortDirection === 'ascending' ? setSlsPenSortDirection('descending') : setSlsPenSortDirection('ascending')
              }
            }}>Store</Table.HeaderCell>
          <Table.HeaderCell
            sorted={slsPenSortColumn === timeframe + '_CRED_SLS_AMT' ? slsPenSortDirection : null}
            onClick={() => {
              if (slsPenSortColumn !== timeframe + '_CRED_SLS_AMT') {
                setSlsPenSortColumn(timeframe + '_CRED_SLS_AMT')
                setSlsPenSortDirection('ascending')
              } else {
                slsPenSortDirection === 'ascending' ? setSlsPenSortDirection('descending') : setSlsPenSortDirection('ascending')
              }
            }}>Credit Sales</Table.HeaderCell>
          <Table.HeaderCell
            sorted={slsPenSortColumn === timeframe + '_TOT_USG' ? slsPenSortDirection : null}
            onClick={() => {
              if (slsPenSortColumn !== timeframe + '_TOT_USG') {
                setSlsPenSortColumn(timeframe + '_TOT_USG')
                setSlsPenSortDirection('ascending')
              } else {
                slsPenSortDirection === 'ascending' ? setSlsPenSortDirection('descending') : setSlsPenSortDirection('ascending')
              }
            }}>Usage</Table.HeaderCell>
          <Table.HeaderCell
            sorted={slsPenSortColumn === timeframe + '_TOT_VP' ? slsPenSortDirection : null}
            onClick={() => {
              if (slsPenSortColumn !== timeframe + '_TOT_VP') {
                setSlsPenSortColumn(timeframe + '_TOT_VP')
                setSlsPenSortDirection('ascending')
              } else {
                slsPenSortDirection === 'ascending' ? setSlsPenSortDirection('descending') : setSlsPenSortDirection('ascending')
              }
            }}>bps vPlan</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {
          slsPenDataSorted.map((data) => {
            return (
              <Table.Row>
                <Table.Cell>
                  <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/credit?strNbr=${data['STR_NBR']}`}>STR {data['STR_NBR']}</Link>
                </Table.Cell>
                <Table.Cell>{getThousandDollarString(data[timeframe + '_CRED_SLS_AMT'])}</Table.Cell>
                <Table.Cell>{getPercentString(data[timeframe + '_CRED_SLS_AMT'] / data[timeframe + '_TOT_SLS_AMT'])}</Table.Cell>
                <Table.Cell
                  style={getCompareStyle(data[timeframe + '_CRED_SLS_AMT'] / data[timeframe + '_TOT_SLS_AMT'] - data[timeframe + '_TOT_CRED_SLS_PLN'] / data[timeframe + '_TOT_SLS_PLN'])}
                >{getBpsString(data[timeframe + '_CRED_SLS_AMT'] / data[timeframe + '_TOT_SLS_AMT'] - data[timeframe + '_TOT_CRED_SLS_PLN'] / data[timeframe + '_TOT_SLS_PLN'])}</Table.Cell>
              </Table.Row>
            )
          })
        }
      </Table.Body>
    </Table>
  </Tab.Pane>

  const consumerView = <Tab.Pane>
    <Header textAlign='center'>Applications by Store</Header>
    <Table textAlign='center' celled unstackable sortable size='small' style={{ fontSize: 12 }}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            sorted={perfSortColumn === 'STR_NBR' ? perfSortDirection : null}
            onClick={() => {
              if (perfSortColumn !== 'STR_NBR') {
                setPerfSortColumn('STR_NBR')
                setPerfSortDirection('ascending')
              } else {
                perfSortDirection === 'ascending' ? setPerfSortDirection('descending') : setPerfSortDirection('ascending')
              }
            }}>Store</Table.HeaderCell>
          <Table.HeaderCell
            sorted={perfSortColumn === timeframe + '_CONS_APPS' ? perfSortDirection : null}
            onClick={() => {
              if (perfSortColumn !== timeframe + '_CONS_APPS') {
                setPerfSortColumn(timeframe + '_CONS_APPS')
                setPerfSortDirection('ascending')
              } else {
                perfSortDirection === 'ascending' ? setPerfSortDirection('descending') : setPerfSortDirection('ascending')
              }
            }}>Apps</Table.HeaderCell>
          <Table.HeaderCell
            sorted={perfSortColumn === timeframe + '_CONS_VG' ? perfSortDirection : null}
            onClick={() => {
              if (perfSortColumn !== timeframe + '_CONS_VG') {
                setPerfSortColumn(timeframe + '_CONS_VG')
                setPerfSortDirection('ascending')
              } else {
                perfSortDirection === 'ascending' ? setPerfSortDirection('descending') : setPerfSortDirection('ascending')
              }
            }}>% vGoal</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {
          perfDataSorted.map((data) => {
            return (
              <Table.Row>
                <Table.Cell>
                  <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/credit?strNbr=${data['STR_NBR']}`}>STR {data['STR_NBR']}</Link>
                </Table.Cell>
                <Table.Cell>{data[timeframe + '_CONS_APPS']}</Table.Cell>
                <Table.Cell
                  style={getCompareStyle(data[timeframe + '_CONS_APPS'] / data[timeframe + '_CONS_APP_GOAL'] - 1)}
                >{getPercentString(data[timeframe + '_CONS_APPS'] / data[timeframe + '_CONS_APP_GOAL'] - 1)}</Table.Cell>
              </Table.Row>
            )
          })
        }
      </Table.Body>
    </Table>
    <Header textAlign='center'>Usage by Store</Header>
    <Table textAlign='center' celled unstackable sortable size='small' style={{ fontSize: 12 }}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            sorted={slsPenSortColumn === 'STR_NBR' ? slsPenSortDirection : null}
            onClick={() => {
              if (slsPenSortColumn !== 'STR_NBR') {
                setSlsPenSortColumn('STR_NBR')
                setSlsPenSortDirection('ascending')
              } else {
                slsPenSortDirection === 'ascending' ? setSlsPenSortDirection('descending') : setSlsPenSortDirection('ascending')
              }
            }}>Store</Table.HeaderCell>
          <Table.HeaderCell
            sorted={slsPenSortColumn === timeframe + '_CONS_SLS_AMT' ? slsPenSortDirection : null}
            onClick={() => {
              if (slsPenSortColumn !== timeframe + '_CONS_SLS_AMT') {
                setSlsPenSortColumn(timeframe + '_CONS_SLS_AMT')
                setSlsPenSortDirection('ascending')
              } else {
                slsPenSortDirection === 'ascending' ? setSlsPenSortDirection('descending') : setSlsPenSortDirection('ascending')
              }
            }}>Credit Sales</Table.HeaderCell>
          <Table.HeaderCell
            sorted={slsPenSortColumn === timeframe + '_CONS_USG' ? slsPenSortDirection : null}
            onClick={() => {
              if (slsPenSortColumn !== timeframe + '_CONS_USG') {
                setSlsPenSortColumn(timeframe + '_CONS_USG')
                setSlsPenSortDirection('ascending')
              } else {
                slsPenSortDirection === 'ascending' ? setSlsPenSortDirection('descending') : setSlsPenSortDirection('ascending')
              }
            }}>Usage</Table.HeaderCell>
          <Table.HeaderCell
            sorted={slsPenSortColumn === timeframe + '_CONS_VP' ? slsPenSortDirection : null}
            onClick={() => {
              if (slsPenSortColumn !== timeframe + '_CONS_VP') {
                setSlsPenSortColumn(timeframe + '_CONS_VP')
                setSlsPenSortDirection('ascending')
              } else {
                slsPenSortDirection === 'ascending' ? setSlsPenSortDirection('descending') : setSlsPenSortDirection('ascending')
              }
            }}>bps vPlan</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {
          slsPenDataSorted.map((data) => {
            return (
              <Table.Row>
                <Table.Cell>
                  <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/credit?strNbr=${data['STR_NBR']}`}>STR {data['STR_NBR']}</Link>
                </Table.Cell>
                <Table.Cell>{getThousandDollarString(data[timeframe + '_CONS_SLS_AMT'])}</Table.Cell>
                <Table.Cell>{getPercentString(data[timeframe + '_CONS_SLS_AMT'] / data[timeframe + '_TOT_SLS_AMT'])}</Table.Cell>
                <Table.Cell
                  style={getCompareStyle(data[timeframe + '_CONS_SLS_AMT'] / data[timeframe + '_TOT_SLS_AMT'] - data[timeframe + '_CONS_CRED_SLS_PLN'] / data[timeframe + '_TOT_SLS_PLN'])}
                >{getBpsString(data[timeframe + '_CONS_SLS_AMT'] / data[timeframe + '_TOT_SLS_AMT'] - data[timeframe + '_CONS_CRED_SLS_PLN'] / data[timeframe + '_TOT_SLS_PLN'])}</Table.Cell>
              </Table.Row>
            )
          })
        }
      </Table.Body>
    </Table>
  </Tab.Pane>

  const commercialView = <Tab.Pane>
    <Header textAlign='center'>Applications by Store</Header>
    <Table textAlign='center' celled unstackable sortable size='small' style={{ fontSize: 12 }}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            sorted={perfSortColumn === 'STR_NBR' ? perfSortDirection : null}
            onClick={() => {
              if (perfSortColumn !== 'STR_NBR') {
                setPerfSortColumn('STR_NBR')
                setPerfSortDirection('ascending')
              } else {
                perfSortDirection === 'ascending' ? setPerfSortDirection('descending') : setPerfSortDirection('ascending')
              }
            }}>Store</Table.HeaderCell>
          <Table.HeaderCell
            sorted={perfSortColumn === timeframe + '_COMM_APPS' ? perfSortDirection : null}
            onClick={() => {
              if (perfSortColumn !== timeframe + '_COMM_APPS') {
                setPerfSortColumn(timeframe + '_COMM_APPS')
                setPerfSortDirection('ascending')
              } else {
                perfSortDirection === 'ascending' ? setPerfSortDirection('descending') : setPerfSortDirection('ascending')
              }
            }}>Apps</Table.HeaderCell>
          <Table.HeaderCell
            sorted={perfSortColumn === timeframe + '_COMM_VG' ? perfSortDirection : null}
            onClick={() => {
              if (perfSortColumn !== timeframe + '_COMM_VG') {
                setPerfSortColumn(timeframe + '_COMM_VG')
                setPerfSortDirection('ascending')
              } else {
                perfSortDirection === 'ascending' ? setPerfSortDirection('descending') : setPerfSortDirection('ascending')
              }
            }}>% vGoal</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {
          perfDataSorted.map((data) => {
            return (
              <Table.Row>
                <Table.Cell>
                  <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/credit?strNbr=${data['STR_NBR']}`}>STR {data['STR_NBR']}</Link>
                </Table.Cell>
                <Table.Cell>{data[timeframe + '_COMM_APPS']}</Table.Cell>
                <Table.Cell
                  style={getCompareStyle(data[timeframe + '_COMM_APPS'] / data[timeframe + '_COMM_APP_GOAL'] - 1)}
                >{getPercentString(data[timeframe + '_COMM_APPS'] / data[timeframe + '_COMM_APP_GOAL'] - 1)}</Table.Cell>
              </Table.Row>
            )
          })
        }
      </Table.Body>
    </Table>
    <Header textAlign='center'>Usage by Store</Header>
    <Table textAlign='center' celled unstackable sortable size='small' style={{ fontSize: 12 }}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            sorted={slsPenSortColumn === 'STR_NBR' ? slsPenSortDirection : null}
            onClick={() => {
              if (slsPenSortColumn !== 'STR_NBR') {
                setSlsPenSortColumn('STR_NBR')
                setSlsPenSortDirection('ascending')
              } else {
                slsPenSortDirection === 'ascending' ? setSlsPenSortDirection('descending') : setSlsPenSortDirection('ascending')
              }
            }}>Store</Table.HeaderCell>
          <Table.HeaderCell
            sorted={slsPenSortColumn === timeframe + '_COMM_SLS_AMT' ? slsPenSortDirection : null}
            onClick={() => {
              if (slsPenSortColumn !== timeframe + '_COMM_SLS_AMT') {
                setSlsPenSortColumn(timeframe + '_COMM_SLS_AMT')
                setSlsPenSortDirection('ascending')
              } else {
                slsPenSortDirection === 'ascending' ? setSlsPenSortDirection('descending') : setSlsPenSortDirection('ascending')
              }
            }}>Credit Sales</Table.HeaderCell>
          <Table.HeaderCell
            sorted={slsPenSortColumn === timeframe + '_COMM_USG' ? slsPenSortDirection : null}
            onClick={() => {
              if (slsPenSortColumn !== timeframe + '_COMM_USG') {
                setSlsPenSortColumn(timeframe + '_COMM_USG')
                setSlsPenSortDirection('ascending')
              } else {
                slsPenSortDirection === 'ascending' ? setSlsPenSortDirection('descending') : setSlsPenSortDirection('ascending')
              }
            }}>Usage</Table.HeaderCell>
          <Table.HeaderCell
            sorted={slsPenSortColumn === timeframe + '_COMM_VP' ? slsPenSortDirection : null}
            onClick={() => {
              if (slsPenSortColumn !== timeframe + '_COMM_VP') {
                setSlsPenSortColumn(timeframe + '_COMM_VP')
                setSlsPenSortDirection('ascending')
              } else {
                slsPenSortDirection === 'ascending' ? setSlsPenSortDirection('descending') : setSlsPenSortDirection('ascending')
              }
            }}>bps vPlan</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {
          slsPenDataSorted.map((data) => {
            return (
              <Table.Row>
                <Table.Cell>
                  <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/credit?strNbr=${data['STR_NBR']}`}>STR {data['STR_NBR']}</Link>
                </Table.Cell>
                <Table.Cell>{getThousandDollarString(data[timeframe + '_COMM_SLS_AMT'])}</Table.Cell>
                <Table.Cell>{getPercentString(data[timeframe + '_COMM_SLS_AMT'] / data[timeframe + '_TOT_SLS_AMT'])}</Table.Cell>
                <Table.Cell
                  style={getCompareStyle(data[timeframe + '_COMM_SLS_AMT'] / data[timeframe + '_TOT_SLS_AMT'] - data[timeframe + '_COMM_CRED_SLS_PLN'] / data[timeframe + '_TOT_SLS_PLN'])}
                >{getBpsString(data[timeframe + '_COMM_SLS_AMT'] / data[timeframe + '_TOT_SLS_AMT'] - data[timeframe + '_COMM_CRED_SLS_PLN'] / data[timeframe + '_TOT_SLS_PLN'])}</Table.Cell>
              </Table.Row>
            )
          })
        }
      </Table.Body>
    </Table>
  </Tab.Pane>

  // Set up renders for each of the two department view options: Sales and Comp
  const viewOption = [
    {
      menuItem: 'Total',
      render: () => totalView
    },

    {
      menuItem: 'Consumer',
      render: () => consumerView,
    },

    {
      menuItem: 'Commercial',
      render: () => commercialView,
    },
  ]

  return <Tab panes={viewOption} />
})