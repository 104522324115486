
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import React, { useState } from 'react'
import { UnitMenu } from '../Common/UnitMenu'
import { DataTable } from '../Common/DataTable'
import { getData } from '../../service/DataService'
import { timeframeCode } from '../Common/timeframeCode'
import { Header, Loader, Grid } from 'semantic-ui-react'
import { useQueryParams } from '../../hooks/useQueryParams'
import { TimeframeDropdown } from '../Common/TimeframeDropdown'
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon'

const filterByTimeframe = (row, timeframe, timeframeCode) => {
  return row && row['TRNSFM_TYP_SORT_ID'] === timeframeCode[timeframe];
};

export const AisleDrilldown = ({ timeframe, settimeframe, fpToggle }) => {
  const [unit, setUnit] = useState('NOM')
  const { strNbr = 0, aisle = 0 } = useQueryParams(); 
  const { data, isError, isLoading } = useQuery(['querySwmLocBayDtl', { strNbr, aisle, fpToggle }], getData)

  if (isLoading) return <Loader active>Loading...</Loader>;
  if (isError) return <p>Something went wrong fetching data.</p>;

  const bayDataFilter = data.filter(row => filterByTimeframe(row, timeframe, timeframeCode));

  const dataObj = {
    sortable: true,
    headers: [
      { name: 'Bay ' },
      { name: 'Sales Amt ' },
      { name: unit === 'NOM' ? 'Sales Comp $ ' : 'Sales Comp % ' },
      { name: 'Unit Sales ' },
      { name: unit === 'NOM' ? 'Unit Comp # ' : 'Unit Comp % ' },
    ],
    data: bayDataFilter.map(row => {
      const rowData = [
        {
          stringValue: row['BAY'],
          link: {
            to: `/store/aisle/bay`,
            params: {
              strNbr: strNbr,
              aisle: aisle,
              bay: row['BAY'],
            },
          },
        },
        {
          dollarValue: row['SLS_AMT'],
          customStyle: { color: row['SLS_AMT'] < 0 ? 'red' : 'black' },
          decimals: 2,
        },
        {
          dollarValue: unit === 'NOM' ? row['SLS_COMP_NOM'] : null,
          pctValue: unit === 'PCT' ? row['SLS_COMP_PCT'] : null,
          compareStyle: true,
          decimals: 2,
        },
        {
          numValue: row['UNT_SLS'],
          customStyle: { color: row['UNT_SLS'] < 0 ? 'red' : 'black' },
        },
        {
          numValue: unit === 'NOM' ? row['UNT_COMP_NOM'] : undefined,
          pctValue: unit === 'PCT' ? row['UNT_COMP_PCT'] : undefined,
          compareStyle: true,
        },
      ];
      return rowData;
    })
  };

  return (
    <>
      <Header textAlign='center'>Aisle {aisle}</Header>

      <Grid>
        <Grid.Column width={8}>
          <Grid.Row><Link style={{ color: '#EE7125' }} to={`/store/overview?strNbr=${strNbr}`} data-test='b2o-link'>Back to Overview</Link></Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
          <Grid.Row>Units:</Grid.Row>
          <Grid.Row>
            <UnitMenu unit={unit} setUnit={setUnit} />
          </Grid.Row>

        </Grid.Column>
        <Grid.Column width={8}>
          <Grid.Row>&nbsp;</Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
          <Grid.Row>Timeframe:</Grid.Row>
          <Grid.Row>
            {new Date().getDay() === 1 ?
              <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} /> :
              <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />}
          </Grid.Row>
        </Grid.Column>
      </Grid>

      <Grid>
        <Grid.Row>
          <Grid.Column width={16} textAlign='center'>
            <Header textAlign='center'>All Bays</Header>
            <DataTable data={dataObj} />

          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
}
