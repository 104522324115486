import React, {useState} from 'react'
import {
    Grid,
    Header,
    Loader,
    Menu,
  } from 'semantic-ui-react'
import { withRouter, Link } from "react-router-dom/cjs/react-router-dom.min";
import { useQuery } from 'react-query'
import { TimeframeDropdown } from '../Common/TimeframeDropdown'
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon'
import { DataTable } from '../Common/DataTable'

import {
    getData
  } from '../../service/DataService'

import {IMAGING_GOAL} from './OSAImaging'


export const OSAImagingDept = withRouter(({ timeframe, settimeframe, level, fpToggle, locNbr, location }) => {

  const search = new URLSearchParams(location.search)
  const deptNbrInt = search.has('deptNbr') ? search.get('deptNbr') : 0

  const [aisleBayTab, setaisleBayTab] = useState("Aisle")

  const osaImagingQuery = useQuery([`query/osaImageCapture/${level}`, { locNbr, fpToggle }], getData)
  if (osaImagingQuery.isLoading) {
    return (<Loader active>Loading</Loader>)
  }
  if (!osaImagingQuery.data || osaImagingQuery.data.length === 0) {
    return (<Header textAlign='center'>Invalid Store Number</Header>)
  }

  const lastTimeframeMap = {
    'WTD': 'LW',
    'LW': null, // We don't have comparisons for last week or month, so they'll be null
    'LM': null,
    'MTD': 'LM',
    'QTD': 'LQ',
    'HTD': 'LH',
    'YTD': 'YTD_LY',
    'L12': 'L12_LY',
  }

  const ltf = lastTimeframeMap[timeframe]
  let dept_nm = ''

  console.log(osaImagingQuery.data.filter(({MER_DEPT_NBR})  => !MER_DEPT_NBR))

  const aislesummary = osaImagingQuery.data
  .filter(({MER_DEPT_NBR}) => MER_DEPT_NBR && MER_DEPT_NBR.toString() === deptNbrInt)
  .reduce((acc, row) => {
    if (!acc[row.AISLE]) {
      acc[row.AISLE] = {actual: 0, goal: 0, lastActual: 0}
    }
    acc[row.AISLE].actual += row[`${timeframe}_IMAGES_TAKEN`]
    acc[row.AISLE].goal += row[`${timeframe}_IMAGES_EXPECTED`]
    if (ltf) { 
      acc[row.AISLE].lastActual += row[`${ltf}_IMAGES_TAKEN`]
    } else {
      acc[row.AISLE].lastActual = null
    }

    if (row.DEPT_NM) {
      dept_nm = row.DEPT_NM
    }

    return acc
  }, {})

  const aisleData = {
    sortable: true,
    headers: [{name: 'Aisle'},{name: 'Imaging %'},{name: ltf ? `v${ltf} bps` : '-'},],
    data: 
      Object.entries(aislesummary).map(([aisle, dat]) => {
        return [
          {
            stringValue: aisle,
            link: {
              to: '/osa/imaging/dept/aisle',
              params: {
                strNbr: locNbr,
                deptNbr: deptNbrInt,
                aisle: aisle,
              }
            }
          },
          {pctValue: dat.actual / dat.goal, decimals: 1, compareValue: IMAGING_GOAL},
          ltf ? {numValue: dat.actual - dat.lastActual, compareStyle: true, postfix: ' bps'} : {stringValue: '-'},
        ]
      })
  }

  const bayData = {
    sortable: true, pagination: true,
    headers: [{name: 'Bays'},{name: 'Image Needed'},{name: 'Last Imaged'},{name: 'Days Old'}],
    data: 
    osaImagingQuery.data
    .filter(row => row.MER_DEPT_NBR && row.MER_DEPT_NBR.toString() === deptNbrInt && row[`${timeframe}_IMAGES_EXPECTED`] > 0)
    .map(({AISLE_BAY, IMAGE_DAY_AGE, LAST_IMAGED_DT}) => {
        return [
          {stringValue: AISLE_BAY},
          {
            stringValue: IMAGE_DAY_AGE < 7 && IMAGE_DAY_AGE != null ? 'NO' : 'YES',
            customStyle: {color: IMAGE_DAY_AGE < 7 && IMAGE_DAY_AGE != null ? 'green' : 'red'}
          },
          {stringValue: LAST_IMAGED_DT},
          {stringValue: IMAGE_DAY_AGE, compareValue: 7, compareStyleInvert: true},
        ]
      })
  }

  return (
    <>
    <Grid>
      <Grid.Column width={8}>
        <Grid.Row>
          <Link style={{ color: '#EE7125' }} to={`/${level}/overview?strNbr=${locNbr}`} data-test='b2o-link'>Overview</Link>
          {' / '}
          <Link style={{ color: '#EE7125' }} to={`/osa?strNbr=${locNbr}`} data-test='b2osa-link'>OSA</Link>
          {' / '}
          <Link style={{ color: '#EE7125' }} to={`/osa/store/imaging?strNbr=${locNbr}`} data-test='b2osa-link'>Imaging%</Link>
          {` / D${deptNbrInt}`}
        </Grid.Row>
        <Grid.Row>&nbsp;</Grid.Row>
          </Grid.Column>
          <Grid.Column width={8}>
        Timeframe:
        {new Date().getDay() === 1 ?
          <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} /> :
          <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />}
      </Grid.Column>
    </Grid>
    <Header textAlign='center' >{`${deptNbrInt} ${dept_nm}`}</Header>
    <Menu compact>
      <Menu.Item
        name='Aisle'
        active={aisleBayTab === 'Aisle'}
        content='Aisle'
        onClick={() => {
          setaisleBayTab('Aisle')
        }}
      />
      <Menu.Item
        name='Bay'
        active={aisleBayTab === 'Bay'}
        content='Bay'
        onClick={() => {
          setaisleBayTab('Bay')
        }}
      />
    </Menu>
    <DataTable data={aisleBayTab === 'Aisle' ? aisleData : bayData} />
    </>
)
})