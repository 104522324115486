import { useCallback, useEffect, useState } from "react";

export const ACTIVE_SUGGESTION_INITIAL_VALUE = -1;

export const useSuggestions = ({ onEscapeAction, onSelectAction, suggestions }) => {
  const [activeSuggestion, setActiveSuggestion] = useState(ACTIVE_SUGGESTION_INITIAL_VALUE);
  const suggestionsFormatted = suggestions.map(({ name, number }) => ({ number, value: `${number} - ${name}` }));
  const suggestionsCount = suggestionsFormatted.length;

  const goUp = useCallback(
    () => setActiveSuggestion(prev => (prev > ACTIVE_SUGGESTION_INITIAL_VALUE ? prev - 1 : suggestionsCount - 1)),
    [suggestionsCount]
  );

  const goDown = useCallback(
    () => setActiveSuggestion(prev => (prev < suggestionsCount - 1 ? prev + 1 : ACTIVE_SUGGESTION_INITIAL_VALUE)),
    [suggestionsCount]
  );

  const onEscape = useCallback(() => {
    if (onEscapeAction) onEscapeAction()
  }, [onEscapeAction]);

  const onSelectOption = useCallback(
    value => {
      const suggestionSelected = suggestionsFormatted[activeSuggestion];
      if (onSelectAction) onSelectAction(value || suggestionSelected || { number: ACTIVE_SUGGESTION_INITIAL_VALUE });
    },
    [activeSuggestion, onSelectAction, suggestionsFormatted]
  );

  useEffect(() => {
    const SUGGESTIONS_ACTIONS = { Enter: onSelectOption, Escape: onEscape, ArrowDown: goDown, ArrowUp: goUp };

    const handleKeyDown = e => {
      if (SUGGESTIONS_ACTIONS[e.key]) {
        e.preventDefault();
        SUGGESTIONS_ACTIONS[e.key]();
      }
    };

    window?.addEventListener('keydown', handleKeyDown);
    return () => {
      window?.removeEventListener('keydown', handleKeyDown);
    };
  }, [onEscape, onSelectOption, goDown, goUp]);

  return { activeSuggestion, suggestionsFormatted };
}
