import { Header } from 'semantic-ui-react';
import { DataTable } from '../../../../../components/Common/DataTable';
import { DataView } from '../../../../../components/Common/DataView';

export const WtdSales = ({ wtdSalesData, dailySalesData, dailySalesTitle = 'Sales' }) => (
  <>
    <Header textAlign='center' style={{ textTransform: 'capitalize' }}>
      WTD Sales
    </Header>
    <DataTable data={wtdSalesData} />

    <DataView className='mt-4' key={'dailySalesView'} testId={'dailySalesView'}>
      <DataView.Title position={'center'}>Daily {dailySalesTitle}</DataView.Title>
      <DataView.DataTable data={dailySalesData} />
    </DataView>
  </>
);
