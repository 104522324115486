import { capitalizeFirstLetter } from '../../../../../../utils/string';
import { OSA_OHM_NO_LOC_TAGS_GOAL } from '../../../../../../constants/goals';

const TRNSFM_DT = 'TRNSFM_DT';
const ACTUAL = 'ACTUAL';
const BPS = 'BPS';
const _NO_LOC_PALLET_COUNT = '_NO_LOC_PALLET_COUNT';
const _TOTAL_PALLET_COUNT = '_TOTAL_PALLET_COUNT';
const _LY_NO_LOC_PALLET_COUNT = '_LY_NO_LOC_PALLET_COUNT';
const _LY_TOTAL_PALLET_COUNT = '_LY_TOTAL_PALLET_COUNT';

export const getTransformDate = ({ osaOhmNoLocTagsByCoreData }) => osaOhmNoLocTagsByCoreData[0][TRNSFM_DT];

// BreadCrumbs

export const getBreadcrumbItems = ({ level }) => [
  { to: `/${level}/overview`, text: 'Overview' },
  { to: `/${level}/osa`, text: 'OSA' },
  { text: 'OHM No Loc Tags' }
];

// OHM No Loc Tags Table

const getOHMNoLocationCompareStyle = value => ({
  color: value > OSA_OHM_NO_LOC_TAGS_GOAL ? 'red' : 'green'
});

const getBPSValue = (actual, bps) =>
  bps === '-' || isNaN(bps) ? '-' : ((actual - bps) * 10000).toLocaleString(undefined, { maximumFractionDigits: 0 });

const getOhmNoLocTagsDataFormatted = ({ osaOhmNoLocTagsByCoreData, subLevel, timeframe, ltf }) => {
  const { currentLevel: subCurrentLevel } = subLevel;
  const noLocPalletCount = `${timeframe}${_NO_LOC_PALLET_COUNT}`;
  const totalPalletCount = `${timeframe}${_TOTAL_PALLET_COUNT}`;
  const lyNoLocPalletCount = `${ltf}${_LY_NO_LOC_PALLET_COUNT}`;
  const lyTotalPalletCount = `${ltf}${_LY_TOTAL_PALLET_COUNT}`;

  return osaOhmNoLocTagsByCoreData.map(osaOhmNoLocTagsDataItem => ({
    [subCurrentLevel]: osaOhmNoLocTagsDataItem[subCurrentLevel],
    [noLocPalletCount]: osaOhmNoLocTagsDataItem[noLocPalletCount],
    [totalPalletCount]: osaOhmNoLocTagsDataItem[totalPalletCount],
    [ACTUAL]: osaOhmNoLocTagsDataItem[noLocPalletCount] / osaOhmNoLocTagsDataItem[totalPalletCount],
    [lyNoLocPalletCount]: osaOhmNoLocTagsDataItem[lyNoLocPalletCount],
    [lyTotalPalletCount]: osaOhmNoLocTagsDataItem[lyTotalPalletCount],
    [BPS]:
      osaOhmNoLocTagsDataItem[lyTotalPalletCount] !== 0
        ? osaOhmNoLocTagsDataItem[lyNoLocPalletCount] / osaOhmNoLocTagsDataItem[lyTotalPalletCount]
        : '-'
  }));
};

export const getOhmNoLocTagsData = ({ osaOhmNoLocTagsByCoreData, level, subLevel, timeframe, ltf }) => {
  const ohmNoLocTagsDataFormatted = getOhmNoLocTagsDataFormatted({ osaOhmNoLocTagsByCoreData, subLevel, timeframe, ltf });
  const { levelName: subLevelName, urlParam: subUrlParam, currentLevel: subCurrentLevel } = subLevel;

  return ohmNoLocTagsDataFormatted.map(row => [
    {
      link: {
        to: `/osa/ohmnoloc/${subLevelName}`,
        params: {
          [subUrlParam]: String(row[subCurrentLevel]).padStart(4, '0')
        }
      },
      stringValue: String(row[subCurrentLevel]).padStart(4, '0')
    },
    {
      pctValue: row[ACTUAL],
      decimals: 1,
      customStyle: getOHMNoLocationCompareStyle(row[ACTUAL])
    },
    {
      pctValue: OSA_OHM_NO_LOC_TAGS_GOAL
    },
    {
      stringValue: getBPSValue(row[ACTUAL], row[BPS])
    }
  ]);
};

const getOhmNoLocTagsHeaders = ({ subLevel, ltf }) => [
  { name: `${capitalizeFirstLetter(subLevel.levelName)} ` },
  { name: 'Actual ' },
  { name: 'Goal ' },
  { name: `v${ltf ?? ''} bps ` }
];

export const getOhmNoLocTagsDataTable = ({ osaOhmNoLocTagsByCoreData, level, subLevel, timeframe, ltf }) => ({
  headers: getOhmNoLocTagsHeaders({ subLevel, ltf }),
  data: getOhmNoLocTagsData({ osaOhmNoLocTagsByCoreData, level, subLevel, timeframe, ltf }),
  sortable: true,
  sortableDefault: 0,
  sortDirection: 'descending',
  pagination: true
});
