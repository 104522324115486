import React, { useState } from 'react';
import { Grid, Header, Loader } from 'semantic-ui-react';
import { DataTable } from '../Common/DataTable';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { TimeframeDropdown } from '../Common/TimeframeDropdown';
import { OSA_OHM_NO_LOC_TAGS_GOAL } from '../../constants/goals';
import { getLastTimeframe } from '../../utils/timeFrameUtils';
import useQueryApi from '../../hooks/useQueryApi';
import { METRIC_CONSTANTS } from '../../utils/constantsByLevel';
import { capitalizeFirstLetter } from '../../utils/string';

export const OHMNoLocationTagsLevel = ({ timeframe, settimeframe, level, fpToggle, locNbr, levelName }) => {
  const {
    data: ohmNoLocationTagsData,
    isError,
    isLoading
  } = useQueryApi(`query/osaOHMNoLocTagsPlusOne/${level}`, { locNbr, fpToggle });
  const capitalizeSubLevel = capitalizeFirstLetter(METRIC_CONSTANTS[level].urlLinkLevel);

  if (isError) return <p>Something went wrong.</p>;

  if (isLoading) {
    return <Loader active>Loading</Loader>;
  }

  if (!ohmNoLocationTagsData || ohmNoLocationTagsData.length === 0) {
    return <Header textAlign='center'>Invalid Store Number</Header>;
  }
  const tranformDate = ohmNoLocationTagsData[0].TRNSFM_DT;

  let overviewDstFilter = ohmNoLocationTagsData.filter(row => {
    return row['METRIC_LEVEL'] === 'DST'
      ? row['METRIC_LEVEL'] === 'DST'
      : row['METRIC_LEVEL'] === 'RGN'
        ? row['METRIC_LEVEL'] === 'RGN'
        : null;
  });

  const ltf = getLastTimeframe(timeframe);

  let ohmNoLocationFiltered = overviewDstFilter.reduce((acc, curr) => {
    let item = acc.find(item => (level === 'region' ? item.DST_NBR === curr.DST_NBR : item.RGN_NBR === curr.RGN_NBR));
    if (item) {
      item[METRIC_CONSTANTS[level]['propToRender']] = curr[METRIC_CONSTANTS[level]['propToRender']];
      item[timeframe + '_NO_LOC_PALLET_COUNT'] += curr[timeframe + '_NO_LOC_PALLET_COUNT'];
      item[timeframe + '_TOTAL_PALLET_COUNT'] += curr[timeframe + '_TOTAL_PALLET_COUNT'];
      item.ACTUAL = item[timeframe + '_NO_LOC_PALLET_COUNT'] / item[timeframe + '_TOTAL_PALLET_COUNT'];
      item[ltf + '_LY_NO_LOC_PALLET_COUNT'] += curr[ltf + '_LY_NO_LOC_PALLET_COUNT'];
      item[ltf + '_LY_TOTAL_PALLET_COUNT'] += curr[ltf + '_LY_TOTAL_PALLET_COUNT'];
      item.BPS =
        item[ltf + '_LY_TOTAL_PALLET_COUNT'] !== 0
          ? item[ltf + '_LY_NO_LOC_PALLET_COUNT'] / item[ltf + '_LY_TOTAL_PALLET_COUNT']
          : '-';
    } else {
      acc.push({
        DST_NBR: curr.DST_NBR,
        RGN_NBR: curr.RGN_NBR,
        [timeframe + '_NO_LOC_PALLET_COUNT']: curr[timeframe + '_NO_LOC_PALLET_COUNT'],
        [timeframe + '_TOTAL_PALLET_COUNT']: curr[timeframe + '_TOTAL_PALLET_COUNT'],
        ACTUAL: curr[timeframe + '_NO_LOC_PALLET_COUNT'] / curr[timeframe + '_TOTAL_PALLET_COUNT'],
        [ltf + '_LY_NO_LOC_PALLET_COUNT']: curr[ltf + '_LY_NO_LOC_PALLET_COUNT'],
        [ltf + '_LY_TOTAL_PALLET_COUNT']: curr[ltf + '_LY_TOTAL_PALLET_COUNT'],
        BPS:
          curr[ltf + '_LY_TOTAL_PALLET_COUNT'] !== 0
            ? curr[ltf + '_LY_NO_LOC_PALLET_COUNT'] / curr[ltf + '_LY_TOTAL_PALLET_COUNT']
            : '-'
      }
      );
    }
    return acc;
  }, []);

  const getOHMNoLocationCompareStyle = value => {
    return { color: value > OSA_OHM_NO_LOC_TAGS_GOAL ? 'red' : 'green' };
  };
  const getBPSValue = (actual, bps) => {
    const result = {
      value:
        bps === '-' || isNaN(bps) ? '-' : ((actual - bps) * 10000).toLocaleString(undefined, { maximumFractionDigits: 0 })
    };
    return result.value;
  };
  const dataObj = {
    sortable: true,
    sortableDefault: 0,
    sortDirection: 'descending',
    pagination: true,
    headers: [
      { name: `${capitalizeSubLevel} ` },
      { name: 'Actual ' },
      { name: 'Goal ' },
      { name: ltf === null ? 'v bps ' : `v${ltf} bps ` }
    ],
    data: ohmNoLocationFiltered.map(row => [
      {
        link: {
          to: `/osa/ohmnoloc/${METRIC_CONSTANTS[level]['urlLinkLevel']}`,
          params: {
            [METRIC_CONSTANTS[level]['urlSubLevelParam']]: String(row[METRIC_CONSTANTS[level]['propToRender']]).padStart(
              4,
              '0'
            )
          }
        },
        stringValue: String(row[METRIC_CONSTANTS[level]['propToRender']]).padStart(4, '0')
      },
      {
        pctValue: row['ACTUAL'],
        decimals: 1,
        customStyle: getOHMNoLocationCompareStyle(row['ACTUAL'])
      },
      {
        pctValue: OSA_OHM_NO_LOC_TAGS_GOAL
      },
      {
        stringValue: getBPSValue(row['ACTUAL'], row['BPS'])
      }
    ])
  };
  return (
    <>
      <Grid>
        <Grid.Column width={8}>
          <Grid.Row>
            <Link style={{ color: '#EE7125' }} to={`/${level}/overview?dstNbr=${locNbr}`} data-test='b2o-link'>
              Overview
            </Link>
            {' / '}
            <Link style={{ color: '#EE7125' }} to={`/osa?dstNbr=${locNbr}`} data-test='b2osa-link'>
              OSA
            </Link>
            {` / OHM No Loc Tags`}
          </Grid.Row>
        </Grid.Column>
      </Grid>
      <Grid>
        <Grid.Column width={8} />
        <Grid.Column width={8}>
          Timeframe:
          <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
        </Grid.Column>
      </Grid>

      <Header textAlign='center'>
        OHM No Loc Tags %,{locNbr}, {levelName}
        {tranformDate && <Header.Subheader>Data as of {tranformDate}</Header.Subheader>}
      </Header>
      <DataTable data={dataObj} />
    </>
  );
};
