import { Popup, Icon } from 'semantic-ui-react';
import {
  initSummaryData,
  getSkuBayCompletedTasks,
  getSkuBayTaskGoal,
  isTaskOriginSidekick,
  increaseSidekickData,
  isTaskOriginManager,
  increaseManagerData,
  isTaskTypeLogged,
  getSkuPackDown,
  getCompletedTaskCount,
  getTotalTaskCount
} from '../../../../../../utils/osa';
import { capitalizeFirstLetter } from '../../../../../../utils/string';
import { OSA_SIDEKICK_SKUBAY_GOAL } from '../../../../../../constants/goals';

const increaseLastTimeframeData = ({ data, sidekickExeScoresPlusOneDataMap, row, ltf, subCurrentLevel, timeframe }) => {
  const exeData = sidekickExeScoresPlusOneDataMap[row[subCurrentLevel]];

  data.totalCompletedTasks += getSkuBayCompletedTasks(exeData, timeframe);
  data.totalTasksGoal += getSkuBayTaskGoal(exeData, timeframe);
  data.ttlCompLTF += getCompletedTaskCount(row, ltf);
  data.ttlTasksLTF += getTotalTaskCount(row, ltf);
  if (isTaskTypeLogged(row)) data.logSkusLTF += getSkuPackDown(row, ltf);
};

const getSidekickExeScoresPlusOneDataMap = ({ sidekickExeScoresPlusOneData, subCurrentLevel }) =>
  Object.fromEntries(sidekickExeScoresPlusOneData.map(row => [row[subCurrentLevel], row]));

export const getDirectedTaskSummary = ({
  osaSidekickSkuBayData,
  sidekickExeScoresPlusOneData,
  subLevel,
  timeframe,
  ltf
}) => {
  const subCurrentLevel = subLevel.currentLevel;

  return osaSidekickSkuBayData.reduce((acc, row) => {
    if (!acc[row[subCurrentLevel]]) acc[row[subCurrentLevel]] = initSummaryData();

    const sidekickExeScoresPlusOneDataMap = getSidekickExeScoresPlusOneDataMap({
      sidekickExeScoresPlusOneData,
      subCurrentLevel
    });
    const exeData = sidekickExeScoresPlusOneDataMap[row[subCurrentLevel]];
    acc[row[subCurrentLevel]].completedTasks = getSkuBayCompletedTasks(exeData, timeframe);
    acc[row[subCurrentLevel]].taskGoal = getSkuBayTaskGoal(exeData, timeframe);

    if (isTaskOriginSidekick(row)) increaseSidekickData(acc[row[subCurrentLevel]], row, timeframe);
    if (isTaskOriginManager(row)) increaseManagerData(acc[row[subCurrentLevel]], row, timeframe);
    if (isTaskTypeLogged(row)) acc[row[subCurrentLevel]].logSkus += getSkuPackDown(row, timeframe);
    if (ltf)
      increaseLastTimeframeData({
        data: acc[row[subCurrentLevel]],
        sidekickExeScoresPlusOneDataMap,
        row,
        ltf,
        subCurrentLevel,
        timeframe
      });

    return acc;
  }, {});
};

// BreadCrumbs

export const getBreadcrumbItems = ({ level }) => [
  { to: `/${level}/overview`, text: 'Overview' },
  { to: `/${level}/osa`, text: 'OSA' },
  { text: 'SKU/Bay' }
];

// Overview Table

const getOverviewData = ({ osaSidekickSkuBayData, sidekickExeScoresPlusOneData, subLevel, timeframe, ltf }) => {
  const subLevelName = subLevel.levelName;
  const subLevelUrlParam = subLevel.urlParam;
  const directedTaskSummary = getDirectedTaskSummary({
    osaSidekickSkuBayData,
    sidekickExeScoresPlusOneData,
    subLevel,
    timeframe,
    ltf
  });

  return Object.entries(directedTaskSummary)
    .map(([levelNbr, data]) => {
      const ltfValue =
        ltf && Math.round((data.totalCompletedTasks / data.totalTasksGoal - data.ttlCompLTF / data.ttlTasksLTF) * 10000);
      return [
        {
          stringValue: levelNbr.padStart(4, '0'),
          link: {
            to: `/osa/sidekickskubay/${subLevelName}`,
            params: {
              [subLevelUrlParam]: levelNbr
            }
          }
        },
        {
          compareValue: OSA_SIDEKICK_SKUBAY_GOAL,
          pctValue: data.completedTasks / (data.taskGoal || 1)
        },
        { pctValue: OSA_SIDEKICK_SKUBAY_GOAL, decimals: 1 },
        ltf
          ? {
              compareStyle: true,
              stringValue: ltfValue.toLocaleString(undefined, { maximumFractionDigits: 0 }),
              orderBy: ltfValue,
              postfix: ' bps'
            }
          : { stringValue: '-' }
      ];
    })
    .filter(row => row !== null); // Filter out any null rows
};

const getHeaders = ({ subLevel, ltf }) => [
  { name: `${capitalizeFirstLetter(subLevel.levelName)}s` },
  { name: 'SKU/Bay Execution Score' },
  { name: 'Goal' },
  { name: ltf ? `v${ltf} bps` : '-' }
];

export const getOverviewDataTable = ({ osaSidekickSkuBayData, sidekickExeScoresPlusOneData, subLevel, timeframe, ltf }) => ({
  headers: getHeaders({ subLevel, ltf }),
  sortable: true,
  sortableDefault: 0,
  data: getOverviewData({ osaSidekickSkuBayData, sidekickExeScoresPlusOneData, subLevel, timeframe, ltf })
});

// DIR TASKS Table

const getDirTasksDefaultHeaders = ({ subLevel }) => [
  { name: `${capitalizeFirstLetter(subLevel.levelName)}s ` },
  { name: 'Tasks Comp # ' },
  { name: 'Tasks Goal ' },
  {
    element: (
      <span>
        Execution Rate %
        <Popup
          on='click'
          position='bottom left'
          content='Execution Rate= Directed Tasks Completed/Directed Tasks Goal
          Directed Tasks Goals is an estimate of the number of tasks that can be done by the store given the labor hours.'
          trigger={<Icon name='info circle' color='blue' />}
        />
      </span>
    )
  },
  { name: 'Tasks Sent #' },
  { name: 'Tasks Goal %' }
];

const getDirTasksSkuHeaders = ({ subLevel, isSkuSelected }) => {
  const headerPrefix = isSkuSelected ? 'SKU' : 'Bay';

  return [
    { name: `${capitalizeFirstLetter(subLevel.levelName)}s ` },
    { name: `${headerPrefix} Tasks Comp #` },
    { name: `${headerPrefix} Tasks Sent #` },
    { name: `${headerPrefix} Tasks Comp %` }
  ];
};

const getDirTasksHeader = ({ isTotalSelected, subLevel, isSkuSelected }) =>
  isTotalSelected ? getDirTasksDefaultHeaders({ subLevel }) : getDirTasksSkuHeaders({ subLevel, isSkuSelected });

export const getDirTasksData = ({
  osaSidekickSkuBayData,
  sidekickExeScoresPlusOneData,
  subLevel,
  timeframe,
  ltf,
  isTotalSelected,
  isSkuSelected
}) => {
  const subLevelName = subLevel.levelName;
  const subLevelUrlParam = subLevel.urlParam;
  const directedTaskSummary = getDirectedTaskSummary({
    osaSidekickSkuBayData,
    sidekickExeScoresPlusOneData,
    subLevel,
    timeframe,
    ltf
  });

  return Object.entries(directedTaskSummary).map(([levelNbr, data]) => {
    const dataPrefix = isTotalSelected ? 'skuBay' : isSkuSelected ? 'sku' : 'bay';

    const linkObject = {
      stringValue: levelNbr.padStart(4, '0'),
      link: {
        to: `/osa/sidekickskubay/${subLevelName}`,
        params: {
          [subLevelUrlParam]: levelNbr
        }
      }
    };

    if (isTotalSelected)
      return [
        { ...linkObject },
        { stringValue: data.completedTasks.toLocaleString(undefined, { maximumFractionDigits: 0 }) },
        { stringValue: data.taskGoal.toLocaleString(undefined, { maximumFractionDigits: 0 }) },
        { pctValue: data.completedTasks / data.taskGoal, decimals: 1 },
        { stringValue: data[`${dataPrefix}Sent`].toLocaleString(undefined, { maximumFractionDigits: 0 }) },
        { pctValue: OSA_SIDEKICK_SKUBAY_GOAL }
      ];

    return [
      { ...linkObject },
      { stringValue: data[`${dataPrefix}Comp`].toLocaleString(undefined, { maximumFractionDigits: 0 }) },
      { stringValue: data[`${dataPrefix}Sent`].toLocaleString(undefined, { maximumFractionDigits: 0 }) },
      { pctValue: data[`${dataPrefix}Comp`] / data[`${dataPrefix}Sent`], decimals: 1 }
    ];
  });
};

export const getDirTasksDataTable = ({
  osaSidekickSkuBayData,
  sidekickExeScoresPlusOneData,
  subLevel,
  timeframe,
  ltf,
  isTotalSelected,
  isSkuSelected
}) => ({
  headers: getDirTasksHeader({ isTotalSelected, subLevel, isSkuSelected }),
  sortable: true,
  sortableColumn: 0,
  data: getDirTasksData({
    osaSidekickSkuBayData,
    sidekickExeScoresPlusOneData,
    subLevel,
    timeframe,
    ltf,
    isTotalSelected,
    isSkuSelected
  })
});

// MGR ADD Table

const getMgrAddData = ({ osaSidekickSkuBayData, sidekickExeScoresPlusOneData, subLevel, timeframe, ltf }) => {
  const subLevelName = subLevel.levelName;
  const subLevelUrlParam = subLevel.urlParam;
  const directedTaskSummary = getDirectedTaskSummary({
    osaSidekickSkuBayData,
    sidekickExeScoresPlusOneData,
    subLevel,
    timeframe,
    ltf
  });

  return Object.entries(directedTaskSummary).map(([levelNbr, data]) => {
    return [
      {
        stringValue: levelNbr.padStart(4, '0'),
        link: {
          to: `/osa/sidekickskubay/${subLevelName}`,
          params: {
            [subLevelUrlParam]: levelNbr
          }
        }
      },

      { stringValue: data.mgrComp.toLocaleString(undefined, { maximumFractionDigits: 0 }) },
      { stringValue: data.mgrSent.toLocaleString(undefined, { maximumFractionDigits: 0 }) },
      { pctValue: data.mgrComp / data.mgrSent, decimals: 1 }
    ];
  });
};

const getMgrAddHeader = ({ subLevel }) => [
  { name: `${capitalizeFirstLetter(subLevel.levelName)}s` },
  { name: 'Ttl Tasks Comp #' },
  { name: 'Ttl Tasks Added #' },
  { name: 'Ttl Tasks Comp %' }
];

export const getMgrAddDataTable = ({ osaSidekickSkuBayData, sidekickExeScoresPlusOneData, subLevel, timeframe, ltf }) => ({
  headers: getMgrAddHeader({ subLevel }),
  sortable: true,
  sortableDefault: 0,
  data: getMgrAddData({ osaSidekickSkuBayData, sidekickExeScoresPlusOneData, subLevel, timeframe, ltf })
});

// LOGGED WK Table

const getLoggedWkData = ({ osaSidekickSkuBayData, sidekickExeScoresPlusOneData, subLevel, timeframe, ltf }) => {
  const subLevelName = subLevel.levelName;
  const subLevelUrlParam = subLevel.urlParam;
  const directedTaskSummary = getDirectedTaskSummary({
    osaSidekickSkuBayData,
    sidekickExeScoresPlusOneData,
    subLevel,
    timeframe,
    ltf
  });

  return Object.entries(directedTaskSummary).map(([levelNbr, data]) => {
    return [
      {
        stringValue: levelNbr.padStart(4, '0'),
        link: {
          to: `/osa/sidekickskubay/${subLevelName}`,
          params: {
            [subLevelUrlParam]: levelNbr
          }
        }
      },
      { stringValue: data.logSkus.toLocaleString(undefined, { maximumFractionDigits: 0 }) },
      {
        stringValue: (data.logSkus - data.logSkusLTF).toLocaleString(undefined, { maximumFractionDigits: 0 }),
        compareStyle: true
      }
    ];
  });
};

const getLoggedWkHeader = ({ subLevel, ltf }) => [
  { name: `${capitalizeFirstLetter(subLevel.levelName)}s` },
  { name: 'Logged SKUs #' },
  { name: ltf ? `v${ltf} SKU#` : '-' }
];

export const getLoggedWkDataTable = ({ osaSidekickSkuBayData, sidekickExeScoresPlusOneData, subLevel, timeframe, ltf }) => ({
  headers: getLoggedWkHeader({ subLevel, ltf }),
  sortable: true,
  sortableDefault: 0,
  data: getLoggedWkData({ osaSidekickSkuBayData, sidekickExeScoresPlusOneData, subLevel, timeframe, ltf })
});
