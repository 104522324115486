import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { UnitMenu } from '../../Common/UnitMenu';
import { DataTable } from '../../Common/DataTable';
import useQueryApi from '../../../hooks/useQueryApi';
import { Loader, Header, Grid } from 'semantic-ui-react';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { TimeframeDropdown } from '../../Common/TimeframeDropdown';
import { capitalizeFirstLetter as capitalize } from '../../../utils/string';

const SMD_RSNCODE_QUERIES = {
  region: {
    endPoint: 'querySmdDstByRgnRsnCd'
  },
  division: {
    endPoint: 'querySmdRgnByDivRsnCd'
  }
};

export const SmdRgnRsn = ({ fpToggle, timeframe, settimeframe }) => {
  const [unit, setUnit] = useState('NOM');
  const { divNbr = 0, rgnNbr = 0, mkdnRsnCd = 0 } = useQueryParams();
  const level = divNbr ? 'division' : 'region';
  const orgNbr = level === 'division' ? divNbr : rgnNbr;
  const params = level === 'division' ? { locNbr: divNbr, mkdnRsnCd, fpToggle } : { locNbr: rgnNbr, mkdnRsnCd, fpToggle };
  const { data, isError, isLoading } = useQueryApi(SMD_RSNCODE_QUERIES[level].endPoint, params);

  if (isError) return <p>Something went wrong.</p>;
  if (isLoading) return <Loader active>Loading...</Loader>;

  const dataObj = {
    sortable: true,
    sortableDefault: 0,
    headers: [{ name: level === 'division' ? 'Region #' : 'District #' }, { name: 'SMD ' }, { name: 'vLY ' }],
    data: data.map(row => [
      {
        stringValue: level === 'division' ? row['RGN_NBR'] : row['DST_NBR'],
        link: {
          to: level === 'division' ? '/region/smd/reason' : '/district/smd/reason',
          params:
            level === 'division'
              ? {
                  rgnNbr: row['RGN_NBR'],
                  mkdnRsnCd: row['MKDN_RSN_CD']
                }
              : {
                  dstNbr: row['DST_NBR'],
                  mkdnRsnCd: row['MKDN_RSN_CD']
                }
        }
      },
      unit === 'NOM'
        ? {
            dollarValue: row[timeframe + '_SMD'],
            decimals: 2
          }
        : {
            pctValue: row[timeframe + '_SMD_PCT'],
            decimals: 1
          },
      unit === 'NOM'
        ? {
            dollarValue: row[timeframe + '_SMD_VLY_DOL'],
            compareStyle: true,
            compareStyleInvert: true
          }
        : {
            numValue: row[timeframe + '_SMD_VLY_BPS'],
            decimals: 0,
            postfix: ' bps',
            compareStyleInvert: true,
            compareStyle: true
          }
    ])
  };

  return (
    <>
      <Link
        style={{ color: '#EE7125' }}
        to={level === 'division' ? `/division/smd?divNbr=${divNbr}` : `/region/smd?rgnNbr=${rgnNbr}`}
        data-test='b2o-link'>
        Back to SMD Detail
      </Link>

      <Header textAlign='left'>
        SMD - Reason Codes - {capitalize(level)} {orgNbr}, {data[0]?.DIV_NM || data[0]?.RGN_NM}
        <Header.Subheader>Data as of {data[0]?.TRNSFM_DT}</Header.Subheader>
      </Header>

      <Grid>
        <Grid.Row>
          <Grid.Column>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <div style={{ textAlign: 'center' }}>
                <p style={{ marginBottom: '0' }}>Units:</p>
                <UnitMenu unit={unit} setUnit={setUnit} />
              </div>
              <div style={{ textAlign: 'center' }}>
                <p style={{ marginBottom: '0', marginTop: '1rem' }}>Timeframe:</p>
                <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Header textAlign='center'>
        {data[0]
          ? data[0]['MKDN_RSN_CD'] + '-' + data[0]['MKDN_RSN_DESC']
          : `Reason Code ${mkdnRsnCd} - No markdowns found.`}
      </Header>
      {data[0] && <DataTable data={dataObj} />}
    </>
  );
};
