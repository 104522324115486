import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useQueryParams = () => {
  const { search } = useLocation();
  
  const queryParams = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    const params = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });
    return params;
  }, [search]);

  return queryParams;
};
