import { Fragment } from 'react';
import { BreadcrumbSection, Breadcrumb as BreadcrumbWrapper } from 'semantic-ui-react';
import { Link } from './Link';

export const Breadcrumb = ({ items }) => {
  return (
    <BreadcrumbWrapper>
      {items.map((item, index) => (
        <Fragment key={item.text}>
          {items.length === index + 1 ? (
            <span>{item.text}</span>
          ) : (
            <BreadcrumbSection>
              <Link to={item.to} text={item.text} />
            </BreadcrumbSection>
          )}

          {items.length !== index + 1 && <span> / </span>}
        </Fragment>
      ))}
    </BreadcrumbWrapper>
  );
};
