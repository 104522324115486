import React, { useState, useEffect } from 'react'
import { Header, Grid, Modal, Loader, Menu, Segment, Button } from 'semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getData } from '../../service/DataService'
import { DataTable } from '../Common/DataTable'

export const ShrinkReducedTag = withRouter(({ fpToggle, location }) => {

  const search = new URLSearchParams(location.search)
  const strNbr = search.has('strNbr') ? search.get('strNbr') : 0

  const [loc, setLoc] = useState(true)
  const [deptNbr, setDeptNbr] = useState("All")

  const [open, setOpen] = useState(false)
  const [modalData, setModalData] = useState([])

  const shrinkReducedTagQuery = useQuery(['queryShrinkReducedTag', { strNbr, fpToggle }], getData)

  if (shrinkReducedTagQuery.isLoading) {
    return (<Loader active>Loading...</Loader>)
  }

  if (!shrinkReducedTagQuery.data) {
    return (<Header textAlign='center'>Invalid Store Number</Header>)
  }

  const shrinkReducedTagData = shrinkReducedTagQuery.data

  // Filter by loc or noLoc, loc by default
  let dataFilter = shrinkReducedTagData.filter((row) => {
    return loc ? row && row['NO_HOME_FLG'] === 'HOME' : row && row['NO_HOME_FLG'] === 'NO HOME'
  }).filter((row) => {
    return deptNbr !== "All" ? row && row['DEPT_NBR'].toString() === deptNbr : row
  })

  const data = {
    sortable: true,
    sortableDefault: 0,
    pagination: true,
    headers: [
      {name: 'Mkdn Tag ID'},
      {name: 'Mkdn Tag Loc'},
      {name: 'Current Retail Amt'},
      {name: 'Next Mkdn Amt'},
      {name: 'Next Mkdn Week'},
      {name: 'Weeks Remaining'},
    ],
    data: dataFilter.map((row) => ([
      { 
        stringValue: (<p onClick={() => {setOpen(true); setModalData(row)}}>{row['MKDN_TAG_ID']}</p> ),
        customStyle: { color: '#EE7125', textDecoration: 'underline' }
      },
      { stringValue: row['MTAG_ITEM_LOC_TXT'] },
      { stringValue: row['CURR_RETAIL_AMT'] },
      { stringValue: row['MKDN_AMT'] },
      { stringValue: row['NXT_MKDN_WEEK'] },
      { stringValue: row['WK_RMN_CNT'] },
    ]))
  }

  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column width={8} textAlign='left'>
            <Grid.Row><Link style={{ color: '#EE7125' }} to={`/store/shrink?strNbr=${strNbr}`} data-test='b2o-link'>Back to Shrink Overview</Link></Grid.Row>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8} textAlign='justified'>
            <Grid.Row>&nbsp;</Grid.Row>
            <Grid.Row>
              <Menu compact size='small'>
                <Menu.Item
                  name='Loc'
                  active={loc === true}
                  content='Loc'
                  onClick={() => {
                    setLoc(true)
                  }}
                />
                <Menu.Item
                  name='No-Loc'
                  active={loc === false}
                  content='No-Loc'
                  onClick={() => {
                    setLoc(false)
                  }}
                />
              </Menu>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={8} textAlign='left'>
            <Grid.Row>Select Dept:</Grid.Row>
            <div>
              <select
                name="dept"
                onChange={e => {
                  setDeptNbr(e.target.value)
                }}
                value={deptNbr}
                style={{ padding: 10 }}
              >
                <option value="All" selected>All</option>
                <option value="21">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
                <option value="24">24</option>
                <option value="25">25</option>
                <option value="26">26</option>
                <option value="27">27</option>
                <option value="28">28</option>
                <option value="29">29</option>
                <option value="30">30</option>
                <option value="59">59</option>
              </select>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Header textAlign='center'>Reduced Tags - Store {strNbr}</Header>
      <DataTable data={data} />
      {open && <Modal
        closeIcon
        open={open}
        onClose={() => { setOpen(false) }}
        size={'mini'}
      >
        <Segment color='orange'>
          <Grid>
          <Grid.Column>
              <Grid.Row>
                <Header > SKU # </Header>
              </Grid.Row>
              <Grid.Row><p textAlign='center'>{modalData['SKU_NBR'].toString().length > 6 ? // Format SKUs, 10-digit (xxxx-xxx-xxx) versus 6-digit (xxx-xxx)
                    modalData['SKU_NBR'].toString().substring(0, 4) + '-' + modalData['SKU_NBR'].toString().substring(4, 7) + '-' + modalData['SKU_NBR'].toString().substring(7) :
                    modalData['SKU_NBR'].toString().substring(0, 3) + '-' + modalData['SKU_NBR'].toString().substring(3)}</p></Grid.Row>
              <Grid.Row>&nbsp;</Grid.Row>
              <Grid.Row>
                <Header>SKU Description</Header>
              </Grid.Row>
              <Grid.Row><p textAlign='center'>{modalData['SKU_DESC']}</p></Grid.Row>
              <Grid.Row>&nbsp;</Grid.Row>
              <Grid.Row><Header>Dept #{modalData['DEPT_NBR']}</Header></Grid.Row>
              <Grid.Row>&nbsp;</Grid.Row>
              <Grid.Row>
                <Button
                  onClick={() => { setOpen(false) }}
                  style={{ color: 'white', backgroundColor: '#EE7125' }}
                  content='Close'
                />
              </Grid.Row>
              <Grid.Row>&nbsp;</Grid.Row>
            </Grid.Column>
          </Grid>
        </Segment>
      </Modal>}
    </>
  )

})