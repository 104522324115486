export const Select = ({ label, name, onChange, value, items }) => (
  <section className='my-4 select-section'>
    <label>{label}</label>
    <select name={name} onChange={event => onChange(event.target.value)} value={value} style={{ padding: 10 }}>
      {items.map(({ value, text }, index) => (
        <option key={index} value={value}>
          {text}
        </option>
      ))}
    </select>
  </section>
);
