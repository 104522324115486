import { SalesTab } from './SalesTab';
import { Tab } from 'semantic-ui-react';
import { ComparisonByDeparmentTab } from './ComparisonByDeparmentTab';
import { InventoryTab } from './InventoryTab';
import { Overview } from './Overview';

export const OverviewTabs = () => {
  const tabs = [
    {
      menuItem: 'Sales',
      render: () => <SalesTab />
    },
    {
      menuItem: 'Comp',
      render: () => <ComparisonByDeparmentTab />
    },
    {
      menuItem: 'Inventory',
      render: () => <InventoryTab />
    }
  ];

  return (
    <>
      <Overview />
      <Tab panes={tabs} />
    </>
  );
};
