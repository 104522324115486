import React, { useState } from 'react'
import {
  Table,
  Tab,
  Icon,
} from 'semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'
import { getCompareStyle } from '../Common/TableCellUtils'

export const CompTab = withRouter(({ data, timeframe, location }) => {

  const [sortColumn, setSortColumn] = useState("DEPT_NBR")
  const [sortDirection, setSortDirection] = useState("ascending")

  const search = new URLSearchParams(location.search)
  const strNbr = search.has('strNbr') ? search.get('strNbr') : 0

  let dataSorted = data.sort((left, right) => {
    let result = left[sortColumn] - right[sortColumn]
    if (sortColumn === 'DEPT_NBR') {
      result = left.DEPT_NBR > right.DEPT_NBR ? 1 : -1
    }
    if (sortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  return (
    <Tab.Pane>
      <Table textAlign='center' celled unstackable sortable size='small' style={{ fontSize: 12 }}> 
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={sortColumn === 'DEPT_NBR' ? sortDirection : null}
              onClick={() => {
                if (sortColumn !== 'DEPT_NBR') {
                  setSortColumn('DEPT_NBR')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>
              DEPT {sortColumn !== `DEPT_NBR` && <Icon fitted name='sort' />}
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === timeframe + '_SLS_AMT_VLY_PCT' ? sortDirection : null}
              style={{whiteSpace: "normal",wordWrap: 'break-word'}}
              onClick={() => {
                if (sortColumn !== timeframe + '_SLS_AMT_VLY_PCT') {
                  setSortColumn(timeframe + '_SLS_AMT_VLY_PCT')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>Sales Comp % {sortColumn !== timeframe + '_SLS_AMT_VLY_PCT' && <Icon fitted name='sort' />}</Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === timeframe + '_TXN_CNT_VLY_PCT' ? sortDirection : null}
              style={{whiteSpace: "normal",wordWrap: 'break-word'}}
              onClick={() => {
                if (sortColumn !== timeframe + '_TXN_CNT_VLY_PCT') {
                  setSortColumn(timeframe + '_TXN_CNT_VLY_PCT')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>Trans Comp % {sortColumn !== timeframe + '_TXN_CNT_VLY_PCT' && <Icon fitted name='sort' />}</Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === timeframe + '_AVG_TCKT_VLY_PCT' ? sortDirection : null}
              style={{whiteSpace: "normal",wordWrap: 'break-word'}}
              onClick={() => {
                if (sortColumn !== timeframe + '_AVG_TCKT_VLY_PCT') {
                  setSortColumn(timeframe + '_AVG_TCKT_VLY_PCT')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>Avg Tckt Comp % {sortColumn !== timeframe + '_AVG_TCKT_VLY_PCT' && <Icon fitted name='sort' />}</Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === timeframe + '_UNTS_VLY_PCT' ? sortDirection : null}
              style={{whiteSpace: "normal",wordWrap: 'break-word'}}
              onClick={() => {
                if (sortColumn !== timeframe + '_UNTS_VLY_PCT') {
                  setSortColumn(timeframe + '_UNTS_VLY_PCT')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>Unit Comp % {sortColumn !== timeframe + '_UNTS_VLY_PCT' && <Icon fitted name='sort' />}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {dataSorted.map((data) => {
            return (
              <Table.Row>
                <Table.Cell>
                  <Link
                    style={{color: '#EE7125',textDecoration: 'underline'}}
                    to={`/store/sales/dept?strNbr=${strNbr}&deptNbr=${data['DEPT_NBR']}`}>
                    {data['MER_DEPT_NM'].replaceAll('/', '/\u200b')}
                  </Link>
                </Table.Cell>
                <Table.Cell style={getCompareStyle(data[timeframe + '_SLS_AMT_VLY_PCT'])}>
                  {(100 * data[timeframe + '_SLS_AMT_VLY_PCT']).toLocaleString(undefined, { maximumFractionDigits: 1 }) + '%'}
                </Table.Cell>
                <Table.Cell style={getCompareStyle(data[timeframe + '_TXN_CNT_VLY_PCT'])}>
                  {(100 * data[timeframe + '_TXN_CNT_VLY_PCT']).toLocaleString(undefined, { maximumFractionDigits: 1 }) + '%'}
                </Table.Cell>
                <Table.Cell style={getCompareStyle(data[timeframe + '_AVG_TCKT_VLY_PCT'])}>
                  {(100 * data[timeframe + '_AVG_TCKT_VLY_PCT']).toLocaleString(undefined, { maximumFractionDigits: 1 }) + '%'}
                </Table.Cell>
                <Table.Cell style={getCompareStyle(data[timeframe + '_UNTS_VLY_PCT'])}>
                  {(100 * data[timeframe + '_UNTS_VLY_PCT']).toLocaleString(undefined, { maximumFractionDigits: 1 }) + '%'}
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </Tab.Pane>
  )
}
)