export const hasFocus = element => element === document.activeElement
export const getByClassName = (className, wrapper = document) => wrapper.getElementsByClassName(className)
export const isClassNameInScreen = (className, wrapper = document) => wrapper.getElementsByClassName(className).length > 0
export const scrollTo = (query, elementPosition = 0) => {
  if (!query) return

  const elements = document.querySelectorAll(query)
  if (!elements) console.error('Elements by query not found')

  elements[elementPosition]?.scrollIntoView({ block: 'nearest' });
}
