import React, { useState } from 'react'
import {
  Header,
  Grid,
  Modal,
  Segment,
  Button,
  Label
} from 'semantic-ui-react'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import { useQuery } from 'react-query'
import {
  getData
} from '../../service/DataService'



export const ViewChangeLog = ({ fpToggle }) => {

  const [open, setOpen] = useState(false)

  const changeLogQuery = useQuery(['queryGetChangeLog', { fpToggle }], getData)

  if (changeLogQuery.isLoading || changeLogQuery.data.length === 0) {
    return (null)
  }

  const changeData = changeLogQuery.data[0]


  const excitingButton = (
    <Button as='div' labelPosition='right' size='mini'>
      <Button color='orange' size='mini'>
        What's New?
      </Button>
      <Label basic color='red' pointing='left' size='mini'>
        Recent Release!
      </Label>
    </Button>
  )

  const regularButton = <Button size='mini' style={{color: 'white', backgroundColor: 'gray'}}>What's New?</Button>

  const releaseDate = Date.parse(changeData.LOAD_TS)
  const daysDifference = Math.floor((Date.now() - releaseDate)/1000/60/60/24);
  

  return (
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={daysDifference <= 7 ? excitingButton : regularButton}
      >
        <Segment color='orange'>
          <Grid>
            <Grid.Column width={16}>
              <Grid.Row>
                <Header textAlign='center' style={{ color: 'red' }}>Change Log</Header>
              </Grid.Row>
              <Grid.Row>&nbsp;</Grid.Row>
              <Grid.Row>
                <ReactMarkdown plugins={[gfm]} children={changeData['MESSAGE']} />
              </Grid.Row>
              <Grid.Row>&nbsp;</Grid.Row>
              <Grid.Row>
                <Button
                  onClick={() => { setOpen(false) }}
                  style={{ color: 'white', backgroundColor: '#EE7125' }}
                  content='OK'
                />
              </Grid.Row>
              <Grid.Row>&nbsp;</Grid.Row>
            </Grid.Column>
          </Grid>
        </Segment>
      </Modal>
  )

}