import { Header } from "semantic-ui-react"
import { DataTable } from "../Common/DataTable"

export const VocDetailsComponent = ({ dataFilter, level, levelNumber, levelName }) => {
  const rowProps = ['LTPA', 'Product Condition', 'Order Accuracy', 'Reasonable Wait Time']
  const propsByRow = ['SATISFACTION_SCORE', 'PRODUCT_CONDITION_RATING', 'ORDER_FULFILLMENT_ACCURACY', 'WAIT_TIME_5']

  const boss = dataFilter.filter(row => row['ORDER_TYPE'] === 'BOSS')
  const bopis = dataFilter.filter(row => row['ORDER_TYPE'] === 'BOPIS')
  const mixed = dataFilter.filter(row => row['ORDER_TYPE'] === 'MIXED')
  const total = dataFilter.filter(row => row['ORDER_TYPE'] === 'TOTAL')

  const dataObjVocDetails = {
    sortable: false,
    headers: [
      { name: '' },
      { name: 'BOSS' },
      { name: 'BOPIS' },
      { name: 'MIXED' },
      { name: 'TOTAL' },
    ],
    data: rowProps.map((row, indexRow) => [
      {
        stringValue: row
      },
      {
        pctValue: boss.length > 0 ? boss[0][propsByRow[indexRow]] : '-'
      },
      {
        pctValue: bopis.length > 0 ? bopis[0][propsByRow[indexRow]] : '-'
      },
      {
        pctValue: mixed.length > 0 ? mixed[0][propsByRow[indexRow]] : '-'
      },
      {
        pctValue: total.length > 0 ? total[0][propsByRow[indexRow]] : '-'
      }
    ])
  }
  return (
    <>
      <Header textAlign='center' style={{ textTransform: 'capitalize' }}>VOC Overview - {level} {levelNumber}, {levelName}</Header>
      <DataTable data={dataObjVocDetails} />
    </>
  )
}