import { Header, Loader, Tab } from 'semantic-ui-react';
import { Core } from './Core';
import { DivisionList } from './DivisionList';
import { useCoreOverviewContext } from '../../../hooks/useCoreOverviewContext';

const panes = [
  {
    menuItem: 'Core',
    render: () => <Core />
  },
  {
    menuItem: 'Division List',
    render: () => <DivisionList />
  }
];

export const OverviewMenu = () => {
  const { isError, isLoading } = useCoreOverviewContext();

  if (isError) return <Header>Something went wrong</Header>;
  if (isLoading) return <Loader active>Loading</Loader>;

  return <Tab panes={panes} menu={{ secondary: true, pointing: true }} style={{ marginBottom: '1em' }} />;
};
