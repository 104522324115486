export const TimeframeDropdown = ({ timeframe, settimeframe }) => {
  const isMonday = new Date().getDay() === 1
  const options = isMonday ? MONDAY_OPTIONS : BASE_OPTIONS

  return (
    <div>
      <select
        name="timeframe"
        onChange={e => {
          settimeframe(e.target.value)
        }}
        value={timeframe}
        style={{ padding: 10 }}
      >
        {options.map(option => (
          <option key={option.key} value={option.value}>{option.text}</option>
        ))}
      </select>
    </div>
  )
}

const BASE_OPTIONS = [
  { key: 'WTD', text: 'WTD', value: 'WTD' },
  { key: 'LW', text: 'LW', value: 'LW' },
  { key: 'LM', text: 'LM', value: 'LM' },
  { key: 'MTD', text: 'MTD', value: 'MTD' },
  { key: 'QTD', text: 'QTD', value: 'QTD' },
  { key: 'HTD', text: 'HTD', value: 'HTD' },
  { key: 'YTD', text: 'YTD', value: 'YTD' },
  { key: 'L12', text: 'L12', value: 'L12' },
]

const MONDAY_OPTIONS = [
  { key: 'LW', text: 'LW', value: 'WTD' },
  { key: 'LLW', text: 'LLW', value: 'LW' },
  { key: 'LM', text: 'LM', value: 'LM' },
  { key: 'MTD', text: 'MTD', value: 'MTD' },
  { key: 'QTD', text: 'QTD', value: 'QTD' },
  { key: 'HTD', text: 'HTD', value: 'HTD' },
  { key: 'YTD', text: 'YTD', value: 'YTD' },
  { key: 'L12', text: 'L12', value: 'L12' },
]
