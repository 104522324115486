import React from 'react';
import { Segment, Tab } from 'semantic-ui-react';
import { LaborOverview } from '../components/labor/LaborOverview';
import { LaborListTab } from '../components/labor/LaborListTab';
import LaborContextProvider from '../context/LaborContext';

const LaborPage = ({ timeframe, settimeframe, fpToggle, userEmplCtgryNm, level }) => {
  const panes = [
    {
      menuItem: 'Core',
      render: () => <LaborOverview />
    },
    {
      menuItem: 'Division List',
      render: () => <LaborListTab />
    }
  ];
  return (
    <LaborContextProvider
      timeframe={timeframe}
      settimeframe={settimeframe}
      fpToggle={fpToggle}
      userEmplCtgryNm={userEmplCtgryNm}
      level={level}>
      <Segment>
        <Tab panes={panes} />
      </Segment>
    </LaborContextProvider>
  );
};

export default LaborPage;
