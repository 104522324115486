import React from 'react';
import { Header } from 'semantic-ui-react';
import { DataTable } from '../../../../../../components/Common/DataTable';
import { createDataObjPurgePackDown, reducePurgePackDown } from '../../utils/osa/purge';

const PurgePackDownByDiv = ({ data, dataScores, entityField, findItem, timeframe }) => {
  const purgePackDown = reducePurgePackDown(data, dataScores, entityField, timeframe, findItem);

  const dataObj = createDataObjPurgePackDown(purgePackDown, entityField, timeframe);

  return (
    <>
      <Header textAlign='center'>Purge Packdown by Division</Header>
      <DataTable data={dataObj} />
    </>
  );
};

export default PurgePackDownByDiv;
