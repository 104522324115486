import { useState } from 'react';
import { VOC_LTPA_GOAL, VOC_LTSA_GOAL } from '../../../constants/goals';
import { DataView } from '../../Common/DataView';
import { getCompareStyle, getDollarString, getPercentString } from '../../Common/TableCellUtils';
import { Grid, Header } from 'semantic-ui-react';

const LEVEL_CONSTANTS = {
  region: {
    sufix: 'DST',
    columnHeader: 'District',
    subQueryParam: 'dstNbr',
    subLevel: 'district',
    rowValue: 'DST_NBR'
  },
  division: {
    sufix: 'RGN',
    columnHeader: 'Region',
    subQueryParam: 'rgnNbr',
    subLevel: 'region',
    rowValue: 'RGN_NBR'
  }
};

const headersList = (metrict, unit, level) => {
  if (metrict === 'Days Safe') {
    return [{ name: `${LEVEL_CONSTANTS[level]['columnHeader']} # ` }, { name: 'Days Safe ' }];
  }
  return [
    { name: `${LEVEL_CONSTANTS[level]['columnHeader']} # ` },
    { name: `Actual ` },
    { name: `vP/vF  ${unit === 'PCT' ? '% ' : ''}` },
    { name: `vLY  ${unit === 'PCT' ? '% ' : ''}` }
  ];
};

const bodyRows = ({ timeframe, metric, row, unit, level }) => {
  const listOfRecords = [
    {
      stringValue: `${LEVEL_CONSTANTS[level]['sufix']} ${row[LEVEL_CONSTANTS[level]['rowValue']]}`,
      orderBy: parseInt(row[LEVEL_CONSTANTS[level]['rowValue']]),
      link: {
        to: `/${LEVEL_CONSTANTS[level]['subLevel']}/overview`,
        params: {
          [LEVEL_CONSTANTS[level]['subQueryParam']]: String(row[LEVEL_CONSTANTS[level]['rowValue']]).padStart(4, '0')
        }
      }
    }
  ];
  if (metric === 'Days Safe') {
    listOfRecords.push({
      stringValue: row['DAYS_SAFE'],
      orderBy: row['DAYS_SAFE']
    });
  }
  if (metric === 'Sales') {
    const currentList = [
      {
        stringValue: getDollarString(row[timeframe + propsByMetric[metric]['actual']]),
        orderBy: row[timeframe + propsByMetric[metric]['actual']]
      },
      {
        stringValue:
          unit !== 'PCT'
            ? getDollarString(row[timeframe + propsByMetric[metric][unit + '_VP']])
            : getPercentString(row[timeframe + propsByMetric[metric][unit + '_VP']], 2),
        customStyle: getCompareStyle(row[timeframe + propsByMetric[metric][unit + '_VP']]),
        orderBy: row[timeframe + propsByMetric[metric][unit + '_VP']]
      },
      {
        stringValue:
          unit !== 'PCT'
            ? getDollarString(row[timeframe + propsByMetric[metric][unit + '_VLY']])
            : getPercentString(row[timeframe + propsByMetric[metric][unit + '_VLY']], 2),
        customStyle: getCompareStyle(row[timeframe + propsByMetric[metric][unit + '_VLY']]),
        orderBy: row[timeframe + propsByMetric[metric][unit + '_VLY']]
      }
    ];
    listOfRecords.push(...currentList);
  }
  if (metric === 'Units') {
    const currentList = [
      {
        stringValue: (+row[timeframe + propsByMetric[metric]['actual']]).toLocaleString(undefined, {
          maximumFractionDigits: 0
        }),
        orderBy: row[timeframe + propsByMetric[metric]['actual']]
      },
      {
        stringValue: '-'
      },
      {
        stringValue:
          unit !== 'PCT'
            ? (+row[timeframe + propsByMetric[metric][unit + '_VLY']]).toLocaleString(undefined, {
                maximumFractionDigits: 0
              })
            : getPercentString(row[timeframe + propsByMetric[metric][unit + '_VLY']]),
        customStyle: getCompareStyle(row[timeframe + propsByMetric[metric][unit + '_VLY']]),
        orderBy: row[timeframe + propsByMetric[metric][unit + '_VLY']]
      }
    ];
    listOfRecords.push(...currentList);
  }
  if (metric === 'Transactions') {
    const currentList = [
      {
        stringValue: (+row[timeframe + propsByMetric[metric]['actual']]).toLocaleString(undefined, {
          maximumFractionDigits: 0
        }),
        orderBy: row[timeframe + propsByMetric[metric]['actual']]
      },
      {
        stringValue: '-'
      },
      {
        stringValue:
          unit !== 'PCT'
            ? (+row[timeframe + propsByMetric[metric][unit + '_VLY']]).toLocaleString(undefined, {
                maximumFractionDigits: 0
              })
            : getPercentString(row[timeframe + propsByMetric[metric][unit + '_VLY']]),
        customStyle: getCompareStyle(row[timeframe + propsByMetric[metric][unit + '_VLY']]),
        orderBy: row[timeframe + propsByMetric[metric][unit + '_VLY']]
      }
    ];
    listOfRecords.push(...currentList);
  }
  if (metric === 'Average Ticket') {
    const currentList = [
      {
        stringValue: getDollarString(row[timeframe + propsByMetric[metric]['actual']], 2),
        orderBy: row[timeframe + propsByMetric[metric]['actual']]
      },
      {
        stringValue: '-'
      },
      {
        stringValue:
          unit !== 'PCT'
            ? getDollarString(row[timeframe + propsByMetric[metric][unit + '_VLY']], 2)
            : getPercentString(row[timeframe + propsByMetric[metric][unit + '_VLY']], 2),
        customStyle: getCompareStyle(row[timeframe + propsByMetric[metric][unit + '_VLY']]),
        orderBy: row[timeframe + propsByMetric[metric][unit + '_VLY']]
      }
    ];
    listOfRecords.push(...currentList);
  }
  if (metric === 'Units per Basket') {
    const unitsPerBasketByNOM =
      row['TY_' + timeframe + '_COMP_UNT_SLS'] / row['TY_' + timeframe + '_COMP_TXN_CNT'] -
      row['LY_' + timeframe + '_COMP_UNT_SLS'] / row['LY_' + timeframe + '_COMP_TXN_CNT'];
    const unitsPerBasketByPCT =
      (row['TY_' + timeframe + '_COMP_UNT_SLS'] / row['TY_' + timeframe + '_COMP_TXN_CNT'] -
        row['LY_' + timeframe + '_COMP_UNT_SLS'] / row['LY_' + timeframe + '_COMP_TXN_CNT']) /
      (row['LY_' + timeframe + '_COMP_UNT_SLS'] / row['LY_' + timeframe + '_COMP_TXN_CNT']);
    const currentList = [
      {
        stringValue: (row['TY_' + timeframe + '_UNT_SLS'] / row['TY_' + timeframe + '_TXN_CNT']).toLocaleString(undefined, {
          maximumFractionDigits: 2
        }),
        orderBy: row['TY_' + timeframe + '_UNT_SLS'] / row['TY_' + timeframe + '_TXN_CNT']
      },
      {
        stringValue: '-'
      },
      {
        stringValue:
          unit !== 'PCT'
            ? unitsPerBasketByNOM.toLocaleString(undefined, { maximumFractionDigits: 2 })
            : getPercentString(unitsPerBasketByPCT, 2),
        customStyle: getCompareStyle(unitsPerBasketByNOM),
        orderBy: unit !== 'PCT' ? unitsPerBasketByNOM : unitsPerBasketByPCT
      }
    ];
    listOfRecords.push(...currentList);
  }
  if (metric === 'SMD') {
    const currentList = [
      {
        stringValue: getPercentString(row[timeframe + '_SMD_ACT'], 2),
        orderBy: row[timeframe + '_SMD_ACT']
      },
      {
        stringValue:
          unit !== 'PCT'
            ? (+row[timeframe + '_SMD_VP']).toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' bps'
            : '-',
        customStyle: getCompareStyle(-1 * row[timeframe + '_SMD_VP']),
        orderBy: unit !== 'PCT' ? row[timeframe + '_SMD_VP'] : undefined
      },
      {
        stringValue:
          unit !== 'PCT'
            ? (+row[timeframe + '_SMD_VLY']).toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' bps'
            : '-',
        customStyle: getCompareStyle(-1 * row[timeframe + '_SMD_VLY']),
        orderBy: unit !== 'PCT' ? row[timeframe + '_SMD_VLY'] : undefined
      }
    ];
    listOfRecords.push(...currentList);
  }
  if (metric === 'Labor') {
    const currentList = [
      {
        stringValue: (+row[timeframe + '_LBR_ACT']).toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' hrs',
        orderBy: row[timeframe + '_LBR_ACT']
      },
      {
        stringValue:
          unit === 'NOM'
            ? (+row[timeframe + propsByMetric[metric][unit + '_VP']]).toLocaleString(undefined, {
                maximumFractionDigits: 0
              }) + ' hrs'
            : getPercentString(row[timeframe + propsByMetric[metric][unit + '_VP']]),
        customStyle: unit === 'PCT' ? getCompareStyle(row[timeframe + propsByMetric[metric][unit + '_VP']]) : undefined,
        orderBy: row[timeframe + propsByMetric[metric][unit + '_VP']]
      },
      {
        stringValue: '-'
      }
    ];
    listOfRecords.push(...currentList);
  }
  if (metric === 'GET') {
    const currentList = [
      {
        stringValue: getPercentString(row[timeframe + propsByMetric[metric]['actual']], 2),
        customStyle: {
          color: +row[timeframe + propsByMetric[metric]['actual']] >= VOC_LTSA_GOAL ? 'green' : 'red'
        },
        orderBy: row[timeframe + propsByMetric[metric]['actual']]
      },
      {
        stringValue: '-'
      },
      {
        stringValue: unit === 'NOM' ? getPercentString(row[timeframe + propsByMetric[metric][unit + '_VLY']], 2) : '-',
        customStyle: {
          color: +row[timeframe + propsByMetric[metric][unit + '_VLY']] >= VOC_LTSA_GOAL ? 'green' : 'red'
        },
        orderBy: row[timeframe + propsByMetric[metric][unit + '_VLY']]
      }
    ];
    listOfRecords.push(...currentList);
  }
  if (metric === 'LTSA') {
    const currentList = [
      {
        stringValue: getPercentString(row[timeframe + propsByMetric[metric]['actual']], 2),
        customStyle: {
          color: +row[timeframe + propsByMetric[metric]['actual']] >= VOC_LTSA_GOAL ? 'green' : 'red'
        },
        orderBy: row[timeframe + propsByMetric[metric]['actual']]
      },
      {
        stringValue: '-'
      },
      {
        stringValue: unit === 'NOM' ? getPercentString(row[timeframe + propsByMetric[metric][unit + '_VLY']], 2) : '-',
        customStyle: {
          color: +row[timeframe + propsByMetric[metric][unit + '_VLY']] >= VOC_LTSA_GOAL ? 'green' : 'red'
        },
        orderBy: row[timeframe + propsByMetric[metric][unit + '_VLY']]
      }
    ];
    listOfRecords.push(...currentList);
  }
  if (metric === 'LTPA') {
    const currentList = [
      {
        stringValue: getPercentString(row[timeframe + propsByMetric[metric]['actual']], 2),
        customStyle: {
          color:
            +row[timeframe + '_ONLINE_SAT_ACT'] > VOC_LTPA_GOAL
              ? 'green'
              : +row[timeframe + '_ONLINE_SAT_ACT'] < VOC_LTPA_GOAL
                ? 'red'
                : 'black'
        },
        orderBy: row[timeframe + propsByMetric[metric]['actual']]
      },
      {
        stringValue: '-'
      },
      {
        stringValue: unit !== 'NOM' ? getPercentString(row[timeframe + propsByMetric[metric][unit + '_VLY']], 2) : '-',
        customStyle: getCompareStyle(row[timeframe + propsByMetric[metric][unit + '_VLY']]),
        orderBy: unit === 'NOM' ? row[timeframe + propsByMetric[metric][unit + '_VLY']] : undefined
      }
    ];
    listOfRecords.push(...currentList);
  }
  if (metric === 'Leads') {
    const currentList = [
      {
        stringValue: (+row[timeframe + propsByMetric[metric]['actual']]).toLocaleString(undefined, {
          maximumFractionDigits: 0
        }),
        orderBy: row[timeframe + propsByMetric[metric]['actual']]
      },
      {
        stringValue: '-'
      },
      {
        stringValue:
          unit !== 'NOM'
            ? getPercentString(row[timeframe + propsByMetric[metric][unit + '_VLY']])
            : (+row[timeframe + propsByMetric[metric][unit + '_VLY']]).toLocaleString(undefined, {
                maximumFractionDigits: 0
              }),
        customStyle: getCompareStyle(row[timeframe + propsByMetric[metric][unit + '_VLY']]),
        orderBy: row[timeframe + propsByMetric[metric][unit + '_VLY']]
      }
    ];
    listOfRecords.push(...currentList);
  }
  if (metric === 'Measures') {
    const currentList = [
      {
        stringValue: (+row[timeframe + propsByMetric[metric]['actual']]).toLocaleString(undefined, {
          maximumFractionDigits: 0
        }),
        orderBy: row[timeframe + propsByMetric[metric]['actual']]
      },
      {
        stringValue: '-'
      },
      {
        stringValue:
          unit !== 'NOM'
            ? getPercentString(row[timeframe + propsByMetric[metric][unit + '_VLY']])
            : (+row[timeframe + propsByMetric[metric][unit + '_VLY']]).toLocaleString(undefined, {
                maximumFractionDigits: 0
              }),
        customStyle: getCompareStyle(row[timeframe + propsByMetric[metric][unit + '_VLY']]),
        orderBy: row[timeframe + propsByMetric[metric][unit + '_VLY']]
      }
    ];
    listOfRecords.push(...currentList);
  }
  if (metric === 'HDPP - MA UA') {
    const currentList = [
      {
        stringValue: getPercentString(row[timeframe + '_MA_HDPP']),
        orderBy: row[timeframe + '_MA_HDPP']
      },
      {
        stringValue: getPercentString(row[timeframe + '_MA_HDPP_VG']),
        customStyle: getCompareStyle(row[timeframe + '_MA_HDPP_VG']),
        orderBy: row[timeframe + '_MA_HDPP_VG']
      },
      {
        stringValue: getPercentString(row[timeframe + '_MA_HDPP_VLY']),
        customStyle: getCompareStyle(row[timeframe + '_MA_HDPP_VLY']),
        orderBy: row[timeframe + '_MA_HDPP_VLY']
      }
    ];
    listOfRecords.push(...currentList);
  }
  if (metric === 'HDPP - GM UA') {
    const currentList = [
      {
        stringValue: getPercentString(row[timeframe + '_MA_HDPP']),
        orderBy: row[timeframe + '_MA_HDPP']
      },
      {
        stringValue: getPercentString(row[timeframe + '_GM_HDPP_VG']),
        customStyle: getCompareStyle(row[timeframe + '_GM_HDPP_VG']),
        orderBy: row[timeframe + '_GM_HDPP_VG']
      },
      {
        stringValue: getPercentString(row[timeframe + '_GM_HDPP_VLY']),
        customStyle: getCompareStyle(row[timeframe + '_GM_HDPP_VLY']),
        orderBy: row[timeframe + '_GM_HDPP_VLY']
      }
    ];
    listOfRecords.push(...currentList);
  }

  return listOfRecords;
};

export const SalesBySubLevelList = ({
  timeframe,
  metricsByDistrict,
  unit,
  levelNumber,
  SafetyIncOshaLtGlbiByRgnData,
  dataAsOf,
  setMetric,
  metric,
  level,
  levelName
}) => {
  const listByDaysSafe = SafetyIncOshaLtGlbiByRgnData.filter(
    row => row.METRIC_LEVEL === LEVEL_CONSTANTS[level]['sufix']
  ).map(row => bodyRows({ timeframe, row, metric, unit, level }));

  const districtListDataObj = {
    headers: headersList(metric, unit, level),
    data:
      metric === 'Days Safe'
        ? listByDaysSafe
        : metricsByDistrict.map(row => bodyRows({ timeframe, row, metric, unit, level })),
    sortable: true,
    sortableDefault: 0
  };

  return (
    <>
      <Grid>
        <Grid.Column width={8} />
        <Grid.Column width={8}>
          Metric:
          <div>
            <select
              name='metric'
              onChange={e => {
                setMetric(e.target.value);
              }}
              value={metric}
              style={{ padding: 10 }}>
              <option value='Sales' selected>
                Sales
              </option>
              <option value='Units'>Units</option>
              <option value='Transactions'>Transactions</option>
              <option value='Average Ticket'>Average Ticket</option>
              <option value='Units per Basket'>Units per Basket</option>
              <option value='Days Safe'>Days Safe</option>
              <option value='SMD'>SMD</option>
              <option value='Labor'>Labor</option>
              <option value='GET'>GET</option>
              <option value='LTSA'>LTSA</option>
              <option value='LTPA'>LTPA</option>
              <option value='Leads'>Leads</option>
              <option value='Measures'>Measures</option>
              <option value='HDPP - MA UA'>HDPP - MA UA</option>
              <option value='HDPP - GM UA'>HDPP - GM UA</option>
            </select>
          </div>
        </Grid.Column>
      </Grid>
      <Header textAlign='center' style={{ textTransform: 'capitalize' }}>
        {metric} - {level} {levelNumber}, {levelName}
        <Header.Subheader>Data as of {dataAsOf}</Header.Subheader>
      </Header>

      <DataView testId={'salesByDistrict'}>
        <DataView.DataTable data={districtListDataObj} />
      </DataView>
    </>
  );
};

const propsByMetric = {
  Sales: {
    NOM_VP: '_SLS_VP',
    PCT_VP: '_SLS_AMT_VP_PCT',
    NOM_VLY: '_SLS_AMT_VLY',
    PCT_VLY: '_SLS_AMT_VLY_PCT',
    actual: '_SLS_AMT_ACT'
  },
  Units: {
    NOM_VP: null,
    PCT_VP: null,
    NOM_VLY: '_UNTS_VLY',
    PCT_VLY: '_UNTS_VLY_PCT',
    actual: '_UNTS_ACT'
  },
  Transactions: {
    NOM_VP: null,
    PCT_VP: null,
    NOM_VLY: '_TRNS_VLY',
    PCT_VLY: '_TRNS_VLY_PCT',
    actual: '_TRNS_ACT'
  },
  'Average Ticket': {
    NOM_VP: null,
    PCT_VP: null,
    NOM_VLY: '_AVG_TCKT_VLY',
    PCT_VLY: '_AVG_TCKT_VLY_PCT',
    actual: '_AVG_TCKT_ACT'
  },
  SMD: {
    NOM_VP: '_SMD_VP',
    PCT_VP: null,
    NOM_VLY: '_SMD_VLY',
    PCT_VLY: null,
    actual: '_SMD_ACT'
  },
  Labor: {
    NOM_VP: '_LBR_VF',
    PCT_VP: '_LBR_VF_PCT',
    NOM_VLY: null,
    PCT_VLY: null,
    actual: '_LBR_ACT'
  },
  GET: {
    NOM_VP: null,
    PCT_VP: null,
    NOM_VLY: '_GET_VLY',
    PCT_VLY: null,
    actual: '_GET_ACT'
  },
  LTSA: {
    NOM_VP: null,
    PCT_VP: null,
    NOM_VLY: '_LTSA_VLY',
    PCT_VLY: null,
    actual: '_LTSA_ACT'
  },
  LTPA: {
    NOM_VP: null,
    PCT_VP: null,
    NOM_VLY: '_ONLINE_SAT_VLY',
    PCT_VLY: null,
    actual: '_ONLINE_SAT_ACT'
  },
  Leads: {
    NOM_VP: null,
    PCT_VP: null,
    NOM_VLY: '_LEADS_VLY',
    PCT_VLY: '_LEADS_VLY_PCT',
    actual: '_LEADS_ACT'
  },
  Measures: {
    NOM_VP: null,
    PCT_VP: null,
    NOM_VLY: '_MEAS_VLY',
    PCT_VLY: '_MEAS_VLY_PCT',
    actual: '_MEAS_ACT'
  }
};
