export const TY_SLS_AMT = 'TY_SLS_AMT';
export const TY_UNT_SLS = 'TY_UNT_SLS';
export const TRNSFM_TYP_SORT_ID = 'TRNSFM_TYP_SORT_ID';

export const SKUS_SORT_OPTIONS = [
  { key: TY_SLS_AMT, text: 'Sales Amount', value: TY_SLS_AMT },
  { key: TY_UNT_SLS, text: 'Unit Sales', value: TY_UNT_SLS }
];

export const SKUS_RANK_ORDER = ['Top', 'Bottom'];
export const UNIT_CHAR = { NOM: '$', PCT: '%' };

export const NOMTY_SLS_AMT = 'NOMTY_SLS_AMT';
export const PCTTY_SLS_AMT = 'PCTTY_SLS_AMT';
export const NOMTY_UNT_SLS = 'NOMTY_UNT_SLS';
export const PCTTY_UNT_SLS = 'PCTTY_UNT_SLS';

export const SKUS_HEADERS = (isMetricSales, isUnitNom, unit) => [
  { name: 'Rank' },
  { name: 'SKU#' },
  { name: 'SKU DESC' },
  { name: 'DEPT' },
  { name: isMetricSales ? 'Sales Amt $' : 'Units' },
  { name: `${isMetricSales ? 'Sales' : 'Units'} vLY ${!isMetricSales && isUnitNom ? '#' : UNIT_CHAR[unit]}` }
];

export const skusColumnBuilder = (row, index, unit) => {
  let vLY;
  let metric;
  switch (unit) {
    case NOMTY_SLS_AMT:
      vLY = { dollarValue: row.SLS_COMP_NOM, compareStyle: true };
      metric = { dollarValue: row.TY_SLS_AMT, compareStyle: true };
      break;
    case PCTTY_SLS_AMT:
      vLY = { pctValue: row.SLS_COMP_PCT, compareStyle: true };
      metric = { dollarValue: row.TY_SLS_AMT, compareStyle: true };
      break;
    case NOMTY_UNT_SLS:
      vLY = {
        compareStyle: true,
        orderBy: row.UNT_COMP_NOM,
        stringValue: row.UNT_COMP_NOM.toLocaleString('en-US', { maximumFractionDigits: 0 })
      };
      metric = {
        orderBy: row.TY_UNT_SLS,
        stringValue: row.TY_UNT_SLS.toLocaleString('en-US', { maximumFractionDigits: 0 })
      };
      break;
    case PCTTY_UNT_SLS:
      vLY = { pctValue: row.UNT_COMP_PCT, compareStyle: true };
      metric = {
        orderBy: row.TY_UNT_SLS,
        stringValue: row.TY_UNT_SLS.toLocaleString('en-US', { maximumFractionDigits: 0 })
      };
      break;
    default:
      console.error(`Invalid unit: ${unit}. Unit can only be 'NOM' or 'PCT'.`);
      break;
  }

  return [
    { numValue: index + 1 },
    { numValue: row.SKU_NBR },
    { stringValue: row.SKU_DESC },
    { numValue: row.DEPT_NBR },
    metric,
    vLY
  ];
};
