import React, { useState, useEffect } from "react";
import { Header, Loader, Grid, Segment, Table, Pagination, Icon } from 'semantic-ui-react';
import { useQuery } from 'react-query';
import { withRouter, Link } from 'react-router-dom/cjs/react-router-dom.min';
import { getData } from '../../service/DataService';

import { TimeframeDropdownLimited } from '../Common/TimeframeDropdownLimited'
import { TimeframeDropdownMonLimited } from '../Common/TimeframeDropdownMonLimited'

export const SideKickPurgeDeptBay = withRouter(({ timeframe, settimeframe, level, fpToggle, locNbr, location}) => {

    const search = new URLSearchParams(location.search)
    const deptNbrInt = search.has('deptNbr') ? search.get('deptNbr') : 0
    const [pageNum, setPageNum] = useState(1)
    let rowsPerPage = 20
    const [sortColumn, setSortColumn] = useState("AISLE_BAY")
    const [sortDirection, setSortDirection] = useState("asecending")
 
    const SidekickSkuBayDateDtlByDeptBayQuery = useQuery([`queryOSASidekickSkuBayDateDtl`, { strNbr: locNbr, deptNbrInt, fpToggle }], getData)
   
    useEffect(() => {
      setPageNum(1)
    }, [timeframe, sortColumn, sortDirection])

    if (SidekickSkuBayDateDtlByDeptBayQuery.isLoading) {
      return (<Loader active>Loading</Loader>)
    }
    if (!SidekickSkuBayDateDtlByDeptBayQuery.data || SidekickSkuBayDateDtlByDeptBayQuery.data.length === 0) {
      return (<Header textAlign='center'>Invalid Store Number</Header>)
    }

    const SidekickSkuBayDateDtlByDeptBayQueryData = SidekickSkuBayDateDtlByDeptBayQuery.data.filter(({DEPT_NBR}) => DEPT_NBR === 38)

    let SidekickSkuBayDateDtlByDeptBayQueryDataFilter = SidekickSkuBayDateDtlByDeptBayQueryData.filter((row) => {
        return (
            row['TASK_TYPE'] === 'BAY' && row[timeframe + '_FLG'] === 1 
        )
    })

    let DeptBaySorted = SidekickSkuBayDateDtlByDeptBayQueryDataFilter.sort((left, right) => {
      let result = left[sortColumn] - right[sortColumn]
      if (sortColumn === 'AISLE_BAY') {
        result = left.AISLE_BAY > right.AISLE_BAY ? 1 : -1
      }
      if (sortColumn === 'SKUS_PACKED_DOWN') {
        result = left.SKUS_PACKED_DOWN > right.SKUS_PACKED_DOWN ? 1 : -1
      }
      if (sortColumn === 'ASSOC') {
        result = left.ASSOC > right.ASSOC ? 1 : -1
      }
      if (sortColumn === 'COMPLETED_DT') {
        result = left.COMPLETED_DT > right.COMPLETED_DT ? 1 : -1
      }
      if (sortDirection === 'descending') {
          result *= -1
      }
      return result
    })

    let dataPaginationDeptBay = DeptBaySorted.slice(rowsPerPage * (pageNum - 1), parseInt(Math.min(rowsPerPage * pageNum, DeptBaySorted.length)))

    return (
        <>
        <Segment>
        <Grid>
            <Grid.Column width={8}>
                <Grid.Row>
                <Link style={{ color: '#EE7125' }} to={`/${level}/overview?strNbr=${locNbr}`} data-test='b2o-link'>Overview</Link>
                {' / '}
                <Link style={{ color: '#EE7125' }} to={`/osa?strNbr=${locNbr}`} data-test='b2osa-link'>OSA</Link>
                {' / '}
                <Link style={{ color: '#EE7125' }} to={`/osa/Purge/store?strNbr=${locNbr}`} data-test='b2osa-link'>Purge</Link>
                {` / ${deptNbrInt}`}
                </Grid.Row>
                <Grid.Row>&nbsp;</Grid.Row>
            </Grid.Column>
            <Grid.Column width={8} />
            <Grid.Column width={8}>
                        Timeframe: 
              {new Date().getDay() === 1 ?
                <TimeframeDropdownMonLimited timeframe={timeframe} settimeframe={settimeframe} /> :
                <TimeframeDropdownLimited timeframe={timeframe} settimeframe={settimeframe} />}
            </Grid.Column>
        </Grid>

        <Header textAlign='center'>Bay Packdown - D{deptNbrInt}</Header>
        <Grid>
        {DeptBaySorted.length > rowsPerPage && <Grid.Row>
          <Grid.Column width={16} textAlign='center'>
            <Pagination
              size='mini'
              defaultActivePage={1}
              totalPages={Math.floor((DeptBaySorted.length - 1) / rowsPerPage) + 1}
              activePage={pageNum}
              boundaryRange={0}
              siblingRange={1}
              onPageChange={(e, { activePage }) => {
                setPageNum(activePage)
              }}
            />
          </Grid.Column>
        </Grid.Row>}
        </Grid>
        <Grid.Row>&nbsp;</Grid.Row>
        <Table textAlign='center' unstackable celled sortable size='small'>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    sorted={sortColumn === 'AISLE_BAY' ? sortDirection : null}
                    onClick={() => {
                        if (sortColumn !== 'AISLE_BAY') {
                            setSortColumn('AISLE_BAY')
                            setSortDirection('ascending')
                        } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                            }
                    }}>Bay {sortColumn !== 'AISLE_BAY' && <Icon fitted name='sort' />}</Table.HeaderCell>
                    <Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    sorted={sortColumn === 'SKUS_PACKED_DOWN' ? sortDirection : null}
                    onClick={() => {
                        if (sortColumn !== 'SKUS_PACKED_DOWN') {
                            setSortColumn('SKUS_PACKED_DOWN')
                            setSortDirection('ascending')
                        } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                            }
                    }}>Num of SKU PKDN {sortColumn !== 'SKUS_PACKED_DOWN' && <Icon fitted name='sort' />}</Table.HeaderCell>
                    <Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    sorted={sortColumn === 'ASSOC' ? sortDirection : null}
                    onClick={() => {
                        if (sortColumn !== 'ASSOC') {
                            setSortColumn('ASSOC')
                            setSortDirection('ascending')
                        } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                            }
                    }}>Associate {sortColumn !== 'ASSOC' && <Icon fitted name='sort' />}</Table.HeaderCell>
                    <Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    sorted={sortColumn === 'COMPLETED_DT' ? sortDirection : null}
                    onClick={() => {
                        if (sortColumn !== 'COMPLETED_DT') {
                            setSortColumn('COMPLETED_DT')
                            setSortDirection('ascending')
                        } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                            }
                    }}>Comp Date {sortColumn !== 'COMPLETED_DT' && <Icon fitted name='sort' />}</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {dataPaginationDeptBay.map((data) => {
                    return (
                    <Table.Row>
                        <Table.Cell><Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/osa/Purge/deptBayByBay?strNbr=${locNbr}&bay=${data['AISLE_BAY']}`}>{data.AISLE_BAY}</Link></Table.Cell>
                        <Table.Cell>{data.SKUS_PACKED_DOWN ? data.SKUS_PACKED_DOWN : '-'}</Table.Cell>
                        <Table.Cell>{data.ASSOC ? data.ASSOC : '-'}</Table.Cell>
                        <Table.Cell>{data.COMPLETED_DT ? data.COMPLETED_DT : '-'}</Table.Cell>
                    </Table.Row>
                    )})}
            </Table.Body>
        </Table>
        </Segment>
        </>
    )
 })