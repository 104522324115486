import { Button, Header, Modal, Segment } from 'semantic-ui-react';
import { formatSku } from '../../utils/formatter';

export const SkuDetailsModal = ({ isOpen, onClose, skuData }) => {
  return (
    <Modal closeIcon open={isOpen} onClose={onClose} role='dialog'>
      <Segment color='orange'>
        <Header textAlign='center'>{formatSku(skuData?.SKU_NBR || '')}</Header>

        <Header className='mb-0 mt-3'>Class</Header>
        <p>{skuData?.EXT_CLASS_NBR + '-' + skuData?.CLASS_DESC}</p>

        <Header className='my-0'>Subclass</Header>
        <p>{skuData?.EXT_SUB_CLASS_NBR + '-' + skuData?.SUB_CLASS_DESC}</p>

        <Header className='my-0'>SKU Description</Header>
        <p>{skuData?.SKU_DESC}</p>

        <Button content='Close' onClick={onClose} role='button' style={{ color: 'white', backgroundColor: '#EE7125' }} />
      </Segment>
    </Modal>
  );
};
