import React from 'react';
import { Header } from 'semantic-ui-react';
import { DataTable } from '../Common/DataTable';
import { getPercentString, getThousandDollarString } from '../Common/TableCellUtils';

export const IntradayTable = ({ hourlySalesData }) => {
  const intradayDataObj = {
    headers: [
      { name: 'As Of (Local Time)' },
      { name: 'Cumulative Sales $ (k)' },
      { name: '$ vPlan (k)' },
      { name: '% to Daily Plan' },
      { name: 'Comp %' }
    ],
    data: hourlySalesData.map(data => [
      { orderBy: data['SLS_HR'], stringValue: data['AS_OF'] },
      {
        customStyle: { color: data['CUMMU_SLS'] < 0 ? 'red' : 'black' },
        stringValue: getThousandDollarString(data['CUMMU_SLS'], 1)
      },
      {
        customStyle: { color: data['DOL_TO_DAILY_PLN'] < 0 ? 'red' : 'black' },
        stringValue: getThousandDollarString(data['DOL_TO_DAILY_PLN'], 1)
      },
      {
        customStyle: { color: data['PCT_TO_DAILY_PLN'] < 0 ? 'red' : 'black' },
        stringValue: getPercentString(data['PCT_TO_DAILY_PLN'], 1, 1)
      },
      {
        customStyle: { color: data['COMP_PCT'] < 0 ? 'red' : 'black' },
        stringValue: getPercentString(data['COMP_PCT'], 1, 1)
      }
    ]),
    sortable: true,
    sortableDefault: 0
  };

  return (
    <>
      <Header textAlign='center'>Intraday View</Header>
      <DataTable data={intradayDataObj} data-testid='intraday-view-table' />
    </>
  );
};
