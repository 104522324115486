import React from 'react';
import useQueryApi from '../../../../../hooks/useQueryApi';
import { useQueryParams } from '../../../../../hooks/useQueryParams';
import { Breadcrumb } from '../../../../../components/Common/Breadcrumb';
import { dailySalesColumns, dailySalesHeaders, wtdSalesColumns, wtdSalesHeaders } from '../utils/dailySales';
import { DataView } from '../../../../../components/Common/DataView';
import { Loader, Segment } from 'semantic-ui-react';
import { WtdSales } from '../components/WtdSales';

export const DailySalesDepartment = ({ fpToggle }) => {
  const { deptNbr } = useQueryParams();
  const defaultParams = { fpToggle, isCore: true, deptNbrInt: deptNbr };
  const queryWtdDeptSlsByCore = useQueryApi('queryWtdDeptSlsByCore', defaultParams);
  const queryDailyDeptSlsByCore = useQueryApi('queryDailyDeptSlsByCore', defaultParams);

  if (queryWtdDeptSlsByCore.isLoading || queryDailyDeptSlsByCore.isLoading) return <Loader active>Loading...</Loader>;

  const wtdSalesData = {
    headers: wtdSalesHeaders,
    data: queryWtdDeptSlsByCore.data.map(row => wtdSalesColumns(row))
  };

  const dailySalesData = {
    sortable: true,
    sortableDefault: 0,
    headers: dailySalesHeaders,
    data: queryDailyDeptSlsByCore.data.map(row => dailySalesColumns(row))
  };

  const breadcrumbItems = [{ to: `/core/daily`, text: 'Back to Daily' }, { text: `Dept ${deptNbr}` }];

  return (
    <section className='mb-4'>
      <Segment>
        <Breadcrumb items={breadcrumbItems} />

        <DataView className='mt-2'>
          <DataView.Title position='center'>DEPT {deptNbr} - Core</DataView.Title>

          <WtdSales wtdSalesData={wtdSalesData} dailySalesData={dailySalesData} dailySalesTitle='View' />
        </DataView>
      </Segment>
    </section>
  );
};
