import { DataTable } from '../../../../../../../components/Common/DataTable';
import { getLoggedWkDataTable } from '../../../utils/osa/sidekickskubay';

export const LoggedWkTab = props => {
  const { osaSidekickSkuBayData, sidekickExeScoresPlusOneData, subLevel, timeframe, ltf } = props;
  const loggedWkDataTable = getLoggedWkDataTable({
    osaSidekickSkuBayData,
    sidekickExeScoresPlusOneData,
    subLevel,
    timeframe,
    ltf
  });

  return <DataTable data={loggedWkDataTable} />;
};
