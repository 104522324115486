import { Header } from 'semantic-ui-react';
import { DataTable } from '../../../../../components/Common/DataTable';

export const WTDSalesByDepartment = ({ data }) => (
  <>
    <Header textAlign='left'>WTD Sales by Department</Header>

    <DataTable data={data} />
  </>
);
