import React, { useState, useEffect } from 'react'
import {
  Header,
  Grid,
  Loader,
  Modal,
  Segment,
  Button,
  Form,
  TextArea,
  Tab
} from 'semantic-ui-react'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'

import { useQuery } from 'react-query'
import {
  getAdminData
} from '../../service/DataService'

export const EditAlert = () => {

  const [open, setOpen] = useState(false)
  const [alertData, setAlertData] = useState([])
  const [messageField, setMessageField] = useState('')
  const [alertSendLoading, setAlertSendLoading] = useState(false)

  const pulseAlertQuery = useQuery(['queryGetStorePulseAlert'], getAdminData)

  useEffect(() => {
    if (pulseAlertQuery.isSuccess) {
      pulseAlertQuery.data.length >= 1 ? setAlertData(pulseAlertQuery.data[0]) : setAlertData([])
    }
  }, [pulseAlertQuery.data, pulseAlertQuery.isSuccess])

  useEffect(() => {
    if (alertData['ENABLED']) {
      setMessageField(alertData['MESSAGE'])
    }
  }, [alertData])


  const panes = [
    { menuItem: 'Edit', render: () => 
      <Tab.Pane>
        <Form>
          <a href='https://guides.github.com/features/mastering-markdown/' target="_blank" rel="noreferrer">
            Markdown Reference
          </a>
          <TextArea
            rows={6}
            style={{ fontSize: '16px' }}
            placeholder="Enter alert message..."
            defaultValue={messageField === '' ? null : messageField}
            onChange={(_, { value }) => {
              // if (value.length <= 2000) {
                setMessageField(value)
              // }

            }}
          />
        </Form>
      </Tab.Pane> },
    { menuItem: 'Preview', render: () => <Tab.Pane><ReactMarkdown plugins={[gfm]} children={messageField} /></Tab.Pane> },
  ]

  return (
    pulseAlertQuery.isLoading || alertSendLoading ? <Loader active>Loading</Loader> :
      <Modal
        closeIcon
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={<Button size='mini' style={{color: 'white', backgroundColor: 'gray'}}>Alert Admin</Button>}
      >
        <Segment color='orange'>
          <Header textAlign='center'>Admin</Header>
          <Grid>
            <Grid.Column width={16} textAlign='left'>
              <Grid.Row style = {{color: messageField.length > 2000 ? 'red' : 'black'}}>Alert message: ({messageField.length}/2000 characters used.{messageField.length > 2000 ? ' Message too long!' : ''})</Grid.Row>
              <Grid.Row>&nbsp;</Grid.Row>
              <Grid.Row>
                <Tab panes={panes}/>
              </Grid.Row>
            </Grid.Column>
            <Grid.Row>
              <Grid.Column width={8} textAlign='left'>
                <Button
                  disabled={messageField.length > 2000}
                  size='mini'
                  style={{ color: 'white', backgroundColor: '#EE7125' }}
                  onClick={() => {
                    setAlertSendLoading(true)
                    fetch(`${process.env.REACT_APP_BACKEND}/api/support/upsertStorePulseAlert`, {
                      credentials: 'include',
                      body: JSON.stringify({
                        MESSAGE: messageField.slice(0,2000),
                        ENABLED: true
                      }),
                      method: 'POST'
                    })
                      .then((response) => {
                        if (response.ok) {
                          setAlertSendLoading(false)
                          setOpen(false)
                        }
                      })
                  }}
                >Submit</Button>
              </Grid.Column>
              <Grid.Column width={8} textAlign='right'>
                <Button
                  size='mini'
                  style={{ color: 'white', backgroundColor: '#EE7125' }}
                  onClick={() => {
                    setAlertSendLoading(true)
                    setMessageField('')
                    fetch(`${process.env.REACT_APP_BACKEND}/api/support/upsertStorePulseAlert`, {
                      credentials: 'include',
                      body: JSON.stringify({
                        MESSAGE: messageField,
                        ENABLED: false
                      }),
                      method: 'POST'
                    })
                      .then((response) => {
                        if (response.ok) {
                          setAlertSendLoading(false)
                          setOpen(false)
                        }
                      })
                  }}
                >Cancel Alert</Button>
              </Grid.Column>
            </Grid.Row>

          </Grid>
        </Segment>
      </Modal>
  )

}