import { useMemo } from 'react';
import classNames from 'classnames';

export const Suggestion = ({ active, currentSearch, number, onClick, value }) => {
  const currentSearchLowered = currentSearch.trim().toLowerCase();
  const classNameWrapper = classNames('suggestion', { active });
  const suggestionParts = useMemo(() => {
    return value.split(new RegExp(`(${currentSearchLowered})`, 'gi'));
  }, [currentSearchLowered, value]);

  const handleOnClick = () => onClick({ number, value });

  return (
    <div className={classNameWrapper} data-testid='suggestion' onClick={handleOnClick}>
      {suggestionParts.map((part, index) =>
        part.toLowerCase() === currentSearchLowered ? (
          <span key={index + part} className='text-bold'>
            {part}
          </span>
        ) : (
          <span key={index + part}>{part}</span>
        )
      )}
    </div>
  );
};
