import React, { useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Button, Dropdown, Grid, Header, Icon, Loader, Modal, Segment } from 'semantic-ui-react'
import { TimeframeDropdown } from '../Common/TimeframeDropdown'
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon'

import { useQuery } from 'react-query'
import {
  getData
} from '../../service/DataService'
import { DataTable } from '../Common/DataTable'
import { getPercentString } from '../Common/TableCellUtils'

export const VocDetail = withRouter(({ timeframe, settimeframe, fpToggle, location, levelName }) => {

  const search = new URLSearchParams(location.search)
  const strNbr = search.has('strNbr') ? search.get('strNbr') : 0
  const [orderType, setOrderType] = useState('ALL')
  const [metric, setMetric] = useState('LTPA')

  const [open, setOpen] = useState(false)
  const [orderNumber, setOrderNumber] = useState('')
  const [improvementComment, setImprovementComment] = useState('')
  const [emplPrseCmt, setEmplPrseCmt] = useState('')

  const vocDetailQuery = useQuery(['querySwmVocDtl', { strNbr, fpToggle }], getData)

  if (vocDetailQuery.isLoading) {
    return <Loader active>Loading...</Loader>
  }

  // Filter by timeframe
  let dataFilterTf = vocDetailQuery.data.filter((row) => {
    return row[timeframe + '_FLG'] && row
  })

  // Filter by order type (for use in orderType dropdown, details table)
  let dataFilterType = dataFilterTf.filter((row) => {
    if (orderType === 'ALL') {
      return row
    }
    else if (orderType === 'OTHER') {
      return (
        row &&
        row['ORDER_TYPE'] === ''
      )
    }
    else {
      return (
        row &&
        row['ORDER_TYPE'] === orderType
      )
    }
  })


  // Filter by order type
  let bossFilter = dataFilterTf.filter((row) => {
    return row && row['ORDER_TYPE'] === 'BOSS'
  })

  let bopisFilter = dataFilterTf.filter((row) => {
    return row && row['ORDER_TYPE'] === 'BOPIS'
  })

  let mixedFilter = dataFilterTf.filter((row) => {
    return row && row['ORDER_TYPE'] === 'MIXED'
  })

  // Find average by order type and metric (for use in voc overview grid)
  let satisfBoss = bossFilter.length !== 0 ? bossFilter.reduce((sum, obj) => sum + (obj.SATISFACTION_SCORE === 5 ? 1 : 0), 0) / bossFilter.length : null
  let satisfBopis = bopisFilter.length !== 0 ? bopisFilter.reduce((sum, obj) => sum + (obj.SATISFACTION_SCORE === 5 ? 1 : 0), 0) / bopisFilter.length : null
  let satisfMixed = mixedFilter.length !== 0 ? mixedFilter.reduce((sum, obj) => sum + (obj.SATISFACTION_SCORE === 5 ? 1 : 0), 0) / mixedFilter.length : null
  let satisfTotal = dataFilterTf.length !== 0 ? dataFilterTf.reduce((sum, obj) => sum + (obj.SATISFACTION_SCORE === 5 ? 1 : 0), 0) / dataFilterTf.length : null
  let prodCondBoss = bossFilter.length !== 0 ? bossFilter.reduce((sum, obj) => sum + (obj.PRODUCT_CONDITION_RATING === 5 ? 1 : 0), 0) / bossFilter.length : null
  let prodCondBopis = bopisFilter.length !== 0 ? bopisFilter.reduce((sum, obj) => sum + (obj.PRODUCT_CONDITION_RATING === 5 ? 1 : 0), 0) / bopisFilter.length : null
  let prodCondMixed = mixedFilter.length !== 0 ? mixedFilter.reduce((sum, obj) => sum + (obj.PRODUCT_CONDITION_RATING === 5 ? 1 : 0), 0) / mixedFilter.length : null
  let prodCondTotal = dataFilterTf.length !== 0 ? dataFilterTf.reduce((sum, obj) => sum + (obj.PRODUCT_CONDITION_RATING === 5 ? 1 : 0), 0) / dataFilterTf.length : null
  let orderAccBoss = bossFilter.length !== 0 ? bossFilter.reduce((sum, obj) => sum + (obj.ORDER_FULFILLMENT_ACCURACY === 5 ? 1 : 0), 0) / bossFilter.length : null
  let orderAccBopis = bopisFilter.length !== 0 ? bopisFilter.reduce((sum, obj) => sum + (obj.ORDER_FULFILLMENT_ACCURACY === 5 ? 1 : 0), 0) / bopisFilter.length : null
  let orderAccMixed = mixedFilter.length !== 0 ? mixedFilter.reduce((sum, obj) => sum + (obj.ORDER_FULFILLMENT_ACCURACY === 5 ? 1 : 0), 0) / mixedFilter.length : null
  let orderAccTotal = dataFilterTf.length !== 0 ? dataFilterTf.reduce((sum, obj) => sum + (obj.ORDER_FULFILLMENT_ACCURACY === 5 ? 1 : 0), 0) / dataFilterTf.length : null
  let waitTmBoss = bossFilter.length !== 0 ? bossFilter.reduce((sum, obj) => sum + (obj.WAIT_TIME === 5 ? 1 : 0), 0) / bossFilter.length : null
  let waitTmBopis = bopisFilter.length !== 0 ? bopisFilter.reduce((sum, obj) => sum + (obj.WAIT_TIME === 5 ? 1 : 0), 0) / bopisFilter.length : null
  let waitTmMixed = mixedFilter.length !== 0 ? mixedFilter.reduce((sum, obj) => sum + (obj.WAIT_TIME === 5 ? 1 : 0), 0) / mixedFilter.length : null
  let waitTmTotal = dataFilterTf.length !== 0 ? dataFilterTf.reduce((sum, obj) => sum + (obj.WAIT_TIME === 5 ? 1 : 0), 0) / dataFilterTf.length : null

  // VOC Overview Table Rows
  const overviewRows = [
    {
      name: 'LTPA',
      boss: satisfBoss,
      bopis: satisfBopis,
      mixed: satisfMixed,
      total: satisfTotal
    },
    {
      name: 'Product Condition',
      boss: prodCondBoss,
      bopis: prodCondBopis,
      mixed: prodCondMixed,
      total: prodCondTotal
    },
    {
      name: 'Order Accuracy',
      boss: orderAccBoss,
      bopis: orderAccBopis,
      mixed: orderAccMixed,
      total: orderAccTotal
    },
    {
      name: 'Reasonable Wait Time',
      boss: waitTmBoss,
      bopis: waitTmBopis,
      mixed: waitTmMixed,
      total: waitTmTotal
    }
  ]

  // VOC Overview Table Object
  const overviewObject = {
    data: overviewRows.map(row => {
      return [
        {
          stringValue: row.name
        },
        {
          pctValue: row.boss
        },
        {
          pctValue: row.bopis
        },
        {
          pctValue: row.mixed
        },
        {
          pctValue: row.total
        }
      ]
    }),
    headers: [
      { name: '' },
      { name: 'BOSS' },
      { name: 'BOPIS' },
      { name: 'MIXED' },
      { name: 'TOTAL' }
    ]
  };

  // VOC Overview Details Table Object
  const overviewDetailsObject = {
    data: dataFilterType?.map(row => {
      const isShowComments = row['CUSTOMER_IMPROVEMENT_COMMENTS'].length > 0 || row['EMPLOYEE_PRAISE_COMMENTS'].length > 0;
      return [
        {
          stringValue: <>{row['ORDER_NUMBER']}&nbsp;{isShowComments && <Icon fitted style={{ color: 'black', textDecoration: 'none' }} name='comment outline' />}</>,
          customStyle: {
            color: isShowComments ? '#EE7125' : 'black',
            textDecoration: isShowComments && 'underline',
            cursor: isShowComments && 'pointer',
          },
          onClick: () => {
            if (isShowComments) {
              setOpen(true)
              setOrderNumber(row['ORDER_NUMBER'])
              setImprovementComment(row['CUSTOMER_IMPROVEMENT_COMMENTS'])
              setEmplPrseCmt(row['EMPLOYEE_PRAISE_COMMENTS'])
            }
          }
        },
        {
          stringValue: row['RESPONSE_DATE'].toString().slice(0, 10)
        },
        {
          stringValue: row['ORDER_TYPE']
        },
        {
          stringValue: row['CUSTOMER_CONTACT_APPROVAL']
        },
        metric === 'LTPA' && {
          stringValue: row['SATISFACTION_SCORE']
        },
        metric === 'ProdCond' && {
          stringValue: row['PRODUCT_CONDITION_RATING']
        },
        metric === 'OrderAcc' && {
          stringValue: row['ORDER_FULFILLMENT_ACCURACY']
        },
        metric === 'WaitTime' && {
          stringValue: row['WAIT_TIME']
        }
      ].filter(row => Boolean(row));
    }),
    headers: [
      { name: 'Order #' },
      { name: 'Response Date' },
      { name: 'Order Type' },
      { name: 'Cust Contact Approval' },
      metric === 'LTPA' && { name: 'LTPA' },
      metric === 'ProdCond' && { name: 'Product Condition' },
      metric === 'OrderAcc' && { name: 'Order Accuracy' },
      metric === 'WaitTime' && { name: 'Reasonable Wait Time' }
    ].filter(row => Boolean(row)),
    pagination: true,
    sortable: true,
    sortableDefault: 1
  };

  return (
    <Segment fluid>
      <Grid>
        <Grid.Row>
          <Grid.Column width={8} textAlign='center'>
            <Grid.Row>&nbsp;</Grid.Row>
            <Link style={{ color: '#EE7125' }} to={`/store/overview?strNbr=${strNbr}`} data-test='b2o-link'>Back to Overview</Link>
          </Grid.Column>
          <Grid.Column width={8}>
            Timeframe:
            {new Date().getDay() === 1 ?
              <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} /> :
              <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />}
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Header textAlign='center' style={{ textTransform: 'capitalize' }}>VOC Overview - Store {strNbr}, {levelName}</Header>
      <DataTable data={overviewObject} />
      &nbsp;
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            Select Order Type:
            <Dropdown
              fluid
              compact
              selection
              defaultValue='ALL'
              options={[
                { key: 'ALL', text: 'ALL', value: 'ALL' },
                { key: 'BOPIS', text: 'BOPIS', value: 'BOPIS' },
                { key: 'BOSS', text: 'BOSS', value: 'BOSS' },
                { key: 'MIXED', text: 'MIXED', value: 'MIXED' },
                { key: 'OTHER', text: 'OTHER', value: 'OTHER' }
              ]}
              onChange={(_, { value }) => {
                setOrderType(value)
              }}
            />
          </Grid.Column>
          <Grid.Column width={8}>
            Select Metric:
            <Dropdown
              fluid
              compact
              selection
              defaultValue='LTPA'
              options={[
                { key: 'LTPA', text: 'LTPA', value: 'LTPA' },
                { key: 'ProdCond', text: 'Product Condition', value: 'ProdCond' },
                { key: 'OrderAcc', text: 'Order Accuracy', value: 'OrderAcc' },
                { key: 'WaitTime', text: 'Wait Time', value: 'WaitTime' }
              ]}
              onChange={(_, { value }) => {
                setMetric(value)
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Header textAlign='center'>Online VOC Details</Header>
      <DataTable data={overviewDetailsObject} />
      {open && <Modal
        closeIcon
        open={open}
        onClose={() => { setOpen(false) }}
      >
        <Segment color='orange'>
          <Grid textAlign='center'>
            <Grid.Column width={16}>
              <Grid.Row>
                <Header>
                  Order Number: {orderNumber}
                </Header>
              </Grid.Row>
              <Grid.Row>&nbsp;</Grid.Row>
              <Grid.Row>
                <Header textAlign='center' style={{ color: 'red' }}>Customer Comments</Header>
              </Grid.Row>
              <Grid.Row><p textAlign='center'>{improvementComment}</p></Grid.Row>
              <Grid.Row>&nbsp;</Grid.Row>
              <Grid.Row>
                <Header textAlign='center' style={{ color: 'red' }}>Employee Praise Comments</Header>
              </Grid.Row>
              <Grid.Row><p textAlign='center'>{emplPrseCmt}</p></Grid.Row>
              <Grid.Row>&nbsp;</Grid.Row>
              <Grid.Row>
                <Button
                  onClick={() => { setOpen(false) }}
                  style={{ color: 'white', backgroundColor: '#EE7125' }}
                  content='Close'
                />
              </Grid.Row>
              <Grid.Row>&nbsp;</Grid.Row>
            </Grid.Column>
          </Grid>
        </Segment>
      </Modal>}
    </Segment>
  )
})