import React from 'react';
import { Header } from 'semantic-ui-react';
import { DataView } from '../../../../../../components/Common/DataView';

export const TotalLeadsCore = ({ totalRecords, totalLeadsDataObj }) => {
  return (
    <div>
      <br />
      <DataView testId='totalLeads'>
        <DataView.Title position='center'>
          Total Leads - Core
          <Header.Subheader> Data as of {totalRecords[0]?.TRNSFM_DT}</Header.Subheader>
        </DataView.Title>
        <DataView.DataTable data={totalLeadsDataObj} />
      </DataView>
      <br />
      <p>
        *Target is reported as the total leads generated last year through the current period. For example, YTD target is all
        leads generated last year through the upcoming Sunday. Actual Lead counts and vLY are reported through the previous
        day.
      </p>
      <br />
    </div>
  );
};
