import { Tab } from 'semantic-ui-react';
import { SalesDeparmentsTab } from './SalesDeparmentsTab';
import { SalesClassesTab } from './SalesClassesTab';
import { TabMenu } from '../../Common/TabMenu';
import { SalesSubClassesTab } from './SalesSubClassesTab';
import { Top40Btm40Skus } from '../../ProdHierDrilldown/Top40Btm40Skus';
import { useOverviewContext } from '../../../hooks/useOverviewContext';

export const SalesTab = () => {
  const { salesSkuData, timeframe } = useOverviewContext();

  const tabs = [
    {
      menuItem: 'Dept',
      render: () => <SalesDeparmentsTab />
    },
    {
      menuItem: 'Classes',
      render: () => <SalesClassesTab />
    },
    {
      menuItem: 'Subclasses',
      render: () => <SalesSubClassesTab />
    },
    {
      menuItem: 'Skus',
      render: () => <Top40Btm40Skus data={salesSkuData} timeframe={timeframe} />
    }
  ];

  return (
    <Tab.Pane>
      <TabMenu panes={tabs} />
    </Tab.Pane>
  );
};
