import React, { useState, useEffect } from "react";

import {
  Header,
  Grid,
  Table,
  Loader,
  Menu,
  Icon,
  Button,
  Modal,
  Segment,
  Pagination,
} from "semantic-ui-react";
import { withRouter, Link } from "react-router-dom";
import { useQuery } from "react-query";
import { getData } from "../../service/DataService";
import { getDollarString, getPercentString } from "../Common/TableCellUtils";
import { UnitMenu } from '../Common/UnitMenu'

export const DstShrinkSubClass = withRouter(({ fpToggle, location }) => {
  const search = new URLSearchParams(location.search);

  const dstNbr = search.has("dstNbr") ? search.get("dstNbr") : 0;
  const subClassNbr = search.has("subClassNbr") ? search.get("subClassNbr") : 0;

  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [skuSumUnits, setSkuSumUnits] = useState("NOM");
  const [sortColumnSku, setSortColumnSku] = useState("SKU_NBR");
  const [sortDirectionSku, setSortDirectionSku] = useState("ascending");
  const [pageNum, setPageNum] = useState(1);
  let rowsPerPage = 20;

  const shrinkActualsSkuByStrScls = useQuery(
    ["queryShrinkActualsSkuByDstScls", { dstNbr, subClassNbr, fpToggle }],
    getData
  );

  useEffect(() => {
    setPageNum(1)
  }, [sortColumnSku, sortDirectionSku])

  if (shrinkActualsSkuByStrScls.isLoading) {
    return <Loader active>Loading...</Loader>;
  }

  if (!shrinkActualsSkuByStrScls.data[0]) {
    return (
      <Header textAlign="center">
        Invalid District/Class Number {dstNbr}/{subClassNbr}
      </Header>
    );
  }

  const subclassSkuSumData = shrinkActualsSkuByStrScls.data;
  const subClassDesc = subclassSkuSumData[0].SUB_CLASS_DESC;

  let deptNbr = subClassNbr.toString().substring(1, 3)

  let deptNm = subclassSkuSumData.length > 0 ? subclassSkuSumData[0]['DEPT_NM'] :
    deptNbr

  let classNbr = subClassNbr.toString().substring(0, 7)

  let classDesc = subclassSkuSumData.length > 0 ? subclassSkuSumData[0]['CLASS_DESC'] : classNbr

  let skuSumrySorted = subclassSkuSumData.sort((left, right) => {
    let result = left[sortColumnSku] - right[sortColumnSku];
    if (sortColumnSku === "SKU_NBR") {
      result = left.SKU_NBR > right.SKU_NBR ? 1 : -1;
    }
    if (sortColumnSku === "DEPT_NBR") {
      result = left.DEPT_NBR > right.DEPT_NBR ? 1 : -1;
    }
    if (sortColumnSku === "TOTAL_SHRINK_AMT") {
      result = left.TOTAL_SHRINK_AMT > right.TOTAL_SHRINK_AMT ? 1 : -1;
    }
    if (sortColumnSku === "TOTAL_SALES_AMT") {
      if (isNaN(left.TOTAL_SHRINK_AMT / left.TOTAL_SALES_AMT)) { return 1; }
      if (isNaN(right.TOTAL_SHRINK_AMT / right.TOTAL_SALES_AMT)) { return -1; }
      result =
        left.TOTAL_SHRINK_AMT / left.TOTAL_SALES_AMT >
          right.TOTAL_SHRINK_AMT / right.TOTAL_SALES_AMT
          ? 1
          : -1;
    }
    if (sortColumnSku === "LY_TOTAL_SHRINK_AMT") {
      result =
        left.TOTAL_SHRINK_AMT - left.LY_TOTAL_SHRINK_AMT >
          right.TOTAL_SHRINK_AMT - right.LY_TOTAL_SHRINK_AMT
          ? 1
          : -1;
    }
    if (sortColumnSku === "LY_TOTAL_SALES_AMT") {
      const l = left.TOTAL_SHRINK_AMT / left.TOTAL_SALES_AMT - left.LY_TOTAL_SHRINK_AMT / left.LY_TOTAL_SALES_AMT;
      const r = right.TOTAL_SHRINK_AMT / right.TOTAL_SALES_AMT - right.LY_TOTAL_SHRINK_AMT / right.LY_TOTAL_SALES_AMT;
      if (isNaN(l)) { return 1; }
      if (isNaN(r)) { return -1; }
      result = l > r ? 1 : -1;
    }
    if (sortColumnSku === "OPS_SHRINK_AMT") {
      result = left.OPS_SHRINK_AMT > right.OPS_SHRINK_AMT ? 1 : -1;
    }
    if (sortColumnSku === "MAL_SHRINK_AMT") {
      if (isNaN(left.OPS_SHRINK_AMT / left.TOTAL_SALES_AMT)) { return 1; }
      if (isNaN(right.OPS_SHRINK_AMT / right.TOTAL_SALES_AMT)) { return -1; }
      result =
        left.OPS_SHRINK_AMT / left.TOTAL_SALES_AMT >
          right.OPS_SHRINK_AMT / right.TOTAL_SALES_AMT
          ? 1
          : -1;
    }

    if (sortDirectionSku === "descending") {
      result *= -1;
    }
    return result;
  });

  let dataPaginationSku = skuSumrySorted.slice(
    rowsPerPage * (pageNum - 1),
    parseInt(Math.min(rowsPerPage * pageNum, skuSumrySorted.length))
  );

  return (
    <>
      <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/district/shrink?dstNbr=${dstNbr}`} >
        Shrink
      </Link>
      {' / '}
      <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/district/shrink/dept?dstNbr=${dstNbr}&deptNbr=${deptNbr}`} >
        {deptNbr}-{deptNm}
      </Link>
      {' / '}
      <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/district/shrink/class?dstNbr=${dstNbr}&classNbr=${classNbr}`} >
        {classNbr}-{classDesc}
      </Link>
      {' / '}
      {subClassNbr}-{subClassDesc}
      <Header>
        {subClassNbr}-{subClassDesc}
      </Header>
      <Grid>
        <Grid.Column width={8}>
          <Grid.Row>Units:</Grid.Row>
          <Menu compact size="small">
            <UnitMenu
              unit={skuSumUnits}
              setUnit={setSkuSumUnits}
            />
          </Menu>
        </Grid.Column>
        {skuSumrySorted.length > rowsPerPage ? <Grid.Row>
          <Grid.Column width={16} textAlign='center'>
            <Pagination
              size='mini'
              defaultActivePage={1}
              totalPages={Math.floor((skuSumrySorted.length - 1) / rowsPerPage) + 1}
              activePage={pageNum}
              boundaryRange={0}
              siblingRange={1}
              onPageChange={(e, { activePage }) => {
                setPageNum(activePage)
              }}
            />
          </Grid.Column>
        </Grid.Row> : <Grid.Row></Grid.Row>}
      </Grid>
      <Table textAlign="center" unstackable celled sortable size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: "break-word" }}
              sorted={sortColumnSku === "SKU_NBR" ? sortDirectionSku : null}
              onClick={() => {
                if (sortColumnSku !== "SKU_NBR") {
                  setSortColumnSku("SKU_NBR");
                  setSortDirectionSku("ascending");
                } else {
                  sortDirectionSku === "ascending"
                    ? setSortDirectionSku("descending")
                    : setSortDirectionSku("ascending");
                }
              }}
            >
              SKU #{" "}
              {sortColumnSku !== "SKU_NBR" && <Icon fitted name="sort" />}
            </Table.HeaderCell>
            <Table.HeaderCell>SKU Desc</Table.HeaderCell>
            {skuSumUnits === "NOM" && (
              <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                sorted={
                  sortColumnSku === "TOTAL_SHRINK_AMT"
                    ? sortDirectionSku
                    : null
                }
                onClick={() => {
                  if (sortColumnSku !== "TOTAL_SHRINK_AMT") {
                    setSortColumnSku("TOTAL_SHRINK_AMT");
                    setSortDirectionSku("ascending");
                  } else {
                    sortDirectionSku === "ascending"
                      ? setSortDirectionSku("descending")
                      : setSortDirectionSku("ascending");
                  }
                }}
              >
                Shrink ${" "}
                {sortColumnSku !== "TOTAL_SHRINK_AMT" && (
                  <Icon fitted name="sort" />
                )}
              </Table.HeaderCell>
            )}
            {skuSumUnits === "PCT" && (
              <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                sorted={
                  sortColumnSku === "TOTAL_SALES_AMT"
                    ? sortDirectionSku
                    : null
                }
                onClick={() => {
                  if (sortColumnSku !== "TOTAL_SALES_AMT") {
                    setSortColumnSku("TOTAL_SALES_AMT");
                    setSortDirectionSku("ascending");
                  } else {
                    sortDirectionSku === "ascending"
                      ? setSortDirectionSku("descending")
                      : setSortDirectionSku("ascending");
                  }
                }}
              >
                Shrink %{" "}
                {sortColumnSku !== "TOTAL_SALES_AMT" && (
                  <Icon fitted name="sort" />
                )}
              </Table.HeaderCell>
            )}
            {skuSumUnits === "NOM" && (
              <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                sorted={
                  sortColumnSku === "LY_TOTAL_SHRINK_AMT"
                    ? sortDirectionSku
                    : null
                }
                onClick={() => {
                  if (sortColumnSku !== "LY_TOTAL_SHRINK_AMT") {
                    setSortColumnSku("LY_TOTAL_SHRINK_AMT");
                    setSortDirectionSku("ascending");
                  } else {
                    sortDirectionSku === "ascending"
                      ? setSortDirectionSku("descending")
                      : setSortDirectionSku("ascending");
                  }
                }}
              >
                vLY ${" "}
                {sortColumnSku !== "LY_TOTAL_SHRINK_AMT" && (
                  <Icon fitted name="sort" />
                )}
              </Table.HeaderCell>
            )}
            {skuSumUnits === "PCT" && (
              <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                sorted={
                  sortColumnSku === "LY_TOTAL_SALES_AMT"
                    ? sortDirectionSku
                    : null
                }
                onClick={() => {
                  if (sortColumnSku !== "LY_TOTAL_SALES_AMT") {
                    setSortColumnSku("LY_TOTAL_SALES_AMT");
                    setSortDirectionSku("ascending");
                  } else {
                    sortDirectionSku === "ascending"
                      ? setSortDirectionSku("descending")
                      : setSortDirectionSku("ascending");
                  }
                }}
              >
                vLY %{" "}
                {sortColumnSku !== "LY_TOTAL_SALES_AMT" && (
                  <Icon fitted name="sort" />
                )}
              </Table.HeaderCell>
            )}
            {skuSumUnits === "NOM" && (
              <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                sorted={
                  sortColumnSku === "OPS_SHRINK_AMT" ? sortDirectionSku : null
                }
                onClick={() => {
                  if (sortColumnSku !== "OPS_SHRINK_AMT") {
                    setSortColumnSku("OPS_SHRINK_AMT");
                    setSortDirectionSku("ascending");
                  } else {
                    sortDirectionSku === "ascending"
                      ? setSortDirectionSku("descending")
                      : setSortDirectionSku("ascending");
                  }
                }}
              >
                Ops Shrink ${" "}
                {sortColumnSku !== "OPS_SHRINK_AMT" && (
                  <Icon fitted name="sort" />
                )}
              </Table.HeaderCell>
            )}
            {skuSumUnits === "PCT" && (
              <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                sorted={
                  sortColumnSku === "MAL_SHRINK_AMT" ? sortDirectionSku : null
                }
                onClick={() => {
                  if (sortColumnSku !== "MAL_SHRINK_AMT") {
                    setSortColumnSku("MAL_SHRINK_AMT");
                    setSortDirectionSku("ascending");
                  } else {
                    sortDirectionSku === "ascending"
                      ? setSortDirectionSku("descending")
                      : setSortDirectionSku("ascending");
                  }
                }}
              >
                Ops Shrink %{" "}
                {sortColumnSku !== "MAL_SHRINK_AMT" && (
                  <Icon fitted name="sort" />
                )}
              </Table.HeaderCell>
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {dataPaginationSku.map((data) => {
            return (
              <Table.Row>
                <Table.Cell
                  style={{ color: "#EE7125", textDecoration: "underline" }}
                  onClick={() => {
                    setOpen(true);
                    setModalData(data);
                  }}
                >
                  {data["SKU_NBR"].toString().length > 6 // Format SKUs, 10-digit (xxxx-xxx-xxx) versus 6-digit (xxx-xxx)
                    ? data["SKU_NBR"].toString().substring(0, 4) +
                    "-" +
                    data["SKU_NBR"].toString().substring(4, 7) +
                    "-" +
                    data["SKU_NBR"].toString().substring(7)
                    : data["SKU_NBR"].toString().substring(0, 3) +
                    "-" +
                    data["SKU_NBR"].toString().substring(3)}
                </Table.Cell>
                <Table.Cell>{data.SKU_DESC.replaceAll('/', '/\u200b')}</Table.Cell>
                {skuSumUnits === "NOM" && (
                  <Table.Cell>
                    {getDollarString(data.TOTAL_SHRINK_AMT)}
                  </Table.Cell>
                )}
                {skuSumUnits === "PCT" && (
                  <Table.Cell>
                    {getPercentString(
                      data.TOTAL_SHRINK_AMT / data.TOTAL_SALES_AMT, 2, 2
                    )}
                  </Table.Cell>
                )}
                {skuSumUnits === "NOM" && (
                  <Table.Cell>
                    {getDollarString(
                      data.TOTAL_SHRINK_AMT - data.LY_TOTAL_SHRINK_AMT
                    )}
                  </Table.Cell>
                )}
                {skuSumUnits === "PCT" && (
                  <Table.Cell>
                    {getPercentString(
                      data.TOTAL_SHRINK_AMT / data.TOTAL_SALES_AMT -
                      data.LY_TOTAL_SHRINK_AMT / data.LY_TOTAL_SALES_AMT, 2, 2
                    )}
                  </Table.Cell>
                )}
                {skuSumUnits === "NOM" && (
                  <Table.Cell>
                    {getDollarString(data.OPS_SHRINK_AMT)}
                  </Table.Cell>
                )}
                {skuSumUnits === "PCT" && (
                  <Table.Cell>
                    {getPercentString(
                      data.OPS_SHRINK_AMT / data.TOTAL_SALES_AMT, 2, 2
                    )}
                  </Table.Cell>
                )}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      {open && (
        <Modal
          closeIcon
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        >
          <Segment color="orange">
            <Grid>
              <Grid.Column width={16}>
                <Grid.Row>
                  <Header textAlign="center">
                    {modalData["SKU_NBR"].toString().length > 6 // Format SKUs, 10-digit (xxxx-xxx-xxx) versus 6-digit (xxx-xxx)
                      ? modalData["SKU_NBR"].toString().substring(0, 4) +
                      "-" +
                      modalData["SKU_NBR"].toString().substring(4, 7) +
                      "-" +
                      modalData["SKU_NBR"].toString().substring(7)
                      : modalData["SKU_NBR"].toString().substring(0, 3) +
                      "-" +
                      modalData["SKU_NBR"].toString().substring(3)}
                  </Header>
                </Grid.Row>
                <Grid.Row>&nbsp;</Grid.Row>
                <Grid.Row>
                  <Header>Class</Header>
                </Grid.Row>
                <Grid.Row>
                  <p textAlign="center">
                    {modalData["EXT_CLASS_NBR"] +
                      "-" +
                      modalData["CLASS_DESC"]}
                  </p>
                </Grid.Row>
                <Grid.Row>&nbsp;</Grid.Row>
                <Grid.Row>
                  <Header>Subclass</Header>
                </Grid.Row>
                <Grid.Row>
                  <p textAlign="center">
                    {modalData["EXT_SUB_CLASS_NBR"] +
                      "-" +
                      modalData["SUB_CLASS_DESC"]}
                  </p>
                </Grid.Row>
                <Grid.Row>&nbsp;</Grid.Row>
                <Grid.Row>
                  <Header>SKU Description</Header>
                </Grid.Row>
                <Grid.Row>
                  <p textAlign="center">{modalData["SKU_DESC"]}</p>
                </Grid.Row>
              </Grid.Column>
              &nbsp; &nbsp;
              <Grid.Column>
                <Grid.Row>&nbsp;</Grid.Row>
                <Grid.Row>
                  <Button
                    onClick={() => {
                      setOpen(false);
                    }}
                    style={{ color: "white", backgroundColor: "#EE7125" }}
                    content="Close"
                  />
                </Grid.Row>
                <Grid.Row>&nbsp;</Grid.Row>
              </Grid.Column>
            </Grid>
          </Segment>
        </Modal>
      )}
    </>
  );
});