const REGION_DOMAINS = ['storewalk-np.homedepot.com', 'storepulse-np.homedepot.com'];

const isMenuInDevelopment = REGION_DOMAINS.includes(window.location.host) || process.env.NODE_ENV === 'development';

export const LEVEL_OPTIONS = [
  { key: 'store', text: 'Store', value: 'store' },
  { key: 'district', text: 'District', value: 'district' },
  { key: 'region', text: 'Region', value: 'region' },
  isMenuInDevelopment ? { key: 'division', text: 'Division', value: 'division' } : <></>,
  isMenuInDevelopment ? { key: 'core', text: 'Core', value: 'core' } : <></>
];
