
import React, { useState, useEffect } from 'react'
import { Header, Loader, Icon, Grid, Table, Pagination, Dropdown } from 'semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'
import { TimeframeDropdown } from '../Common/TimeframeDropdown'
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon'
import { UnitMenu } from '../Common/UnitMenu'

import { useQuery } from 'react-query'
import {
  getData
} from '../../service/DataService'
import { getPercentString, getThousandDollarString } from '../Common/TableCellUtils'
import { timeframeCode } from '../Common/timeframeCode'

export const AisleBaysByStore = withRouter(({ timeframe, settimeframe, fpToggle, location }) => {

  const [sortColumn, setSortColumn] = useState("SLS_AMT")
  const [sortDirection, setSortDirection] = useState("descending")
  const [unit, setUnit] = useState('NOM')
  const [pageNum, setPageNum] = useState(1)
  const [selectedDepts, setSelectedDepts] = useState([]);
  let rowsPerPage = 50

  const search = new URLSearchParams(location.search)
  const strNbr = search.has('strNbr') ? search.get('strNbr') : 0


  const locStrBayQuery = useQuery(['querySwmLocStrBay', { strNbr, fpToggle }], getData)

  useEffect(() => {
    setPageNum(1)
  }, [timeframe, unit, sortColumn, sortDirection])

  if (locStrBayQuery.isLoading) {
    return (<Loader active>Loading...</Loader>)
  }
//add drop down with raw data.map
  const bayDataFilter = locStrBayQuery.data.filter((row) => {
    return (
      row &&
      row['TRNSFM_TYP_SORT_ID'] === timeframeCode[timeframe] &&
      (selectedDepts.length === 0 || selectedDepts.includes(row.DEPT_NBR))
    )
  })
 
  const uniqueDepartments = locStrBayQuery.data.reduce((unique, item) => {
    return unique.some(dept => dept.DEPT_NBR === item.DEPT_NBR) ? unique : [...unique, item];
  }, []);
  const dropdownOptions = uniqueDepartments.map((dept, index) => ({
    key: index,
    text: `${dept.DEPT_NBR} - ${dept.DEPT_NM}`,
    value: dept.DEPT_NBR,
  }));

  let bayDataSort = bayDataFilter.sort((left, right) => {
    let result = left[sortColumn] - right[sortColumn]
    if (sortColumn === 'BAY') {
      result = left.BAY > right.BAY ? 1 : -1
    }
    if (sortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  let dataPagination = bayDataSort.slice(rowsPerPage * (pageNum - 1), parseInt(Math.min(rowsPerPage * pageNum, bayDataSort.length)))

  return (
    <>
      <Grid>
        {bayDataSort.length > rowsPerPage && (
          <Grid.Row>
            <Grid.Column width={16} textAlign='center'>
              <Pagination
                size='mini'
                defaultActivePage={1}
                totalPages={Math.floor((bayDataSort.length - 1) / rowsPerPage) + 1}
                activePage={pageNum}
                boundaryRange={0}
                siblingRange={1}
                onPageChange={(e, { activePage }) => {
                  setPageNum(activePage);
                }}
              />
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
  
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <Dropdown
              placeholder='FILTER'
              fluid
              search
              multiple
              selection
              options={dropdownOptions}
              onChange={(e, { value }) => setSelectedDepts(value)}
            />
          </Grid.Column>
        </Grid.Row>
        
        <Grid.Row>
          <Grid.Column width={16} textAlign='center'>
            <Header textAlign='center'>
              {sortDirection === 'descending' ? 'Top' : 'Bottom'} Bays
            </Header>
            <Table
              textAlign='center'
              unstackable
              celled
              sortable
              size='small'
              style={{ fontSize: 12 }}
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    sorted={sortColumn === 'BAY' ? sortDirection : null}
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    onClick={() => {
                      if (sortColumn !== 'BAY') {
                        setSortColumn('BAY');
                        setSortDirection('ascending');
                      } else {
                        sortDirection === 'ascending'
                          ? setSortDirection('descending')
                          : setSortDirection('ascending');
                      }
                    }}
                  >
                    Bay {sortColumn !== 'BAY' && <Icon fitted name='sort' />}
                  </Table.HeaderCell>
                  <Table.HeaderCell>DEPT</Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={sortColumn === 'SLS_AMT' ? sortDirection : null}
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    onClick={() => {
                      if (sortColumn !== 'SLS_AMT') {
                        setSortColumn('SLS_AMT');
                        setSortDirection('ascending');
                      } else {
                        sortDirection === 'ascending'
                          ? setSortDirection('descending')
                          : setSortDirection('ascending');
                      }
                    }}
                  >
                    Sales Amt {sortColumn !== 'SLS_AMT' && <Icon fitted name='sort' />}
                  </Table.HeaderCell>
                  {unit === 'NOM' && (
                    <Table.HeaderCell
                      sorted={sortColumn === 'SLS_COMP_NOM' ? sortDirection : null}
                      style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                      onClick={() => {
                        if (sortColumn !== 'SLS_COMP_NOM') {
                          setSortColumn('SLS_COMP_NOM');
                          setSortDirection('ascending');
                        } else {
                          sortDirection === 'ascending'
                            ? setSortDirection('descending')
                            : setSortDirection('ascending');
                        }
                      }}
                    >
                      Sales Comp $ {sortColumn !== 'SLS_COMP_NOM' && <Icon fitted name='sort' />}
                    </Table.HeaderCell>
                  )}
                  {unit === 'PCT' && (
                    <Table.HeaderCell
                      sorted={sortColumn === 'SLS_COMP_PCT' ? sortDirection : null}
                      style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                      onClick={() => {
                        if (sortColumn !== 'SLS_COMP_PCT') {
                          setSortColumn('SLS_COMP_PCT');
                          setSortDirection('ascending');
                        } else {
                          sortDirection === 'ascending'
                            ? setSortDirection('descending')
                            : setSortDirection('ascending');
                        }
                      }}
                    >
                      Sales Comp % {sortColumn !== 'SLS_COMP_PCT' && <Icon fitted name='sort' />}
                    </Table.HeaderCell>
                  )}
                  <Table.HeaderCell
                    sorted={sortColumn === 'UNT_SLS' ? sortDirection : null}
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    onClick={() => {
                      if (sortColumn !== 'UNT_SLS') {
                        setSortColumn('UNT_SLS');
                        setSortDirection('ascending');
                      } else {
                        sortDirection === 'ascending'
                          ? setSortDirection('descending')
                          : setSortDirection('ascending');
                      }
                    }}
                  >
                    Unit Sales {sortColumn !== 'UNT_SLS' && <Icon fitted name='sort' />}
                  </Table.HeaderCell>
                  {unit === 'NOM' && (
                    <Table.HeaderCell
                      sorted={sortColumn === 'UNT_COMP_NOM' ? sortDirection : null}
                      style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                      onClick={() => {
                        if (sortColumn !== 'UNT_COMP_NOM') {
                          setSortColumn('UNT_COMP_NOM');
                          setSortDirection('ascending');
                        } else {
                          sortDirection === 'ascending'
                            ? setSortDirection('descending')
                            : setSortDirection('ascending');
                        }
                      }}
                    >
                      Unit Comp # {sortColumn !== 'UNT_COMP_NOM' && <Icon fitted name='sort' />}
                    </Table.HeaderCell>
                  )}
                  {unit === 'PCT' && (
                    <Table.HeaderCell
                      sorted={sortColumn === 'UNT_COMP_PCT' ? sortDirection : null}
                      style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                      onClick={() => {
                        if (sortColumn !== 'UNT_COMP_PCT') {
                          setSortColumn('UNT_COMP_PCT');
                          setSortDirection('ascending');
                        } else {
                          sortDirection === 'ascending'
                            ? setSortDirection('descending')
                            : setSortDirection('ascending');
                        }
                      }}
                    >
                      Unit Comp % {sortColumn !== 'UNT_COMP_PCT' && <Icon fitted name='sort' />}
                    </Table.HeaderCell>
                  )}
                </Table.Row>
              </Table.Header>
  
              <Table.Body>
                {dataPagination.map((data) => {
                  return (
                    <Table.Row key={data['BAY']}>
                      <Table.Cell>
                        <Link
                          style={{ color: '#EE7125', textDecoration: 'underline' }}
                          to={`/store/baysbystore/bay?strNbr=${strNbr}&bay=${data['BAY']}`}
                        >
                          {data['BAY']}
                        </Link>
                      </Table.Cell>
                      <Table.Cell>{data.DEPT_NBR}</Table.Cell>
                      <Table.Cell style={{ color: data['SLS_AMT'] < 0 ? 'red' : 'black' }}>
                        {getThousandDollarString(data['SLS_AMT'], 1)}
                      </Table.Cell>
                      {unit === 'NOM' && (
                        <Table.Cell
                          style={{
                            color: data['SLS_COMP_NOM'] < 0 ? 'red' : data['SLS_COMP_NOM'] > 0 ? 'green' : 'black',
                          }}
                        >
                          {getThousandDollarString(data['SLS_COMP_NOM'], 1)}
                        </Table.Cell>
                      )}
                      {unit === 'PCT' && (
                        <Table.Cell
                          style={{
                            color: data['SLS_COMP_PCT'] < 0 ? 'red' : data['SLS_COMP_PCT'] > 0 ? 'green' : 'black',
                          }}
                        >
                          {getPercentString(data['SLS_COMP_PCT'])}
                        </Table.Cell>
                      )}
                      <Table.Cell style={{ color: data['UNT_SLS'] < 0 ? 'red' : 'black' }}>
                        {data['UNT_SLS'] != null
                          ? data['UNT_SLS'].toLocaleString(undefined, { maximumFractionDigits: 0 })
                          : 'Loading'}
                      </Table.Cell>
                      {unit === 'NOM' && (
                        <Table.Cell
                          style={{
                            color: data['UNT_COMP_NOM'] < 0 ? 'red' : data['UNT_COMP_NOM'] > 0 ? 'green' : 'black',
                          }}
                        >
                          {data['UNT_COMP_NOM'] != null
                            ? data['UNT_COMP_NOM'].toLocaleString(undefined, { maximumFractionDigits: 0 })
                            : 'Loading'}
                        </Table.Cell>
                      )}
                      {unit === 'PCT' && (
                        <Table.Cell
                          style={{
                            color: data['UNT_COMP_PCT'] < 0 ? 'red' : data['UNT_COMP_PCT'] > 0 ? 'green' : 'black',
                          }}
                        >
                          {getPercentString(data['UNT_COMP_PCT'])}
                        </Table.Cell>
                      )}
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
})