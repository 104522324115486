import { DataView } from "../../../../../../components/Common/DataView";
import { getCompareStyle } from "../../../../../../components/Common/TableCellUtils";
import { formatNumberWithCommas } from "../../../../../../utils/formatter";

export const MeasureBySummaryProgram = ({ data, timeframe, unit, allWebStore, isMonday }) => {
  
  const measureBySumProgram = {
    headers: [
      {
        name: 'Summary Program Name '
      },
      { name: (isMonday && timeframe === 'WTD') ? 'TW Target ' : 'Target ' },
      (isMonday && timeframe === 'WTD') ? { name: 'LW Target ' } : false,
      {
        name: 'Measure '
      },
      {
        name: unit === 'PCT' ? 'VLY% ' : 'VLY '
      }
    ].filter(row => Boolean(row)),
    data: data.map(row => [
      {
        stringValue: row['SUMMARY_PROGRAM_NAME']
      },
      {
        numValue: formatNumberWithCommas(row[timeframe + allWebStore + '_MEAS_TGT']),
        orderBy: row[timeframe + allWebStore + '_MEAS_TGT']
      },
      (isMonday && timeframe === 'WTD') && {
        numValue: formatNumberWithCommas(row['LW' + allWebStore + '_MEAS_TGT']),
        orderBy: row['LW' + allWebStore + '_MEAS_TGT']
      },
      {
        numValue: formatNumberWithCommas(row[timeframe + allWebStore + '_MEAS']),
        orderBy: row[timeframe + allWebStore + '_MEAS']
      },
      unit === 'NOM' ? {
        numValue: formatNumberWithCommas(row[timeframe + allWebStore + '_VLY']),
        orderBy: row[timeframe + allWebStore + '_VLY'],
        customStyle: getCompareStyle(row[timeframe + allWebStore + '_VLY'])
      } : {
        pctValue: row[timeframe + allWebStore + '_VLY_PCT'],
        orderBy: row[timeframe + allWebStore + '_VLY_PCT'],
        customStyle: getCompareStyle(row[timeframe + allWebStore + '_VLY_PCT']),
        decimals: 2
      },
    ].filter(row => Boolean(row))
    ),
    sortable: true,
    sortableDefault: 0
  }

  return (
    <DataView>
      <DataView.Title position="center">
        Measures by Summary Program
      </DataView.Title>
      <DataView.DataTable data={measureBySumProgram} />
    </DataView>
  );
}