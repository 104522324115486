import { useMemo, useState } from 'react';
import { Dropdown, Grid, Header } from 'semantic-ui-react';
import { DataTable } from '../../../../../components/Common/DataTable';
import { ltsaMenuOptions, getLtsaPropsByMetric } from '../../../../../constants/ltsa';

export const Ltsa = ({ data, timeframe }) => {
  const [metric, setMetric] = useState('Ltsa');

  const filterByCustomerType = (data, customerType) => {
    return data.filter(item => item && item['CUSTOMER_TYPE'] === customerType);
  };

  const metricName = useMemo(() => getLtsaPropsByMetric(metric), [metric]);
  const proCustomerData = useMemo(() => filterByCustomerType(data, 'PRO'), [data]);
  const consumerCustomerData = useMemo(() => filterByCustomerType(data, 'CONSUMER'), [data]);

  const combinedCustomerData = useMemo(
    () =>
      consumerCustomerData.map(consumer => {
        const pro = proCustomerData.find(pro => pro['DIV_NBR'] === consumer['DIV_NBR']);
        return {
          pro: pro || {},
          consumer: consumer,
          DIV_NBR: consumer['DIV_NBR']
        };
      }),
    [consumerCustomerData, proCustomerData]
  );

  const dataObj = useMemo(
    () => ({
      sortable: true,
      sortableDefault: 0,
      headers: [{ name: 'Division # ' }, { name: 'Consumer ' }, { name: 'Pro ' }, { name: 'Total ' }],
      data: combinedCustomerData.map(({ DIV_NBR, consumer, pro }) => {
        return [
          {
            stringValue: DIV_NBR,
            link: {
              to: `/division/ltsa`,
              params: { divNbr: DIV_NBR }
            }
          },
          { pctValue: consumer[`${timeframe}${metricName[0]}`] / consumer[`${timeframe}${metricName[1]}`] },
          { pctValue: pro[`${timeframe}${metricName[0]}`] / pro[`${timeframe}${metricName[1]}`] },
          {
            pctValue:
              (consumer[`${timeframe}${metricName[0]}`] + pro[`${timeframe}${metricName[0]}`]) /
              (consumer[`${timeframe}${metricName[1]}`] + pro[`${timeframe}${metricName[1]}`])
          }
        ];
      })
    }),
    [combinedCustomerData, timeframe, metricName]
  );

  return (
    <>
      <Grid>
        <Grid.Row style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
          <Grid.Column width={8}>
            <Header textAlign='center'>LTSA By Divsion</Header>
          </Grid.Column>

          <Grid.Column width={8}>
            Select Metric:
            <Dropdown
              fluid
              compact
              selection
              defaultValue='Ltsa'
              options={ltsaMenuOptions}
              onChange={(_, { value }) => setMetric(value)}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Header textAlign='center'>LTSA by Division</Header>
      <DataTable data={dataObj} />
    </>
  );
};
