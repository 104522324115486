import React, { useState, useEffect } from 'react'
import { Header, Grid, Table, Loader, Pagination, Icon } from 'semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getData } from '../../service/DataService'

export const ShrinkAgedWc = withRouter(({ fpToggle, location }) => {

  const search = new URLSearchParams(location.search)
  const strNbr = search.has('strNbr') ? search.get('strNbr') : 0

  const [sortColumn, setSortColumn] = useState("LN_AVAIL_DT")
  const [sortDirection, setSortDirection] = useState("descending")
  const [pageNum, setPageNum] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(20)

  const shrinkAgedWcQuery = useQuery(['queryShrinkAgedWc', { strNbr, fpToggle }], getData)

  useEffect(() => {
    setPageNum(1)
  }, [sortColumn, sortDirection, rowsPerPage])

  if (shrinkAgedWcQuery.isLoading) {
    return (<Loader active>Loading...</Loader>)
  }

  if (!shrinkAgedWcQuery.data) {
    return (<Header textAlign='center'>Invalid Store Number</Header>)
  }

  const data = shrinkAgedWcQuery.data

  let dataSorted = data.sort((left, right) => {
    let result = left[sortColumn] - right[sortColumn]
    if (sortColumn === 'EXTN_HOST_ORDER_REF') {
      result = left.EXTN_HOST_ORDER_REF > right.EXTN_HOST_ORDER_REF ? 1 : -1
    }
    if (sortColumn === 'ORDER_DATE') {
      result = left.ORDER_DATE > right.ORDER_DATE ? 1 : -1
    }
    if (sortColumn === 'LN_AVAIL_DT') {
      result = left.LN_AVAIL_DT > right.LN_AVAIL_DT ? 1 : -1
    }
    if (sortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  let dataPagination = dataSorted.slice(rowsPerPage * (pageNum - 1), parseInt(Math.min(rowsPerPage * pageNum, dataSorted.length)))

  return (
    <>
      <Grid>
        <Grid.Column width={8} textAlign='left'>
          <Grid.Row><Link style={{ color: '#EE7125' }} to={`/store/shrink?strNbr=${strNbr}`} data-test='b2o-link'>Back to Shrink Overview</Link></Grid.Row>
        </Grid.Column>
        <Grid.Column textAlign='justified' width={8}>
          <Grid.Row>
            Rows per Page:
          </Grid.Row>
          <Grid.Row>
            <div>
              <select
                name="rowsPerPage"
                onChange={e => {
                  setRowsPerPage(e.target.value)
                }}
                value={rowsPerPage}
                style={{ padding: 10 }}
              >
                <option value="20" selected>20</option>
                <option value="40">40</option>
              </select>
            </div>
          </Grid.Row>
        </Grid.Column>
      </Grid>
      <Grid>
        {dataSorted.length > rowsPerPage && <Grid.Row>
          <Grid.Column width={16} textAlign='center'>
            <Pagination
              size='mini'
              defaultActivePage={1}
              totalPages={Math.floor((dataSorted.length - 1) / rowsPerPage) + 1}
              activePage={pageNum}
              boundaryRange={0}
              siblingRange={1}
              onPageChange={(e, { activePage }) => {
                setPageNum(activePage)
              }}
            />
          </Grid.Column>
        </Grid.Row>}
      </Grid>
      <Header textAlign='center'>Aged Will Calls - Store {strNbr}</Header>
      <Table textAlign='center' unstackable celled sortable size='small' style={{ fontSize: 12 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={sortColumn === 'EXTN_HOST_ORDER_REF' ? sortDirection : null}
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumn !== 'EXTN_HOST_ORDER_REF') {
                  setSortColumn('EXTN_HOST_ORDER_REF')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>Order Number {sortColumn !== 'EXTN_HOST_ORDER_REF' && <Icon fitted name='sort' />}</Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
            >Order Lines</Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'ORDER_DATE' ? sortDirection : null}
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumn !== 'ORDER_DATE') {
                  setSortColumn('ORDER_DATE')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>Order Date {sortColumn !== 'ORDER_DATE' && <Icon fitted name='sort' />}</Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'LN_AVAIL_DT' ? sortDirection : null}
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumn !== 'LN_AVAIL_DT') {
                  setSortColumn('LN_AVAIL_DT')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>Available Date {sortColumn !== 'LN_AVAIL_DT' && <Icon fitted name='sort' />}</Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'DAYS_OPEN' ? sortDirection : null}
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumn !== 'DAYS_OPEN') {
                  setSortColumn('DAYS_OPEN')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>Days Open {sortColumn !== 'DAYS_OPEN' && <Icon fitted name='sort' />}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {dataPagination.map((data) => {
            return (
              <Table.Row>
                <Table.Cell>
                  <Link
                    to={{ pathname: `https://orders.apps.homedepot.com/view/${data['EXTN_HOST_ORDER_REF']}` }}
                    style={{ color: '#EE7125', textDecoration: 'underline' }}
                    target="_blank"
                  >{data['EXTN_HOST_ORDER_REF']}</Link></Table.Cell>
                <Table.Cell>{data['ORDER_LINES']}</Table.Cell>
                <Table.Cell>{data['ORDER_DATE']}</Table.Cell>
                <Table.Cell>{data['LN_AVAIL_DT']}</Table.Cell>
                <Table.Cell>{data['DAYS_OPEN']}</Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </>
  )

})