import { getCompareStyle } from '../../../../../../components/Common/TableCellUtils';
import { formatNumberWithCommas } from '../../../../../../utils/formatter';

export const coreLeadsAdapter = (timeframe, allWebStore, coreLead, totalLeads) => {
  const sumProgNmFilter = coreLead.filter(row => {
    return row && row['SUMMARY_PROGRAM_NAME'] !== 'TOTAL';
  });

  let totalRecords = totalLeads;
  if (totalLeads?.length > 0) {
    const totalCoreLeads = totalLeads.reduce((acc, row) => {
      if (!acc.TRNSFM_DT) {
        // Initialize totalCoreLeads with the first row's values
        return { ...row };
      } else {
        acc[timeframe + allWebStore + '_LEADS_TGT'] = (acc[timeframe + allWebStore + '_LEADS_TGT'] || 0) + (row[timeframe + allWebStore + '_LEADS_TGT'] || 0);
        acc['LW' + allWebStore + '_LEADS_TGT'] = (acc['LW' + allWebStore + '_LEADS_TGT'] || 0) + (row['LW' + allWebStore + '_LEADS_TGT'] || 0);
        acc[timeframe + allWebStore + '_LEADS'] = (acc[timeframe + allWebStore + '_LEADS'] || 0) + (row[timeframe + allWebStore + '_LEADS'] || 0);
        acc[timeframe + allWebStore + '_VLY'] = (acc[timeframe + allWebStore + '_VLY'] || 0) + (row[timeframe + allWebStore + '_VLY'] || 0);
        acc[timeframe + allWebStore + '_VLY_PCT'] = (acc[timeframe + allWebStore + '_VLY_PCT'] || 0) + (row[timeframe + allWebStore + '_VLY_PCT'] || 0);
        return acc;
      }
    }, {});
    totalRecords = [totalCoreLeads, ...totalLeads];
  }
  return { sumProgNmFilter, totalRecords };
};

export const isMonday = new Date().getDay() === 1;

export const leadsBySumProgramAdapter = (timeframe, sumProgNmFilter, allWebStore, unit) => ({
  headers: [
    {
      name: 'Summary Program Name '
    },
    { name: isMonday && timeframe === 'WTD' ? 'TW Target ' : 'Target' },
    isMonday && timeframe === 'WTD' ? { name: 'LW Target ' } : false,
    {
      name: 'Leads '
    },
    {
      name: unit === 'PCT' ? 'VLY% ' : 'VLY '
    }
  ].filter(row => Boolean(row)),
  data: sumProgNmFilter.map(row =>
    [
      {
        stringValue: row['SUMMARY_PROGRAM_NAME']
      },
      {
        numValue: formatNumberWithCommas(row[timeframe + allWebStore + '_LEADS_TGT']),
        orderBy: row[timeframe + allWebStore + '_LEADS_TGT']
      },
      isMonday &&
      timeframe === 'WTD' && {
        numValue: formatNumberWithCommas(row['LW' + allWebStore + '_LEADS_TGT']),
        orderBy: row['LW' + allWebStore + '_LEADS_TGT']
      },
      {
        numValue: formatNumberWithCommas(row[timeframe + allWebStore + '_LEADS']),
        orderBy: +row[timeframe + allWebStore + '_LEADS']
      },
      unit === 'NOM'
        ? {
          numValue: formatNumberWithCommas(row[timeframe + allWebStore + '_VLY']),
          orderBy: row[timeframe + allWebStore + '_VLY'],
          customStyle: getCompareStyle(row[timeframe + allWebStore + '_VLY'])
        }
        : {
          pctValue: row[timeframe + allWebStore + '_VLY_PCT'],
          orderBy: row[timeframe + allWebStore + '_VLY_PCT'],
          customStyle: getCompareStyle(row[timeframe + allWebStore + '_VLY_PCT']),
          decimals: 2
        }
    ].filter(row => Boolean(row))
  ),
  sortable: true,
  sortableDefault: 0
});

export const totalLeadsDataObjAdapter = (timeframe, unit, totalRecords, allWebStore) => ({
  headers: [
    { name: 'Org # ' },
    { name: isMonday && timeframe === 'WTD' ? 'TW Target ' : 'Target ' },
    isMonday && timeframe === 'WTD' ? { name: 'LW Target ' } : false,
    { name: 'Leads ' },
    { name: unit === 'PCT' ? 'VLY% ' : 'VLY ' }
  ].filter(row => Boolean(row)),
  data: [...new Set(totalRecords)].map((row, index) =>
    [
      {
        stringValue: index === 0 ? 'CORE' : 'DIV' + " " + row[index === 0 ? '' : 'DIV_NBR'],
        link:
          index > 0
            ? {
              to: `/division/leads?divNbr=${row['DIV_NBR']}`
            }
            : undefined,
        orderBy: row['DIV_NBR']
      },
      {
        numValue: formatNumberWithCommas(row[timeframe + allWebStore + '_LEADS_TGT']),
        orderBy: row[timeframe + allWebStore + '_LEADS_TGT']
      },
      isMonday &&
      timeframe === 'WTD' && {
        numValue: formatNumberWithCommas(row['LW' + allWebStore + '_LEADS_TGT']),
        orderBy: row['LW' + allWebStore + '_LEADS_TGT']
      },
      {
        numValue: formatNumberWithCommas(row[timeframe + allWebStore + '_LEADS']),
        orderBy: row[timeframe + allWebStore + '_LEADS']
      },
      unit === 'NOM'
        ? {
          numValue: formatNumberWithCommas(row[timeframe + allWebStore + '_VLY']),
          orderBy: row[timeframe + allWebStore + '_VLY'],
          customStyle: getCompareStyle(row[timeframe + allWebStore + '_VLY'])
        }
        : {
          pctValue: row[timeframe + allWebStore + '_VLY_PCT'],
          orderBy: row[timeframe + allWebStore + '_VLY_PCT'],
          customStyle: getCompareStyle(row[timeframe + allWebStore + '_VLY_PCT']),
          decimals: 2
        }
    ].filter(row => Boolean(row))
  ),
  sortable: true,
  sortableDefault: 0
});
