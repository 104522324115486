import React, { useMemo } from 'react';
import { Tab, Loader, Header } from "semantic-ui-react";
import { IntradayTable } from '../../../../../../components/HourlyDaily/IntradayTable';
import useQueryApi from '../../../../../../hooks/useQueryApi';
import { findBiggestObject } from '../../../../../../utils/array';
import { CurrentDaySalesCore } from '../../components/hourlySales/CurrentDaySalesCore';
import { IntradayDepartmentViewCore } from '../../components/hourlySales/IntradayDepartmentViewCore';
import { HourlySalesCoreDivision } from '../../components/hourlySales/DivisionList';

export const HourlySales = ({ fpToggle }) => {
  const { data: hourlySalesData = [], isLoading: intradayDataLoading } = useQueryApi('queryCurrentDaySlsIntradayByCore', {
    isCore: true,
    fpToggle
  });

  const { data: deptOverviewQuery = [], isLoading: deptOverviewQueryLoading } = useQueryApi(
    'querySlsIntradayDeptOverviewByCore',
    {
      isCore: true,
      fpToggle
    }
  );

  const { data: hourlySalesQuery = [], isLoading: hourlySalesQueryLoading } = useQueryApi(
    'querySlsIntradayByCore',
    {
      isCore: true,
      fpToggle
    }
  );

  const latestHourData = useMemo(() => {
    if (intradayDataLoading) return {};

    return findBiggestObject(hourlySalesData, 'SLS_HR') || {};
  }, [hourlySalesData, intradayDataLoading]);

  const isLoading = intradayDataLoading || deptOverviewQueryLoading || hourlySalesQueryLoading;

  const panes = [
    {
      menuItem: 'Core',
      render: () => {
        return (
          <>
            <CurrentDaySalesCore latestHourData={latestHourData} />
            <IntradayTable hourlySalesData={hourlySalesData} />
            <IntradayDepartmentViewCore IntradayData={deptOverviewQuery} />
          </>
        )
      }
    },
    {
      menuItem: 'Division List',
      render: () => <HourlySalesCoreDivision hourlySalesDivisionData={hourlySalesQuery} />
    }
  ];


  if (isLoading) {
    return <Loader active>Loading...</Loader>;
  }

  if (!hourlySalesData[0] || !deptOverviewQuery[0]) {
    return <Header textAlign='center'>Invalid Store Number</Header>;
  }

  return <Tab panes={panes} menu={{ secondary: true, pointing: true }} style={{ marginBottom: '1em' }} />;

};
