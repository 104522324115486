import React, { useMemo } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Grid, Header, Loader } from 'semantic-ui-react';
import { DataTable } from '../../../../../../components/Common/DataTable';
import {
  getCalloutStyle,
  getPercentString,
  getThousandDollarString
} from '../../../../../../components/Common/TableCellUtils';
import { IntradayViewTable } from '../../../../../../components/HourlyDaily/IntradayViewTable';
import useQueryApi from '../../../../../../hooks/useQueryApi';
import { useQueryParams } from '../../../../../../hooks/useQueryParams';
import { findBiggestObject } from '../../../../../../utils/array';

export const IntradayDeptViewCore = ({ fpToggle }) => {
  const { deptNbr = 0 } = useQueryParams();
  const deptNbrInt = deptNbr.includes('-') ? parseInt(deptNbr.split('-')[0]) : deptNbr; // NOTE: Current url contains the deptNbr as integer-deptName, we only need the integer

  const { data, isError, isLoading } = useQueryApi('querySlsIntradayDeptByCore', {
    deptNbrInt,
    isCore: true,
    fpToggle,
    deptNbr
  });

  const latestHourData = useMemo(() => {
    if (isLoading) return {};

    return findBiggestObject(data, 'SLS_HR') || {};
  }, [data, isLoading]);

  if (isError) return <p>Something went wrong</p>;
  if (isLoading) return <Loader active>Loading...</Loader>;

  const currentDaySalesObj = {
    data: [
      [
        {
          stringValue: latestHourData['LOCAL_DATE']
        },
        {
          customStyle: getCalloutStyle(latestHourData['CUMMU_SLS']),
          stringValue: getThousandDollarString(latestHourData['CUMMU_SLS'], 1)
        },
        {
          customStyle: getCalloutStyle(latestHourData['DOL_TO_DAILY_PLN']),
          stringValue: getThousandDollarString(latestHourData['DOL_TO_DAILY_PLN'], 1)
        },
        {
          customStyle: getCalloutStyle(latestHourData['PCT_TO_DAILY_PLN']),
          stringValue: getPercentString(latestHourData['PCT_TO_DAILY_PLN'], 1, 1)
        },
        {
          customStyle: getCalloutStyle(latestHourData['COMP_PCT']),
          stringValue: getPercentString(latestHourData['COMP_PCT'], 1, 1)
        },
        {
          customStyle: getCalloutStyle(latestHourData['SLS_PLN_AMT'] - latestHourData['LY_COMP_SLS_AMT']),
          stringValue: getPlannedComp(latestHourData['SLS_PLN_AMT'], latestHourData['LY_COMP_SLS_AMT'])
        }
      ]
    ],
    headers: [
      { name: 'Date' },
      { name: 'Sales $ (k)' },
      { name: '$ vPlan (k)' },
      {
        element: (
          <span>
            % to
            <br />
            Daily Plan
          </span>
        ),
        name: 'to-daily-plan'
      },
      { name: 'Comp %' },
      {
        element: (
          <span>
            Planned
            <br />
            Comp %
          </span>
        ),
        name: 'planned-comp-%'
      }
    ]
  };

  const title = `DEPT ${data[0]?.DEPT_NM} - Core`;

  return (
    <>
      <Grid>
        <Grid.Column width={8} textAlign='left'>
          <Grid.Row>
            <Link style={{ color: '#EE7125' }} to={`/core/intraday`}>
              Back to Intraday
            </Link>
          </Grid.Row>
        </Grid.Column>
      </Grid>
      <Header textAlign='center'>{title}</Header>
      <Header textAlign='center'>Current Day Sales</Header>
      <DataTable data={currentDaySalesObj} />

      <IntradayViewTable data={data} />
    </>
  );
};

const getPlannedComp = (SLS_PLN_AMT, LY_COMP_SLS_AMT) => {
  if (SLS_PLN_AMT == null || LY_COMP_SLS_AMT == null) return '-';

  return getPercentString(SLS_PLN_AMT / LY_COMP_SLS_AMT - 1, 1, 1);
};
