import React from 'react';
import { Tab } from 'semantic-ui-react';
import { DataTable } from '../Common/DataTable';

const DATA_TABLE_HEADERS = [{ name: 'Channel' }, { name: 'Total' }, { name: 'Consumer' }, { name: 'Commercial' }];
const ASSOCIATE = 'ASSOCIATE';
const CHANNEL = 'CHANNEL';

/**
 * Filters the data by the given indicator and timeframe, the indicator will be CHANNEL or ASSOCIATE.
 * If the indicator is ASSOCIATE, then only rows with any applications
 * (consumer, commercial, or total) are returned.
 * @param {array} data Data to be filtered.
 * @param {string} indicator ASSOCIATE or CHANNEL.
 * @param {string} timeframe Timeframe (e.g. 'QTD', 'YTD', etc.).
 * @return {array} Filtered data.
 */
const filterData = (data, indicator, timeframe) => {
  return data.filter(
    row =>
      row &&
      row['ASSOC_CHNL_IND'] === indicator &&
      (indicator === ASSOCIATE
        ? row[`${timeframe}_TOT_APPS`] + row[`${timeframe}_COMM_APPS`] + row[`${timeframe}_CONS_APPS`] > 0
        : true)
  );
};

const generateRows = (structureRows, timeframe) => {
  return structureRows.map(row => [
    { stringValue: row['ASSOC_CHNL'] },
    { numValue: row[`${timeframe}_TOT_APPS`] },
    { numValue: row[`${timeframe}_CONS_APPS`] },
    { numValue: row[`${timeframe}_COMM_APPS`] }
  ]);
};

const createObjectSchema = (data, headers, timeframe, pagination) => ({
  data: generateRows(data, timeframe),
  headers,
  sortable: true,
  pagination: pagination,
  sortableDefault: 0
});

export const ChannelAssociate = ({ credAssocChnlData, timeframe }) => {
  const assocFilter = filterData(credAssocChnlData, ASSOCIATE, timeframe);
  const chnlFilter = filterData(credAssocChnlData, CHANNEL, timeframe);

  const channelObjectSchema = createObjectSchema(chnlFilter, DATA_TABLE_HEADERS, timeframe, false);
  const associateObjectSchema = createObjectSchema(assocFilter, DATA_TABLE_HEADERS, timeframe, true);

  const viewOption = [
    {
      menuItem: 'Channel',
      render: () => (
        <Tab.Pane>
          <DataTable data={channelObjectSchema} />
        </Tab.Pane>
      )
    },
    {
      menuItem: 'Associate',
      render: () => (
        <Tab.Pane>
          <DataTable data={associateObjectSchema} />
        </Tab.Pane>
      )
    }
  ];

  return <Tab panes={viewOption} />;
};
