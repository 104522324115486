import { Header, Tab } from 'semantic-ui-react';
import { Comp } from './comp/Comp';
import { SALES_TAB } from './Tabs/SalesTab';
import { Overview } from './Overview';
import { TimeframeMenu } from '../../../../../components/Menus/TimeFrameMenu';
import { useCoreOverviewContext } from '../../../hooks/useCoreOverviewContext';
export const Core = () => {
  const dataAsOf = '2024-09-12'; // TODO: Replace with actual data
  const { timeframe, settimeframe, unit, setUnit } = useCoreOverviewContext();
  return (
    <>
      <Header>
        Overview - Core
        <Header.Subheader>Data as of {dataAsOf}</Header.Subheader>
      </Header>
      <TimeframeMenu timeframe={timeframe} settimeframe={settimeframe} unit={unit} setUnit={setUnit} showUnits />
      <Overview />
      <Tab panes={OVERVIEW_TABS} />
    </>
  );
};

const OVERVIEW_TABS = [
  {
    menuItem: 'Sales',
    render: () => (
      <Tab.Pane>
        <Tab
          menu={{
            compact: true,
            size: 'small',
            widths: 4
          }}
          panes={SALES_TAB}
        />
      </Tab.Pane>
    )
  },
  { menuItem: 'Comp', render: () => <Comp /> },
  { menuItem: 'Inventory' }
];
