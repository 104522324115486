import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDebounce } from "use-debounce";
import { MAX_SEARCH_TERM_LENGTH, SEARCH_TERM_DELAY } from "../constants/autocomplete";
import { hasFocus } from "../utils/elements";
import { handleFetch } from "../service/DataService";

export const useAutocompleteSuggestions = (level, fpToggle, searchValue, searchInputRef) => {
  const [debouncedSearchValue] = useDebounce(searchValue, SEARCH_TERM_DELAY);
  const [isLoading, setIsLoading] = useState(false);
  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(false);
  const isSearchInputFocused = hasFocus(searchInputRef?.current?.inputRef?.current);
  const isNotCoreLevel = level !== 'core'
  const {
    data = [],
    isError,
    isLoading: isQueryLoading,
    isSuccess,
  } = useQuery([`query/levelSearch/${level}/${debouncedSearchValue}`, { fpToggle }], handleFetch, {
    enabled: isNotCoreLevel && isSearchInputFocused && debouncedSearchValue.length > MAX_SEARCH_TERM_LENGTH
  });

  const closeAutocomplete = () => setIsAutocompleteOpen(false);

  useEffect(() => {
    if (isSearchInputFocused && searchValue.length > MAX_SEARCH_TERM_LENGTH) setIsLoading(isNotCoreLevel);

    setIsAutocompleteOpen(isSearchInputFocused && searchValue.length > MAX_SEARCH_TERM_LENGTH && isNotCoreLevel);
  }, [searchValue, isNotCoreLevel, isSearchInputFocused]);

  useEffect(() => {
    if (isSuccess || isError) setIsLoading(false);
  }, [data, isError, isSuccess]);

  return {
    closeAutocomplete,
    suggestions: data,
    isError,
    isLoading: isLoading || isQueryLoading,
    isAutocompleteOpen,
    isSuccess
  };
}
