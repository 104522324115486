import { useQuery } from "react-query";
import { handleFetch } from "../service/DataService";

export const useFetchLevelName = ({ level, locNbr, fpToggle }) => {
  const { data, isLoading } = useQuery([`query/levelName/${level}/${locNbr}`, { fpToggle }], handleFetch);

  return {
    currentLevelName: data?.levelName,
    isLoadingName: isLoading
  }
}
