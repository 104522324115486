import React, { useState } from 'react'
import {
  Table,
  Tab,
  Icon,
  Grid,
  Header,
  Menu,
} from 'semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'
import { UnitMenu } from '../Common/UnitMenu'
import { getCompareStyle, getCalloutStyle, getDollarString, getPercentString } from '../Common/TableCellUtils'
import { AisleBaysByStore } from './AisleBaysByStore'

export const AisleBayTab = withRouter(({ aisleData, dataSlsDeptByStr, timeframe, location, fpToggle }) => {

  const search = new URLSearchParams(location.search)
  const strNbr = search.has('strNbr') ? search.get('strNbr') : 0

  const [aisleSortColumn, setAisleSortColumn] = useState("AISLE")
  const [aisleSortDirection, setAisleSortDirection] = useState("ascending")
  const [deptSortColumn, setDeptSortColumn] = useState("DEPT_NBR")
  const [deptSortDirection, setDeptSortDirection] = useState("ascending")
  const [selectedOption, setSelectedOption] = useState('aisles');

  const [unit, setUnit] = useState("NOM")
  const [allOrDept, setAllOrDept] = useState("All")

  const timeframeCode = {
    "WTD": 3,
    "MTD": 5,
    "QTD": 7,
    "HTD": 9,
    "YTD": 11,
    "LW": 15,
    "LM": 16,
    "L12": 33
  }

  let aisleDataFiltered = aisleData.filter((row) => {
    return row && row['TRNSFM_TYP_SORT_ID'] === timeframeCode[timeframe]
  })

  let aisleDataSorted = aisleDataFiltered.sort((left, right) => {
    let result = left[aisleSortColumn] - right[aisleSortColumn]
    if (aisleSortColumn === 'AISLE') {
      result = left.AISLE > right.AISLE ? 1 : -1
    }
    if (aisleSortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  let deptDataFiltered = dataSlsDeptByStr.filter((row) => {
    return row && row.DEPT_NBR !== 78
  })

  let deptDataSorted = deptDataFiltered.sort((left, right) => {
    let result = left[deptSortColumn] - right[deptSortColumn]
    if (deptSortColumn === 'DEPT_NBR') {
      result = left.DEPT_NBR > right.DEPT_NBR ? 1 : -1
    }
    if (deptSortDirection === 'descending') {
      result *= -1
    }
    return result
  })
  
  
  
  
  return (
    <Tab.Pane>
      <Grid>
        <Grid.Column width={8}>
          <Menu compact size="small">
            <Menu.Item
              name="AISLES"
              active={selectedOption === 'aisles'}
              onClick={() => setSelectedOption('aisles')}
            />
            <Menu.Item
              name="BAYS"
              active={selectedOption === 'bays'}
              onClick={() => setSelectedOption('bays')}
            />
          </Menu>
          <Grid.Row>Units:</Grid.Row>
          <UnitMenu unit={unit} setUnit={setUnit} />
        </Grid.Column>
        <Grid.Column width={8}>
          {selectedOption === 'aisles' && (
            <>
              <Grid.Row>All or Dept:</Grid.Row>
              <Menu compact size="small">
                <Menu.Item
                  name="All"
                  active={allOrDept === 'All'}
                  content="All"
                  onClick={() => setAllOrDept('All')}
                />
                <Menu.Item
                  name="Dept"
                  active={allOrDept === 'Dept'}
                  content="Dept"
                  onClick={() => setAllOrDept('Dept')}
                />
              </Menu>
              <Grid.Row>&nbsp;</Grid.Row>
              <Grid.Row>
                <Link
                  style={{ color: '#EE7125' }}
                  to={`/store/baysbystore?strNbr=${strNbr}`}
                  data-test="b2o-link"
                >
                  View Bays by Store
                </Link>
              </Grid.Row>
            </>
          ) }
        </Grid.Column>
      </Grid>
      {selectedOption === 'bays' && (<AisleBaysByStore
              strNbr={strNbr}
              dataSlsDeptByStr={dataSlsDeptByStr}
              timeframe={timeframe}
              location={location}
              fpToggle={fpToggle}
            />
          )}
      {allOrDept === 'All' && selectedOption === 'aisles' && (
        <Grid>
          <Grid.Column>
            <Header textAlign="center">All Aisles</Header>
            <Table
              textAlign="center"
              celled
              unstackable
              sortable
              size="small"
              style={{ fontSize: 12 }}
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    sorted={aisleSortColumn === 'AISLE' ? aisleSortDirection : null}
                    style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    onClick={() => {
                      if (aisleSortColumn !== 'AISLE') {
                        setAisleSortColumn('AISLE');
                        setAisleSortDirection('ascending');
                      } else {
                        aisleSortDirection === 'ascending'
                          ? setAisleSortDirection('descending')
                          : setAisleSortDirection('ascending');
                      }
                    }}
                  >
                    Aisle {aisleSortColumn !== 'AISLE' && <Icon fitted name="sort" />}
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={aisleSortColumn === 'SLS_AMT' ? aisleSortDirection : null}
                    style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    onClick={() => {
                      if (aisleSortColumn !== 'SLS_AMT') {
                        setAisleSortColumn('SLS_AMT');
                        setAisleSortDirection('ascending');
                      } else {
                        aisleSortDirection === 'ascending'
                          ? setAisleSortDirection('descending')
                          : setAisleSortDirection('ascending');
                      }
                    }}
                  >
                    Sales Amt {aisleSortColumn !== 'SLS_AMT' && <Icon fitted name="sort" />}
                  </Table.HeaderCell>
                  {unit === 'NOM' && (
                    <Table.HeaderCell
                      sorted={aisleSortColumn === 'SLS_COMP_NOM' ? aisleSortDirection : null}
                      style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                      onClick={() => {
                        if (aisleSortColumn !== 'SLS_COMP_NOM') {
                          setAisleSortColumn('SLS_COMP_NOM');
                          setAisleSortDirection('ascending');
                        } else {
                          aisleSortDirection === 'ascending'
                            ? setAisleSortDirection('descending')
                            : setAisleSortDirection('ascending');
                        }
                      }}
                    >
                      Sales Comp $
                      {aisleSortColumn !== 'SLS_COMP_NOM' && <Icon fitted name="sort" />}
                    </Table.HeaderCell>
                  )}
                  {unit === 'PCT' && (
                    <Table.HeaderCell
                      sorted={aisleSortColumn === 'SLS_COMP_PCT' ? aisleSortDirection : null}
                      style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                      onClick={() => {
                        if (aisleSortColumn !== 'SLS_COMP_PCT') {
                          setAisleSortColumn('SLS_COMP_PCT');
                          setAisleSortDirection('ascending');
                        } else {
                          aisleSortDirection === 'ascending'
                            ? setAisleSortDirection('descending')
                            : setAisleSortDirection('ascending');
                        }
                      }}
                    >
                      Sales Comp %
                      {aisleSortColumn !== 'SLS_COMP_PCT' && <Icon fitted name="sort" />}
                    </Table.HeaderCell>
                  )}
                  <Table.HeaderCell
                    sorted={aisleSortColumn === 'UNT_SLS' ? aisleSortDirection : null}
                    style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    onClick={() => {
                      if (aisleSortColumn !== 'UNT_SLS') {
                        setAisleSortColumn('UNT_SLS');
                        setAisleSortDirection('ascending');
                      } else {
                        aisleSortDirection === 'ascending'
                          ? setAisleSortDirection('descending')
                          : setAisleSortDirection('ascending');
                      }
                    }}
                  >
                    Unit Sales {aisleSortColumn !== 'UNT_SLS' && <Icon fitted name="sort" />}
                  </Table.HeaderCell>
                  {unit === 'NOM' && (
                    <Table.HeaderCell
                      sorted={aisleSortColumn === 'UNT_COMP_NOM' ? aisleSortDirection : null}
                      onClick={() => {
                        if (aisleSortColumn !== 'UNT_COMP_NOM') {
                          setAisleSortColumn('UNT_COMP_NOM');
                          setAisleSortDirection('ascending');
                        } else {
                          aisleSortDirection === 'ascending'
                            ? setAisleSortDirection('descending')
                            : setAisleSortDirection('ascending');
                        }
                      }}
                    >
                      Unit Comp #
                      {aisleSortColumn !== 'UNT_COMP_NOM' && <Icon fitted name="sort" />}
                    </Table.HeaderCell>
                  )}
                  {unit === 'PCT' && (
                    <Table.HeaderCell
                      sorted={aisleSortColumn === 'UNT_COMP_PCT' ? aisleSortDirection : null}
                      style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                      onClick={() => {
                        if (aisleSortColumn !== 'UNT_COMP_PCT') {
                          setAisleSortColumn('UNT_COMP_PCT');
                          setAisleSortDirection('ascending');
                        } else {
                          aisleSortDirection === 'ascending'
                            ? setAisleSortDirection('descending')
                            : setAisleSortDirection('ascending');
                        }
                      }}
                    >
                      Unit Comp %
                      {aisleSortColumn !== 'UNT_COMP_PCT' && <Icon fitted name="sort" />}
                    </Table.HeaderCell>
                  )}
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {aisleDataSorted.map((data) => (
                  <Table.Row key={data['AISLE']}>
                    <Table.Cell>
                      <Link
                        style={{ color: '#EE7125', textDecoration: 'underline' }}
                        to={`/store/aisle?strNbr=${strNbr}&aisle=${data['AISLE']}`}
                      >
                        {data['AISLE']}
                      </Link>
                    </Table.Cell>
                    <Table.Cell style={getCalloutStyle(data['SLS_AMT'])}>
                      {getDollarString(data['SLS_AMT'])}
                    </Table.Cell>
                    {unit === 'NOM' && (
                      <Table.Cell style={getCompareStyle(data['SLS_COMP_NOM'])}>
                        {getDollarString(data['SLS_COMP_NOM'])}
                      </Table.Cell>
                    )}
                    {unit === 'PCT' && (
                      <Table.Cell style={getCompareStyle(data['SLS_COMP_PCT'])}>
                        {getPercentString(data['SLS_COMP_PCT'])}
                      </Table.Cell>
                    )}
                    <Table.Cell style={getCalloutStyle(data['UNT_SLS'])}>
                      {data['UNT_SLS'].toLocaleString(undefined, {
                        maximumFractionDigits: 0,
                      })}
                    </Table.Cell>
                    {unit === 'NOM' && (
                      <Table.Cell style={getCompareStyle(data['UNT_COMP_NOM'])}>
                        {data['UNT_COMP_NOM'].toLocaleString(undefined, {
                          maximumFractionDigits: 0,
                        })}
                      </Table.Cell>
                    )}
                    {unit === 'PCT' && (
                      <Table.Cell style={getCompareStyle(data['UNT_COMP_PCT'])}>
                        {getPercentString(data['UNT_COMP_PCT'])}
                      </Table.Cell>
                    )}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid>
      )}
      {allOrDept === 'Dept' && (
        <Grid>
          <Grid.Column>
            <Header textAlign="center">Select a department:</Header>
            <Table
              textAlign="center"
              celled
              compact
              unstackable
              sortable
              size="small"
              style={{ fontSize: 12 }}
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    sorted={deptSortColumn === 'DEPT_NBR' ? deptSortDirection : null}
                    onClick={() => {
                      if (deptSortColumn !== 'DEPT_NBR') {
                        setDeptSortColumn('DEPT_NBR');
                        setDeptSortDirection('ascending');
                      } else {
                        deptSortDirection === 'ascending'
                          ? setDeptSortDirection('descending')
                          : setDeptSortDirection('ascending');
                      }
                    }}
                  >
                    DEPT {deptSortColumn !== 'DEPT_NBR' && <Icon fitted name="sort" />}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {deptDataSorted.map((data) => (
                  <Table.Row key={data['DEPT_NBR']}>
                    <Table.Cell>
                      <Link
                        style={{ color: '#EE7125', textDecoration: 'underline' }}
                        to={`/store/deptLoc?strNbr=${strNbr}&deptNbr=${data['DEPT_NBR']}`}
                      >
                        {data['MER_DEPT_NM'].replaceAll('/', '/\u200b')}
                      </Link>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid>
      )}
    </Tab.Pane>
  );})
