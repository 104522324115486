import React, { useState } from 'react'
import {
  Table,
  Loader,
  Header,
  Grid,
  Icon,
  Menu,
} from 'semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'
import { UnitMenu } from '../Common/UnitMenu'
import { TimeframeDropdown } from '../Common/TimeframeDropdown'
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon'

import { useQuery } from 'react-query'
import {
  getData
} from '../../service/DataService'
import { getThousandDollarString, getPercentString, getSmdCompareStyle } from '../Common/TableCellUtils'

export const SmdDetailDst = withRouter(({ timeframe, settimeframe, fpToggle, location }) => {

  const search = new URLSearchParams(location.search)
  const dstNbr = search.has('dstNbr') ? search.get('dstNbr') : 0

  const [strDeptRsnCd, setStrDeptRsnCd] = useState("str")

  const [sortColumn, setSortColumn] = useState("MKDN_RSN_CD")
  const [sortDirection, setSortDirection] = useState("ascending")
  const [ovrUndrSortColumn, setOvrUndrSortColumn] = useState('STR_NBR')
  const [ovrUndrSortDirection, setOvrUndrSortDirection] = useState("ascending")
  const [sortColumnDept, setSortColumnDept] = useState("DEPT_NBR")
  const [sortDirectionDept, setSortDirectionDept] = useState("ascending")
  const [unit, setUnit] = useState('NOM')

  const smdDetailQuery = useQuery(['querySmdByDst', { dstNbr, fpToggle }], getData)
  const smdOvrUndrQuery = useQuery(['querySwmSmdOvrUndrByDst', { dstNbr, fpToggle }], getData)
  const smdDeptQuery = useQuery(['querySmdDeptByDst', { dstNbr, fpToggle }], getData)


  if (smdDetailQuery.isLoading || smdOvrUndrQuery.isLoading || smdDeptQuery.isLoading) {
    return <Loader active>Loading...</Loader>
  }

  let rsnCdFiltered = smdDetailQuery.data.filter(row => {
    return row && row['MKDN_RSN_CD'] !== 7
  })

  let storeUseFilter = smdDetailQuery.data.filter(row => {
    return row && row['MKDN_RSN_CD'] === 7
  })

  let rsnCdSorted = rsnCdFiltered.sort((left, right) => {
    let result = left[sortColumn] - right[sortColumn]
    if (sortColumn === 'MKDN_RSN_CD') {
      result = left.MKDN_RSN_CD > right.MKDN_RSN_CD ? 1 : -1
    }
    if (sortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  let deptFiltered = smdDeptQuery.data.filter(row => {
    return row && row['METRIC_LEVEL'] === 'DST'
  })

  let deptSorted = deptFiltered.sort((left, right) => {
    let result = left[sortColumnDept] - right[sortColumnDept]
    if (sortColumnDept === timeframe + '_SMD_PCT') {
      result = (left[timeframe + '_SMD'] / left['TY_' + timeframe + '_SLS_AMT']) - (right[timeframe + '_SMD'] / right['TY_' + timeframe + '_SLS_AMT'])
    }
    if (sortColumnDept === timeframe + '_VP') {
      result = (left[timeframe + '_SMD'] - left[timeframe + '_MKDN_PLN_AMT'] * left['TY_' + timeframe + '_SLS_AMT'] / left[timeframe + '_SLS_PLN_AMT']) 
      - (right[timeframe + '_SMD'] - right[timeframe + '_MKDN_PLN_AMT'] * right['TY_' + timeframe + '_SLS_AMT'] / right[timeframe + '_SLS_PLN_AMT'])
    }
    if (sortColumnDept === timeframe + '_VP_BPS') {
      result = (left[timeframe + '_SMD'] / left['TY_' + timeframe + '_SLS_AMT'] - left[timeframe + '_MKDN_PLN_AMT'] / left[timeframe + '_SLS_PLN_AMT'])
        - (right[timeframe + '_SMD'] / right['TY_' + timeframe + '_SLS_AMT'] - right[timeframe + '_MKDN_PLN_AMT'] / right[timeframe + '_SLS_PLN_AMT'])
    }
    if (sortDirectionDept === 'descending') {
      result *= -1
    }
    return result
  })

  let ovrUndrDataSorted = smdOvrUndrQuery.data.sort((left, right) => {
    let result = left[ovrUndrSortColumn] - right[ovrUndrSortColumn]
    if (ovrUndrSortDirection === 'descending') {
      result *= -1
    }
    return result
  })


  return (
    <>
      <Grid>
        <Grid.Column width={8}>
          <Grid.Row>&nbsp;</Grid.Row>
          <Grid.Row>
            <Link style={{ color: '#EE7125' }} to={`/district/overview?dstNbr=${dstNbr}`} data-test='b2o-link'>Back to Overview</Link>
          </Grid.Row>

        </Grid.Column>
        <Grid.Column width={8}>
          <Grid.Row>Timeframe:</Grid.Row>
          <Grid.Row>
            {new Date().getDay() === 1 ?
              <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} /> :
              <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />}
          </Grid.Row>
        </Grid.Column>
      </Grid>

      <Grid>
        <Grid.Column>
          <Menu size='small' widths={3}>
            <Menu.Item
              name='str'
              active={strDeptRsnCd === 'str'}
              content='Stores'
              onClick={() => {
                setStrDeptRsnCd('str')
              }}
            />
            <Menu.Item
              name='dept'
              active={strDeptRsnCd === 'dept'}
              content='Depts'
              onClick={() => {
                setStrDeptRsnCd('dept')
              }}
            />
            <Menu.Item
              name='rsnCd'
              active={strDeptRsnCd === 'rsnCd'}
              content='Reason Codes'
              onClick={() => {
                setStrDeptRsnCd('rsnCd')
              }}
            />
          </Menu>
        </Grid.Column>
      </Grid>

      {strDeptRsnCd === 'str' && <Header textAlign='center'>SMD - Store Comps</Header>}
      {strDeptRsnCd === 'str' && <Table textAlign='center' fixed unstackable sortable celled size='small'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={ovrUndrSortColumn === 'STR_NBR' ? ovrUndrSortDirection : null}
              onClick={() => {
                if (ovrUndrSortColumn !== 'STR_NBR') {
                  setOvrUndrSortColumn('STR_NBR')
                  setOvrUndrSortDirection('ascending')
                } else {
                  ovrUndrSortDirection === 'ascending' ? setOvrUndrSortDirection('descending') : setOvrUndrSortDirection('ascending')
                }
              }}
            >Org # {ovrUndrSortColumn !== 'STR_NBR' && <Icon fitted name='sort' />}</Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={ovrUndrSortColumn === timeframe + '_SMD_AMT' ? ovrUndrSortDirection : null}
              onClick={() => {
                if (ovrUndrSortColumn !== timeframe + '_SMD_AMT') {
                  setOvrUndrSortColumn(timeframe + '_SMD_AMT')
                  setOvrUndrSortDirection('ascending')
                } else {
                  ovrUndrSortDirection === 'ascending' ? setOvrUndrSortDirection('descending') : setOvrUndrSortDirection('ascending')
                }
              }}
            >SMD $ {ovrUndrSortColumn !== timeframe + '_SMD_AMT' && <Icon fitted name='sort' />}</Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={ovrUndrSortColumn === timeframe + '_SMD_ACT' ? ovrUndrSortDirection : null}
              onClick={() => {
                if (ovrUndrSortColumn !== timeframe + '_SMD_ACT') {
                  setOvrUndrSortColumn(timeframe + '_SMD_ACT')
                  setOvrUndrSortDirection('ascending')
                } else {
                  ovrUndrSortDirection === 'ascending' ? setOvrUndrSortDirection('descending') : setOvrUndrSortDirection('ascending')
                }
              }}
            >SMD % {ovrUndrSortColumn !== timeframe + '_SMD_ACT' && <Icon fitted name='sort' />}</Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={ovrUndrSortColumn === timeframe + '_SMD_BPS_VP' ? ovrUndrSortDirection : null}
              onClick={() => {
                if (ovrUndrSortColumn !== timeframe + '_SMD_BPS_VP') {
                  setOvrUndrSortColumn(timeframe + '_SMD_BPS_VP')
                  setOvrUndrSortDirection('ascending')
                } else {
                  ovrUndrSortDirection === 'ascending' ? setOvrUndrSortDirection('descending') : setOvrUndrSortDirection('ascending')
                }
              }}
            >SMD bps vP {ovrUndrSortColumn !== timeframe + '_SMD_BPS_VP' && <Icon fitted name='sort' />}</Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={ovrUndrSortColumn === timeframe + '_SMD_OVER_UNDER' ? ovrUndrSortDirection : null}
              onClick={() => {
                if (ovrUndrSortColumn !== timeframe + '_SMD_OVER_UNDER') {
                  setOvrUndrSortColumn(timeframe + '_SMD_OVER_UNDER')
                  setOvrUndrSortDirection('ascending')
                } else {
                  ovrUndrSortDirection === 'ascending' ? setOvrUndrSortDirection('descending') : setOvrUndrSortDirection('ascending')
                }
              }}
            >SMD $ Over Under {ovrUndrSortColumn !== timeframe + '_SMD_OVER_UNDER' && <Icon fitted name='sort' />}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            ovrUndrDataSorted.map((data) => {
              return (
                <Table.Row>
                  {<Table.Cell>
                    {data['METRIC_LEVEL'] === 'DST' ?
                      'DST ' + data['DST_NBR'] :
                      <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/smd?strNbr=${data['STR_NBR']}`} >
                        {'STR ' + data['STR_NBR']}
                      </Link>
                    }
                  </Table.Cell>}
                  <Table.Cell>{getThousandDollarString(data[timeframe + '_SMD_AMT'], 1)}</Table.Cell>
                  <Table.Cell>{getPercentString(data[timeframe + '_SMD_ACT'], 2)}</Table.Cell>
                  <Table.Cell
                    style={getSmdCompareStyle(data[timeframe + '_SMD_BPS_VP'])}
                  >{data[timeframe + '_SMD_BPS_VP'] ? data[timeframe + '_SMD_BPS_VP'].toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' bps' : '-'}
                  </Table.Cell>
                  <Table.Cell
                    style={getSmdCompareStyle(data[timeframe + '_SMD_OVER_UNDER'])}
                  >{getThousandDollarString(data[timeframe + '_SMD_OVER_UNDER'],1)}</Table.Cell>
                </Table.Row>
              )
            })
          }

        </Table.Body>
      </Table>}

      {strDeptRsnCd === 'dept' && <Header textAlign='center'>SMD - Departments</Header>}
      {strDeptRsnCd === 'dept' && <Table textAlign='center' unstackable celled sortable size='small'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={sortColumnDept === 'DEPT_NBR' ? sortDirectionDept : null}
              onClick={() => {
                if (sortColumnDept !== 'DEPT_NBR') {
                  setSortColumnDept('DEPT_NBR')
                  setSortDirectionDept('ascending')
                } else {
                  sortDirectionDept === 'ascending' ? setSortDirectionDept('descending') : setSortDirectionDept('ascending')
                }
              }}>
              Dept # {sortColumnDept !== 'DEPT_NBR' && <Icon fitted name='sort' />}
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={sortColumnDept === timeframe + '_SMD' ? sortDirectionDept : null}
              onClick={() => {
                if (sortColumnDept !== timeframe + '_SMD') {
                  setSortColumnDept(timeframe + '_SMD')
                  setSortDirectionDept('ascending')
                } else {
                  sortDirectionDept === 'ascending' ? setSortDirectionDept('descending') : setSortDirectionDept('ascending')
                }
              }}>
              SMD {sortColumnDept !== timeframe + '_SMD' && <Icon fitted name='sort' />}
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={sortColumnDept === timeframe + '_SMD_PCT' ? sortDirectionDept : null}
              onClick={() => {
                if (sortColumnDept !== timeframe + '_SMD_PCT') {
                  setSortColumnDept(timeframe + '_SMD_PCT')
                  setSortDirectionDept('ascending')
                } else {
                  sortDirectionDept === 'ascending' ? setSortDirectionDept('descending') : setSortDirectionDept('ascending')
                }
              }}>
              SMD % {sortColumnDept !== timeframe + '_SMD_PCT' && <Icon fitted name='sort' />}
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={sortColumnDept === timeframe + '_VP_BPS' ? sortDirectionDept : null}
              onClick={() => {
                if (sortColumnDept !== timeframe + '_VP_BPS') {
                  setSortColumnDept(timeframe + '_VP_BPS')
                  setSortDirectionDept('ascending')
                } else {
                  sortDirectionDept === 'ascending' ? setSortDirectionDept('descending') : setSortDirectionDept('ascending')
                }
              }}>
              SMD bps vP {sortColumnDept !== timeframe + '_VP_BPS' && <Icon fitted name='sort' />}
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={sortColumnDept === timeframe + '_VP' ? sortDirectionDept : null}
              onClick={() => {
                if (sortColumnDept !== timeframe + '_VP') {
                  setSortColumnDept(timeframe + '_VP')
                  setSortDirectionDept('ascending')
                } else {
                  sortDirectionDept === 'ascending' ? setSortDirectionDept('descending') : setSortDirectionDept('ascending')
                }
              }}>
              SMD $ Over Under {sortColumnDept !== timeframe + '_VP' && <Icon fitted name='sort' />}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {deptSorted.map((data) => {
            return (
              <Table.Row>
                <Table.Cell>
                  <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/district/smd/dept?dstNbr=${dstNbr}&deptNbr=${data['DEPT_NBR']}`} >
                    {data['DEPT_NBR']}-{data['DEPT_NM'].toUpperCase().replace(/\//g, ' ')}
                  </Link>
                </Table.Cell>
                <Table.Cell>{getThousandDollarString(data[timeframe + '_SMD'],1)}</Table.Cell>
                <Table.Cell singleLine>{getPercentString(data[timeframe + '_SMD'] / data['TY_' + timeframe + '_SLS_AMT'], 2)}</Table.Cell>
                <Table.Cell
                  singleLine
                  style={{ color: data[timeframe + '_SMD'] / data['TY_' + timeframe + '_SLS_AMT'] - data[timeframe + '_MKDN_PLN_AMT'] / data[timeframe + '_SLS_PLN_AMT'] > 0 ? 'red' : 'green' }}
                >{(10000 * (data[timeframe + '_SMD'] / data['TY_' + timeframe + '_SLS_AMT'] - data[timeframe + '_MKDN_PLN_AMT'] / data[timeframe + '_SLS_PLN_AMT'])).toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' bps'}</Table.Cell>
                <Table.Cell
                  singleLine
                  style={{ color: data[timeframe + '_SMD'] - data[timeframe + '_MKDN_PLN_AMT'] * data['TY_' + timeframe + '_SLS_AMT'] / data[timeframe + '_SLS_PLN_AMT'] > 0 ? 'red' : 'green' }}
                >{getThousandDollarString(data[timeframe + '_SMD'] - data[timeframe + '_MKDN_PLN_AMT'] * data['TY_' + timeframe + '_SLS_AMT'] / data[timeframe + '_SLS_PLN_AMT'],1)}</Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>}

      {strDeptRsnCd === 'rsnCd' && <Grid>
        <Grid.Column width={8}>
          <Grid.Row>Units:</Grid.Row>
          <Grid.Row>
            <UnitMenu unit={unit} setUnit={setUnit} />
          </Grid.Row>
        </Grid.Column>
      </Grid>}
      {strDeptRsnCd === 'rsnCd' && <Header textAlign='center'>SMD - Reason Codes</Header>}
      {strDeptRsnCd === 'rsnCd' && <Table textAlign='center' unstackable celled sortable size='small'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
            style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={sortColumn === 'MKDN_RSN_CD' ? sortDirection : null}
              onClick={() => {
                if (sortColumn !== 'MKDN_RSN_CD') {
                  setSortColumn('MKDN_RSN_CD')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>
              Reason Code {sortColumn !== 'MKDN_RSN_CD' && <Icon fitted name='sort' />}
            </Table.HeaderCell>
            {unit === 'NOM' && <Table.HeaderCell
            style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={sortColumn === timeframe + '_SMD' ? sortDirection : null}
              onClick={() => {
                if (sortColumn !== timeframe + '_SMD') {
                  setSortColumn(timeframe + '_SMD')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>
              SMD {sortColumn !== timeframe + '_SMD' && <Icon fitted name='sort' />}
            </Table.HeaderCell>}
            {unit === 'PCT' && <Table.HeaderCell
            style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={sortColumn === timeframe + '_SMD_PCT' ? sortDirection : null}
              onClick={() => {
                if (sortColumn !== timeframe + '_SMD_PCT') {
                  setSortColumn(timeframe + '_SMD_PCT')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>
              SMD {sortColumn !== timeframe + '_SMD_PCT' && <Icon fitted name='sort' />}
            </Table.HeaderCell>}
            {unit === 'PCT' && <Table.HeaderCell
            style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={sortColumn === timeframe + '_SMD_VLY_BPS' ? sortDirection : null}
              onClick={() => {
                if (sortColumn !== timeframe + '_SMD_VLY_BPS') {
                  setSortColumn(timeframe + '_SMD_VLY_BPS')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>
              vLY {sortColumn !== timeframe + '_SMD_VLY_BPS' && <Icon fitted name='sort' />}
            </Table.HeaderCell>}
            {unit === 'NOM' && <Table.HeaderCell
            style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={sortColumn === timeframe + '_SMD_VLY_DOL' ? sortDirection : null}
              onClick={() => {
                if (sortColumn !== timeframe + '_SMD_VLY_DOL') {
                  setSortColumn(timeframe + '_SMD_VLY_DOL')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>
              vLY {sortColumn !== timeframe + '_SMD_VLY_DOL' && <Icon fitted name='sort' />}
            </Table.HeaderCell>}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {rsnCdSorted.map((data) => {
            return (
              <Table.Row>
                <Table.Cell>
                  <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/district/smd/reason?dstNbr=${dstNbr}&mkdnRsnCd=${data['MKDN_RSN_CD']}`} >
                    {data['MKDN_RSN_CD']}-{data['MKDN_RSN_DESC'].toUpperCase()}
                  </Link>
                </Table.Cell>
                {unit === 'NOM' && <Table.Cell singleLine>
                  {data[timeframe + '_SMD'] != null ?
                    data[timeframe + '_SMD'] >= 0 ?
                      '$' + data[timeframe + '_SMD'].toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 3 }) :
                      '-$' + (-1 * data[timeframe + '_SMD']).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 3 }) :
                    'Loading'}
                </Table.Cell>}
                {unit === 'PCT' && <Table.Cell singleLine>
                  {data[timeframe + '_SMD_PCT'] != null ?
                    (100 * data[timeframe + '_SMD_PCT']).toLocaleString(undefined, { maximumFractionDigits: 3 }) :
                    'Loading'}
                </Table.Cell>}
                {unit === 'PCT' && <Table.Cell singleLine style={{ color: data[timeframe + '_SMD_VLY_BPS'] < 0 ? 'green' : data[timeframe + '_SMD_VLY_BPS'] > 0 ? 'red' : 'black' }}>
                  {data[timeframe + '_SMD_VLY_BPS'] != null ?
                    data[timeframe + '_SMD_VLY_BPS'].toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' bps' :
                    'Loading'}
                </Table.Cell>}
                {unit === 'NOM' && <Table.Cell singleLine style={{ color: data[timeframe + '_SMD_VLY_DOL'] < 0 ? 'green' : data[timeframe + '_SMD_VLY_DOL'] > 0 ? 'red' : 'black' }}>
                  {data[timeframe + '_SMD_VLY_DOL'] != null ?
                    data[timeframe + '_SMD_VLY_DOL'] >= 0 ?
                      '$' + data[timeframe + '_SMD_VLY_DOL'].toLocaleString(undefined, { maximumFractionDigits: 0 }) :
                      '-$' + (-1 * data[timeframe + '_SMD_VLY_DOL']).toLocaleString(undefined, { maximumFractionDigits: 0 }) :
                    'Loading'}
                </Table.Cell>}
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>}

      {strDeptRsnCd === 'rsnCd' && <Header textAlign='center'>Reason Codes - Store Use</Header>}
      {strDeptRsnCd === 'rsnCd' && <Table textAlign='center' unstackable celled sortable size='small'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
            style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={sortColumn === 'MKDN_RSN_CD' ? sortDirection : null}
              onClick={() => {
                if (sortColumn !== 'MKDN_RSN_CD') {
                  setSortColumn('MKDN_RSN_CD')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>
              Reason Code {sortColumn !== 'MKDN_RSN_CD' && <Icon fitted name='sort' />}
            </Table.HeaderCell>
            {unit === 'NOM' && <Table.HeaderCell
            style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={sortColumn === timeframe + '_SMD' ? sortDirection : null}
              onClick={() => {
                if (sortColumn !== timeframe + '_SMD') {
                  setSortColumn(timeframe + '_SMD')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>
              SMD {sortColumn !== timeframe + '_SMD' && <Icon fitted name='sort' />}
            </Table.HeaderCell>}
            {unit === 'PCT' && <Table.HeaderCell
            style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={sortColumn === timeframe + '_SMD_PCT' ? sortDirection : null}
              onClick={() => {
                if (sortColumn !== timeframe + '_SMD_PCT') {
                  setSortColumn(timeframe + '_SMD_PCT')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>
              SMD {sortColumn !== timeframe + '_SMD_PCT' && <Icon fitted name='sort' />}
            </Table.HeaderCell>}
            {unit === 'PCT' && <Table.HeaderCell
            style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={sortColumn === timeframe + '_SMD_VLY_BPS' ? sortDirection : null}
              onClick={() => {
                if (sortColumn !== timeframe + '_SMD_VLY_BPS') {
                  setSortColumn(timeframe + '_SMD_VLY_BPS')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>
              vLY {sortColumn !== timeframe + '_SMD_VLY_BPS' && <Icon fitted name='sort' />}
            </Table.HeaderCell>}
            {unit === 'NOM' && <Table.HeaderCell
            style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={sortColumn === timeframe + '_SMD_VLY_DOL' ? sortDirection : null}
              onClick={() => {
                if (sortColumn !== timeframe + '_SMD_VLY_DOL') {
                  setSortColumn(timeframe + '_SMD_VLY_DOL')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>
              vLY {sortColumn !== timeframe + '_SMD_VLY_DOL' && <Icon fitted name='sort' />}
            </Table.HeaderCell>}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {storeUseFilter.map((data) => {
            return (
              <Table.Row>
                <Table.Cell>
                  <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/district/smd/reason?dstNbr=${dstNbr}&mkdnRsnCd=${data['MKDN_RSN_CD']}`} >
                    {data['MKDN_RSN_CD']}-{data['MKDN_RSN_DESC'].toUpperCase()}
                  </Link>
                </Table.Cell>
                {unit === 'NOM' && <Table.Cell singleLine>
                  {data[timeframe + '_SMD'] != null ?
                    data[timeframe + '_SMD'] >= 0 ?
                      '$' + data[timeframe + '_SMD'].toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 3 }) :
                      '-$' + (-1 * data[timeframe + '_SMD']).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 3 }) :
                    'Loading'}
                </Table.Cell>}
                {unit === 'PCT' && <Table.Cell singleLine>
                  {data[timeframe + '_SMD_PCT'] != null ?
                    (100 * data[timeframe + '_SMD_PCT']).toLocaleString(undefined, { maximumFractionDigits: 3 }) :
                    'Loading'}
                </Table.Cell>}
                {unit === 'PCT' && <Table.Cell singleLine style={{ color: data[timeframe + '_SMD_VLY_BPS'] < 0 ? 'green' : data[timeframe + '_SMD_VLY_BPS'] > 0 ? 'red' : 'black' }}>
                  {data[timeframe + '_SMD_VLY_BPS'] != null ?
                    data[timeframe + '_SMD_VLY_BPS'].toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' bps' :
                    'Loading'}
                </Table.Cell>}
                {unit === 'NOM' && <Table.Cell singleLine style={{ color: data[timeframe + '_SMD_VLY_DOL'] < 0 ? 'green' : data[timeframe + '_SMD_VLY_DOL'] > 0 ? 'red' : 'black' }}>
                  {data[timeframe + '_SMD_VLY_DOL'] != null ?
                    data[timeframe + '_SMD_VLY_DOL'] >= 0 ?
                      '$' + data[timeframe + '_SMD_VLY_DOL'].toLocaleString(undefined, { maximumFractionDigits: 0 }) :
                      '-$' + (-1 * data[timeframe + '_SMD_VLY_DOL']).toLocaleString(undefined, { maximumFractionDigits: 0 }) :
                    'Loading'}
                </Table.Cell>}
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>}
      {strDeptRsnCd === 'rsnCd' && <p>Note: Reason Code 7 - Store Use is not part of store markdowns plan and budget.</p>}
    </>
  )
})