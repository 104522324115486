import { Segment, Tab } from 'semantic-ui-react';

import LaborDetailContextProvider from '../../context/LaborDetailContext';
import { LaborDetailsOverview } from './Region/LaborDetailsOverview';
import { LaborListTab } from './Region/LaborListTab';
import { useParams } from 'react-router-dom';

const tabsByLevel = {
  region: {
    main: 'Region',
    list: 'District List'
  },
  division: {
    main: 'Division',
    list: 'Region List'
  },
}

export const LaborContextProvider = ({ fpToggle, settimeframe, timeframe, userEmplCtgryNm, levelName }) => {
  const { level } = useParams()
  const panes = [
    {
      menuItem: tabsByLevel[level].main,
      render: () => <LaborDetailsOverview />
    },
    {
      menuItem: tabsByLevel[level].list,
      render: () => <LaborListTab />
    }
  ];

  return (
    <LaborDetailContextProvider
      fpToggle={fpToggle}
      settimeframe={settimeframe}
      timeframe={timeframe}
      userEmplCtgryNm={userEmplCtgryNm}
      levelName={levelName}
      level={level}
    >
      <Segment>
        <Tab panes={panes} />
      </Segment>
    </LaborDetailContextProvider>
  );
};
