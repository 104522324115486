import React from "react";
import { Menu } from "semantic-ui-react";

export const UnitsSellMenu = ({ salesOrUnits, setSalesOrUnits }) => {
  return (
    <Menu compact size="small">
      <Menu.Item
        name="Sales $"
        active={salesOrUnits === "Sales $"}
        content="SALES $"
        onClick={() => {
          setSalesOrUnits("Sales $");
        }}
      />
      <Menu.Item
        name="Units #"
        active={salesOrUnits === "Units #"}
        content="UNIT #"
        onClick={() => {
          setSalesOrUnits("Units #");
        }}
      /> 
    </Menu>
  );
};
