import React from 'react';
import { CurrentDaySales } from './CurrentDaySales';
import { IntradayDepartmentViewComponent } from './IntradayDeparmentViewComponent';
import { IntradayTable } from './IntradayTable';
import { useIntradayContext } from '../../hooks/useIntradayContext';

export const IntradayView = () => {

  const { currentLevelName, latestHourData, locNbr, level, deptOverviewQuery, hourlySalesData } = useIntradayContext()

  return (
    <>
      <CurrentDaySales
        level={level}
        currentLevelName={currentLevelName}
        latestHourData={latestHourData}
        locNbr={locNbr}
      />

      <IntradayTable hourlySalesData={hourlySalesData} />

      <IntradayDepartmentViewComponent
        queryData={deptOverviewQuery}
        level={level}
        levelNumber={locNbr}
      />
    </>
  );
};
