import React from 'react';
import { Header } from 'semantic-ui-react';
import { DataTable } from '../../../../../../components/Common/DataTable';

export const LeadsBySummaryProgram = ({ leadsBySumProgram }) => {
  return (
    <div>
      {/* Leads detail - by individual summary program */}
      <Header textAlign='center'>Leads by Summary Program</Header>
      <DataTable data={leadsBySumProgram} />
    </div>
  );
};
