import React from 'react';
import { Grid, Menu } from 'semantic-ui-react';
import { UnitMenu } from '../../../../../../components/Common/UnitMenu';

export const SumProNMView = ({ unit, setUnit, allWebStore, setAllWebStore }) => {
  return (
    <>
      <Grid>
        <Grid.Column width={8}>
          <Grid.Row>Units:</Grid.Row>
          <Grid.Row>
            <UnitMenu unit={unit} setUnit={setUnit} />
          </Grid.Row>
        </Grid.Column>
        <Grid.Column width={8} textAlign='right'>
          <Grid.Row>&nbsp;</Grid.Row>
          <Grid.Row>
            <Menu compact size='small'>
              <Menu.Item
                name='All'
                active={allWebStore === ''}
                content='All'
                onClick={() => {
                  setAllWebStore('');
                }}
              />
              <Menu.Item
                name='Web'
                active={allWebStore === '_WEB'}
                content='Web'
                onClick={() => {
                  setAllWebStore('_WEB');
                }}
              />
              <Menu.Item
                name='Store'
                active={allWebStore === '_STR'}
                content='Store'
                onClick={() => {
                  setAllWebStore('_STR');
                }}
              />
            </Menu>
          </Grid.Row>
        </Grid.Column>
      </Grid>
    </>
  );
};
