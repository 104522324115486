import useQueryApi from '../../../../../hooks/useQueryApi';

export const useDailySalesOverviewQueries = fpToggle => {
  const defaultParams = { fpToggle, isCore: true };
  const queryWtdSlsByCore = useQueryApi('queryWtdSlsByCore', defaultParams);
  const wtdDailySalesByDepartment = useQueryApi('queryWtdDailySalesByDepartmentByCore', defaultParams);
  const wtdSalesByDepartment = useQueryApi('queryWtdSalesByDepartmentByCore', defaultParams);
  const salesHistoryByCore = useQueryApi('querySalesHistoryByCore', defaultParams);

  const isLoading =
    queryWtdSlsByCore.isLoading ||
    wtdDailySalesByDepartment.isLoading ||
    wtdSalesByDepartment.isLoading ||
    salesHistoryByCore.isLoading;

  const isError =
    queryWtdSlsByCore.isError ||
    wtdDailySalesByDepartment.isError ||
    wtdSalesByDepartment.isError ||
    salesHistoryByCore.isError;

    return {
      isLoading,
      isError,
      queryWtdSlsByCore: queryWtdSlsByCore.data,
      wtdDailySalesByDepartment: wtdDailySalesByDepartment.data,
      wtdSalesByDepartment: wtdSalesByDepartment.data,
      salesHistoryByCore: salesHistoryByCore.data,
    }
};
