import React from 'react'
import {
    Grid,
    Header,
    Loader,
  } from 'semantic-ui-react'
import { withRouter, Link } from "react-router-dom/cjs/react-router-dom.min";
import { useQuery } from 'react-query'
import { TimeframeDropdown } from '../Common/TimeframeDropdown'
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon'
import { DataTable } from '../Common/DataTable'

import {
    getData
  } from '../../service/DataService'

export const IMAGING_GOAL = 0.85

export const OSAImaging = withRouter(({ timeframe, settimeframe, level, fpToggle, locNbr, ...props }) => {

  const osaImagingQuery = useQuery([`query/osaImageCapture/${level}`, { locNbr, fpToggle }], getData)
  if (osaImagingQuery.isLoading) {
    return (<Loader active>Loading</Loader>)
  }
  if (!osaImagingQuery.data || osaImagingQuery.data.length === 0) {
    return (<Header textAlign='center'>Invalid Store Number</Header>)
  }

  const lastTimeframeMap = {
    'WTD': 'LW',
    'LW': null, // We don't have comparisons for last week or month, so they'll be null
    'LM': null,
    'MTD': 'LM',
    'QTD': 'LQ',
    'HTD': 'LH',
    'YTD': 'YTD_LY',
    'L12': 'L12_LY',
  }

  const ltf = lastTimeframeMap[timeframe]
  const summary = osaImagingQuery.data.reduce((acc, row) => {
    acc.actual += row[`${timeframe}_IMAGES_TAKEN`]
    acc.goal += row[`${timeframe}_IMAGES_EXPECTED`]
    if (ltf) { 
      acc.lastActual += row[`${ltf}_IMAGES_TAKEN`]
      acc.lastGoal += row[`${ltf}_IMAGES_EXPECTED`]
    }
    return acc
  }, {actual: 0, lastActual: 0, goal: 0, lastGoal: 0})

  const summaryData = {
    headers: [{name: 'Actual'},{name: 'Goal'},{name: ltf ? `v${ltf} %` : '-'},],
    data: [[
      {pctValue: summary.actual / summary.goal, decimals: 0, compareValue: IMAGING_GOAL},
      {pctValue: IMAGING_GOAL, decimals: 0},
      {
        pctValue: summary.lastActual ? summary.actual / summary.goal - summary.lastActual / summary.lastGoal : null, 
        stringValue: summary.lastActual ? null : '-', 
        compareStyle: true, 
      },
    ]]
  }

  const baySummaryData = {
    headers: [{name: 'Bay Imaged'},{name: 'Exp Bays'},{name: ltf ? `v${ltf} Bays` : '-'},],
    data: [[
      {numValue: summary.actual}, 
      {numValue: summary.goal}, 
      ltf ? {numValue: summary.actual - summary.lastActual, compareStyle: true} : {stringValue: '-'},
    ]]
  }

  const deptsummary = osaImagingQuery.data.reduce((acc, row) => {
    const dept = row.MER_DEPT_NBR
    if (!acc[dept]) {
      acc[dept] = {actual: 0, goal: 0, lastActual: 0, lastGoal: 0, name: `${row.MER_DEPT_NBR} ${row.DEPT_NM}`}
    }
    acc[dept].actual += row[`${timeframe}_IMAGES_TAKEN`]
    acc[dept].goal += row[`${timeframe}_IMAGES_EXPECTED`]
    if (ltf) { 
      acc[dept].lastActual += row[`${ltf}_IMAGES_TAKEN`]
      acc[dept].lastGoal += row[`${ltf}_IMAGES_EXPECTED`]
    }
    return acc
  }, {})

  const departmentData = {
    headers: [{name: 'Dept'},{name: 'Imaging %'},{name: ltf ? `v${ltf} bps` : '-'},],
    data: 
      Object.entries(deptsummary).map(([dept, dat]) => {
        return [
          {
            stringValue: dat.name,
            link: {
              to: '/osa/imaging/dept',
              params: {
                strNbr: locNbr,
                deptNbr: dept,
              }
            }
          },
          {pctValue: dat.actual / dat.goal, decimals: 1, compareValue: IMAGING_GOAL},
          ltf ? {numValue: Math.round(((dat.actual/dat.goal) - (dat.lastActual/dat.lastGoal)) *10000), compareStyle: true, postfix: ' bps'} : {stringValue: '-'},
        ]
      })
  }

  return (
    <>
    <Grid>
      <Grid.Column width={8}>
        <Grid.Row>
          <Link style={{ color: '#EE7125' }} to={`/${level}/overview?strNbr=${locNbr}`} data-test='b2o-link'>Overview</Link>
          {' / '}
          <Link style={{ color: '#EE7125' }} to={`/osa?strNbr=${locNbr}`} data-test='b2osa-link'>OSA</Link>
          {' / Imaging%'}
        </Grid.Row>
        <Grid.Row>&nbsp;</Grid.Row>
        </Grid.Column>
        <Grid.Column width={8}>
        Timeframe:
        {new Date().getDay() === 1 ?
          <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} /> :
          <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />}
      </Grid.Column>
    </Grid>
    <Header textAlign='center' >Overhead Imaging %</Header>
    <DataTable data={summaryData} />
    <DataTable data={baySummaryData} />
    <Header textAlign='center' >Overhead Imaging Departments Overview</Header>
    <DataTable data={departmentData} />
    </>
)
})