import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { UnitMenu } from '../Common/UnitMenu';
import { DataTable } from '../Common/DataTable';
import { LEVEL_KEYS } from '../../constants/deptDrilldown';
import { getCalloutStyle } from '../Common/TableCellUtils';
import { Tab, Grid, Dropdown, Header } from 'semantic-ui-react';

export const AllClassesTab = withRouter(({ allClasses, level, orgNbr, dataAsOf, name }) => {
  const [metric, setMetric] = useState('SLS');
  const [unit, setUnit] = useState('NOM');


  const dataObj = {
    data: allClasses.map(data =>
      [
        {
          link: {
            to: `/${level}/sales/class`,
            params: {
              [LEVEL_KEYS[level]]: orgNbr,
              classNbr: data['EXT_CLASS_NBR']
            }
          },
          stringValue: data['MER_CLASS_NM']?.replaceAll('/', '/\u200b')  // NAME VALUE COULD BE EMPTY, ADDED ? TO PREVENT CRASH
        },
        metric === 'SLS' && {
          customStyle: getCalloutStyle(data['TY_SLS_AMT']),
          orderBy: data['TY_SLS_AMT'],
          dollarValue: data['TY_SLS_AMT'],
          decimals: 2
        },
        metric === 'SLS' &&
        unit === 'NOM' && {
          compareStyle: true,
          orderBy: data['SLS_VP_NOM'],
          dollarValue: data['SLS_VP_NOM'],
          decimals: 2
        },
        metric === 'SLS' &&
        unit === 'PCT' && {
          compareStyle: true,
          orderBy: data['SLS_VP_PCT'],
          pctValue: data['SLS_VP_PCT'],
          decimals: 2
        },
        metric === 'SLS' &&
        unit === 'NOM' && {
          compareStyle: true,
          orderBy: data['SLS_COMP_NOM'],
          dollarValue: data['SLS_COMP_NOM'],
          decimals: 2
        },
        metric === 'SLS' &&
        unit === 'PCT' && {
          compareStyle: true,
          orderBy: data['SLS_COMP_PCT'],
          pctValue: data['SLS_COMP_PCT'],
          decimals: 2
        },
        metric === 'UNT' && {
          compareStyle: true,
          orderBy: data['TY_UNT_SLS'],
          stringValue: data['TY_UNT_SLS'].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
        },
        metric === 'UNT' &&
        unit === 'NOM' && {
          compareStyle: true,
          orderBy: data['UNT_COMP_NOM'],
          stringValue: data['UNT_COMP_NOM'].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }),
        },
        metric === 'UNT' &&
        unit === 'PCT' && {
          compareStyle: true,
          orderBy: data['UNT_COMP_PCT'],
          pctValue: data['UNT_COMP_PCT'],
          decimals: 2
        },
        metric === 'TXN' && {
          compareStyle: true,
          orderBy: data['TY_TXN_CNT'],
          stringValue: data['TY_TXN_CNT'].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
        },
        metric === 'TXN' &&
        unit === 'NOM' && {
          compareStyle: true,
          orderBy: data['TXN_COMP_NOM'],
          stringValue: data['TXN_COMP_NOM'].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }),
        },
        metric === 'TXN' &&
        unit === 'PCT' && {
          compareStyle: true,
          orderBy: data['TXN_COMP_PCT'],
          pctValue: data['TXN_COMP_PCT'],
          decimals: 2
        },
        metric === 'AVG_TCKT' && {
          compareStyle: true,
          orderBy: data['TY_AVG_TCKT'],
          dollarValue: data['TY_AVG_TCKT'],
          decimals: 2
        },
        metric === 'AVG_TCKT' &&
        unit === 'NOM' && {
          compareStyle: true,
          orderBy: data['AVG_TCKT_COMP_NOM'],
          dollarValue: data['AVG_TCKT_COMP_NOM'],
          decimals: 2
        },
        metric === 'AVG_TCKT' &&
        unit === 'PCT' && {
          compareStyle: true,
          orderBy: data['AVG_TCKT_COMP_PCT'],
          pctValue: data['AVG_TCKT_COMP_PCT'],
          decimals: 2
        }
      ].filter(Boolean)
    ), // Filter out falsy values
    headers: [
      { name: 'Class ' },
      metric === 'SLS' && { name: 'Sales Amt ($) ' },
      metric === 'SLS' && unit === 'NOM' && { name: 'Sales vP ($) ' },
      metric === 'SLS' && unit === 'PCT' && { name: 'Sales vP (%) ' },
      metric === 'SLS' && unit === 'NOM' && { name: 'Sales vLY ($) ' },
      metric === 'SLS' && unit === 'PCT' && { name: 'Sales vLY (%) ' },
      metric === 'UNT' && { name: 'Unit Sales (#) ' },
      metric === 'UNT' && unit === 'NOM' && { name: 'Units vLY (#) ' },
      metric === 'UNT' && unit === 'PCT' && { name: 'Units vLY (%) ' },
      metric === 'TXN' && { name: 'Trans Cnt (#) ' },
      metric === 'TXN' && unit === 'NOM' && { name: 'Trans vLY (#) ' },
      metric === 'TXN' && unit === 'PCT' && { name: 'Trans vLY (%) ' },
      metric === 'AVG_TCKT' && { name: 'Avg Ticket ($) ' },
      metric === 'AVG_TCKT' && unit === 'NOM' && { name: 'Avg Ticket vLY ($) ' },
      metric === 'AVG_TCKT' && unit === 'PCT' && { name: 'Avg Ticket vLY (%) ' }
    ].filter(Boolean), // Filter out falsy values
    sortable: true,
    sortableDefault: 0
  };

  return (
    <Tab.Pane attached={false}>
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign='justified' width={8}>
            <Grid.Row>Metric:</Grid.Row>
            <Grid.Row>
              <Dropdown
                compact
                defaultValue={metric}
                selection
                scrolling
                size='tiny'
                options={[
                  { key: 'SLS', text: 'Sales', value: 'SLS' },
                  { key: 'UNT', text: 'Units', value: 'UNT' },
                  { key: 'TXN', text: 'Trans', value: 'TXN' },
                  { key: 'AVG_TCKT', text: 'Avg Ticket', value: 'AVG_TCKT' }
                ]}
                onChange={(_, { value }) => {
                  setMetric(value);
                }}
              />
            </Grid.Row>
          </Grid.Column>
          <Grid.Column textAlign='justified' width={8}>
            <Grid.Row>&nbsp;</Grid.Row>
            <UnitMenu unit={unit} setUnit={setUnit} />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Header textAlign='center' style={{ textTransform: 'capitalize' }}>
        All Classes, {level} - {orgNbr}, {name}
        <Header.Subheader>
          Data as of {dataAsOf}
        </Header.Subheader>
      </Header>
      <DataTable data={dataObj} />
    </Tab.Pane>
  );
});
