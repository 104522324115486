import { useState } from 'react'
import { Header, Grid } from 'semantic-ui-react'
import { UnitMenu } from '../../../../../../components/Common/UnitMenu'
import { AvSAvFMenu } from '../../../../../../components/Details/AvSAvFMenu'
import { getRoundToDecimalString } from '../../../../../../components/Common/TableCellUtils'
import { useLaborContext } from '../../hooks/useLaborContext'
import { useLaborQueries } from '../../hooks/useLaborQueries'
import { DataTable } from '../../../../../../components/Common/DataTable'
import { Loader } from 'semantic-ui-react'

export const LaborDetails = ({ days, dataLaborOverview }) => {
  const { isHourlyEmployee, timeframe, level, fpToggle } = useLaborContext();
  const [unitLaborDetails, setUnitLaborDetails] = useState('NOM')
  const [avsOrAvfLaborDeets, setAvsOrAvfLaborDeets ] = useState('AVF')
  const [clf, setClf] = useState("All")
  const isMonday = new Date().getDay() === 1;
  const isDaysTotal = days === 'Total';
  const isDaysWeekend = days === 'Wkdy/Wknd';
  const isPCT = unitLaborDetails === 'PCT';
  const isNOM = unitLaborDetails === 'NOM';
  const isWTD = timeframe === 'WTD';
  const isAVS = avsOrAvfLaborDeets === 'AVS';
  const { laborDetailQuery =[], isError, isLoading } = useLaborQueries({ fpToggle, timeframe });

  if (isError) return <Header textAlign='center'>Something went wrong</Header>;
  if (isLoading) return <Loader active>Loading</Loader>;

  const classificationarr = []
  dataLaborOverview.forEach(element => {
    if (element['CLASSIFICATION'] !== 'Total') {
      if (!classificationarr.includes(element['CLASSIFICATION'])) {
        classificationarr.push(element['CLASSIFICATION'])
      }
    }
  });

  //For labor Details table - the following filter method filter out the row that has all the value fields equal to zero/null and show row data based on classification
  let laborDetailsFilter = laborDetailQuery.filter(row => {
    return clf === 'All' || row['CLASSIFICATION'] === clf;
  });

  const getOtHours = data =>{
    if(isWTD && !isMonday) return getRoundToDecimalString(data[timeframe + '_PROJ_OT_HRS'], 0)
    if((isWTD && isMonday) || !isWTD ) return getRoundToDecimalString(data[timeframe + '_OT_HRS'], 0)
  }

  const dataTotalObj = {
    data: laborDetailsFilter.map((data)=>{
      const isManager = data.CLASSIFICATION === 'Manager';
      const isHrlEmplAndManager = isHourlyEmployee && isManager;

      return [
        {
          link: isManager ? {to:`/${level}/laborDetailManager`, params: {deptNbr: data['DEPT_NBR']}} : {to:`/${level}/laborDetail`, params: {deptNbr: data['DEPT_NBR']}},
          stringValue: `${data['DEPT_NBR']}-${data['DEPT_NM'] ? data['DEPT_NM'].replaceAll('/', '/\u200b') : 'Other'}`
        },
        {
          stringValue: isHrlEmplAndManager ? '-' : getRoundToDecimalString(data[timeframe + '_ACT_HRS'], 0),
        },
        {
          stringValue: isHrlEmplAndManager ? '-' : getOtHours(data)
        },
        {
          stringValue: isNOM ? isManager ? '-' : getRoundToDecimalString(data[timeframe+'_ACT_HRS']-data[timeframe+'_FCST_HRS'], 0) :undefined,
          customStyle: isPCT ? { color: (data[timeframe+'_ACT_HRS']-data[timeframe+'_FCST_HRS'])/data[timeframe+'_FCST_HRS'] < -0.001 ? 'red' : 'green' } : {},
          pctValue: isPCT ? isManager ? '-' : getRoundToDecimalString((data[timeframe+'_ACT_HRS']-data[timeframe+'_FCST_HRS'])/data[timeframe+'_FCST_HRS'], 2) : undefined,
        },
        {
          stringValue: isNOM ? isHrlEmplAndManager ? '-' : getRoundToDecimalString(data[timeframe+'_ACT_HRS']-data[timeframe+'_SCH_WRK_HRS'], 0) :undefined,
          customStyle: isPCT ? { color: (data[timeframe+'_ACT_HRS']-data[timeframe+'_SCH_WRK_HRS'])/data[timeframe+'_SCH_WRK_HRS'] < -0.001 ? 'red' : 'green' } : {},
          pctValue: isPCT ? isHrlEmplAndManager ? '-' : getRoundToDecimalString((data[timeframe+'_ACT_HRS']-data[timeframe+'_SCH_WRK_HRS'])/data[timeframe+'_SCH_WRK_HRS'], 2) : undefined,
        },

      ]

    }),

    headers: [
      { name: 'Dept' },
      { name: 'HRS' },
      { name: isWTD && !isMonday ? 'PROJ O/T' : 'O/T' },
      { name: `AvF ${isPCT ? '%' : ''}` },
      { name: `AvS ${isPCT ? '%' : ''}` }
    ],
    sortable: true,
    sortableDefault: 0
  }

  const dataWeekDayObjAvs = {
    data: laborDetailsFilter.map((data)=>{
      const isManager = data.CLASSIFICATION === 'Manager';
      const isHrlEmplAndManager = isHourlyEmployee && isManager;

      return [
        {
          stringValue: `${data['DEPT_NBR']}-${data['DEPT_NM'] ? data['DEPT_NM'].replaceAll('/', '/\u200b') : 'Other'}`
        },
        {
          stringValue: isNOM ? isHrlEmplAndManager ? '-' : getRoundToDecimalString(data[timeframe+'_ACT_HRS_WEEKDAY']-data[timeframe+'_SCH_HRS_WEEKDAY'], 0) : undefined,
          customStyle: isPCT ? { color: (data[timeframe+'_ACT_HRS_WEEKDAY']-data[timeframe+'_SCH_HRS_WEEKDAY']) < 0 ? 'red' : 'green' } : {},
          pctValue: isPCT ? isHrlEmplAndManager ? '-' : (data[timeframe+'_ACT_HRS_WEEKDAY']-data[timeframe+'_SCH_HRS_WEEKDAY'])/data[timeframe+'_SCH_HRS_WEEKDAY'] : undefined,
          decimals: 2
        },
        {
          stringValue: isNOM ? isHrlEmplAndManager ? '-' : getRoundToDecimalString(data[timeframe+'_ACT_HRS_WEEKEND']-data[timeframe+'_SCH_HRS_WEEKEND'], 0) : undefined,
          customStyle: isPCT ? { color: (data[timeframe+'_ACT_HRS_WEEKEND']-data[timeframe+'_SCH_HRS_WEEKEND']) < 0 ? 'red' : 'green' } : {},
          pctValue: isPCT ? isHrlEmplAndManager ? '-' : (data[timeframe+'_ACT_HRS_WEEKEND']-data[timeframe+'_SCH_HRS_WEEKEND'])/data[timeframe+'_SCH_HRS_WEEKEND'] : undefined,
          decimals: 2
        },
        {
          stringValue: isNOM ? isHrlEmplAndManager ? '-' : getRoundToDecimalString(data[timeframe+'_ACT_HRS']-data[timeframe+'_SCH_WRK_HRS'], 0) : undefined,
          customStyle: isPCT ? { color: (data[timeframe+'_ACT_HRS']-data[timeframe+'_SCH_WRK_HRS']) < 0 ? 'red' : 'green' } : {},
          pctValue: isPCT ? isHrlEmplAndManager ? '-' : (data[timeframe+'_ACT_HRS']-data[timeframe+'_SCH_WRK_HRS'])/data[timeframe+'_SCH_WRK_HRS'] : undefined,
          decimals: 2
        },
      ]
    }),
    headers: [
      { name: 'Dept' },
      { name: `Wkdy Av${isAVS ? 'S' : 'F'} ${isPCT ? '%' :''}` },
      { name: `Wknd Av${isAVS ? 'S' : 'F'} ${isPCT ? '%' :''}` },
      { name: `Total Av${isAVS ? 'S' : 'F'} ${isPCT ? '%' :''}` },
    ],
    sortable: true,
    sortableDefault: 0
  }

  const dataWeekDayObjAvF = {
    data: laborDetailsFilter.map((data)=>{
      const isManager = data.CLASSIFICATION === 'Manager';

      return [
        {
          stringValue: `${data['DEPT_NBR']}-${data['DEPT_NM'] ? data['DEPT_NM'].replaceAll('/', '/\u200b') : 'Other'}`
        },
        {
          stringValue: isNOM ? isManager ? '-' : getRoundToDecimalString(data[timeframe+'_ACT_HRS_WEEKDAY']-data[timeframe+'_FCST_HRS_WEEKDAY'], 0) : undefined,
          customStyle: isPCT ? { color: (data[timeframe+'_ACT_HRS_WEEKDAY']-data[timeframe+'_FCST_HRS_WEEKDAY']) < 0 ? 'red' : 'green' } : {},
          pctValue: isPCT ? isManager ? '-' : (data[timeframe+'_ACT_HRS_WEEKDAY']-data[timeframe+'_FCST_HRS_WEEKDAY'])/data[timeframe+'_FCST_HRS_WEEKDAY'] : undefined,
          decimals: 2
        },
        {
          stringValue: isNOM ? isManager ? '-' : getRoundToDecimalString(data[timeframe+'_ACT_HRS_WEEKEND']-data[timeframe+'_FCST_HRS_WEEKEND'], 0) : undefined,
          customStyle: isPCT ? { color: (data[timeframe+'_ACT_HRS_WEEKEND']-data[timeframe+'_FCST_HRS_WEEKEND']) < 0 ? 'red' : 'green' } : {},
          pctValue: isPCT ? isManager ? '-' : (data[timeframe+'_ACT_HRS_WEEKEND']-data[timeframe+'_FCST_HRS_WEEKEND'])/data[timeframe+'_FCST_HRS_WEEKEND'] : undefined,
          decimals: 2
        },
        {
          stringValue: isNOM ? isManager ? '-' : getRoundToDecimalString(data[timeframe+'_ACT_HRS']-data[timeframe+'_FCST_HRS'], 0) : undefined,
          customStyle: isPCT ? { color: (data[timeframe+'_ACT_HRS']-data[timeframe+'_FCST_HRS']) < -0.01 ? 'red' : 'green' } : {},
          pctValue: isPCT ? isManager ? '-' : (data[timeframe+'_ACT_HRS']-data[timeframe+'_FCST_HRS'])/data[timeframe+'_FCST_HRS'] : undefined,
          decimals: 2
        },
      ]
    }),
    headers: [
      { name: 'Dept' },
      { name: `Wkdy Av${isAVS ? 'S' : 'F'} ${isPCT ? '%' :''}` },
      { name: `Wknd Av${isAVS ? 'S' : 'F'} ${isPCT ? '%' :''}` },
      { name: `Total Av${isAVS ? 'S' : 'F'} ${isPCT ? '%' :''}` },
    ],
    sortable: true,
    sortableDefault: 0
  }
  return (
      <>
        <Header textAlign='center'>Labor Details</Header>
        <Grid>
          <Grid.Column width={7} textAlign='left'>
            <Grid.Row>Units:</Grid.Row>
            <Grid.Row>
            <UnitMenu unit={unitLaborDetails} setUnit={setUnitLaborDetails} />
            </Grid.Row>
          </Grid.Column>
        <Grid.Column textAlign='right' width={9}>
          {isDaysWeekend && <Grid.Row>
            <AvSAvFMenu avsOrAvf={avsOrAvfLaborDeets} setAvsOrAvf={setAvsOrAvfLaborDeets} />
            </Grid.Row>}
          <Grid.Row>
            Labor Categories
            <div>
              <select
                name="classificationarr"
                onChange={e => {
                  setClf(e.target.value)
                }}
                value={clf}
                style={{ padding: 10 }}
              >
                <option value="All">All</option>
                {classificationarr.map((row, index) => {
                  return <option value={row} key={index}>{row}</option>
                })}
              </select>
            </div>
            </Grid.Row>
         </Grid.Column>
         <Grid.Row>&nbsp;</Grid.Row>
        </Grid>

        {isDaysTotal && <DataTable data={dataTotalObj} />}
        {isDaysWeekend && (isAVS ? <DataTable data={dataWeekDayObjAvs}/> : <DataTable data={dataWeekDayObjAvF}/>)}
      </>
  )
}