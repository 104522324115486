import React, { useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import {
  Table,
  Header,
  Grid,
  Loader,
} from 'semantic-ui-react'
import { UnitMenu } from '../Common/UnitMenu'
import { TimeframeDropdown } from '../Common/TimeframeDropdown'
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon'

import { useQuery } from 'react-query'
import {
  getData
} from '../../service/DataService'
// import { Quotes } from './Quotes'

import { getDollarString, getThousandDollarString, getPercentString, getCalloutStyle, getCompareStyle } from '../Common/TableCellUtils'

export const SpecialtyProRental = withRouter(({ timeframe, settimeframe, fpToggle, location }) => {

  const search = new URLSearchParams(location.search)
  const strNbr = search.has('strNbr') ? search.get('strNbr') : 0

  const [sortColumnSls] = useState('DEPT_NBR')
  const [sortDirectionSls] = useState('ascending')

  const [sortColumnLbr] = useState('DEPT_NM')
  const [sortDirectionLbr] = useState('ascending')

  const [unit, setUnit] = useState('NOM')

  const proSpecTrSlsByStrQuery = useQuery(['queryProSpecTrSlsByStr', { strNbr, fpToggle }], getData)
  const proSpecTrLbrByStrQuery = useQuery(['queryProSpecTrLbrByStr', { strNbr, fpToggle }], getData)
  const proOpsByStrQuery = useQuery(['queryProOpsByStr', { strNbr, fpToggle }], getData)
  const specOpsByStrQuery = useQuery(['querySpecOpsByStr', { strNbr, fpToggle }], getData)
  const trOpsByStrQuery = useQuery(['queryTrOpsByStr', { strNbr, fpToggle }], getData)
  const quotesAggByStrQuery = useQuery(['queryQuotesAggByStr', { strNbr, fpToggle }], getData)
  // const quotesDtlByStrQuery = useQuery(['queryQuotesDtlByStr', { strNbr, fpToggle }], getData)
  // const quotesAssocDeptNbrByStrQuery = useQuery(['queryQuotesAssocDeptNbrByStr', { strNbr, fpToggle }], getData)
  // const quotesMerDeptNbrByStrQuery = useQuery(['queryQuotesMerDeptNbrByStr', { strNbr, fpToggle }], getData)


  if (proSpecTrSlsByStrQuery.isLoading || proSpecTrLbrByStrQuery.isLoading ||
    proOpsByStrQuery.isLoading || specOpsByStrQuery.isLoading || trOpsByStrQuery.isLoading ||
    quotesAggByStrQuery.isLoading //|| 
    // quotesDtlByStrQuery.isLoading ||
    // quotesAssocDeptNbrByStrQuery.isLoading || 
    // quotesMerDeptNbrByStrQuery.isLoading
    ) {
    return <Loader active>Loading...</Loader>
  }
  if (!proSpecTrSlsByStrQuery.data[0]) {
    return (<Header textAlign='center'>Invalid Store Number</Header>)
  }

  // Processing quotes data
  let quotesOverviewData = quotesAggByStrQuery.data[0]
  // let quotesDtlData = quotesDtlByStrQuery.data
  // let assocDeptNbrData = quotesAssocDeptNbrByStrQuery.data
  // let merDeptNbrData = quotesMerDeptNbrByStrQuery.data

  // Processing sales data
  let proSpecTrSlsBodyFilter = proSpecTrSlsByStrQuery.data.filter((row) => {
    return row && row['DEPT_NBR'] !== 42 && row['DEPT_NBR'] !== 9999
  })

  let proSpecTrSls42Filter = proSpecTrSlsByStrQuery.data.filter((row) => {
    return row && row['DEPT_NBR'] === 42
  })
  let proSpecTrSls42Row = proSpecTrSls42Filter[0]

  let proSpecTrSlsTtlFilter = proSpecTrSlsByStrQuery.data.filter((row) => {
    return row && row['DEPT_NBR'] === 9999
  })
  let proSpecTrSlsTtlRow = proSpecTrSlsTtlFilter[0]

  let proSpecTrSlsBodySorted = proSpecTrSlsBodyFilter.sort((left, right) => {
    let result = left[sortColumnSls] - right[sortColumnSls]
    if (sortColumnSls === 'MER_DEPT_NM') {
      result = left.MER_DEPT_NM > right.MER_DEPT_NM ? 1 : -1
    }
    if (sortDirectionSls === 'descending') {
      result *= -1
    }
    return result
  })

  // Processing labor data
  let proSpecTrLbrBodyFilter = proSpecTrLbrByStrQuery.data.filter((row) => {
    return row && row['DEPT_NBR'] !== 9999
  })

  let proSpecTrLbrTtlFilter = proSpecTrLbrByStrQuery.data.filter((row) => {
    return row && row['DEPT_NBR'] === 9999
  })
  let proSpecTrLbrTtlRow = proSpecTrLbrTtlFilter[0]

  let proSpecTrLbrSorted = proSpecTrLbrBodyFilter.sort((left, right) => {
    let result = left[sortColumnLbr] - right[sortColumnLbr]
    if (sortColumnLbr === 'DEPT_NM') {
      result = left.DEPT_NM > right.DEPT_NM ? 1 : -1
    }
    if (sortDirectionLbr === 'descending') {
      result *= -1
    }
    return result
  })

  // Processing Pro Ops data
  let proOps = proOpsByStrQuery.data[0]

  // Processing Specialty Ops data
  let specOps = specOpsByStrQuery.data[0]

  // Processing TR Ops data
  let trOps = trOpsByStrQuery.data[0]


  return (
    <>
      <Header textAlign='center'>Specialty, Pro, Rental - Store #{strNbr}</Header>
      <Grid>
        <Grid.Column width={8} textAlign='left'>
          <Grid.Row>
            Units:
          </Grid.Row>
          <Grid.Row>
            <UnitMenu unit={unit} setUnit={setUnit} />
          </Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
        </Grid.Column>
        <Grid.Column width={8} textAlign='right'>
          Timeframe:
          {new Date().getDay() === 1 ?
            <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} /> :
            <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />}
        </Grid.Column>
      </Grid>

      {/* <Quotes
        quotesOverviewData={quotesOverviewData}
        quotesDtlData={quotesDtlData}
        assocDeptNbrData={assocDeptNbrData}
        merDeptNbrData={merDeptNbrData}
        timeframe={timeframe} /> */}
      <Header textAlign='center'>Quotes</Header>
      <Table textAlign='center' celled unstackable size='small' compact style={{ fontSize: 11 }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Open Opportunity</Table.HeaderCell>
              <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Open Count</Table.HeaderCell>
              <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Sold $</Table.HeaderCell>
              <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Sold Count</Table.HeaderCell>
              <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Sold Quote % *</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>{getThousandDollarString(quotesOverviewData[timeframe + '_OPEN_QUOTE_AMT'], 1)}</Table.Cell>
              <Table.Cell>{quotesOverviewData[timeframe + '_OPEN_QUOTE_CNT'].toLocaleString(undefined, { maximumFractionDigits: 1 })}</Table.Cell>
              <Table.Cell>{getThousandDollarString(quotesOverviewData[timeframe + '_QUOTE_SOLD_AMT'], 1)}</Table.Cell>
              <Table.Cell>{quotesOverviewData[timeframe + '_QUOTE_SOLD_CNT'].toLocaleString(undefined, { maximumFractionDigits: 1 })}</Table.Cell>
              <Table.Cell>{getPercentString(quotesOverviewData[timeframe + '_QUOTE_SOLD_CNT'] / quotesOverviewData[timeframe + '_TOT_QUOTE_CNT'])}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
       <p>
        * Sold % is calculated as the total number of quotes sold divided by all quotes in all statuses (Open. Sold, Cancelled).
        </p>

      <p>&nbsp;</p>
      <Header textAlign='center'>Sales</Header>
      <Table textAlign='center' celled unstackable size='small' compact style={{ fontSize: 11 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>Act</Table.HeaderCell>
            {unit === 'NOM' && <Table.HeaderCell>vP</Table.HeaderCell>}
            {unit === 'PCT' && <Table.HeaderCell>vP %</Table.HeaderCell>}
            {unit === 'NOM' && <Table.HeaderCell>vLY</Table.HeaderCell>}
            {unit === 'PCT' && <Table.HeaderCell>vLY %</Table.HeaderCell>}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>PRO XTRA REG TNDR</Table.Cell>
            <Table.Cell style={getCalloutStyle(proSpecTrSls42Row[timeframe + '_TY_SLS_AMT'])}>
              {getThousandDollarString(proSpecTrSls42Row[timeframe + '_TY_SLS_AMT'], 1)}</Table.Cell>
            {unit === 'NOM' && <Table.Cell>--</Table.Cell>}
            {unit === 'PCT' && <Table.Cell>--</Table.Cell>}
            {unit === 'NOM' && <Table.Cell style={getCompareStyle(proSpecTrSls42Row[timeframe + '_SLS_VLY_PCT'] - 0.02)}>
              {getThousandDollarString(proSpecTrSls42Row[timeframe + '_SLS_VLY'], 1)}</Table.Cell>}
            {unit === 'PCT' && <Table.Cell style={getCompareStyle(proSpecTrSls42Row[timeframe + '_SLS_VLY_PCT'] - 0.02)}>
              {getPercentString(proSpecTrSls42Row[timeframe + '_SLS_VLY_PCT'])}</Table.Cell>}
          </Table.Row>
          {proSpecTrSlsBodySorted.map((data) => {
            return (
              <Table.Row>
                <Table.Cell>
                  {data['MER_DEPT_NM'] === '1-SERVICES' ? 
                    <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/services?strNbr=${strNbr}`} >
                    SERVICES
                    </Link> :
                    <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/sales/dept?strNbr=${strNbr}&deptNbr=${data['DEPT_NBR']}`} >
                      {data['MER_DEPT_NM']}
                    </Link>}
                </Table.Cell>
                <Table.Cell style={getCalloutStyle(data[timeframe + '_TY_SLS_AMT'])}>
                  {getThousandDollarString(data[timeframe + '_TY_SLS_AMT'], 1)}</Table.Cell>
                {unit === 'NOM' && <Table.Cell style={getCompareStyle(data[timeframe + '_SLS_VP'])}>
                  {getThousandDollarString(data[timeframe + '_SLS_VP'], 1)}</Table.Cell>}
                {unit === 'PCT' && <Table.Cell style={getCompareStyle(data[timeframe + '_SLS_VP_PCT'])}>
                  {getPercentString(data[timeframe + '_SLS_VP_PCT'])}</Table.Cell>}
                {unit === 'NOM' && <Table.Cell style={getCompareStyle(data[timeframe + '_SLS_VLY'])}>
                  {getThousandDollarString(data[timeframe + '_SLS_VLY'], 1)}</Table.Cell>}
                {unit === 'PCT' && <Table.Cell style={getCompareStyle(data[timeframe + '_SLS_VLY_PCT'])}>
                  {getPercentString(data[timeframe + '_SLS_VLY_PCT'])}</Table.Cell>}
              </Table.Row>
            )
          })}
          <Table.Row style={{ fontWeight: "bold" }}>
            <Table.Cell>TOTAL **</Table.Cell>
            <Table.Cell style={getCalloutStyle(proSpecTrSlsTtlRow[timeframe + '_TY_SLS_AMT'])}>
              {getThousandDollarString(proSpecTrSlsTtlRow[timeframe + '_TY_SLS_AMT'], 1)}</Table.Cell>
            {unit === 'NOM' && <Table.Cell style={getCompareStyle(proSpecTrSlsTtlRow[timeframe + '_SLS_VP'])}>
              {getThousandDollarString(proSpecTrSlsTtlRow[timeframe + '_SLS_VP'], 1)}</Table.Cell>}
            {unit === 'PCT' && <Table.Cell style={getCompareStyle(proSpecTrSlsTtlRow[timeframe + '_SLS_VP_PCT'])}>
              {getPercentString(proSpecTrSlsTtlRow[timeframe + '_SLS_VP_PCT'])}</Table.Cell>}
            {unit === 'NOM' && <Table.Cell style={getCompareStyle(proSpecTrSlsTtlRow[timeframe + '_SLS_VLY'])}>
              {getThousandDollarString(proSpecTrSlsTtlRow[timeframe + '_SLS_VLY'], 1)}</Table.Cell>}
            {unit === 'PCT' && <Table.Cell style={getCompareStyle(proSpecTrSlsTtlRow[timeframe + '_SLS_VLY_PCT'])}>
              {getPercentString(proSpecTrSlsTtlRow[timeframe + '_SLS_VLY_PCT'])}</Table.Cell>}
          </Table.Row>
        </Table.Body>
      </Table>
      <p>** Pro Xtra Registered Tender & Services are not included in the total due to overlapping sales in the department totals.</p>
      <Grid><Grid.Row>&nbsp;</Grid.Row></Grid>
      <Header textAlign='center'>Labor</Header>
      <Table textAlign='center' celled unstackable size='small' compact style={{ fontSize: 11 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>Act</Table.HeaderCell>
            {unit === 'NOM' && <Table.HeaderCell>AvF</Table.HeaderCell>}
            {unit === 'PCT' && <Table.HeaderCell>AvF %</Table.HeaderCell>}
            {unit === 'NOM' && <Table.HeaderCell>AvS</Table.HeaderCell>}
            {unit === 'PCT' && <Table.HeaderCell>AvS %</Table.HeaderCell>}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {proSpecTrLbrSorted.map((data) => {
            return (
              <Table.Row>
                <Table.Cell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>
                  {data['DEPT_NM']}</Table.Cell>
                <Table.Cell>{data[timeframe + '_ACT_HRS'].toLocaleString(undefined, { maximumFractionDigits: 0 })}</Table.Cell>
                {unit === 'NOM' && <Table.Cell>
                  {data[timeframe + '_HRS_VF'].toLocaleString(undefined, { maximumFractionDigits: 0 })}</Table.Cell>}
                {unit === 'PCT' && <Table.Cell style={{ color: data[timeframe + '_HRS_VF_PCT'] > 0.01 || data[timeframe + '_HRS_VF_PCT'] < -0.01 ? 'red' : 'green' }}>
                  {getPercentString(data[timeframe + '_HRS_VF_PCT'])}</Table.Cell>}
                {unit === 'NOM' && <Table.Cell>
                  {data[timeframe + '_HRS_VS'].toLocaleString(undefined, { maximumFractionDigits: 0 })}</Table.Cell>}
                {unit === 'PCT' && <Table.Cell style={{ color: data[timeframe + '_HRS_VS_PCT'] > 0.01 || data[timeframe + '_HRS_VS_PCT'] < -0.01 ? 'red' : 'green' }}>
                  {getPercentString(data[timeframe + '_HRS_VS_PCT'])}</Table.Cell>}
              </Table.Row>
            )
          })}
          <Table.Row style={{ fontWeight: "bold" }}>
            <Table.Cell>TOTAL</Table.Cell>
            <Table.Cell>
              {proSpecTrLbrTtlRow[timeframe + '_ACT_HRS'].toLocaleString(undefined, { maximumFractionDigits: 0 })}</Table.Cell>
            {unit === 'NOM' && <Table.Cell>
              {proSpecTrLbrTtlRow[timeframe + '_HRS_VF'].toLocaleString(undefined, { maximumFractionDigits: 0 })}</Table.Cell>}
            {unit === 'PCT' && <Table.Cell style={{ color: proSpecTrLbrTtlRow[timeframe + '_HRS_VF_PCT'] > 0.01 || proSpecTrLbrTtlRow[timeframe + '_HRS_VF_PCT'] < -0.01 ? 'red' : 'green' }}>
              {getPercentString(proSpecTrLbrTtlRow[timeframe + '_HRS_VF_PCT'])}</Table.Cell>}
            {unit === 'NOM' && <Table.Cell>
              {proSpecTrLbrTtlRow[timeframe + '_HRS_VS'].toLocaleString(undefined, { maximumFractionDigits: 0 })}</Table.Cell>}
            {unit === 'PCT' && <Table.Cell style={{ color: proSpecTrLbrTtlRow[timeframe + '_HRS_VS_PCT'] > 0.01 || proSpecTrLbrTtlRow[timeframe + '_HRS_VS_PCT'] < -0.01 ? 'red' : 'green' }}>
              {getPercentString(proSpecTrLbrTtlRow[timeframe + '_HRS_VS_PCT'])}</Table.Cell>}
          </Table.Row>
        </Table.Body>
      </Table>
      <Grid><Grid.Row>&nbsp;</Grid.Row></Grid>
      <Header textAlign='center'>Specialty Key Metrics</Header>
      <Table textAlign='center' celled unstackable size='small' compact style={{ fontSize: 11 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>
              <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/leads?strNbr=${strNbr}`} >Lead Comp</Link>
            </Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>
              <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/meas?strNbr=${strNbr}`} >Meas Comp</Link>
            </Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>DOTW Standards</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>1:1 Cmplt</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell style={getCompareStyle(specOps[timeframe + '_LEADS_VLY_PCT'])}>
              {getPercentString(specOps[timeframe + '_LEADS_VLY_PCT'])}</Table.Cell>
            <Table.Cell style={getCompareStyle(specOps[timeframe + '_MEAS_VLY_PCT'])}>
              {getPercentString(specOps[timeframe + '_MEAS_VLY_PCT'])}</Table.Cell>
            <Table.Cell>{getPercentString(specOps[timeframe + '_WALK_COMPLETE_PCT'])}</Table.Cell>
            <Table.Cell>{getPercentString(specOps[timeframe + '_YES_RESP_PCT'])}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Table textAlign='center' celled unstackable size='small' compact style={{ fontSize: 11 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Spotlight Cmplt</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>CSU Cmplt</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>% of Spec. Meeting Sls Goal</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>{getPercentString(specOps[timeframe + '_SPOT_CMPLT_PCT'])}</Table.Cell>
            <Table.Cell>{getPercentString(specOps[timeframe + '_CSU_CMPLT_PCT'])}</Table.Cell>
            <Table.Cell>{getPercentString(specOps[timeframe + '_SPEC_OVR_GOAL'])}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Grid><Grid.Row>&nbsp;</Grid.Row></Grid>
      <Header textAlign='center'>Pro Key Metrics</Header>
      {proOps ? <Table textAlign='center' celled unstackable size='small' compact style={{ fontSize: 11 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Pro Xtra Signups</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Pro Xtra Signups Avg</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Pro MRO Act</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Pro MRO Avg</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Pro LTSA</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Pro GET</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell style={{ color: proOps[timeframe + '_PXTRA_SIGNUP'] >= 8 ? 'green' : 'red' }}>{proOps[timeframe + '_PXTRA_SIGNUP']}</Table.Cell>
            <Table.Cell style={{ color: proOps[timeframe + '_PXTRA_SIGNUP_AVG'] >= 8 ? 'green' : 'red' }}>
              {proOps[timeframe + '_PXTRA_SIGNUP_AVG'].toLocaleString(undefined, { maximumFractionDigits: 1 })}</Table.Cell>
            <Table.Cell style={getCalloutStyle(proOps[timeframe + '_PRO_MRO_SLS'])}>
              {getDollarString(proOps[timeframe + '_PRO_MRO_SLS'])}</Table.Cell>
            <Table.Cell style={{ color: proOps[timeframe + '_PRO_MRO_AVG'] >= 1200 ? 'green' : 'red' }}>
              {getDollarString(proOps[timeframe + '_PRO_MRO_AVG'])}</Table.Cell>
            <Table.Cell style={{ color: proOps[timeframe + '_PRO_LTSA_PCT'] >= 0.85 ? 'green' : 'red' }}>
              {getPercentString(proOps[timeframe + '_PRO_LTSA_PCT'])}</Table.Cell>
            <Table.Cell style={{ color: proOps[timeframe + '_PRO_GET_PCT'] >= 0.85 ? 'green' : 'red' }}>
              {getPercentString(proOps[timeframe + '_PRO_GET_PCT'])}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table> : <Grid><Grid.Column textAlign='center'>No Pro data for this store.</Grid.Column></Grid>}
      <Grid><Grid.Row>&nbsp;</Grid.Row></Grid>
      <Header textAlign='center'>Rental Key Metrics</Header>
      {trOps ? <Table textAlign='center' celled unstackable size='small' compact style={{ fontSize: 11 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Damage Waiver %</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Critical Down Tools</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Critical Down Tools - No Parts</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>LTRA</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Rental GET</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>{getPercentString(trOps[timeframe + '_DMG_WVR_PCT'])}</Table.Cell>
            <Table.Cell>{trOps['TTL_CAD_WO']}</Table.Cell>
            <Table.Cell>{trOps['CAD_NO_PARTS']}</Table.Cell>
            <Table.Cell style={{ color: trOps[timeframe + '_LTRA_PCT'] > 0.85 ? 'green' : 'red' }}>{getPercentString(trOps[timeframe + '_LTRA_PCT'])}</Table.Cell>
            <Table.Cell style={{ color: trOps[timeframe + '_GET_PCT'] > 0.85 ? 'green' : 'red' }}>{getPercentString(trOps[timeframe + '_GET_PCT'])}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table> : <Grid><Grid.Column textAlign='center'>No Rental data for this store.</Grid.Column></Grid>}
    </>
  )

}
)