import React from 'react';
import { Tab } from 'semantic-ui-react';
import { CoreEvents } from './CoreEvents';
import { TotalEvents } from './TotalEvents';
import { NonCoreEvents } from './NonCoreEvents';

export const DistrictListTab = ({ events, level }) => {
  const filteredEvents = events.filter(event => {
    return level === 'region' ? event && event['METRIC_LEVEL'] === 'DST' : event && event['METRIC_LEVEL'] === 'RGN';
  });

  return (
    <Tab.Pane>
      <TotalEvents events={filteredEvents} level={level} />
      <CoreEvents events={filteredEvents} level={level} />
      <NonCoreEvents events={filteredEvents} level={level} />
    </Tab.Pane>
  );
};
