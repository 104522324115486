import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import {
  Table,
  Header,
  Grid,
  Loader,
} from 'semantic-ui-react'
import { ChannelView } from './ChannelView'
import { TimeframeDropdown } from '../Common/TimeframeDropdown'
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon'

import { useQuery } from 'react-query'
import {
  getData
} from '../../service/DataService'

import { getDollarString, getPercentString } from '../Common/TableCellUtils'

export const OrderFulfillment = withRouter(({ timeframe, settimeframe, fpToggle, location }) => {

  const search = new URLSearchParams(location.search)
  const strNbr = search.has('strNbr') ? search.get('strNbr') : 0
  const dstNbr = search.has('dstNbr') ? search.get('dstNbr') : 0

  const [sortColumn, setSortColumn] = useState('CHANNEL')
  const [sortDirection, setSortDirection] = useState('ascending')

  const onlineQuery = useQuery(['queryOrdrFulDtlsByStr', { strNbr, fpToggle }], getData)
  const channelDtlQuery = useQuery(['querySwmOnlineChnlAssocDtl', { strNbr, fpToggle }], getData)
  const delvDtlQuery = useQuery(['queryDelvDtlByStr', { strNbr, fpToggle }], getData)
  const slaMissQuery = useQuery(['querySwmOnlineSlaMissDtl', { strNbr, fpToggle }], getData)
  const missedDelvDtlQuery = useQuery(['querySwmMissedDelvDtl', { strNbr, fpToggle }], getData)                                                 
  const dsNonComplianceQuery = useQuery(['querySwmDsNonCompliance', { strNbr, fpToggle }], getData)
  const dsAssocDtlQuery = useQuery(['querySwmDsAssocDtl', { strNbr, fpToggle }], getData)

  if (onlineQuery.isLoading || channelDtlQuery.isLoading ||
    slaMissQuery.isLoading || delvDtlQuery.isLoading || missedDelvDtlQuery.isLoading ||
    dsNonComplianceQuery.isLoading || dsAssocDtlQuery.isLoading) {
    return <Loader active>Loading...</Loader>
  }

  if (!onlineQuery.data[0]) {
    return (<Header textAlign='center'>Invalid or Unauthorized Store Number</Header>)
  }

  let onlineDataSort = onlineQuery.data.sort((left, right) => {
    let result = left[sortColumn] - right[sortColumn]
    if (sortColumn === 'CHANNEL') {
      result = left.CHANNEL > right.CHANNEL ? 1 : -1
    }
    if (sortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  onlineDataSort = onlineDataSort.filter((row) => {
    return row && row['CHANNEL'] !== 'BOSS+BOPIS'
  })

  let chnlDataFilter = onlineQuery.data.filter((row) => {
    return row && row['CHANNEL'] === 'BOSS+BOPIS'
  })

  return (
    <>
      <Grid>
        <Grid.Column width={8}>
        </Grid.Column>
        <Grid.Column width={8}>
          <Grid.Row>
            Timeframe:
          </Grid.Row>
          <Grid.Row>
            {new Date().getDay() === 1 ?
              <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} /> :
              <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />}
          </Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
        </Grid.Column>
      </Grid>
      <Header textAlign='center'>Order Fulfillment - Store #{strNbr}</Header>
      <Table textAlign='center' celled sortable unstackable size='small' style={{ fontSize: 10 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={sortColumn === 'CHANNEL' ? sortDirection : null}
              onClick={() => {
                if (sortColumn !== 'CHANNEL') {
                  setSortColumn('CHANNEL')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>Order Channel</Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={sortColumn === timeframe + '_NET_SLS_AMT' ? sortDirection : null}
              onClick={() => {
                if (sortColumn !== timeframe + '_NET_SLS_AMT') {
                  setSortColumn(timeframe + '_NET_SLS_AMT')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>Act Sales</Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={sortColumn === timeframe + '_LY_NET_SLS_AMT' ? sortDirection : null}
              onClick={() => {
                if (sortColumn !== timeframe + '_LY_NET_SLS_AMT') {
                  setSortColumn(timeframe + '_LY_NET_SLS_AMT')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>LY Sales</Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={sortColumn === timeframe + '_RLSD_ORDERS' ? sortDirection : null}
              onClick={() => {
                if (sortColumn !== timeframe + '_RLSD_ORDERS') {
                  setSortColumn(timeframe + '_RLSD_ORDERS')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>Orders Fulfilled</Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={sortColumn === timeframe + '_ONLINE_SAT_SCORE' ? sortDirection : null}
              onClick={() => {
                if (sortColumn !== timeframe + '_ONLINE_SAT_SCORE') {
                  setSortColumn(timeframe + '_ONLINE_SAT_SCORE')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>CSAT</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body singleLine>
          {onlineDataSort.map((data) => {
            return (
              <Table.Row>
                <Table.Cell>
                  {data['CHANNEL'] === 'BODFS' ? 'Deliveries' : data['CHANNEL']}
                </Table.Cell>
                <Table.Cell>{getDollarString(data[timeframe + '_NET_SLS_AMT'])}</Table.Cell>
                <Table.Cell>{getDollarString(data[timeframe + '_LY_NET_SLS_AMT'])}</Table.Cell>
                <Table.Cell>
                  {data[timeframe + '_RLSD_ORDERS'] !== null ? data[timeframe + '_RLSD_ORDERS'] !== 0 ?
                    data[timeframe + '_RLSD_ORDERS'].toLocaleString(undefined, { maximumFractionDigits: 2 }) :
                    '-' :
                    'Loading'}
                </Table.Cell>
                <Table.Cell>
                  {data[timeframe + '_ONLINE_SAT_SCORE'] !== null ? data[timeframe + '_ONLINE_SURVEY_COUNT'] !== 0 ?
                    (data[timeframe + '_ONLINE_SAT_SCORE'] / data[timeframe + '_ONLINE_SURVEY_COUNT']).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }) :
                    '-' :
                    'Loading'}
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
      <Table textAlign='center' celled sortable unstackable size='small' style={{ fontSize: 10 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Avg Mins to Pick</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Pick on Time %</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}># Missed SLA</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>DS Compliance %</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>OF App %</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body singleLine>
          {chnlDataFilter.map((data) => {
            return (
              <Table.Row>
                <Table.Cell>{data[timeframe + '_AVG_MINS_TO_PICK'] !== null ?
                  data[timeframe + '_AVG_MINS_TO_PICK'].toLocaleString(undefined, { maximumFractionDigits: 2 }) :
                  '-'}</Table.Cell>
                <Table.Cell>{data[timeframe + '_PCT_ON_TM'] !== null ?
                  (100 * data[timeframe + '_PCT_ON_TM']).toLocaleString(undefined, { maximumFractionDigits: 2 }) :
                  '-'}</Table.Cell>
                <Table.Cell>{data[timeframe + '_OVER_SLA_CNT']}</Table.Cell>
                <Table.Cell>{getPercentString(data[timeframe + '_DS_COMP'])}</Table.Cell>
                <Table.Cell>{getPercentString(data[timeframe + '_OF_APP'])}</Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
      <ChannelView
        chnlData={onlineQuery.data}
        chnlDtlData={channelDtlQuery.data}
        slaMissData={slaMissQuery.data}
        delvDtlData={delvDtlQuery.data}
        missedDelvDtlData={missedDelvDtlQuery.data}
        dsNonComplianceData={dsNonComplianceQuery.data}
        dsAssocDtlData={dsAssocDtlQuery.data}
        timeframe={timeframe}
        strNbr={strNbr}
        dstNbr={dstNbr}
      />
    </>
  )

})