import { DataTable } from '../Common/DataTable'

export const AvgMinPickBoss = ({ chnlDtlData, timeframe }) => {

  let chnlDtlDataFilter = chnlDtlData.filter((row) => {
    return row && row['CHANNEL'] === 'BOSS' && row[timeframe + '_FULFL_ORDERS'] > 0
  })
  
  const dataObj = {
    sortable: true,
    pagination: true,
    sortableDefault: 0,
    sortableColumns: [0, 1, 2, 3],
    headers: [
      { name: 'User ID' },
      {
        name: 'Associate Name',
        element: <label>Associate <br /> Name</label>
      },
      {
        name: 'Avg Mins to Pick',
        element: <label>Avg Mins <br />to Pick</label>
      },
      {
        name: 'Fullfilled Orders',
        element: <label>Fulfilled <br /> Orders</label>,
      }
    ],
    data: chnlDtlDataFilter.map(row => ([
      {
        stringValue: row['USER_ID'],
      },
      {
        stringValue: row['ASSOC_NM']
      },
      {
        numValue: row[timeframe + '_AVG_MINS_TO_PICK']
      },
      {
        numValue: row[timeframe + '_FULFL_ORDERS']
      }
    ]) )
  }

  return (
      <DataTable data={dataObj} />
  )
}