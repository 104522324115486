import { Menu } from 'semantic-ui-react';

export const MenuItems = ({ activeItem, className, dataTest, items, setItem, size }) => {
  return (
    <Menu className={className} compact data-testid={dataTest} size={size}>
      {items.map(item => (
        <Menu.Item
          active={activeItem === item}
          content={item}
          key={item}
          name={item}
          onClick={() => setItem(item)}
          role='option'
        />
      ))}
    </Menu>
  );
};
