import React, { useState } from 'react';
import { Grid } from 'semantic-ui-react';
import { DataView } from '../../Common/DataView';
import { Select } from '../../Common/Select';
import {
  ACT_HRS,
  AGG_TYPE,
  ALL,
  DATE_FORMAT_ITEMS,
  DAY_DESC,
  EN_US,
  FCST_HRS_ROW,
  FISCAL_DAY,
  FSCL_YR,
  LABOR,
  LY_SLS_AMT,
  MARKDOWNS,
  METRIC_ITEMS,
  PERIOD_DESC,
  PERIOD_END_DT,
  SALES_COMP,
  SALES_PLAN,
  SCORE_CARD_HEADERS,
  SLS_PLN_AMT,
  SMD_PCT_PLN,
  SMKDN_AMT,
  TIMEFRAME_ITEMS,
  TY_SLS_AMT
} from './constants';

export const SalesScorecard = ({ data }) => {
  const [metric, setMetric] = useState(SALES_PLAN);
  const [timeframe, setTimeframe] = useState(FISCAL_DAY);
  const [fiscalYear, setFiscalYear] = useState(ALL);
  const [dateFormat, setDateFormat] = useState(PERIOD_END_DT);
  const slsHistData = data || [];
  const slsHistDataFiltered = slsHistData.filter(
    row => row && row[AGG_TYPE] === timeframe && (fiscalYear === ALL || row[FSCL_YR].toString() === fiscalYear)
  );
  const fiscalYears = slsHistData.reduce((acc, curr) => (acc.includes(curr[FSCL_YR]) ? acc : [...acc, curr[FSCL_YR]]), []);
  const fiscalYearsSorted = fiscalYears.sort();
  const isDateFormatFiscalYear = dateFormat === PERIOD_DESC;

  const dataTableByRow = row => {
    switch (metric) {
      case SALES_PLAN:
        return [{ dollarValue: row[SLS_PLN_AMT] }, { pctValue: row[TY_SLS_AMT] / row[SLS_PLN_AMT] - 1 }];
      case SALES_COMP:
        return [{ dollarValue: row[LY_SLS_AMT] }, { pctValue: row[TY_SLS_AMT] / row[LY_SLS_AMT] - 1 }];
      case LABOR:
        return [
          { stringValue: row[ACT_HRS].toLocaleString(EN_US, { maximumFractionDigits: 0 }) },
          { stringValue: row[FCST_HRS_ROW].toLocaleString(EN_US, { maximumFractionDigits: 0 }) },
          { pctValue: row[ACT_HRS] / row[FCST_HRS_ROW] - 1 }
        ];
      case MARKDOWNS:
        return [
          { pctValue: row[SMKDN_AMT] / row[TY_SLS_AMT] },
          { pctValue: row[SMD_PCT_PLN] },
          {
            decimals: 0,
            numValue: 10000 * (row[SMKDN_AMT] / row[TY_SLS_AMT] - row[SMD_PCT_PLN]),
            postfix: ' bps'
          }
        ];
      default:
        return [];
    }
  };

  const yearScoreCardDataObj = {
    data: slsHistDataFiltered.map(row =>
      [
        isDateFormatFiscalYear && {
          stringValue: row.FSCL_YR
        },
        {
          orderBy: isDateFormatFiscalYear ? row.PERIOD_END_DT : undefined,
          stringValue: row[dateFormat]
        },
        timeframe === FISCAL_DAY && { stringValue: row[DAY_DESC] },
        (metric === SALES_PLAN || metric === SALES_COMP) && { dollarValue: row[TY_SLS_AMT] },
        ...dataTableByRow(row)
      ].filter(Boolean)
    ),
    headers: SCORE_CARD_HEADERS.filter(
      ({ dateFormat: dateFormatH, metric: metricH, timeframe: timeframeH }) =>
        (!timeframeH || timeframeH.includes(timeframe)) &&
        (!metricH || metricH.includes(metric)) &&
        (!dateFormatH || dateFormatH.includes(dateFormat))
    ),
    pagination: true,
    sortable: true,
    sortableDefault: isDateFormatFiscalYear ? 1 : 0,
    sortableColumns: [isDateFormatFiscalYear ? 1 : 0]
  };
  const fiscalYearsSortedItems = [{ value: ALL, text: ALL }, ...fiscalYearsSorted.map(row => ({ value: row, text: row }))];

  return (
    <>
      <Grid>
        <Grid.Column width={8} textAlign='left'>
          <Select label='Metric:' name='metric' onChange={setMetric} value={metric} items={METRIC_ITEMS} />
          <Select label='Timeframe:' name='timeframe' onChange={setTimeframe} value={timeframe} items={TIMEFRAME_ITEMS} />
        </Grid.Column>
        <Grid.Column width={8} textAlign='right'>
          <Select
            label='Fiscal Year:'
            name='fiscalYear'
            onChange={setFiscalYear}
            value={fiscalYear}
            items={fiscalYearsSortedItems}
          />
          <Select
            label='Date Format:'
            name='dateFormat'
            onChange={setDateFormat}
            value={dateFormat}
            items={DATE_FORMAT_ITEMS}
          />
        </Grid.Column>
      </Grid>

      <DataView>
        <DataView.Title>Full Year Scorecard</DataView.Title>

        <DataView.DataTable data={yearScoreCardDataObj} />
      </DataView>
    </>
  );
};
