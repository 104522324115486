import React, {useState, useEffect} from 'react'
import {
    Grid,
    Header,
    Loader,
    Menu,
    Divider,
  } from 'semantic-ui-react'
import { withRouter, Link } from "react-router-dom/cjs/react-router-dom.min";
import { useQuery } from 'react-query'
import { DataTable } from '../Common/DataTable'
import {getData} from '../../service/DataService'
import { TimeframeDropdownLimited } from '../Common/TimeframeDropdownLimited'
import { TimeframeDropdownMonLimited } from '../Common/TimeframeDropdownMonLimited'



export const SidekickSkuBayDept = withRouter(({ timeframe, settimeframe, level, fpToggle, locNbr, location }) => {

  useEffect(() => {
    if (timeframe !== 'WTD' && timeframe !== 'LW') {
      settimeframe('WTD')
    }
  }, [timeframe])

  const search = new URLSearchParams(location.search)
  const deptNbrInt = search.has('deptNbr') ? search.get('deptNbr') : 0

  const [tasksBreakdownTab, settasksBreakdownTab] = useState("DIR TASKS")
  const [drilldownTab, setdrilldownTab] = useState("DIR TASKS")
  const [drilldownTaskTypeTab, setdrilldownTaskTypeTab] = useState("SKU")

  
  const osaSidekickSkuBayQuery = useQuery([`query/queryOSASidekickSkuBay/${level}`, { locNbr, fpToggle }], getData)
  const osaSidekickSkuBayDateDtlQuery = useQuery([`queryOSASidekickSkuBayDateDtl`, { strNbr: locNbr, deptNbrInt, fpToggle }], getData)
  if (osaSidekickSkuBayQuery.isLoading || osaSidekickSkuBayDateDtlQuery.isLoading) { 
    return (<Loader active>Loading</Loader>)
  }
  if (
      (!osaSidekickSkuBayQuery.data || osaSidekickSkuBayQuery.data.length === 0)
      || (!osaSidekickSkuBayDateDtlQuery.data || osaSidekickSkuBayDateDtlQuery.data.length === 0)
    ) {
    return (<Header textAlign='center'>Invalid Store Number</Header>)
  }


  let dept_nm = ''

  const deptData = osaSidekickSkuBayQuery.data.filter(row => row.DEPT === deptNbrInt)

  const directedTaskSummary = deptData
  .filter(row => row.TASK_ORIGIN === 'SIDEKICK')
  .reduce((acc, row) => {
    acc.skuComp += row.TASK_TYPE === 'SKU' ? row[`${timeframe}_COMPLETED_TASK_COUNT`] : 0
    acc.skuSent += row.TASK_TYPE === 'SKU' ? row[`${timeframe}_TOTAL_TASK_COUNT`] : 0
    acc.bayComp += row.TASK_TYPE === 'BAY' ? row[`${timeframe}_COMPLETED_TASK_COUNT`] : 0
    acc.baySent += row.TASK_TYPE === 'BAY' ? row[`${timeframe}_TOTAL_TASK_COUNT`] : 0
    acc.ttlComp += row[`${timeframe}_COMPLETED_TASK_COUNT`]
    acc.ttlTasks += row[`${timeframe}_TOTAL_TASK_COUNT`]
    return acc
  }, {skuComp: 0, skuSent: 0, bayComp: 0, baySent: 0, ttlComp: 0, ttlTasks: 0})

  const directedTaskData = {
    headers: [
      {name: 'SKU Tasks #'}, {name: 'Bay Tasks #'}, {name: 'Ttl Tasks Comp %'}
    ],
    data: [
      [
        {stringValue: (<>Comp {directedTaskSummary.skuComp}<br />Ttl Sent {directedTaskSummary.skuSent}</>)}, 
        {stringValue: (<>Comp {directedTaskSummary.bayComp}<br />Ttl Sent {directedTaskSummary.baySent}</>)},
        {
          pctValue: directedTaskSummary.ttlComp / directedTaskSummary.ttlTasks, decimals: 1
        }
      ], 
    ]
  }

  const managerTaskSummary = deptData
  .filter(row => row.TASK_ORIGIN === 'MANAGER')
  .reduce((acc, row) => {
    acc.ttlComp += row[`${timeframe}_COMPLETED_TASK_COUNT`]
    acc.ttlTasks += row[`${timeframe}_TOTAL_TASK_COUNT`]
    return acc
  }, {ttlComp: 0, ttlTasks: 0})
  const managerAddedBaysData = {
    headers: [
      {name: 'Ttl Tasks Comp #'}, {name: 'Ttl Tasks Added #'}, {name: 'Ttl Tasks Comp %'}
    ],
    data: [
      [
        {numValue: managerTaskSummary.ttlComp}, 
        {numValue: managerTaskSummary.ttlTasks},
        {
          pctValue: managerTaskSummary.ttlComp / managerTaskSummary.ttlTasks, decimals: 1
        }
      ], 
    ]
  }

  const loggedWorkSummary = deptData
  .filter(row => row.TASK_TYPE === 'LOGGED')
  .reduce((acc, row) => {
    acc.total += row[`${timeframe}_SKUS_PACKED_DOWN`]
    return acc
  }, {total: 0})
  const loggedWorkData = {
    headers: [],
    data: [
      [
        {stringValue: 'Logged SKUs'}, 
        {numValue: loggedWorkSummary.total, postfix: ' SKUs'}
      ], 
    ]
  }

  // Get the data for the selected week (this week or last week)
  const weekDetail = osaSidekickSkuBayDateDtlQuery.data
  .filter(({WTD_FLG, LW_FLG}) => timeframe === 'WTD' ?  WTD_FLG === 1 : LW_FLG === 1)

  // Grab the department name really quick
  weekDetail.forEach(({DEPT_NM}) => dept_nm = DEPT_NM ? DEPT_NM : dept_nm)

  const dailyData = weekDetail
  .reduce((acc, row) => {

    // If we try to create a new Date object directly with a string, it'll make a UTC Midnight date that 
    // is then converted to the local timezone, which will generally subtract a day. This is not what we want.
    // So we need to create the date object directly from the year, month, and day values.
    const [year, month, day] = row.CREATED_DT.split('-').map(Number);
    // Create a Date object in local timezone
    const date = new Date(year, month - 1, day); // month is 0-indexed
    const dayOfWeek = date.getDay()
    acc[dayOfWeek].dir += row.TASK_TYPE === 'SKU' || row.TASK_TYPE === 'BAY' ? row.TOTAL_TASK_COUNT : 0
    acc[dayOfWeek].com += row.TASK_TYPE === 'SKU' || row.TASK_TYPE === 'BAY' ? row.COMPLETED_TASK_COUNT : 0
    acc[dayOfWeek].mgr += row.TASK_ORIGIN === 'MANAGER' ? row.TOTAL_TASK_COUNT : 0
    acc[dayOfWeek].mgrCom += row.TASK_ORIGIN === 'MANAGER' ? row.COMPLETED_TASK_COUNT : 0
    return acc
  }, [ // Initialize the array with 7 objects, one for each day of the week
    {dir:0,mgr:0,com:0,mgrCom:0},{dir:0,mgr:0,com:0,mgrCom:0},{dir:0,mgr:0,com:0,mgrCom:0},
    {dir:0,mgr:0,com:0,mgrCom:0},{dir:0,mgr:0,com:0,mgrCom:0},{dir:0,mgr:0,com:0,mgrCom:0},
    {dir:0,mgr:0,com:0,mgrCom:0}
  ])

  const directedTasksBreakdownData = {
    headers: [
      {name: ''},{name: 'M'},{name: 'T'},
      {name: 'W'},{name: 'T'},{name: 'F'},
      {name: 'S'},{name: 'S'},
    ],
    data: [
      [
        {stringValue: 'DIR'}, 
        {numValue: dailyData[1].dir ? dailyData[1].dir : '-'},
        {numValue: dailyData[2].dir ? dailyData[2].dir : '-'},
        {numValue: dailyData[3].dir ? dailyData[3].dir : '-'},
        {numValue: dailyData[4].dir ? dailyData[4].dir : '-'},
        {numValue: dailyData[5].dir ? dailyData[5].dir : '-'},
        {numValue: dailyData[6].dir ? dailyData[6].dir : '-'},
        {numValue: dailyData[0].dir ? dailyData[0].dir : '-'},
      ], 
      [
        {stringValue: 'COM'}, 
        {numValue: dailyData[1].com ? dailyData[1].com : '-'},
        {numValue: dailyData[2].com ? dailyData[2].com : '-'},
        {numValue: dailyData[3].com ? dailyData[3].com : '-'},
        {numValue: dailyData[4].com ? dailyData[4].com : '-'},
        {numValue: dailyData[5].com ? dailyData[5].com : '-'},
        {numValue: dailyData[6].com ? dailyData[6].com : '-'},
        {numValue: dailyData[0].com ? dailyData[0].com : '-'},
      ], 
    ]
  }
  const mgrAddTasksBreakdownData = {
    headers: [
      {name: ''},{name: 'M'},{name: 'T'},
      {name: 'W'},{name: 'T'},{name: 'F'},
      {name: 'S'},{name: 'S'},
    ],
    data: [
      [
        {stringValue: 'MGR'}, 
        {numValue: dailyData[1].mgr ? dailyData[1].mgr : '-'},
        {numValue: dailyData[2].mgr ? dailyData[2].mgr : '-'},
        {numValue: dailyData[3].mgr ? dailyData[3].mgr : '-'},
        {numValue: dailyData[4].mgr ? dailyData[4].mgr : '-'},
        {numValue: dailyData[5].mgr ? dailyData[5].mgr : '-'},
        {numValue: dailyData[6].mgr ? dailyData[6].mgr : '-'},
        {numValue: dailyData[0].mgr ? dailyData[0].mgr : '-'},
      ], 
      [
        {stringValue: 'COM'}, 
        {numValue: dailyData[1].mgrCom ? dailyData[1].mgrCom : '-'},
        {numValue: dailyData[2].mgrCom ? dailyData[2].mgrCom : '-'},
        {numValue: dailyData[3].mgrCom ? dailyData[3].mgrCom : '-'},
        {numValue: dailyData[4].mgrCom ? dailyData[4].mgrCom : '-'},
        {numValue: dailyData[5].mgrCom ? dailyData[5].mgrCom : '-'},
        {numValue: dailyData[6].mgrCom ? dailyData[6].mgrCom : '-'},
        {numValue: dailyData[0].mgrCom ? dailyData[0].mgrCom : '-'},
      ], 
    ]
  }

  const dirTasksSkuData = {
    sortable: true,
    headers: [
      {name: 'Bay'}, {name: 'SKU'}, {name: 'SKU DES'}, {name: 'OH Qty'}, {name: 'Associate'}
    ],
    data: weekDetail
    .filter(({TASK_TYPE}) => TASK_TYPE === 'SKU' )
    .map((row, i) => {
      return [
        {stringValue: row.AISLE_BAY}, 
        {stringValue: row.SKU_NBR}, 
        {stringValue: row.SKU_DESC}, 
        {numValue: row.OH_QTY}, 
        {stringValue: row.ASSOC}
      ]
    })
  }

  const dirTasksBaySummary = weekDetail
  .filter(({TASK_TYPE}) => TASK_TYPE === 'BAY')
  .reduce((acc, row) => {
    if (!acc[row.AISLE_BAY]) {
      acc[row.AISLE_BAY] = {skus: 0, ASSOC: '', compDate: ''}
    }
    acc[row.AISLE_BAY].skus += row.SKUS_PACKED_DOWN
    acc[row.AISLE_BAY].ASSOC = row.ASSOC
    acc[row.AISLE_BAY].compDate = row.CREATED_DT
    return acc
  }, {})
  const dirTasksBayData = {
    sortable: true,
    headers: [
      {name: 'Bay'}, {name: 'Num of SKU PKDN'}, {name: 'Associate'}, {name: 'Comp Date'}
    ],
    data: Object.entries(dirTasksBaySummary).map(([bay, row]) => {
      return [
        {
          stringValue: bay,
          /* TODO - replace when SIDEKICK_SKU_BAY_DATE_DTL is fixed */
          // link: {
          //   to: `/osa/sidekickskubay/dept/baypkdn`,
          //   params: {
          //     strNbr: locNbr,
          //     deptNbr: deptNbrInt,
          //     bay: bay,
          //     type: 'BAY'
          //   }
          // }
        }, 
        {numValue: row.skus}, 
        {stringValue: row.ASSOC},
        {stringValue: row.compDate}, 
      ]
    })
  }

  const mgrTasksBaySummary = weekDetail
  .filter(({TASK_ORIGIN}) => TASK_ORIGIN === 'MANAGER')
  .reduce((acc, row) => {
    if (!acc[row.AISLE_BAY]) {
      acc[row.AISLE_BAY] = {skus: 0, ASSOC: '', compDate: ''}
    }
    acc[row.AISLE_BAY].skus += row[`SKUS_PACKED_DOWN`] ?? 0
    acc[row.AISLE_BAY].ASSOC = row.ASSOC
    acc[row.AISLE_BAY].compDate = row.COMPLETED_DT
    return acc
  }, {})
  const mgrTasksBayData = {
    sortable: true,
    headers: [
      {name: 'Bay'}, {name: 'Num of SKU PKDN'}, {name: 'Associate'}, {name: 'Comp Date'}
    ],
    data: Object.entries(mgrTasksBaySummary).map(([bay, row]) => {
      return [
        {
          stringValue: bay,
          /* TODO - replace when SIDEKICK_SKU_BAY_DATE_DTL is fixed */
          // link: {
          //   to: `/osa/sidekickskubay/dept/baypkdn`,
          //   params: {
          //     strNbr: locNbr,
          //     deptNbr: deptNbrInt,
          //     bay: bay,
          //     type: 'MGR ADDED (BAY)'
          //   }
          // }
        }, 
        {numValue: row.skus}, 
        {stringValue: row.ASSOC},
        {stringValue: row.compDate}, 
      ]
    })
  }


  const logWorkBaySummary = weekDetail
  .filter(({TASK_TYPE}) => TASK_TYPE === 'LOGGED')
  .reduce((acc, row) => {
    if (!acc[row.AISLE_BAY]) {
      acc[row.AISLE_BAY] = {skus: 0, ASSOC: '', compDate: ''}
    }
    acc[row.AISLE_BAY].skus += row[`SKUS_PACKED_DOWN`] ?? 0
    acc[row.AISLE_BAY].ASSOC = row.ASSOC
    acc[row.AISLE_BAY].compDate = row.CREATED_DT
    return acc
  }, {})
  const logWorkBayData = {
    sortable: true,
    headers: [
      {name: 'Bay'}, {name: 'Num of SKU PKDN'}, {name: 'Associate'}, {name: 'Comp Date'}
    ],
    data: Object.entries(logWorkBaySummary).map(([bay, row]) => {
      return [
        {
          stringValue: bay,
          /* TODO - replace when SIDEKICK_SKU_BAY_DATE_DTL is fixed */
          // link: {
          //   to: `/osa/sidekickskubay/dept/baypkdn`,
          //   params: {
          //     strNbr: locNbr,
          //     deptNbr: deptNbrInt,
          //     bay: bay,
          //     type: 'LOGGED'
          //   }
          // }
        }, 
        {numValue: row.skus}, 
        {stringValue: row.ASSOC},
        {stringValue: row.compDate}, 
      ]
    })
  }


  let bottomData = null
  switch(drilldownTab) {
    case 'DIR TASKS': 
      bottomData = drilldownTaskTypeTab === 'SKU' ? dirTasksSkuData : dirTasksBayData
      break;
    case 'MGR ADD': 
      bottomData = mgrTasksBayData; break;
    case 'LOGGED WK':
      bottomData = logWorkBayData; break;
    default:
      bottomData = dirTasksSkuData
  }
  return (
    <>
    <Grid>
      <Grid.Column width={8}>
        <Grid.Row>
          <Link style={{ color: '#EE7125' }} to={`/${level}/overview?strNbr=${locNbr}`} data-test='b2o-link'>Overview</Link>
          {' / '}
          <Link style={{ color: '#EE7125' }} to={`/osa?strNbr=${locNbr}`} data-test='b2osa-link'>OSA</Link>
          {' / '}
          <Link style={{ color: '#EE7125' }} to={`/osa/sidekickskubay/store?strNbr=${locNbr}`} data-test='b2osa-link'>SKU/Bay</Link>
          {` / D${deptNbrInt}`}
        </Grid.Row>
        <Grid.Row>&nbsp;</Grid.Row>
          </Grid.Column>
          <Grid.Column width={8}>
                        Timeframe: 
              {new Date().getDay() === 1 ?
                <TimeframeDropdownMonLimited timeframe={timeframe} settimeframe={settimeframe} /> :
                <TimeframeDropdownLimited timeframe={timeframe} settimeframe={settimeframe} />}
      </Grid.Column>
    </Grid>

    <Divider />

    <Header textAlign='center' >{`${deptNbrInt} ${dept_nm} - SKU/Bay Overview`}</Header>
    Directed Tasks
    <DataTable data={directedTaskData} />
    Manager Added Bays
    <DataTable data={managerAddedBaysData} />
    Logged Work
    <DataTable data={loggedWorkData} />

    <Divider />

    <Header textAlign='center' >{`Tasks breakdown by WTD`}</Header>
    Tasks<br />
    <Menu compact>
      <Menu.Item
        name='DIR TASKS'
        active={tasksBreakdownTab === 'DIR TASKS'}
        content='DIR TASKS'
        onClick={() => {
          settasksBreakdownTab('DIR TASKS')
        }}
      />
      <Menu.Item
        name='MGR ADD'
        active={tasksBreakdownTab === 'MGR ADD'}
        content='MGR ADD'
        onClick={() => {
          settasksBreakdownTab('MGR ADD')
        }}
      />
    </Menu>
    <DataTable data={tasksBreakdownTab === 'DIR TASKS' ? directedTasksBreakdownData : mgrAddTasksBreakdownData} />
    {
        tasksBreakdownTab === 'DIR TASKS' ?
            'DIR: Directed tasks sent by Sidekick by day' : 'MGR: Manager added bays by day'
    } <br />
    COM: Completed tasks by day
    
    <Divider />

    <Menu compact>
      <Menu.Item
        name='DIR TASKS'
        active={drilldownTab === 'DIR TASKS'}
        content='DIR TASKS'
        onClick={() => {
          setdrilldownTab('DIR TASKS')
        }}
      />
      <Menu.Item
        name='MGR ADD'
        active={drilldownTab === 'MGR ADD'}
        content='MGR ADD'
        onClick={() => {
          setdrilldownTab('MGR ADD')
        }}
      />
      <Menu.Item
        name='LOGGED WK'
        active={drilldownTab === 'LOGGED WK'}
        content='LOGGED WK'
        onClick={() => {
          setdrilldownTab('LOGGED WK')
        }}
      />
    </Menu>
    
    {
        drilldownTab === 'DIR TASKS' &&
        <><br /><br /> Task Type <br />
        <Menu compact>
            <Menu.Item
            name='SKU'
            active={drilldownTaskTypeTab === 'SKU'}
            content='SKU'
            onClick={() => {
                setdrilldownTaskTypeTab('SKU')
            }}
            />
            <Menu.Item
            name='BAY'
            active={drilldownTaskTypeTab === 'BAY'}
            content='BAY'
            onClick={() => {
                setdrilldownTaskTypeTab('BAY')
            }}
            />
        </Menu></>
    }
    <DataTable data={bottomData} />
    </>
)
})
