import React, { useState, useEffect } from 'react'
import {
  Table,
  Pagination,
  Grid
} from 'semantic-ui-react'

export const SlaMissBoss = ({ slaMissData, timeframe }) => {
  const [sortColumn, setSortColumn] = useState('USER_ID')
  const [sortDirection, setSortDirection] = useState('ascending')
  const [pageNum, setPageNum] = useState(1)
  let rowsPerPage = 50

  useEffect(() => {
    setPageNum(1)
  }, [timeframe])

  let slaMissDataFilter = slaMissData.filter((row) => {
    return row && row[timeframe + '_FLG'] === 1 && row['CHANNEL'] === 'BOSS'
  })

  let slaMissDataSort = slaMissDataFilter.sort((left, right) => {
    let result = left[sortColumn] - right[sortColumn]
    if (sortColumn === 'USER_ID') {
      result = left.USER_ID > right.USER_ID ? 1 : -1
    }
    if (sortColumn === 'EXTN_HOST_ORDER_REF') {
      result = left.EXTN_HOST_ORDER_REF > right.EXTN_HOST_ORDER_REF ? 1 : -1
    }
    if (sortColumn === 'ASSOC_NM') {
      result = left.ASSOC_NM > right.ASSOC_NM ? 1 : -1
    }
    if (sortColumn === 'FULFL_DT') {
      result = left.FULFL_DT > right.FULFL_DT ? 1 : -1
    }
    if (sortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  let slaMissDataPagination = slaMissDataSort.slice(rowsPerPage * (pageNum - 1), parseInt(Math.min(rowsPerPage * pageNum, slaMissDataSort.length)))

  return (
    <>
      {slaMissDataSort.length > rowsPerPage && <Grid>
        <Grid.Column width={16} textAlign='center'>
          <Grid.Row>&nbsp;</Grid.Row>
          <Grid.Row>
            <Pagination
              size='mini'
              defaultActivePage={1}
              totalPages={Math.floor((slaMissDataSort.length - 1) / rowsPerPage) + 1}
              activePage={pageNum}
              boundaryRange={0}
              siblingRange={1}
              onPageChange={(e, { activePage }) => {
                setPageNum(activePage)
              }}
            />
          </Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
        </Grid.Column>
      </Grid>}

      <Table textAlign='center' celled sortable unstackable size='small' style={{ fontSize: 10 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={sortColumn === 'EXTN_HOST_ORDER_REF' ? sortDirection : null}
              onClick={() => {
                if (sortColumn !== 'EXTN_HOST_ORDER_REF') {
                  setSortColumn('EXTN_HOST_ORDER_REF')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>Order #</Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'USER_ID' ? sortDirection : null}
              onClick={() => {
                if (sortColumn !== 'USER_ID') {
                  setSortColumn('USER_ID')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>User ID</Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'ASSOC_NM' ? sortDirection : null}
              onClick={() => {
                if (sortColumn !== 'ASSOC_NM') {
                  setSortColumn('ASSOC_NM')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>Associate<br />Name</Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'FULFL_DT' ? sortDirection : null}
              onClick={() => {
                if (sortColumn !== 'FULFL_DT') {
                  setSortColumn('FULFL_DT')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>Fulfill<br />Date</Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'MINS_TO_PICK' ? sortDirection : null}
              onClick={() => {
                if (sortColumn !== 'MINS_TO_PICK') {
                  setSortColumn('MINS_TO_PICK')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>Mins<br />to Pick</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {slaMissDataPagination.map((data) => {
            return (
              <Table.Row>
                <Table.Cell>
                  {data['EXTN_HOST_ORDER_REF']}
                </Table.Cell>
                <Table.Cell>
                  {data['USER_ID']}
                </Table.Cell>
                <Table.Cell>
                  {data['ASSOC_NM']}
                </Table.Cell>
                <Table.Cell>
                  {data['FULFL_DT'].toString().slice(0, 10)}
                </Table.Cell>
                <Table.Cell>
                  {data['MINS_TO_PICK'] !== null ?
                    data['MINS_TO_PICK'].toLocaleString(undefined, { maximumFractionDigits: 2 }) :
                    '-'}
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </>
  )
}