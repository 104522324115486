import React from 'react';
import { DataTable } from '../Common/DataTable';

const TABLE_DATA = {
  orderRef: {
    name: 'Order #',
    value: 'EXTN_HOST_ORDER_REF'
  },
  userId: {
    name: 'User ID',
    value: 'USER_ID'
  },
  associateName: {
    name: 'Associate Name',
    value: 'ASSOC_NM'
  },
  fulfillDate: {
    name: 'Fulfill Date',
    value: 'FULFL_DT'
  },
  minsToPick: {
    name: 'Mins to Pick',
    value: 'MINS_TO_PICK'
  }
};
const { orderRef, userId, associateName, fulfillDate, minsToPick } = TABLE_DATA;

const HEADERS = [
  { name: orderRef.name },
  { name: userId.name },
  { name: associateName.name },
  { name: fulfillDate.name },
  { name: minsToPick.name }
];

const SORTABLE = true;
const SORTABLE_COLUMNS = HEADERS.map((_, i) => i);
const SORT_DEFAULT = HEADERS.findIndex(header => header.name === userId.name);
const SORT_DIRECTION = 'descending';

const PAGINATION = true;

const FLG = 'FLG';
const CHANNEL = 'CHANNEL';
const BOPIS = 'BOPIS';
const getSlaMissBopisData = ({ slaMissData, timeframe }) => {
  const slaMissDataFiltered = slaMissData.filter(row => row?.[`${timeframe}_${FLG}`] === 1 && row?.[CHANNEL] === BOPIS);
  const slaMissDataFormatted = slaMissDataFiltered.map(row => [
    { stringValue: row[orderRef.value] },
    { stringValue: row[userId.value] },
    { stringValue: row[associateName.value] },
    { stringValue: row[fulfillDate.value] },
    { numValue: row[minsToPick.value] }
  ]);

  return slaMissDataFormatted;
};

const getSlaMissBopisDataTable = ({ slaMissData, timeframe }) => {
  const data = getSlaMissBopisData({ slaMissData, timeframe });

  return {
    sortable: SORTABLE,
    sortableColumns: SORTABLE_COLUMNS,
    sortableDefault: SORT_DEFAULT,
    sortDirection: SORT_DIRECTION,
    pagination: PAGINATION,
    headers: HEADERS,
    data
  };
};

export const SlaMissBopis = ({ slaMissData, timeframe }) => {
  const slaMissBopisDataTable = getSlaMissBopisDataTable({ slaMissData, timeframe });

  return <DataTable data={slaMissBopisDataTable} />;
};
