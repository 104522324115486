import { Header, Loader, Tab } from 'semantic-ui-react';
import useQueryApi from '../../../../../hooks/useQueryApi';
import { CoreEventsDetails } from '../components/CoreEventsDetails';
import { useQueryParams } from '../../../../../hooks/useQueryParams';
import { CoreEventListAtDivision } from '../components/CoreEventListAtDivision';

export const CoreEventsDetailPage = ({ fpToggle }) => {
  const { evntId = 0 } = useQueryParams();
  const params = { isCore: true, evntId, fpToggle };

  const eventsSkuDetails = useQueryApi('queryEventsSkuDetailsAtCore', params);
  const eventsSummaryDetails = useQueryApi('queryEventsSummaryDetailsAtCore', params);
  const eventsSummaryAggregationDetails = useQueryApi('queryEventsSummaryAggregationDetailsAtCore', params);

  if (eventsSkuDetails.isLoading || eventsSummaryDetails.isLoading || eventsSummaryAggregationDetails.isLoading) {
    return <Loader active>Loading...</Loader>;
  }
  if (!eventsSkuDetails.data || !eventsSummaryDetails.data || !eventsSummaryAggregationDetails.data) {
    return <Header active>Not Events Details data found.</Header>;
  }

  const tabs = [
    {
      menuItem: 'Core',
      render: () => (
        <CoreEventsDetails
          fpToggle={fpToggle}
          eventsSkuDetails={eventsSkuDetails.data}
          eventsSummaryDetails={eventsSummaryDetails.data[0]}
        />
      )
    },
    {
      menuItem: 'Division List',
      render: () => <CoreEventListAtDivision events={eventsSummaryAggregationDetails.data} />
    }
  ];

  return (
    <div>
      <Header>{eventsSummaryDetails.data[0]?.EVNT_NM} - Core</Header>

      <div className='d-flex mb-4 gap-3'>
        <span>Begin: {eventsSummaryDetails.data[0]?.EVNT_EFF_BGN_DT}</span>
        <span>End: {eventsSummaryDetails.data[0]?.EVNT_EFF_END_DT}</span>
      </div>

      <Tab className='mt-3' panes={tabs} />
    </div>
  );
};
