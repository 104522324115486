import { IMAGING_GOAL } from '../../../../../../components/OSA/OSAImaging';
import {
  OSA_OH_AUTO_REJECT_RATE_GOAL,
  OSA_OHM_NO_LOC_TAGS_GOAL,
  OSA_OVERHEAD_IMAGING_GOAL,
  OSA_SIDEKICK_PURGE_GOAL,
  OSA_SIDEKICK_SKUBAY_GOAL,
  OSA_URGENT_TASK_EXEC_GOAL
} from '../../../../../../constants/goals';

export const districtSummary = {
  tasksSkuBayCompleted: 0,
  tasksSkuBayCompletedGoal: 0,
  tasksPurgeCompleted: 0,
  tasksPurgeCompletedGoal: 0
};

export const DOUBLE_DASH = '--';
export const SINGLE_DASH = '-';
export const MERCH_LEAD = 'MERCH LEAD';

export const getOsaImagingHeaders = lastTimeframe => [
  { name: 'Division' },
  { name: 'Actual ' },
  { name: 'Goal ' },
  { name: lastTimeframe ? `v${lastTimeframe} bps ` : '- ' }
];

export const osaImagingDataColumns = (osaImagingDataSummary, timeframe, lastTimeframe) =>
  Object.entries(osaImagingDataSummary).map(([store, dat]) => {
    const checkValues = ['L12', 'YTD'].includes(timeframe) && dat.lastGoal === 0;
    return [
      {
        stringValue: store,
        link: {
          to: '/osa/division/imaging/',
          params: {
            divNbr: store
          }
        }
      },
      { pctValue: dat.actual / dat.goal, decimals: 1, compareValue: IMAGING_GOAL },
      { pctValue: IMAGING_GOAL },
      lastTimeframe && !checkValues
        ? {
            numValue: Math.round((dat.actual / dat.goal - dat.lastActual / dat.lastGoal) * 10000),
            compareStyle: true,
            postfix: ' bps'
          }
        : { stringValue: DOUBLE_DASH }
    ];
  });


export const osaImagingSummaryReduced = (osaImagingData, timeframe, lastTimeframe) =>
  osaImagingData.reduce((acc, row) => {
    const columField = row.DIV_NBR.toString().padStart(4, '0');
    if (!acc[columField]) {
      acc[columField] = { actual: 0, goal: 0, lastActual: 0, lastGoal: 0 };
    }
    acc[columField].actual += row[`${timeframe}_IMAGES_TAKEN`];
    acc[columField].goal += row[`${timeframe}_IMAGES_EXPECTED`];
    if (lastTimeframe) {
      acc[columField].lastActual += row[`${lastTimeframe}_IMAGES_TAKEN`];
      acc[columField].lastGoal += Number(row[`${lastTimeframe}_IMAGES_EXPECTED`]);
    } else {
      acc[columField].lastActual = null;
      acc[columField].lastGoal = null;
    }
    return acc;
  }, {});

export const osaImaginBreadcrumbItems = [
  { to: '/core/overview', text: 'Overview' },
  { to: '/core/osa', text: 'OSA' },
  { text: 'Imaging%' }
];

export const isFirstweekDay = new Date().getDay() === 1;

export const OSA_OVERVIEW_HEADERS = lastTimeFrame => [
  { name: '' },
  { name: 'Actual' },
  { name: 'Goal' },
  { name: lastTimeFrame ? `v${lastTimeFrame} bps` : SINGLE_DASH }
];
export const osaOverviewDataBuilder = (showDrilldown, osaSummaryData, timeframe, lastTimeFrame) => [
  [
    {
      stringValue: 'Sidekick Exec - SKU/BAY',
      link: showDrilldown && {
        to: `osa/sidekickskubay`
      }
    },
    {
      pctValue: (districtSummary.tasksSkuBayCompleted || 0) / (districtSummary.tasksSkuBayCompletedGoal || 1),
      compareValue: OSA_SIDEKICK_SKUBAY_GOAL
    },
    {
      pctValue: OSA_SIDEKICK_SKUBAY_GOAL
    },
    {
      stringValue: DOUBLE_DASH
    }
  ],
  [
    {
      stringValue: 'Sidekick Exec - Purge',
      link: showDrilldown && {
        to: `/core/osa/Purge`
      }
    },
    {
      pctValue: (districtSummary.tasksPurgeCompleted || 0) / (districtSummary.tasksPurgeCompletedGoal || 1),
      compareValue: OSA_SIDEKICK_PURGE_GOAL
    },
    {
      pctValue: OSA_SIDEKICK_PURGE_GOAL
    },
    {
      stringValue: DOUBLE_DASH
    }
  ],
  [
    {
      stringValue: 'Overhead Imaging %',
      link: showDrilldown && {
        to: `/core/osa/imaging`
      }
    },
    {
      pctValue: osaSummaryData[timeframe + '_IMAGES_TAKEN'] / osaSummaryData[timeframe + '_IMAGES_EXPECTED'],
      compareValue: OSA_OVERHEAD_IMAGING_GOAL
    },
    {
      pctValue: OSA_OVERHEAD_IMAGING_GOAL
    },
    {
      numValue: lastTimeFrame
        ? osaSummaryData[timeframe + '_IMAGES_TAKEN'] - osaSummaryData[lastTimeFrame + '_IMAGES_TAKEN']
        : SINGLE_DASH,
      postfix: ' bps',
      compareStyle: true
    }
  ],
  [
    {
      stringValue: 'OHM No Loc Tags %',
      link: showDrilldown && {
        to: `/core/osa/ohmnoloc`
      }
    },
    {
      pctValue: osaSummaryData[timeframe + '_NO_LOC_PALLET_COUNT'] / osaSummaryData[timeframe + '_TOTAL_PALLET_COUNT'],
      compareValue: OSA_OHM_NO_LOC_TAGS_GOAL,
      compareStyleInvert: true
    },
    {
      pctValue: OSA_OHM_NO_LOC_TAGS_GOAL,
      prefix: '<'
    },
    { stringValue: SINGLE_DASH }
  ],
  [
    { stringValue: 'Urgent Task Exec.' },
    {
      pctValue: osaSummaryData[timeframe + '_COMP_TASK_CNT_24_HR'] / osaSummaryData[timeframe + '_TOTAL_TASK_CNT_24_HR'],
      compareValue: OSA_URGENT_TASK_EXEC_GOAL
    },
    {
      pctValue: OSA_URGENT_TASK_EXEC_GOAL
    },
    { stringValue: SINGLE_DASH }
  ],
  [
    { stringValue: 'OH Auto Reject Rate' },
    {
      pctValue: osaSummaryData[timeframe + '_OH_AUTO_RJT_CNT'] / osaSummaryData[timeframe + '_OH_NEED_APPRV_CNT'],
      compareValue: OSA_OH_AUTO_REJECT_RATE_GOAL,
      compareStyleInvert: true
    },
    {
      pctValue: OSA_OH_AUTO_REJECT_RATE_GOAL,
      prefix: '<'
    },
    { stringValue: SINGLE_DASH }
  ]
];
