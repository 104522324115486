import React, { useState, useEffect } from "react";
import { Header, Loader, Grid, Segment, Table, Icon, Pagination } from 'semantic-ui-react';
import { useQuery } from 'react-query';
import { withRouter, Link } from 'react-router-dom/cjs/react-router-dom.min';
import { getData } from '../../service/DataService';

import { TimeframeDropdownLimited } from '../Common/TimeframeDropdownLimited'
import { TimeframeDropdownMonLimited } from '../Common/TimeframeDropdownMonLimited'

export const SideKickPurgeDeptPurgeByBay = withRouter(({ timeframe, settimeframe, level, fpToggle, locNbr, location, ...props}) => {

    const search = new URLSearchParams(location.search);
    const bay = search.has("bay") ? search.get("bay") : 0;
    const [pageNum, setPageNum] = useState(1)
    let rowsPerPage = 20
    const [sortColumn, setSortColumn] = useState("SKU_NBR")
    const [sortDirection, setSortDirection] = useState("asecending")

    const queryOSASidekickSkuBayDateDtlPurgeBayQuery = useQuery([`queryOSASidekickSkuBayDateDtlBreakDown`, { strNbr: locNbr, fpToggle }], getData)
    
    useEffect(() => {
        setPageNum(1)
    }, [timeframe, sortColumn, sortDirection])

    if (queryOSASidekickSkuBayDateDtlPurgeBayQuery.isLoading) {
        return (<Loader active>Loading</Loader>)
    }

    if (!queryOSASidekickSkuBayDateDtlPurgeBayQuery.data || queryOSASidekickSkuBayDateDtlPurgeBayQuery.data.length === 0) {
        return (<Header textAlign='center'>Invalid Store Number</Header>)
    }

    const queryOSASidekickSkuBayDateDtlPurgeBayData = queryOSASidekickSkuBayDateDtlPurgeBayQuery.data

    let queryOSASidekickSkuBayDateDtlPurgeBayDataFilter = queryOSASidekickSkuBayDateDtlPurgeBayData.filter((row) => {
        return (
            row['AISLE_BAY'] === bay && row['TASK_TYPE'] === 'PURGE' && row['SKU_NBR'] !== null && row['ASSOC'] !== null && row['OH_QTY'] !== null
        )
    })

    let DeptPurgeByBaySorted = queryOSASidekickSkuBayDateDtlPurgeBayDataFilter.sort((left, right) => {
        let result = left[sortColumn] - right[sortColumn]
        if (sortColumn === 'SKU_NBR') {
          result = left.SKU_NBR > right.SKU_NBR ? 1 : -1
        }
        if (sortColumn === 'OH_QTY') {
          result = left.OH_QTY > right.OH_QTY ? 1 : -1
        }
        if (sortDirection === 'descending') {
            result *= -1
        }
        return result
      })

    let dataPaginationDeptPurgeByBay = DeptPurgeByBaySorted.slice(rowsPerPage * (pageNum - 1), parseInt(Math.min(rowsPerPage * pageNum, DeptPurgeByBaySorted.length)))

    let assocName;
    let compDate;
    let deptNbr;
    if (dataPaginationDeptPurgeByBay && dataPaginationDeptPurgeByBay.length > 0) {
        assocName = dataPaginationDeptPurgeByBay[0].ASSOC
        compDate = dataPaginationDeptPurgeByBay[0].COMPLETED_DT
        deptNbr = dataPaginationDeptPurgeByBay[0].DEPT_NBR
    } else {
        assocName = '-'
        compDate = '-'
    }

    return (
        <>
        <Segment>
        <Grid>
            <Grid.Column width={8}>
                <Grid.Row>
                <Link style={{ color: '#EE7125' }} to={`/${level}/overview?strNbr=${locNbr}`} data-test='b2o-link'>Overview</Link>
                {' / '}
                <Link style={{ color: '#EE7125' }} to={`/osa?strNbr=${locNbr}`} data-test='b2osa-link'>OSA</Link>
                {' / '}
                <Link style={{ color: '#EE7125' }} to={`/osa/Purge/store?strNbr=${locNbr}`} data-test='b2osa-link'>Purge</Link>
                {' / '}
                <Link style={{ color: '#EE7125' }} to={`/osa/Purge/deptPurge?strNbr=${locNbr}&deptNbr=${deptNbr}`} data-test='b2osa-link'>{deptNbr}</Link>
                {` / ${bay}`}
                </Grid.Row>
                <Grid.Row>&nbsp;</Grid.Row>
            </Grid.Column>
            <Grid.Column width={7} />
            <Grid.Column width={7}>
                        Timeframe: 
              {new Date().getDay() === 1 ?
                <TimeframeDropdownMonLimited timeframe={timeframe} settimeframe={settimeframe} /> :
                <TimeframeDropdownLimited timeframe={timeframe} settimeframe={settimeframe} />}
            </Grid.Column>
        </Grid>

        <Header textAlign='center'>Purge Packdown {bay}</Header>
        <p style={{ textAlign:"center" }}>Completed by: {assocName}</p>
        <p style={{ textAlign:"center" }}>Completion Date: {compDate}</p>
        <Grid>
        {DeptPurgeByBaySorted.length > rowsPerPage && <Grid.Row>
          <Grid.Column width={16} textAlign='center'>
            <Pagination
              size='mini'
              defaultActivePage={1}
              totalPages={Math.floor((DeptPurgeByBaySorted.length - 1) / rowsPerPage) + 1}
              activePage={pageNum}
              boundaryRange={0}
              siblingRange={1}
              onPageChange={(e, { activePage }) => {
                setPageNum(activePage)
              }}
            />
          </Grid.Column>
        </Grid.Row>}
        </Grid>
        <Grid.Row>&nbsp;</Grid.Row>
        <Table textAlign='center' unstackable celled sortable size='small'>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    sorted={sortColumn === 'SKU_NBR' ? sortDirection : null}
                    onClick={() => {
                        if (sortColumn !== 'SKU_NBR') {
                            setSortColumn('SKU_NBR')
                            setSortDirection('ascending')
                        } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                            }
                    }}>SKU {sortColumn !== 'SKU_NBR' && <Icon fitted name='sort' />}</Table.HeaderCell>
                    <Table.HeaderCell>SKU DES</Table.HeaderCell>
                    <Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    sorted={sortColumn === 'OH_QTY' ? sortDirection : null}
                    onClick={() => {
                        if (sortColumn !== 'OH_QTY') {
                            setSortColumn('OH_QTY')
                            setSortDirection('ascending')
                        } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                            }
                    }}>OH QTY {sortColumn !== 'OH_QTY' && <Icon fitted name='sort' />}</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {dataPaginationDeptPurgeByBay.length === 0 ? (
                <Table.Row>
                    <Table.Cell>{'-'}</Table.Cell>
                    <Table.Cell>{'-'}</Table.Cell>
                    <Table.Cell>{'-'}</Table.Cell>
                </Table.Row> ) : (
                dataPaginationDeptPurgeByBay.map((data) => {
                    return (
                <Table.Row>
                    <Table.Cell>{data.SKU_NBR ? data.SKU_NBR : '-'}</Table.Cell>
                    <Table.Cell>{data.SKU_DESC ? data.SKU_DESC : '-'}</Table.Cell>
                    <Table.Cell>{data.OH_QTY ? data.OH_QTY : '-'}</Table.Cell>
                </Table.Row>)}))}
            </Table.Body>
        </Table>
        </Segment>
        </>
    )
 })