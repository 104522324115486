import React from "react";
import { Menu } from "semantic-ui-react";

export const AvSAvFMenu = ({ avsOrAvf, setAvsOrAvf }) => {
  return (
    <Menu compact size="small">
      <Menu.Item
        name="AVF"
        active={avsOrAvf === "AVF"}
        content="AVF"
        onClick={() => {
          setAvsOrAvf("AVF");
        }}
      />
      <Menu.Item
        name="AVS"
        active={avsOrAvf === "AVS"}
        content="AVS"
        onClick={() => {
          setAvsOrAvf("AVS");
        }}
      /> 
    </Menu>
  );
};
