import CoreContextProvider from '../../../context/CoreContext';
import { OverviewMenu } from '../components/OverviewMenu';

export const CoreOverviewPage = props => {
  return (
    <CoreContextProvider {...props}>
      <OverviewMenu />
    </CoreContextProvider>
  );
};
