import React, { useState } from 'react'
import {
  Table,
  Pagination,
  Grid
} from 'semantic-ui-react'

export const DelvMissWOBin = ({ missedDelvDtlData, timeframe }) => {
  const [sortColumn, setSortColumn] = useState('EXTN_HOST_ORDER_REF')
  const [sortDirection, setSortDirection] = useState('descending')
  const [pageNum, setPageNum] = useState(1)
  let rowsPerPage = 50

  let dataFilter = missedDelvDtlData.filter((row) => {
    return row && row['MISS_CATEGORY'] === 'WORK_ORDER_BIN' && row[timeframe+'_FLG']
  })

  let dataSort = dataFilter.sort((left, right) => {
    let result = left[sortColumn] - right[sortColumn]
    if (sortColumn === 'EXTN_HOST_ORDER_REF') {
      result = left.EXTN_HOST_ORDER_REF > right.EXTN_HOST_ORDER_REF ? 1 : -1
    }
    if (sortColumn === 'WORK_ORDER_NO') {
      result = left.WORK_ORDER_NO > right.WORK_ORDER_NO ? 1 : -1
    }
    if (sortColumn === 'MISS_TYPE') {
      result = left.MISS_TYPE > right.MISS_TYPE ? 1 : -1
    }
    if (sortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  let dataPagination = dataSort.slice(rowsPerPage * (pageNum - 1), parseInt(Math.min(rowsPerPage * pageNum, dataSort.length)))

  return (
    <>
      {dataSort.length > rowsPerPage && <Grid>
          <Grid.Column width={16} textAlign='center'>
            <Grid.Row>&nbsp;</Grid.Row>
            <Grid.Row>
              <Pagination
                size='mini'
                defaultActivePage={1}
                totalPages={Math.floor((dataSort.length - 1) / rowsPerPage) + 1}
                activePage={pageNum}
                boundaryRange={0}
                siblingRange={1}
                onPageChange={(e, { activePage }) => {
                  setPageNum(activePage)
                }}
              />
            </Grid.Row>
            <Grid.Row>&nbsp;</Grid.Row>
          </Grid.Column>
        </Grid>}
        <Table textAlign='center' celled sortable unstackable size='small' style={{ fontSize: 10 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={sortColumn === 'EXTN_HOST_ORDER_REF' ? sortDirection : null}
              onClick={() => {
                if (sortColumn !== 'EXTN_HOST_ORDER_REF') {
                  setSortColumn('EXTN_HOST_ORDER_REF')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>Order #</Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'WORK_ORDER_NO' ? sortDirection : null}
              onClick={() => {
                if (sortColumn !== 'WORK_ORDER_NO') {
                  setSortColumn('WORK_ORDER_NO')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>Work Order #</Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'MISS_TYPE' ? sortDirection : null}
              onClick={() => {
                if (sortColumn !== 'MISS_TYPE') {
                  setSortColumn('MISS_TYPE')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>Reason Code</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {dataPagination.map((data) => {
            return (
              <Table.Row>
                <Table.Cell>{data['EXTN_HOST_ORDER_REF']}</Table.Cell>
                <Table.Cell>{data['WORK_ORDER_NO']}</Table.Cell>
                <Table.Cell>{data['MISS_TYPE']}</Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </>
  )

}