import React, { useState, useEffect } from 'react'
import { Header, Grid, Table, Loader, Icon, Pagination } from 'semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getData } from '../../service/DataService'
import { getDollarString, getPercentString } from '../Common/TableCellUtils'
import { TimeframeDropdownShrink } from '../Common/TimeframeDropdownShrink'

export const ShrinkDamagedMd = withRouter(({ timeframe, settimeframe, fpToggle, location }) => {

  const search = new URLSearchParams(location.search)
  const strNbr = search.has('strNbr') ? search.get('strNbr') : 0

  const [sortColumn, setSortColumn] = useState(timeframe+"_DAMAGED_MD")
  const [sortDirection, setSortDirection] = useState("descending")
  const [pageNum, setPageNum] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(20)

  const shrinkDamagedMdQuery = useQuery(['queryShrinkDamagedMd', { strNbr, fpToggle }], getData)

  useEffect(() => {
    setPageNum(1)
  }, [sortColumn, sortDirection, rowsPerPage])

  if (shrinkDamagedMdQuery.isLoading) {
    return (<Loader active>Loading...</Loader>)
  }

  if (!shrinkDamagedMdQuery.data) {
    return (<Header textAlign='center'>Invalid Store Number</Header>)
  }

  const data = shrinkDamagedMdQuery.data

  let dataFilterNulls = data.filter((row) => {
    return row && row['EXT_CLASS_NBR'] !== null
  })

  let dataSorted = dataFilterNulls.sort((left, right) => {
    let result = left[sortColumn] - right[sortColumn]
    if (sortColumn === 'EXT_CLASS_NBR') {
      result = left.EXT_CLASS_NBR > right.EXT_CLASS_NBR ? 1 : -1
    }
    if (sortColumn === 'CLASS_DESC') {
      result = left.CLASS_DESC > right.CLASS_DESC ? 1 : -1
    }
    if (sortColumn === 'LN_AVAIL_DT') {
      result = left.LN_AVAIL_DT > right.LN_AVAIL_DT ? 1 : -1
    }
    if (sortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  let dataPagination = dataSorted.slice(rowsPerPage * (pageNum - 1), parseInt(Math.min(rowsPerPage * pageNum, dataSorted.length)))

  return (
    <>
      <Grid>
        <Grid.Column width={8} textAlign='left'>
          <Grid.Row><Link style={{ color: '#EE7125' }} to={`/store/shrink?strNbr=${strNbr}`} data-test='b2o-link'>Back to Shrink Overview</Link></Grid.Row>
        </Grid.Column>
        <Grid.Column width={8}></Grid.Column>
      </Grid>
      <Grid>
        <Grid.Column width={8} textAlign='left'>
          <TimeframeDropdownShrink timeframe={timeframe} settimeframe={settimeframe} />
        </Grid.Column>
        <Grid.Column textAlign='justified' width={8}>
          <Grid.Row>
            Rows per Page:
          </Grid.Row>
          <Grid.Row>
            <div>
              <select
                name="rowsPerPage"
                onChange={e => {
                  setRowsPerPage(e.target.value)
                }}
                value={rowsPerPage}
                style={{ padding: 10 }}
              >
                <option value="20" selected>20</option>
                <option value="40">40</option>
              </select>
            </div>
          </Grid.Row>
        </Grid.Column>
      </Grid>
      <Grid>
      {dataSorted.length > rowsPerPage && <Grid.Row>
          <Grid.Column width={16} textAlign='center'>
            <Pagination
              size='mini'
              defaultActivePage={1}
              totalPages={Math.floor((dataSorted.length - 1) / rowsPerPage) + 1}
              activePage={pageNum}
              boundaryRange={0}
              siblingRange={1}
              onPageChange={(e, { activePage }) => {
                setPageNum(activePage)
              }}
            />
          </Grid.Column>
        </Grid.Row>}
      </Grid>
      <Header textAlign='center'>Damaged Markdowns - Store {strNbr}</Header>
      <Table textAlign='center' unstackable celled sortable size='small' style={{ fontSize: 12 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={sortColumn === 'EXT_CLASS_NBR' ? sortDirection : null}
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumn !== 'EXT_CLASS_NBR') {
                  setSortColumn('EXT_CLASS_NBR')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>Class Number {sortColumn !== 'EXT_CLASS_NBR' && <Icon fitted name='sort' />}</Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'CLASS_DESC' ? sortDirection : null}
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumn !== 'CLASS_DESC') {
                  setSortColumn('CLASS_DESC')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>Class Description {sortColumn !== 'CLASS_DESC' && <Icon fitted name='sort' />}</Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === timeframe+'_DAMAGED_MD' ? sortDirection : null}
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumn !== timeframe+'_DAMAGED_MD') {
                  setSortColumn(timeframe+'_DAMAGED_MD')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>Damaged MD $ {sortColumn !== 'DAMAGED_MD' && <Icon fitted name='sort' />}</Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === timeframe+'_DAMAGED_MD' ? sortDirection : null}
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumn !== timeframe+'_DAMAGED_MD') {
                  setSortColumn(timeframe+'_DAMAGED_MD')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>Damaged MD % {sortColumn !== timeframe+'_DAMAGED_MD' && <Icon fitted name='sort' />}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {dataPagination.map((data) => {
            return (
              <Table.Row>
                <Table.Cell>{data['EXT_CLASS_NBR']}</Table.Cell>
                <Table.Cell>{data['CLASS_DESC']}</Table.Cell>
                <Table.Cell>{getDollarString(data[timeframe+'_DAMAGED_MD'])}</Table.Cell>
                <Table.Cell>{getPercentString(data[timeframe+'_DAMAGED_MD'] / data[timeframe + '_SLS_AMT'], 2)}</Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </>
  )

})