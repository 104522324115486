import useQueryApi from '../../../../../../hooks/useQueryApi';
import { Breadcrumb } from '../../../../../../components/Common/Breadcrumb';
import { DataTable } from '../../../../../../components/Common/DataTable';
import { DataView } from '../../../../../../components/Common/DataView';
import { getLastTimeframe } from '../../../../../../utils/timeFrameUtils';
import {
  getOsaImagingHeaders,
  isFirstweekDay,
  osaImaginBreadcrumbItems,
  osaImagingDataColumns,
  osaImagingSummaryReduced,
} from '../../utils/osa/osa';
import { Header, Loader } from 'semantic-ui-react';
import { TimeframeDropdown } from '../../../../../../components/Common/TimeframeDropdown';
import { TimeframeDropdownMon } from '../../../../../../components/Common/TimeframeDropdownMon';

export const OsaImagingPage = ({ timeframe, settimeframe, fpToggle }) => {
  const defaultParams = { fpToggle, isCore: true };
  const lastTimeframe = getLastTimeframe(timeframe);
  const { isError, isLoading, data: osaImagingData = [] } = useQueryApi('osaImageCapturePlusOneByCore', defaultParams);

  if (isLoading) return <Loader active>Loading...</Loader>;
  if (isError) return <Header textAlign='center'>Something went wrong fetching data.</Header>;

  const osaImagingDataSummary = osaImagingSummaryReduced(osaImagingData, timeframe, lastTimeframe);
  const headers = getOsaImagingHeaders(lastTimeframe);

  const data = {
    sortable: true,
    sortableDefault: 0,
    headers,
    data: osaImagingDataColumns(osaImagingDataSummary, timeframe, lastTimeframe)
  };

  const dropdownProps = { timeframe, settimeframe };

  return (
    <section className='mb-4'>
      <Breadcrumb items={osaImaginBreadcrumbItems} />

      <DataView className='mt-2'>
        <DataView.Controls className='align-items-center d-flex flex-column'>
          Timeframe:
          {isFirstweekDay ? <TimeframeDropdownMon {...dropdownProps} /> : <TimeframeDropdown {...dropdownProps} />}
        </DataView.Controls>

        <DataView.Title position='center'>
          Overhead Imaging % - Core
          <Header.Subheader>Data as of {osaImagingData[0]?.TRNSFM_DT}</Header.Subheader>
        </DataView.Title>
      </DataView>

      <DataTable data={data} />
    </section>
  );
};
