import { useState } from 'react'

import { Grid, Header, Tab } from 'semantic-ui-react'
import { UnitMenu } from '../../Common/UnitMenu'
import { DataTable } from '../../Common/DataTable'

import { useQueryParams } from '../../../hooks/useQueryParams'
import { useOverviewContext } from '../../../hooks/useOverviewContext'

export const SalesDeparmentsTab = () => {
  const [unit, setUnit] = useState('NOM')
  const { salesDeptData, timeframe, level, divNbr } = useOverviewContext()
  const { rgnNbr } = useQueryParams()
  const isNOM = unit === 'NOM'
  const isPCT = unit === 'PCT'
  const queryParam = rgnNbr ? { rgnNbr } : { divNbr }
  const dataObj = {
    data: salesDeptData.map(row => ([
      {
        link: {
          to: `/${level}/sales/dept`,
          params: {
            ...queryParam,
            deptNbr: row['DEPT_NBR'],
          }
        },
        stringValue: row['MER_DEPT_NM'].replaceAll('/', '/\u200b'),
      },
      {
        dollarValue: row[`${timeframe}_SLS_AMT_ACT`],
        orderBy: row[`${timeframe}_SLS_AMT_ACT`],
      },
      {
        compareStyle: true,
        dollarValue: isNOM ? row[`${timeframe}_SLS_VP`] : undefined,
        pctValue: isPCT ? row[`${timeframe}_SLS_AMT_VP_PCT`] : undefined,
      },
      {
        compareStyle: true,
        dollarValue: isNOM ? row[`${timeframe}_SLS_AMT_VLY`] : undefined,
        pctValue: isPCT ? row[`${timeframe}_SLS_AMT_VLY_PCT`] : undefined,
      },
    ])),
    headers: [
      { name: 'Dept ' },
      { name: 'Actual ' },
      { name: `vP ${isPCT ? '%' : ''}` },
      { name: `vLY ${isPCT ? '%' : ''}` },
    ],
    sortable: true,
    sortableDefault: 0
  }

  return (
    <Tab.Pane attached={false}>
      <Grid>
        <Grid.Column width={8}>
          <Grid.Row>Units:</Grid.Row>

          <UnitMenu unit={unit} setUnit={setUnit} />
        </Grid.Column>
      </Grid>

      <Header textAlign='center' style={{ textTransform: 'capitalize' }}>{level} {rgnNbr || divNbr} Departments</Header>

      <DataTable data={dataObj} />
    </Tab.Pane>
  )
}
