import { Tab } from "semantic-ui-react";
import { useState } from "react";
import { MetricTopBottomDropdown } from "../../../../../../components/Common/MetricTopBottomDropdown";
import { FORMATMETRIC } from "../../../../../../constants/deptDrilldown";
import { getTopBtmRecords } from "../../../../../../utils/topBottomFormat";
import { DataView } from "../../../../../../components/Common/DataView";

export const CoreTopBtmSubClasses = ({ topBtmSubClasses }) => {
  const [metric, setMetric] = useState('SLS_AMT');
  const { bottomRecords, topRecords } = getTopBtmRecords({ rows: topBtmSubClasses, metric, link: '/core/sales/subclass', tab: 'topBtmSubClasses' })

  return (
    <Tab.Pane attached={false}>
      <MetricTopBottomDropdown metric={metric} setMetric={setMetric} />
      <DataView>
        <DataView.Title position="center">
          Top Subclasses By {FORMATMETRIC[metric]}
        </DataView.Title>
        <DataView.DataTable data={topRecords} />
      </DataView>
      <DataView className='mt-4'>
        <DataView.Title position="center">
          Bottom Subclasses By {FORMATMETRIC[metric]}
        </DataView.Title>
        <DataView.DataTable data={bottomRecords} />
      </DataView>
    </Tab.Pane>
  );
}