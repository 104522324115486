import { useMemo, useState } from 'react';
import { Checkbox, Dropdown } from 'semantic-ui-react';
import { uniqueValues } from '../../../../../utils/array';
import { formatSku } from '../../../../../utils/formatter';
import { DataView } from '../../../../../components/Common/DataView';
import { MenuItems } from '../../../../../components/Common/MenuItems';
import { SkuDetailsModal } from '../../../../../components/Sku/SkuDetailsModal';

export const CoreEventsSkuDetails = ({ eventsSkuDetails, isEventSales }) => {
  const [deptNbr, setDeptNbr] = useState('All');
  const [core, setCore] = useState('CORE');
  const [zeroSaleOnly, setZeroSaleOnly] = useState(false);
  const [modalData, setModalData] = useState(null);
  const dataFiltered = useMemo(
    () =>
      eventsSkuDetails.filter(row => {
        return (
          row &&
          (deptNbr === 'All' || row['DEPT_NBR'] === deptNbr) &&
          row['CORE_NC_FLG'] === core &&
          (row['ZERO_SALE_FLG'] === 1 || !zeroSaleOnly) // return all by default, only zero sales if box is checked
        );
      }),
    [deptNbr, core, eventsSkuDetails, zeroSaleOnly]
  );
  const departmentOptions = useMemo(
    () =>
      ['All', ...uniqueValues(eventsSkuDetails?.map(data => data['DEPT_NBR']))].map(dept => ({
        key: dept,
        text: dept,
        value: dept
      })),
    [eventsSkuDetails]
  );

  const dataObj = useMemo(
    () => ({
      data: dataFiltered.map(row => [
        {
          customStyle: { color: '#EE7125', cursor: 'pointer', textDecoration: 'underline' },
          stringValue: formatSku(row['SKU_NBR']),
          onClick: () => setModalData(row)
        },
        {
          stringValue: row['SKU_DESC']
        },
        {
          dollarValue: row['TOT_SLS_AMT'],
          orderBy: row['TOT_SLS_AMT']
        },
        {
          pctValue: isEventSales ? row['ST_NUM'] / row['ST_DEN'] : row['ST_NUM_UNITS'] / row['ST_DEN_UNITS']
        },
        {
          dollarValue: isEventSales ? row['RESID_AMT'] : undefined,
          stringValue: isEventSales ? undefined : (row['RESID_AMT_UNITS'] || 0).toLocaleString('en-US')
        }
      ]),
      headers: [
        { name: 'SKU #' },
        { name: 'SKU Description' },
        { name: 'Sales' },
        { name: isEventSales ? 'Sell-Thru % ' : 'Unit Sell-Thru % ' },
        { name: `Residual ${isEventSales ? '$' : '#'}` }
      ],
      pagination: true,
      sortable: true,
      sortableDefault: 2,
      sortDirection: 'ascending'
    }),
    [isEventSales, dataFiltered]
  );

  return (
    <>
      <DataView className='mt-4' testId='data-view-sku-detail'>
        <DataView.Title>SKU Detail</DataView.Title>

        <DataView.Controls>
          <Dropdown
            compact
            defaultValue={deptNbr}
            selection
            options={departmentOptions}
            onChange={(_, { value }) => setDeptNbr(value)}
          />

          <MenuItems
            className='mx-3'
            activeItem={core}
            dataTest='core-non-core-picker'
            items={['CORE', 'NON-CORE']}
            setItem={setCore}
            size='tiny'
          />

          <Checkbox
            data-testid='zero-sale-only-checkbox'
            label='Zero Sale Only'
            onChange={(_, { checked }) => setZeroSaleOnly(checked)}
            checked={zeroSaleOnly}
          />
        </DataView.Controls>

        <DataView.DataTable data={dataObj} />
      </DataView>

      <SkuDetailsModal isOpen={!!modalData} onClose={() => setModalData(null)} skuData={modalData} />
    </>
  );
};
