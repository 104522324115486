import { useParams } from 'react-router-dom';
import { Header, Loader } from 'semantic-ui-react';
import { useFetchLevelName } from '../../hooks/useFetchLevelName';
import { useOsaSideKickSkuBayQuery } from '../../hooks/useOsaSideKickSkuBayQuery';
import { useOsaSideKickExeScoresPlusOneQuery } from '../../hooks/useOsaSideKickExeScoresPlusOneQuery';
import {
  getCompletedTaskCount,
  getSkuBayCompletedTasks,
  getSkuBayTaskGoal,
  getTotalTaskCount,
  getSkuPackDown,
  increaseManagerData,
  increaseSidekickData,
  initSummaryData,
  isTaskOriginManager,
  isTaskOriginSidekick,
  isTaskTypeLogged
} from '../../utils/osa';
import { Breadcrumb } from '../Common/Breadcrumb';
import { SidekickExecSkuBay } from './SidekickExecSkuBay';
import { SidekickSkuBayTasksCompletion } from './SidekickSkuBayTasksCompletion';
import { getLastTimeframe, getParamByLevel } from '../../utils/timeFrameUtils';
import { METRIC_CONSTANTS } from '../../utils/constantsByLevel';

const getBreadcrumbItems = ({ level, urlParam, locNbr }) => [
  { to: `/${level}/overview?${urlParam}=${locNbr}`, text: 'Overview' },
  {
    to: `/osa?${urlParam}=${locNbr}`,
    text: 'OSA'
  },
  { text: 'SKU/Bay' }
];

export const SideKickSkuBay = ({ locNbr, fpToggle, timeframe, settimeframe }) => {
  const { level } = useParams();

  const { currentLevelName, isLoadingName: isLoadingCurrentLevelName } = useFetchLevelName({ level, locNbr, fpToggle });
  const {
    data: osaSidekickSkuBayData = [],
    isLoading: isLoadingOsaSidekickSkuBay,
    isError: isErrorOsaSidekickSkuBay
  } = useOsaSideKickSkuBayQuery({ level, locNbr, fpToggle });

  const {
    dataAsMap: dataMapSidekickExeScoresPlusOne,
    isDataEmpty: isDataEmptySidekickExeScoresPlusOne,
    isLoading: isLoadingsidekickExeScoresPlusOne,
    isError: isErrorSidekickExeScoresPlusOne
  } = useOsaSideKickExeScoresPlusOneQuery({ level, locNbr, fpToggle });

  if (isErrorOsaSidekickSkuBay || isErrorSidekickExeScoresPlusOne)
    return <Header textAlign='center'>Something went wrong</Header>;
  if (isLoadingOsaSidekickSkuBay || isLoadingsidekickExeScoresPlusOne || isLoadingCurrentLevelName)
    return <Loader active>Loading</Loader>;
  if (!osaSidekickSkuBayData.length || isDataEmptySidekickExeScoresPlusOne)
    return <Header textAlign='center'>Invalid Store Number</Header>;

  const ltf = getLastTimeframe(timeframe);
  const urlParam = getParamByLevel(level);
  const subLevel = METRIC_CONSTANTS[level].subLevel;
  const subLevelName = subLevel.levelName;
  const subLevelUrlParam = subLevel.urlParam;
  const subCurrentLevel = subLevel.currentLevel;

  const breadcrumbItems = getBreadcrumbItems({ level, urlParam, locNbr });

  const increaseLastTimeframeData = (data, row, ltf) => {
    const exeData = dataMapSidekickExeScoresPlusOne[row[subCurrentLevel]];

    data.totalCompletedTasks += getSkuBayCompletedTasks(exeData, timeframe);
    data.totalTasksGoal += getSkuBayTaskGoal(exeData, timeframe);
    data.ttlCompLTF += getCompletedTaskCount(row, ltf);
    data.ttlTasksLTF += getTotalTaskCount(row, ltf);
    if (isTaskTypeLogged(row)) data.logSkusLTF += getSkuPackDown(row, ltf);
  };

  const directedTaskSummary = osaSidekickSkuBayData.reduce((acc, row) => {
    if (!acc[row[subCurrentLevel]]) acc[row[subCurrentLevel]] = initSummaryData();

    const exeData = dataMapSidekickExeScoresPlusOne[row[subCurrentLevel]];
    console.log('exeData', exeData);
    acc[row[subCurrentLevel]].completedTasks = getSkuBayCompletedTasks(exeData, timeframe);
    acc[row[subCurrentLevel]].taskGoal = getSkuBayTaskGoal(exeData, timeframe);

    if (isTaskOriginSidekick(row)) increaseSidekickData(acc[row[subCurrentLevel]], row, timeframe);
    if (isTaskOriginManager(row)) increaseManagerData(acc[row[subCurrentLevel]], row, timeframe);
    if (isTaskTypeLogged(row)) acc[row[subCurrentLevel]].logSkus += getSkuPackDown(row, timeframe);
    if (ltf) increaseLastTimeframeData(acc[row[subCurrentLevel]], row, ltf);

    return acc;
  }, {});
  console.log('directedTaskSummary', directedTaskSummary);

  return (
    <>
      <Breadcrumb items={breadcrumbItems} />
      <SidekickExecSkuBay
        level={level}
        locNbr={locNbr}
        currentLevelName={currentLevelName}
        osaSidekickSkuBayData={osaSidekickSkuBayData}
        timeframe={timeframe}
        settimeframe={settimeframe}
        ltf={ltf}
        directedTaskSummary={directedTaskSummary}
      />
      <SidekickSkuBayTasksCompletion
        directedTaskSummary={directedTaskSummary}
        subLevelName={subLevelName}
        subLevelUrlParam={subLevelUrlParam}
        ltf={ltf}
      />
    </>
  );
};
