import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Loader, Header } from 'semantic-ui-react';
import useQueryApi from '../../../../../../hooks/useQueryApi';
import { useQueryParams } from '../../../../../../hooks/useQueryParams';
import { UnitMenu } from '../../../../../../components/Common/UnitMenu';
import { DataTable } from '../../../../../../components/Common/DataTable';
import { TimeframeDropdown } from '../../../../../../components/Common/TimeframeDropdown';

export const SmdReasonCodesPage = ({ fpToggle, timeframe, settimeframe }) => {
  const { mkdnRsnCd = 0 } = useQueryParams();
  const [unit, setUnit] = useState('NOM');
  const reasonCodeDrill = useQueryApi('queryReasonCodeDrillAtCore', { isCore: true, mkdnRsnCd, fpToggle });

  if (reasonCodeDrill.isLoading) {
    return <Loader active>Loading...</Loader>;
  }

  const dataObj = {
    sortable: true,
    sortableDefault: 0,
    headers: [{ name: 'Division #' }, { name: 'SMD' }, { name: 'vLY' }],
    data: reasonCodeDrill.data?.map(row => [
      {
        stringValue: row['DIV_NBR'],
        link: {
          to: '/division/smd/reason',
          params: {
            divNbr: row['DIV_NBR'],
            mkdnRsnCd: row['MKDN_RSN_CD']
          }
        }
      },
      unit === 'NOM'
        ? {
            decimals: 2,
            dollarValue: row[timeframe + '_SMD']
          }
        : {
            decimals: 1,
            pctValue: row[timeframe + '_SMD_PCT']
          },
      unit === 'NOM'
        ? {
            compareStyle: true,
            compareStyleInvert: true,
            dollarValue: row[timeframe + '_SMD_VLY_DOL']
          }
        : {
            decimals: 0,
            postfix: ' bps',
            compareStyle: true,
            compareStyleInvert: true,
            numValue: row[timeframe + '_SMD_VLY_BPS']
          }
    ])
  };

  const dataAsOf = reasonCodeDrill.data[0]?.TRNSFM_DT;

  return (
    <>
      <Link style={{ color: '#EE7125' }} to='/core/smd' data-test='b2o-link'>
        Back to SMD Detail
      </Link>

      <Header textAlign='left'>
        SMD - Reason Codes - Core
        <Header.Subheader>Data as of {dataAsOf}</Header.Subheader>
      </Header>

      <Grid>
        <Grid.Row>
          <Grid.Column>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <div style={{ textAlign: 'center' }}>
                <p style={{ marginBottom: '0' }}>Units:</p>
                <UnitMenu unit={unit} setUnit={setUnit} />
              </div>
              <div style={{ textAlign: 'center' }}>
                <p style={{ marginBottom: '0', marginTop: '1rem' }}>Timeframe:</p>
                <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Header textAlign='center'>
        {reasonCodeDrill.data[0]
          ? reasonCodeDrill.data[0]?.MKDN_RSN_CD + '-' + reasonCodeDrill.data[0]?.MKDN_RSN_DESC
          : `Reason Code ${mkdnRsnCd} - No markdowns found.`}
      </Header>
      {reasonCodeDrill.data[0] && <DataTable data={dataObj} />}
    </>
  );
};
