import { Header, Segment } from 'semantic-ui-react';
import { DataView } from '../../../../../../components/Common/DataView';

export const DaysSafe = ({ daysSafeData }) => {
  const daysSafeDataObj = {
    headers: [{ name: 'Org # ' }, { name: 'Day Safe ' }],
    sortable: true,
    sortableDefault: 0,
    data: daysSafeData.map((row, index) => [
      {
        stringValue: row.DIV_NM,
        orderBy: row.DIV_NM
      },
      {
        stringValue: row['DAYS_SAFE']
      }
    ])
  };

  const minDaysSafe = Math.min(...daysSafeData.map(row => row['DAYS_SAFE']));

  return (
    <>
      <Header textAlign='center' style={{ textTransform: 'capitalize' }}>
        Safety - Core
        <Header.Subheader>Data as of {daysSafeData[0]?.TRNSFM_DT}</Header.Subheader>
      </Header>
      <Header textAlign='center'>Days Safe: {minDaysSafe}</Header>
      <Segment>
        <DataView>
          <DataView.Title position='center'>Days Safe</DataView.Title>
          <DataView.DataTable data={daysSafeDataObj} />
        </DataView>
      </Segment>
    </>
  );
};
