import { DataView } from '../../Common/DataView';
import { getSmdCompareStyle, getThousandDollarString } from '../../Common/TableCellUtils';

export const SmdDetailDistrictListTab = ({ data, timeframe }) => {
  const dataObj = {
    data: data.map(row => [
      {
        link:
          row.METRIC_LEVEL === 'RGN'
            ? undefined
            : {
                to: '/district/smd',
                params: {
                  dstNbr: String(row.DST_NBR).padStart(4, '0')
                }
              },
        orderBy: row.METRIC_LEVEL === 'RGN' ? -1 : row.DST_NBR,
        stringValue: row.METRIC_LEVEL === 'RGN' ? `RGN ${row.RGN_NBR}` : `DST ${row.DST_NBR}`
      },
      {
        dollarValue: getThousandDollarString(row[timeframe + '_SMD_AMT'], 1),
        orderBy: row[timeframe + '_SMD_AMT']
      },
      {
        decimals: 2,
        pctValue: row[timeframe + '_SMD_ACT']
      },
      {
        customStyle: getSmdCompareStyle(row[timeframe + '_SMD_BPS_VP']),
        postfix: ' bps',
        orderBy: row[timeframe + '_SMD_BPS_VP'],
        stringValue: row[timeframe + '_SMD_BPS_VP']
          ? row[timeframe + '_SMD_BPS_VP'].toLocaleString(undefined, { maximumFractionDigits: 0 })
          : '-'
      },
      {
        customStyle: getSmdCompareStyle(row[timeframe + '_SMD_OVER_UNDER']),
        orderBy: row[timeframe + '_SMD_OVER_UNDER'],
        stringValue: getThousandDollarString(row[timeframe + '_SMD_OVER_UNDER'], 1)
      }
    ]),
    headers: [
      { name: 'Org #' },
      { name: 'SMD $' },
      { name: 'SMD %' },
      { name: 'SMD bps vP ' },
      { name: 'SMD $ Over Under ' }
    ],
    sortable: true,
    sortableDefault: 0
  };

  return (
    <DataView className='mt-3' testId='data-view-smd-detail-district-comps'>
      <DataView.Title>SMD - District Comps</DataView.Title>

      <DataView.DataTable data={dataObj} />
    </DataView>
  );
};
