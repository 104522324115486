import { useState } from 'react';
import { useCoreOverviewContext } from '../../../hooks/useCoreOverviewContext';
import { Dropdown, Header } from 'semantic-ui-react';
import { DataView } from '../../../../../components/Common/DataView';
import { UnitMenu } from '../../../../../components/Common/UnitMenu';
import { TimeframeDropdown } from '../../../../../components/Common/TimeframeDropdown';
import { formatLevelNumber } from '../../../../../utils/level';
import { getCompareStyle, getDollarString, getPercentString } from '../../../../../components/Common/TableCellUtils';
import { formatNumberWithCommas } from '../../../../../utils/formatter';
import { VOC_LTPA_GOAL, VOC_LTSA_GOAL } from '../../../../../constants/goals';

const OPTIONS = [
  { key: 'sales', text: 'Sales', value: 'sales' },
  { key: 'units', text: 'Units', value: 'units' },
  { key: 'transactions', text: 'Transactions', value: 'transactions' },
  { key: 'average_ticket', text: 'Average Ticket', value: 'average_ticket' },
  { key: 'units_per_basket', text: 'Units per Basket', value: 'units_per_basket' },
  { key: 'days_safe', text: 'Days Safe', value: 'days_safe' },
  { key: 'smd', text: 'SMD', value: 'smd' },
  { key: 'labor', text: 'Labor', value: 'labor' },
  { key: 'get', text: 'GET', value: 'get' },
  { key: 'ltsa', text: 'LTSA', value: 'ltsa' },
  { key: 'ltpa', text: 'LTPA', value: 'ltpa' },
  { key: 'leads', text: 'Leads', value: 'leads' },
  { key: 'measures', text: 'Measures', value: 'measures' },
  { key: 'HDPP - MA UA', text: 'HDPP - MA UA', value: 'HDPP - MA UA' },
  { key: 'HDPP - GM UA', text: 'HDPP - GM UA', value: 'HDPP - GM UA' }
];

const DAYS_SAFE_HEADERS = [{ name: 'Division' }, { name: 'Days Safe ' }];
const DAYS_SAFE_METRIC = 'days_safe';
const UNITS_PER_BASKET = 'units_per_basket'

const getHeadersList = (metrict, unit) => {
  if (metrict === DAYS_SAFE_METRIC) return DAYS_SAFE_HEADERS;

  return [
    { name: 'Division' },
    { name: `Actual ` },
    { name: `vP/vF  ${unit === 'PCT' ? '% ' : ''}` },
    { name: `vLY  ${unit === 'PCT' ? '% ' : ''}` }
  ];
};

export const DivisionList = () => {
  const [unit, setUnit] = useState('NOM');
  const [metric, setMetric] = useState('sales');
  const { salesSafetyByDivisionData = [], salesMetricsData = [], settimeframe, timeframe } = useCoreOverviewContext();
  const isUnitNom = unit === 'NOM';

  const daySafeData = salesSafetyByDivisionData.map(row => [
    {
      link: {
        to: `/division/overview`,
        params: {
          divNbr: formatLevelNumber(row.DIV_NBR)
        }
      },
      orderBy: row.DIV_NBR,
      stringValue: formatLevelNumber(row.DIV_NBR)
    },
    {
      numValue: row.DAYS_SAFE,
      orderBy: row.DAYS_SAFE
    }
  ]);

  const metricsData = salesMetricsData.map(row => {
    if (metric === DAYS_SAFE_METRIC) return daySafeData //duplicate to prevent crash
    if (metric === UNITS_PER_BASKET) {
      const unitsPerBasketByNOM = (row['TY_' + timeframe + '_COMP_UNT_SLS'] / row['TY_' + timeframe + '_COMP_TXN_CNT'] -
        row['LY_' + timeframe + '_COMP_UNT_SLS'] / row['LY_' + timeframe + '_COMP_TXN_CNT']);
      const unitsPerBasketByPCT = (row['TY_' + timeframe + '_COMP_UNT_SLS'] / row['TY_' + timeframe + '_COMP_TXN_CNT'] -
        row['LY_' + timeframe + '_COMP_UNT_SLS'] / row['LY_' + timeframe + '_COMP_TXN_CNT']) /
        (row['LY_' + timeframe + '_COMP_UNT_SLS'] / row['LY_' + timeframe + '_COMP_TXN_CNT']);
      const totalUnitsPerBasket = (row['TY_' + timeframe + '_UNT_SLS']) / (row['TY_' + timeframe + '_TXN_CNT'])
      return [
        {
          link: {
            to: `/division/overview`,
            params: {
              divNbr: formatLevelNumber(row.DIV_NBR)
            }
          },
          orderBy: row.DIV_NBR,
          stringValue: formatLevelNumber(row.DIV_NBR)
        },
        {
          stringValue: formatNumberWithCommas(totalUnitsPerBasket, 2),
          orderBy: totalUnitsPerBasket
        },
        {
          stringValue: '-'
        },
        {
          stringValue: unit !== 'PCT'
            ? formatNumberWithCommas(unitsPerBasketByNOM, 2)
            : getPercentString(unitsPerBasketByPCT, 2),
          customStyle: getCompareStyle(unitsPerBasketByNOM),
          orderBy: unitsPerBasketByNOM || unitsPerBasketByPCT
        }
      ]
    }
    if (metric === 'HDPP - MA UA') {
      return [
        {
          link: {
            to: `/division/overview`,
            params: {
              divNbr: formatLevelNumber(row.DIV_NBR)
            }
          },
          orderBy: row.DIV_NBR,
          stringValue: formatLevelNumber(row.DIV_NBR)
        },
        {
          stringValue: getPercentString(row[timeframe + '_MA_HDPP'])
        },
        {
          stringValue: getPercentString(row[timeframe + '_MA_HDPP_VG']),
          customStyle: getCompareStyle(row[timeframe + '_MA_HDPP_VG'])
        },
        {
          stringValue: getPercentString(row[timeframe + '_MA_HDPP_VLY']),
          customStyle: getCompareStyle(row[timeframe + '_MA_HDPP_VLY'])
        }]
    }
    if (metric === 'HDPP - GM UA') {
      return [
        {
          link: {
            to: `/division/overview`,
            params: {
              divNbr: formatLevelNumber(row.DIV_NBR)
            }
          },
          orderBy: row.DIV_NBR,
          stringValue: formatLevelNumber(row.DIV_NBR)
        },
        {
          stringValue: getPercentString(row[timeframe + '_MA_HDPP'])
        },
        {
          stringValue: getPercentString(row[timeframe + '_GM_HDPP_VG']),
          customStyle: getCompareStyle(row[timeframe + '_GM_HDPP_VG'])
        },
        {
          stringValue: getPercentString(row[timeframe + '_GM_HDPP_VLY']),
          customStyle: getCompareStyle(row[timeframe + '_GM_HDPP_VLY'])
        }]
    }
    if (metric === 'ltpa') {
      return [
        {
          link: {
            to: `/division/overview`,
            params: {
              divNbr: formatLevelNumber(row.DIV_NBR)
            }
          },
          orderBy: row.DIV_NBR,
          stringValue: formatLevelNumber(row.DIV_NBR)
        },
        {
          stringValue: getPercentString(row[timeframe + '_ONLINE_SAT_ACT']),
          customStyle: {
            color:
              (+row[timeframe + '_ONLINE_SAT_ACT']) > VOC_LTPA_GOAL
                ? 'green'
                : (+row[timeframe + '_ONLINE_SAT_ACT']) < VOC_LTPA_GOAL
                  ? 'red'
                  : 'black'
          }
        },
        {
          stringValue: '-'
        },
        {
          stringValue: unit !== 'NOM' ? getPercentString(row[timeframe + '_ONLINE_SAT_VLY'], 2) : '-',
          customStyle: getCompareStyle(row[timeframe + '_ONLINE_SAT_VLY'])
        }]
    }
    if (metric === 'transactions') {
      return [
        {
          link: {
            to: `/division/overview`,
            params: {
              divNbr: formatLevelNumber(row.DIV_NBR)
            }
          },
          orderBy: row.DIV_NBR,
          stringValue: formatLevelNumber(row.DIV_NBR)
        }, {
          stringValue: formatNumberWithCommas(+row[timeframe + objects[metric][0]['dollar']])
        },
        {
          stringValue: '-',
        },
        {
          stringValue: unit !== 'PCT'
            ? formatNumberWithCommas(+row[timeframe + objects[metric][2]['dollar']])
            : getPercentString(row[timeframe + objects[metric][2]['pct']]),
          customStyle: getCompareStyle(row[timeframe + objects[metric][2][isUnitNom ? 'dollar' : 'pct']])
        }]
    }
    if (metric === 'ltsa') {
      return [
        {
          link: {
            to: `/division/overview`,
            params: {
              divNbr: formatLevelNumber(row.DIV_NBR)
            }
          },
          orderBy: row.DIV_NBR,
          stringValue: formatLevelNumber(row.DIV_NBR)
        }, {
          stringValue: getPercentString(row[timeframe + objects[metric][0]['dollar']], 2),
          customStyle: {
            color: (+row[timeframe + objects[metric][0]['dollar']]) >= VOC_LTSA_GOAL ? 'green' : 'red'
          }
        },
        {
          stringValue: '-'
        },
        {
          stringValue: unit === 'NOM' ? getPercentString(row[timeframe + objects[metric][2]['dollar']], 2) : '-',
          customStyle: {
            color: (+row[timeframe + objects[metric][0]['dollar']]) >= VOC_LTSA_GOAL ? 'green' : 'red'
          }
        }]
    }
    if (metric === 'get') {
      return [
        {
          link: {
            to: `/division/overview`,
            params: {
              divNbr: formatLevelNumber(row.DIV_NBR)
            }
          },
          orderBy: row.DIV_NBR,
          stringValue: formatLevelNumber(row.DIV_NBR)
        },
        {
          stringValue: getPercentString(row[timeframe + objects[metric][0]['dollar']], 2),
          customStyle: {
            color: (+row[timeframe + objects[metric][0]['dollar']]) >= VOC_LTSA_GOAL ? 'green' : 'red'
          }
        },
        {
          stringValue: '-'
        },
        {
          stringValue: unit === 'NOM' ? getPercentString(row[timeframe + objects[metric][2]['dollar']], 2) : '-',
          customStyle: {
            color: (+row[timeframe + objects[metric][2]['dollar']]) >= VOC_LTSA_GOAL ? 'green' : 'red'
          }
        }]
    }
    if (metric === 'smd') {
      return [
        {
          link: {
            to: `/division/overview`,
            params: {
              divNbr: formatLevelNumber(row.DIV_NBR)
            }
          },
          orderBy: row.DIV_NBR,
          stringValue: formatLevelNumber(row.DIV_NBR)
        },
        {
          stringValue: getPercentString(row[timeframe + '_SMD_ACT'], 2)
        },
        {
          stringValue: unit !== 'PCT'
            ? (+row[timeframe + '_SMD_VP']).toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' bps'
            : '-',
          customStyle: getCompareStyle(-1 * row[timeframe + '_SMD_VP'])
        },
        {
          stringValue: unit !== 'PCT'
            ? (+row[timeframe + '_SMD_VLY']).toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' bps'
            : '-',
          customStyle: getCompareStyle(-1 * row[timeframe + '_SMD_VLY'])
        }]
    }
    if (metric === 'average_ticket') {
      return [
        {
          link: {
            to: `/division/overview`,
            params: {
              divNbr: formatLevelNumber(row.DIV_NBR)
            }
          },
          orderBy: row.DIV_NBR,
          stringValue: formatLevelNumber(row.DIV_NBR)
        }, {
          stringValue: getDollarString(row[timeframe + objects[metric][0]['dollar']], 2),
        },
        {
          stringValue: '-',
        },
        {
          stringValue: unit !== 'PCT'
            ? getDollarString(row[timeframe + objects[metric][2]['dollar']], 2)
            : getPercentString(row[timeframe + objects[metric][2]['pct']], 2),
          customStyle: getCompareStyle(row[timeframe + objects[metric][2][isUnitNom ? 'dollar' : 'pct']])
        }]
    }
    if (metric === 'labor') {
      return [
        {
          link: {
            to: `/division/overview`,
            params: {
              divNbr: formatLevelNumber(row.DIV_NBR)
            }
          },
          orderBy: row.DIV_NBR,
          stringValue: formatLevelNumber(row.DIV_NBR)
        }, {
          stringValue: formatNumberWithCommas(+row[timeframe + '_LBR_ACT']) + ' hrs'
        },
        {
          stringValue: unit === 'NOM'
            ? formatNumberWithCommas(+row[timeframe + objects[metric][1]['dollar']]) + ' hrs'
            : getPercentString(row[timeframe + objects[metric][1]['pct']]),
          customStyle: unit === 'PCT' ? getCompareStyle(row[timeframe + objects[metric][1]['pct']]) : undefined
        },
        {
          stringValue: '-'
        }]
    }

    const actualValue = row[timeframe + objects[metric][0]?.dollar];
    const varianceValue = row[timeframe + objects[metric][1][isUnitNom ? 'dollar' : 'pct']] || '-';
    const versusLastYearValue = row[timeframe + objects[metric][2][isUnitNom ? 'dollar' : 'pct']] || '-';
    return [
      {
        link: {
          to: `/division/overview`,
          params: {
            divNbr: formatLevelNumber(row.DIV_NBR)
          }
        },
        orderBy: row.DIV_NBR,
        stringValue: formatLevelNumber(row.DIV_NBR)
      },
      {
        dollarValue: actualValue,
        orderBy: actualValue
      },
      {
        customStyle: getCompareStyle(varianceValue),
        dollarValue: isUnitNom ? varianceValue : undefined,
        pctValue: !isUnitNom ? varianceValue : undefined,
        orderBy: varianceValue
      },
      {
        customStyle: getCompareStyle(versusLastYearValue),
        dollarValue: isUnitNom ? versusLastYearValue : undefined,
        pctValue: !isUnitNom ? versusLastYearValue : undefined,
        orderBy: varianceValue
      }
    ];
  });
  const dataAsOf = '2024-09-12'; // TODO: Replace with actual data

  const dataObj = {
    headers: getHeadersList(metric, unit),
    data: metric === DAYS_SAFE_METRIC ? daySafeData : metricsData,
    sortable: true,
    sortableDefault: 0
  };

  return (
    <DataView>
      <DataView.Controls className='d-flex gap-3'>
        <UnitMenu unit={unit} setUnit={setUnit} />

        <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />

        <Dropdown
          compact
          defaultValue={metric}
          selection
          size='tiny'
          options={OPTIONS}
          onChange={(_, { value }) => setMetric(value)}
        />
      </DataView.Controls>

      <DataView.Title>
        Overview - Core, Sales By Division
        <Header.Subheader>Data as of {dataAsOf}</Header.Subheader>
      </DataView.Title>

      <DataView.DataTable data={dataObj} />
    </DataView>
  );
};

const objects = {
  sales: [
    { dollar: '_SLS_AMT_ACT' },
    { dollar: '_SLS_VP', pct: '_SLS_AMT_VP_PCT' },
    { dollar: '_SLS_AMT_VLY', pct: '_SLS_AMT_VLY_PCT' }
  ],
  units: [
    { dollar: '_UNTS_ACT' },
    { dollar: null, pct: null },
    { dollar: '_UNTS_VLY', pct: '_UNTS_VLY_PCT' }
  ],
  transactions: [
    { dollar: '_TRNS_ACT' },
    { dollar: null, pct: null },
    { dollar: '_TRNS_VLY', pct: '_TRNS_VLY_PCT' }
  ],
  average_ticket: [
    { dollar: '_AVG_TCKT_ACT' },
    { dollar: null, pct: null },
    { dollar: '_AVG_TCKT_VLY', pct: '_AVG_TCKT_VLY_PCT' }
  ],
  smd: [
    { dollar: '_SMD_ACT' },
    { dollar: '_SMD_VP', pct: null },
    { dollar: '_SMD_VLY', pct: null }
  ],
  labor: [
    { dollar: '_LBR_ACT' },
    { dollar: '_LBR_VF', pct: '_LBR_VF_PCT' },
    { dollar: null, pct: null }
  ],
  get: [
    { dollar: '_GET_ACT' },
    { dollar: null, pct: null },
    { dollar: '_GET_VLY', pct: null }
  ],
  ltsa: [
    { dollar: '_LTSA_ACT' },
    { dollar: null, pct: null },
    { dollar: '_LTSA_VLY', pct: null }
  ],
  ltpa: [
    { dollar: '_ONLINE_SAT_ACT' },
    { dollar: null, pct: null },
    { dollar: '_ONLINE_SAT_VLY', pct: null }
  ],
  leads: [
    { dollar: '_LEADS_ACT' },
    { dollar: null, pct: null },
    { dollar: '_LEADS_VLY', pct: '_LEADS_VLY_PCT' }
  ],
  measures: [
    { dollar: '_MEAS_ACT' },
    { dollar: null, pct: null },
    { dollar: '_MEAS_VLY', pct: '_MEAS_VLY_PCT' }
  ],
  units_per_basket: [
    { dollar: '_MEAS_ACT' },
    { dollar: null, pct: null },
    { dollar: '_MEAS_VLY', pct: '_MEAS_VLY_PCT' }
  ],
  'HDPP - MA UA': [
    { dollar: '_MA_HDPP' },
    { dollar: '_MA_HDPP_VG', pct: '_MA_HDPP_VG' },
    { dollar: '_MA_HDPP_VLY', pct: '_MA_HDPP_VLY' },
  ],
  'HDPP - GM UA': [
    { dollar: '_MA_HDPP' },
    { dollar: '_GM_HDPP_VG', pct: '_GM_HDPP_VG' },
    { dollar: '_GM_HDPP_VLY', pct: '_GM_HDPP_VLY' },
  ]
};
