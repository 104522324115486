export const MENU_ITEMS = [
  'overview',
  'intraday',
  'daily',
  'orderfulfillment',
  'credit',
  'specialtyprorental',
  'shrink',
  'events',
  'headlinesinsights'
];
