import useQueryApi from '../../../hooks/useQueryApi';

export const useOverviewQueries = (fpToggle) => {
  const defaultParams = { fpToggle, isCore: true };

  const {
    data: salesDeptData = [],
    isError: isErrorSalesDept,
    isLoading: isLoadingSalesDept
  } = useQueryApi(`querySlsDeptByCore`, defaultParams);

  const {
    data: salesClassesData = [],
    isError: isErrorSalesClasses,
    isLoading: isLoadingSalesClasses
  } = useQueryApi('querySlsClsByCore', defaultParams);
  const {
    data: salesSkusData = [],
    isError: isErrorSalesSkus,
    isLoading: isLoadingSalesSkus
  } = useQueryApi('querySlsSkuByCore', defaultParams);

  const {
    data: salesSafetyByDivisionData = [],
    isError: isErrorSalesSafetyByDivision,
    isLoading: isLoadingSalesSafetyByDivision
  } = useQueryApi('querySafetyIncOshaLtGlbiByCore', defaultParams);

  const {
    data: salesMetricsData = [],
    isError: isErrorSalesMetricsData,
    isLoading: isLoadingSalesMetricsData
  } = useQueryApi('querySwmRgnMetricsByCore', defaultParams);
  const {
    data: salesSubClassesData = [],
    isError: isErrorSalesSubClasses,
    isLoading: isLoadingSalesSubClasses
  } = useQueryApi('querySlsSclsByCore', defaultParams);

  const {
    data: salesCoreData = [],
    isError: isErrorCoreDept,
    isLoading: isLoadingCoreDept
  } = useQueryApi(`queryOverviewCompByCore`, defaultParams);

  const {
    data: swmDetailsData = [],
    isError: isErrorSwmDetails,
    isLoading: isLoadingSwmDetails
  } = useQueryApi('querySwmCore', defaultParams);

  const {
    data: SafetyIncOshaLtGlbiData = [],
    isError: isErrorSafetyIncOshaLtGlbi,
    isLoading: isLoadingSafetyIncOshaLtGlbi
  } = useQueryApi('querySafetyIncOshaLtGlbiByCore', defaultParams);

  const isError = isErrorSalesDept || isErrorSalesClasses || isErrorSalesSkus || isErrorSalesSafetyByDivision || isErrorSalesMetricsData || isErrorSalesSubClasses || isErrorCoreDept || isErrorSwmDetails || isErrorSafetyIncOshaLtGlbi;
  const isLoading = isLoadingSalesDept || isLoadingSalesClasses || isLoadingSalesSkus || isLoadingSalesSafetyByDivision || isLoadingSalesMetricsData || isLoadingSalesSubClasses || isLoadingCoreDept || isLoadingSwmDetails || isLoadingSafetyIncOshaLtGlbi;

  return {
    isError,
    isLoading,
    salesClassesData,
    salesCoreData,
    salesDeptData,
    salesMetricsData,
    salesSafetyByDivisionData,
    salesSkusData,
    salesSubClassesData,
    swmDetailsData,
    SafetyIncOshaLtGlbiData
  };
};
