import { useMemo, useState } from 'react';
import { Header, Menu } from 'semantic-ui-react';
import { DataTable } from '../../../../../components/Common/DataTable';
import { UnitsSellMenu } from '../../../../../components/Events/ UnitsSellMenu';

export const CoreActiveEvents = ({ events }) => {
  const [salesOrUnits, setSalesOrUnits] = useState('Units #');
  const activeEvents = useMemo(() => events.filter(event => event && event['EVENT_STATUS'] === 'ACTIVE'), [events]);

  const activeEventsObj = useMemo(
    () => ({
      headers: [
        { name: 'Events ' },
        { name: 'Begin Date ' },
        { name: 'End Date ' },
        { name: 'Total Sales ' },
        { name: salesOrUnits === 'Sales $ ' ? 'Sell Thru % ' : 'Unit Sell Thru % ' }
      ],
      data: activeEvents.map(event => [
        {
          stringValue: event['EVNT_NM'],
          link: { to: '/core/events/detail', params: { evntId: event['EVNT_ID'] } }
        },
        { stringValue: event['EVNT_EFF_BGN_DT'] },
        { stringValue: event['EVNT_EFF_END_DT'] },
        { dollarValue: event['TOT_EVNT_SLS_AMT'] },
        {
          pctValue:
            salesOrUnits === 'Sales $'
              ? event['TOT_ST_NUM'] / event['TOT_ST_DEN']
              : event['TOT_ST_NUM_UNITS'] / event['TOT_ST_DEN_UNITS']
        }
      ]),
      sortable: true,
      pagination: true,
      sortableDefault: 1,
      sortDirection: 'ascending'
    }),
    [activeEvents, salesOrUnits]
  );

  return (
    <>
      <Header textAlign='center'>Active Events</Header>
      <Menu compact size='small' style={{ marginBottom: '1rem' }}>
        <UnitsSellMenu salesOrUnits={salesOrUnits} setSalesOrUnits={setSalesOrUnits} />
      </Menu>
      <DataTable data={activeEventsObj} />
    </>
  );
};
