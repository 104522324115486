import React from 'react'
import { Header, Grid, Loader } from 'semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import { DataTable } from '../Common/DataTable';
import { getData } from '../../service/DataService'
import { TimeframeDropdownShrink } from '../Common/TimeframeDropdownShrink'

export const ShrinkRtvDeletedTag = withRouter(({ timeframe, settimeframe, fpToggle, location }) => {

  const search = new URLSearchParams(location.search)
  const strNbr = search.has('strNbr') ? search.get('strNbr') : 0

  const shrinkRtvDeletedTagQuery = useQuery(['queryShrinkRtvDeletedTag', { strNbr, fpToggle }], getData)

  if (shrinkRtvDeletedTagQuery.isLoading) {
    return (<Loader active>Loading...</Loader>)
  }

  if (!shrinkRtvDeletedTagQuery.data) {
    return (<Header textAlign='center'>Invalid Store Number</Header>)
  }

  const data = shrinkRtvDeletedTagQuery.data

  let dataFilter = data.filter((row) => {
    return row && row[timeframe + '_FLG'] === 1
  })

  const dataObj = {
    headers: [
      { name: 'RTV Label #' },
      { name: 'SKU #' },
      { name: 'SKU Description' },
      { name: 'RTV Deleted Amt $' },
    ],
    data: dataFilter.map(row => {
      const rowData = [
        {
          stringValue: row['RTV_LBL_NBR'],
        },
        {
          stringValue: row['SKU_NBR'],
        },
        {
          stringValue: row['SKU_DESC'],
        },
        {
          dollarValue: row['RTV_DEL_AMT'],
        }
      ]
      return rowData
    }),
    sortable: true,
    pagination: false,
    sortableDefault: 3,
    sortDirection: 'ascending'
  }

  return (
    <>
      <Grid>
        <Grid.Column width={8} textAlign='left'>
          <Grid.Row><Link style={{ color: '#EE7125' }} to={`/store/shrink?strNbr=${strNbr}`} data-test='b2o-link'>Back to Shrink Overview</Link></Grid.Row>
        </Grid.Column>
        <Grid.Column width={8} textAlign='left'>
          <TimeframeDropdownShrink timeframe={timeframe} settimeframe={settimeframe} />
        </Grid.Column>
      </Grid>

      <Grid>
        <Grid.Column width={16} textAlign='center' style={{ marginBottom: '30px' }}>
          <Header textAlign='center'>RTV Deleted Tags - Store {strNbr} </Header>
          <DataTable data={dataObj} />
        </Grid.Column>
      </Grid>
    </>
  )

})