import React, { useState } from 'react'
import {
  Header,
  Grid,
  Modal,
  Segment,
  Button,
  Form,
  TextArea,
  Tab
} from 'semantic-ui-react'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'

export const NewChangeLog = () => {

  const [open, setOpen] = useState(false)
  const [messageField, setMessageField] = useState('')
  const [alertSendLoading, setAlertSendLoading] = useState(false)

  const panes = [
    { menuItem: 'Edit', render: () => 
      <Tab.Pane>
        <Form>
          <a href='https://guides.github.com/features/mastering-markdown/' target="_blank" rel="noreferrer">
            Markdown Reference
          </a>
          <TextArea
            rows={6}
            style={{ fontSize: '16px' }}
            placeholder="Enter change log..."
            defaultValue={messageField === '' ? null : messageField}
            onChange={(_, { value }) => {
              // if (value.length <= 2000) {
                setMessageField(value)
              // }

            }}
          />
        </Form>
      </Tab.Pane> },
    { menuItem: 'Preview', render: () => <Tab.Pane><ReactMarkdown plugins={[gfm]} children={messageField} /></Tab.Pane> },
  ]

  return (
      <Modal
        closeIcon
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={<Button size='mini' style={{color: 'white', backgroundColor: 'gray'}}>Change Log Admin</Button>}
      >
        <Segment color='orange'>
          <Header textAlign='center'>Create New Change Log</Header>
          <Grid>
            <Grid.Column width={16} textAlign='left'>
              <Grid.Row style = {{color: messageField.length > 2000 ? 'red' : 'black'}}>Change Log: ({messageField.length}/2000 characters used.{messageField.length > 2000 ? ' Message too long!' : ''})</Grid.Row>
              <Grid.Row>&nbsp;</Grid.Row>
              <Grid.Row>
                <Tab panes={panes}/>
              </Grid.Row>
            </Grid.Column>
            <Grid.Row>
              <Grid.Column width={8} textAlign='left'>
                <Button
                  disabled={messageField.length > 2000 || alertSendLoading}
                  size='mini'
                  style={{ color: 'white', backgroundColor: '#EE7125' }}
                  loading={alertSendLoading}
                  onClick={() => {
                    setAlertSendLoading(true)
                    fetch(`${process.env.REACT_APP_BACKEND}/api/support/insertChangeLog`, {
                      credentials: 'include',
                      body: JSON.stringify({
                        MESSAGE: messageField.slice(0,2000),
                      }),
                      method: 'POST'
                    })
                      .then((response) => {
                        if (response.ok) {
                          setOpen(false)
                        }
                      })
                  }}
                >Submit</Button>
              </Grid.Column>
              <Grid.Column width={8} textAlign='right'>
                <Button
                  size='mini'
                  style={{ color: 'white', backgroundColor: '#EE7125' }}
                  onClick={() => {
                    setAlertSendLoading(true)
                    setMessageField('')
                    setOpen(false)
                  }}
                >Cancel</Button>
              </Grid.Column>
            </Grid.Row>

          </Grid>
        </Segment>
      </Modal>
  )

}