import { Dropdown, Grid } from "semantic-ui-react"

export const MetricTopBottomDropdown = ({ metric, setMetric }) => {
    return (
        <Grid>
            <Grid.Row>
                <Grid.Column textAlign='justified' width={6}>
                    <Grid.Row>
                        Metric:
                    </Grid.Row>
                    <Grid.Row>
                        <Dropdown
                            compact
                            defaultValue={metric}
                            selection
                            scrolling
                            size='tiny'
                            options={[{ key: 'SLS_AMT', text: 'Sales Amt', value: 'SLS_AMT' },
                            { key: 'SLS_COMP_NOM', text: 'Sales Comp $', value: 'SLS_COMP_NOM' },
                            { key: 'SLS_COMP_PCT', text: 'Sales Comp %', value: 'SLS_COMP_PCT' },
                            { key: 'UNT_SLS', text: 'Unit Sales', value: 'UNT_SLS' },
                            { key: 'UNT_COMP_NOM', text: 'Units Comp #', value: 'UNT_COMP_NOM' },
                            { key: 'UNT_COMP_PCT', text: 'Units Comp %', value: 'UNT_COMP_PCT' },
                            ]}
                            onChange={(_, { value }) => {
                                setMetric(value)
                            }} />
                    </Grid.Row>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}