import { Header, Loader } from 'semantic-ui-react';
import useQueryApi from '../../../../../hooks/useQueryApi';
import { CoreActiveEvents } from '../components/CoreActiveEvents';
import { CoreCompletedEvents } from '../components/CoreCompletedEvents';

export const EventsPage = ({ fpToggle }) => {
  const events = useQueryApi('queryEventsAtCore', { isCore: true, fpToggle });

  if (events.isLoading) return <Loader active>Loading...</Loader>;
  if (events.data.length === 0) {
    return <Header textAlign='center'>Not Events data found.</Header>;
  }

  return (
    <>
      <Header textAlign='center'>Events - Core</Header>

      <CoreActiveEvents events={events.data} />
      <CoreCompletedEvents events={events.data} />
    </>
  );
};
