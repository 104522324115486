import { Header, Loader } from 'semantic-ui-react';
import { TabMenu } from '../Common/TabMenu';
import { TimeframeDropdown } from '../Common/TimeframeDropdown';
import { SmdDetailDeptTab } from './Division/SmdDetailDeptTab';
import { SmdDetailRegionListTab } from './Division/SmdDetailRegionListTab';

import useQueryApi from '../../hooks/useQueryApi';
import { useQueryParams } from '../../hooks/useQueryParams';
import { SmdDivisionReasonCodesTab } from './Division/SmdDivisionReasonCodesTab';

export const DIV = 'DIV';
export const RGN = 'RGN';
const metrics = [DIV, RGN];

export const SmdDetailDiv = ({ fpToggle, level, settimeframe, timeframe }) => {
  const { divNbr = 0 } = useQueryParams();
  const { data: reasonCodesData, isLoading: isLoadingReasonCodes } = useQueryApi('querySmdByDiv', {
    dstNbr: divNbr,
    fpToggle
  });
  const { data: regionCompsData = [], isLoading: isLoadingDistrictComps } = useQueryApi('querySwmSmdOvrUndrByDiv', {
    locNbr: divNbr,
    fpToggle
  });
  const { data: smdDeptData = [], isLoading: isLoadingSmdDeptData } = useQueryApi('querySmdDeptByDiv', {
    locNbr: divNbr,
    fpToggle
  });

  if (isLoadingDistrictComps || isLoadingSmdDeptData || isLoadingReasonCodes) return <Loader active>Loading...</Loader>;

  const smdDeptDataFiltered = smdDeptData.filter(row => row && row.METRIC_LEVEL === DIV);
  const smdRegionListDataFiltered = regionCompsData.filter(row => metrics.includes(row.METRIC_LEVEL));
  const divisionName = regionCompsData[0]?.DIV_NM;
  const transformDate = regionCompsData[0]?.TRNSFM_DT;

  const tabs = [
    {
      menuItem: 'Region List',
      render: () => <SmdDetailRegionListTab data={smdRegionListDataFiltered} timeframe={timeframe} />
    },
    {
      menuItem: 'Depts',
      render: () => <SmdDetailDeptTab data={smdDeptDataFiltered} timeframe={timeframe} />
    },
    {
      menuItem: 'Reason Codes',
      render: () => <SmdDivisionReasonCodesTab data={reasonCodesData} timeframe={timeframe} />
    }
  ];

  return (
    <div>
      <Header className='mb-1 text-capitalize' textAlign='left'>
        SMD - {level} {divNbr}, {String(divisionName).toLowerCase()}
      </Header>

      <p>Data as of {transformDate}</p>

      <span>Timeframe:</span>
      <TimeframeDropdown settimeframe={settimeframe} timeframe={timeframe} />

      <TabMenu className='mt-4' panes={tabs} />
    </div>
  );
};
