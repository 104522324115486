import React, { useState } from 'react'
import {
  Table,
  Tab,
  Header
} from 'semantic-ui-react'
import { AvgMinPickBoss } from './AvgMinPickBoss'
import { AvgMinPickBopis } from './AvgMinPickBopis'
import { SlaMissBoss } from './SlaMissBoss'
import { SlaMissBopis } from './SlaMissBopis'
import { DelvMissWOBin } from './DelvMissWOBin'
import { DelvMissReadiness } from './DelvMissReadiness'
import { DSBoss } from './DSBoss'
import { DSBopis } from './DSBopis'

import { getCompareStyle, getDollarString, getPercentString } from '../Common/TableCellUtils'

export const ChannelView = ({ chnlData, chnlDtlData, slaMissData, delvDtlData, missedDelvDtlData, dsNonComplianceData, dsAssocDtlData, timeframe }) => {
  const [chnlSortColumn] = useState('CHANNEL')
  const [chnlSortDirection] = useState('ascending')

  let chnlDataSort = chnlData.sort((left, right) => {
    let result = left[chnlSortColumn] - right[chnlSortColumn]
    if (chnlSortColumn === 'CHANNEL') {
      result = left.CHANNEL > right.CHANNEL ? 1 : -1
    }
    if (chnlSortDirection === 'descending') {
      result *= -1
    }
    return result
  })
  
  let chnlDataBopisFilter = chnlDataSort.filter((row) => {
    return row && row['CHANNEL'] === 'BOPIS'
  })

  let chnlDataBossFilter = chnlDataSort.filter((row) => {
    return row && row['CHANNEL'] === 'BOSS'
  })

  let delvMissWOBinFilter = null
  if (missedDelvDtlData != null) {delvMissWOBinFilter = missedDelvDtlData.filter((row) => {
    return row && row['MISS_CATEGORY'] === 'WORK_ORDER_BIN' && row[timeframe+'_FLG']
  })}


  let delvMissReadinessFilter = null
  if(missedDelvDtlData != null) {delvMissReadinessFilter = missedDelvDtlData.filter((row) => {
    return row && row['MISS_CATEGORY'] === 'STORE_READINESS' && row[timeframe+'_FLG']
  })}

  const bossOption = [
    {
      menuItem: 'Avg Min-Pick',
      render: () => <AvgMinPickBoss chnlDtlData={chnlDtlData} timeframe={timeframe} />
    },
    {
      menuItem: '# Miss SLA',
      render: () => <SlaMissBoss slaMissData={slaMissData} timeframe={timeframe} />
    },
  
    {
      menuItem: 'Dir. Staging',
      render: () => dsAssocDtlData.length > 0 ? 
        <DSBoss dsNonComplianceData={dsNonComplianceData} dsAssocDtlData={dsAssocDtlData} timeframe={timeframe} /> :
        <>
          &nbsp;
          <p>This store does not have lockers installed.</p>
        </>
    },
  ]

  const bopisOption = [
    {
      menuItem: 'Avg Min-Pick',
      render: () => <AvgMinPickBopis chnlDtlData={chnlDtlData} timeframe={timeframe} />
    },
    {
      menuItem: '# Miss SLA',
      render: () => <SlaMissBopis slaMissData={slaMissData} timeframe={timeframe} />
    },
   
    {
      menuItem: 'Dir. Staging',
      render: () => dsAssocDtlData.length > 0 ?
        <DSBopis dsNonComplianceData={dsNonComplianceData} dsAssocDtlData={dsAssocDtlData} timeframe={timeframe} /> :
        <>
          &nbsp;
          <p>This store does not have lockers installed.</p>
        </>
    },
  ]

  const deliveriesOption = [
    {
      menuItem: 'WO Bin Misses',
      render: () => <DelvMissWOBin missedDelvDtlData={missedDelvDtlData} timeframe={timeframe} />
    },
    {
      menuItem: 'Readiness Misses',
      render: () => <DelvMissReadiness missedDelvDtlData={missedDelvDtlData} timeframe={timeframe} />
    }
  ]

  const bopisView = <Tab.Pane>
    <Table textAlign='center' celled sortable unstackable size='small' style={{ fontSize: 10 }}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell style={{whiteSpace: "normal",wordWrap: 'break-word'}}>Avg Mins to Pick</Table.HeaderCell>
          <Table.HeaderCell style={{whiteSpace: "normal",wordWrap: 'break-word'}}>Pick on Time %</Table.HeaderCell>
          <Table.HeaderCell style={{whiteSpace: "normal",wordWrap: 'break-word'}}># Missed SLA</Table.HeaderCell>
          <Table.HeaderCell style={{whiteSpace: "normal",wordWrap: 'break-word'}}>DS Compliance %</Table.HeaderCell>
          <Table.HeaderCell style={{whiteSpace: "normal",wordWrap: 'break-word'}}>OF App %</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body singleLine>
        {chnlDataBopisFilter.map((data) => {
          return (
            <Table.Row>
              <Table.Cell>{data[timeframe + '_AVG_MINS_TO_PICK'] !== null ?
                data[timeframe + '_AVG_MINS_TO_PICK'].toLocaleString(undefined, { maximumFractionDigits: 2 }) :
                '-'}</Table.Cell>
              <Table.Cell>{getPercentString(data[timeframe + '_PCT_ON_TM'])}</Table.Cell>
              <Table.Cell>{data[timeframe + '_OVER_SLA_CNT']}</Table.Cell>              
              <Table.Cell>{getPercentString(data[timeframe +'_DS_COMP'])}</Table.Cell>
              <Table.Cell>{getPercentString(data[timeframe +'_OF_APP'])}</Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
    <Header textAlign='center'>More Details - BOPIS</Header>
    <Tab panes={bopisOption} />
  </Tab.Pane>

  const bossView = <Tab.Pane>
    <Table textAlign='center' celled sortable unstackable size='small' style={{ fontSize: 10 }}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell style={{whiteSpace: "normal",wordWrap: 'break-word'}}>Avg Mins to Pick</Table.HeaderCell>
          <Table.HeaderCell style={{whiteSpace: "normal",wordWrap: 'break-word'}}>Pick on Time %</Table.HeaderCell>
          <Table.HeaderCell style={{whiteSpace: "normal",wordWrap: 'break-word'}}># Missed SLA</Table.HeaderCell>
          <Table.HeaderCell style={{whiteSpace: "normal",wordWrap: 'break-word'}}>DS Compliance %</Table.HeaderCell>
          <Table.HeaderCell style={{whiteSpace: "normal",wordWrap: 'break-word'}}>OF App %</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body singleLine>
        {chnlDataBossFilter.map((data) => {
          return (
            <Table.Row>
              <Table.Cell>{data[timeframe + '_AVG_MINS_TO_PICK'] !== null ?
                data[timeframe + '_AVG_MINS_TO_PICK'].toLocaleString(undefined, { maximumFractionDigits: 2 }) :
                '-'}</Table.Cell>
              <Table.Cell>{getPercentString(data[timeframe + '_PCT_ON_TM'])}</Table.Cell>
              <Table.Cell>{data[timeframe + '_OVER_SLA_CNT']}</Table.Cell>
              <Table.Cell>{getPercentString(data[timeframe +'_DS_COMP'])}</Table.Cell>
              <Table.Cell>{getPercentString(data[timeframe +'_OF_APP'])}</Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
    <Header textAlign='center'>More Details - BOSS</Header>
    <Tab panes={bossOption} />
  </Tab.Pane>

  const deliveriesView = <Tab.Pane>
    <Table textAlign='center' celled sortable unstackable size='small' style={{ fontSize: 10 }}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell style={{whiteSpace: "normal",wordWrap: 'break-word'}}>Truck Type</Table.HeaderCell>
          <Table.HeaderCell style={{whiteSpace: "normal",wordWrap: 'break-word'}}>Act Sales</Table.HeaderCell>
          <Table.HeaderCell style={{whiteSpace: "normal",wordWrap: 'break-word'}}>Orders Fulfilled</Table.HeaderCell>
          <Table.HeaderCell style={{whiteSpace: "normal",wordWrap: 'break-word'}}>Orders Fulfilled Comp %</Table.HeaderCell>
          <Table.HeaderCell style={{whiteSpace: "normal",wordWrap: 'break-word'}}>CSAT</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {delvDtlData.map((data) => {
          return (
            <Table.Row>
              <Table.Cell>{data['TRUCK_TYPE']}</Table.Cell>
              <Table.Cell>{getDollarString(data[timeframe+'_SLS_AMT'])}</Table.Cell>
              <Table.Cell>{data[timeframe+'_FULFL_ORD']}</Table.Cell>
              <Table.Cell style={getCompareStyle(data[timeframe+'_FULFL_ORD_COMP_PCT'])}>{getPercentString(data[timeframe+'_FULFL_ORD_COMP_PCT'])}</Table.Cell>
              <Table.Cell>{data[timeframe+'_SURVEY_COUNT'] !== 0 ?
                            data[timeframe+'_CSAT'].toLocaleString(undefined, { maximumFractionDigits: 1 }) :
                            '--'}</Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
    {missedDelvDtlData != null && <Table textAlign='center' celled sortable unstackable size='small' style={{ fontSize: 10 }}>
      <Table.Header>
        <Table.Row>
         <Table.HeaderCell>Misses- WO BIN</Table.HeaderCell>
         <Table.HeaderCell>Misses- Readiness</Table.HeaderCell>
        </Table.Row> 
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>{delvMissWOBinFilter.length}</Table.Cell>
          <Table.Cell>{delvMissReadinessFilter.length}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table> }
    <Header textAlign='center'>More Details - Deliveries</Header>
    {missedDelvDtlData != null && <Tab panes={deliveriesOption} /> }
  </Tab.Pane>
  const curbsideView = <Tab.Pane>curbside deliveries placeholder</Tab.Pane>

  const options = [
    {
      menuItem: 'BOPIS',
      render: () => bopisView
    },
    {
      menuItem: 'BOSS',
      render: () => bossView
    },
    {
      menuItem: 'Deliveries',
      render: () => deliveriesView
    },
    {
      menuItem: 'Curbside',
      render: () => curbsideView
    }
  ]

  return <Tab panes={options} />
}