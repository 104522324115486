import { useState } from "react";
import { Grid, Header, Loader, Segment } from "semantic-ui-react";
import useQueryApi from "../../../../../hooks/useQueryApi";
import { TimeframeDropdown } from "../../../../../components/Common/TimeframeDropdown";
import { Link } from "react-router-dom";
import { UnitMenu } from "../../../../../components/Common/UnitMenu";
import { MenuItems } from "../../../../../components/Common/MenuItems";
import { TotalMeasure } from "../components/Measures/TotalMeasure";
import { MeasureBySummaryProgram } from "../components/Measures/MeasureBySummaryProgram";

const MEASURE_ITEMS = {
  All: '',
  Web: '_WEB',
  Store: '_STR'
}

export const MeasurePage = ({ fpToggle, settimeframe, timeframe }) => {

  const [unit, setUnit] = useState('NOM')
  const [allWebStore, setAllWebStore] = useState('All')
  const { data: coreMeasureData,
    isLoading: isLoadingMeasure,
    isError: isErrorMeasure
  } = useQueryApi('coreMeasure', { fpToggle, isCore: true });
  const { data: summaryProgramData,
    isLoading: isLoadingSummaryProgram,
    isError: isErrorSummaryProgram
  } = useQueryApi('coreSummaryProgramName', { fpToggle, isCore: true });

  if (isLoadingMeasure || isLoadingSummaryProgram) return <Loader active>Loading...</Loader>;
  if (isErrorMeasure || isErrorSummaryProgram) return <Header textAlign='center'>Something went wrong fetching data.</Header>;

  const isMonday = new Date().getDay() === 1
  return (
    <>
      <Grid>
        <Grid.Column width={8}>
          <Grid.Row>&nbsp;</Grid.Row>
          <Grid.Row>
            <Link style={{ color: '#EE7125' }} to={'/core/overview'} data-test='b2o-link'>Back to Overview</Link>
          </Grid.Row>
        </Grid.Column>
        <Grid.Column width={8}>
          <Grid.Row>Timeframe:</Grid.Row>
          <Grid.Row>
            <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
          </Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
        </Grid.Column>
      </Grid>
      <Segment>
        <Grid>
          <Grid.Column width={8}>
            <Grid.Row>Units:</Grid.Row>
            <Grid.Row>
              <UnitMenu unit={unit} setUnit={setUnit} />
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={8} textAlign='right'>
            <Grid.Row>&nbsp;</Grid.Row>
            <Grid.Row>
              <MenuItems
                activeItem={allWebStore}
                setItem={setAllWebStore}
                items={['All', 'Web', 'Store']}
                size='small'
              />
            </Grid.Row>
          </Grid.Column>
        </Grid>
        <TotalMeasure
          timeframe={timeframe}
          allWebStore={MEASURE_ITEMS[allWebStore]}
          data={coreMeasureData}
          unit={unit}
          isMonday={isMonday}
        />
        <p>
          *Target is reported as the total measures generated last year through the current period.
          For example, YTD target is all measures generated last year through the upcoming Sunday.
          Actual Measure counts and vLY are reported through the previous day.
        </p>
        <MeasureBySummaryProgram
          timeframe={timeframe}
          allWebStore={MEASURE_ITEMS[allWebStore]}
          data={summaryProgramData}
          unit={unit}
          isMonday={isMonday}
        />
      </Segment >
    </>
  );
};
