export const YEAR = 'Year';
export const TIME_PERIOD = 'Time Period';
export const PERIOD_DESC = 'PERIOD_DESC';
export const DAY_NAME = 'Day Name';
export const FISCAL_DAY = 'FISCAL DAY';
export const SALES = 'Sales $';
export const SALES_PLAN = 'Sales Plan';
export const SALES_PLAN_QUANTITY = 'Sales Plan $';
export const VP_PCT = 'vP %';
export const LY_SALES = 'LY Sales $';
export const SALES_COMP = 'Sales Comp';
export const VLY_PCT = 'vLY %';
export const LABOR_HOURS = 'Labor Hours';
export const LABOR = 'Labor';
export const FCST_HRS = 'Fcst Hours';
export const VF_PCT = 'vF %';
export const MKDN_PCT = 'Mkdn %';
export const MARKDOWNS = 'Markdowns';
export const MKDN_PLAN_PCT = 'Mkdn Plan %';
export const VP_BPS = 'vP bps';

export const FSCL_YR = 'FSCL_YR';
export const AGG_TYPE = 'AGG_TYPE';
export const ALL = 'All';
export const PERIOD_END_DT = 'PERIOD_END_DT';

export const DAY_DESC = 'DAY_DESC';
export const TY_SLS_AMT = 'TY_SLS_AMT';
export const SLS_PLN_AMT = 'SLS_PLN_AMT';
export const LY_SLS_AMT = 'LY_SLS_AMT';
export const ACT_HRS = 'ACT_HRS';
export const EN_US = 'en-US';
export const FCST_HRS_ROW = 'FCST_HRS';
export const SMKDN_AMT = 'SMKDN_AMT';
export const SMD_PCT_PLN = 'SMD_PCT_PLN';

export const FISCAL_PERIOD = 'Fiscal Period';

export const FISCAL_WEEK = 'FISCAL WEEK';
export const FISCAL_MONTH = 'FISCAL MONTH';
export const FISCAL_QUARTER = 'FISCAL QUARTER';

export const TIMEFRAME_ITEMS = [
  { value: FISCAL_DAY, text: 'Day' },
  { value: FISCAL_WEEK, text: 'Week' },
  { value: FISCAL_MONTH, text: 'Month' },
  { value: FISCAL_QUARTER, text: 'Quarter' }
];

export const METRIC_ITEMS = [
  { value: SALES_PLAN, text: SALES_PLAN },
  { value: SALES_COMP, text: SALES_COMP },
  { value: LABOR, text: LABOR },
  { value: MARKDOWNS, text: MARKDOWNS }
];

export const DATE_FORMAT_ITEMS = [
  { value: PERIOD_END_DT, text: 'Date' },
  { value: PERIOD_DESC, text: FISCAL_PERIOD }
];

export const SCORE_CARD_HEADERS = [
  { name: YEAR, dateFormat: [PERIOD_DESC] },
  { name: TIME_PERIOD },
  { name: DAY_NAME, timeframe: [FISCAL_DAY] },
  { name: SALES, metric: [SALES_PLAN, SALES_COMP] },
  { name: SALES_PLAN_QUANTITY, metric: [SALES_PLAN] },
  { name: VP_PCT, metric: [SALES_PLAN] },
  { name: LY_SALES, metric: [SALES_COMP] },
  { name: VLY_PCT, metric: [SALES_COMP] },
  { name: LABOR_HOURS, metric: [LABOR] },
  { name: FCST_HRS, metric: [LABOR] },
  { name: VF_PCT, metric: [LABOR] },
  { name: MKDN_PCT, metric: [MARKDOWNS] },
  { name: MKDN_PLAN_PCT, metric: [MARKDOWNS] },
  { name: VP_BPS, metric: [MARKDOWNS] }
];
