import React, { useMemo } from 'react';
import { Header } from 'semantic-ui-react';
import { DataTable } from '../../Common/DataTable';
import { capitalizeFirstLetter } from '../../../utils/string';
import { getPercentString } from '../../Common/TableCellUtils';
import { METRIC_CONSTANTS } from '../../../utils/constantsByLevel';

export const OnlineVoc = ({ onlineVocList, level, metric, timeframe }) => {

  const capitalizeSubLevel = capitalizeFirstLetter(METRIC_CONSTANTS[level].urlLinkLevel);

  const filteredData = useMemo(() => {
    const filterByOrderType = orderType =>
      onlineVocList.filter(row => row && row['TIME_FRAME'] === timeframe && row['ORDER_TYPE'].toLowerCase() === orderType);

    return {
      bopis: filterByOrderType('bopis'),
      boss: filterByOrderType('boss'),
      mixed: filterByOrderType('mixed'),
      total: filterByOrderType('total')
    };
  }, [onlineVocList, timeframe]);

  const { bopis, boss, mixed, total } = filteredData;

  const dataObj = useMemo(
    () => ({
      headers: [
        { name: `${capitalizeSubLevel} # ` },
        { name: 'BOPIS ' },
        { name: 'BOSS ' },
        { name: 'MIXED ' },
        { name: 'TOTAL ' }
      ],
      data: total.map(totalItem => [
        {
          stringValue: totalItem[METRIC_CONSTANTS[level]['propToRender']],
          link: {
            to: `/${METRIC_CONSTANTS[level]['urlLinkLevel']}/voc`,
            params: {
              [METRIC_CONSTANTS[level]['urlSubLevelParam']]: String(totalItem[METRIC_CONSTANTS[level]['propToRender']]).padStart(4, '0')
            }
          }
        },
        ...getColumnsByMetric(bopis, boss, mixed, totalItem, metric, level)
      ]),
      pagination: true,
      sortable: true,
      sortableDefault: 0
    }),
    [bopis, boss, mixed, total, metric]
  );

  return (
    <>
      <Header textAlign='center'>Online VOC By {capitalizeSubLevel}</Header>
      <DataTable data={dataObj} />
    </>
  );
};

function getColumnsByMetric(bopis, boss, mixed, totalItem, metric, level) {
  const metricMapping = {
    LTPA: 'SATISFACTION_SCORE',
    ProdCond: 'PRODUCT_CONDITION_RATING',
    OrderAcc: 'ORDER_FULFILLMENT_ACCURACY',
    WaitTm: 'WAIT_TIME_5'
  };

  const columnKey = metricMapping[metric];
  if (!columnKey) return [{ stringValue: '-' }, { stringValue: '-' }, { stringValue: '-' }, { stringValue: '-' }];

  const getMetricValue = dataset => {
    const item = dataset.find(row => row[METRIC_CONSTANTS[level]['propToRender']] === totalItem[METRIC_CONSTANTS[level]['propToRender']])
    return item ? getPercentString(item[columnKey]) : '-';
  };

  return [
    { stringValue: getMetricValue(bopis) },
    { stringValue: getMetricValue(boss) },
    { stringValue: getMetricValue(mixed) },
    { stringValue: getPercentString(totalItem[columnKey]) }
  ];
}
