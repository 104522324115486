import React, { useState, useEffect } from 'react'
import {
  Table,
  Loader,
  Segment,
  Grid,
  Icon,
  Pagination,
  Button, Header, Modal, Divider,
} from 'semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'
import { TimeframeDropdown } from '../Common/TimeframeDropdown'
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon'
import { getRoundToDecimalString } from '../Common/TableCellUtils'
import { getHoursToHrsAndMin } from '../Common/TableCellUtils'

import { useQuery } from 'react-query'
import {
  getData
} from '../../service/DataService'
import { ACCESS_LEVELS } from '../Common/AccessChecks';

export const LaborDetailAssoc = withRouter(({ timeframe, settimeframe, fpToggle, location, userMgtLvlId, userIsCorporate }) => {
  const search = new URLSearchParams(location.search)
  const strNbr = search.has('strNbr') ? search.get('strNbr') : 0
  const deptNbrInt = search.has("deptNbr") ? search.get("deptNbr") : 0;

  const [sortColumn, setSortColumn] = useState("EMPL_NAME")
  const [sortDirection, setSortDirection] = useState("ascending")

  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState([]);

  const [modalpoup, setModalPopup] = useState([]);
  let rowsPerPage = 20;
  const [pageNum, setPageNum] = useState(1);
  const [flagValue, setFlagValue] = useState('WTD');
  const [weakday, setWeakday] = useState({
    MONDAY: '-',
    TUESDAY: '-',
    WEDNESDAY: '-',
    THURSDAY: '-',
    FRIDAY: '-',
    SATURDAY: '-',
    SUNDAY: '-',
    mondayScheduled: '-',
    TUESDAYScheduled: '-',
    WEDNESDAYScheduled: '-',
    THURSDAYScheduled: '-',
    FRIDAYScheduled: '-',
    SATURDAYScheduled: '-',
    SUNDAYScheduled: '-',
  });
  const popupArray = [];

  // converting the TimeFrame
  const FlegSwitch = (timeframe) => {
    switch (timeframe) {
      case 'WTD':
        return "WTD_FLG";
      case 'LW':
        return "LW_FLG";
      case 'LM':
        return "LM_FLG";
      case 'MTD':
        return "MTD_FLG";
      case 'QTD':
        return "QTD_FLG";
      case 'HTD':
        return "HTD_FLG";
      case 'YTD':
        return "YTD_FLG";
      case 'L12':
        return "L12_FLG";
      default:
        return "WTD_FLG";
    }
  };
  
  // The API itself enforces access levels, so we don't have to be super strict here
  let queryStr = 'queryLaborDtlAssocByStr850'
  if (userMgtLvlId <= ACCESS_LEVELS.STORE_MANAGERS || userIsCorporate) {
    queryStr = 'queryLaborDtlAssocByStr' 
  } else if (userMgtLvlId === ACCESS_LEVELS.HOURLY_ASM) {
    queryStr = 'queryLaborDtlAssocByStr830'
  }
  const laborDetailAssocQuery = useQuery([queryStr, { strNbr, deptNbrInt, fpToggle, userMgtLvlId }], getData)

  useEffect(() => {
    const newFlagValue = FlegSwitch(timeframe);
    setFlagValue(newFlagValue);
  }, [timeframe, modalpoup]);

  useEffect(() => {
    setPageNum(1)
  }, [sortColumn, sortDirection])

  // updating the popup
  useEffect(() => {
    const updatedWeakday = {
      MONDAY: '-',
      TUESDAY: '-',
      WEDNESDAY: '-',
      THURSDAY: '-',
      FRIDAY: '-',
      SATURDAY: '-',
      SUNDAY: '-',
      mondayScheduled: '-',
      TUESDAYScheduled: '-',
      WEDNESDAYScheduled: '-',
      THURSDAYScheduled: '-',
      FRIDAYScheduled: '-',
      SATURDAYScheduled: '-',
      SUNDAYScheduled: '-',
    };

    modalpoup.forEach((element) => {
      if (element.DAY_NM === "MONDAY") {
        updatedWeakday.MONDAY = element.ACTUAL_HOURS;
        updatedWeakday.mondayScheduled = element.SCHEDULED_HOURS;
      }
      if (element.DAY_NM === "TUESDAY") {
        updatedWeakday.TUESDAY = element.ACTUAL_HOURS;
        updatedWeakday.TUESDAYScheduled = element.SCHEDULED_HOURS;
      }
      if (element.DAY_NM === "WEDNESDAY") {
        updatedWeakday.WEDNESDAY = element.ACTUAL_HOURS;
        updatedWeakday.WEDNESDAYScheduled = element.SCHEDULED_HOURS;
      }
      if (element.DAY_NM === "THURSDAY") {
        updatedWeakday.THURSDAY = element.ACTUAL_HOURS;
        updatedWeakday.THURSDAYScheduled = element.SCHEDULED_HOURS;
      }
      if (element.DAY_NM === "FRIDAY") {
        updatedWeakday.FRIDAY = element.ACTUAL_HOURS;
        updatedWeakday.FRIDAYScheduled = element.SCHEDULED_HOURS;
      }
      if (element.DAY_NM === "SATURDAY") {
        updatedWeakday.SATURDAY = element.ACTUAL_HOURS;
        updatedWeakday.SATURDAYScheduled = element.SCHEDULED_HOURS;
      }
      if (element.DAY_NM === "SUNDAY") {
        updatedWeakday.SUNDAY = element.ACTUAL_HOURS;
        updatedWeakday.SUNDAYScheduled = element.SCHEDULED_HOURS;
      }
    });

    setWeakday(updatedWeakday);

  }, [modalpoup]);

  if (laborDetailAssocQuery.isLoading) {
    return <Loader active>Loading...</Loader>
  }
  const laborDetailAssoc = laborDetailAssocQuery.data

  const updatedLaborDetail = laborDetailAssoc.reduce((acc, obj) => {
    if (obj[flagValue] == 1) {
      popupArray.push(obj)
      const exitingOBJ = acc.find(Item => Item.EMPL_NAME === obj.EMPL_NAME);
      if (exitingOBJ) {
        exitingOBJ.ACTUAL_HOURS += obj.ACTUAL_HOURS
        exitingOBJ.OVERTIME_HOURS += obj.OVERTIME_HOURS
        exitingOBJ.SCHEDULED_HOURS += obj.SCHEDULED_HOURS
        exitingOBJ.PROJECTED_OVERTIME_HOURS += obj.PROJECTED_OVERTIME_HOURS
      } else {
        acc.push({ EMPL_NAME: obj.EMPL_NAME, EMPL_CAT: obj.EMPL_CAT, ACTUAL_HOURS: obj.ACTUAL_HOURS, SCHEDULED_HOURS: obj.SCHEDULED_HOURS, OVERTIME_HOURS: obj.OVERTIME_HOURS, PROJECTED_OVERTIME_HOURS: obj.PROJECTED_OVERTIME_HOURS })
      }
    }
    return acc
  }, [])

  const updatedPopUp = (EMPL_NAME) => {
    const upp = popupArray.filter(ob => ob.EMPL_NAME === EMPL_NAME)

    return upp;
  }

  let deptDataSort = updatedLaborDetail.sort((left, right) => {
    let result = left[sortColumn] - right[sortColumn]

    if (sortColumn === 'EMPL_NAME') {
      result = left.DEPT_NM > right.DEPT_NM ? 1 : -1
    }
    if (sortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  let dataPaginationLaborDetailAssoc = deptDataSort.slice(rowsPerPage * (pageNum - 1), parseInt(Math.min(rowsPerPage * pageNum, deptDataSort.length)))

  return (
    <Segment>
      <Grid>
        <Grid.Column width={7}>
          <Grid.Row>
            <Link style={{ color: '#EE7125' }} to={`/store/overview?strNbr=${strNbr}`} data-test='b2o-link'>Back to Overview</Link>
            {' / '}
            <Link style={{ color: '#EE7125' }} to={`/store/labor?strNbr=${strNbr}`} data-test='b2o-link'>Labor Overview</Link>
          </Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
        </Grid.Column>
        <Grid.Column width={9}>
          <Grid.Row>Timeframe:</Grid.Row>
          <Grid.Row>
            {new Date().getDay() === 1 ?
              <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} /> :
              <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />}
          </Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
        </Grid.Column>
        {deptDataSort.length > rowsPerPage && <Grid.Row>
          <Grid.Column width={16} textAlign='center'>
            <Pagination
              size='mini'
              defaultActivePage={1}
              totalPages={Math.floor((deptDataSort.length - 1) / rowsPerPage) + 1}
              activePage={pageNum}
              boundaryRange={0}
              siblingRange={1}
              onPageChange={(e, { activePage }) => {
                setPageNum(activePage)
              }}
            />
          </Grid.Column>
        </Grid.Row>}
      </Grid>
      <Header textAlign='center'>D{deptNbrInt} Associates</Header>
      <Table textAlign='center' unstackable celled sortable size='small'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={sortColumn === 'EMPL_NAME' ? sortDirection : null}
              onClick={() => {
                if (sortColumn !== 'EMPL_NAME') {
                  setSortColumn('EMPL_NAME')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>
              Associates {sortColumn !== 'EMPL_NAME' && <Icon fitted name='sort' />}
            </Table.HeaderCell>

            <Table.HeaderCell>HRS</Table.HeaderCell>
            {timeframe === "WTD" && new Date().getDay() !== 1 &&<Table.HeaderCell>PROJ O/T</Table.HeaderCell>}
            {timeframe === "WTD" && new Date().getDay() === 1 &&<Table.HeaderCell>O/T</Table.HeaderCell>}
            {timeframe !== "WTD" && <Table.HeaderCell>O/T</Table.HeaderCell>}
            <Table.HeaderCell>SCH</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {dataPaginationLaborDetailAssoc.map((data) => {
            return (
              <Table.Row>
                {(timeframe === "WTD" || timeframe === "LW") && <Table.Cell
                  style={{ color: "#EE7125", textDecoration: "underline" }}
                  singleLine
                  onClick={() => {

                    setOpen(true);
                    setModalData(data);
                    const popupData = updatedPopUp(data.EMPL_NAME);
                    setModalPopup(popupData)

                  }}
                >{data.EMPL_NAME}</Table.Cell>}
                {(timeframe !== "WTD" && timeframe !== "LW") && <Table.Cell>{data.EMPL_NAME}</Table.Cell>}
                <Table.Cell>{getRoundToDecimalString(data.ACTUAL_HOURS, 2)}</Table.Cell>
                {timeframe === "WTD" && new Date().getDay() !== 1 && <Table.Cell>{getRoundToDecimalString(data.PROJECTED_OVERTIME_HOURS, 2)}</Table.Cell>}
                {timeframe === "WTD" && new Date().getDay() === 1 && <Table.Cell>{getRoundToDecimalString(data.OVERTIME_HOURS, 2)}</Table.Cell>}
                {timeframe !== "WTD" && <Table.Cell>{getRoundToDecimalString(data.OVERTIME_HOURS, 2)}</Table.Cell>}
                <Table.Cell>{getRoundToDecimalString(data.SCHEDULED_HOURS, 2)}</Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
      {open && (
        <Modal
          closeIcon
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        >
          <Segment color="orange">
            <Grid>
              <Grid.Column width={16}>
                <Grid.Row>
                  <Header textAlign="center">
                    Associate's Schedule
                  </Header>
                </Grid.Row>
                <Divider></Divider>

                <Grid.Row>
                  <Header>{modalData["EMPL_NAME"]

                  }</Header>
                </Grid.Row>
                <Grid.Row>
                  <p>{modalData["EMPL_CAT"]}</p>
                  <p>Time frame:</p>
                </Grid.Row>
                {/* <Divider></Divider> */}
                <Table unstackable celled sortable size='small'>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell></Table.HeaderCell>
                      <Table.HeaderCell>M</Table.HeaderCell>
                      <Table.HeaderCell>T</Table.HeaderCell>
                      <Table.HeaderCell>W</Table.HeaderCell>
                      <Table.HeaderCell>T</Table.HeaderCell>
                      <Table.HeaderCell>F</Table.HeaderCell>
                      <Table.HeaderCell>S</Table.HeaderCell>
                      <Table.HeaderCell>S</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>ACT*</Table.Cell>
                      <Table.Cell>{getRoundToDecimalString(weakday.MONDAY, 2)}</Table.Cell>
                      <Table.Cell>{getRoundToDecimalString(weakday.TUESDAY, 2)}</Table.Cell>
                      <Table.Cell>{getRoundToDecimalString(weakday.WEDNESDAY, 2)}</Table.Cell>
                      <Table.Cell>{getRoundToDecimalString(weakday.THURSDAY, 2)}</Table.Cell>
                      <Table.Cell>{getRoundToDecimalString(weakday.FRIDAY, 2)}</Table.Cell>
                      <Table.Cell>{getRoundToDecimalString(weakday.SATURDAY, 2)}</Table.Cell>
                      <Table.Cell>{getRoundToDecimalString(weakday.SUNDAY, 2)}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>SCH*</Table.Cell>
                      <Table.Cell>{getRoundToDecimalString(weakday.mondayScheduled, 2)}</Table.Cell>
                      <Table.Cell>{getRoundToDecimalString(weakday.TUESDAYScheduled, 2)}</Table.Cell>
                      <Table.Cell>{getRoundToDecimalString(weakday.WEDNESDAYScheduled, 2)}</Table.Cell>
                      <Table.Cell>{getRoundToDecimalString(weakday.THURSDAYScheduled, 2)}</Table.Cell>
                      <Table.Cell>{getRoundToDecimalString(weakday.FRIDAYScheduled, 2)}</Table.Cell>
                      <Table.Cell>{getRoundToDecimalString(weakday.SATURDAYScheduled, 2)}</Table.Cell>
                      <Table.Cell>{getRoundToDecimalString(weakday.SUNDAYScheduled, 2)}</Table.Cell>
                    </Table.Row>

                  </Table.Body>
                </Table>
                <Grid.Row>
                  <p>ACT*:Actual Hours</p>
                  <p>SCH*:Schedule Hours</p>
                </Grid.Row>
                <Divider></Divider>
                <Grid.Row>
                  <p>Hours : {getHoursToHrsAndMin(modalData["ACTUAL_HOURS"])}</p>
                </Grid.Row>
                <Grid.Row>
                  <p>Schedule : {getRoundToDecimalString(modalData["SCHEDULED_HOURS"], 2)} hrs</p>
                </Grid.Row>
                <Grid.Row>
                  <p>Overtime  : {getHoursToHrsAndMin(modalData["OVERTIME_HOURS"])}</p>
                </Grid.Row>
                <Grid.Row>
                  <p> Projected Overtime : {getHoursToHrsAndMin(modalData["PROJECTED_OVERTIME_HOURS"])}</p>
                </Grid.Row>
              </Grid.Column>
              &nbsp; &nbsp;
              <Grid.Column>
                <Grid.Row>&nbsp;</Grid.Row>
                <Grid.Row>
                  <Button
                    onClick={() => {
                      setOpen(false);
                    }}
                    style={{ color: "white", backgroundColor: "#EE7125" }}
                    content="Close"
                  />
                </Grid.Row>
                <Grid.Row>&nbsp;</Grid.Row>
              </Grid.Column>
            </Grid>
          </Segment>
        </Modal>
      )}
    </Segment>
  )
})
