import { DataTable } from '../../../../../../../components/Common/DataTable';
import { getMgrAddDataTable } from '../../../utils/osa/sidekickskubay';

export const MgrAddTab = props => {
  const { osaSidekickSkuBayData, sidekickExeScoresPlusOneData, subLevel, timeframe, ltf } = props;
  const mgrAddDataTable = getMgrAddDataTable({
    osaSidekickSkuBayData,
    sidekickExeScoresPlusOneData,
    subLevel,
    timeframe,
    ltf
  });

  return <DataTable data={mgrAddDataTable} />;
};
