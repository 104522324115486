import { DataTable } from '../../../../../../../../components/Common/DataTable';
import {
  SUBCLASS_HEADERS_BY_METRIC,
  propsByMetric
} from '../../../../../../utils/sales';
import { getCalloutStyle } from '../../../../../../../../components/Common/TableCellUtils';

export const SubClassTable = ({ subClassesList, metric }) => {
  
  const tableHeaders = SUBCLASS_HEADERS_BY_METRIC[metric];
  const dataObj = {
    sortable: true,
    sortableDefault: 0,
    sortDirection: 'descending',
    pagination: true,
    headers: [{ name: 'Subclass' }, ...tableHeaders],
    data: subClassesList.map(row => [
      {
        link: {
          to: '/core/sales/subclass',
          params: {
            subClassNbr: row['EXT_SUB_CLASS_NBR']
          }
        },
        stringValue: row['MER_SUB_CLASS_NM'].replaceAll('/', '/\u200b')
      },
      {
        dollarValue:
          metric === 'SLS' || metric === 'AVG_TCKT'
            ? row[propsByMetric[metric][0]].toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            : undefined,
        numValue:
          metric === 'TXN' || metric === 'UNT'
            ? row[propsByMetric[metric][0]].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
            : undefined,
        orderBy: row[propsByMetric[metric][0]],
        customStyle: getCalloutStyle(row[propsByMetric[metric][0]])
      },
      {
        dollarValue:
          metric === 'SLS' || metric === 'AVG_TCKT'
            ? row[propsByMetric[metric][1]].toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            : undefined,
        numValue:
          metric === 'TXN' || metric === 'UNT'
            ? row[propsByMetric[metric][1]].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
            : undefined,
        orderBy: row[propsByMetric[metric][1]],
        compareStyle: true
      },
      {
        pctValue: row[propsByMetric[metric][2]],
        compareStyle: true,
        decimals: 2,
      }
    ])
  };

  return (
      <DataTable data={dataObj} />
  );
};
