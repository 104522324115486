import { getCalloutStyle, getThousandDollarString } from "../../../../../components/Common/TableCellUtils";

export const AGG_TYPE = 'AGG_TYPE';
export const ACT_HRS = 'ACT_HRS';
export const ALL = 'All';
export const DAY_DESC = 'DAY_DESC';
export const DAY_NAME = 'Day Name';
export const EN_US = 'en-US';
export const FCST_HRS = 'Fcst Hours';
export const FCST_HRS_ROW = 'FCST_HRS';
export const FISCAL_DAY = 'FISCAL DAY';
export const FISCAL_DAY_LABEL = 'Fiscal Day';
export const FSCL_YR = 'FSCL_YR';
export const LABOR = 'Labor';
export const LABOR_HOURS = 'Labor Hours';
export const LY_SALES = 'LY Sales $';
export const LY_SLS_AMT = 'LY_SLS_AMT';
export const MARKDOWNS = 'Markdowns';
export const MKDN_PCT = 'Mkdn %';
export const MKDN_PLAN_PCT = 'Mkdn Plan %';
export const PERIOD_DESC = 'PERIOD_DESC';
export const PERIOD_END_DT = 'PERIOD_END_DT';
export const SALES = 'Sales $';
export const SALES_COMP = 'Sales Comp';
export const SALES_PLAN = 'Sales Plan';
export const SALES_PLAN_QUANTITY = 'Sales Plan $';
export const SLS_PLN_AMT = 'SLS_PLN_AMT';
export const SMD_PCT_PLN = 'SMD_PCT_PLN';
export const SMKDN_AMT = 'SMKDN_AMT';
export const TIME_PERIOD = 'Time Period';
export const TY_SLS_AMT = 'TY_SLS_AMT';
export const VLY_PCT = 'vLY %';
export const VF_PCT = 'vF %';
export const VP_BPS = 'vP bps';
export const VP_PCT = 'vP %';
export const YEAR = 'Year';

export const FISCAL_MONTH = 'FISCAL MONTH';
export const FISCAL_PERIOD = 'Fiscal Period';
export const FISCAL_QUARTER = 'FISCAL QUARTER';
export const FISCAL_WEEK = 'FISCAL WEEK';

export const TIMEFRAME_ITEMS = [
  { value: FISCAL_DAY, text: 'Day' },
  { value: FISCAL_WEEK, text: 'Week' },
  { value: FISCAL_MONTH, text: 'Month' },
  { value: FISCAL_QUARTER, text: 'Quarter' }
];

export const SALES_SCORECARD = 'Sales Scorecard';
export const WTD_SALES = 'WTD Sales';
export const WTD_OR_SCORECARD_TABS = [WTD_SALES, SALES_SCORECARD];

export const textCapitalizedStyle = {
  textTransform: 'capitalize'
};

export const DATE_FORMAT_ITEMS = [
  { value: PERIOD_END_DT, text: 'Date' },
  { value: PERIOD_DESC, text: FISCAL_PERIOD }
];

export const METRIC_ITEMS = [
  { value: SALES_PLAN, text: SALES_PLAN },
  { value: SALES_COMP, text: SALES_COMP },
  { value: LABOR, text: LABOR },
  { value: MARKDOWNS, text: MARKDOWNS }
];

export const salesPlanItems = [SALES_PLAN, SALES_COMP];

export const dailySalesHeaders = [
  { name: 'Day' },
  {
    element: (
      <span>
        Daily <br />
        Sales $ (k)
      </span>
    ),
    name: 'daily-sales-k'
  },
  { name: '$ vPlan (k)' },
  {
    element: (
      <span>
        % to <br />
        Daily Plan
      </span>
    ),
    name: 'to-daily-plan'
  },
  { name: 'Comp %' },
  {
    element: (
      <span>
        Planned
        <br />
        Comp %
      </span>
    ),
    name: 'planned-comp'
  }
];

export const wtdSalesColumns = (data, type = '') => [
  type === 'departments'
    ? {
        link: {
          params: {
            deptNbr: data['DEPT_NM']
          },
          to: `/core/daily/dept`
        },
        stringValue: data['DEPT_NM']
      }
    : { stringValue: data['ABBR_DAY_NM'] },
  {
    customStyle: getCalloutStyle(data['WTD_SLS_AMT']),
    stringValue: getThousandDollarString(data['WTD_SLS_AMT'], 1)
  },
  {
    customStyle: getCalloutStyle(data['DOL_TO_WTD_PLN']),
    stringValue: getThousandDollarString(data['DOL_TO_WTD_PLN'], 1)
  },
  {
    customStyle: getCalloutStyle(data['PCT_TO_WTD_PLN']),
    decimals: 1,
    pctValue: data['PCT_TO_WTD_PLN']
  },
  {
    customStyle: getCalloutStyle(data['WTD_COMP_PCT']),
    decimals: 1,
    pctValue: data['WTD_COMP_PCT']
  },
  {
    customStyle: getCalloutStyle(data['WTD_PLANNED_COMP_PCT']),
    decimals: 1,
    pctValue: data['WTD_PLANNED_COMP_PCT']
  }
];

export const dataTableByRow = (row, metric) => {
  switch (metric) {
    case SALES_PLAN:
      return [{ dollarValue: row[SLS_PLN_AMT] }, { pctValue: row[TY_SLS_AMT] / row[SLS_PLN_AMT] - 1 }];
    case SALES_COMP:
      return [{ dollarValue: row[LY_SLS_AMT] }, { pctValue: row[TY_SLS_AMT] / row[LY_SLS_AMT] - 1 }];
    case LABOR:
      return [
        { stringValue: row[ACT_HRS].toLocaleString(EN_US, { maximumFractionDigits: 0 }) },
        { stringValue: row[FCST_HRS_ROW].toLocaleString(EN_US, { maximumFractionDigits: 0 }) },
        { pctValue: row[ACT_HRS] / row[FCST_HRS_ROW] - 1 }
      ];
    case MARKDOWNS:
      return [
        { pctValue: row[SMKDN_AMT] / row[TY_SLS_AMT] },
        { pctValue: row[SMD_PCT_PLN] },
        {
          decimals: 0,
          numValue: 10000 * (row[SMKDN_AMT] / row[TY_SLS_AMT] - row[SMD_PCT_PLN]),
          postfix: ' bps'
        }
      ];
    default:
      return [];
  }
};

export const dailySalesColumns = row => [
  {
    orderBy: row['DAY_CODE'],
    stringValue: row['ABBR_DAY_NM']
  },
  {
    stringValue: getThousandDollarString(row[TY_SLS_AMT], 1)
  },
  {
    customStyle: getCalloutStyle(row['DOL_TO_PLN']),
    stringValue: getThousandDollarString(row['DOL_TO_PLN'], 1)
  },
  {
    customStyle: getCalloutStyle(row['PCT_TO_PLN']),
    decimals: 1,
    pctValue: row['PCT_TO_PLN']
  },
  {
    customStyle: getCalloutStyle(row['COMP_PCT']),
    decimals: 1,
    pctValue: row['COMP_PCT']
  },
  {
    customStyle: getCalloutStyle(row['PLANNED_COMP_PCT']),
    decimals: 1,
    pctValue: row['PLANNED_COMP_PCT']
  }
];

export const wtdSalesHeaders = [
  { name: 'As Of' },
  {
    element: (
      <span>
        WTD Sales <br />$ (k)
      </span>
    ),
    name: 'wtd-sales-k'
  },
  {
    element: (
      <span>
        $ vPlan <br />
        WTD (k)
      </span>
    ),
    name: 'vplan-k'
  },
  {
    element: (
      <span>
        % to <br />
        WTD Plan
      </span>
    ),
    name: 'to-wtd-plan'
  },
  {
    element: (
      <span>
        WTD <br />
        Comp %
      </span>
    ),
    name: 'wtd-comp'
  },
  {
    element: (
      <span>
        WTD Planned
        <br />
        Comp %
      </span>
    ),
    name: 'wtd-planned-comp'
  }
];

export const wtdSalesColumnsByDepartmentHeaders = [
  { name: 'Dept' },
  {
    element: (
      <span>
        WTD Sales <br />$ (k)
      </span>
    ),
    name: 'wtd-sales-k'
  },
  {
    element: (
      <span>
        $ vPlan
        <br />
        WTD (k)
      </span>
    ),
    name: 'vplan-wtd-k'
  },
  {
    element: (
      <span>
        % to
        <br />
        WTD Plan
      </span>
    ),
    name: 'pct-wtd-plan'
  },
  {
    element: (
      <span>
        WTD
        <br />
        Comp %
      </span>
    ),
    name: 'wtd-comp'
  },
  {
    element: (
      <span>
        WTD Planned
        <br />
        Comp %
      </span>
    ),
    name: 'wtd-planned-comp'
  }
];

export const yearScoreCardDataColumns = (data, isDateFormatFiscalYear, dateFormat, timeframe, metric) =>
  data.map(row =>
    [
      isDateFormatFiscalYear && {
        stringValue: row.FSCL_YR
      },
      {
        orderBy: isDateFormatFiscalYear ? row.PERIOD_END_DT : undefined,
        stringValue: row[dateFormat]
      },
      timeframe === FISCAL_DAY && { stringValue: row[DAY_DESC] },
      (metric === SALES_PLAN || metric === SALES_COMP) && { dollarValue: row[TY_SLS_AMT] },
      ...dataTableByRow(row, metric)
    ].filter(Boolean)
  );

export const SCORE_CARD_HEADERS = [
  { name: YEAR, dateFormat: [PERIOD_DESC] },
  { name: TIME_PERIOD },
  { name: DAY_NAME, timeframe: [FISCAL_DAY] },
  { name: SALES, metric: [SALES_PLAN, SALES_COMP] },
  { name: SALES_PLAN_QUANTITY, metric: [SALES_PLAN] },
  { name: VP_PCT, metric: [SALES_PLAN] },
  { name: LY_SALES, metric: [SALES_COMP] },
  { name: VLY_PCT, metric: [SALES_COMP] },
  { name: LABOR_HOURS, metric: [LABOR] },
  { name: FCST_HRS, metric: [LABOR] },
  { name: VF_PCT, metric: [LABOR] },
  { name: MKDN_PCT, metric: [MARKDOWNS] },
  { name: MKDN_PLAN_PCT, metric: [MARKDOWNS] },
  { name: VP_BPS, metric: [MARKDOWNS] }
];

export const scoreCardHeaders = (timeframe, metric, dateFormat) =>
  SCORE_CARD_HEADERS.filter(
    ({ dateFormat: dateFormatH, metric: metricH, timeframe: timeframeH }) =>
      (!timeframeH || timeframeH.includes(timeframe)) &&
      (!metricH || metricH.includes(metric)) &&
      (!dateFormatH || dateFormatH.includes(dateFormat))
  );
