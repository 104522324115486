
// Constant values for the levels of access. Everyone is max int, Store Users are 900, etc
// The lower the access level, the more access the user has
export const ACCESS_LEVELS = {
    EVERYONE: Number.MAX_SAFE_INTEGER,
    STORE_USER: 900,
    DEPT_SUP: 850,
    HOURLY_ASM: 830,
    STORE_MANAGERS: 800,
    DIST_MANAGERS: 700,
};
