import React from 'react'
import { Container, Message, MessageContent, MessageHeader, Icon } from 'semantic-ui-react'

export const LegacyPageNotice = ({ isSticky, isBottom }) => {
  const style = isBottom ?
    { marginBottom: '1em', position: 'sticky', bottom: 0, zIndex: 10 } :
    { marginTop: '1em', position: 'sticky', top: 0, zIndex: 10 }

  return (
    <Message color="red" size="massive" {...(isSticky && { style })}>
       <MessageContent>
         <MessageHeader><Icon name='exclamation' loading />Store Pulse has moved to a new URL<Icon name='exclamation' loading /></MessageHeader>
         <a href="https://storepulse.homedepot.com/" style={{ paddingLeft: 3 }}>
           https://storepulse.homedepot.com
         </a>
         <br />
         Please update any saved links and go to this link in the future. This current url will be deactivated on August 30th.
       </MessageContent>
    </Message>
  )

  // TODO: We had to go with a non-blocking alert due to First Phones not being fully rolled out. Switch back to the blocking code when First Phones is fully rolled out.
  // return (
  //   <Container>
  //     <Message color="red"  size='massive'>
  //       <MessageContent>
  //         <MessageHeader>Store Pulse has moved to a new URL</MessageHeader>
  //         <a href="https://storepulse.homedepot.com/" style={{ paddingLeft: 3 }}>
  //           https://storepulse.homedepot.com
  //         </a>
  //         <br />
  //         Please update any saved links and go to this link in the future. This current url will be deactivated on August 30th.
  //       </MessageContent>
  //     </Message>
  //   </Container>
  // )
}