import React from 'react';
import { Grid, Header, Icon, Popup } from 'semantic-ui-react';
import { DataTable } from '../../../../../../components/Common/DataTable';
import { createDataObjSideKickExecution, sideKickPurgeDataReduce } from '../../utils/osa/purge';

const SidekickExecution = ({ ltf, data, dataScores, findItem, timeframe, entityField }) => {
  const sideKickPurgeData = sideKickPurgeDataReduce(data, dataScores, entityField, timeframe, findItem, ltf);

  const dataObj = createDataObjSideKickExecution(sideKickPurgeData, ltf);

  return (
    <>
      <Grid centered style={{ marginBottom: '1rem' }}>
        <Grid.Column textAlign='center'>
          <Header as='h3'>
            Sidekick Execution - Purge
            <Popup
              trigger={<Icon name='info circle' color='blue' />}
              content='Execution Rate = Purge Tasks Completed/60. A store is given 60 purge tasks to execute each week.'
              position='right center'
              on='click'
              textAlign='center'
            />
          </Header>
        </Grid.Column>
      </Grid>

      <DataTable data={dataObj} />
    </>
  );
};

export default SidekickExecution;
