import React, { useState } from 'react';
import { Loader, Header, Segment, Grid, Dropdown } from 'semantic-ui-react';
import { withRouter, Link } from 'react-router-dom';
import { TimeframeDropdown } from '../Common/TimeframeDropdown';
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon';

import { useQuery } from 'react-query';
import { getData } from '../../service/DataService';
import { DataTable } from '../Common/DataTable';

import {
  VOC_LTSA_GOAL,
  VOC_GET_GOAL,
  VOC_SHELF_AVAILABILITY_GOAL,
  VOC_CASHIER_FRIENDLINESS_GOAL
} from '../../constants/goals';

export const LtsaDetailDst = withRouter(({ timeframe, settimeframe, fpToggle, level, locNbr }) => {
  const [metric, setMetric] = useState('Ltsa');

  const ltsaSmryDstByDstQuery = useQuery([`query/ltsaSummary/${level}`, { locNbr, fpToggle }], getData);
  const ltsaSmryStrByDstQuery = useQuery([`query/ltsaSummaryPlusOne/${level}`, { locNbr, fpToggle }], getData);

  if (ltsaSmryDstByDstQuery.isLoading || ltsaSmryStrByDstQuery.isLoading) {
    return <Loader active>Loading...</Loader>;
  }

  const consumerSummary = ltsaSmryDstByDstQuery.data.filter(({ CUSTOMER_TYPE }) => CUSTOMER_TYPE === 'CONSUMER')[0];
  const proSummary = ltsaSmryDstByDstQuery.data.filter(({ CUSTOMER_TYPE }) => CUSTOMER_TYPE === 'PRO')[0];

  const bold = { fontWeight: 'bold' };
  const summaryObj = {
    headers: [{ name: '' }, { name: 'Consumer' }, { name: 'Pro' }, { name: 'Total' }],
    data: [
      [
        { stringValue: 'LTSA', customStyle: bold },
        {
          pctValue: consumerSummary[`${timeframe}_LTSA_SCR_CNT`] / consumerSummary[`${timeframe}_LTSA_SVY_CNT`],
          compareValue: VOC_LTSA_GOAL
        },
        {
          pctValue: proSummary[`${timeframe}_LTSA_SCR_CNT`] / proSummary[`${timeframe}_LTSA_SVY_CNT`],
          compareValue: VOC_LTSA_GOAL
        },
        {
          pctValue:
            (consumerSummary[`${timeframe}_LTSA_SCR_CNT`] + proSummary[`${timeframe}_LTSA_SCR_CNT`]) /
            (consumerSummary[`${timeframe}_LTSA_SVY_CNT`] + proSummary[`${timeframe}_LTSA_SVY_CNT`]),
          compareValue: VOC_LTSA_GOAL
        }
      ],
      [
        { stringValue: 'Shelf Availability', customStyle: bold },
        {
          pctValue: consumerSummary[`${timeframe}_SHLF_AVL_CNT`] / consumerSummary[`${timeframe}_SHLF_SVY_CNT`],
          compareValue: VOC_SHELF_AVAILABILITY_GOAL
        },
        {
          pctValue: proSummary[`${timeframe}_SHLF_AVL_CNT`] / proSummary[`${timeframe}_SHLF_SVY_CNT`],
          compareValue: VOC_SHELF_AVAILABILITY_GOAL
        },
        {
          pctValue:
            (consumerSummary[`${timeframe}_SHLF_AVL_CNT`] + proSummary[`${timeframe}_SHLF_AVL_CNT`]) /
            (consumerSummary[`${timeframe}_SHLF_SVY_CNT`] + proSummary[`${timeframe}_SHLF_SVY_CNT`]),
          compareValue: VOC_SHELF_AVAILABILITY_GOAL
        }
      ],
      [
        { stringValue: 'Cashier Friendliness', customStyle: bold },
        {
          pctValue: consumerSummary[`${timeframe}_CSHR_FRND_CNT`] / consumerSummary[`${timeframe}_CSHR_SVY_CNT`],
          compareValue: VOC_CASHIER_FRIENDLINESS_GOAL
        },
        {
          pctValue: proSummary[`${timeframe}_CSHR_FRND_CNT`] / proSummary[`${timeframe}_CSHR_SVY_CNT`],
          compareValue: VOC_CASHIER_FRIENDLINESS_GOAL
        },
        {
          pctValue:
            (consumerSummary[`${timeframe}_CSHR_FRND_CNT`] + proSummary[`${timeframe}_CSHR_FRND_CNT`]) /
            (consumerSummary[`${timeframe}_CSHR_SVY_CNT`] + proSummary[`${timeframe}_CSHR_SVY_CNT`]),
          compareValue: VOC_CASHIER_FRIENDLINESS_GOAL
        }
      ],
      [
        { stringValue: 'Time To Checkout Score', customStyle: bold },
        { pctValue: consumerSummary[`${timeframe}_CHCK_OUT_CNT`] / consumerSummary[`${timeframe}_CHCK_SVY_CNT`] },
        { pctValue: proSummary[`${timeframe}_CHCK_OUT_CNT`] / proSummary[`${timeframe}_CHCK_SVY_CNT`] },
        {
          pctValue:
            (consumerSummary[`${timeframe}_CHCK_OUT_CNT`] + proSummary[`${timeframe}_CHCK_OUT_CNT`]) /
            (consumerSummary[`${timeframe}_CHCK_SVY_CNT`] + proSummary[`${timeframe}_CHCK_SVY_CNT`])
        }
      ],
      [
        { stringValue: 'GET', customStyle: bold },
        {
          pctValue: consumerSummary[`${timeframe}_GET_SCR_CNT`] / consumerSummary[`${timeframe}_GET_SVY_CNT`],
          compareValue: VOC_GET_GOAL
        },
        {
          pctValue: proSummary[`${timeframe}_GET_SCR_CNT`] / proSummary[`${timeframe}_GET_SVY_CNT`],
          compareValue: VOC_GET_GOAL
        },
        {
          pctValue:
            (consumerSummary[`${timeframe}_GET_SCR_CNT`] + proSummary[`${timeframe}_GET_SCR_CNT`]) /
            (consumerSummary[`${timeframe}_GET_SVY_CNT`] + proSummary[`${timeframe}_GET_SVY_CNT`]),
          compareValue: VOC_GET_GOAL
        }
      ],
      [
        { stringValue: 'Greet', customStyle: bold },
        { pctValue: consumerSummary[`${timeframe}_GRT_SCR_CNT`] / consumerSummary[`${timeframe}_ENG_SVY_CNT`] },
        { pctValue: proSummary[`${timeframe}_GRT_SCR_CNT`] / proSummary[`${timeframe}_ENG_SVY_CNT`] },
        {
          pctValue:
            (consumerSummary[`${timeframe}_GRT_SCR_CNT`] + proSummary[`${timeframe}_GRT_SCR_CNT`]) /
            (consumerSummary[`${timeframe}_ENG_SVY_CNT`] + proSummary[`${timeframe}_ENG_SVY_CNT`])
        }
      ],
      [
        { stringValue: 'Engage', customStyle: bold },
        { pctValue: consumerSummary[`${timeframe}_ENG_SCR_CNT`] / consumerSummary[`${timeframe}_ENG_SVY_CNT`] },
        { pctValue: proSummary[`${timeframe}_ENG_SCR_CNT`] / proSummary[`${timeframe}_ENG_SVY_CNT`] },
        {
          pctValue:
            (consumerSummary[`${timeframe}_ENG_SCR_CNT`] + proSummary[`${timeframe}_ENG_SCR_CNT`]) /
            (consumerSummary[`${timeframe}_ENG_SVY_CNT`] + proSummary[`${timeframe}_ENG_SVY_CNT`])
        }
      ],
      [
        { stringValue: 'Thank', customStyle: bold },
        { pctValue: consumerSummary[`${timeframe}_THK_SCR_CNT`] / consumerSummary[`${timeframe}_THK_SVY_CNT`] },
        { pctValue: proSummary[`${timeframe}_THK_SCR_CNT`] / proSummary[`${timeframe}_THK_SVY_CNT`] },
        {
          pctValue:
            (consumerSummary[`${timeframe}_THK_SCR_CNT`] + proSummary[`${timeframe}_THK_SCR_CNT`]) /
            (consumerSummary[`${timeframe}_THK_SVY_CNT`] + proSummary[`${timeframe}_THK_SVY_CNT`])
        }
      ]
    ]
  };

  let strDataFilterConsumer = ltsaSmryStrByDstQuery.data.filter(row => {
    return row && row['CUSTOMER_TYPE'] === 'CONSUMER';
  });

  let strDataFilterPro = ltsaSmryStrByDstQuery.data.filter(row => {
    return row && row['CUSTOMER_TYPE'] === 'PRO';
  });

  const combinedStrData = strDataFilterConsumer.map(row => {
    const proRow = strDataFilterPro.filter(proRow => {
      return proRow['STR_NBR'] === row['STR_NBR'];
    })[0];
    return {
      STR_NBR: row['STR_NBR'],
      consumer: row,
      pro: proRow ? proRow : {}
    };
  });

  let props = ['_LTSA_SCR_CNT', '_LTSA_SVY_CNT'];
  switch (metric) {
    case 'ShelfAvail':
      props = ['_SHLF_AVL_CNT', '_SHLF_SVY_CNT'];
      break;
    case 'CashierFriendly':
      props = ['_CSHR_FRND_CNT', '_CSHR_SVY_CNT'];
      break;
    case 'TimeToCheckout':
      props = ['_CHCK_OUT_CNT', '_CHCK_SVY_CNT'];
      break;
    case 'GET':
      props = ['_GET_SCR_CNT', '_GET_SVY_CNT'];
      break;
    case 'Greet':
      props = ['_GRT_SCR_CNT', '_ENG_SVY_CNT'];
      break;
    case 'Engage':
      props = ['_ENG_SCR_CNT', '_ENG_SVY_CNT'];
      break;
    case 'Thank':
      props = ['_THK_SCR_CNT', '_THK_SVY_CNT'];
      break;
  }

  const strObj = {
    sortable: true,
    sortableDefault: 0,
    headers: [{ name: 'Store #' }, { name: 'Consumer' }, { name: 'Pro' }, { name: 'Total' }],
    data: combinedStrData.map(({ STR_NBR, consumer, pro }) => {
      return [
        { stringValue: STR_NBR, link: { to: `/store/ltsa`, params: { strNbr: STR_NBR } } },
        { pctValue: consumer[`${timeframe}${props[0]}`] / consumer[`${timeframe}${props[1]}`] },
        { pctValue: pro[`${timeframe}${props[0]}`] / pro[`${timeframe}${props[1]}`] },
        {
          pctValue:
            (consumer[`${timeframe}${props[0]}`] + pro[`${timeframe}${props[0]}`]) /
            (consumer[`${timeframe}${props[1]}`] + pro[`${timeframe}${props[1]}`])
        }
      ];
    }),
  };

  return (
    <Segment fluid>
      <Grid>
        <Grid.Row>
          <Grid.Column width={8} textAlign='center'>
            <Grid.Row>&nbsp;</Grid.Row>
            <Link style={{ color: '#EE7125' }} to={`/${level}/overview?dstNbr=${locNbr}`} data-test='b2o-link'>
              Back to Overview
            </Link>
          </Grid.Column>
          <Grid.Column width={8}>
            Timeframe:
            {new Date().getDay() === 1 ? (
              <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} />
            ) : (
              <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Header textAlign='center'>VOC Summary - District #{locNbr}</Header>
      <DataTable data={summaryObj} />
      &nbsp;
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <Grid.Row>&nbsp;</Grid.Row>
            <Grid.Row>
              <Header textAlign='center'>LTSA By Store</Header>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={8}>
            Select Metric:
            <Dropdown
              fluid
              compact
              selection
              defaultValue='Ltsa'
              options={[
                { key: 'Ltsa', text: 'LTSA Score', value: 'Ltsa' },
                { key: 'ShelfAvail', text: 'Shelf Availability', value: 'ShelfAvail' },
                { key: 'CashierFriendly', text: 'Cashier Friendliness', value: 'CashierFriendly' },
                { key: 'TimeToCheckout', text: 'Time To Checkout', value: 'TimeToCheckout' },
                { key: 'GET', text: 'GET', value: 'GET' },
                { key: 'Greet', text: 'Greet', value: 'Greet' },
                { key: 'Engage', text: 'Engage', value: 'Engage' },
                { key: 'Thank', text: 'Thank', value: 'Thank' }
              ]}
              onChange={(_, { value }) => {
                setMetric(value);
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <DataTable data={strObj} />
    </Segment>
  );
});
