import { ACCESS_LEVELS } from '../../../../../../components/Common/AccessChecks';
import { Breadcrumb } from '../../../../../../components/Common/Breadcrumb';
import { DataView } from '../../../../../../components/Common/DataView';
import { districtSummary, MERCH_LEAD, OSA_OVERVIEW_HEADERS, osaOverviewDataBuilder } from '../../utils/osa/osa';
import { getSkuBayCompletedTasks, getSkuBayTaskGoal } from '../../../../../../utils/osa';
import { getLastTimeframe } from '../../../../../../utils/timeFrameUtils';
import { Header, Loader } from 'semantic-ui-react';
import { TimeframeDropdown } from '../../../../../../components/Common/TimeframeDropdown';
import { useOverviewOsaQueries } from '../../../../hooks/useOverviewOsaQueries';

export const OsaOverviewPage = ({ userMgtLvlId, userIsCorporate, jobProfileNm, timeframe, settimeframe, fpToggle }) => {
  const { isError, isLoading, osaHighlevel, osaDriverSummary } = useOverviewOsaQueries(fpToggle);
  const showDrilldown = userMgtLvlId <= ACCESS_LEVELS.DEPT_SUP || userIsCorporate || jobProfileNm === MERCH_LEAD;
  const lastTimeFrame = getLastTimeframe(timeframe);

  if (isError) return <p>Something went wrong.</p>;
  if (isLoading) return <Loader active>Loading</Loader>;

  if (!osaHighlevel.length || !osaDriverSummary.length) {
    return (<Header textAlign='center'>No data to show</Header>)
  }

  if (Array.isArray(osaHighlevel)) {
    osaHighlevel.forEach(store => {
      districtSummary.tasksSkuBayCompleted += getSkuBayCompletedTasks(store, timeframe);
      districtSummary.tasksSkuBayCompletedGoal += getSkuBayTaskGoal(store, timeframe);
      districtSummary.tasksPurgeCompleted += parseInt(store[`${timeframe}_STR_PURGE_COMPLETED_TASK_COUNT`] ?? 0);
      districtSummary.tasksPurgeCompletedGoal += parseInt(store[`${timeframe}_STR_PURGE_TASK_GOAL`] ?? 0);
    });
  }

  const osaSummaryData = osaDriverSummary[0];
  const headers = OSA_OVERVIEW_HEADERS(lastTimeFrame);
  const data = {
    headers,
    data: osaOverviewDataBuilder(showDrilldown, osaSummaryData, timeframe, lastTimeFrame)
  };

  const breadcrumbItems = [{ to: `/core/overview`, text: 'Overview' }, { text: 'OSA' }];

  return (
    <section>
      <Breadcrumb items={breadcrumbItems} />

      <DataView className='mt-2'>
        <DataView.Title>
          On-Shelf Availability Metrics - Core
          <Header.Subheader>Data as of {osaSummaryData?.TRNSFM_DT}</Header.Subheader>
        </DataView.Title>

        <DataView.Controls className='align-items-center d-flex flex-column'>
          Timeframe:
          <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
        </DataView.Controls>
        <DataView.DataTable data={data} />
      </DataView>
    </section>
  );
};
