import { useEffect, useState } from 'react'

import { getWindowDimensions } from '../utils/window'

const MOBILE_BREAKPOINT = 768

export const useWindowsDimensions = () => {
  const [{ height, width }, setDimensions] = useState(getWindowDimensions())
  const isMobile = width <= MOBILE_BREAKPOINT
  const isDesktop = !isMobile

  const handleWindowSizeChange = () => setDimensions(getWindowDimensions())

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  return { height, isDesktop, isMobile, width }
}
