import React from 'react'
import {
  Tab,
} from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import { DstCompTab } from './DstCompTab'
import { DstSlsTab } from './DstSlsTab'
import { DstInventoryTab } from './DstInventoryTab'
import { Top40Btm40Skus } from '../ProdHierDrilldown/Top40Btm40Skus'

export const DstOverviewTabs = withRouter(({ dataSlsDeptByDst, dataSlsClsByDst, dataSlsSclsByDst, deptInventory, Top40Btm40Query, timeframe, dstNm }) => {


  const salesView = <DstSlsTab
    dataSlsDeptByDst={dataSlsDeptByDst}
    dataSlsClsByDst={dataSlsClsByDst}
    dataSlsSclsByDst={dataSlsSclsByDst}
    Top40Btm40Query={Top40Btm40Query}
    timeframe={timeframe}
    dstNm={dstNm} />


  const compView = <DstCompTab
    data={dataSlsDeptByDst}
    timeframe={timeframe}
    dstNm={dstNm} />

  const inventoryView = <DstInventoryTab
    data={deptInventory}
    dstNm={dstNm} />


  const tabs = [
    {
      menuItem: 'Sales',
      render: () => salesView
    },

    {
      menuItem: 'Comp',
      render: () => compView,
    },

    {
      menuItem: 'Inventory',
      render: () => inventoryView,
    },
 
  ]

  return <Tab panes={tabs} />
})