import React, { useState, useEffect } from 'react'
import {
  Table,
  Pagination,
  Grid,
  Menu,
  Header

} from 'semantic-ui-react'

import { getPercentString } from '../Common/TableCellUtils'

export const DSBoss = ({ dsNonComplianceData, timeframe, dsAssocDtlData }) => {
  const [sortColumn, setSortColumn] = useState('EXTN_HOST_ORDER_REF')
  const [orderOrAssoc, setOrderOrAssoc] = useState('ORDER')
  const [sortDirection, setSortDirection] = useState('descending')
  const [pageNum, setPageNum] = useState(1)
  let rowsPerPage = 50

  
  const [sortColumnAssoc, setSortColumnAssoc] = useState('USER_ID')
  const [sortDirectionAssoc, setSortDirectionAssoc] = useState('ascending')
  const [pageNumAssoc, setPageNumAssoc] = useState(1)
  let rowsPerPageAssoc = 50
  

  useEffect(() => {
    setPageNum(1)
  }, [timeframe])

  useEffect(() => {
    setPageNumAssoc(1)
  }, [timeframe])


  let dataFilter = dsNonComplianceData.filter((row) => {
    return row && row['ORDER_TYPE'] === 'BOSS' && row[timeframe + '_FLG']
  })


  let dataFilterAssoc = dsAssocDtlData.filter((row) => {
    return row && row['ORDER_TYPE'] === 'BOSS' && row[timeframe + '_DIRECTED_ORD_CT'] > 0
  })


  
  let dataSort = dataFilter.sort((left, right) => {
    let result = left[sortColumn] - right[sortColumn]
    if (sortColumn === 'EXTN_HOST_ORDER_REF') {
      result = left.EXTN_HOST_ORDER_REF > right.EXTN_HOST_ORDER_REF ? 1 : -1
    }
    if (sortColumn === 'ASSOC_NM') {
      result = left.ASSOC_NM > right.ASSOC_NM ? 1 : -1
    }
    if (sortColumn === 'USER_ID') {
      result = left.USER_ID > right.USER_ID ? 1 : -1
    }
    if (sortColumn === 'DIRECTED_LOCATION') {
      result = left.DIRECTED_LOCATION > right.DIRECTED_LOCATION ? 1 : -1
    }
    if (sortColumn === 'STAGED_LOCATION') {
      result = left.STAGED_LOCATION > right.STAGED_LOCATION ? 1 : -1
    }
    if (sortDirection === 'descending') {
      result *= -1
    }
    return result
  })


  let dataSortAssoc = dataFilterAssoc.sort((left, right) => {
    let result = left[sortColumnAssoc] - right[sortColumnAssoc]
    if (sortColumnAssoc === 'USER_ID') {
      result = left.USER_ID > right.USER_ID ? 1 : -1
    }
    if (sortColumnAssoc === 'ASSOC_NM') {
      result = left.ASSOC_NM > right.ASSOC_NM ? 1 : -1
    }
    if (sortColumnAssoc === 'DS_COMPLIANCE_PCT') {
      result = left[timeframe + '_STAGED_DIRECTED_ORD_CT'] / left[timeframe + '_DIRECTED_ORD_CT'] > right[timeframe + '_STAGED_DIRECTED_ORD_CT'] / right[timeframe + '_DIRECTED_ORD_CT'] ? 1 : -1
    }
    if (sortColumnAssoc === 'NUM_NON_COMPLIANT') {
      result = left[timeframe + '_DIRECTED_ORD_CT'] - left[timeframe + '_STAGED_DIRECTED_ORD_CT'] > right[timeframe + '_DIRECTED_ORD_CT'] - right[timeframe + '_STAGED_DIRECTED_ORD_CT'] ? 1 : -1
    }
    if (sortDirectionAssoc === 'descending') {
      result *= -1
    }
    return result
  })

  let dataPagination = dataSort.slice(rowsPerPage * (pageNum - 1), parseInt(Math.min(rowsPerPage * pageNum, dataSort.length)))

  let dataPaginationAssoc = dataSortAssoc.slice(rowsPerPageAssoc * (pageNumAssoc - 1), parseInt(Math.min(rowsPerPageAssoc * pageNumAssoc, dataSortAssoc.length)))


  
  return (
    <>

<Grid>
        <Grid.Row>&nbsp;</Grid.Row>
      </Grid>
      <Menu compact size='small'>
        <Menu.Item
          name='ORDER'
          active={orderOrAssoc === 'ORDER'}
          content='Non Compliant Orders'
          onClick={() => {
            setOrderOrAssoc('ORDER')
          }}
        />
        <Menu.Item
          name='ASSOC'
          active={orderOrAssoc === 'ASSOC'}
          content='Associate View'
          onClick={() => {
            setOrderOrAssoc('ASSOC')
          }}
        />
      </Menu>

      {orderOrAssoc === 'ORDER' &&
        <>
          {dataSort.length > rowsPerPage && <Grid>
            <Grid.Column width={16} textAlign='center'>
              <Grid.Row>&nbsp;</Grid.Row>
              <Grid.Row>
                <Pagination
                  size='mini'
                  defaultActivePage={1}
                  totalPages={Math.floor((dataSort.length - 1) / rowsPerPage) + 1}
                  activePage={pageNum}
                  boundaryRange={0}
                  siblingRange={1}
                  onPageChange={(e, { activePage }) => {
                    setPageNum(activePage)
                  }}
                />
              </Grid.Row>
              <Grid.Row>&nbsp;</Grid.Row>
            </Grid.Column>
          </Grid>}
          <Header textAlign='center'>Non Compliant Orders</Header>
          <Table textAlign='center' celled sortable unstackable size='small' style={{ fontSize: 10 }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                  sorted={sortColumn === 'EXTN_HOST_ORDER_REF' ? sortDirection : null}
                  onClick={() => {
                    if (sortColumn !== 'EXTN_HOST_ORDER_REF') {
                      setSortColumn('EXTN_HOST_ORDER_REF')
                      setSortDirection('ascending')
                    } else {
                      sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                    }
                  }}>Order #</Table.HeaderCell>
                <Table.HeaderCell
                  style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                  sorted={sortColumn === 'USER_ID' ? sortDirection : null}
                  onClick={() => {
                    if (sortColumn !== 'USER_ID') {
                      setSortColumn('USER_ID')
                      setSortDirection('ascending')
                    } else {
                      sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                    }
                  }}>User ID</Table.HeaderCell>
                <Table.HeaderCell
                  style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                  sorted={sortColumn === 'ASSOC_NM' ? sortDirection : null}
                  onClick={() => {
                    if (sortColumn !== 'ASSOC_NM') {
                      setSortColumn('ASSOC_NM')
                      setSortDirection('ascending')
                    } else {
                      sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                    }
                  }}>Associate Name</Table.HeaderCell>
                <Table.HeaderCell
                  style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                  sorted={sortColumn === 'DIRECTED_LOCATION' ? sortDirection : null}
                  onClick={() => {
                    if (sortColumn !== 'DIRECTED_LOCATION') {
                      setSortColumn('DIRECTED_LOCATION')
                      setSortDirection('ascending')
                    } else {
                      sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                    }
                  }}>Directed Location</Table.HeaderCell>
                <Table.HeaderCell
                  style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                  sorted={sortColumn === 'STAGED_LOCATION' ? sortDirection : null}
                  onClick={() => {
                    if (sortColumn !== 'STAGED_LOCATION') {
                      setSortColumn('STAGED_LOCATION')
                      setSortDirection('ascending')
                    } else {
                      sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                    }
                  }}>Staged Location</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body singleLine>
              {dataPagination.map((data) => {
                return (
                  <Table.Row>
                    <Table.Cell>{data['EXTN_HOST_ORDER_REF']}</Table.Cell>
                    <Table.Cell>{data['USER_ID']}</Table.Cell>
                    <Table.Cell>{data['ASSOC_NM']}</Table.Cell>
                    <Table.Cell>{data['DIRECTED_LOCATION']}</Table.Cell>
                    <Table.Cell>{data['STAGED_LOCATION']}</Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
        </>
      }
      {orderOrAssoc === 'ASSOC' &&
        <>
          {dataSortAssoc.length > rowsPerPageAssoc && <Grid>
            <Grid.Column width={16} textAlign='center'>
              <Grid.Row>&nbsp;</Grid.Row>
              <Grid.Row>
                <Pagination
                  size='mini'
                  defaultActivePage={1}
                  totalPages={Math.floor((dataSortAssoc.length - 1) / rowsPerPageAssoc) + 1}
                  activePage={pageNumAssoc}
                  boundaryRange={0}
                  siblingRange={1}
                  onPageChange={(e, { activePage }) => {
                    setPageNumAssoc(activePage)
                  }}
                />
              </Grid.Row>
              <Grid.Row>&nbsp;</Grid.Row>
            </Grid.Column>
          </Grid>}
          <Header textAlign='center'>DS Compliance by Associate</Header>
          <Table textAlign='center' celled sortable unstackable size='small' style={{ fontSize: 10 }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                  sorted={sortColumnAssoc === 'USER_ID' ? sortDirectionAssoc : null}
                  onClick={() => {
                    if (sortColumnAssoc !== 'USER_ID') {
                      setSortColumnAssoc('USER_ID')
                      setSortDirectionAssoc('ascending')
                    } else {
                      sortDirectionAssoc === 'ascending' ? setSortDirectionAssoc('descending') : setSortDirectionAssoc('ascending')
                    }
                  }}>User ID</Table.HeaderCell>
                <Table.HeaderCell
                  style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                  sorted={sortColumnAssoc === 'ASSOC_NM' ? sortDirectionAssoc : null}
                  onClick={() => {
                    if (sortColumnAssoc !== 'ASSOC_NM') {
                      setSortColumnAssoc('ASSOC_NM')
                      setSortDirectionAssoc('ascending')
                    } else {
                      sortDirectionAssoc === 'ascending' ? setSortDirectionAssoc('descending') : setSortDirectionAssoc('ascending')
                    }
                  }}>Associate Name</Table.HeaderCell>
                <Table.HeaderCell
                  style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                  sorted={sortColumnAssoc === 'DS_COMPLIANCE_PCT' ? sortDirectionAssoc : null}
                  onClick={() => {
                    if (sortColumnAssoc !== 'DS_COMPLIANCE_PCT') {
                      setSortColumnAssoc('DS_COMPLIANCE_PCT')
                      setSortDirectionAssoc('ascending')
                    } else {
                      sortDirectionAssoc === 'ascending' ? setSortDirectionAssoc('descending') : setSortDirectionAssoc('ascending')
                    }
                  }}>DS Compliance %</Table.HeaderCell>
                <Table.HeaderCell
                  style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                  sorted={sortColumnAssoc === 'NUM_NON_COMPLIANT' ? sortDirectionAssoc : null}
                  onClick={() => {
                    if (sortColumnAssoc !== 'NUM_NON_COMPLIANT') {
                      setSortColumnAssoc('NUM_NON_COMPLIANT')
                      setSortDirectionAssoc('ascending')
                    } else {
                      sortDirectionAssoc === 'ascending' ? setSortDirectionAssoc('descending') : setSortDirectionAssoc('ascending')
                    }
                  }}># Non-Compliant Orders</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body singleLine>
              {dataPaginationAssoc.map((data) => {
                return (
                  <Table.Row>
                    <Table.Cell>{data['USER_ID']}</Table.Cell>
                    <Table.Cell>{data['ASSOC_NM']}</Table.Cell>
                    <Table.Cell>{getPercentString(data[timeframe + '_STAGED_DIRECTED_ORD_CT'] / data[timeframe + '_DIRECTED_ORD_CT'])}</Table.Cell>
                    <Table.Cell>{data[timeframe + '_DIRECTED_ORD_CT'] - data[timeframe + '_STAGED_DIRECTED_ORD_CT']}</Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
        </>
      }
    </>
  )
}