import React, { useState } from 'react'
import { Icon, Grid, Table, Tab, Header } from 'semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'

import { UnitMenu } from '../Common/UnitMenu'
import { getCompareStyle, getDollarString, getPercentString } from '../Common/TableCellUtils'

export const DeptsByDstTab = withRouter(({ dstNbr, dstNm, data, timeframe }) => {

  const [sortColumn, setSortColumn] = useState("DEPT_NBR")
  const [sortDirection, setSortDirection] = useState("ascending")
  const [unit, setUnit] = useState("NOM")

  let dataSorted = data.sort((left, right) => {
    let result = left[sortColumn] - right[sortColumn]
    if (sortColumn === 'DEPT_NBR') {
      result = left.DEPT_NBR > right.DEPT_NBR ? 1 : -1
    }
    if (sortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  return (
    <Tab.Pane attached={false}>
      <Grid>
        <Grid.Column>
          <Grid.Row>Units:</Grid.Row>
          <UnitMenu unit={unit} setUnit={setUnit} />
        </Grid.Column>
      </Grid>
      <Header textAlign='center'>District {dstNbr}, {dstNm}, Departments</Header>
      <Grid>
        <Grid.Column>
          <Grid.Row>
            <Table textAlign='center' unstackable celled sortable size='small' style={{ fontSize: 12 }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    sorted={sortColumn === 'DEPT_NBR' ? sortDirection : null}
                    style={{whiteSpace: "normal",wordWrap: 'break-word'}}
                    onClick={() => {
                      if (sortColumn !== 'DEPT_NBR') {
                        setSortColumn('DEPT_NBR')
                        setSortDirection('ascending')
                      } else {
                        sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                      }
                    }}>
                    DEPT {sortColumn !== `DEPT_NBR` && <Icon fitted name='sort' />}
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={sortColumn === timeframe + '_SLS_AMT_ACT' ? sortDirection : null}
                    style={{whiteSpace: "normal",wordWrap: 'break-word'}}
                    onClick={() => {
                      if (sortColumn !== timeframe + '_SLS_AMT_ACT') {
                        setSortColumn(timeframe + '_SLS_AMT_ACT')
                        setSortDirection('ascending')
                      } else {
                        sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                      }
                    }}>
                    Actual {sortColumn !== `${timeframe}_SLS_AMT_ACT` && <Icon fitted name='sort' />}
                  </Table.HeaderCell>
                  {unit === 'NOM' && <Table.HeaderCell
                    sorted={sortColumn === timeframe + '_SLS_VP' ? sortDirection : null}
                    style={{whiteSpace: "normal",wordWrap: 'break-word'}}
                    onClick={() => {
                      if (sortColumn !== timeframe + '_SLS_VP') {
                        setSortColumn(timeframe + '_SLS_VP')
                        setSortDirection('ascending')
                      } else {
                        sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                      }
                    }}>
                    vP {sortColumn !== `${timeframe}_SLS_VP` && <Icon fitted name='sort' />}
                  </Table.HeaderCell>}
                  {unit === 'PCT' && <Table.HeaderCell
                    sorted={sortColumn === timeframe + '_SLS_AMT_VP_PCT' ? sortDirection : null}
                    style={{whiteSpace: "normal",wordWrap: 'break-word'}}
                    onClick={() => {
                      if (sortColumn !== timeframe + '_SLS_AMT_VP_PCT') {
                        setSortColumn(timeframe + '_SLS_AMT_VP_PCT')
                        setSortDirection('ascending')
                      } else {
                        sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                      }
                    }}>
                    vP % {sortColumn !== `${timeframe}_SLS_AMT_VP_PCT` && <Icon fitted name='sort' />}
                  </Table.HeaderCell>}
                  {unit === 'NOM' && <Table.HeaderCell
                    sorted={sortColumn === timeframe + '_SLS_AMT_VLY' ? sortDirection : null}
                    style={{whiteSpace: "normal",wordWrap: 'break-word'}}
                    onClick={() => {
                      if (sortColumn !== timeframe + '_SLS_AMT_VLY') {
                        setSortColumn(timeframe + '_SLS_AMT_VLY')
                        setSortDirection('ascending')
                      } else {
                        sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                      }
                    }}>
                    vLY {sortColumn !== `${timeframe}_SLS_AMT_VLY` && <Icon fitted name='sort' />}
                  </Table.HeaderCell>}
                  {unit === 'PCT' && <Table.HeaderCell
                    sorted={sortColumn === timeframe + '_SLS_AMT_VLY_PCT' ? sortDirection : null}
                    style={{whiteSpace: "normal",wordWrap: 'break-word'}}
                    onClick={() => {
                      if (sortColumn !== timeframe + '_SLS_AMT_VLY_PCT') {
                        setSortColumn(timeframe + '_SLS_AMT_VLY_PCT')
                        setSortDirection('ascending')
                      } else {
                        sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                      }
                    }}>
                    vLY % {sortColumn !== `${timeframe}_SLS_AMT_VLY_PCT` && <Icon fitted name='sort' />}
                  </Table.HeaderCell>}
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {dataSorted.map((data) => {
                  return (
                    <Table.Row>
                      <Table.Cell>
                        <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/district/sales/dept?dstNbr=${dstNbr}&deptNbr=${data['DEPT_NBR']}`}>
                          {data['MER_DEPT_NM'].replaceAll('/', '/\u200b')}
                        </Link>
                        {/* {data['MER_DEPT_NM'].replaceAll('/', '/\u200b')} */}
                      </Table.Cell>
                      <Table.Cell singleLine>
                        {getDollarString(data[timeframe + '_SLS_AMT_ACT'])}
                      </Table.Cell>
                      {unit === 'NOM' && <Table.Cell singleLine style={getCompareStyle(data[timeframe + '_SLS_VP'])}>
                        {getDollarString(data[timeframe + '_SLS_VP'])}
                      </Table.Cell>}
                      {unit === 'PCT' && <Table.Cell singleLine style={getCompareStyle(data[timeframe + '_SLS_AMT_VP_PCT'])}>
                        {getPercentString(data[timeframe + '_SLS_AMT_VP_PCT'])}
                      </Table.Cell>}
                      {unit === 'NOM' && <Table.Cell singleLine style={getCompareStyle(data[timeframe + '_SLS_AMT_VLY'])}>
                        {getDollarString(data[timeframe + '_SLS_AMT_VLY'])}
                      </Table.Cell>}
                      {unit === 'PCT' && <Table.Cell singleLine style={getCompareStyle(data[timeframe + '_SLS_AMT_VLY_PCT'])}>
                        {getPercentString(data[timeframe + '_SLS_AMT_VLY_PCT'])}
                      </Table.Cell>}
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>
          </Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
        </Grid.Column>
      </Grid>
    </Tab.Pane>
  )
})