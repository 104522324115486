import { SkuTable } from './SkuTable';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import useQueryApi from '../../hooks/useQueryApi';
import { useFetchLevelName } from '../../hooks/useFetchLevelName';
import { useQueryParams } from '../../hooks/useQueryParams';
import { Breadcrumb } from '../Common/Breadcrumb';
import { Header, Loader, Dropdown, Grid } from 'semantic-ui-react';
import { SubClassesTable } from './SubClassesTable';
import { TabMenu } from '../Common/TabMenu';
import { TimeframeDropdown } from '../Common/TimeframeDropdown';
import { capitalizeFirstLetter } from '../../utils/string';
import { getParamByLevel } from '../../utils/timeFrameUtils';
import { timeframeCode } from '../Common/timeframeCode';

const SUB_CLASSES_ENDPOINTS_BY_LEVEL = {
  store: 'querySlsSclsByStrCls',
  district: 'querySlsSclsByDstCls',
  region: 'querySlsSclsByRgnCls',
  division: 'querySlsSclsByDivCls'
};

const SKU_ENDPOINTS_BY_LEVEL = {
  store: 'querySlsSkuByStrCls',
  district: 'querySlsSkuByDstCls',
  region: 'querySlsSkuByRgnCls',
  division: 'querySlsSkuByDivCls'
};

export const ClassDrilldown = ({ timeframe, settimeframe, fpToggle }) => {
  const [metric, setMetric] = useState('SLS');
  const { level } = useParams();
  const { strNbr = 0, dstNbr = 0, rgnNbr = 0, divNbr = 0, classNbr = 0 } = useQueryParams();
  const orgNbr = strNbr || dstNbr || rgnNbr || divNbr || 0;
  const { currentLevelName: levelName, isLoadingName } = useFetchLevelName({ level, locNbr: orgNbr, fpToggle });

  const {
    data: skuData,
    isError: skuIsError,
    isLoading: skuIsLoading
  } = useQueryApi(SKU_ENDPOINTS_BY_LEVEL[level], { locNbr: orgNbr, classNbr, fpToggle });

  const {
    data: subClassesData,
    isError: subClassesIsError,
    isLoading: subClassesIsLoading
  } = useQueryApi(SUB_CLASSES_ENDPOINTS_BY_LEVEL[level], { locNbr: orgNbr, classNbr, fpToggle });

  if (subClassesIsError || skuIsError) return <p>Something went wrong.</p>;
  if (subClassesIsLoading || skuIsLoading || isLoadingName) return <Loader active>Loading...</Loader>;

  let deptNbr = classNbr.toString().substring(1, 3);
  const deptNm = getDeptName(subClassesData, skuData, deptNbr);
  const classNm = getClassName(subClassesData, skuData, classNbr);

  const allSkus = skuData.filter(row => row && row['TRNSFM_TYP_SORT_ID'] === timeframeCode[timeframe]);
  const allSubclasses = subClassesData.filter(row => row && row['TRNSFM_TYP_SORT_ID'] === timeframeCode[timeframe]);

  const paramByLevel = getParamByLevel(level);
  const breadcrumbItems = [
    {
      text: 'Overview',
      to: `/${level}/overview?${paramByLevel}=${orgNbr}`
    },
    {
      text: deptNm,
      to: `/${level}/sales/dept?${paramByLevel}=${orgNbr}&deptNbr=${deptNbr}`
    },
    { text: classNm }
  ];

  const panes = [
    {
      menuItem: 'Subclasses',
      render: () => <SubClassesTable subClassesList={allSubclasses} level={level} metric={metric} orgNbr={orgNbr} />
    },
    {
      menuItem: 'SKUs',
      render: () => <SkuTable metric={metric} skuList={allSkus} />
    }
  ];

  return (
    <>
      <Breadcrumb items={breadcrumbItems} />

      <Header textAlign='left' style={{ marginTop: '2.5rem', marginBottom: '2rem' }}>
        Overview - Sales - Subclasses - {capitalizeFirstLetter(level)} {orgNbr}, {levelName}
        <Header.Subheader>Data as of {allSubclasses[0]?.TRNSFM_DT}</Header.Subheader>
      </Header>

      <Grid>
        <Grid.Row>
          <Grid.Column textAlign='justified' width={8}>
            <Grid.Row>Metric:</Grid.Row>
            <Grid.Row>
              <Dropdown
                defaultValue={metric}
                selection
                scrolling
                compact
                size='small'
                options={[
                  { key: 'SLS', text: 'Sales', value: 'SLS' },
                  { key: 'UNT', text: 'Units', value: 'UNT' },
                  { key: 'TXN', text: 'Trans', value: 'TXN' },
                  { key: 'AVG_TCKT', text: 'Avg Ticket', value: 'AVG_TCKT' }
                ]}
                onChange={(_, { value }) => {
                  setMetric(value);
                }}
              />
            </Grid.Row>
          </Grid.Column>

          <Grid.Column width={8}>
            <Grid.Row>Timeframe:</Grid.Row>
            <Grid.Row>
              <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <TabMenu className='mt-3' color='orange' panes={panes} />
    </>
  );
};

const getDeptName = (subClassesData, skuData, deptNbr) => {
  if (subClassesData.length > 0) return subClassesData[0]['MER_DEPT_NM'];
  if (skuData.length > 0) return `${skuData[0]['DEPT_NBR']}-${skuData[0]['DEPT_NM']}`;
  return deptNbr;
};

const getClassName = (subClassesData, skuData, classNbr) => {
  if (subClassesData.length > 0) return subClassesData[0]['MER_CLASS_NM'];
  if (skuData.length > 0) return `${skuData[0]['EXT_CLASS_NBR']}-${skuData[0]['CLASS_DESC']}`;
  return classNbr;
};
