import React, { useState, useEffect } from 'react'
import {
  Header,
  Grid,
  Loader,
  Modal,
  Segment,
  Button,
  Form,
  TextArea,
  Tab
} from 'semantic-ui-react'

import { useQuery } from 'react-query'
import {
  getAdminData
} from '../../service/DataService'

export const PageTables = () => {

  const [open, setOpen] = useState(false)

  const panes = [
    { menuItem: 'Tables', render: () => 
      <Tab.Pane>
        <PG/>
      </Tab.Pane> },
  ]

  return (
      <Modal
        closeIcon
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={<Button size='mini' style={{color: 'white', backgroundColor: 'gray'}}>Page Tables</Button>}
      >
        <Segment color='orange'>
          <Header textAlign='center'>BQ Tables for this Page</Header>
          <Grid>
            <Grid.Column width={16} textAlign='left'>
              <Grid.Row>
                <Tab panes={panes}/>
              </Grid.Row>
            </Grid.Column>
          </Grid>
        </Segment>
      </Modal>
  )

}

// We have the actual PageTables as a seperate component so we don't load the data until the user clicks the button.

const PG = () => {

  const pageTablesQuery = useQuery(['getPageTables', window.location.href], getAdminData)

  if (pageTablesQuery.isLoading ) {
    return <Loader active>Loading</Loader>
  }

  const pageTables = pageTablesQuery.data
  .map(({projectId, datasetId, tableId}) => {
    return `${projectId}.${datasetId}.${tableId}`
  })
  .reduce((acc, curr) => acc + curr + '\n', '')

  return <pre>{pageTables}</pre>
  // return <p>Hello World</p>
}