import React from 'react';
import { Grid, Header, Loader } from 'semantic-ui-react';
import { Breadcrumb } from '../../../../../../components/Common/Breadcrumb';
import { TimeframeDropdownLimited } from '../../../../../../components/Common/TimeframeDropdownLimited';
import { TimeframeDropdownMonLimited } from '../../../../../../components/Common/TimeframeDropdownMonLimited';
import useQueryApi from '../../../../../../hooks/useQueryApi';
import { getLastTimeframe } from '../../../../../../utils/timeFrameUtils';
import { filterPurgeData, findItem } from '../../utils/osa/purge';
import PurgePackDownByDiv from '../../components/osa/PurgePackDownByDiv';
import SidekickExecution from '../../components/osa/SidekickExecution';

export const OSAPurge = ({ timeframe, settimeframe, fpToggle }) => {
  const param = { isCore: true, fpToggle };
  const { isLoading: isPurgeLoading, data: purgeData, error: purgeError } = useQueryApi(`querySidekickPurgeCore`, param);
  const {
    isLoading: exeScoresLoading,
    data: exeScores,
    error: exeScoresError
  } = useQueryApi(`queryPackDownByDivPurgeCore`, param);

  if (isPurgeLoading || exeScoresLoading) return <Loader active>Loading...</Loader>;
  if (purgeError || exeScoresError) return <Header textAlign='center'>Error loading purge data or execution scores</Header>;
  if (!purgeData || purgeData.length === 0) return <Header textAlign='center'>Invalid Store Number</Header>;

  const osaSideKickPurgeDstDataFilterByPurge = filterPurgeData(purgeData);
  const lastTimeFrame = getLastTimeframe(timeframe);

  const breadcrumbItems = [
    { to: `/core`, text: 'core' },
    { to: `/core/osa`, text: 'osa' },
    { to: `/core/osa/Purge`, text: 'Purge' }
  ];

  return (
    <>
      <Breadcrumb items={breadcrumbItems} />

      <Grid>
        <Grid.Column width={12} />
        <Grid.Column width={7}>
          Timeframe:
          {new Date().getDay() === 1 ? (
            <TimeframeDropdownMonLimited timeframe={timeframe} settimeframe={settimeframe} />
          ) : (
            <TimeframeDropdownLimited timeframe={timeframe} settimeframe={settimeframe} />
          )}
        </Grid.Column>
      </Grid>
      <SidekickExecution
        ltf={lastTimeFrame}
        data={osaSideKickPurgeDstDataFilterByPurge}
        dataScores={exeScores}
        findItem={findItem}
        timeframe={timeframe}
        entityField='DIV_NM'
      />
      <PurgePackDownByDiv
        data={osaSideKickPurgeDstDataFilterByPurge}
        dataScores={exeScores}
        entityField='DIV_NBR'
        findItem={findItem}
        timeframe={timeframe}
      />
    </>
  );
};
