import { createContext, useMemo } from "react";
import { useQueryParams } from "../hooks/useQueryParams";
import { useQueries } from "react-query";
import { IntradayMenu } from "../components/HourlyDaily/IntradayMenu";
import { findBiggestObject } from "../utils/array";
import { INTRADAY_CONSTANTS } from "../constants/intradayConstant";
import { getData, handleFetch } from "../service/DataService";
import { useParams } from "react-router-dom";

export const IntradayContext = createContext();

const IntradayContextProvider = ({ fpToggle }) => {
  const { strNbr, dstNbr, rgnNbr, divNbr } = useQueryParams();
  const locNbr = strNbr || dstNbr || rgnNbr || divNbr;
  const { level } = useParams();

  // salesIntradayByLevel not required for Store level
  const results = useQueries([
    { queryKey: [`query/salesIntraday/${level}`, { locNbr, fpToggle }], queryFn: getData },
    { queryKey: [`query/salesIntradayByLevel/${level}`, { locNbr, fpToggle }], queryFn: getData, enabled: level !== 'store' },
    { queryKey: [`query/levelName/${level}/${locNbr}`, { fpToggle }], queryFn: handleFetch },
    { queryKey: [INTRADAY_CONSTANTS[level]['deptOverviewQuery'], { locNbr, fpToggle }], queryFn: getData }
  ])

  const isLoading = results.some((query) => query.isLoading);
  const isError = results.some((query) => query.isError);

  const [hourlySalesData, totalSalesByLevel, currentLevelName, deptOverviewQuery] = results
  const latestHourData = useMemo(() => {
    if (isLoading) return {};

    return findBiggestObject(hourlySalesData.data, 'SLS_HR') || {};
  }, [hourlySalesData.data, isLoading]);

  const value = {
    hourlySalesData: hourlySalesData.data,
    currentLevelName: currentLevelName?.data?.levelName?.toLowerCase() || false,
    deptOverviewQuery: deptOverviewQuery.data,
    latestHourData: latestHourData,
    totalSalesByLevel: totalSalesByLevel.data,
    isLoading,
    isError,
    level,
    locNbr
  }

  return (
    <IntradayContext.Provider value={value}>
      <IntradayMenu />
    </IntradayContext.Provider>
  );
}

export default IntradayContextProvider;
