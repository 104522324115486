import { useState } from 'react';
import { Menu, Tab } from 'semantic-ui-react';
import { UnitsSellMenu } from '../ UnitsSellMenu';
import { DataView } from '../../Common/DataView';
import { SkuDetails } from './SkuDetails';

export const RegionTab = ({ eventDtlData, eventSummaryData }) => {
  const [unitSale, setUnitSale] = useState('Units #');
  const isEventSales = unitSale === 'Sales $';

  const BASE_HEADERS = [
    { name: 'Sales' },
    { name: isEventSales ? 'Sell-Thru %' : 'Unit Sell-Thru %' },
    { name: `Residual ${isEventSales ? '$' : '#'}` }
  ];

  const totalEventDataObj = {
    data: [
      [
        {
          dollarValue: eventSummaryData['TOT_EVNT_SLS_AMT']
        },
        {
          pctValue: isEventSales
            ? eventSummaryData['TOT_ST_NUM'] / eventSummaryData['TOT_ST_DEN']
            : eventSummaryData['TOT_ST_NUM_UNITS'] / eventSummaryData['TOT_ST_DEN_UNITS']
        },
        {
          dollarValue: isEventSales ? eventSummaryData['TOT_EVNT_RESID'] : undefined,
          stringValue: isEventSales ? undefined : (eventSummaryData['TOT_RESID_UNITS'] || 0).toLocaleString('en-US')
        }
      ]
    ],
    headers: BASE_HEADERS
  };

  const coreEventDataObj = {
    data: [
      [
        {
          dollarValue: eventSummaryData['CORE_EVNT_SLS_AMT']
        },
        {
          pctValue: isEventSales
            ? eventSummaryData['ST_CORE_NUM'] / eventSummaryData['ST_CORE_DEN']
            : eventSummaryData['ST_CORE_NUM_UNITS'] / eventSummaryData['ST_CORE_DEN_UNITS']
        },
        {
          dollarValue: isEventSales ? eventSummaryData['CORE_RESID'] : undefined,
          stringValue: isEventSales ? undefined : (eventSummaryData['CORE_RESID_UNITS'] || 0).toLocaleString('en-US')
        }
      ]
    ],
    headers: BASE_HEADERS
  };

  const nonCoreEventDataObj = {
    data: [
      [
        {
          dollarValue: eventSummaryData['NC_EVNT_SLS_AMT']
        },
        {
          pctValue: isEventSales
            ? eventSummaryData['ST_NC_NUM'] / eventSummaryData['ST_NC_DEN']
            : eventSummaryData['ST_NC_NUM_UNITS'] / eventSummaryData['ST_NC_DEN_UNITS']
        },
        {
          dollarValue: isEventSales ? eventSummaryData['NC_RESID'] : undefined,
          stringValue: isEventSales ? undefined : (eventSummaryData['NC_RESID_UNITS'] || 0).toLocaleString('en-US')
        }
      ]
    ],
    headers: BASE_HEADERS
  };

  return (
    <Tab.Pane>
      <Menu compact size='small'>
        <UnitsSellMenu salesOrUnits={unitSale} setSalesOrUnits={setUnitSale} />
      </Menu>

      <DataView className='mt-4' testId='data-view-total-event'>
        <DataView.Title>Total Event</DataView.Title>
        <DataView.DataTable data={totalEventDataObj} />
      </DataView>

      <DataView className='mt-4' testId='data-view-core'>
        <DataView.Title>Core</DataView.Title>
        <DataView.DataTable data={coreEventDataObj} />
      </DataView>

      <DataView className='mt-4' testId='data-view-non-core'>
        <DataView.Title>Non-Core</DataView.Title>
        <DataView.DataTable data={nonCoreEventDataObj} />
      </DataView>

      <SkuDetails eventDtlData={eventDtlData} isEventSales={isEventSales} />
    </Tab.Pane>
  );
};
