import React from 'react'
// import {
//   Dropdown,
// } from 'semantic-ui-react'

export const TimeframeDropdownMonLimited = ({ timeframe, settimeframe }) => {

  var mondayTf2Tf = {
    'LW': 'WTD',
    'LLW': 'LW',
  }
  
  var tf2MondayTf = {
    'WTD': 'LW',
    'LW': 'LLW',
  }

  return (
      <div>
      <select
        name="timeframe"
        onChange={e => {
          settimeframe(mondayTf2Tf[e.target.value])
        }}
        value={tf2MondayTf[timeframe]}
        style={{ padding: 10 }}
      >
        <option value="LW" selected>LW</option>
        <option value="LLW">LLW</option>
      </select>
    </div>
  )
}