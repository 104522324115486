import { Header, Loader, Grid } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import { useQuery } from 'react-query'
import { getData } from '../../service/DataService'
import { getThousandDollarString } from '../Common/TableCellUtils'

import { DataTable } from '../Common/DataTable'
import { useQueryParams } from '../../hooks/useQueryParams'

export const InvClassDrilldown = ({ level, fpToggle}) => {

  const getLevelKey = {
    'store' : 'strNbr',
    'district' : 'dstNbr',
    'region' : 'rgnNbr',
    'division' : 'divNbr'
  }

  const INV_CLASS_QUERIES ={
    'store' : 'queryInvClsByStrDept',
    'district' : 'queryInvClsByDstDept',
    'region' : 'queryInvClsByRgnDept',
    'division' : 'queryInvClsByDivDept'
  }

  const INV_SUB_CLASS_QUERIES = {
    'store' : 'queryInvSclsByStrCls',
    'district' : 'queryInvSclsByDstCls',
    'region' : 'queryInvSclsByRgnCls',
    'division' : 'queryInvSclsByDivCls'
  }

  const { deptNbr: deptNbrInt = 0, dstNbr = 0, rgnNbr = 0, strNbr = 0, classNbr = 0, divNbr = 0 } = useQueryParams()
  const orgNbr = dstNbr || strNbr || rgnNbr || divNbr || 0

  const classInvQuery = useQuery([INV_CLASS_QUERIES[level], { locNbr: orgNbr, deptNbrInt, fpToggle }], getData)
  const subClassInvQuery = useQuery([INV_SUB_CLASS_QUERIES[level], { locNbr: orgNbr, classNbr, fpToggle }], getData)

  const classInventory = classInvQuery.data
  const subClassInventory = subClassInvQuery.data

  if (classInvQuery.isLoading || subClassInvQuery.isLoading) {
    return (<Loader active>Loading...</Loader>)
  }

  // Load, extract and sort class numbers
  let allClassSort = classInventory.sort((left, right) => {
    let result = left.CLASS_NM > right.CLASS_NM ? 1 : -1
    return result
  })

  let classNbrArr = []
  allClassSort.map((data) => {
    return classNbrArr.push(data['EXT_CLASS_NBR'].toString())
  })

  let classNmArr = []
  allClassSort.map((data) => {
    return classNmArr.push(data['CLASS_NM'])
  })

  let classNm = classNmArr[classNbrArr.indexOf(classNbr)]

  const dataObj ={
    headers:[
      { name: 'Subclass '},
      { name: 'Inv $ '},
      { name: 'ASW '},
      { name: 'WOS '},
      { name: 'ICE $ '},
    ],
    data: subClassInventory.map( row => ([
      {
        link : {
          to: `/${level}/inventory/dept/class/subclass`,
          params:{ 
            [getLevelKey[level]] : orgNbr, 
            deptNbr: deptNbrInt, 
            classNbr: classNbr, 
            subClassNbr: row['EXT_SUB_CLASS_NBR'] 
          },
        },
        stringValue: row['SUB_CLASS_NM'],
      },
      {
        orderBy: row['INV_AMT'],
        stringValue: getThousandDollarString(row['INV_AMT']),
      },
      {
        orderBy: row['ASW'],
        stringValue: getThousandDollarString(row['ASW']),
      },
      {
        decimals: 1,
        numValue: row['WOS'],
      },
      {
        orderBy: row['ICE_INV_AMT'],
        stringValue: getThousandDollarString(row['ICE_INV_AMT']),
      }
    ])),
    sortable: true,
    sortableDefault: 0,
  }

  return (
    <>
      <Header textAlign='center'>{classNm}</Header>
      {/* Buttons for adjacent classes */}
      <Grid>
        <Grid.Row fluid>
          <Grid.Column textAlign='left' width={8}>
            <Link
              to={`/${level}/inventory/dept/class?${getLevelKey[level]}=${orgNbr}&deptNbr=${deptNbrInt}&classNbr=${classNbrArr[(classNbrArr.indexOf(classNbr) + classNbrArr.length - 1) % classNbrArr.length]}`}
              style={{ color: '#EE7125', textDecoration: 'underline' }}>
              {classNmArr[(classNbrArr.indexOf(classNbr) + classNbrArr.length - 1) % classNbrArr.length]}
            </Link>

          </Grid.Column>
          <Grid.Column textAlign='right' width={8}>
            <Link
              to={`/${level}/inventory/dept/class?${getLevelKey[level]}=${orgNbr}&deptNbr=${deptNbrInt}&classNbr=${classNbrArr[(classNbrArr.indexOf(classNbr) + 1) % classNbrArr.length]}`}
              style={{ color: '#EE7125', textDecoration: 'underline' }}>
              {classNmArr[(classNbrArr.indexOf(classNbr) + 1) % classNbrArr.length]}
            </Link>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Grid>
        <Grid.Row>
          <Grid.Column width={8} textAlign='left'>
            <Grid.Row>&nbsp;</Grid.Row>
            <Grid.Row><Link style={{ color: '#EE7125' }} to={`/${level}/inventory/dept?${getLevelKey[level]}=${orgNbr}&deptNbr=${deptNbrInt}`} data-test='b2o-link'>Back to Department</Link></Grid.Row>

          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Header textAlign='center'>Inventory Detail</Header>

      <DataTable data={dataObj} />

    </>
  )

}