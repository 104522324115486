import PropTypes from 'prop-types';
import { Link as LinkRouterDom } from 'react-router-dom';

export const Link = ({ children, dataTest, text, to }) => {
  return (
    <LinkRouterDom style={{ color: '#EE7125' }} to={to} data-test={dataTest}>
      {children || text}
    </LinkRouterDom>
  );
};

Link.defaultProps = {
  dataTest: 'b2o-link'
};

Link.propTypes = {
  dataTest: PropTypes.string,
  text: PropTypes.string,
  to: PropTypes.string.isRequired
};
