import { useState } from 'react';
import { Breadcrumb } from '../../../../../../components/Common/Breadcrumb';
import { DataView } from '../../../../../../components/Common/DataView';
import { Dropdown, Header, Loader, Segment } from 'semantic-ui-react';
import { isFirstweekDay } from '../../utils/osa/osa';
import { LTPA, onlineVocDropdownItems, osaImaginBreadcrumbItems } from '../../utils/Ltpa/voc';
import { OnlineVoc } from '../../components/ltpa/OnlineVoc';
import { TimeframeDropdownMon } from '../../../../../../components/Common/TimeframeDropdownMon';
import { TimeframeDropdown } from '../../../../../../components/Common/TimeframeDropdown';
import { VocDetails } from '../../components/ltpa/VocDetails';
import useQueryApi from '../../../../../../hooks/useQueryApi';

export const LtpaOverviewPage = ({ timeframe, settimeframe, fpToggle }) => {
  const [metric, setMetric] = useState(LTPA);
  const defaultParams = { fpToggle, isCore: true };
  const onlineVocCoreSummary = useQueryApi('queryOnlineVocSmryCoreByCore', defaultParams);

  if (onlineVocCoreSummary.isLoading) return <Loader active>Loading...</Loader>;
  if (onlineVocCoreSummary.isError) return <Header textAlign='center'>Something went wrong fetching data.</Header>;

  let overviewDataFilter = onlineVocCoreSummary.data.filter(row => row && row['TIME_FRAME'] === timeframe);

  const dropdownProps = { timeframe, settimeframe };

  return (
    <section className='mb-4'>
      <Segment>
        <Breadcrumb items={osaImaginBreadcrumbItems} />

        <DataView className='mt-2'>
          <DataView.Controls className='align-items-center d-flex flex-column'>
            Timeframe:
            {isFirstweekDay ? <TimeframeDropdownMon {...dropdownProps} /> : <TimeframeDropdown {...dropdownProps} />}
          </DataView.Controls>

          <VocDetails dataFilter={overviewDataFilter} />
        </DataView>

        <DataView className='mt-4'>
          <DataView.Controls className='d-flex flex-column'>
            Select Metric:
            <Dropdown
              compact
              selection
              defaultValue={LTPA}
              options={onlineVocDropdownItems}
              onChange={(_, { value }) => setMetric(value)}
              style={{ width: '50%', minWith: '300px' }}
            />
          </DataView.Controls>

          <OnlineVoc onlineVocList={onlineVocCoreSummary.data} level='core' metric={metric} timeframe={timeframe} />
        </DataView>
      </Segment>
    </section>
  );
};
