import React, { useState } from 'react'
import {
  Grid,
  Header,
  Loader
} from 'semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'
import { TimeframeDropdown } from '../Common/TimeframeDropdown'
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon'
import { UnitMenu } from '../Common/UnitMenu'
import { useQuery } from 'react-query'
import { getData } from '../../service/DataService'
import { timeframeCode } from '../Common/timeframeCode'
import { DataTable } from '../Common/DataTable'
import { useQueryParams } from '../../hooks/useQueryParams'
import { skuStringFormatter } from '../../utils/formatter'
import { getCalloutStyle } from '../Common/TableCellUtils'

export const DeptLocBayDrilldown = withRouter(({ timeframe, settimeframe, fpToggle }) => {

  const [unit, setUnit] = useState('NOM')
  const {strNbr, deptNbr, aisle, bay} = useQueryParams(['strNbr', 'deptNbr', 'aisle', 'bay'])
  const deptNbrInt = deptNbr

  const locSkuDeptQuery = useQuery(['querySwmLocSkuDept', { strNbr, deptNbrInt, aisle, bay, fpToggle }], getData)


  if (locSkuDeptQuery.isLoading) {
    return (<Loader active>Loading...</Loader>)
  }

  const skuDataFilter = locSkuDeptQuery.data.filter((row) => {
    return (
      row &&
      row['TRNSFM_TYP_SORT_ID'] === timeframeCode[timeframe]
    )
  })


  const dataObj = {
    sortable: true,
    sortableDefault: 0,
    pagination: true,
    headers: [
      { name: 'SKU' },
      { name: 'SKU Description' },
      { name: 'Sales Amt' },
      { name: unit === 'NOM' ? 'Sales Comp $' : 'Sales Comp %' },
      { name: 'Unit Sales' },
      { name: unit === 'NOM' ? 'Unit Comp #' : 'Unit Comp %' },
    ],
    data: skuDataFilter.map(row => [
      { stringValue: skuStringFormatter(row['SKU_NBR']) },
      { stringValue: row['SKU_DESC'].replaceAll('(', '\u200b(') },
      {
        dollarValue: row['SLS_AMT'],
        customStyle: getCalloutStyle(row['SLS_AMT'])
      },
      unit === 'NOM' ?
        {
          dollarValue: row['SLS_COMP_NOM'],
          compareStyle: true,
        }
        : {
          pctValue: row['SLS_COMP_PCT'],
          compareStyle: true,
        },
      {
        numValue: row['UNT_SLS'],
        decimals: 0,
        customStyle: getCalloutStyle(row['UNT_SLS']),
      },
      unit === 'NOM' ?
        {
          numValue: row['UNT_COMP_NOM'],
          compareStyle: true
        }
        : {
          pctValue: row['UNT_COMP_PCT'],
          compareStyle: true
        },
    ])
  }


  return (
    <>
      <Grid>
        <Grid.Column width={8}>
          <Grid.Row><Link style={{ color: '#EE7125' }} to={`/store/deptLoc/aisle?strNbr=${strNbr}&deptNbr=${deptNbrInt}&aisle=${aisle}`} data-test='b2o-link'>Back to Aisle</Link></Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
          <Grid.Row>Units:</Grid.Row>
          <Grid.Row>
            <UnitMenu unit={unit} setUnit={setUnit} />
          </Grid.Row>

        </Grid.Column>
        <Grid.Column width={8}>
          <Grid.Row>&nbsp;</Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
          <Grid.Row>Timeframe:</Grid.Row>
          <Grid.Row>
            {new Date().getDay() === 1 ?
              <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} /> :
              <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />}
          </Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
        </Grid.Column>
      </Grid>

      <Header textAlign='center'>
        {!!skuDataFilter.length ? (`SKUs - ${deptNbrInt + "-" + skuDataFilter[0].DEPT_NM} - Bay ${bay}`) : ('Invalid or Unauthorized Department Number')}
      </Header>
      {!!skuDataFilter.length && <DataTable data={dataObj} />}
    </>
  )
})
