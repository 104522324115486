
import { useState } from 'react'
import { Header, Loader, Grid } from 'semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'
import { TimeframeDropdown } from '../Common/TimeframeDropdown'
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon'
import { UnitMenu } from '../Common/UnitMenu'

import { useQuery } from 'react-query'
import { getData } from '../../service/DataService'
import { timeframeCode } from '../Common/timeframeCode'
import { DataTable } from '../Common/DataTable'

export const BaysByStoreBayDrilldown = withRouter(({ timeframe, settimeframe, fpToggle, location }) => {
  const [unit, setUnit] = useState('NOM')
  const search = new URLSearchParams(location.search)
  const strNbr = search.has('strNbr') ? search.get('strNbr') : 0
  const aisle = search.has('bay') ? search.get('bay').slice(0,2) : 0
  const bay = search.has('bay') ? search.get('bay') : 0
  const locSkuDtlQuery = useQuery(['querySwmLocSkuDtl', { strNbr, aisle, bay, fpToggle }], getData)

  if (locSkuDtlQuery.isLoading) {
    return (<Loader active>Loading...</Loader>)
  }

  const skuDataFilter = locSkuDtlQuery.data.filter((row) => {
    return (
      row &&
      row['TRNSFM_TYP_SORT_ID'] === timeframeCode[timeframe]
    )
  })

  const dataObj = {
    sortable: true,
    sortableDefault: 0,
    pagination: true,
    headers: [
      { name: 'SKU' },
      { name: 'SKU Description' },
      { name: 'Sales Amt' },
      { name: unit === 'NOM' ? 'Sales Comp $' : 'Sales Comp %' },
      { name: 'Unit Sales' },
      { name: unit === 'NOM' ? 'Unit Comp $' : 'Unit Comp %' },
    ],
    data: skuDataFilter.map(row => [
      {
        stringValue: row['SKU_NBR']
      },
      {
        stringValue: row['SKU_DESC'].replaceAll('(', '\u200b(')
      },
      {
        dollarValue: row['SLS_AMT'],
        decimals: 2,
        customStyle: {
          color: row['SLS_AMT'] < 0 ? 'red' : 'black'
        }
      },
      unit === 'NOM' ? {
        dollarValue: row['SLS_COMP_NOM'],
        compareStyle: true,
        compareStyleInverter: true,
        decimals: 2,
      } : {
        pctValue: row['SLS_COMP_PCT'],
        compareStyle: true,
        compareStyleInverter: true
      },
      {
        numValue: row['UNT_SLS'],
        customStyle: {
          color: row['UNT_SLS'] < 0 ? 'red' : 'black'
        }
      },
      unit === 'NOM' ? {
        numValue: row['UNT_COMP_NOM'],
        compareStyle: true,
        compareStyleInverter: true
      } : {
        pctValue: row['UNT_COMP_PCT'],
        compareStyle: true,
        compareStyleInverter: true
      },
    ])
  }

  return (
    <>
      <Header textAlign='center'>Bay {bay}</Header>

      <Grid>
        <Grid.Column width={8}>
          <Grid.Row><Link style={{ color: '#EE7125' }} to={`/store/baysbystore?strNbr=${strNbr}`} >Back to Top Bays</Link></Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
          <Grid.Row>Units:</Grid.Row>
          <Grid.Row>
            <UnitMenu unit={unit} setUnit={setUnit} />
          </Grid.Row>

        </Grid.Column>
        <Grid.Column width={8}>
          <Grid.Row>&nbsp;</Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
          <Grid.Row>Timeframe:</Grid.Row>
          <Grid.Row>
            {new Date().getDay() === 1 ?
              <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} /> :
              <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />}
          </Grid.Row>
        </Grid.Column>
      </Grid>

      <Grid>
        <Grid.Row>
          <Grid.Column width={16} textAlign='center'>
            <Header textAlign='center'>All SKUs</Header>

            <DataTable data={dataObj}/>

          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
})