import React from 'react'
import {
  Menu,
} from 'semantic-ui-react'

export const UnitMenu = ({unit, setUnit}) => {
  return (
    <Menu compact data-test="unitMenu" size='small'>
      <Menu.Item
        name='NOM'
        active={unit === 'NOM'}
        content='#'
        onClick={() => {
          setUnit('NOM')
        }}
      />
      <Menu.Item
        name='PCT'
        active={unit === 'PCT'}
        content='%'
        onClick={() => {
          setUnit('PCT')
        }}
      />
    </Menu>
  )
}
