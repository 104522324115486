import { useState } from 'react';
import { Menu } from 'semantic-ui-react';
import { DataView } from '../../Common/DataView';
import { UnitsSellMenu } from '../ UnitsSellMenu';

export const TotalEvents = ({ events, level }) => {
  const [salesOrUnits, setSalesOrUnits] = useState('Units #');
  const previousLevel = level === 'division' ? 'region' : 'district';

  const dataObj = {
    headers: [
      { name: level === 'division' ? 'Region # ' : 'District # ' },
      { name: 'Sales ' },
      { name: salesOrUnits === 'Units #' ? 'Unit Sell-Thru % ' : 'Sell-Thru % ' },
      { name: salesOrUnits === 'Units #' ? 'Residual # ' : 'Residual $ ' }
    ],
    data: events.map(event => {
      const params =
        level === 'division'
          ? { rgnNbr: event['RGN_NBR'], evntId: event['EVNT_ID'] }
          : { dstNbr: event['DST_NBR'], evntId: event['EVNT_ID'] };

      return [
        {
          stringValue: level === 'division' ? event['RGN_NBR'] : event['DST_NBR'],
          link: {
            to: `/${previousLevel}/events/detail`,
            params: params
          }
        },
        { dollarValue: event['TOT_EVNT_SLS_AMT'] },
        {
          pctValue:
            salesOrUnits === 'Sales $'
              ? event['TOT_ST_NUM'] / event['TOT_ST_DEN']
              : event['TOT_ST_NUM_UNITS'] / event['TOT_ST_DEN_UNITS']
        },
        {
          stringValue: salesOrUnits === 'Units #' ? event['TOT_RESID_UNITS'].toLocaleString() : undefined,
          dollarValue: salesOrUnits === 'Sales $' ? event['TOT_EVNT_RESID'] : undefined
        }
      ];
    }),
    sortable: true,
    pagination: true,
    sortableDefault: 0
  };

  return (
    <DataView>
      <DataView.Controls>
        <Menu compact size='small' style={{ textAlign: 'left' }}>
          <UnitsSellMenu salesOrUnits={salesOrUnits} setSalesOrUnits={setSalesOrUnits} />
        </Menu>
      </DataView.Controls>
      <DataView.Title>Total Event</DataView.Title>
      <DataView.DataTable data={dataObj} />
    </DataView>
  );
};
