import React, { useState } from 'react'
import {
  Table,
  Loader,
  Header,
  Segment,
  Grid,
  Icon,
} from 'semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'
import { UnitMenu } from '../Common/UnitMenu'
import { TimeframeDropdown } from '../Common/TimeframeDropdown'
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon'

import { useQuery } from 'react-query'
import {
  getData
} from '../../service/DataService'
import { getDollarString, getThousandDollarString, getPercentString, getSmdCompareStyle } from '../Common/TableCellUtils'

export const SmdStrDept = withRouter(({ timeframe, settimeframe, fpToggle, location }) => {



  const search = new URLSearchParams(location.search)
  const strNbr = search.has('strNbr') ? search.get('strNbr') : 0
  const deptNbrInt = search.has('deptNbr') ? search.get('deptNbr') : 0

  const [sortColumn, setSortColumn] = useState("MKUP_MKDN_RSN_CD")
  const [sortDirection, setSortDirection] = useState("ascending")

  const [unit, setUnit] = useState('NOM')

  const overviewQuery = useQuery(['querySmdDeptByStr', { strNbr, fpToggle }], getData)
  const rsnQuery = useQuery(['querySmdRsnByStrDept', { strNbr, deptNbrInt, fpToggle }], getData)

  if (overviewQuery.isLoading || rsnQuery.isLoading) {
    return <Loader active>Loading...</Loader>
  }

  let rsnCdFiltered = rsnQuery.data.filter(row => {
    return row && row['MKUP_MKDN_RSN_CD'] !== 7
  })

  let storeUseFilter = rsnQuery.data.filter(row => {
    return row && row['MKUP_MKDN_RSN_CD'] === 7
  })

  let rsnCdSorted = rsnCdFiltered.sort((left, right) => {
    let result = left[sortColumn] - right[sortColumn]
    if (sortColumn === 'MKUP_MKDN_RSN_DESC') {
      result = left.MKUP_MKDN_RSN_DESC > right.MKUP_MKDN_RSN_DESC ? 1 : -1
    }
    if (sortColumn === timeframe + '_SMD_PCT') {
      result = left[timeframe + '_SMD'] / left['TY_' + timeframe + '_SLS_AMT'] - right[timeframe + '_SMD'] / right['TY_' + timeframe + '_SLS_AMT']
    }
    if (sortColumn === timeframe + '_SMD_VLY_BPS') {
      result = (left[timeframe + '_SMD'] / left['TY_' + timeframe + '_SLS_AMT'] - left[timeframe + '_LY_SMD'] / left['LY_' + timeframe + '_SLS_AMT']) -
        (right[timeframe + '_SMD'] / right['TY_' + timeframe + '_SLS_AMT'] - right[timeframe + '_LY_SMD'] / right['LY_' + timeframe + '_SLS_AMT'])
    }
    if (sortColumn === timeframe + '_SMD_VLY_DOL') {
      result = (left[timeframe + '_SMD'] - left[timeframe + '_LY_SMD']) - (right[timeframe + '_SMD'] - right[timeframe + '_LY_SMD'])
    }
    if (sortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  let overviewFilter = overviewQuery.data.filter(row => {
    return row && row['DEPT_NBR'].toString() === deptNbrInt
  })[0]


  return (
    <>
      <Segment>
        <Grid>
          <Grid.Column width={8}>
            <Grid.Row>&nbsp;</Grid.Row>
            <Grid.Row>
              <Link style={{ color: '#EE7125' }} to={`/store/smd?strNbr=${strNbr}`} data-test='b2o-link'>Back to SMD Overview</Link>
            </Grid.Row>

          </Grid.Column>
          <Grid.Column width={8}>
            <Grid.Row>Timeframe:</Grid.Row>
            <Grid.Row>
              {new Date().getDay() === 1 ?
                <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} /> :
                <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />}
            </Grid.Row>
          </Grid.Column>
        </Grid>

        <Header textAlign='center'>{overviewFilter['DEPT_NBR'] + '-' + overviewFilter['DEPT_NM']}</Header>
        <Table textAlign='center' fixed unstackable sortable celled size='small'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>SMD $</Table.HeaderCell>
              <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>SMD %</Table.HeaderCell>
              <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>SMD bps vP</Table.HeaderCell>
              <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>SMD $ Over Under</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>{getThousandDollarString(overviewFilter[timeframe + '_SMD'], 1)} </Table.Cell>
              <Table.Cell>{getPercentString(overviewFilter[timeframe + '_SMD'] / overviewFilter['TY_' + timeframe + '_SLS_AMT'], 2)}</Table.Cell>
              <Table.Cell
                style={getSmdCompareStyle(overviewFilter[timeframe + '_SMD'] / overviewFilter['TY_' + timeframe + '_SLS_AMT'] - overviewFilter[timeframe + '_MKDN_PLN_AMT'] / overviewFilter[ timeframe + '_SLS_PLN_AMT'])}
              >{(10000 * (overviewFilter[timeframe + '_SMD'] / overviewFilter['TY_' + timeframe + '_SLS_AMT'] - overviewFilter[timeframe + '_MKDN_PLN_AMT'] / overviewFilter[timeframe + '_SLS_PLN_AMT'])).toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' bps'}
              </Table.Cell>
              <Table.Cell 
                style={getSmdCompareStyle(overviewFilter[timeframe + '_SMD'] - overviewFilter[timeframe + '_MKDN_PLN_AMT'] * overviewFilter['TY_' + timeframe + '_SLS_AMT'] / overviewFilter[timeframe + '_SLS_PLN_AMT'])}
              >{getThousandDollarString(overviewFilter[timeframe + '_SMD'] - overviewFilter[timeframe + '_MKDN_PLN_AMT'] * overviewFilter['TY_' + timeframe + '_SLS_AMT'] / overviewFilter[timeframe + '_SLS_PLN_AMT'], 1)}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <p>Note: Reason Code 7 - Store Use is not part of store markdowns plan and budget.</p>
        <Grid>
          <Grid.Column width={8}>
            <Grid.Row>Units:</Grid.Row>
            <Grid.Row>
              <UnitMenu unit={unit} setUnit={setUnit} />
            </Grid.Row>
          </Grid.Column>
        </Grid>
        &nbsp;
        <Header textAlign='center'>Reason Codes</Header>
        <Table textAlign='center' unstackable celled sortable size='small'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={sortColumn === 'MKUP_MKDN_RSN_CD' ? sortDirection : null}
                onClick={() => {
                  if (sortColumn !== 'MKUP_MKDN_RSN_CD') {
                    setSortColumn('MKUP_MKDN_RSN_CD')
                    setSortDirection('ascending')
                  } else {
                    sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                  }
                }}>
                Reason Code {sortColumn !== 'MKUP_MKDN_RSN_CD' && <Icon fitted name='sort' />}
              </Table.HeaderCell>
              {unit === 'NOM' && <Table.HeaderCell
                sorted={sortColumn === timeframe + '_SMD' ? sortDirection : null}
                onClick={() => {
                  if (sortColumn !== timeframe + '_SMD') {
                    setSortColumn(timeframe + '_SMD')
                    setSortDirection('ascending')
                  } else {
                    sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                  }
                }}>
                SMD $ {sortColumn !== timeframe + '_SMD' && <Icon fitted name='sort' />}
              </Table.HeaderCell>}
              {unit === 'PCT' && <Table.HeaderCell
                sorted={sortColumn === timeframe + '_SMD_PCT' ? sortDirection : null}
                onClick={() => {
                  if (sortColumn !== timeframe + '_SMD_PCT') {
                    setSortColumn(timeframe + '_SMD_PCT')
                    setSortDirection('ascending')
                  } else {
                    sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                  }
                }}>
                SMD % {sortColumn !== timeframe + '_SMD_PCT' && <Icon fitted name='sort' />}
              </Table.HeaderCell>}
              {unit === 'PCT' && <Table.HeaderCell
                sorted={sortColumn === timeframe + '_SMD_VLY_BPS' ? sortDirection : null}
                onClick={() => {
                  if (sortColumn !== timeframe + '_SMD_VLY_BPS') {
                    setSortColumn(timeframe + '_SMD_VLY_BPS')
                    setSortDirection('ascending')
                  } else {
                    sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                  }
                }}>
                vLY bps {sortColumn !== timeframe + '_SMD_VLY_BPS' && <Icon fitted name='sort' />}
              </Table.HeaderCell>}
              {unit === 'NOM' && <Table.HeaderCell
                sorted={sortColumn === timeframe + '_SMD_VLY_DOL' ? sortDirection : null}
                onClick={() => {
                  if (sortColumn !== timeframe + '_SMD_VLY_DOL') {
                    setSortColumn(timeframe + '_SMD_VLY_DOL')
                    setSortDirection('ascending')
                  } else {
                    sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                  }
                }}>
                vLY $ {sortColumn !== timeframe + '_SMD_VLY_DOL' && <Icon fitted name='sort' />}
              </Table.HeaderCell>}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {rsnCdSorted.map((data) => {
              return (
                <Table.Row>
                  <Table.Cell>
                    <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/smd/dept/reason?strNbr=${strNbr}&deptNbr=${data['DEPT_NBR']}&mkdnRsnCd=${data['MKUP_MKDN_RSN_CD']}`} >
                      {data['MKUP_MKDN_RSN_CD']}-{data['MKUP_MKDN_RSN_DESC'].toUpperCase()}
                    </Link>
                  </Table.Cell>
                  {unit === 'NOM' && <Table.Cell singleLine>{getDollarString(data[timeframe + '_SMD'])}</Table.Cell>}
                  {unit === 'PCT' && <Table.Cell singleLine>{getPercentString(data[timeframe + '_SMD'] / data['TY_' + timeframe + '_SLS_AMT'])}</Table.Cell>}
                  {unit === 'PCT' && <Table.Cell singleLine style={{
                    color:
                      data[timeframe + '_SMD'] / data['TY_' + timeframe + '_SLS_AMT'] - data[timeframe + '_LY_SMD'] / data['LY_' + timeframe + '_SLS_AMT'] < 0 ? 'green' :
                        data[timeframe + '_SMD'] / data['TY_' + timeframe + '_SLS_AMT'] - data[timeframe + '_LY_SMD'] / data['LY_' + timeframe + '_SLS_AMT'] > 0 ? 'red' :
                          'black'
                  }}>
                    {(10000 * (data[timeframe + '_SMD'] / data['TY_' + timeframe + '_SLS_AMT'] - data[timeframe + '_LY_SMD'] / data['LY_' + timeframe + '_SLS_AMT'])).toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' bps'}
                  </Table.Cell>}
                  {unit === 'NOM' && <Table.Cell singleLine style={{
                    color:
                      data[timeframe + '_SMD'] - data[timeframe + '_LY_SMD'] < 0 ? 'green' :
                        data[timeframe + '_SMD'] - data[timeframe + '_LY_SMD'] > 0 ? 'red' :
                          'black'
                  }}>
                    {getDollarString(data[timeframe + '_SMD'] - data[timeframe + '_LY_SMD'])}
                  </Table.Cell>}
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>

        <Header textAlign='center'>Reason Codes - Store Use</Header>
        <Table textAlign='center' unstackable celled size='small'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Reason Code </Table.HeaderCell>
              {unit === 'NOM' && <Table.HeaderCell>SMD $</Table.HeaderCell>}
              {unit === 'PCT' && <Table.HeaderCell>SMD %</Table.HeaderCell>}
              {unit === 'PCT' && <Table.HeaderCell>vLY bps</Table.HeaderCell>}
              {unit === 'NOM' && <Table.HeaderCell>vLY $</Table.HeaderCell>}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {storeUseFilter.map((data) => {
              return (
                <Table.Row>
                  <Table.Cell>
                    <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/smd/dept/reason?strNbr=${strNbr}&deptNbr=${data['DEPT_NBR']}&mkdnRsnCd=${data['MKUP_MKDN_RSN_CD']}`} >
                      {data['MKUP_MKDN_RSN_CD']}-{data['MKUP_MKDN_RSN_DESC'].toUpperCase()}
                    </Link>
                  </Table.Cell>
                  {unit === 'NOM' && <Table.Cell singleLine>{getDollarString(data[timeframe + '_SMD'])}</Table.Cell>}
                  {unit === 'PCT' && <Table.Cell singleLine>{getPercentString(data[timeframe + '_SMD'] / data['TY_' + timeframe + '_SLS_AMT'])}</Table.Cell>}
                  {unit === 'PCT' && <Table.Cell singleLine style={{
                    color:
                      data[timeframe + '_SMD'] / data['TY_' + timeframe + '_SLS_AMT'] - data[timeframe + '_LY_SMD'] / data['LY_' + timeframe + '_SLS_AMT'] < 0 ? 'green' :
                        data[timeframe + '_SMD'] / data['TY_' + timeframe + '_SLS_AMT'] - data[timeframe + '_LY_SMD'] / data['LY_' + timeframe + '_SLS_AMT'] > 0 ? 'red' :
                          'black'
                  }}>
                    {(10000 * (data[timeframe + '_SMD'] / data['TY_' + timeframe + '_SLS_AMT'] - data[timeframe + '_LY_SMD'] / data['LY_' + timeframe + '_SLS_AMT'])).toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' bps'}
                  </Table.Cell>}
                  {unit === 'NOM' && <Table.Cell singleLine style={{
                    color:
                      data[timeframe + '_SMD'] - data[timeframe + '_LY_SMD'] < 0 ? 'green' :
                        data[timeframe + '_SMD'] - data[timeframe + '_LY_SMD'] > 0 ? 'red' :
                          'black'
                  }}>
                    {getDollarString(data[timeframe + '_SMD'] - data[timeframe + '_LY_SMD'])}
                  </Table.Cell>}
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
        <p>Note: Reason Code 7 - Store Use is not part of store markdowns plan and budget.</p>
      </Segment>
    </>
  )
})