import { DeparmentsTab } from './Sales/DeparmentsTab';
import { SalesClassesTab } from './Sales/SalesClassesTab';
import { SalesSubClassesTab } from './Sales/SalesSubClassesTab';
import { SkusTab } from './Sales/SkusTab';

export const SALES_TAB = [
  {
    menuItem: 'Dept',
    render: () => <DeparmentsTab />
  },
  {
    menuItem: 'Classes',
    render: () => <SalesClassesTab />
  },
  { menuItem: 'Subclasses', 
    render: () => <SalesSubClassesTab /> 
  },
  {
    menuItem: 'Skus',
    render: () => <SkusTab />
  }
];
