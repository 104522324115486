import useQueryApi from "../../../hooks/useQueryApi";

export const useSalesDeptQueries = ({ fpToggle, deptNbrInt }) => {
  const {
    data: salesDeptData = [],
    isError: isErrorSalesDept,
    isLoading: isLoadingSalesDept
  } = useQueryApi(`query/deptSales/division`, { fpToggle, locNbr: '0001' });

  const {
    data: salesByDept,
    isLoading: isLoadingQuerySlsByDept,
    isError: isErrorQuerySalesByDept
  } = useQueryApi('queryCoreSlsByDept', { isCore: true, deptNbrInt, fpToggle })
 
  const {
    data: topBtmSalesData,
    isLoading: isLoadingTopBtmSales,
    isError: isErrorTopBtmSalesByDept
  } = useQueryApi('queryCoreSlsTopBtmByDept', { isCore: true, deptNbrInt, fpToggle })

  const isError = isErrorSalesDept || isErrorQuerySalesByDept || isErrorTopBtmSalesByDept;
  const isLoading = isLoadingSalesDept || isLoadingQuerySlsByDept || isLoadingTopBtmSales;

  return {
    isError,
    isLoading,
    salesDeptData,
    salesByDept,
    topBtmSalesData
  };
};
