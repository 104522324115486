import { createContext } from "react"
import { useSalesDeptQueries } from "../../../hooks/useSalesDeptQueries"
import { useQueryParams } from "../../../../../hooks/useQueryParams"
import { Header, Loader } from "semantic-ui-react"
import { CoreDeptDrillDown } from "../pages/CoreDeptDrillDown"

export const CoreSalesDepartmentContext = createContext()

export const CoreSalesDepartmentProvider = ({ fpToggle, timeframe, settimeframe }) => {
  const { deptNbr: deptNbrInt = 0 } = useQueryParams()
  const { salesByDept, isLoading, isError, salesDeptData, topBtmSalesData } = useSalesDeptQueries({ fpToggle, deptNbrInt })

  if (isError) return <Header textAlign="center">Something went wrong</Header>;
  if (isLoading) return <Loader active>Loading</Loader>;

  if (!salesByDept[0] || !salesDeptData[0] || !topBtmSalesData[0]) {
    return <Header textAlign="center" >Invalid or Unauthorized User</Header>
  }

  const value = {
    deptNbrInt,
    salesByDept,
    timeframe,
    settimeframe,
    salesDeptData,
    topBtmSalesData
  }

  return (
    <CoreSalesDepartmentContext.Provider value={value}>
      <CoreDeptDrillDown />
    </CoreSalesDepartmentContext.Provider>
  )
}
