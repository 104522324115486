import { BOPIS, BOSS, MIXED, rowByColumn, TOTAL, VOC_ROWS, vocOrdersReducer } from '../../utils/Ltpa/voc';
import { DataTable } from '../../../../../../components/Common/DataTable';
import { Header } from 'semantic-ui-react';

export const VocDetails = ({ dataFilter }) => {
  const orders = vocOrdersReducer(dataFilter);

  const boss = orders[BOSS] || [];
  const bopis = orders[BOPIS] || [];
  const mixed = orders[MIXED] || [];
  const total = orders[TOTAL] || [];

  const dataObjVocDetails = {
    sortable: false,
    headers: [{ name: '' }, { name: BOSS }, { name: BOPIS }, { name: MIXED }, { name: TOTAL }],
    data: VOC_ROWS.map((row, indexRow) => [
      { stringValue: row },
      { pctValue: rowByColumn(boss, indexRow) },
      { pctValue: rowByColumn(bopis, indexRow) },
      { pctValue: rowByColumn(mixed, indexRow) },
      { pctValue: rowByColumn(total, indexRow) }
    ])
  };
  return (
    <>
      <Header textAlign='center' style={{ textTransform: 'capitalize' }}>
        VOC Overview - Core
      </Header>
      <DataTable data={dataObjVocDetails} />
    </>
  );
};
