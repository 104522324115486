import React from 'react'
import { DataTable } from '../Common/DataTable'

export const AvgMinPickBopis = ({ chnlDtlData, timeframe }) => {

  let chnlDtlDataFilter = chnlDtlData.filter((row) => {
    return row && row['CHANNEL'] === 'BOPIS' && row[timeframe + '_FULFL_ORDERS'] > 0
  })


  const dataObj = {
    sortable: true,
    pagination: true,
    sortableDefault: 0,
    sortableColumns: [0, 1, 2, 3],
    headers: [
      { name: 'User ID' },
      {
        name: 'Associate Name',
        element: <label>Associate <br /> Name</label>
      },
      {
        name: 'Avg Mins to Pick',
        element: <label>Avg Mins <br />to Pick</label>
      },
      {
        element: <label>Fulfilled <br /> Orders</label>,
        name: 'Fulfilled Orders'
      }
    ],
    data: chnlDtlDataFilter.map(row => ([
      {
        stringValue: row['USER_ID']
      },
      {
        stringValue: row['ASSOC_NM']
      },
      { stringValue: row[timeframe + '_AVG_MINS_TO_PICK'] },
      { stringValue: row[timeframe + '_FULFL_ORDERS'] }
    ]))
  }


  return (
    <DataTable data={dataObj} />
  )
}