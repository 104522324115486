import { Tab } from 'semantic-ui-react';

export const TabMenu = ({ color, panes = [], ...props }) => {
  const baseMenuStyles = {
    attached: false,
    color,
    pointing: false,
    secondary: false,
    borderless: false,
    compact: true,
    size: 'small',
    widths: panes.length
  };

  return <Tab {...props} menu={baseMenuStyles} panes={panes} />;
};
