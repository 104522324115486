import { createContext, useState } from 'react';

import { Loader } from 'semantic-ui-react';

import { useLaborQuery } from '../hooks/useLaborQuery';

export const LaborDetailContext = createContext();

const LaborDetailContextProvider = ({ children, fpToggle, settimeframe, timeframe, userEmplCtgryNm, levelName, level }) => {
  const [days, setDays] = useState('Total');
  const { data, isError, isLoading, queryParam, laborDetails, laborDetailQuery } = useLaborQuery({
    fpToggle,
    timeframe,
    level
  });

  if (isError) return <p>Something went wrong</p>;
  if (isLoading) return <Loader active>Loading...</Loader>;

  const value = {
    dataLaborOverview: data,
    days,
    setDays,
    settimeframe,
    timeframe,
    isHourlyEmployee: userEmplCtgryNm.toUpperCase().includes('HOURLY'),
    queryParam,
    levelName,
    laborDetails,
    level,
    laborDetailQuery
  };

  return <LaborDetailContext.Provider value={value}>{children}</LaborDetailContext.Provider>;
};

export default LaborDetailContextProvider;
