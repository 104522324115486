import { Header, Loader, Grid, Segment } from 'semantic-ui-react'
import { Link, useLocation } from 'react-router-dom'
import { DataTable } from '../Common/DataTable'
import { TimeframeDropdown } from '../Common/TimeframeDropdown'
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon'

import { useQuery } from 'react-query'
import {
  getData
} from '../../service/DataService'

export const MeasAssocView = ({ fpToggle, settimeframe, timeframe }) => {
  const location = useLocation()
  const search = new URLSearchParams(location.search)
  const strNbr = search.has('strNbr') ? search.get('strNbr') : 0
  const ldap = search.has('ldap') ? search.get('ldap') : 0

  const measAssocDetailQuery = useQuery(['querySwmOpsMsAsscDtl', {strNbr, ldap, fpToggle}], getData)

  if(measAssocDetailQuery.isLoading) {
    return <Loader active>Loading...</Loader>
  }

  const dataFilter = measAssocDetailQuery.data.filter((row) => {
    return (
      row &&
      row[timeframe + '_MEAS_COUNT'] !== 0
    )
  })

  const dataObj = {
    data: dataFilter.map(row => ([
      { stringValue: row['SUMMARY_PROGRAM_NAME'] },
      { numValue: row[timeframe + '_MEAS_COUNT'] }
    ])),
    headers: [
      { name: 'Summary Program Name' },
      { name: 'Measures' }
    ],
    sortable: true,
    sortableDefault: 0,
  }

  return (
    <>
      <Segment>
        <Grid>
          <Grid.Column width={8} textAlign='justified'>
            <Grid.Row>&nbsp;</Grid.Row>
            <Grid.Row>
              <Link style={{color: '#EE7125'}} to={`/store/meas?strNbr=${strNbr}`}>Back to Measures</Link>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={8}>
            <Grid.Row>Timeframe:</Grid.Row>
            <Grid.Row>
            {new Date().getDay() === 1 ?
                    <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} /> :
                    <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />}
            </Grid.Row>
            <Grid.Row>&nbsp;</Grid.Row>
          </Grid.Column>
        </Grid>
      </Segment>
        <Segment>
          <Header textAlign='center'>Associate {ldap}'s Measures</Header>

          <DataTable data={dataObj} />
        </Segment>
    </>
  )
}
