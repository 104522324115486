import React, { useState } from 'react'
import {
  Loader,
  Header,
  Segment,
  Grid,
} from 'semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'
import { TimeframeDropdown } from '../Common/TimeframeDropdown'
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon'
import { UnitMenu } from '../Common/UnitMenu'
import { useQuery } from 'react-query'
import { DataTable } from '../Common/DataTable'
import {
  getData
} from '../../service/DataService'

export const LaborDetailDeptByDst = withRouter(({ timeframe, settimeframe, fpToggle, location }) => {
    const search = new URLSearchParams(location.search)
    const deptNbrInt = search.has("deptNbr") ? search.get("deptNbr") : 0;
    const dstNbr = search.has('dstNbr') ? search.get('dstNbr') : 0
    const [unit, setUnit] = useState('NOM')
    
    const laborDetailQuerys = useQuery(['queryLaborDtlByDeptStrDst', { dstNbr, fpToggle }], getData)

    if (laborDetailQuerys.isLoading) {
      return <Loader active>Loading...</Loader>
    }
  
    let laborDetailFilter = laborDetailQuerys.data.filter((row) => {
      if(row['DEPT_NBR'].toString() === deptNbrInt){
        return row && row['METRIC_LEVEL'] === 'STR'
      }
      return false
    })

    const useProjected = new Date().getDay() !== 1 && timeframe === "WTD"
    const laborDetailData = {
      sortable: true,
      headers: [
        {name: 'Store'},
        {name: 'Hours'},
        {
          name: useProjected ? 'PROJ O/T' : 'O/T',
        },
        {name: unit === 'PCT' ? 'AvF%' : 'AvF'},
        {name: unit === 'PCT' ? 'AvS%' : 'AvS'},
      ],
      
      data: laborDetailFilter.map(data => [
        {
          stringValue: data['STR_NBR'],
          link: {
              to: '/store/labor',
              params: {
                  strNbr: data['STR_NBR']
              }
          },
        },
        { numValue: data[timeframe + '_ACT_HRS'] || 0, decimals: 0 },
        { numValue: useProjected ? data[timeframe+'_PROJ_OT_HRS'] : data[timeframe + '_OT_HRS'] || 0, decimals: 0 },
        (unit === 'NOM' ? 
          { numValue: data[timeframe + '_ACT_HRS'] - data[timeframe + '_FCST_HRS'] || 0, decimals: 2 } : 
          {
            pctValue: (data[timeframe+'_ACT_HRS']-data[timeframe+'_FCST_HRS'])/data[timeframe+'_FCST_HRS'],
            compareStyle: true,
            decimals: 2
          }
        ),
        (unit === 'NOM' ? 
          { numValue: data[timeframe + '_ACT_HRS'] - data[timeframe + '_SCH_HRS'] || 0, decimals: 2 } : 
          {
            pctValue: (data[timeframe + '_ACT_HRS'] - data[timeframe + '_SCH_HRS'])/data[timeframe + '_SCH_HRS'],
            compareStyle: true,
            decimals: 2
          }
        )
      ]),
    }
   
    return (
      <Segment>
        <Grid>
          <Grid.Column width={7}>
            <Grid.Row>
              <Link style={{ color: '#EE7125' }} to={`/district/overview?dstNbr=${dstNbr}`} >Back to Overview</Link>
              {' / '}
              <Link style={{ color: '#EE7125' }} to={`/district/labor?dstNbr=${dstNbr}`} >Labor Overview</Link>
            </Grid.Row>
            <Grid.Row>&nbsp;</Grid.Row>
            <Grid.Row>&nbsp;</Grid.Row>
            <Grid.Row>Units:</Grid.Row>
            <Grid.Row>
              <UnitMenu unit={unit} setUnit={setUnit} />
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={9}>
            <Grid.Row>Timeframe:</Grid.Row>
            <Grid.Row>
              {new Date().getDay() === 1 ?
              <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} /> :
              <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />}
            </Grid.Row>
            <Grid.Row>&nbsp;</Grid.Row>
            <Grid.Row>
            </Grid.Row>
          </Grid.Column>
        </Grid>

        <Header textAlign='center'>Labor Detail -  D{deptNbrInt}</Header>
        <DataTable data={laborDetailData} unit={unit} />
      </Segment>
    )
})


