import React, { useState, useEffect } from 'react'
import { withRouter, Link } from 'react-router-dom'
import {
  Table,
  Header,
  Grid,
  Loader,
  Icon,
  Menu,
  Checkbox,
  Pagination,
  TableHeaderCell,
  Segment,
  Modal,
  Button,
  Tab
} from 'semantic-ui-react'

import { useQuery } from 'react-query'
import {
  getData
} from '../../service/DataService'
import { getDollarString, getPercentString } from '../Common/TableCellUtils'
import { UnitsSellMenu } from './ UnitsSellMenu'

export const EventsDetailDst = withRouter(({ fpToggle, location }) => {

  const [open, setOpen] = useState(false)
  const [modalData, setModalData] = useState([])

  const [deptNbr, setDeptNbr] = useState("All")
  const [core, setCore] = useState("CORE")
  const [zeroSaleOnly, setZeroSaleOnly] = useState(false)

  const [sortColumn, setSortColumn] = useState("TOT_SLS_AMT")
  const [sortDirection, setSortDirection] = useState("descending")
  const [sortColumnStr, setSortColumnStr] = useState("STR_NBR")
  const [sortDirectionStr, setSortDirectionStr] = useState("ascending")
  const [sortColumnCore, setSortColumnCore] = useState("STR_NBR")
  const [sortDirectionCore, setSortDirectionCore] = useState("ascending")
  const [sortColumnNC, setSortColumnNC] = useState("STR_NBR")
  const [sortDirectionNC, setSortDirectionNC] = useState("ascending")
  const [pageNum, setPageNum] = useState(1)
  let rowsPerPage = 20

  const [eventsSalesOrUnits, setEventsSalesOrUnits] = useState("Units #")
  const [salesOrUnits, setSalesOrUnits] = useState("Units #")
  const [salesOrUnitsCore, setSalesOrUnitsCore] = useState("Units #")
  const [salesOrUnitsNC, setSalesOrUnitsNC] = useState("Units #")

  const search = new URLSearchParams(location.search)
  const dstNbr = search.has('dstNbr') ? search.get('dstNbr') : 0
  const evntId = search.has('evntId') ? search.get('evntId') : 0
  const evntSumryQuery = useQuery(['queryEvntSumryByDstEvnt', { dstNbr, evntId, fpToggle }], getData)
  const evntDtlQuery = useQuery(['queryEvntDtlByDstEvnt', { dstNbr, evntId, fpToggle }], getData)
  const evntSmryByDstEvnt = useQuery(['queryEvntSmryByDstEvnt', { dstNbr, evntId, fpToggle }], getData);
  useEffect(() => {
    setPageNum(1)
  }, [sortColumn, sortDirection, deptNbr, core, zeroSaleOnly])

  if (evntDtlQuery.isLoading || evntSumryQuery.isLoading || evntSmryByDstEvnt.isLoading) {
    return <Loader active>Loading...</Loader>
  }

  if (!evntSumryQuery.data[0]) {
    return (<Header textAlign='center'>Invalid district number</Header>)
  }

  const evntSumryData = evntSumryQuery.data[0]
  const evntDtlData = evntDtlQuery.data
  const evntDstStrSum = evntSmryByDstEvnt.data
  let evntSumryTotalStrFilter = evntDstStrSum.filter((row) => {
    return row && row['METRIC_LEVEL'] === 'STR'
  })
  let evntSumryTotalCoreFilter = evntDstStrSum.filter((row) => {
    return row && row['METRIC_LEVEL'] === 'STR'
  })
  let evntSumryTotalNCFilter = evntDstStrSum.filter((row) => {
    return row && row['METRIC_LEVEL'] === 'STR'
  })

  let evntSumryStrSorted = evntSumryTotalStrFilter.sort((left, right) => {
    let result = left[sortColumnStr] - right[sortColumnStr]
    if (sortColumnStr === 'STR_NBR') {
      result = left.STR_NBR > right.STR_NBR ? 1 : -1
    }
    if (sortColumnStr === 'TOT_EVNT_SLS_AMT') {
      result = left.TOT_EVNT_SLS_AMT > right.TOT_EVNT_SLS_AMT ? 1 : -1
    }
    if (sortColumnStr === 'TOT_EVNT_SLS_UNITS') {
      result = left.TOT_EVNT_SLS_UNITS > right.TOT_EVNT_SLS_UNITS ? 1 : -1
    }
    if (sortColumnStr === 'TOT_ST_NUM') {
      result = left.TOT_ST_NUM / left.TOT_ST_DEN > right.TOT_ST_NUM / right.TOT_ST_DEN ? 1 : -1
    }
    if (sortColumnStr === 'TOT_ST_NUM_UNITS') {
      result = left.TOT_ST_NUM_UNITS / left.TOT_ST_DEN_UNITS > right.TOT_ST_NUM_UNITS / right.TOT_ST_DEN_UNITS ? 1 : -1
    }
    if (sortColumnStr === 'TOT_EVNT_RESID') {
      result = left.TOT_EVNT_RESID > right.TOT_EVNT_RESID ? 1 : -1
    }
    if (sortColumnStr === 'TOT_RESID_UNITS') {
      result = left.TOT_RESID_UNITS > right.TOT_RESID_UNITS ? 1 : -1
    }
    if (sortColumnStr === 'EVNT_EFF_BGN_DT') {
      result = left.EVNT_EFF_BGN_DT > right.EVNT_EFF_BGN_DT ? 1 : -1
    }
    if (sortColumnStr === 'EVNT_EFF_END_DT') {
      result = left.EVNT_EFF_END_DT > right.EVNT_EFF_END_DT ? 1 : -1
    }
    if (sortColumnStr === 'EVNT_NM') {
      result = left.EVNT_NM > right.EVNT_NM ? 1 : -1
    }

    if (sortDirectionStr === 'descending') {
      result *= -1
    }
    return result
  })

  let evntSumryCoreSorted = evntSumryTotalCoreFilter.sort((left, right) => {
    let result = left[sortColumnCore] - right[sortColumnCore]
    if (sortColumnCore === 'STR_NBR') {
      result = left.STR_NBR > right.STR_NBR ? 1 : -1
    }
    if (sortColumnCore === 'CORE_EVNT_SLS_AMT') {
      result = left.CORE_EVNT_SLS_AMT > right.CORE_EVNT_SLS_AMT ? 1 : -1
    }
    if (sortColumnCore === 'CORE_EVNT_SLS_UNITS') {
      result = left.CORE_EVNT_SLS_UNITS > right.CORE_EVNT_SLS_UNITS ? 1 : -1
    }
    if (sortColumnCore === 'ST_CORE_NUM') {
      result = left.ST_CORE_NUM / left.ST_CORE_DEN > right.ST_CORE_NUM / right.ST_CORE_DEN ? 1 : -1
    }
    if (sortColumnCore === 'ST_CORE_NUM_UNITS') {
      result = left.ST_CORE_NUM_UNITS / left.ST_CORE_DEN_UNITS > right.ST_CORE_NUM_UNITS / right.ST_CORE_DEN_UNITS ? 1 : -1
    }
    if (sortColumnCore === 'CORE_RESID') {
      result = left.CORE_RESID > right.CORE_RESID ? 1 : -1
    }
    if (sortColumnCore === 'CORE_RESID_UNITS') {
      result = left.CORE_RESID_UNITS > right.CORE_RESID_UNITS ? 1 : -1
    }

    if (sortDirectionCore === 'descending') {
      result *= -1
    }
    return result
  })

  let evntSumryNCSorted = evntSumryTotalNCFilter.sort((left, right) => {
    let result = left[sortColumnNC] - right[sortColumnNC]
    if (sortColumnNC === 'STR_NBR') {
      result = left.NC_NBR > right.NC_NBR ? 1 : -1
    }
    if (sortColumnNC === 'NC_EVNT_SLS_AMT') {
      result = left.NC_EVNT_SLS_AMT > right.NC_EVNT_SLS_AMT ? 1 : -1
    }
    if (sortColumnNC === 'NC_ECNT_SLS_UNITS') {
      result = left.NC_ECNT_SLS_UNITS > right.NC_ECNT_SLS_UNITS ? 1 : -1
    }
    if (sortColumnNC === 'ST_NC_NUM') {
      result = left.ST_NC_NUM / left.ST_NC_DEN > right.ST_NC_NUM / right.ST_NC_DEN ? 1 : -1
    }
    if (sortColumnNC === 'ST_NC_NUM_UNITS') {
      result = left.ST_NC_NUM_UNITS / left.ST_NC_DEN_UNITS > right.ST_NC_NUM_UNITS / right.ST_NC_DEN_UNITS ? 1 : -1
    }
    if (sortColumnNC === 'NC_RESID') {
      result = left.NC_RESID > right.NC_RESID ? 1 : -1
    }
    if (sortColumnNC === 'NC_RESID_UNITS') {
      result = left.NC_RESID_UNITS > right.NC_RESID_UNITS ? 1 : -1
    }

    if (sortDirectionNC === 'descending') {
      result *= -1
    }
    return result
  })

  let depts = []
  evntDtlData.map((data) => {
    return depts.includes(data['DEPT_NBR']) ? depts : depts.push(data['DEPT_NBR'])
  })

  let evntDtlDataFilter = evntDtlData.filter((row) => {
    return row &&
      (deptNbr === 'All' || row['DEPT_NBR'] === deptNbr) &&
      row['CORE_NC_FLG'] === core &&
      (row['ZERO_SALE_FLG'] === 1 || !zeroSaleOnly) // return all by default, only zero sales if box is checked
  })

  let evntDtlDataSorted = evntDtlDataFilter.sort((left, right) => {
    let result = left[sortColumn] - right[sortColumn]
    if (sortColumn === 'SKU_NBR') {
      result = left.SKU_NBR > right.SKU_NBR ? 1 : -1
    }
    if (sortColumn === 'SKU_DESC') {
      result = left.SKU_DESC > right.SKU_DESC ? 1 : -1
    }
    if (sortColumn === 'ST') {
      result = left.ST_NUM / left.ST_DEN > right.ST_NUM / right.ST_DEN ? 1 : -1
    }
    if (sortColumn === 'UST') {
      result = left.ST_NUM_UNITS / left.ST_DEN_UNITS > right.ST_NUM_UNITS / right.ST_DEN_UNITS ? 1 : -1
    }
    if (sortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  let dataPagination = evntDtlDataSorted.slice(rowsPerPage * (pageNum - 1), parseInt(Math.min(rowsPerPage * pageNum, evntDtlDataSorted.length)))

  const panes = [
    {
      menuItem: 'District',
      render: () => <Tab.Pane attached={false}>
        <Header textAlign='center'>{evntSumryData['EVNT_NM']}</Header>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8} textAlign='left'>
              <b>Begin: {evntSumryData['EVNT_EFF_BGN_DT']}</b>
            </Grid.Column>
            <Grid.Column width={8} textAlign='right'>
              <b>End: {evntSumryData['EVNT_EFF_END_DT']}</b>
            </Grid.Column>
            <Grid.Row>&nbsp;</Grid.Row>
          </Grid.Row>
        </Grid>
        <Menu compact size='small'>
          <UnitsSellMenu salesOrUnits={eventsSalesOrUnits} setSalesOrUnits={setEventsSalesOrUnits} />
        </Menu>
        <Header textAlign='center'>Total Event</Header>
        <Table textAlign='center' fixed unstackable celled size='small'>
          <Table.Header>
            <Table.Row>
              {<Table.HeaderCell>Sales</Table.HeaderCell>}
              {eventsSalesOrUnits === 'Sales $' && <Table.HeaderCell>Sell-Thru %</Table.HeaderCell>}
              {eventsSalesOrUnits === 'Units #' && <Table.HeaderCell>Unit Sell-Thru %</Table.HeaderCell>}
              {eventsSalesOrUnits === 'Sales $' && <Table.HeaderCell>Residual $</Table.HeaderCell>}
              {eventsSalesOrUnits === 'Units #' && <Table.HeaderCell>Residual #</Table.HeaderCell>}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              {<Table.Cell>{getDollarString(evntSumryData['TOT_EVNT_SLS_AMT'])}</Table.Cell>}
              {eventsSalesOrUnits === 'Sales $' && <Table.Cell>{getPercentString(evntSumryData['TOT_ST_NUM'] / evntSumryData['TOT_ST_DEN'])}</Table.Cell>}
              {eventsSalesOrUnits === 'Sales $' && <Table.Cell>{getDollarString(evntSumryData['TOT_EVNT_RESID'])}</Table.Cell>}
              {/* {eventsSalesOrUnits === 'Units #' && <Table.Cell>{evntSumryData['TOT_EVNT_SLS_UNITS']?evntSumryData['TOT_EVNT_SLS_UNITS'].toLocaleString("en-US"):0}</Table.Cell>} */}
              {eventsSalesOrUnits === 'Units #' && <Table.Cell>{getPercentString(evntSumryData['TOT_ST_NUM_UNITS'] / evntSumryData['TOT_ST_DEN_UNITS'])}</Table.Cell>}
              {eventsSalesOrUnits === 'Units #' && <Table.Cell>{evntSumryData['TOT_RESID_UNITS'] ? evntSumryData['TOT_RESID_UNITS'].toLocaleString("en-US") : 0}</Table.Cell>}
            </Table.Row>
          </Table.Body>
        </Table>
        <Header textAlign='center'>Core</Header>
        <Table textAlign='center' fixed unstackable celled size='small'>
          <Table.Header>
            <Table.Row>
              {<Table.HeaderCell>Sales</Table.HeaderCell>}
              {eventsSalesOrUnits === 'Sales $' && <Table.HeaderCell>Sell-Thru %</Table.HeaderCell>}
              {eventsSalesOrUnits === 'Units #' && <Table.HeaderCell>Unit Sell-Thru %</Table.HeaderCell>}
              {eventsSalesOrUnits === 'Sales $' && <Table.HeaderCell>Residual $</Table.HeaderCell>}
              {eventsSalesOrUnits === 'Units #' && <Table.HeaderCell>Residual #</Table.HeaderCell>}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              {<Table.Cell>{getDollarString(evntSumryData['CORE_EVNT_SLS_AMT'])}</Table.Cell>}
              {eventsSalesOrUnits === 'Sales $' && <Table.Cell>{getPercentString(evntSumryData['ST_CORE_NUM'] / evntSumryData['ST_CORE_DEN'])}</Table.Cell>}
              {eventsSalesOrUnits === 'Sales $' && <Table.Cell>{getDollarString(evntSumryData['CORE_RESID'])}</Table.Cell>}
              {/* {eventsSalesOrUnits === 'Units #' && <Table.Cell>{evntSumryData['CORE_EVNT_SLS_UNITS']?evntSumryData['CORE_EVNT_SLS_UNITS'].toLocaleString("en-US"):0}</Table.Cell>} */}
              {eventsSalesOrUnits === 'Units #' && <Table.Cell>{getPercentString(evntSumryData['ST_CORE_NUM_UNITS'] / evntSumryData['ST_CORE_DEN_UNITS'])}</Table.Cell>}
              {eventsSalesOrUnits === 'Units #' && <Table.Cell>{evntSumryData['CORE_RESID_UNITS'] ? evntSumryData['CORE_RESID_UNITS'].toLocaleString("en-US") : 0}</Table.Cell>}
            </Table.Row>
          </Table.Body>
        </Table>
        <Header textAlign='center'>Non-Core</Header>
        <Table textAlign='center' fixed unstackable celled size='small'>
          <Table.Header>
            <Table.Row>
              {<Table.HeaderCell>Sales</Table.HeaderCell>}
              {eventsSalesOrUnits === 'Sales $' && <Table.HeaderCell>Sell-Thru %</Table.HeaderCell>}
              {eventsSalesOrUnits === 'Units #' && <Table.HeaderCell>Unit Sell-Thru %</Table.HeaderCell>}
              {eventsSalesOrUnits === 'Sales $' && <Table.HeaderCell>Residual $</Table.HeaderCell>}
              {eventsSalesOrUnits === 'Units #' && <Table.HeaderCell>Residual #</Table.HeaderCell>}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              {<Table.Cell>{getDollarString(evntSumryData['NC_EVNT_SLS_AMT'])}</Table.Cell>}
              {eventsSalesOrUnits === 'Sales $' && <Table.Cell>{getPercentString(evntSumryData['ST_NC_NUM'] / evntSumryData['ST_NC_DEN'])}</Table.Cell>}
              {eventsSalesOrUnits === 'Sales $' && <Table.Cell>{getDollarString(evntSumryData['NC_RESID'])}</Table.Cell>}
              {/* {eventsSalesOrUnits === 'Units #' && <Table.Cell>{evntSumryData['NC_ECNT_SLS_UNITS']?evntSumryData['NC_ECNT_SLS_UNITS'].toLocaleString("en-US"):0}</Table.Cell>} */}
              {eventsSalesOrUnits === 'Units #' && <Table.Cell>{getPercentString(evntSumryData['ST_NC_NUM_UNITS'] / evntSumryData['ST_NC_DEN_UNITS'])}</Table.Cell>}
              {eventsSalesOrUnits === 'Units #' && <Table.Cell>{evntSumryData['NC_RESID_UNITS'] ? evntSumryData['NC_RESID_UNITS'].toLocaleString("en-US") : 0}</Table.Cell>}
            </Table.Row>
          </Table.Body>
        </Table>
        <Header textAlign='center'>SKU Detail</Header>
        <Grid>
          <Grid.Row>
            <Grid.Column textAlign='left' width={8}>
              Dept:
              <div>
                <select
                  name="depts"
                  onChange={e => {
                    setDeptNbr(e.target.value)
                  }}
                  value={deptNbr}
                  style={{ padding: 10 }}
                >
                  <option value="All" selected>All</option>
                  {depts.map((row) => {
                    return <option value={row}>{row}</option>
                  })}
                </select>
              </div>
            </Grid.Column>
            <Grid.Column textAlign='right' width={8}>
              <Checkbox
                label='Zero Sale Only'
                onChange={(e, data) => { data.checked ? setZeroSaleOnly(true) : setZeroSaleOnly(false) }}
                checked={zeroSaleOnly}
              />
              <Grid.Row>&nbsp;</Grid.Row>
              <Menu compact size='mini'>
                <Menu.Item
                  name='CORE'
                  active={core === 'CORE'}
                  content='CORE'
                  onClick={() => {
                    setCore('CORE')
                  }}
                />
                <Menu.Item
                  name='NON-CORE'
                  active={core === 'NON-CORE'}
                  content='NON-CORE'
                  onClick={() => {
                    setCore('NON-CORE')
                  }}
                />
              </Menu>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid>
          {evntDtlDataSorted.length > rowsPerPage && <Grid.Row>
            <Grid.Column width={16} textAlign='center'>
              <Pagination
                size='mini'
                defaultActivePage={1}
                totalPages={Math.floor((evntDtlDataSorted.length - 1) / rowsPerPage) + 1}
                activePage={pageNum}
                boundaryRange={0}
                siblingRange={1}
                onPageChange={(e, { activePage }) => {
                  setPageNum(activePage)
                }}
              />
            </Grid.Column>
          </Grid.Row>}
        </Grid>
        <Table textAlign='center' unstackable celled sortable size='small'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                sorted={sortColumn === 'SKU_NBR' ? sortDirection : null}
                onClick={() => {
                  if (sortColumn !== 'SKU_NBR') {
                    setSortColumn('SKU_NBR')
                    setSortDirection('ascending')
                  } else {
                    sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                  }
                }}
              >SKU # {sortColumn !== 'SKU_NBR' && <Icon fitted name='sort' />}</Table.HeaderCell>
              <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                sorted={sortColumn === 'SKU_DESC' ? sortDirection : null}
                onClick={() => {
                  if (sortColumn !== 'SKU_DESC') {
                    setSortColumn('SKU_DESC')
                    setSortDirection('ascending')
                  } else {
                    sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                  }
                }}
              >SKU Description {sortColumn !== 'SKU_DESC' && <Icon fitted name='sort' />}</Table.HeaderCell>
              {<Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                sorted={sortColumn === 'TOT_SLS_AMT' ? sortDirection : null}
                onClick={() => {
                  if (sortColumn !== 'TOT_SLS_AMT') {
                    setSortColumn('TOT_SLS_AMT')
                    setSortDirection('ascending')
                  } else {
                    sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                  }
                }}
              >Sales {sortColumn !== 'TOT_SLS_AMT' && <Icon fitted name='sort' />}</Table.HeaderCell>}

              {/* {eventsSalesOrUnits === 'Units #' && <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                sorted={sortColumn === 'TOT_SLS_UNITS' ? sortDirection : null}
                onClick={() => {
                  if (sortColumn !== 'TOT_SLS_UNITS') {
                    setSortColumn('TOT_SLS_UNITS')
                    setSortDirection('ascending')
                  } else {
                    sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                  }
                }}
              >Units {sortColumn !== 'TOT_SLS_UNITS' && <Icon fitted name='sort' />}</Table.HeaderCell>} */}

              {eventsSalesOrUnits === 'Sales $' && <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                sorted={sortColumn === 'ST' ? sortDirection : null}
                onClick={() => {
                  if (sortColumn !== 'ST') {
                    setSortColumn('ST')
                    setSortDirection('ascending')
                  } else {
                    sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                  }
                }}
              >Sell-Thru % {sortColumn !== 'ST' && <Icon fitted name='sort' />}</Table.HeaderCell>}
              {eventsSalesOrUnits === 'Units #' && <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                sorted={sortColumn === 'UST' ? sortDirection : null}
                onClick={() => {
                  if (sortColumn !== 'UST') {
                    setSortColumn('UST')
                    setSortDirection('ascending')
                  } else {
                    sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                  }
                }}
              >Unit Sell-Thru % {sortColumn !== 'UST' && <Icon fitted name='sort' />}</Table.HeaderCell>}
              {eventsSalesOrUnits === 'Sales $' && <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                sorted={sortColumn === 'RESID_AMT' ? sortDirection : null}
                onClick={() => {
                  if (sortColumn !== 'RESID_AMT') {
                    setSortColumn('RESID_AMT')
                    setSortDirection('ascending')
                  } else {
                    sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                  }
                }}
              >Residual $ {sortColumn !== 'RESID_AMT' && <Icon fitted name='sort' />}</Table.HeaderCell>}
              {eventsSalesOrUnits === 'Units #' && <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                sorted={sortColumn === 'RESID_AMT_UNITS' ? sortDirection : null}
                onClick={() => {
                  if (sortColumn !== 'RESID_AMT_UNITS') {
                    setSortColumn('RESID_AMT_UNITS')
                    setSortDirection('ascending')
                  } else {
                    sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                  }
                }}
              >Residual # {sortColumn !== 'RESID_AMT_UNITS' && <Icon fitted name='sort' />}</Table.HeaderCell>}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {dataPagination.map((data) => {
              return (
                <Table.Row>
                  <Table.Cell
                    style={{ color: '#EE7125', textDecoration: 'underline' }}
                    singleLine
                    onClick={() => {
                      console.log("1111", data)
                      setOpen(true)
                      setModalData(data)
                    }}>
                    {data['SKU_NBR'].toString().length > 6 ? // Format SKUs, 10-digit (xxxx-xxx-xxx) versus 6-digit (xxx-xxx)
                      data['SKU_NBR'].toString().substring(0, 4) + '-' + data['SKU_NBR'].toString().substring(4, 7) + '-' + data['SKU_NBR'].toString().substring(7) :
                      data['SKU_NBR'].toString().substring(0, 3) + '-' + data['SKU_NBR'].toString().substring(3)}
                  </Table.Cell>
                  {/* <Table.Cell>{data['SKU_NBR']}</Table.Cell> */}
                  <Table.Cell>{data['SKU_DESC']}</Table.Cell>
                  {<Table.Cell>{getDollarString(data['TOT_SLS_AMT'])}</Table.Cell>}
                  {eventsSalesOrUnits === 'Sales $' && <Table.Cell>{data['ST_DEN'] !== 0 ? getPercentString(data['ST_NUM'] / data['ST_DEN']) : '-'}</Table.Cell>}
                  {eventsSalesOrUnits === 'Sales $' && <Table.Cell>{getDollarString(data['RESID_AMT'])}</Table.Cell>}
                  {/* {eventsSalesOrUnits === 'Units #' && <Table.Cell>{data['TOT_SLS_UNITS']?data['TOT_SLS_UNITS'].toLocaleString("en-US"):0}</Table.Cell>} */}
                  {eventsSalesOrUnits === 'Units #' && <Table.Cell>{data['ST_DEN_UNITS'] !== 0 ? getPercentString(data['ST_NUM_UNITS'] / data['ST_DEN_UNITS']) : '-'}</Table.Cell>}
                  {eventsSalesOrUnits === 'Units #' && <Table.Cell>{data['RESID_AMT_UNITS'] ? data['RESID_AMT_UNITS'].toLocaleString("en-US") : 0}</Table.Cell>}
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      </Tab.Pane>


    },
    {
      menuItem: 'Store',
      render: () =>
        <>
          <Tab.Pane attached={false}>
            {/******************************************************************************
      ************************************Total***************************************
      ********************************************************************************/}
            <Menu compact size='small'>
              <UnitsSellMenu salesOrUnits={salesOrUnits} setSalesOrUnits={setSalesOrUnits} />
            </Menu>
            <Header textAlign='center'>Total Event</Header>
            <Table textAlign='center' unstackable celled sortable size='small'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    sorted={sortColumnStr === 'STR_NBR' ? sortDirectionStr : null}
                    onClick={() => {
                      if (sortColumnStr !== 'STR_NBR') {
                        setSortColumnStr('STR_NBR')
                        setSortDirectionStr('descending')
                      } else {
                        sortDirectionStr === 'descending' ? setSortDirectionStr('ascending') : setSortDirectionStr('descending')
                      }
                    }}
                  >Store # {sortColumn !== 'STR_NBR' && <Icon fitted name='sort' />}</Table.HeaderCell>
                  {<Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    sorted={sortColumnStr === 'TOT_EVNT_SLS_AMT' ? sortDirectionStr : null}
                    onClick={() => {
                      if (sortColumnStr !== 'TOT_EVNT_SLS_AMT') {
                        setSortColumnStr('TOT_EVNT_SLS_AMT')
                        setSortDirectionStr('descending')
                      } else {
                        sortDirectionStr === 'descending' ? setSortDirectionStr('ascending') : setSortDirectionStr('descending')
                      }
                    }}
                  >Sales {sortColumnStr !== 'TOT_EVNT_SLS_AMT' && <Icon fitted name='sort' />}</Table.HeaderCell>}

                  {/* {salesOrUnits === 'Units #' && <Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    sorted={sortColumnStr === 'TOT_EVNT_SLS_UNITS' ? sortDirectionStr : null}
                    onClick={() => {
                      if (sortColumnStr !== 'TOT_EVNT_SLS_UNITS') {
                        setSortColumnStr('TOT_EVNT_SLS_UNITS')
                        setSortDirectionStr('descending')
                      } else {
                        sortDirectionStr === 'descending' ? setSortDirectionStr('ascending') : setSortDirectionStr('descending')
                      }
                    }}
                  >Units {sortColumnStr !== 'TOT_EVNT_SLS_UNITS' && <Icon fitted name='sort' />}</Table.HeaderCell>} */}

                  {salesOrUnits === 'Sales $' && <Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    sorted={sortColumnStr === 'TOT_ST_NUM' ? sortDirectionStr : null}
                    onClick={() => {
                      if (sortColumnStr !== 'TOT_ST_NUM') {
                        setSortColumnStr('TOT_ST_NUM')
                        setSortDirectionStr('descending')
                      } else {
                        sortDirectionStr === 'descending' ? setSortDirectionStr('ascending') : setSortDirectionStr('descending')
                      }
                    }}
                  >Sell-Thru % {sortColumnStr !== 'TOT_ST_NUM' && <Icon fitted name='sort' />}</Table.HeaderCell>}
                  {salesOrUnits === 'Units #' && <Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    sorted={sortColumnStr === 'TOT_ST_NUM_UNITS' ? sortDirectionStr : null}
                    onClick={() => {
                      if (sortColumnStr !== 'TOT_ST_NUM_UNITS') {
                        setSortColumnStr('TOT_ST_NUM_UNITS')
                        setSortDirectionStr('descending')
                      } else {
                        sortDirectionStr === 'descending' ? setSortDirectionStr('ascending') : setSortDirectionStr('descending')
                      }
                    }}
                  >Unit Sell-Thru % {sortColumnStr !== 'TOT_ST_NUM_UNITS' && <Icon fitted name='sort' />}</Table.HeaderCell>}

                  {salesOrUnits === 'Sales $' && <Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    sorted={sortColumnStr === 'TOT_EVNT_RESID' ? sortDirectionStr : null}
                    onClick={() => {
                      if (sortColumnStr !== 'TOT_EVNT_RESID') {
                        setSortColumnStr('TOT_EVNT_RESID')
                        setSortDirectionStr('descending')
                      } else {
                        sortDirectionStr === 'descending' ? setSortDirectionStr('ascending') : setSortDirectionStr('descending')
                      }
                    }}
                  >Residual ${sortColumnStr !== 'TOT_EVNT_RESID' && <Icon fitted name='sort' />}</Table.HeaderCell>}
                  {salesOrUnits === 'Units #' && <Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    sorted={sortColumnStr === 'TOT_RESID_UNITS' ? sortDirectionStr : null}
                    onClick={() => {
                      if (sortColumnStr !== 'TOT_RESID_UNITS') {
                        setSortColumnStr('TOT_RESID_UNITS')
                        setSortDirectionStr('descending')
                      } else {
                        sortDirectionStr === 'descending' ? setSortDirectionStr('ascending') : setSortDirectionStr('descending')
                      }
                    }}
                  >Residual #{sortColumnStr !== 'TOT_RESID_UNITS' && <Icon fitted name='sort' />}</Table.HeaderCell>}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {evntSumryStrSorted.map((data) => {
                  return (
                    <Table.Row>
                      <Table.Cell>
                        <Link
                          to={`/store/events/detail?strNbr=${data['STR_NBR']}&evntId=${data['EVNT_ID']}`}
                          style={{ color: '#EE7125', textDecoration: 'underline' }}
                        >{data['STR_NBR']}</Link>
                      </Table.Cell>
                      {<Table.Cell>{getDollarString(data['TOT_EVNT_SLS_AMT'])}</Table.Cell>}
                      {/* {salesOrUnits === 'Units #' && <Table.Cell>{data['TOT_EVNT_SLS_UNITS']?data['TOT_EVNT_SLS_UNITS'].toLocaleString("en-US"):0}</Table.Cell>} */}

                      {salesOrUnits === 'Sales $' && <Table.Cell>{getPercentString(data['TOT_ST_NUM'] / data['TOT_ST_DEN'])}</Table.Cell>}
                      {salesOrUnits === 'Units #' && <Table.Cell>{getPercentString(data['TOT_ST_NUM_UNITS'] / data['TOT_ST_DEN_UNITS'])}</Table.Cell>}

                      {salesOrUnits === 'Sales $' && <Table.Cell>{getDollarString(data['TOT_EVNT_RESID'])}</Table.Cell>}
                      {salesOrUnits === 'Units #' && <Table.Cell>{data['TOT_RESID_UNITS'] ? data['TOT_RESID_UNITS'].toLocaleString("en-US") : 0}</Table.Cell>}
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>
            {/******************************************************************************
      ************************************CORE****************************************
      ********************************************************************************/}
            <Menu compact size='small'>
              <UnitsSellMenu salesOrUnits={salesOrUnitsCore} setSalesOrUnits={setSalesOrUnitsCore} />
            </Menu>
            <Header textAlign='center'>Core</Header>
            <Table textAlign='center' unstackable celled sortable size='small'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    sorted={sortColumnCore === 'STR_NBR' ? sortDirectionCore : null}
                    onClick={() => {
                      if (sortColumnCore !== 'STR_NBR') {
                        setSortColumnCore('STR_NBR')
                        setSortDirectionCore('descending')
                      } else {
                        sortDirectionCore === 'descending' ? setSortDirectionCore('ascending') : setSortDirectionCore('descending')
                      }
                    }}
                  >Store # {sortColumnCore !== 'STR_NBR' && <Icon fitted name='sort' />}</Table.HeaderCell>
                  {<Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    sorted={sortColumnCore === 'CORE_EVNT_SLS_AMT' ? sortDirectionCore : null}
                    onClick={() => {
                      if (sortColumnCore !== 'CORE_EVNT_SLS_AMT') {
                        setSortColumnCore('CORE_EVNT_SLS_AMT')
                        setSortDirectionCore('descending')
                      } else {
                        sortDirectionCore === 'descending' ? setSortDirectionCore('ascending') : setSortDirectionCore('descending')
                      }
                    }}
                  >Sales {sortColumnCore !== 'CORE_EVNT_SLS_AMT' && <Icon fitted name='sort' />}</Table.HeaderCell>}

                  {/* {salesOrUnitsCore === 'Units #' && <Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    sorted={sortColumnCore === 'CORE_EVNT_SLS_UNITS' ? sortDirectionCore : null}
                    onClick={() => {
                      if (sortColumnCore !== 'CORE_EVNT_SLS_UNITS') {
                        setSortColumnCore('CORE_EVNT_SLS_UNITS')
                        setSortDirectionCore('descending')
                      } else {
                        sortDirectionCore === 'descending' ? setSortDirectionCore('ascending') : setSortDirectionCore('descending')
                      }
                    }}
                  >Units {sortColumnCore !== 'CORE_EVNT_SLS_UNITS' && <Icon fitted name='sort' />}</Table.HeaderCell>} */}

                  {salesOrUnitsCore === 'Sales $' && <Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    sorted={sortColumnCore === 'ST_CORE_NUM' ? sortDirectionCore : null}
                    onClick={() => {
                      if (sortColumnCore !== 'ST_CORE_NUM') {
                        setSortColumnCore('ST_CORE_NUM')
                        setSortDirectionCore('descending')
                      } else {
                        sortDirectionCore === 'descending' ? setSortDirectionCore('ascending') : setSortDirectionCore('descending')
                      }
                    }}
                  >Sell-Thru % {sortColumnCore !== 'ST_CORE_NUM' && <Icon fitted name='sort' />}</Table.HeaderCell>}
                  {salesOrUnitsCore === 'Units #' && <Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    sorted={sortColumnCore === 'ST_CORE_NUM_UNITS' ? sortDirectionCore : null}
                    onClick={() => {
                      if (sortColumnCore !== 'ST_CORE_NUM_UNITS') {
                        setSortColumnCore('ST_CORE_NUM_UNITS')
                        setSortDirectionCore('descending')
                      } else {
                        sortDirectionCore === 'descending' ? setSortDirectionCore('ascending') : setSortDirectionCore('descending')
                      }
                    }}
                  >Unit Sell-Thru % {sortColumnCore !== 'ST_CORE_NUM_UNITS' && <Icon fitted name='sort' />}</Table.HeaderCell>}

                  {salesOrUnitsCore === 'Sales $' && <Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    sorted={sortColumnCore === 'CORE_RESID' ? sortDirectionCore : null}
                    onClick={() => {
                      if (sortColumnCore !== 'CORE_RESID') {
                        setSortColumnCore('CORE_RESID')
                        setSortDirectionCore('descending')
                      } else {
                        sortDirectionCore === 'descending' ? setSortDirectionCore('ascending') : setSortDirectionCore('descending')
                      }
                    }}
                  >Residual ${sortColumnCore !== 'CORE_RESID' && <Icon fitted name='sort' />}</Table.HeaderCell>}
                  {salesOrUnitsCore === 'Units #' && <Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    sorted={sortColumnCore === 'CORE_RESID_UNITS' ? sortDirectionCore : null}
                    onClick={() => {
                      if (sortColumnCore !== 'CORE_RESID_UNITS') {
                        setSortColumnCore('CORE_RESID_UNITS')
                        setSortDirectionCore('descending')
                      } else {
                        sortDirectionCore === 'descending' ? setSortDirectionCore('ascending') : setSortDirectionCore('descending')
                      }
                    }}
                  >Residual #{sortColumnCore !== 'CORE_RESID_UNITS' && <Icon fitted name='sort' />}</Table.HeaderCell>}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {evntSumryCoreSorted.map((data) => {
                  return (
                    <Table.Row>
                      <Table.Cell>
                        <Link
                          to={`/store/events/detail?strNbr=${data['STR_NBR']}&evntId=${data['EVNT_ID']}`}
                          style={{ color: '#EE7125', textDecoration: 'underline' }}
                        >{data['STR_NBR']}</Link>
                      </Table.Cell>
                      {<Table.Cell>{getDollarString(data['CORE_EVNT_SLS_AMT'])}</Table.Cell>}
                      {/* {salesOrUnitsCore === 'Units #' && <Table.Cell>{data['CORE_EVNT_SLS_UNITS']}</Table.Cell>} */}

                      {salesOrUnitsCore === 'Sales $' && <Table.Cell>{getPercentString(data['ST_CORE_NUM'] / data['ST_CORE_DEN'])}</Table.Cell>}
                      {salesOrUnitsCore === 'Units #' && <Table.Cell>{getPercentString(data['ST_CORE_NUM_UNITS'] / data['ST_CORE_DEN_UNITS'])}</Table.Cell>}

                      {salesOrUnitsCore === 'Sales $' && <Table.Cell>{getDollarString(data['CORE_RESID'])}</Table.Cell>}
                      {salesOrUnitsCore === 'Units #' && <Table.Cell>{data['CORE_RESID_UNITS'] ? data['CORE_RESID_UNITS'].toLocaleString("en-US") : 0}</Table.Cell>}
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>
            {/******************************************************************************
      ***********************************NON-CORE*************************************
      ********************************************************************************/}
            <Menu compact size='small'>
              <UnitsSellMenu salesOrUnits={salesOrUnitsNC} setSalesOrUnits={setSalesOrUnitsNC} />
            </Menu>
            <Header textAlign='center'>Non-Core</Header>
            <Table textAlign='center' unstackable celled sortable size='small'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    sorted={sortColumnNC === 'STR_NBR' ? sortDirectionNC : null}
                    onClick={() => {
                      if (sortColumnNC !== 'STR_NBR') {
                        setSortColumnNC('STR_NBR')
                        setSortDirectionNC('descending')
                      } else {
                        sortDirectionNC === 'descending' ? setSortDirectionNC('ascending') : setSortDirectionNC('descending')
                      }
                    }}
                  >Store # {sortColumnNC !== 'STR_NBR' && <Icon fitted name='sort' />}</Table.HeaderCell>
                  {<Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    sorted={sortColumnNC === 'NC_EVNT_SLS_AMT' ? sortDirectionNC : null}
                    onClick={() => {
                      if (sortColumnNC !== 'NC_EVNT_SLS_AMT') {
                        setSortColumnNC('NC_EVNT_SLS_AMT')
                        setSortDirectionNC('descending')
                      } else {
                        sortDirectionNC === 'descending' ? setSortDirectionNC('ascending') : setSortDirectionNC('descending')
                      }
                    }}
                  >Sales {sortColumnNC !== 'NC_EVNT_SLS_AMT' && <Icon fitted name='sort' />}</Table.HeaderCell>}

                  {/* {salesOrUnitsNC === 'Units #' && <Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    sorted={sortColumnNC === 'NC_ECNT_SLS_UNITS' ? sortDirectionNC : null}
                    onClick={() => {
                      if (sortColumnNC !== 'NC_ECNT_SLS_UNITS') {
                        setSortColumnNC('NC_ECNT_SLS_UNITS')
                        setSortDirectionNC('descending')
                      } else {
                        sortDirectionNC === 'descending' ? setSortDirectionNC('ascending') : setSortDirectionNC('descending')
                      }
                    }}
                  >Units {sortColumnNC !== 'NC_ECNT_SLS_UNITS' && <Icon fitted name='sort' />}</Table.HeaderCell>} */}


                  {salesOrUnitsNC === 'Sales $' && <Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    sorted={sortColumnNC === 'ST_NC_NUM' ? sortDirectionNC : null}
                    onClick={() => {
                      if (sortColumnNC !== 'ST_NC_NUM') {
                        setSortColumnNC('ST_NC_NUM')
                        setSortDirectionNC('descending')
                      } else {
                        sortDirectionNC === 'descending' ? setSortDirectionNC('ascending') : setSortDirectionNC('descending')
                      }
                    }}
                  >Sell-Thru % {sortColumnNC !== 'ST_NC_NUM' && <Icon fitted name='sort' />}</Table.HeaderCell>}
                  {salesOrUnitsNC === 'Units #' && <Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    sorted={sortColumnNC === 'ST_NC_NUM_UNITS' ? sortDirectionNC : null}
                    onClick={() => {
                      if (sortColumnNC !== 'ST_NC_NUM_UNITS') {
                        setSortColumnNC('ST_NC_NUM_UNITS')
                        setSortDirectionNC('descending')
                      } else {
                        sortDirectionNC === 'descending' ? setSortDirectionNC('ascending') : setSortDirectionNC('descending')
                      }
                    }}
                  >Unit Sell-Thru % {sortColumnNC !== 'ST_NC_NUM_UNITS' && <Icon fitted name='sort' />}</Table.HeaderCell>}

                  {salesOrUnitsNC === 'Sales $' && <Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    sorted={sortColumnNC === 'NC_RESID' ? sortDirectionNC : null}
                    onClick={() => {
                      if (sortColumnNC !== 'NC_RESID') {
                        setSortColumnNC('NC_RESID')
                        setSortDirectionNC('descending')
                      } else {
                        sortDirectionNC === 'descending' ? setSortDirectionNC('ascending') : setSortDirectionNC('descending')
                      }
                    }}
                  >Residual ${sortColumnNC !== 'NC_RESID' && <Icon fitted name='sort' />}</Table.HeaderCell>}
                  {salesOrUnitsNC === 'Units #' && <Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    sorted={sortColumnNC === 'NC_RESID_UNITS' ? sortDirectionNC : null}
                    onClick={() => {
                      if (sortColumnNC !== 'NC_RESID_UNITS') {
                        setSortColumnNC('NC_RESID_UNITS')
                        setSortDirectionNC('descending')
                      } else {
                        sortDirectionNC === 'descending' ? setSortDirectionNC('ascending') : setSortDirectionNC('descending')
                      }
                    }}
                  >Residual #{sortColumnNC !== 'NC_RESID_UNITS' && <Icon fitted name='sort' />}</Table.HeaderCell>}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {evntSumryNCSorted.map((data) => {
                  return (
                    <Table.Row>
                      <Table.Cell>
                        <Link
                          to={`/store/events/detail?strNbr=${data['STR_NBR']}&evntId=${data['EVNT_ID']}`}
                          style={{ color: '#EE7125', textDecoration: 'underline' }}
                        >{data['STR_NBR']}</Link>
                      </Table.Cell>
                      {<Table.Cell>{getDollarString(data['NC_EVNT_SLS_AMT'])}</Table.Cell>}
                      {/* {salesOrUnitsNC === 'Units #' && <Table.Cell>{data['NC_ECNT_SLS_UNITS']}</Table.Cell>} */}

                      {salesOrUnitsNC === 'Sales $' && <Table.Cell>{getPercentString(data['ST_NC_NUM'] / data['ST_NC_DEN'])}</Table.Cell>}
                      {salesOrUnitsNC === 'Units #' && <Table.Cell>{getPercentString(data['ST_NC_NUM_UNITS'] / data['ST_NC_DEN_UNITS'])}</Table.Cell>}

                      {salesOrUnitsNC === 'Sales $' && <Table.Cell>{getDollarString(data['NC_RESID'])}</Table.Cell>}
                      {salesOrUnitsNC === 'Units #' && <Table.Cell>{data['NC_RESID_UNITS'] ? data['NC_RESID_UNITS'].toLocaleString("en-US") : 0}</Table.Cell>}
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>
          </Tab.Pane>
        </>
    },

  ]

  return (
    <>

      <Link style={{ color: '#EE7125' }} to={`/district/events?dstNbr=${dstNbr}`} data-test='b2o-link'>Back to Events</Link>

      <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
      {open && <Modal
        closeIcon
        open={open}
        onClose={() => { setOpen(false) }}
      >
        <Segment color='orange'>
          <Grid>
            <Grid.Column width={16}>
              <Grid.Row>
                <Header textAlign='center'>
                  {modalData['SKU_NBR'].toString().length > 6 ? // Format SKUs, 10-digit (xxxx-xxx-xxx) versus 6-digit (xxx-xxx)
                    modalData['SKU_NBR'].toString().substring(0, 4) + '-' + modalData['SKU_NBR'].toString().substring(4, 7) + '-' + modalData['SKU_NBR'].toString().substring(7) :
                    modalData['SKU_NBR'].toString().substring(0, 3) + '-' + modalData['SKU_NBR'].toString().substring(3)}
                </Header>
              </Grid.Row>
              <Grid.Row>&nbsp;</Grid.Row>
              <Grid.Row><Header>Class</Header></Grid.Row>
              <Grid.Row><p textAlign='center'>{modalData['EXT_CLASS_NBR'] + '-' + modalData['CLASS_DESC']}</p></Grid.Row>
              <Grid.Row>&nbsp;</Grid.Row>
              <Grid.Row><Header>Subclass</Header></Grid.Row>
              <Grid.Row><p textAlign='center'>{modalData['EXT_SUB_CLASS_NBR'] + '-' + modalData['SUB_CLASS_DESC']}</p></Grid.Row>
              <Grid.Row>&nbsp;</Grid.Row>
              <Grid.Row><Header>SKU Description</Header></Grid.Row>
              <Grid.Row><p textAlign='center'>{modalData['SKU_DESC']}</p></Grid.Row>
            </Grid.Column>
            &nbsp;
            &nbsp;

            <Grid.Column>
              <Grid.Row>&nbsp;</Grid.Row>
              <Grid.Row>
                <Button
                  onClick={() => { setOpen(false) }}
                  style={{ color: 'white', backgroundColor: '#EE7125' }}
                  content='Close'
                />
              </Grid.Row>
              <Grid.Row>&nbsp;</Grid.Row>
            </Grid.Column>
          </Grid>
        </Segment>
      </Modal>}


    </>
  )
})












