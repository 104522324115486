import classnames from 'classnames';
import { Loader, Segment } from 'semantic-ui-react';
import { Suggestions } from './Suggestions';
import '../../styles/autocomplete.css';

export const Autocomplete = ({
  closeAutocomplete,
  currentSearch,
  isError,
  isLoading,
  onSelectSuggestions,
  searchInputRef,
  suggestions
}) => {
  const isSuggestionsEmpty = suggestions.length === 0;
  const classNameWrapper = classnames('autocomplete mt-2', {
    'autocomplete-empty': isSuggestionsEmpty || isError,
    'autocomplete-loading': isLoading
  });

  return (
    <Segment className={classNameWrapper}>
      {isError ? (
        <span>Something went wrong try another search...</span>
      ) : isLoading ? (
        <Loader active data-testid='autocomplete--loader' />
      ) : isSuggestionsEmpty ? (
        <span>There is no suggestions...</span>
      ) : (
        <Suggestions
          closeAutocomplete={closeAutocomplete}
          currentSearch={currentSearch}
          onSelectSuggestions={onSelectSuggestions}
          searchInputRef={searchInputRef}
          suggestions={suggestions}
        />
      )}
    </Segment>
  );
};
