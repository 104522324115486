import { createContext } from 'react';
import { useOverviewQueries } from '../hooks/useOverviewQueries';

export const CoreContext = createContext();

const CoreContextProvider = ({ children, fpToggle, levelName, ...props }) => {
  const {
    isError,
    isLoading,
    salesClassesData,
    salesCoreData,
    salesDeptData,
    salesMetricsData,
    salesSafetyByDivisionData,
    salesSkusData,
    salesSubClassesData,
    swmDetailsData,
    SafetyIncOshaLtGlbiData
  } = useOverviewQueries(fpToggle);

  const value = {
    ...props,
    isError,
    isLoading,
    salesClassesData,
    salesCoreData,
    salesDeptData,
    salesMetricsData,
    salesSafetyByDivisionData,
    salesSkusData,
    salesSubClassesData,
    swmDetailsData,
    SafetyIncOshaLtGlbiData
  };

  return <CoreContext.Provider value={value}>{children}</CoreContext.Provider>;
};

export default CoreContextProvider;