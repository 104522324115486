import React from 'react'

export const TimeframeDropdownShrink = ({ timeframe, settimeframe }) => {
  return (
    <div>
      <select
        name="timeframe"
        onChange={e => {
          settimeframe(e.target.value)
        }}
        value={timeframe}
        style={{ padding: 10 }}
      >
        {new Date().getDay() === 1 ? <option value="WTD">LW</option> : <option value="WTD">WTD</option>}
        {new Date().getDay() === 1 ? <option value="LW">LLW</option> : <option value="LW">LW</option>}
        <option value="LM">LM</option>
        <option value="MTD">MTD</option>
        <option value="QTD">QTD</option>
        <option value="HTD">HTD</option>
        <option value="YTD">YTD</option>
        <option value="R12M">L12</option>
        <option value="LAST_INV">Since Last Inv</option>
      </select>
    </div>
  )
}
