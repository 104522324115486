import React, { useState, useEffect } from 'react'
import {
  Header,
  Grid,
  Modal,
  Segment,
  Button
} from 'semantic-ui-react'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'

import { useQuery } from 'react-query'
import {
  getData
} from '../../service/DataService'

import { LegacyPageNotice } from './LegacyPageNotice'

export const ViewAlert = ({ fpToggle, clearedAlerts, setClearedAlerts }) => {
  const [alertData, setAlertData] = useState([])
  const [hasContent, setHasContent] = useState(false)
  const [isLegacyAlertOpen, setIsLegacyAlertOpen] = useState(false)

  const pulseAlertQuery = useQuery(['queryGetStorePulseAlert', { fpToggle }], getData)

  useEffect(() => {
    // TODO: REMOVE THIS AFTER THE LEGACY PAGE IS SHOT DOWN
    const LEGACY_PAGE = 'storewalk.homedepot.com'
    const isLegacyPage = window.location.host === LEGACY_PAGE
    setIsLegacyAlertOpen(isLegacyPage)
    setHasContent(isLegacyPage)
  }, [])

  useEffect(() => {
    if (pulseAlertQuery.isSuccess) {
      pulseAlertQuery.data.length >= 1 ? setAlertData(pulseAlertQuery.data[0]) : setAlertData([])
    }
  }, [pulseAlertQuery.data, pulseAlertQuery.isSuccess])

  useEffect(() => {
    if (alertData['ENABLED']) {
      setHasContent(true)
    }
  }, [alertData])


  return (
    pulseAlertQuery.isSuccess && !clearedAlerts && hasContent ?
      <Modal
        closeIcon
        open={!clearedAlerts}
        onClose={() => { setClearedAlerts(true) }}
      >
        <Segment color='orange'>
          <Grid>
            <Grid.Column width={16}>
              <Grid.Row>
                <Header textAlign='center' style={{ color: 'red' }}>Pulse Notifications</Header>
              </Grid.Row>
              <Grid.Row>&nbsp;</Grid.Row>
              <Grid.Row>
                {isLegacyAlertOpen && <LegacyPageNotice />}

                <ReactMarkdown plugins={[gfm]} children={alertData['MESSAGE']} />
              </Grid.Row>
              <Grid.Row>&nbsp;</Grid.Row>
              <Grid.Row>
                <Button
                  onClick={() => { setClearedAlerts(true) }}
                  style={{ color: 'white', backgroundColor: '#EE7125' }}
                  content='OK'
                />
              </Grid.Row>
              <Grid.Row>&nbsp;</Grid.Row>
            </Grid.Column>
          </Grid>
        </Segment>
      </Modal> :
      (null)
  )

}