import { useMemo } from 'react';

import { Link } from 'react-router-dom';
import { Grid, Header, Loader } from 'semantic-ui-react';

import { useQuery } from 'react-query';
import { useQueryParams } from '../../hooks/useQueryParams';

import { getData } from '../../service/DataService';
import { findBiggestObject } from '../../utils/array';
import { capitalizeFirstLetter } from '../../utils/string';
import { getCalloutStyle, getPercentString, getThousandDollarString } from '../Common/TableCellUtils';

import { useFetchLevelName } from '../../hooks/useFetchLevelName';
import { DataTable } from '../Common/DataTable';
import { IntradayViewTable } from './IntradayViewTable';

export const IntradayDeptView = ({ fpToggle, level }) => {
  const { rgnNbr = 0, strNbr = 0, deptNbr = 0, dstNbr = 0, divNbr = 0 } = useQueryParams();
  const locNbr = rgnNbr || dstNbr || strNbr || divNbr;
  const deptNbrInt = deptNbr.includes('-') ? parseInt(deptNbr.split('-')[0]) : deptNbr; // NOTE: Current url contains the deptNbr as integer-deptName, we only need the integer
  const { data, isError, isLoading } = useQuery(
    [`query/salesIntradayDept/${level}/${locNbr}/dept`, { deptNbrInt, varPresent: true, fpToggle }],
    getData
  );
  const { currentLevelName, isLoadingName } = useFetchLevelName({ level, fpToggle, locNbr });

  const latestHourData = useMemo(() => {
    if (isLoading) return {};

    return findBiggestObject(data, 'SLS_HR') || {};
  }, [data, isLoading]);

  if (isError) return <p>Something went wrong</p>;
  if (isLoading || isLoadingName) return <Loader active>Loading...</Loader>;

  const currentDaySalesObj = {
    data: [
      [
        {
          stringValue: latestHourData['LOCAL_DATE']
        },
        {
          customStyle: getCalloutStyle(latestHourData['CUMMU_SLS']),
          stringValue: getThousandDollarString(latestHourData['CUMMU_SLS'], 1)
        },
        {
          customStyle: getCalloutStyle(latestHourData['DOL_TO_DAILY_PLN']),
          stringValue: getThousandDollarString(latestHourData['DOL_TO_DAILY_PLN'], 1)
        },
        {
          customStyle: getCalloutStyle(latestHourData['PCT_TO_DAILY_PLN']),
          stringValue: getPercentString(latestHourData['PCT_TO_DAILY_PLN'], 1, 1)
        },
        {
          customStyle: getCalloutStyle(latestHourData['COMP_PCT']),
          stringValue: getPercentString(latestHourData['COMP_PCT'], 1, 1)
        },
        {
          customStyle: getCalloutStyle(latestHourData['SLS_PLN_AMT'] - latestHourData['LY_COMP_SLS_AMT']),
          stringValue: getPlannedComp(latestHourData['SLS_PLN_AMT'], latestHourData['LY_COMP_SLS_AMT'])
        }
      ]
    ],
    headers: [
      { name: 'Date' },
      { name: 'Sales $ (k)' },
      { name: '$ vPlan (k)' },
      {
        element: (
          <span>
            % to
            <br />
            Daily Plan
          </span>
        ),
        name: 'to-daily-plan'
      },
      { name: 'Comp %' },
      {
        element: (
          <span>
            Planned
            <br />
            Comp %
          </span>
        ),
        name: 'planned-comp-%'
      }
    ]
  };

  const linkParam =
    level === 'store' ? 'strNbr' : level === 'district' ? 'dstNbr' : level === 'division' ? 'divNbr' : 'rgnNbr';
  const title = `DEPT ${data[0]?.DEPT_NM} - ${capitalizeFirstLetter(level)} ${locNbr}, ${capitalizeFirstLetter(currentLevelName)}`;

  return (
    <>
      <Grid>
        <Grid.Column width={8} textAlign='left'>
          <Grid.Row>
            <Link style={{ color: '#EE7125' }} to={`/${level}/intraday?${linkParam}=${locNbr}`}>
              Back to Intraday
            </Link>
          </Grid.Row>
        </Grid.Column>
      </Grid>
      <Header textAlign='center'>{title}</Header>
      <Header textAlign='center'>Current Day Sales</Header>
      <DataTable data={currentDaySalesObj} />

      <IntradayViewTable data={data} />
    </>
  );
};

const getPlannedComp = (SLS_PLN_AMT, LY_COMP_SLS_AMT) => {
  if (SLS_PLN_AMT == null || LY_COMP_SLS_AMT == null) return '-';

  return getPercentString(SLS_PLN_AMT / LY_COMP_SLS_AMT - 1, 1, 1);
};
