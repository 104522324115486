import { isMonday } from '../../utils/date';

export const TimeframeDropdownLimited = ({ timeframe, settimeframe }) => {
  const options = isMonday ? MONDAY_OPTIONS : BASE_OPTIONS;

  return (
    <div>
      <select
        name='timeframe'
        onChange={e => {
          settimeframe(e.target.value);
        }}
        value={timeframe}
        style={{ padding: 10 }}>
        {options.map(option => (
          <option key={option.key} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
    </div>
  );
};

const BASE_OPTIONS = [
  { key: 'WTD', text: 'WTD', value: 'WTD' },
  { key: 'LW', text: 'LW', value: 'LW' }
];

const MONDAY_OPTIONS = [
  { key: 'LW', text: 'LW', value: 'WTD' },
  { key: 'LLW', text: 'LLW', value: 'LW' }
];
