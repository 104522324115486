import { Header, Grid, Loader } from 'semantic-ui-react';
import { TimeframeDropdown } from '../Common/TimeframeDropdown';
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon';
import { ChannelAssociate } from './ChannelAssociate';
import { getCompareStyle, getCalloutStyle, getBpsString, getThousandDollarString } from '../Common/TableCellUtils';
import { DataTable } from '../Common/DataTable';
import { useQueryParams } from '../../hooks/useQueryParams';
import useQueryApi from '../../hooks/useQueryApi';

const calculateVGoalDecimal = (value = null, decimals = null) =>
  value && decimals
    ? decimals === 0
      ? '-'
      : (value / decimals).toLocaleString(undefined, {
          maximumFractionDigits: 0
        })
    : 'Loading';

const compareStyleGenerator = (value = null, decimals = null) => {
  return { color: value > decimals ? 'green' : value < decimals ? 'red' : 'black' };
};

export const Credit = ({ timeframe, settimeframe, fpToggle }) => {
  const { strNbr } = useQueryParams();
  const { data: creditPerfQuery = [], isLoading: isLoadingCreditPer } = useQueryApi('queryCreditPerfStrByStr', {
    strNbr,
    fpToggle
  });
  const { data: credSlsPenCalcQuery = [], isLoading: isLoadingCredSlsPenCalc } = useQueryApi('queryCreditSlsPenStrByStr', {
    strNbr,
    fpToggle
  });
  const { data: credAssocChnlData = [], isLoading: isLoadingCredAssocChnl } = useQueryApi('queryCreditAssocChnlByStr', {
    strNbr,
    fpToggle
  });

  const creditPerfData = creditPerfQuery[0] ?? {};
  const credSlsPenCalcData = credSlsPenCalcQuery[0] ?? {};

  if (isLoadingCreditPer || isLoadingCredSlsPenCalc || isLoadingCredAssocChnl) return <Loader active>Loading</Loader>;

  if (!creditPerfData || !credSlsPenCalcData) return <Header textAlign='center'>Invalid Store Number</Header>;

  const creditPerfDataCONS_APPS = creditPerfData[timeframe + '_CONS_APPS'];
  const creditPerfDataCONS_APP_GOAL = creditPerfData[timeframe + '_CONS_APP_GOAL'];
  const creditPerfDataCOMM_APPS = creditPerfData[timeframe + '_COMM_APPS'];
  const creditPerfCOMM_APP_GOAL = creditPerfData[timeframe + '_COMM_APP_GOAL'];
  const creditPerfTOT_APPS = creditPerfData[timeframe + '_TOT_APPS'];
  const creditPerfTOT_APP_GOAL = creditPerfData[timeframe + '_TOT_APP_GOAL'];
  const applicationData = {
    headers: [{ name: 'Category' }, { name: 'Apps' }, { name: 'LY' }, { name: 'Goal' }, { name: '% vGoal' }],
    data: [
      [
        { stringValue: 'Consumer' },
        { numValue: creditPerfDataCONS_APPS },
        { numValue: creditPerfData[timeframe + '_LY_CONS_APPS'] },
        { numValue: creditPerfDataCONS_APP_GOAL },
        {
          pctValue: creditPerfDataCONS_APPS / creditPerfDataCONS_APP_GOAL - 1,
          customStyle: compareStyleGenerator(creditPerfDataCONS_APPS, creditPerfDataCONS_APP_GOAL)
        }
      ],
      [
        { stringValue: 'Commercial' },
        { numValue: creditPerfDataCOMM_APPS },
        { numValue: creditPerfData[timeframe + '_LY_COMM_APPS'] },
        { numValue: creditPerfCOMM_APP_GOAL },
        {
          pctValue: creditPerfDataCOMM_APPS / creditPerfCOMM_APP_GOAL - 1,
          customStyle: compareStyleGenerator(creditPerfDataCOMM_APPS, creditPerfCOMM_APP_GOAL)
        }
      ],
      [
        { stringValue: 'Total' },
        { numValue: creditPerfTOT_APPS },
        { numValue: creditPerfData[timeframe + '_LY_TOT_APPS'] },
        { numValue: creditPerfTOT_APP_GOAL },
        {
          pctValue: creditPerfTOT_APPS / creditPerfTOT_APP_GOAL - 1,
          customStyle: compareStyleGenerator(creditPerfTOT_APPS, creditPerfTOT_APP_GOAL)
        }
      ]
    ]
  };

  const efficiencyData = {
    headers: [
      {
        name: 'App\nEfficiency'
      },
      { name: 'LY' }
    ],
    data: [
      [
        {
          stringValue: calculateVGoalDecimal(
            creditPerfData[timeframe + '_TXN_COUNT'],
            creditPerfData[timeframe + '_TOT_APPS']
          )
        },
        {
          stringValue: calculateVGoalDecimal(
            creditPerfData[timeframe + '_LY_TXN_COUNT'],
            creditPerfData[timeframe + '_LY_TOT_APPS']
          )
        }
      ]
    ]
  };

  const credSlsPenCalcDataCONS_SLS_AMT = credSlsPenCalcData[timeframe + '_CONS_SLS_AMT'];
  const credSlsPenCalcDataTOT_SLS_AMT = credSlsPenCalcData[timeframe + '_TOT_SLS_AMT'];
  const credSlsPenCalcDataCONS_CRED_SLS_PLN = credSlsPenCalcData[timeframe + '_CONS_CRED_SLS_PLN'];
  const credSlsPenCalcDataCONS_TOT_SLS_PLN = credSlsPenCalcData[timeframe + '_TOT_SLS_PLN'];
  const credSlsPenCalcDataCONS_LY_CONS_SLS_AMT = credSlsPenCalcData[timeframe + '_LY_CONS_SLS_AMT'];
  const credSlsPenCalcDataCONS_LY_TOT_SLS_AMT = credSlsPenCalcData[timeframe + '_LY_TOT_SLS_AMT'];
  const credSlsPenCalcData_COMM_SLS_AMT = credSlsPenCalcData[timeframe + '_COMM_SLS_AMT'];
  const credSlsPenCalcData_COMM_CRED_SLS_PLN = credSlsPenCalcData[timeframe + '_COMM_CRED_SLS_PLN'];
  const credSlsPenCalcData_LY_COMM_SLS_AMT = credSlsPenCalcData[timeframe + '_LY_COMM_SLS_AMT'];
  const credSlsPenCalcData_CRED_SLS_AMT = credSlsPenCalcData[timeframe + '_CRED_SLS_AMT'];
  const credSlsPenCalcData_TOT_SLS_AMT = credSlsPenCalcData[timeframe + '_TOT_SLS_AMT'];
  const credSlsPenCalcData_TOT_CRED_SLS_PLN = credSlsPenCalcData[timeframe + '_TOT_CRED_SLS_PLN'];
  const credSlsPenCalcData_LY_CRED_SLS_AMT = credSlsPenCalcData[timeframe + '_LY_CRED_SLS_AMT'];

  const usageData = {
    headers: [{ name: 'Category' }, { name: 'Credit Sales' }, { name: 'Usage' }, { name: 'bps vPlan' }, { name: 'bps vLY' }],
    data: [
      [
        { stringValue: 'Consumer' },
        {
          numValue: getThousandDollarString(credSlsPenCalcDataCONS_SLS_AMT),
          customStyle: getCalloutStyle(credSlsPenCalcDataCONS_SLS_AMT)
        },
        {
          pctValue: credSlsPenCalcDataCONS_SLS_AMT / credSlsPenCalcDataTOT_SLS_AMT
        },
        {
          numValue: getBpsString(
            credSlsPenCalcDataCONS_SLS_AMT / credSlsPenCalcDataTOT_SLS_AMT -
              credSlsPenCalcDataCONS_CRED_SLS_PLN / credSlsPenCalcDataCONS_TOT_SLS_PLN
          ),
          customStyle: getCompareStyle(
            credSlsPenCalcDataCONS_SLS_AMT / credSlsPenCalcDataTOT_SLS_AMT -
              credSlsPenCalcDataCONS_CRED_SLS_PLN / credSlsPenCalcDataCONS_TOT_SLS_PLN
          )
        },
        {
          stringValue: getBpsString(
            credSlsPenCalcDataCONS_SLS_AMT / credSlsPenCalcDataTOT_SLS_AMT -
              credSlsPenCalcDataCONS_LY_CONS_SLS_AMT / credSlsPenCalcDataCONS_LY_TOT_SLS_AMT
          ),
          customStyle: getCompareStyle(
            credSlsPenCalcDataCONS_SLS_AMT / credSlsPenCalcDataTOT_SLS_AMT -
              credSlsPenCalcDataCONS_LY_CONS_SLS_AMT / credSlsPenCalcDataCONS_LY_TOT_SLS_AMT
          )
        }
      ],
      [
        { stringValue: 'Commercial' },
        {
          stringValue: getThousandDollarString(credSlsPenCalcData_COMM_SLS_AMT),
          customStyle: getCalloutStyle(credSlsPenCalcData_COMM_SLS_AMT)
        },
        {
          pctValue: credSlsPenCalcData_COMM_SLS_AMT / credSlsPenCalcDataTOT_SLS_AMT
        },
        {
          stringValue: getBpsString(
            credSlsPenCalcData_COMM_SLS_AMT / credSlsPenCalcDataTOT_SLS_AMT -
              credSlsPenCalcData_COMM_CRED_SLS_PLN / credSlsPenCalcDataCONS_TOT_SLS_PLN
          ),
          customStyle: getCompareStyle(
            credSlsPenCalcData_COMM_SLS_AMT / credSlsPenCalcDataTOT_SLS_AMT -
              credSlsPenCalcData_COMM_CRED_SLS_PLN / credSlsPenCalcDataCONS_TOT_SLS_PLN
          )
        },
        {
          stringValue: getBpsString(
            credSlsPenCalcData_COMM_SLS_AMT / credSlsPenCalcDataTOT_SLS_AMT -
              credSlsPenCalcData_LY_COMM_SLS_AMT / credSlsPenCalcDataCONS_LY_TOT_SLS_AMT
          ),
          customStyle: getCompareStyle(
            credSlsPenCalcData_COMM_SLS_AMT / credSlsPenCalcDataTOT_SLS_AMT -
              credSlsPenCalcData_LY_COMM_SLS_AMT / credSlsPenCalcDataCONS_LY_TOT_SLS_AMT
          )
        }
      ],
      [
        { stringValue: 'Total' },
        {
          stringValue: getThousandDollarString(credSlsPenCalcData_CRED_SLS_AMT),
          customStyle: getCalloutStyle(credSlsPenCalcData_CRED_SLS_AMT)
        },
        {
          pctValue: credSlsPenCalcData_CRED_SLS_AMT / credSlsPenCalcData_TOT_SLS_AMT
        },
        {
          numValue: getBpsString(
            credSlsPenCalcData_CRED_SLS_AMT / credSlsPenCalcData_TOT_SLS_AMT -
              credSlsPenCalcData_TOT_CRED_SLS_PLN / credSlsPenCalcDataCONS_TOT_SLS_PLN
          ),
          customStyle: getCompareStyle(
            credSlsPenCalcData_CRED_SLS_AMT / credSlsPenCalcData_TOT_SLS_AMT -
              credSlsPenCalcData_TOT_CRED_SLS_PLN / credSlsPenCalcDataCONS_TOT_SLS_PLN
          )
        },
        {
          stringValue: getBpsString(
            credSlsPenCalcData_CRED_SLS_AMT / credSlsPenCalcData_TOT_SLS_AMT -
              credSlsPenCalcData_LY_CRED_SLS_AMT / credSlsPenCalcDataCONS_LY_TOT_SLS_AMT
          ),
          customStyle: getCompareStyle(
            credSlsPenCalcData_CRED_SLS_AMT / credSlsPenCalcData_TOT_SLS_AMT -
              credSlsPenCalcData_LY_CRED_SLS_AMT / credSlsPenCalcDataCONS_LY_TOT_SLS_AMT
          )
        }
      ]
    ]
  };

  return (
    <>
      <Grid>
        <Grid.Column width={8}>
          <Grid.Row>&nbsp;</Grid.Row>
        </Grid.Column>
        <Grid.Column width={8}>
          Timeframe:
          {new Date().getDay() === 1 ? (
            <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} />
          ) : (
            <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
          )}
        </Grid.Column>
      </Grid>
      <Header textAlign='center'>Credit - Store #{strNbr}</Header>
      {/* Applications Summary */}
      <Header textAlign='center'>Applications</Header>
      <DataTable data={applicationData} />
      <p>Note: If % vGoal ≥ 0%, then the goal is met.</p>
      {/* Efficiency Summary */}
      <Header textAlign='center'>Efficiency</Header>
      <DataTable data={efficiencyData} />
      {/* Usage Summary */}
      <Header textAlign='center'>Usage</Header>
      <DataTable data={usageData} />
      {/* Channel-Associate Tab Toggle */}
      <ChannelAssociate credAssocChnlData={credAssocChnlData} timeframe={timeframe} />
    </>
  );
};
