
import { Header } from "semantic-ui-react";
import { DataView } from "../../../../../../components/Common/DataView";
import { getCompareStyle, getPercentString, getThousandDollarString } from "../../../../../../components/Common/TableCellUtils";
import { METRIC_CONSTANTS } from "../../../../../../utils/constantsByLevel";

const getPlannedComp = (SLS_PLN_AMT, LY_COMP_SLS_AMT) => {
    if (SLS_PLN_AMT == null || LY_COMP_SLS_AMT == null) return '-';
    return getPercentString(SLS_PLN_AMT / LY_COMP_SLS_AMT - 1, 1, 1);
};

export const HourlySalesCoreDivision = ({ hourlySalesDivisionData }) => {

    const dataAsOf = '2024-09-12'; // TODO: Replace with actual data

    const level = 'core';

    const dataObj = {
        sortable: true,
        sortableDefault: 0,
        headers: [
            { name: 'Division' },
            { name: 'Sales $ (k) ' },
            { name: '$ vPlan (k) ' },
            { name: '% to Daily Plan ' },
            { name: 'Comp % ' },
            { name: 'Planned Comp % ' }
        ],
        data: hourlySalesDivisionData.map(row => [
            {
                stringValue: METRIC_CONSTANTS[level]['subMetric'] + " " + row[METRIC_CONSTANTS[level]['propToRender']],
                orderBy: row[METRIC_CONSTANTS[level]['propToRender']],
                link: {
                    to: `/${METRIC_CONSTANTS[level]['urlLinkLevel']}/intraday`,
                    params: {
                        [METRIC_CONSTANTS[level]['urlSubLevelParam']]: String(row[METRIC_CONSTANTS[level]['propToRender']]).padStart(4, '0')
                    }
                }
            },
            {
                stringValue: getThousandDollarString(row['CUMMU_SLS'], 1),
                orderBy: +getThousandDollarString(row['CUMMU_SLS'], 1)
            },
            {
                customStyle: getCompareStyle(row['DOL_TO_DAILY_PLN']),
                stringValue: getThousandDollarString(row['DOL_TO_DAILY_PLN'], 1),
                orderBy: row['DOL_TO_DAILY_PLN']
            },
            {
                customStyle: getCompareStyle(row['PCT_TO_DAILY_PLN']),
                stringValue: getPercentString(row['PCT_TO_DAILY_PLN'], 1, 1),
                orderBy: row['PCT_TO_DAILY_PLN']
            },
            {
                customStyle: getCompareStyle(row['COMP_PCT']),
                stringValue: getPercentString(row['COMP_PCT'], 1, 1),
                orderBy: row['COMP_PCT']
            },
            {
                customStyle: getCompareStyle(row['SLS_PLN_AMT'] - row['LY_COMP_SLS_AMT']),
                stringValue: getPlannedComp(row['SLS_PLN_AMT'], row['LY_COMP_SLS_AMT']),
                orderBy: (row['SLS_PLN_AMT'] !== null && row['LY_COMP_SLS_AMT'] !== null) ? row['SLS_PLN_AMT'] / row['LY_COMP_SLS_AMT'] : undefined
            }
        ])
    };

    return (
        <DataView>
            <DataView.Title>
                Current Day Sales - Core Division Comparision
                <Header.Subheader>Data as of {dataAsOf}</Header.Subheader>
            </DataView.Title>

            <DataView.DataTable data={dataObj} />
        </DataView>
    )
}