import { useMemo, useState } from 'react'
import {
  Grid,
  Header,
  Loader
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { DataTable } from '../Common/DataTable'
import { TimeframeDropdown } from '../Common/TimeframeDropdown'
import { UnitMenu } from '../Common/UnitMenu'
import { getCompareStyle, getCalloutStyle, getDollarString, getPercentString } from '../Common/TableCellUtils'

import { useQuery } from 'react-query'
import { useQueryParams } from '../../hooks/useQueryParams'

import { getData } from '../../service/DataService'
import { timeframeCode } from '../Common/timeframeCode'

const UNIT_CHARACTERS = { NOM: '$', PCT: '%' }

export const DeptLocAisleDrilldown = ({ timeframe, settimeframe, fpToggle }) => {
  const [unit, setUnit] = useState('NOM')
  const { strNbr = 0, deptNbr: deptNbrInt = 0, aisle = 0 } = useQueryParams();
  const {
    data: queryData,
    isError,
    isLoading
  } = useQuery(['querySwmLocBayDept', { strNbr, deptNbrInt, aisle, fpToggle }], getData)
  const bayDataFiltered = useMemo(() => {
    if (isLoading) return []

    return queryData.filter((row) => {
      return (
        row &&
        row['TRNSFM_TYP_SORT_ID'] === timeframeCode[timeframe]
      )
    })
  }, [queryData, isLoading, timeframe])

  const isNOM = unit === 'NOM'

  if (isError) return <h1>Something went wrong</h1>
  if (isLoading) return <Loader active>Loading...</Loader>

  const backToDepartmentLink = `/store/deptLoc?strNbr=${strNbr}&deptNbr=${deptNbrInt}`
  const dataObj = {
    data: bayDataFiltered.map((row) => {
      return [
        {
          link: {
            to: '/store/deptLoc/aisle/bay',
            params: { 
              strNbr,
              deptNbr: deptNbrInt,
              aisle: row['AISLE'],
              bay: row['BAY'],
            },
          },
          stringValue: row['BAY'],
        },
        {
          customStyle: getCalloutStyle(row['SLS_AMT']) ,
          orderBy: row['SLS_AMT'],
          stringValue: getDollarString(row['SLS_AMT'])
        },
        {
          customStyle: getCompareStyle(row[`SLS_COMP_${unit}`]),
          orderBy: row[`SLS_COMP_${unit}`],
          stringValue: isNOM ? getDollarString(row['SLS_COMP_NOM']) : getPercentString(row['SLS_COMP_PCT']),
        },
        {
          customStyle: getCalloutStyle(row['UNT_SLS']),
          numValue: row['UNT_SLS'],
          decimals: 0,
        },
        {
          customStyle: getCompareStyle(row[`UNT_COMP_${unit}`]),
          orderBy: row[`UNT_COMP_${unit}`],
          stringValue: isNOM ? row['UNT_COMP_NOM'].toLocaleString(undefined, { maximumFractionDigits: 0 }) : getPercentString(row['UNT_COMP_PCT']),
        }
      ]
    }),
    headers: [
      { name: 'Bay ' },
      { name: 'Sales Amt ' },
      { name: `Sales Comp ${UNIT_CHARACTERS[unit]} ` },
      { name: 'Unit Sales ' },
      { name: `Unit Comp ${isNOM ? '#' : '%'} ` },
    ],
    sortable: true,
    sortableDefault: 0,
  }

  return (
    <>
      <Grid>
        <Grid.Column width={8}>
          <Grid.Row>
            <Link data-test="b2o-link" style={{ color: '#EE7125' }} to={backToDepartmentLink}>
              Back to Department
            </Link>
          </Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
          <Grid.Row>Units:</Grid.Row>
          <Grid.Row>
            <UnitMenu unit={unit} setUnit={setUnit} />
          </Grid.Row>

        </Grid.Column>
        <Grid.Column width={8}>
          <Grid.Row>&nbsp;</Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
          <Grid.Row>Timeframe:</Grid.Row>
          <Grid.Row>
            <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
          </Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
        </Grid.Column>
      </Grid>
      
      <Header textAlign="center">Bays - {deptNbrInt}-{queryData[0]?.DEPT_NM} - Aisle {aisle}</Header>

      <DataTable data={dataObj} />
    </>
  )
}
