import { useState } from 'react';
import { DataTable } from '../../../../../../../components/Common/DataTable';
import { Header, Tab } from 'semantic-ui-react';
import { DEPARTMENTS_HEADER_TABS, salesByDepartmentRowBuilder } from '../../../../../utils/sales';
import { UnitMenu } from '../../../../../../../components/Common/UnitMenu';
import { useCoreOverviewContext } from '../../../../../hooks/useCoreOverviewContext';

export const DeparmentsTab = () => {
  const [unit, setUnit] = useState('NOM');
  const { salesDeptData, timeframe } = useCoreOverviewContext();

  const data = {
    sortable: true,
    sortableDefault: 0,
    headers: DEPARTMENTS_HEADER_TABS,
    data: salesDeptData.map(row => salesByDepartmentRowBuilder(row, timeframe, unit))
  };

  return (
    <Tab.Pane className='mt-3'>
      <Header textAlign='left'>Sales by Departments</Header>
      <p>Units:</p>
      <UnitMenu unit={unit} setUnit={setUnit} />
      <DataTable data={data} />
    </Tab.Pane>
  );
};
