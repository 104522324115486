import React from 'react'
import {
  Grid,
  Header,
  Loader,
} from 'semantic-ui-react'
import { withRouter, Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useQuery } from 'react-query'
import { TimeframeDropdown } from '../Common/TimeframeDropdown'
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon'
import { DataTable } from '../Common/DataTable'

import {
  getData
} from '../../service/DataService'

import { IMAGING_GOAL } from './OSAImaging'
import { columnName, METRIC_CONSTANTS } from '../../utils/constantsByLevel';
import { capitalizeFirstLetter } from '../../utils/string';

export const OSAImagingDist = withRouter(({ timeframe, settimeframe, fpToggle, locNbr, levelName, ...props }) => {
  const { level } = useParams()
  const osaImagingQuery = useQuery([`query/osaImageCapturePlusOne/${level}`, { locNbr, fpToggle }], getData)
  if (osaImagingQuery.isLoading) {
    return (<Loader active>Loading</Loader>)
  }
  if (!osaImagingQuery.data || osaImagingQuery.data.length === 0) {
    return (<Header textAlign='center'>Invalid Store Number</Header>)
  }

  const lastTimeframeMap = {
    'WTD': 'LW',
    'LW': null, // We don't have comparisons for last week or month, so they'll be null
    'LM': null,
    'MTD': 'LM',
    'QTD': 'LQ',
    'HTD': 'LH',
    'YTD': 'YTD_LY',
    'L12': 'L12_LY',
  }

  const ltf = lastTimeframeMap[timeframe]

  const storeSummary = osaImagingQuery.data.reduce((acc, row) => {
    const ColumField = row[METRIC_CONSTANTS[level]['propToRender']].toString().padStart(4, '0')
    if (!acc[ColumField]) {
      acc[ColumField] = { actual: 0, goal: 0, lastActual: 0, lastGoal: 0 }
    }
    acc[ColumField].actual += row[`${timeframe}_IMAGES_TAKEN`]
    acc[ColumField].goal += row[`${timeframe}_IMAGES_EXPECTED`]
    if (ltf) {
      acc[ColumField].lastActual += row[`${ltf}_IMAGES_TAKEN`]
      acc[ColumField].lastGoal += Number(row[`${ltf}_IMAGES_EXPECTED`])
    } else {
      acc[ColumField].lastActual = null
      acc[ColumField].lastGoal = null
    }
    return acc
  }, {})

  const storeData = {
    sortable: true,
    headers: [{ name: `${capitalizeFirstLetter(METRIC_CONSTANTS[level]['urlLinkLevel'])} ` }, { name: 'Actual ' }, { name: 'Goal ' }, { name: ltf ? `v${ltf} bps ` : '- ' },],
    data:
      Object.entries(storeSummary).map(([store, dat]) => {
        const checkValues = ['L12', 'YTD'].includes(timeframe) && dat.lastGoal === 0 // prevent NaN bps value, no values from previous year
        return [
          {
            stringValue: store,
            link: {
              to: `/osa/${METRIC_CONSTANTS[level]['urlLinkLevel']}/imaging/`,
              params: {
                [METRIC_CONSTANTS[level]['urlSubLevelParam']]: store,
              }
            }
          },
          { pctValue: dat.actual / dat.goal, decimals: 1, compareValue: IMAGING_GOAL },
          { pctValue: IMAGING_GOAL },
          (ltf && !checkValues) ? {
            numValue: Math.round(((dat.actual / dat.goal) - (dat.lastActual / dat.lastGoal)) * 10000),
            compareStyle: true,
            postfix: ' bps',
          } : { stringValue: '--' },
        ]
      }),
    sortableDefault: 0
  }

  return (
    <>
      <Grid>
        <Grid.Column width={8}>
          <Grid.Row>
            <Link style={{ color: '#EE7125' }} to={`/${level}/overview?${METRIC_CONSTANTS[level]['urlParam']}=${locNbr}`} data-test='b2o-link'>Overview</Link>
            {' / '}
            <Link style={{ color: '#EE7125' }} to={`/osa?${METRIC_CONSTANTS[level]['urlParam']}=${locNbr}`} data-test='b2osa-link'>OSA</Link>
            {' / Imaging%'}
          </Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
        </Grid.Column>
        <Grid.Column width={8}>
          Timeframe:
          {new Date().getDay() === 1 ?
            <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} /> :
            <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />}
        </Grid.Column>
      </Grid>
      <Header textAlign='center' style={{ textTransform: 'capitalize' }} >
        Overhead Imaging %, {level} {locNbr}, {String(osaImagingQuery.data[0][columnName[level]]).toLowerCase()}
        <Header.Subheader>Data as of {osaImagingQuery.data[0]?.TRNSFM_DT}</Header.Subheader>
      </Header>
      <DataTable data={storeData} />
    </>
  )
})