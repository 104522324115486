import useQueryApi from '../../../hooks/useQueryApi';

export const useOverviewOsaQueries = fpToggle => {
  const defaultParams = { fpToggle, isCore: true };
  const {
    data: osaHighlevel = [],
    isError: isErrorHighlevel,
    isLoading: isLoadingHighlevel
  } = useQueryApi(`querySidekickExeScoresPlusOneCore`, defaultParams);

  const {
    data: osaDriverSummary = [],
    isError: isErrorDriverSummary,
    isLoading: isLoadingDriverSummary
  } = useQueryApi(`queryOSADriverSummaryPlusOneCore`, defaultParams);

  const isError = isErrorHighlevel || isErrorDriverSummary;
  const isLoading = isLoadingHighlevel || isLoadingDriverSummary;

  return {
    isError,
    isLoading,
    osaHighlevel,
    osaDriverSummary
  };
};
