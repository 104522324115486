export const uniqueValues = (arr = []) => Array.from(new Set(arr))

export const findBiggestObject = (arr = [], prop) => {
  const arrIndex = arr.reduce((acc, curr, index) => {
    if (curr[prop] > arr[acc][prop]) return index

    return acc
  }, 0)
  
  return arr[arrIndex]
}

export const getPrevAndNextElement = (arr = [], currentIndex) => {
  if (!arr.length) return [null, null]

  const previous = arr[(currentIndex + arr.length - 1) % arr.length]
  const next = arr[(currentIndex + 1) % arr.length]

  return [previous, next]
}
