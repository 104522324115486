
import PropTypes from 'prop-types';
import { Dropdown, Grid } from 'semantic-ui-react';

export const MetricDropDownMenu = ({ optionList, setMetric, metric }) => {
    return (
        <Grid.Column textAlign='justified' width={8}>
            <Grid.Row>
                Metric:
            </Grid.Row>
            <Grid.Row>
                <Dropdown
                    defaultValue={metric}
                    selection
                    scrolling
                    compact
                    size='tiny'
                    options={optionList}
                    onChange={(_, { value }) => {
                        setMetric(value)
                    }}
                />
            </Grid.Row>
        </Grid.Column>
    )
}


MetricDropDownMenu.propTypes = {
    optionList: PropTypes.arrayOf({
        key: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
    }).isRequired,
    metric: PropTypes.string.isRequired,
    setMetric: PropTypes.func.isRequired
}