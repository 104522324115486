import { getCalloutStyle, getCompareStyle } from "../components/Common/TableCellUtils"
import { FORMATMETRIC } from "../constants/deptDrilldown"

// function used to parse cell values of top/bottom DeptDrilldown cells
export const topBottomFormat = ({ metric, value, table }) => {
    // dollarValue is used to parse 0 into $0.00, following current pattern in tables
    const dollarValue = value === 0 ? '$' + value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : value
    if (metric === 'SLS_AMT') {
        return {
            dollarValue,
            decimals: 2,
            customStyle: getCalloutStyle(value)
        }
    }
    if (metric === 'SLS_COMP_NOM') {
        return {
            dollarValue,
            decimals: 2,
            compareStyle: true,
            customStyle: table === 'bottom' ? getCompareStyle(value) : undefined
        }
    }
    if (metric === 'SLS_COMP_PCT') {
        return {
            pctValue: value,
            compareStyle: true,
            decimals: 2
        }
    }
    if (metric === 'UNT_SLS') {
        return {
            //toLocaleString to parse number from 12345.67 to 12,345.67
            numValue: value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }),
            customStyle: getCalloutStyle(value)
        }
    }
    if (metric === 'UNT_COMP_NOM') {
        return {
            //toLocaleString to parse number from 12345.67 to 12,345.67
            numValue: value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }),
            compareStyle: true
        }
    }
    if (metric === 'UNT_COMP_PCT') {
        return {
            pctValue: value,
            compareStyle: true,
            decimals: 2
        }
    }
}

export const getTopBtmRecords = ({ rows, metric, link, tab }) => {
    // FILTER AND ORDER RECORDS BY METRIC
    const sortedRows = rows.filter(row => row['METRIC'] === metric).sort((a, b) => a['VALUE'] > b['VALUE'] ? -1 : 1);
    // GENERATE TOP/BOTTOM RECORDS TOP MAP IN DATATABLE COMPONENT
    const topRecords = tableBuilder({ rows: sortedRows.slice(0, 5), positionTable: 'top', tab, link, metric });
    const bottomRecords = tableBuilder({ rows: sortedRows.slice(-5), positionTable: 'bottom', tab, link, metric });
    return {
        topRecords,
        bottomRecords
    }
}

const tableBuilder = ({ rows, link, metric, tab, positionTable }) => {
    return {
        headers: [
            { name: CONSTANTS_BY_TAB[tab]['columnName'] },
            { name: FORMATMETRIC[metric] }
        ],
        data: rows.map(row => [
            {
                stringValue: row['NAME'],
                link: link ? {
                    to: link,
                    params: {
                        [CONSTANTS_BY_TAB[tab]['urlParam']]: row['NAME'].slice(0, CONSTANTS_BY_TAB[tab]['urlParamLength'])
                    }
                } : undefined
            },
            topBottomFormat({ metric, value: row['VALUE'], table: positionTable })
        ])
    }
}

const CONSTANTS_BY_TAB = {
    topBtmClasses: {
        urlParam: 'classNbr',
        columnName: 'Class',
        urlParamLength: 7
    },
    topBtmSubClasses: {
        urlParam: 'subClassNbr',
        columnName: 'Subclass',
        urlParamLength: 11
    },
    topBtmSkus: {
        urlParam: null,
        columnName: 'SKU',
        urlParamLength: null
    }
}
