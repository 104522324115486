import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { OnlineVoc } from './Region/OnlineVoc';
import { getData } from '../../service/DataService';
import { useQueryParams } from '../../hooks/useQueryParams';
import { VocDetailsComponent } from './VocDetailsComponent';
import { TimeframeDropdown } from '../Common/TimeframeDropdown';
import { Loader, Grid, Dropdown, Segment } from 'semantic-ui-react';
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon';
import { METRIC_CONSTANTS } from '../../utils/constantsByLevel';
import { useFetchLevelName } from '../../hooks/useFetchLevelName';

const QUERIES = {
  store: { // There's a bug where the level stays 'store' when hitting the back button, so this compensates
    vocQuery: 'queryOnlineVocSmryDstByDst',
    onlineVoc: 'queryOnlineVocSmryStrByDst'
  },
  district: {
    vocQuery: 'queryOnlineVocSmryDstByDst',
    onlineVoc: 'queryOnlineVocSmryStrByDst'
  },
  region: {
    vocQuery: 'queryOnlineVocSmryRgnByRgn',
    onlineVoc: 'queryOnlineVocSmryDstByRgn'
  },
  division: {
    vocQuery: 'queryOnlineVocSmryDivByDiv',
    onlineVoc: 'queryOnlineVocSmryRgnByDiv'
  },
  core: {
    vocQuery: 'queryOnlineVocSmryCoreByCore',
    onlineVoc: 'queryOnlineVocSmryCoreByCore'
  }
};

export const VocDetailDst = ({ timeframe, settimeframe, fpToggle, level }) => {
  const [metric, setMetric] = useState('LTPA');
  const { dstNbr = 0, rgnNbr = 0, divNbr = 0 } = useQueryParams();
  const locNbr = dstNbr || rgnNbr || divNbr
  const vocQuery = useQuery([QUERIES[level]['vocQuery'], { locNbr, fpToggle }], getData);
  const onlineVocQuery = useQuery([QUERIES[level]['onlineVoc'], { locNbr, fpToggle }], getData);
  const { currentLevelName, isLoadingName} = useFetchLevelName({ fpToggle, level, locNbr })

  if (vocQuery.isLoading || onlineVocQuery.isLoading || isLoadingName) {
    return <Loader active>Loading...</Loader>;
  }

  let overviewDataFilter = vocQuery.data.filter(row => {
    return row && row['TIME_FRAME'] === timeframe;
  });

  return (
    <Segment fluid>
      <Grid>
        <Grid.Row>
          <Grid.Column width={8} textAlign='center'>
            <Grid.Row>&nbsp;</Grid.Row>
            {
              <Link
                style={{ color: '#EE7125' }}
                to={`/${level}/overview?${METRIC_CONSTANTS[level]['urlParam']}=${locNbr}`}
                data-test='b2o-link'>
                Back to List
              </Link>
            }
          </Grid.Column>
          <Grid.Column width={8}>
            Timeframe:
            {new Date().getDay() === 1 ? (
              <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} />
            ) : (
              <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <VocDetailsComponent
        dataFilter={overviewDataFilter}
        level={level}
        levelNumber={locNbr}
        levelName={currentLevelName}
      />
      &nbsp;
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            Select Metric:
            <Dropdown
              fluid
              compact
              selection
              defaultValue='LTPA'
              options={[
                { key: 'LTPA', text: 'LTPA', value: 'LTPA' },
                { key: 'ProdCond', text: 'Product Condition', value: 'ProdCond' },
                { key: 'OrderAcc', text: 'Order Accuracy', value: 'OrderAcc' },
                { key: 'WaitTm', text: 'Reasonable Wait Time', value: 'WaitTm' }
              ]}
              onChange={(_, { value }) => {
                setMetric(value);
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <OnlineVoc onlineVocList={onlineVocQuery.data} level={level} metric={metric} timeframe={timeframe} />
    </Segment>
  );
};
