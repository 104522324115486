import React from 'react';
import { Tab } from 'semantic-ui-react';
import { CoreCoreEvents } from './CoreCoreEvents';
import { CoreTotalEvents } from './CoreTotalEvents';
import { CoreNonCoreEvents } from './CoreNonCoreEvents';

export const CoreEventListAtDivision = ({ events }) => {
  return (
    <Tab.Pane>
      <CoreTotalEvents events={events} />
      <CoreCoreEvents events={events} />
      <CoreNonCoreEvents events={events} />
    </Tab.Pane>
  );
};
