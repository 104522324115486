import { useState } from 'react';
import { Container, Menu } from 'semantic-ui-react';
import { DataView } from '../../../../../../components/Common/DataView';
import { getCompareStyle } from '../../../../../../components/Common/TableCellUtils';
import { TimeframeDropdown } from '../../../../../../components/Common/TimeframeDropdown';

export const SafetySummary = ({ settimeframe, timeframe, safetySummary }) => {
  const [incOshaLt, setIncOshaLt] = useState('_INCIDENT_');

  const menuOptions = [
    { name: 'INCIDENT', value: '_INCIDENT_' },
    { name: 'OSHA', value: '_OSHA_' },
    { name: 'LOST TIME', value: '_LOST_TIME_' }
  ];

  const safetySummaryDataObj = {
    headers: [{ name: 'Org # ' }, { name: 'Actual ' }, { name: 'vP ' }, { name: 'vLY ' }],
    sortable: true,
    sortableDefault: 0,
    data: safetySummary.map((row, index) => [
      {
        stringValue: row['DIV_NM'],
        link: {
          to: `/division/safety?divNbr=${safetySummary[index]['DIV_NBR']}`
        },
        orderBy: row['DIV_NM']
      },
      {
        numValue: row[timeframe + incOshaLt + 'COUNT_WC_TOTAL']
      },
      {
        stringValue: incOshaLt === '_INCIDENT_' ? '-' : undefined,
        pctValue:
          incOshaLt === '_OSHA_'
            ? (row[timeframe + '_OSHA_COUNT_WC_TOTAL'] - row[timeframe + '_WC_COUNTS_PLAN']) /
              row[timeframe + '_WC_COUNTS_PLAN']
            : (row[timeframe + '_LOST_TIME_COUNT_WC_TOTAL'] - row[timeframe + '_LT_COUNTS_PLAN']) /
              row[timeframe + '_LT_COUNTS_PLAN'],
        customStyle:
          incOshaLt === '_INCIDENT_'
            ? undefined
            : incOshaLt === '_OSHA_'
              ? getCompareStyle(row[timeframe + '_WC_COUNTS_PLAN'] - row[timeframe + '_OSHA_COUNT_WC_TOTAL'])
              : getCompareStyle(row[timeframe + '_LT_COUNTS_PLAN'] - row[timeframe + '_LOST_TIME_COUNT_WC_TOTAL'])
      },
      {
        pctValue:
          (row[timeframe + incOshaLt + 'COUNT_WC_TOTAL'] - row[timeframe + '_LY' + incOshaLt + 'COUNT_WC_TOTAL']) /
          row[timeframe + '_LY' + incOshaLt + 'COUNT_WC_TOTAL'],
        customStyle: getCompareStyle(
          row[timeframe + '_LY' + incOshaLt + 'COUNT_WC_TOTAL'] - row[timeframe + incOshaLt + 'COUNT_WC_TOTAL']
        )
      }
    ])
  };

  return (
    <div>
      <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
        <span>Timeframe:</span>
        <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
      </Container>
      <DataView.Title position='center'>Safety Summary</DataView.Title>
      <Menu fluid attached='top' widths={3} color='orange'>
        {menuOptions.map(({ name, value }) => (
          <Menu.Item
            key={value}
            name={value}
            active={incOshaLt === value}
            content={name}
            onClick={() => {
              setIncOshaLt(value);
            }}
          />
        ))}
      </Menu>
      <DataView.DataTable data={safetySummaryDataObj} />
    </div>
  );
};
