import React from "react";
import { Menu } from "semantic-ui-react";

export const DeptAssocToggle = ({ DeptOrAssoc, setDeptOrAssoc }) => {
  return (
    <Menu compact size="small">
      <Menu.Item
        name="DEPARTMENT"
        active={DeptOrAssoc === "DEPARTMENT"}
        content="DEPARTMENT"
        onClick={() => {
            setDeptOrAssoc("DEPARTMENT");
        }}
      />
      <Menu.Item
        name="ASSOCIATES"
        active={DeptOrAssoc === "ASSOCIATES"}
        content="ASSOCIATES"
        onClick={() => {
            setDeptOrAssoc("ASSOCIATES");
        }}
      /> 
    </Menu>
  );
};
