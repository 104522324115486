import { useState } from 'react';
import { Menu } from 'semantic-ui-react';
import { DataView } from '../../../../../components/Common/DataView';
import { UnitsSellMenu } from '../../../../../components/Events/ UnitsSellMenu';

export const CoreNonCoreEvents = ({ events }) => {
  const [salesOrUnits, setSalesOrUnits] = useState('Units #');

  const dataObj = {
    headers: [
      { name: 'Division # ' },
      { name: 'Sales ' },
      { name: salesOrUnits === 'Units #' ? 'Unit Sell-Thru % ' : 'Sell-Thru % ' },
      { name: salesOrUnits === 'Units #' ? 'Residual # ' : 'Residual $ ' }
    ],
    data: events.map(event => {
      return [
        {
          stringValue: event['DIV_NBR'],
          link: {
            to: '/division/events/detail',
            params: { divNbr: event['DIV_NBR'], evntId: event['EVNT_ID'] }
          }
        },
        { dollarValue: event['NC_EVNT_SLS_AMT'] },
        {
          pctValue:
            salesOrUnits === 'Sales $'
              ? event['ST_NC_NUM'] / event['ST_NC_DEN']
              : event['ST_NC_NUM_UNITS'] / event['ST_NC_DEN_UNITS']
        },
        {
          stringValue: salesOrUnits === 'Units #' ? event['NC_RESID_UNITS']?.toLocaleString() : undefined,
          dollarValue: salesOrUnits === 'Sales $' ? event['NC_RESID'] : undefined
        }
      ];
    }),
    sortable: true,
    pagination: true,
    sortableDefault: 0
  };

  return (
    <DataView className='mt-4'>
      <DataView.Controls>
        <Menu compact size='small' style={{ textAlign: 'left' }}>
          <UnitsSellMenu salesOrUnits={salesOrUnits} setSalesOrUnits={setSalesOrUnits} />
        </Menu>
      </DataView.Controls>
      <DataView.Title>Non-Core</DataView.Title>
      <DataView.DataTable data={dataObj} />
    </DataView>
  );
};
