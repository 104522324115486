import { useMemo } from 'react';
import useQueryApi from './useQueryApi';
import { METRIC_CONSTANTS } from '../utils/constantsByLevel';

export const useOsaSideKickExeScoresPlusOneQuery = ({ level, locNbr, fpToggle }) => {
  const subLevel = METRIC_CONSTANTS[level].subLevel;
  const subCurrentLevel = subLevel.currentLevel;
  const { data = [], isError, isLoading } = useQueryApi(`query/sidekickExeScoresPlusOne/${level}`, { locNbr, fpToggle });
  const dataAsMap = useMemo(() => {
    if (isLoading) return {};

    return Object.fromEntries(data.map(row => [row[subCurrentLevel], row]));
  }, [data, isLoading]);

  return { data, dataAsMap, isDataEmpty: data.length === 0, isError, isLoading };
};
