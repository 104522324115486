import { Dropdown, Header, Tab } from 'semantic-ui-react';
import { UnitMenu } from '../../../../../../../components/Common/UnitMenu';
import { useMemo, useState } from 'react';
import { DataTable } from '../../../../../../../components/Common/DataTable';
import { useCoreOverviewContext } from '../../../../../hooks/useCoreOverviewContext';
import { timeframeCode } from '../../../../../../../components/Common/timeframeCode';
import {
  SKUS_HEADERS,
  SKUS_RANK_ORDER,
  SKUS_SORT_OPTIONS,
  skusColumnBuilder,
  TRNSFM_TYP_SORT_ID
} from '../../../../../utils/skus';
import { DataView } from '../../../../../../../components/Common/DataView';
import { MenuItems } from '../../../../../../../components/Common/MenuItems';

export const SkusTab = () => {
  const [{ value: TY_SLS_AMT }, { value: TY_UNT_SLS }] = SKUS_SORT_OPTIONS;
  const [TOP] = SKUS_RANK_ORDER;
  const [unit, setUnit] = useState('NOM');
  const [sortOption, setSortOption] = useState(TY_SLS_AMT);
  const [rankOrder, setRankOrder] = useState(TOP);

  const { salesSkusData, timeframe } = useCoreOverviewContext();

  const salesSkusDataFiltered = useMemo(
    () => salesSkusData?.filter(row => row && row[TRNSFM_TYP_SORT_ID] === timeframeCode[timeframe]),
    [salesSkusData, timeframe]
  );

  const sortedData = useMemo(() => {
    if (!salesSkusDataFiltered) return [];

    return [...salesSkusDataFiltered]
      .sort((a, b) => {
        if (sortOption === TY_SLS_AMT) {
          if (b.TY_SLS_AMT !== a.TY_SLS_AMT)
            return rankOrder === TOP ? b.TY_SLS_AMT - a.TY_SLS_AMT : a.TY_SLS_AMT - b.TY_SLS_AMT;
          return rankOrder === TOP ? b.TY_UNT_SLS - a.TY_UNT_SLS : a.TY_UNT_SLS - b.TY_UNT_SLS;
        }
        if (sortOption === TY_UNT_SLS) {
          if (b.TY_UNT_SLS !== a.TY_UNT_SLS)
            return rankOrder === TOP ? b.TY_UNT_SLS - a.TY_UNT_SLS : a.TY_UNT_SLS - b.TY_UNT_SLS;
          return rankOrder === TOP ? b.TY_SLS_AMT - a.TY_SLS_AMT : a.TY_SLS_AMT - b.TY_SLS_AMT;
        }
        return 0;
      })
      .slice(0, 40);
  }, [salesSkusDataFiltered, sortOption, TY_SLS_AMT, TY_UNT_SLS, rankOrder, TOP]);

  const isMetricSales = sortOption === TY_SLS_AMT;
  const isUnitNom = unit === 'NOM';
  const headers = SKUS_HEADERS(isMetricSales, isUnitNom, unit);

  const data = {
    sortable: true,
    sortableDefault: 0,
    headers,
    data: sortedData.map((row, index) => skusColumnBuilder(row, index, unit + sortOption))
  };

  return (
    <Tab.Pane className='mt-3'>
      <Header textAlign='left'>Top 40 & Bottom 40 Skus</Header>
      <DataView.Controls className='d-flex gap-3'>
        <section>
          <p>Units:</p>
          <UnitMenu unit={unit} setUnit={setUnit} />
        </section>
        <section>
          <p>Metrics:</p>
          <Dropdown
            className='mx-3 mx-0'
            compact
            options={SKUS_SORT_OPTIONS}
            placeholder='Metric'
            selection
            value={sortOption}
            onChange={(_, { value }) => setSortOption(value)}
            style={{ width: '150px', height: 'auto' }}
          />
        </section>
        <section>
          <p>Rank:</p>
          <MenuItems
            activeItem={rankOrder}
            dataTest='rankOrder'
            items={SKUS_RANK_ORDER}
            setItem={setRankOrder}
            size='small'
          />
        </section>
      </DataView.Controls>

      <DataTable data={data} />
    </Tab.Pane>
  );
};
