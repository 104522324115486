import { Header } from 'semantic-ui-react';
import { DataTable } from '../Common/DataTable';
import { getCalloutStyle, getPercentString, getThousandDollarString } from '../Common/TableCellUtils';

export const IntradayViewTable = ({ data }) => {
  const intradayObj = {
    data: data.map(row => {
      return [
        {
          stringValue: row['AS_OF'],
          orderBy: row['SLS_HR'] // AS_OF returns the value as 7am, 8am, 7pm... if we order by that instead of 7, 8, 19 the order will be incorrect
        },
        {
          customStyle: getCalloutStyle(row['CUMMU_SLS']),
          stringValue: getThousandDollarString(row['CUMMU_SLS'], 1)
        },
        {
          customStyle: getCalloutStyle(row['DOL_TO_DAILY_PLN']),
          stringValue: getThousandDollarString(row['DOL_TO_DAILY_PLN'], 1)
        },
        {
          customStyle: getCalloutStyle(row['PCT_TO_DAILY_PLN']),
          stringValue: getPercentString(row['PCT_TO_DAILY_PLN'], 1, 1)
        },
        {
          customStyle: getCalloutStyle(row['COMP_PCT']),
          stringValue: getPercentString(row['COMP_PCT'], 1, 1)
        }
      ];
    }),
    headers: [
      {
        element: (
          <span>
            As Of
            <br />
            (Local Time)
          </span>
        ),
        name: 'local-time'
      },
      {
        element: (
          <span>
            Cumulative
            <br />
            Sales $ (k)
          </span>
        ),
        name: 'sales'
      },
      { name: '$ vPlan (k)' },
      {
        element: (
          <span>
            % to
            <br />
            Daily Plan
          </span>
        ),
        name: 'daily-plan'
      },
      { name: 'Comp %' }
    ],
    sortable: true,
    sortableColumns: [0],
    sortableDefault: 0
  };

  return (
    <>
      <Header textAlign='center'>Intraday View</Header>

      <DataTable data={intradayObj} />
    </>
  );
};
