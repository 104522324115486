import React from 'react';
import { Link } from 'react-router-dom';
import { DataView } from '../../Common/DataView';
import useQueryApi from '../../../hooks/useQueryApi';
import { Loader, Header, Grid } from 'semantic-ui-react';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { TimeframeDropdown } from '../../Common/TimeframeDropdown';
import { getThousandDollarString } from '../../Common/TableCellUtils';
import { capitalizeFirstLetter as capitalize } from '../../../utils/string';

const SMD_DEPT_QUERIES = {
  region: {
    endPoint: 'querySmdDeptByRgn'
  },
  division: {
    endPoint: 'querySmdDeptByDiv'
  }
};

export const SmdRgnDept = ({ timeframe, settimeframe, fpToggle }) => {
  const { divNbr = 0, rgnNbr = 0, deptNbr: deptNbrInt = 0 } = useQueryParams();
  const orgNbr = divNbr || rgnNbr;
  const level = divNbr ? 'division' : 'region';
  const metric = level === 'division' ? 'RGN' : 'DST';

  const params = { locNbr: divNbr || rgnNbr, fpToggle };
  const { data, isLoading, isError } = useQueryApi(SMD_DEPT_QUERIES[level].endPoint, params);

  if (isLoading) return <Loader active>Loading...</Loader>;
  if (isError) return <p>Something went wrong.</p>;

  let filteredData = data.filter(row => {
    return row && row['DEPT_NBR'].toString() === deptNbrInt && row['METRIC_LEVEL'] === metric;
  });
  const orgName = level === 'division' ? filteredData[0]['RGN_NM'] : filteredData[0]['DST_NM'];

  const dataObj = {
    headers: [
      { name: level === 'division' ? 'Region #' : 'District # ' },
      { name: 'SMD $ ' },
      { name: 'SMD % ' },
      { name: 'SMD bps vP ' },
      { name: 'SMD $ Over Under ' }
    ],
    data: filteredData.map(row => {
      const smdVpBpsValue = calculateSmdVpBpsValue(row, timeframe);
      const smdVpValue = calculateSmdVpValue(row, timeframe);
      return [
        {
          stringValue: level === 'division' ? row['RGN_NBR'] : row['DST_NBR'],
          link: {
            to: level === 'division' ? `/region/smd/dept` : `/district/smd/dept`,
            params:
              level === 'division'
                ? { rgnNbr: row['RGN_NBR'], deptNbr: row['DEPT_NBR'] }
                : { dstNbr: row['DST_NBR'], deptNbr: row['DEPT_NBR'] }
          }
        },
        {
          stringValue: getThousandDollarString(row[timeframe + '_SMD'], 1)
        },
        {
          decimals: 2,
          pctValue: row[timeframe + '_SMD'] / row['TY_' + timeframe + '_SLS_AMT']
        },
        {
          decimals: 0,
          postfix: ' bps',
          compareStyle: true,
          numValue: smdVpBpsValue
        },
        {
          decimals: 1,
          compareStyle: true,
          numValue: smdVpValue
        }
      ];
    }),
    sortable: true,
    pagination: true,
    sortableDefault: 0
  };

  const orgLink = level === 'division' ? `/division/smd?divNbr=${divNbr}` : `/region/smd?rgnNbr=${rgnNbr}`;

  return (
    <>
      <Link style={{ color: '#EE7125' }} to={orgLink} data-test='b2o-link'>
        Back to SMD Overview
      </Link>

      <Header textAlign='left'>
        SMD - Departments - {capitalize(level)} {orgNbr}, {orgName}
        <Header.Subheader>Data as of {filteredData[0]['TRNSFM_DT']}</Header.Subheader>
      </Header>

      <DataView>
        <DataView.Controls>
          Timeframe:
          <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
        </DataView.Controls>
        <DataView.Title>{filteredData[0]['DEPT_NBR'] + '-' + filteredData[0]['DEPT_NM']}</DataView.Title>
        <DataView.DataTable data={dataObj} />
      </DataView>
      <br />
    </>
  );
};

const calculateSmdVpBpsValue = (row, timeframe) => {
  return (
    10000 *
    (row[timeframe + '_SMD'] / row['TY_' + timeframe + '_SLS_AMT'] -
      row[timeframe + '_MKDN_PLN_AMT'] / row[timeframe + '_SLS_PLN_AMT'])
  );
};

const calculateSmdVpValue = (row, timeframe) => {
  return (
    row[timeframe + '_SMD'] -
    (row[timeframe + '_MKDN_PLN_AMT'] * row['TY_' + timeframe + '_SLS_AMT']) / row[timeframe + '_SLS_PLN_AMT']
  );
};
