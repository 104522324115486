import useQueryApi from '../../../../../hooks/useQueryApi';

export const useOsaOhmNoLocTagsQueries = params => {
  const { fpToggle } = params;
  const queryParams = { fpToggle, isCore: true };

  const {
    data: osaOhmNoLocTagsByCoreData,
    isError: isErrorOsaOhmNoLocTagsByCore,
    isLoading: isLoadingOsaOhmNoLocTagsByCore
  } = useQueryApi('queryOsaOHMNoLocTagsPlusOneByCore', queryParams);

  const isError = isErrorOsaOhmNoLocTagsByCore;
  const isLoading = isLoadingOsaOhmNoLocTagsByCore;

  return { isError, isLoading, osaOhmNoLocTagsByCoreData };
};
