import { useMemo } from "react";
import { useQuery } from "react-query";
import { useQueryParams } from "./useQueryParams";
import { getData } from "../service/DataService";
import { METRIC_CONSTANTS } from "../utils/constantsByLevel";

const METRICS_TO_CHECK = [
  '_ACT_HRS',
  '_PROJ_OT_HRS',
  '_VF',
  '_VS',
  '_VF_PCT',
  '_VS_PCT',
  '_VF_WEEKDAY',
  '_VF_WEEKEND',
  '_VF_PCT_WEEKDAY',
  '_VF_PCT_WEEKEND',
  '_VS_WEEKDAY',
  '_VS_WEEKEND',
  '_VS_PCT_WEEKDAY',
  '_VS_PCT_WEEKEND',
  '_FCST_HRS',
  '_SCH_HRS',
  '_ACT_HRS_WEEKDAY',
  '_ACT_HRS_WEEKEND',
  '_FCST_HRS_WEEKDAY',
  '_FCST_HRS_WEEKEND'
];

const CLASSIFICATION_ENUM = {
  Manager: 0,
  Core: 1,
  Operations: 2,
  Specialty: 3,
  'Tool/Pro': 4,
  Supervisor: 5,
  Other: 6,
  Total: 7
};

const QUERIES = {
  region: {
    overview: 'queryLaborOverviewByRgn',
    detail: 'queryLaborDtlByRgn'
  },
  division: {
    overview: 'queryLaborOverviewByDiv',
    detail: 'queryLaborDtlByDiv'
  }
}

export const useLaborQuery = ({ fpToggle, timeframe, level }) => {
  const { rgnNbr, divNbr } = useQueryParams();
  const queryParam = rgnNbr || divNbr

  const {
    data: laborOverview,
    isError: isErrorOverview,
    isLoading: isLoadingOverview
  } = useQuery([QUERIES[level].overview, { locNbr: queryParam, fpToggle }], getData);
  const {
    data: laborDetailQuery,
    isError: isErrorLaborDetailQuery,
    isLoading: isLoadingLaborDetailQuery
  } = useQuery([QUERIES[level].detail, { locNbr: queryParam, fpToggle }], getData)

  const dataLaborOverviewFiltered = useMemo(() => {
    if (isLoadingOverview) return [];
    const filterData = laborOverview.filter(row => row['METRIC_LEVEL'] === METRIC_CONSTANTS[level].METRIC_LEVEL).filter(row => METRICS_TO_CHECK.some(key => row[timeframe + key] !== 0));
    return filterData.sort((left, right) =>
      CLASSIFICATION_ENUM[left['CLASSIFICATION']] > CLASSIFICATION_ENUM[right['CLASSIFICATION']] ? 1 : -1
    );
  }, [laborOverview, timeframe, isLoadingOverview, level]);

  const laborDetails = useMemo(() => {
    if (isLoadingOverview) return [];

    return laborOverview.filter(row => METRICS_TO_CHECK.some(key => row[timeframe + key] !== 0));
  }, [laborOverview, timeframe, isLoadingOverview]);

  return {
    data: dataLaborOverviewFiltered,
    laborDetailQuery,
    isError: isErrorLaborDetailQuery || isErrorOverview,
    isLoading: isLoadingLaborDetailQuery || isLoadingOverview,
    laborDetails,
    queryParam
  };
}
