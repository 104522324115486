import { DataView } from '../../../../../../components/Common/DataView';
import { getThousandDollarString } from '../../../../../../components/Common/TableCellUtils';

const HEADER_NAMES = {
  deptRef: 'Dept # ',
  smd: 'SMD ',
  smdPct: 'SMD % ',
  smdBpsVp: 'SMD bps vP ',
  smdOverUnder: 'SMD $ Over Under '
};
const { deptRef, smd, smdPct, smdBpsVp, smdOverUnder } = HEADER_NAMES;

const HEADERS = [{ name: deptRef }, { name: smd }, { name: smdPct }, { name: smdBpsVp }, { name: smdOverUnder }];

const SORTABLE = true;
const SORT_DEFAULT = HEADERS.findIndex(header => header.name === deptRef);

const getSmdDetailDepData = ({ data, timeframe }) => {
  return data.map(row => {
    const pctSmdValue = row[timeframe + '_SMD'] / row['TY_' + timeframe + '_SLS_AMT'];
    const smdBpsValue = pctSmdValue - row[timeframe + '_MKDN_PLN_AMT'] / row[timeframe + '_SLS_PLN_AMT'];
    const smdOverUnder =
      row[timeframe + '_SMD'] -
      (row[timeframe + '_MKDN_PLN_AMT'] * row['TY_' + timeframe + '_SLS_AMT']) / row[timeframe + '_SLS_PLN_AMT'];

    return [
      {
        link: {
          params: {
            deptNbr: row.DEPT_NBR,
            deptName: row.DEPT_NM
          },
          to: '/core/smd/dept'
        },
        stringValue: `${row['DEPT_NBR']}-${row['DEPT_NM']?.toUpperCase()?.replace(/\//g, ' ')}`
      },
      {
        orderBy: row[timeframe + '_SMD'],
        stringValue: getThousandDollarString(row[timeframe + '_SMD'], 1)
      },
      {
        decimals: 2,
        pctValue: pctSmdValue
      },
      {
        customStyle: { color: smdBpsValue > 0 ? 'red' : 'green' },
        orderBy: 10000 * smdBpsValue,
        postfix: ' bps',
        stringValue: (10000 * smdBpsValue).toLocaleString(undefined, { maximumFractionDigits: 0 })
      },
      {
        customStyle: { color: smdOverUnder > 0 ? 'red' : 'green' },
        stringValue: getThousandDollarString(smdOverUnder, 1)
      }
    ];
  });
};

const getSmdDetailDeptTableData = ({ data, timeframe, divNbr }) => {
  const smdDetailDepData = getSmdDetailDepData({ data, timeframe, divNbr });

  return {
    sortable: SORTABLE,
    sortableDefault: SORT_DEFAULT,
    headers: HEADERS,
    data: smdDetailDepData
  };
};

export const SmdDepartaments = ({ data, timeframe }) => {
  const smdDetailDeptTableData = getSmdDetailDeptTableData({ data, timeframe });
  return (
    <DataView className='mt-3' testId='data-view-smd-detail-departments'>
      <DataView.Title>SMD - Departments</DataView.Title>

      <DataView.DataTable data={smdDetailDeptTableData} />
    </DataView>
  );
};
