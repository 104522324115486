import { ACCESS_LEVELS } from '../Common/AccessChecks';
import { getParamByLevel, getLastTimeframe } from '../../utils/timeFrameUtils';
import {
  OSA_OH_AUTO_REJECT_RATE_GOAL,
  OSA_OHM_NO_LOC_TAGS_GOAL,
  OSA_OVERHEAD_IMAGING_GOAL,
  OSA_SIDEKICK_PURGE_GOAL,
  OSA_SIDEKICK_SKUBAY_GOAL,
  OSA_URGENT_TASK_EXEC_GOAL
} from '../../constants/goals';
import { useFetchLevelName } from '../../hooks/useFetchLevelName';
import useQueryApi from '../../hooks/useQueryApi';
import { Header, Loader } from 'semantic-ui-react';
import { Breadcrumb } from '../Common/Breadcrumb';
import { DataView } from '../Common/DataView';
import { TimeframeDropdown } from '../Common/TimeframeDropdown';
import { getSkuBayCompletedTasks, getSkuBayTaskGoal } from '../../utils/osa';
import { formatNumberWithCommas } from '../../utils/formatter';

export const OSALanding = ({
  timeframe,
  settimeframe,
  level,
  fpToggle,
  locNbr,
  userMgtLvlId,
  jobProfileNm,
  userIsCorporate
}) => {
  const { currentLevelName, isLoadingName } = useFetchLevelName({ level, locNbr, fpToggle });
  const { data, isError, isLoading } = useQueryApi(`query/queryOSADriverSummary/${level}`, { locNbr, fpToggle });
  const { data: sideExeData = [], isLoading: sideExeLoading } = useQueryApi(`query/sidekickExeScoresPlusOne/${level}`, {
    locNbr,
    fpToggle
  });

  if (isError) return <p>Something went wrong.</p>;
  if (isLoading || sideExeLoading || isLoadingName) return <Loader active>Loading</Loader>;

  if (!data || data.length === 0) return <Header textAlign='center'>Invalid Store Number</Header>;

  const osaSummaryData = data[0];
  const urlParam = getParamByLevel(level);
  const lastTimeFrame = getLastTimeframe(timeframe);

  // Limit who can get access to the drilldown pages
  const showDrilldown = userMgtLvlId <= ACCESS_LEVELS.DEPT_SUP || userIsCorporate || jobProfileNm === 'MERCH LEAD';

  const districtSummary = {
    tasksSkuBayCompleted: 0,
    tasksSkuBayCompletedGoal: 0,
    tasksPurgeCompleted: 0,
    tasksPurgeCompletedGoal: 0,
    tasksSkuBayCompletedLTF: 0,
    tasksSkuBayCompletedGoalLTF: 0,
    tasksPurgeCompletedLTF: 0,
    tasksPurgeCompletedGoalLTF: 0
  };

  if (Array.isArray(sideExeData)) {
    sideExeData.forEach(store => {
      districtSummary.tasksSkuBayCompleted += getSkuBayCompletedTasks(store, timeframe);
      districtSummary.tasksSkuBayCompletedGoal += getSkuBayTaskGoal(store, timeframe);
      districtSummary.tasksPurgeCompleted += parseInt(store[`${timeframe}_STR_PURGE_COMPLETED_TASK_COUNT`] ?? 0);
      districtSummary.tasksPurgeCompletedGoal += parseInt(store[`${timeframe}_STR_PURGE_TASK_GOAL`] ?? 0);

      districtSummary.tasksSkuBayCompletedLTF += getSkuBayCompletedTasks(store, lastTimeFrame);
      districtSummary.tasksSkuBayCompletedGoalLTF += getSkuBayTaskGoal(store, lastTimeFrame);
      districtSummary.tasksPurgeCompletedLTF += parseInt(store[`${lastTimeFrame}_STR_PURGE_COMPLETED_TASK_COUNT`] ?? 0);
      districtSummary.tasksPurgeCompletedGoalLTF += parseInt(store[`${lastTimeFrame}_STR_PURGE_TASK_GOAL`] ?? 0);
    });
  }

  const osaData = {
    headers: [{ name: '' }, { name: 'Actual' }, { name: 'Goal' }, { name: lastTimeFrame ? `v${lastTimeFrame} bps` : '-' }],
    data: [
      [
        {
          stringValue: 'Sidekick Exec - SKU/BAY',
          link: showDrilldown && {
            to: `/osa/sidekickskubay/${level}/`,
            params: {
              [urlParam]: locNbr
            }
          }
        },
        {
          pctValue: (districtSummary.tasksSkuBayCompleted || 0) / (districtSummary.tasksSkuBayCompletedGoal || 1),
          compareValue: OSA_SIDEKICK_SKUBAY_GOAL
        },
        {
          pctValue: OSA_SIDEKICK_SKUBAY_GOAL
        },
        {
          numValue: lastTimeFrame ?
          formatNumberWithCommas((((districtSummary.tasksSkuBayCompleted || 0) / (districtSummary.tasksSkuBayCompletedGoal || 1)) -
          ((districtSummary.tasksSkuBayCompletedLTF || 0) / (districtSummary.tasksSkuBayCompletedGoalLTF || 1)))
          * 10000)
            : '-',
          postfix: ' bps',
          compareStyle: true
        },
      ],
      [
        {
          stringValue: 'Sidekick Exec - Purge',
          link: showDrilldown && {
            to: `/osa/Purge/${level}/`,
            params: {
              [urlParam]: locNbr
            }
          }
        },
        {
          pctValue: (districtSummary.tasksPurgeCompleted || 0) / (districtSummary.tasksPurgeCompletedGoal || 1),
          compareValue: OSA_SIDEKICK_PURGE_GOAL
        },
        {
          pctValue: OSA_SIDEKICK_PURGE_GOAL
        },
        {
          numValue: lastTimeFrame ?
          formatNumberWithCommas((((districtSummary.tasksPurgeCompleted || 0) / (districtSummary.tasksPurgeCompletedGoal || 1)) -
          ((districtSummary.tasksPurgeCompletedLTF || 0) / (districtSummary.tasksPurgeCompletedGoalLTF || 1)))
          * 10000)
            : '-',
          postfix: ' bps',
          compareStyle: true
        }
      ],
      [
        {
          stringValue: 'Overhead Imaging %',
          link: showDrilldown && {
            to: `/osa/${level}/imaging/`,
            params: {
              [urlParam]: locNbr
            }
          }
        },
        {
          pctValue: osaSummaryData[timeframe + '_IMAGES_TAKEN'] / osaSummaryData[timeframe + '_IMAGES_EXPECTED'],
          compareValue: OSA_OVERHEAD_IMAGING_GOAL
        },
        {
          pctValue: OSA_OVERHEAD_IMAGING_GOAL
        },
        {
          numValue: lastTimeFrame
            ? formatNumberWithCommas((((osaSummaryData[timeframe + '_IMAGES_TAKEN'] || 0) / (osaSummaryData[timeframe + '_IMAGES_EXPECTED'] || 1)) -
              ((osaSummaryData[lastTimeFrame + '_IMAGES_TAKEN'] || 0) / (osaSummaryData[lastTimeFrame + '_IMAGES_EXPECTED'] || 1)))
              * 10000)
            : '-',
          postfix: ' bps',
          compareStyle: true
        }
      ],
      [
        {
          stringValue: 'OHM No Loc Tags %',
          link: showDrilldown && {
            to: `/osa/ohmnoloc${level === 'store' ? '' : '/' + level}`,
            params: {
              [urlParam]: locNbr
            }
          }
        },
        {
          pctValue: osaSummaryData[timeframe + '_NO_LOC_PALLET_COUNT'] / osaSummaryData[timeframe + '_TOTAL_PALLET_COUNT'],
          compareValue: OSA_OHM_NO_LOC_TAGS_GOAL,
          compareStyleInvert: true
        },
        {
          pctValue: OSA_OHM_NO_LOC_TAGS_GOAL,
          prefix: '<'
        },
        {
          numValue: lastTimeFrame
            ? formatNumberWithCommas((((osaSummaryData[timeframe + '_NO_LOC_PALLET_COUNT'] || 0) / (osaSummaryData[timeframe + '_TOTAL_PALLET_COUNT'] || 1)) -
              ((osaSummaryData[lastTimeFrame + '_NO_LOC_PALLET_COUNT'] || 0) / (osaSummaryData[lastTimeFrame + '_TOTAL_PALLET_COUNT'] || 1)))
              * 10000)
            : '-',
          postfix: ' bps',
          compareStyle: true
        }
      ],
      [
        { stringValue: 'Urgent Task Exec.' },
        {
          pctValue: osaSummaryData[timeframe + '_COMP_TASK_CNT_24_HR'] / osaSummaryData[timeframe + '_TOTAL_TASK_CNT_24_HR'],
          compareValue: OSA_URGENT_TASK_EXEC_GOAL
        },
        {
          pctValue: OSA_URGENT_TASK_EXEC_GOAL
        },
        { stringValue: '-' }
      ],
      [
        { stringValue: 'OH Auto Reject Rate' },
        {
          pctValue: osaSummaryData[timeframe + '_OH_AUTO_RJT_CNT'] / osaSummaryData[timeframe + '_OH_NEED_APPRV_CNT'],
          compareValue: OSA_OH_AUTO_REJECT_RATE_GOAL,
          compareStyleInvert: true
        },
        {
          pctValue: OSA_OH_AUTO_REJECT_RATE_GOAL,
          prefix: '<'
        },
        { stringValue: '-' }
      ]
    ]
  };

  const breadcrumbItems = [{ to: `/${level}/overview?${urlParam}=${locNbr}`, text: 'Overview' }, { text: 'OSA' }];

  return (
    <>
      <Breadcrumb items={breadcrumbItems} />

      <DataView className='mt-2'>
        <DataView.Title>
          On-Shelf Availability Metrics - {level} {locNbr}, {currentLevelName}
          <Header.Subheader>Data as of {osaSummaryData?.TRNSFM_DT}</Header.Subheader>
        </DataView.Title>

        <DataView.Controls className='align-items-center d-flex flex-column'>
          Timeframe:
          <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
        </DataView.Controls>
        <DataView.DataTable data={osaData} />
      </DataView>
    </>
  );
};
