import React, { useState, useEffect } from 'react'
import { Header, Grid, Table, Loader, Pagination, Icon } from 'semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getData } from '../../service/DataService'
import { getDollarString, getPercentString } from '../Common/TableCellUtils'

export const ShrinkClearanceNh = withRouter(({ fpToggle, location }) => {

  const search = new URLSearchParams(location.search)
  const strNbr = search.has('strNbr') ? search.get('strNbr') : 0

  const [deptNbr, setDeptNbr] = useState("All")
  const [sortColumn, setSortColumn] = useState("CLR_NH_INV_RETL")
  const [sortDirection, setSortDirection] = useState("descending")
  const [pageNum, setPageNum] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(20)

  const shrinkClearanceNhQuery = useQuery(['queryShrinkClearanceNh', { strNbr, fpToggle }], getData)

  useEffect(() => {
    setPageNum(1)
  }, [sortColumn, sortDirection, rowsPerPage, deptNbr])

  if (shrinkClearanceNhQuery.isLoading) {
    return (<Loader active>Loading...</Loader>)
  }

  if (!shrinkClearanceNhQuery.data) {
    return (<Header textAlign='center'>Invalid Store Number</Header>)
  }

  const data = shrinkClearanceNhQuery.data

  let dataFilterDept = data.filter((row) => {
    return deptNbr !== "All" ? row && row['DEPT_NBR'].toString() === deptNbr : row
  })

  let dataFilterZeros = dataFilterDept.filter((row) => {
    return row && row['CLR_NH_INV_RETL'] > 0.0
  })

  let clrInvRetlTotal = dataFilterDept.reduce((sum, obj) => sum + obj.CLR_INV_RETL, 0)
  let clrNhInvRetlTotal = dataFilterDept.reduce((sum, obj) => sum + obj.CLR_NH_INV_RETL, 0)
  let clrNhInvRetlPct = clrNhInvRetlTotal / clrInvRetlTotal

  let dataSorted = dataFilterZeros.sort((left, right) => {
    let result = left[sortColumn] - right[sortColumn]
    if (sortColumn === 'SKU_DESC') {
      result = left.SKU_DESC > right.SKU_DESC ? 1 : -1
    }
    if (sortColumn === 'SKU_CRT_DT') {
      result = left.SKU_CRT_DT > right.SKU_CRT_DT ? 1 : -1
    }
    if (sortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  let dataPagination = dataSorted.slice(rowsPerPage * (pageNum - 1), parseInt(Math.min(rowsPerPage * pageNum, dataSorted.length)))

  return (
    <>
      <Grid>
        <Grid.Column width={8} textAlign='left'>
          <Grid.Row><Link style={{ color: '#EE7125' }} to={`/store/shrink?strNbr=${strNbr}`} data-test='b2o-link'>Back to Shrink Overview</Link></Grid.Row>
          </Grid.Column>
          <Grid.Column width={8} textAlign='left'>
            <Grid.Row>Select Dept:</Grid.Row>
            <div>
              <select
                name="dept"
                onChange={e => {
                  setDeptNbr(e.target.value)
                }}
                value={deptNbr}
                style={{ padding: 10 }}
              >
                <option value="All" selected>All</option>
                <option value="21">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
                <option value="24">24</option>
                <option value="25">25</option>
                <option value="26">26</option>
                <option value="27">27</option>
                <option value="28">28</option>
                <option value="29">29</option>
                <option value="30">30</option>
                <option value="59">59</option>
              </select>
            </div>
          </Grid.Column>
       </Grid>

      <Header textAlign='center'>Clearance No-Homes - Store {strNbr}</Header>

      <Table textAlign='center' unstackable celled size='small' style={{ fontSize: 12 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Total Clearance</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Total Clearance No-Homes</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Total Clearance NH %</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>{getDollarString(clrInvRetlTotal)}</Table.Cell>
            <Table.Cell>{getDollarString(clrNhInvRetlTotal)}</Table.Cell>
            <Table.Cell>{getPercentString(clrNhInvRetlPct)}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Grid>
        <Grid.Column width={8} />
        <Grid.Column textAlign='justified' width={8}>
          <Grid.Row>
            Rows per Page:
          </Grid.Row>
          <Grid.Row>
            <div>
              <select
                name="rowsPerPage"
                onChange={e => {
                  setRowsPerPage(e.target.value)
                }}
                value={rowsPerPage}
                style={{ padding: 10 }}
              >
                <option value="20" selected>20</option>
                <option value="40">40</option>
              </select>
            </div>
          </Grid.Row>
        </Grid.Column>
      </Grid>
      <Grid>
        {dataSorted.length > rowsPerPage ? <Grid.Row>
          <Grid.Column width={16} textAlign='center'>
            <Pagination
              size='mini'
              defaultActivePage={1}
              totalPages={Math.floor((dataSorted.length - 1) / rowsPerPage) + 1}
              activePage={pageNum}
              boundaryRange={0}
              siblingRange={1}
              onPageChange={(e, { activePage }) => {
                setPageNum(activePage)
              }}
            />
          </Grid.Column>
        </Grid.Row> :
        <Grid.Row></Grid.Row>}
      </Grid>

      <Table textAlign='center' unstackable celled sortable size='small' style={{ fontSize: 12 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={sortColumn === 'SKU_NBR' ? sortDirection : null}
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumn !== 'SKU_NBR') {
                  setSortColumn('SKU_NBR')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>SKU # {sortColumn !== 'SKU_NBR' && <Icon fitted name='sort' />}</Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'SKU_DESC' ? sortDirection : null}
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumn !== 'SKU_DESC') {
                  setSortColumn('SKU_DESC')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>SKU Description {sortColumn !== 'SKU_DESC' && <Icon fitted name='sort' />}</Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'DEPT_NBR' ? sortDirection : null}
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumn !== 'DEPT_NBR') {
                  setSortColumn('DEPT_NBR')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>Dept # {sortColumn !== 'DEPT_NBR' && <Icon fitted name='sort' />}</Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'CLR_NH_INV_RETL' ? sortDirection : null}
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumn !== 'CLR_NH_INV_RETL') {
                  setSortColumn('CLR_NH_INV_RETL')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>Clearance NH Inv Retail {sortColumn !== 'CLR_NH_INV_RETL' && <Icon fitted name='sort' />}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {dataPagination.map((data) => {
            return (
              <Table.Row>
                <Table.Cell>{data['SKU_NBR']}</Table.Cell>
                <Table.Cell>{data['SKU_DESC']}</Table.Cell>
                <Table.Cell>{data['DEPT_NBR']}</Table.Cell>
                <Table.Cell>{getDollarString(data['CLR_NH_INV_RETL'], 2)}</Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </>
  )

})