import React from 'react'
import { Header, Loader, Grid } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import { useQuery } from 'react-query'
import { getData } from '../../service/DataService'
import { getThousandDollarString } from '../Common/TableCellUtils'
import { useQueryParams } from '../../hooks/useQueryParams'
import { DataTable } from '../Common/DataTable'


export const InvSubclassDrilldown = ({ level, fpToggle }) => {

  var getLevelKey = {
    'store' : 'strNbr',
    'district' : 'dstNbr',
    'region' : 'rgnNbr',
    'division' : 'divNbr',
  }

  const INV_SUB_CLASS_QUERIES = {
    'store' : 'queryInvSclsByStrCls',
    'district' : 'queryInvSclsByDstCls',
    'region' : 'queryInvSclsByRgnCls',
    'division' : 'queryInvSclsByDivCls',
  }

  const SKU_INV_QUERIES = {
    'store' : 'queryInvSkuByStrScls',
    'district' : 'queryInvSkuByDstScls',
    'region' : 'queryInvSkuByRgnScls',
    'division' : 'queryInvSkuByDivScls',
  }

  const { deptNbr: deptNbrInt = 0, dstNbr = 0, rgnNbr = 0, divNbr = 0, strNbr = 0, classNbr = 0, subClassNbr = 0} = useQueryParams()
  const orgNbr = dstNbr || strNbr || rgnNbr || divNbr || 0

  const subClassInvQuery = useQuery([INV_SUB_CLASS_QUERIES[level], { locNbr: orgNbr, classNbr, fpToggle }], getData)
  const skuInvQuery = useQuery([SKU_INV_QUERIES[level], { locNbr: orgNbr, subClassNbr, fpToggle }], getData)

  const subClassInventory = subClassInvQuery.data
  const skuInventory = skuInvQuery.data

  if (subClassInvQuery.isLoading || skuInvQuery.isLoading) {
    return (<Loader active>Loading...</Loader>)
  }

  // Load, extract and sort class numbers
  let allSubClassSort = subClassInventory.sort((left, right) => {
    let result = left.SUB_CLASS_NM > right.SUB_CLASS_NM ? 1 : -1
    return result
  })

  let subClassNbrArr = []
  allSubClassSort.map((data) => {
    return subClassNbrArr.push(data['EXT_SUB_CLASS_NBR'].toString())
  })

  let subClassNmArr = []
  allSubClassSort.map((data) => {
    return subClassNmArr.push(data['SUB_CLASS_NM'])
  })

  let subClassNm = subClassNmArr[subClassNbrArr.indexOf(subClassNbr)]

  const dataObj ={
    data : skuInventory.map((data)=>([
      {stringValue: data['SKU_NBR'], orderBy: data['SKU_NBR']},
      {stringValue: data['SKU_DESC'], orderBy: data['SKU_DESC']},
      {stringValue: getThousandDollarString(data['INV_AMT']), orderBy: data['INV_AMT']},
      {stringValue: getThousandDollarString(data['ASW']), orderBy: data['ASW']},
      {stringValue: data['WOS'].toLocaleString(undefined, { maximumFractionDigits: 1}), orderBy: data['WOS']},
      {stringValue: getThousandDollarString(data['ICE_INV_AMT']), orderBy: data['ICE_INV_AMT']},
    ])),
    headers: [
      {name: 'SKU # '},
      {name: 'SKU Desc '},
      {name: 'Inv $ '},
      {name: 'ASW '},
      {name: 'WOS '},
      {name: 'ICE $ '},
    ],
    sortable: true,
    sortableDefault: 0,
    pagination: true,
  }
  return (
    <>
      <Header textAlign='center'>{subClassNm}</Header>
      {/* Buttons for adjacent classes */}
      <Grid>
        <Grid.Row fluid>
          <Grid.Column textAlign='left' width={8}>
            <Link
              to={`/${level}/inventory/dept/class/subclass?${getLevelKey[level]}=${orgNbr}&deptNbr=${deptNbrInt}&classNbr=${classNbr}&subClassNbr=${subClassNbrArr[(subClassNbrArr.indexOf(subClassNbr) + subClassNbrArr.length - 1) % subClassNbrArr.length]}`}
              style={{ color: '#EE7125', textDecoration: 'underline' }}>
              {subClassNmArr[(subClassNbrArr.indexOf(subClassNbr) + subClassNbrArr.length - 1) % subClassNbrArr.length]}
            </Link>

          </Grid.Column>
          <Grid.Column textAlign='right' width={8}>
            <Link
              to={`/${level}/inventory/dept/class/subclass?${getLevelKey[level]}=${orgNbr}&deptNbr=${deptNbrInt}&classNbr=${classNbr}&subClassNbr=${subClassNbrArr[(subClassNbrArr.indexOf(subClassNbr) + 1) % subClassNbrArr.length]}`}
              style={{ color: '#EE7125', textDecoration: 'underline' }}>
              {subClassNmArr[(subClassNbrArr.indexOf(subClassNbr) + 1) % subClassNbrArr.length]}
            </Link>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Grid>
        <Grid.Row>
          <Grid.Column width={8} textAlign='left'>
            <Grid.Row>&nbsp;</Grid.Row>
            <Grid.Row><Link style={{ color: '#EE7125' }} to={`/${level}/inventory/dept/class?${getLevelKey[level]}=${orgNbr}&deptNbr=${deptNbrInt}&classNbr=${classNbr}`} data-test='b2o-link'>Back to Class</Link></Grid.Row>

          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Header textAlign='center'>Inventory Detail</Header>
      <DataTable data={dataObj} />
    </>
  )

}