import React, { useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import {
  Table,
  Header,
  Grid,
  Loader,
  Menu,
} from 'semantic-ui-react'
import { UnitMenu } from '../Common/UnitMenu'
import { TimeframeDropdown } from '../Common/TimeframeDropdown'
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon'

import { useQuery } from 'react-query'
import {
  getData
} from '../../service/DataService'

import { getThousandDollarString, getPercentString, getCalloutStyle, getCompareStyle } from '../Common/TableCellUtils'

export const SpecialtyProRentalDst = withRouter(({ timeframe, settimeframe, fpToggle, location }) => {

  const search = new URLSearchParams(location.search)
  const dstNbr = search.has('dstNbr') ? search.get('dstNbr') : 0

  const [sortColumnQuotes, setSortColumnQuotes] = useState('STR_NBR')
  const [sortDirectionQuotes, setSortDirectionQuotes] = useState('ascending')

  const [slsDistrictStore, setSlsDistrictStore] = useState('District')
  const [sortColumnSls, setSortColumnSls] = useState('STR_NBR')
  const [sortDirectionSls, setSortDirectionSls] = useState('ascending')
  const [deptNmSls, setDeptNmSls] = useState("Total")

  const [sortColumnLbr, setSortColumnLbr] = useState('STR_NBR')
  const [sortDirectionLbr, setSortDirectionLbr] = useState('ascending')
  const [deptNmLbr, setDeptNmLbr] = useState("TOTAL")

  const [sortColumnSpec, setSortColumnSpec] = useState('STR_NBR')
  const [sortDirectionSpec, setSortDirectionSpec] = useState('ascending')

  const [sortColumnPro, setSortColumnPro] = useState('STR_NBR')
  const [sortDirectionPro, setSortDirectionPro] = useState('ascending')

  const [sortColumnTr, setSortColumnTr] = useState('STR_NBR')
  const [sortDirectionTr, setSortDirectionTr] = useState('ascending')

  const [unit, setUnit] = useState('NOM')

  const slsByDstQuery = useQuery(['queryProSpecTrSlsByDst', { dstNbr, fpToggle }], getData)
  const lbrByDstQuery = useQuery(['queryProSpecTrLbrByDst', { dstNbr, fpToggle }], getData)
  const proOpsByDstQuery = useQuery(['queryProOpsByDst', { dstNbr, fpToggle }], getData)
  const specOpsStrByDstQuery = useQuery(['querySpecOpsStrByDst', { dstNbr, fpToggle }], getData)
  const specOpsDstByDstQuery = useQuery(['querySpecOpsDstByDst', { dstNbr, fpToggle }], getData)
  const trOpsByDstQuery = useQuery(['queryTrOpsByDst', { dstNbr, fpToggle }], getData)
  const quotesAggByDstQuery = useQuery(['queryQuotesAggByDst', { dstNbr, fpToggle }], getData)


  if (
    slsByDstQuery.isLoading ||
    lbrByDstQuery.isLoading ||
    proOpsByDstQuery.isLoading ||
    specOpsStrByDstQuery.isLoading ||
    specOpsDstByDstQuery.isLoading ||
    trOpsByDstQuery.isLoading ||
    quotesAggByDstQuery.isLoading
  ) {
    return <Loader active>Loading...</Loader>
  }
  if (!quotesAggByDstQuery.data[0]) {
    return (<Header textAlign='center'>Invalid or Unauthorized District Number</Header>)
  }

  // Processing quotes data
  let quotesAggData = quotesAggByDstQuery.data

  let quotesAggStrFilter = quotesAggData.filter((row) => {
    return row && row['METRIC_LEVEL'] === 'STR'
  })

  let quotesAggStrSorted = quotesAggStrFilter.sort((left, right) => {
    let result = left[sortColumnQuotes] - right[sortColumnQuotes]
    if (sortColumnQuotes === timeframe + '_SOLD_QUOTE_PCT') {
      result = left[timeframe + '_QUOTE_SOLD_CNT'] / left[timeframe + '_TOT_QUOTE_CNT']
        - right[timeframe + '_QUOTE_SOLD_CNT'] / right[timeframe + '_TOT_QUOTE_CNT']
    }
    if (sortDirectionQuotes === 'descending') {
      result *= -1
    }
    return result
  })

  let quotesAggDstFilter = quotesAggData.filter((row) => {
    return row && row['METRIC_LEVEL'] === 'DST'
  })[0]

  // Processing sales data
  let slsData = slsByDstQuery.data

  let slsDataDstFilter = slsData.filter((row) => {
    return row && row['METRIC_LEVEL'] === 'DST' && row['DEPT_NBR'] !== 42 && row['DEPT_NBR'] !== 9999
  })

  let slsData42 = slsData.filter(row => {
    return row && row['METRIC_LEVEL'] === 'DST' && row['DEPT_NBR'] === 42
  })[0]

  let slsDataTtl = slsData.filter(row => {
    return row && row['METRIC_LEVEL'] === 'DST' && row['DEPT_NBR'] === 9999
  })[0]

  let slsDataDstSorted = slsDataDstFilter.sort((left, right) => {
    return left['MER_DEPT_NM'] - right['MER_DEPT_NM']
  })

  let slsDataDstDeptFilter = slsData.filter((row) => {
    return row && row['METRIC_LEVEL'] === 'DST' && row['MER_DEPT_NM'] === deptNmSls
  })[0]

  let slsDataStrFilter = slsData.filter((row) => {
    return row && row['METRIC_LEVEL'] === 'STR' && row['MER_DEPT_NM'] === deptNmSls
  })

  let slsDataStrSorted = slsDataStrFilter.sort((left, right) => {
    let result = left[sortColumnSls] - right[sortColumnSls]
    if (sortDirectionSls === 'descending') {
      result *= -1
    }
    return result
  })

  let slsDataSortedByDeptNm = slsData.sort((left, right) => {
    return left['MER_DEPT_NM'] - right['MER_DEPT_NM']
  })

  let slsDepts = []
  slsDataSortedByDeptNm.map((data) => {
    return slsDepts.includes(data['MER_DEPT_NM']) ? slsDepts : slsDepts.push(data['MER_DEPT_NM'])
  })

  // Processing labor data
  let lbrData = lbrByDstQuery.data

  let lbrDataDstFilter = lbrData.filter((row) => {
    return row && row['METRIC_LEVEL'] === 'DST' && row['DEPT_NM'] === deptNmLbr
  })[0]
  let lbrDataStrFilter = lbrData.filter((row) => {
    return row && row['METRIC_LEVEL'] === 'STR' && row['DEPT_NM'] === deptNmLbr
  })

  let lbrDataStrSorted = lbrDataStrFilter.sort((left, right) => {
    let result = left[sortColumnLbr] - right[sortColumnLbr]
    if (sortDirectionLbr === 'descending') {
      result *= -1
    }
    return result
  })

  let lbrDataSortedByDeptNm = lbrData.sort((left, right) => {
    return left['DEPT_NBR'] - right['DEPT_NBR']
  })

  let lbrDepts = []
  lbrDataSortedByDeptNm.map((data) => {
    return lbrDepts.includes(data['DEPT_NM']) ? lbrDepts : lbrDepts.push(data['DEPT_NM'])
  })

  // Processing Pro Ops data
  let proOps = proOpsByDstQuery.data

  let proOpsDstFilter = proOps.filter((row) => {
    return row && row['METRIC_LEVEL'] === 'DST'
  })[0]

  let proOpsStrFilter = proOps.filter((row) => {
    return row && row['METRIC_LEVEL'] === 'STR'
  })

  let proOpsStrSorted = proOpsStrFilter.sort((left, right) => {
    let result = left[sortColumnPro] - right[sortColumnPro]
    if (sortDirectionPro === 'descending') {
      result *= -1
    }
    return result
  })

  // Processing Specialty Ops data

  let specOpsStr = specOpsStrByDstQuery.data

  let specOpsStrSorted = specOpsStr.sort((left, right) => {
    let result = left[sortColumnSpec] - right[sortColumnSpec]
    if (sortColumnSpec === timeframe + '_WALK_COMPLETE_PCT') {
      result = left[timeframe + '_WALK_COMPLETE'] / left[timeframe + '_TOTAL_WALK'] - right[timeframe + '_WALK_COMPLETE'] / right[timeframe + '_TOTAL_WALK']
    }
    if (sortColumnSpec === timeframe + '_YES_RESP_PCT') {
      result = left[timeframe + '_YES_RESP'] / left[timeframe + '_TOT_RESP'] - right[timeframe + '_YES_RESP'] / right[timeframe + '_TOT_RESP']
    }
    if (sortColumnSpec === timeframe + '_SPOT_CMPLT_PCT') {
      result = left[timeframe + '_CNT_CMPLT'] / left[timeframe + '_CNT_ASSOCS'] - right[timeframe + '_CNT_CMPLT'] / right[timeframe + '_CNT_ASSOCS']
    }
    if (sortColumnSpec === timeframe + '_CSU_CMPLT_PCT') {
      result = left[timeframe + '_CSU_CNT_CMPLT'] / left[timeframe + '_CSU_CNT_ASSOCS'] - right[timeframe + '_CSU_CNT_CMPLT'] / right[timeframe + '_CSU_CNT_ASSOCS']
    }
    if (sortColumnSpec === timeframe + '_SPEC_OVR_GOAL') {
      result = left[timeframe + '_OVR_GOAL'] / left[timeframe + '_TOT_ASSOC'] - right[timeframe + '_OVR_GOAL'] / right[timeframe + '_TOT_ASSOC']
    }
    if (sortDirectionSpec === 'descending') {
      result *= -1
    }
    return result
  })

  let specOpsDst = specOpsDstByDstQuery.data[0]

  // Processing TR Ops data
  let trOps = trOpsByDstQuery.data

  let trOpsDstFilter = trOps.filter((row) => {
    return row && row['METRIC_LEVEL'] === 'DST'
  })[0]

  let trOpsStrFilter = trOps.filter((row) => {
    return row && row['METRIC_LEVEL'] === 'STR'
  })

  let trOpsStrSorted = trOpsStrFilter.sort((left, right) => {
    let result = left[sortColumnTr] - right[sortColumnTr]
    if (sortDirectionTr === 'descending') {
      result *= -1
    }
    return result
  })




  return (
    <>
      <Header textAlign='center'>Specialty, Pro, Rental - District #{dstNbr}</Header>
      <Grid>
        <Grid.Column width={8} textAlign='left'>
          <Grid.Row>
            Units:
          </Grid.Row>
          <Grid.Row>
            <UnitMenu unit={unit} setUnit={setUnit} />
          </Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
        </Grid.Column>
        <Grid.Column width={8} textAlign='justified'>
          Timeframe:
          {new Date().getDay() === 1 ?
            <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} /> :
            <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />}
        </Grid.Column>
      </Grid>
      {/* QUOTES */}
      <Header textAlign='center'>Quotes</Header>
      <Table textAlign='center' celled unstackable size='small' compact style={{ fontSize: 11 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumnQuotes !== 'STR_NBR') {
                  setSortColumnQuotes('STR_NBR')
                  setSortDirectionQuotes('ascending')
                } else {
                  sortDirectionQuotes === 'ascending' ? setSortDirectionQuotes('descending') : setSortDirectionQuotes('ascending')
                }
              }}
            >Store #</Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumnQuotes !== timeframe + '_OPEN_QUOTE_AMT') {
                  setSortColumnQuotes(timeframe + '_OPEN_QUOTE_AMT')
                  setSortDirectionQuotes('ascending')
                } else {
                  sortDirectionQuotes === 'ascending' ? setSortDirectionQuotes('descending') : setSortDirectionQuotes('ascending')
                }
              }}
            >Open Opportunity</Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumnQuotes !== timeframe + '_OPEN_QUOTE_CNT') {
                  setSortColumnQuotes(timeframe + '_OPEN_QUOTE_CNT')
                  setSortDirectionQuotes('ascending')
                } else {
                  sortDirectionQuotes === 'ascending' ? setSortDirectionQuotes('descending') : setSortDirectionQuotes('ascending')
                }
              }}
            >Open Count</Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumnQuotes !== timeframe + '_QUOTE_SOLD_AMT') {
                  setSortColumnQuotes(timeframe + '_QUOTE_SOLD_AMT')
                  setSortDirectionQuotes('ascending')
                } else {
                  sortDirectionQuotes === 'ascending' ? setSortDirectionQuotes('descending') : setSortDirectionQuotes('ascending')
                }
              }}
            >Sold $</Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumnQuotes !== timeframe + '_QUOTE_SOLD_CNT') {
                  setSortColumnQuotes(timeframe + '_QUOTE_SOLD_CNT')
                  setSortDirectionQuotes('ascending')
                } else {
                  sortDirectionQuotes === 'ascending' ? setSortDirectionQuotes('descending') : setSortDirectionQuotes('ascending')
                }
              }}
            >Sold Count</Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumnQuotes !== timeframe + '_SOLD_QUOTE_PCT') {
                  setSortColumnQuotes(timeframe + '_SOLD_QUOTE_PCT')
                  setSortDirectionQuotes('ascending')
                } else {
                  sortDirectionQuotes === 'ascending' ? setSortDirectionQuotes('descending') : setSortDirectionQuotes('ascending')
                }
              }}
            >Sold Quote % *</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {quotesAggStrSorted.map((data) => {
            return (
              <Table.Row>
                <Table.Cell>
                  <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/specialtyprorental?strNbr=${data['STR_NBR']}`}>{data['STR_NBR']}</Link>
                </Table.Cell>
                <Table.Cell>{getThousandDollarString(data[timeframe + '_OPEN_QUOTE_AMT'], 1)}</Table.Cell>
                <Table.Cell>{data[timeframe + '_OPEN_QUOTE_CNT'].toLocaleString(undefined, { maximumFractionDigits: 1 })}</Table.Cell>
                <Table.Cell>{getThousandDollarString(data[timeframe + '_QUOTE_SOLD_AMT'], 1)}</Table.Cell>
                <Table.Cell>{data[timeframe + '_QUOTE_SOLD_CNT'].toLocaleString(undefined, { maximumFractionDigits: 1 })}</Table.Cell>
                <Table.Cell>{getPercentString(data[timeframe + '_QUOTE_SOLD_CNT'] / data[timeframe + '_TOT_QUOTE_CNT'])}</Table.Cell>
              </Table.Row>
            )
          })}
          <Table.Row style={{ fontWeight: 'bold' }}>
            <Table.Cell>Total</Table.Cell>
            <Table.Cell>{getThousandDollarString(quotesAggDstFilter[timeframe + '_OPEN_QUOTE_AMT'], 1)}</Table.Cell>
            <Table.Cell>{quotesAggDstFilter[timeframe + '_OPEN_QUOTE_CNT'].toLocaleString(undefined, { maximumFractionDigits: 1 })}</Table.Cell>
            <Table.Cell>{getThousandDollarString(quotesAggDstFilter[timeframe + '_QUOTE_SOLD_AMT'], 1)}</Table.Cell>
            <Table.Cell>{quotesAggDstFilter[timeframe + '_QUOTE_SOLD_CNT'].toLocaleString(undefined, { maximumFractionDigits: 1 })}</Table.Cell>
            <Table.Cell>{getPercentString(quotesAggDstFilter[timeframe + '_QUOTE_SOLD_CNT'] / quotesAggDstFilter[timeframe + '_TOT_QUOTE_CNT'])}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <p>
        * Sold % is calculated as the total number of quotes sold divided by all quotes in all statuses (Open. Sold, Cancelled).
      </p>
      {/* SALES */}
      <Header textAlign='center'>Sales</Header>
      <Menu compact widths={2} size='small' >
        <Menu.Item
          name='District'
          active={slsDistrictStore === 'District'}
          content='District'
          onClick={() => {
            setSlsDistrictStore('District')
          }}
        />
        <Menu.Item
          name='Store'
          active={slsDistrictStore === 'Store'}
          content='Store'
          onClick={() => {
            setSlsDistrictStore('Store')
          }}
        />
      </Menu>
      &nbsp;
      <Grid>
        <Grid.Column width={8} textAlign='left'>
          <Grid.Row>
            Units:
          </Grid.Row>
          <Grid.Row>
            <UnitMenu unit={unit} setUnit={setUnit} />
          </Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
        </Grid.Column>
        {slsDistrictStore === 'Store' && <Grid.Column textAlign='justified' width={8}>
          Dept:
          <div>
            <select
              name="depts"
              onChange={e => {
                setDeptNmSls(e.target.value)
              }}
              value={deptNmSls}
              style={{ padding: 10 }}
            >
              <option value='Total' selected>{'TOTAL **'}</option>
              <option value='1-SERVICES'>{'SERVICES'}</option>
              <option value='42-PRO XTRA REGISTERED TENDER'>{'PRO XTRA REG TNDR'}</option>
              {slsDepts.map((row) => {
                return row !== 'Total' &&
                  row !== '1-SERVICES' &&
                  row !== '42-PRO XTRA REGISTERED TENDER' &&
                  <option value={row}>{row.replace(' AND ', '&').slice(0, 17)}</option>
              })}
            </select>
          </div>
          &nbsp;
        </Grid.Column>}
      </Grid>
      {slsDistrictStore === 'Store' && <Table textAlign='center' celled unstackable size='small' compact style={{ fontSize: 11 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumnSls !== 'STR_NBR') {
                  setSortColumnSls('STR_NBR')
                  setSortDirectionSls('ascending')
                } else {
                  sortDirectionSls === 'ascending' ? setSortDirectionSls('descending') : setSortDirectionSls('ascending')
                }
              }}
            >Store #</Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumnSls !== timeframe + '_TY_SLS_AMT') {
                  setSortColumnSls(timeframe + '_TY_SLS_AMT')
                  setSortDirectionSls('ascending')
                } else {
                  sortDirectionSls === 'ascending' ? setSortDirectionSls('descending') : setSortDirectionSls('ascending')
                }
              }}
            >Act</Table.HeaderCell>
            {unit === 'NOM' && <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumnSls !== timeframe + '_SLS_VP') {
                  setSortColumnSls(timeframe + '_SLS_VP')
                  setSortDirectionSls('ascending')
                } else {
                  sortDirectionSls === 'ascending' ? setSortDirectionSls('descending') : setSortDirectionSls('ascending')
                }
              }}
            >vP</Table.HeaderCell>}
            {unit === 'PCT' && <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumnSls !== timeframe + '_SLS_VP_PCT') {
                  setSortColumnSls(timeframe + '_SLS_VP_PCT')
                  setSortDirectionSls('ascending')
                } else {
                  sortDirectionSls === 'ascending' ? setSortDirectionSls('descending') : setSortDirectionSls('ascending')
                }
              }}
            >vP %</Table.HeaderCell>}
            {unit === 'NOM' && <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumnSls !== timeframe + '_SLS_VLY') {
                  setSortColumnSls(timeframe + '_SLS_VLY')
                  setSortDirectionSls('ascending')
                } else {
                  sortDirectionSls === 'ascending' ? setSortDirectionSls('descending') : setSortDirectionSls('ascending')
                }
              }}
            >vLY</Table.HeaderCell>}
            {unit === 'PCT' && <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumnSls !== timeframe + '_SLS_VLY_PCT') {
                  setSortColumnSls(timeframe + '_SLS_VLY_PCT')
                  setSortDirectionSls('ascending')
                } else {
                  sortDirectionSls === 'ascending' ? setSortDirectionSls('descending') : setSortDirectionSls('ascending')
                }
              }}
            >vLY %</Table.HeaderCell>}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {slsDataStrSorted.map((data) => {
            return (
              <Table.Row>
                <Table.Cell>
                  <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/specialtyprorental?strNbr=${data['STR_NBR']}`} >
                    {data['STR_NBR']}
                  </Link>
                </Table.Cell>
                <Table.Cell style={getCalloutStyle(data[timeframe + '_TY_SLS_AMT'])}>
                  {getThousandDollarString(data[timeframe + '_TY_SLS_AMT'], 1)}</Table.Cell>
                {unit === 'NOM' && <Table.Cell style={getCompareStyle(data[timeframe + '_SLS_VP'])}>
                  {getThousandDollarString(data[timeframe + '_SLS_VP'], 1)}</Table.Cell>}
                {unit === 'PCT' && <Table.Cell style={getCompareStyle(data[timeframe + '_SLS_VP_PCT'])}>
                  {getPercentString(data[timeframe + '_SLS_VP_PCT'])}</Table.Cell>}
                {unit === 'NOM' && <Table.Cell style={getCompareStyle(deptNmSls !== '42-PRO XTRA REGISTERED TENDER' ? data[timeframe + '_SLS_VLY'] : data[timeframe + '_SLS_VLY_PCT'] - 0.02)}>
                  {getThousandDollarString(data[timeframe + '_SLS_VLY'], 1)}</Table.Cell>}
                {unit === 'PCT' && <Table.Cell style={getCompareStyle(deptNmSls !== '42-PRO XTRA REGISTERED TENDER' ? data[timeframe + '_SLS_VLY_PCT'] : data[timeframe + '_SLS_VLY_PCT'] - 0.02)}>
                  {getPercentString(data[timeframe + '_SLS_VLY_PCT'])}</Table.Cell>}
              </Table.Row>
            )
          })}
          <Table.Row style={{ fontWeight: "bold" }}>
            <Table.Cell>Total</Table.Cell>
            <Table.Cell style={getCalloutStyle(slsDataDstDeptFilter[timeframe + '_TY_SLS_AMT'])}>
              {getThousandDollarString(slsDataDstDeptFilter[timeframe + '_TY_SLS_AMT'], 1)}</Table.Cell>
            {unit === 'NOM' && <Table.Cell style={getCompareStyle(slsDataDstDeptFilter[timeframe + '_SLS_VP'])}>
              {getThousandDollarString(slsDataDstDeptFilter[timeframe + '_SLS_VP'], 1)}</Table.Cell>}
            {unit === 'PCT' && <Table.Cell style={getCompareStyle(slsDataDstDeptFilter[timeframe + '_SLS_VP_PCT'])}>
              {getPercentString(slsDataDstDeptFilter[timeframe + '_SLS_VP_PCT'])}</Table.Cell>}
            {unit === 'NOM' && <Table.Cell style={getCompareStyle(slsDataDstDeptFilter[timeframe + '_SLS_VLY'])}>
              {getThousandDollarString(slsDataDstDeptFilter[timeframe + '_SLS_VLY'], 1)}</Table.Cell>}
            {unit === 'PCT' && <Table.Cell style={getCompareStyle(slsDataDstDeptFilter[timeframe + '_SLS_VLY_PCT'])}>
              {getPercentString(slsDataDstDeptFilter[timeframe + '_SLS_VLY_PCT'])}</Table.Cell>}
          </Table.Row>
        </Table.Body>
      </Table>}
      {slsDistrictStore === 'District' && <Table textAlign='center' celled unstackable size='small' compact style={{ fontSize: 11 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>Act</Table.HeaderCell>
            {unit === 'NOM' && <Table.HeaderCell>vP</Table.HeaderCell>}
            {unit === 'PCT' && <Table.HeaderCell>vP %</Table.HeaderCell>}
            {unit === 'NOM' && <Table.HeaderCell>vLY</Table.HeaderCell>}
            {unit === 'PCT' && <Table.HeaderCell>vLY %</Table.HeaderCell>}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>PRO XTRA REG TNDR</Table.Cell>
            <Table.Cell style={getCalloutStyle(slsData42[timeframe + '_TY_SLS_AMT'])}>
              {getThousandDollarString(slsData42[timeframe + '_TY_SLS_AMT'], 1)}</Table.Cell>
            {unit === 'NOM' && <Table.Cell>--</Table.Cell>}
            {unit === 'PCT' && <Table.Cell>--</Table.Cell>}
            {unit === 'NOM' && <Table.Cell style={getCompareStyle(slsData42[timeframe + '_SLS_VLY_PCT'] - 0.02)}>
              {getThousandDollarString(slsData42[timeframe + '_SLS_VLY'], 1)}</Table.Cell>}
            {unit === 'PCT' && <Table.Cell style={getCompareStyle(slsData42[timeframe + '_SLS_VLY_PCT'] - 0.02)}>
              {getPercentString(slsData42[timeframe + '_SLS_VLY_PCT'])}</Table.Cell>}
          </Table.Row>
          {slsDataDstSorted.map((data) => {
            return (
              <Table.Row>
                <Table.Cell>
                  {data['MER_DEPT_NM'] === '1-SERVICES' ?
                    <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/district/services?dstNbr=${dstNbr}`} >
                      SERVICES
                    </Link> :
                    <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/district/sales/dept?dstNbr=${dstNbr}&deptNbr=${data['DEPT_NBR']}`} >
                      {data['MER_DEPT_NM']}
                    </Link>}
                </Table.Cell>
                <Table.Cell style={getCalloutStyle(data[timeframe + '_TY_SLS_AMT'])}>
                  {getThousandDollarString(data[timeframe + '_TY_SLS_AMT'], 1)}</Table.Cell>
                {unit === 'NOM' && <Table.Cell style={getCompareStyle(data[timeframe + '_SLS_VP'])}>
                  {getThousandDollarString(data[timeframe + '_SLS_VP'], 1)}</Table.Cell>}
                {unit === 'PCT' && <Table.Cell style={getCompareStyle(data[timeframe + '_SLS_VP_PCT'])}>
                  {getPercentString(data[timeframe + '_SLS_VP_PCT'])}</Table.Cell>}
                {unit === 'NOM' && <Table.Cell style={getCompareStyle(data[timeframe + '_SLS_VLY'])}>
                  {getThousandDollarString(data[timeframe + '_SLS_VLY'], 1)}</Table.Cell>}
                {unit === 'PCT' && <Table.Cell style={getCompareStyle(data[timeframe + '_SLS_VLY_PCT'])}>
                  {getPercentString(data[timeframe + '_SLS_VLY_PCT'])}</Table.Cell>}
              </Table.Row>
            )
          })}
          <Table.Row style={{ fontWeight: "bold" }}>
            <Table.Cell>TOTAL **</Table.Cell>
            <Table.Cell style={getCalloutStyle(slsDataTtl[timeframe + '_TY_SLS_AMT'])}>
              {getThousandDollarString(slsDataTtl[timeframe + '_TY_SLS_AMT'], 1)}</Table.Cell>
            {unit === 'NOM' && <Table.Cell style={getCompareStyle(slsDataTtl[timeframe + '_SLS_VP'])}>
              {getThousandDollarString(slsDataTtl[timeframe + '_SLS_VP'], 1)}</Table.Cell>}
            {unit === 'PCT' && <Table.Cell style={getCompareStyle(slsDataTtl[timeframe + '_SLS_VP_PCT'])}>
              {getPercentString(slsDataTtl[timeframe + '_SLS_VP_PCT'])}</Table.Cell>}
            {unit === 'NOM' && <Table.Cell style={getCompareStyle(slsDataTtl[timeframe + '_SLS_VLY_PCT'] - 0.02)}>
              {getThousandDollarString(slsDataTtl[timeframe + '_SLS_VLY'], 1)}</Table.Cell>}
            {unit === 'PCT' && <Table.Cell style={getCompareStyle(slsDataTtl[timeframe + '_SLS_VLY_PCT'] - 0.02)}>
              {getPercentString(slsDataTtl[timeframe + '_SLS_VLY_PCT'])}</Table.Cell>}
          </Table.Row>
        </Table.Body>
      </Table>}
      <p>** Pro Xtra Registered Tender & Services are not included in the total due to overlapping sales in the department totals.</p>
      {/* LABOR */}
      <Header textAlign='center'>Labor</Header>
      <Grid>
        <Grid.Column width={8} textAlign='left'>
          <Grid.Row>
            Units:
          </Grid.Row>
          <Grid.Row>
            <UnitMenu unit={unit} setUnit={setUnit} />
          </Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
        </Grid.Column>
        <Grid.Column textAlign='justified' width={8}>
          Dept:
          <div>
            <select
              name="depts"
              onChange={e => {
                setDeptNmLbr(e.target.value)
              }}
              value={deptNmLbr}
              style={{ padding: 10 }}
            >
              <option value='TOTAL' selected>{'TOTAL'}</option>
              {lbrDepts.map((row) => {
                return row !== 'TOTAL' && <option value={row}>{row}</option>
              })}
            </select>
          </div>
          &nbsp;
        </Grid.Column>
      </Grid>
      <Table textAlign='center' celled unstackable size='small' compact style={{ fontSize: 11 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumnLbr !== 'STR_NBR') {
                  setSortColumnLbr('STR_NBR')
                  setSortDirectionLbr('ascending')
                } else {
                  sortDirectionLbr === 'ascending' ? setSortDirectionLbr('descending') : setSortDirectionLbr('ascending')
                }
              }}
            >Store #</Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumnLbr !== timeframe + '_ACT_HRS') {
                  setSortColumnLbr(timeframe + '_ACT_HRS')
                  setSortDirectionLbr('ascending')
                } else {
                  sortDirectionLbr === 'ascending' ? setSortDirectionLbr('descending') : setSortDirectionLbr('ascending')
                }
              }}
            >Act</Table.HeaderCell>
            {unit === 'NOM' && <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumnLbr !== timeframe + '_HRS_VF') {
                  setSortColumnLbr(timeframe + '_HRS_VF')
                  setSortDirectionLbr('ascending')
                } else {
                  sortDirectionLbr === 'ascending' ? setSortDirectionLbr('descending') : setSortDirectionLbr('ascending')
                }
              }}
            >AvF</Table.HeaderCell>}
            {unit === 'PCT' && <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumnLbr !== timeframe + '_HRS_VF_PCT') {
                  setSortColumnLbr(timeframe + '_HRS_VF_PCT')
                  setSortDirectionLbr('ascending')
                } else {
                  sortDirectionLbr === 'ascending' ? setSortDirectionLbr('descending') : setSortDirectionLbr('ascending')
                }
              }}
            >AvF %</Table.HeaderCell>}
            {unit === 'NOM' && <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumnLbr !== timeframe + '_HRS_VS') {
                  setSortColumnLbr(timeframe + '_HRS_VS')
                  setSortDirectionLbr('ascending')
                } else {
                  sortDirectionLbr === 'ascending' ? setSortDirectionLbr('descending') : setSortDirectionLbr('ascending')
                }
              }}
            >AvS</Table.HeaderCell>}
            {unit === 'PCT' && <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumnLbr !== timeframe + '_HRS_VS_PCT') {
                  setSortColumnLbr(timeframe + '_HRS_VS_PCT')
                  setSortDirectionLbr('ascending')
                } else {
                  sortDirectionLbr === 'ascending' ? setSortDirectionLbr('descending') : setSortDirectionLbr('ascending')
                }
              }}
            >AvS %</Table.HeaderCell>}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {lbrDataStrSorted.map((data) => {
            return (
              <Table.Row>
                <Table.Cell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>
                  <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/specialtyprorental?strNbr=${data['STR_NBR']}`} >
                    {data['STR_NBR']}
                  </Link>
                </Table.Cell>
                <Table.Cell>{data[timeframe + '_ACT_HRS'].toLocaleString(undefined, { maximumFractionDigits: 0 })}</Table.Cell>
                {unit === 'NOM' && <Table.Cell>
                  {data[timeframe + '_HRS_VF'].toLocaleString(undefined, { maximumFractionDigits: 0 })}</Table.Cell>}
                {unit === 'PCT' && <Table.Cell style={{ color: data[timeframe + '_HRS_VF_PCT'] > 0.01 || data[timeframe + '_HRS_VF_PCT'] < -0.01 ? 'red' : 'green' }}>
                  {getPercentString(data[timeframe + '_HRS_VF_PCT'])}</Table.Cell>}
                {unit === 'NOM' && <Table.Cell>
                  {data[timeframe + '_HRS_VS'].toLocaleString(undefined, { maximumFractionDigits: 0 })}</Table.Cell>}
                {unit === 'PCT' && <Table.Cell style={{ color: data[timeframe + '_HRS_VS_PCT'] > 0.01 || data[timeframe + '_HRS_VS_PCT'] < -0.01 ? 'red' : 'green' }}>
                  {getPercentString(data[timeframe + '_HRS_VS_PCT'])}</Table.Cell>}
              </Table.Row>
            )
          })}
          <Table.Row style={{ fontWeight: "bold" }}>
            <Table.Cell>Total</Table.Cell>
            <Table.Cell>
              {lbrDataDstFilter[timeframe + '_ACT_HRS'].toLocaleString(undefined, { maximumFractionDigits: 0 })}</Table.Cell>
            {unit === 'NOM' && <Table.Cell>
              {lbrDataDstFilter[timeframe + '_HRS_VF'].toLocaleString(undefined, { maximumFractionDigits: 0 })}</Table.Cell>}
            {unit === 'PCT' && <Table.Cell style={{ color: lbrDataDstFilter[timeframe + '_HRS_VF_PCT'] > 0.01 || lbrDataDstFilter[timeframe + '_HRS_VF_PCT'] < -0.01 ? 'red' : 'green' }}>
              {getPercentString(lbrDataDstFilter[timeframe + '_HRS_VF_PCT'])}</Table.Cell>}
            {unit === 'NOM' && <Table.Cell>
              {lbrDataDstFilter[timeframe + '_HRS_VS'].toLocaleString(undefined, { maximumFractionDigits: 0 })}</Table.Cell>}
            {unit === 'PCT' && <Table.Cell style={{ color: lbrDataDstFilter[timeframe + '_HRS_VS_PCT'] > 0.01 || lbrDataDstFilter[timeframe + '_HRS_VS_PCT'] < -0.01 ? 'red' : 'green' }}>
              {getPercentString(lbrDataDstFilter[timeframe + '_HRS_VS_PCT'])}</Table.Cell>}
          </Table.Row>
        </Table.Body>
      </Table>

      <Header textAlign='center'>Specialty Key Metrics</Header>
      <Table textAlign='center' celled unstackable size='small' compact style={{ fontSize: 11 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumnSpec !== 'STR_NBR') {
                  setSortColumnSpec('STR_NBR')
                  setSortDirectionSpec('ascending')
                } else {
                  sortDirectionSpec === 'ascending' ? setSortDirectionSpec('descending') : setSortDirectionSpec('ascending')
                }
              }}
            >Store #</Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumnSpec !== timeframe + '_LEADS_VLY_PCT') {
                  setSortColumnSpec(timeframe + '_LEADS_VLY_PCT')
                  setSortDirectionSpec('ascending')
                } else {
                  sortDirectionSpec === 'ascending' ? setSortDirectionSpec('descending') : setSortDirectionSpec('ascending')
                }
              }}
            ><Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/district/leads?dstNbr=${dstNbr}`} >Lead Comp</Link></Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumnSpec !== timeframe + '_MEAS_VLY_PCT') {
                  setSortColumnSpec(timeframe + '_MEAS_VLY_PCT')
                  setSortDirectionSpec('ascending')
                } else {
                  sortDirectionSpec === 'ascending' ? setSortDirectionSpec('descending') : setSortDirectionSpec('ascending')
                }
              }}
            ><Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/district/meas?dstNbr=${dstNbr}`} >Meas Comp</Link></Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumnSpec !== timeframe + '_WALK_COMPLETE_PCT') {
                  setSortColumnSpec(timeframe + '_WALK_COMPLETE_PCT')
                  setSortDirectionSpec('ascending')
                } else {
                  sortDirectionSpec === 'ascending' ? setSortDirectionSpec('descending') : setSortDirectionSpec('ascending')
                }
              }}
            >DOTW Standards</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {specOpsStrSorted.map((data) => {
            return (
              <Table.Row>
                <Table.Cell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>
                  <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/specialtyprorental?strNbr=${data['STR_NBR']}`} >
                    {data['STR_NBR']}
                  </Link>
                </Table.Cell>
                <Table.Cell style={getCompareStyle(data[timeframe + '_LEADS_VLY_PCT'])}>
                  {getPercentString(data[timeframe + '_LEADS_VLY_PCT'])}</Table.Cell>
                <Table.Cell style={getCompareStyle(data[timeframe + '_MEAS_VLY_PCT'])}>
                  {getPercentString(data[timeframe + '_MEAS_VLY_PCT'])}</Table.Cell>
                <Table.Cell>{getPercentString(data[timeframe + '_WALK_COMPLETE'] / data[timeframe + '_TOTAL_WALK'])}</Table.Cell>
              </Table.Row>
            )
          })}
          <Table.Row style={{ fontWeight: "bold" }}>
            <Table.Cell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Total</Table.Cell>
            <Table.Cell style={getCompareStyle(specOpsDst[timeframe + '_LEADS_VLY_PCT'])}>
              {getPercentString(specOpsDst[timeframe + '_LEADS_VLY_PCT'])}</Table.Cell>
            <Table.Cell style={getCompareStyle(specOpsDst[timeframe + '_MEAS_VLY_PCT'])}>
              {getPercentString(specOpsDst[timeframe + '_MEAS_VLY_PCT'])}</Table.Cell>
            <Table.Cell>{getPercentString(specOpsDst[timeframe + '_WALK_COMPLETE_PCT'])}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Header textAlign='center'>Specialty Key Metrics Cont.</Header>
      <Table textAlign='center' celled unstackable size='small' compact style={{ fontSize: 11 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumnSpec !== 'STR_NBR') {
                  setSortColumnSpec('STR_NBR')
                  setSortDirectionSpec('ascending')
                } else {
                  sortDirectionSpec === 'ascending' ? setSortDirectionSpec('descending') : setSortDirectionSpec('ascending')
                }
              }}
            >Store #</Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumnSpec !== timeframe + '_YES_RESP_PCT') {
                  setSortColumnSpec(timeframe + '_YES_RESP_PCT')
                  setSortDirectionSpec('ascending')
                } else {
                  sortDirectionSpec === 'ascending' ? setSortDirectionSpec('descending') : setSortDirectionSpec('ascending')
                }
              }}
            >1:1 Cmplt</Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumnSpec !== timeframe + '_SPOT_CMPLT_PCT') {
                  setSortColumnSpec(timeframe + '_SPOT_CMPLT_PCT')
                  setSortDirectionSpec('ascending')
                } else {
                  sortDirectionSpec === 'ascending' ? setSortDirectionSpec('descending') : setSortDirectionSpec('ascending')
                }
              }}
            >Spotlight Cmplt</Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumnSpec !== timeframe + '_CSU_CMPLT_PCT') {
                  setSortColumnSpec(timeframe + '_CSU_CMPLT_PCT')
                  setSortDirectionSpec('ascending')
                } else {
                  sortDirectionSpec === 'ascending' ? setSortDirectionSpec('descending') : setSortDirectionSpec('ascending')
                }
              }}
            >CSU Cmplt</Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumnSpec !== timeframe + '_SPEC_OVR_GOAL') {
                  setSortColumnSpec(timeframe + '_SPEC_OVR_GOAL')
                  setSortDirectionSpec('ascending')
                } else {
                  sortDirectionSpec === 'ascending' ? setSortDirectionSpec('descending') : setSortDirectionSpec('ascending')
                }
              }}
            >% of Spec. Meeting Sls Goal</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {specOpsStrSorted.map((data) => {
            return (
              <Table.Row>
                <Table.Cell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>
                  <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/specialtyprorental?strNbr=${data['STR_NBR']}`} >
                    {data['STR_NBR']}
                  </Link>
                </Table.Cell>
                <Table.Cell>{getPercentString(data[timeframe + '_YES_RESP'] / data[timeframe + '_TOT_RESP'])}</Table.Cell>
                <Table.Cell>{getPercentString(data[timeframe + '_CNT_CMPLT'] / data[timeframe + '_CNT_ASSOCS'])}</Table.Cell>
                <Table.Cell>{getPercentString(data[timeframe + '_CSU_CNT_CMPLT'] / data[timeframe + '_CSU_CNT_ASSOCS'])}</Table.Cell>
                <Table.Cell>{getPercentString(data[timeframe + '_OVR_GOAL'] / data[timeframe + '_TOT_ASSOC'])}</Table.Cell>
              </Table.Row>
            )
          })}
          <Table.Row style={{ fontWeight: "bold" }}>
            <Table.Cell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Total</Table.Cell>
            <Table.Cell>{getPercentString(specOpsDst[timeframe + '_YES_RESP_PCT'])}</Table.Cell>
            <Table.Cell>{getPercentString(specOpsDst[timeframe + '_SPOT_CMPLT_PCT'])}</Table.Cell>
            <Table.Cell>{getPercentString(specOpsDst[timeframe + '_CSU_CMPLT_PCT'])}</Table.Cell>
            <Table.Cell>{getPercentString(specOpsDst[timeframe + '_SPEC_OVR_GOAL'])}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Header textAlign='center'>Pro Key Metrics</Header>
      {proOps.length > 0 ? <Table textAlign='center' celled unstackable size='small' compact style={{ fontSize: 11 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumnPro !== 'STR_NBR') {
                  setSortColumnPro('STR_NBR')
                  setSortDirectionPro('ascending')
                } else {
                  sortDirectionPro === 'ascending' ? setSortDirectionPro('descending') : setSortDirectionPro('ascending')
                }
              }}
            >Store #</Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumnPro !== timeframe + '_PXTRA_SIGNUP') {
                  setSortColumnPro(timeframe + '_PXTRA_SIGNUP')
                  setSortDirectionPro('ascending')
                } else {
                  sortDirectionPro === 'ascending' ? setSortDirectionPro('descending') : setSortDirectionPro('ascending')
                }
              }}
            >Pro Xtra Signups</Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumnPro !== timeframe + '_PXTRA_SIGNUP_AVG') {
                  setSortColumnPro(timeframe + '_PXTRA_SIGNUP_AVG')
                  setSortDirectionPro('ascending')
                } else {
                  sortDirectionPro === 'ascending' ? setSortDirectionPro('descending') : setSortDirectionPro('ascending')
                }
              }}
            >Pro Xtra Signups Avg</Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumnPro !== timeframe + '_PRO_MRO_SLS') {
                  setSortColumnPro(timeframe + '_PRO_MRO_SLS')
                  setSortDirectionPro('ascending')
                } else {
                  sortDirectionPro === 'ascending' ? setSortDirectionPro('descending') : setSortDirectionPro('ascending')
                }
              }}
            >Pro MRO Act</Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumnPro !== timeframe + '_PRO_MRO_AVG') {
                  setSortColumnPro(timeframe + '_PRO_MRO_AVG')
                  setSortDirectionPro('ascending')
                } else {
                  sortDirectionPro === 'ascending' ? setSortDirectionPro('descending') : setSortDirectionPro('ascending')
                }
              }}
            >Pro MRO Avg</Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumnPro !== timeframe + '_PRO_LTSA_PCT') {
                  setSortColumnPro(timeframe + '_PRO_LTSA_PCT')
                  setSortDirectionPro('ascending')
                } else {
                  sortDirectionPro === 'ascending' ? setSortDirectionPro('descending') : setSortDirectionPro('ascending')
                }
              }}
            >Pro LTSA</Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumnPro !== timeframe + '_PRO_GET_PCT') {
                  setSortColumnPro(timeframe + '_PRO_GET_PCT')
                  setSortDirectionPro('ascending')
                } else {
                  sortDirectionPro === 'ascending' ? setSortDirectionPro('descending') : setSortDirectionPro('ascending')
                }
              }}
            >Pro GET</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {proOpsStrSorted.map((data) => {
            return (
              <Table.Row>
                <Table.Cell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>
                  <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/specialtyprorental?strNbr=${data['STR_NBR']}`} >
                    {data['STR_NBR']}
                  </Link>
                </Table.Cell>
                <Table.Cell style={{ color: data[timeframe + '_PXTRA_SIGNUP'] >= 8 ? 'green' : 'red' }}>{data[timeframe + '_PXTRA_SIGNUP']}</Table.Cell>
                <Table.Cell style={{ color: data[timeframe + '_PXTRA_SIGNUP_AVG'] >= 8 ? 'green' : 'red' }}>
                  {data[timeframe + '_PXTRA_SIGNUP_AVG'].toLocaleString(undefined, { maximumFractionDigits: 1 })}</Table.Cell>
                <Table.Cell style={getCalloutStyle(data[timeframe + '_PRO_MRO_SLS'])}>
                  {getThousandDollarString(data[timeframe + '_PRO_MRO_SLS'])}</Table.Cell>
                <Table.Cell style={{ color: data[timeframe + '_PRO_MRO_AVG'] >= 1200 ? 'green' : 'red' }}>
                  {getThousandDollarString(data[timeframe + '_PRO_MRO_AVG'])}</Table.Cell>
                <Table.Cell style={{ color: data[timeframe + '_PRO_LTSA_PCT'] >= 0.85 ? 'green' : 'red' }}>
                  {getPercentString(data[timeframe + '_PRO_LTSA_PCT'])}</Table.Cell>
                <Table.Cell style={{ color: data[timeframe + '_PRO_GET_PCT'] >= 0.85 ? 'green' : 'red' }}>
                  {getPercentString(data[timeframe + '_PRO_GET_PCT'])}</Table.Cell>
              </Table.Row>
            )
          })}
          <Table.Row style={{ fontWeight: "bold" }}>
            <Table.Cell>Total</Table.Cell>
            <Table.Cell style={{ color: proOpsDstFilter[timeframe + '_PXTRA_SIGNUP'] >= 8 ? 'green' : 'red' }}>{proOpsDstFilter[timeframe + '_PXTRA_SIGNUP']}</Table.Cell>
            <Table.Cell style={{ color: proOpsDstFilter[timeframe + '_PXTRA_SIGNUP_AVG'] >= 8 ? 'green' : 'red' }}>
              {proOpsDstFilter[timeframe + '_PXTRA_SIGNUP_AVG'].toLocaleString(undefined, { maximumFractionDigits: 1 })}</Table.Cell>
            <Table.Cell style={getCalloutStyle(proOpsDstFilter[timeframe + '_PRO_MRO_SLS'])}>
              {getThousandDollarString(proOpsDstFilter[timeframe + '_PRO_MRO_SLS'])}</Table.Cell>
            <Table.Cell style={{ color: proOpsDstFilter[timeframe + '_PRO_MRO_AVG'] >= 1200 ? 'green' : 'red' }}>
              {getThousandDollarString(proOpsDstFilter[timeframe + '_PRO_MRO_AVG'])}</Table.Cell>
            <Table.Cell style={{ color: proOpsDstFilter[timeframe + '_PRO_LTSA_PCT'] >= 0.85 ? 'green' : 'red' }}>
              {getPercentString(proOpsDstFilter[timeframe + '_PRO_LTSA_PCT'])}</Table.Cell>
            <Table.Cell style={{ color: proOpsDstFilter[timeframe + '_PRO_GET_PCT'] >= 0.85 ? 'green' : 'red' }}>
              {getPercentString(proOpsDstFilter[timeframe + '_PRO_GET_PCT'])}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table> : <Grid><Grid.Column textAlign='center'>No Pro data for this store.</Grid.Column></Grid>}

      <Header textAlign='center'>Rental Key Metrics</Header>
      {trOps.length > 0 ? <Table textAlign='center' celled unstackable size='small' compact style={{ fontSize: 11 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumnTr !== 'STR_NBR') {
                  setSortColumnTr('STR_NBR')
                  setSortDirectionTr('ascending')
                } else {
                  sortDirectionTr === 'ascending' ? setSortDirectionTr('descending') : setSortDirectionTr('ascending')
                }
              }}
            >Store #</Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumnTr !== timeframe + '_DMG_WVR_PCT') {
                  setSortColumnTr(timeframe + '_DMG_WVR_PCT')
                  setSortDirectionTr('ascending')
                } else {
                  sortDirectionTr === 'ascending' ? setSortDirectionTr('descending') : setSortDirectionTr('ascending')
                }
              }}
            >Damage Waiver %</Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumnTr !== 'TTL_CAD_WO') {
                  setSortColumnTr('TTL_CAD_WO')
                  setSortDirectionTr('ascending')
                } else {
                  sortDirectionTr === 'ascending' ? setSortDirectionTr('descending') : setSortDirectionTr('ascending')
                }
              }}
            >Critical Down Tools</Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumnTr !== 'CAD_NO_PARTS') {
                  setSortColumnTr('CAD_NO_PARTS')
                  setSortDirectionTr('ascending')
                } else {
                  sortDirectionTr === 'ascending' ? setSortDirectionTr('descending') : setSortDirectionTr('ascending')
                }
              }}
            >Critical Down Tools - No Parts</Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumnTr !== timeframe + '_LTRA_PCT') {
                  setSortColumnTr(timeframe + '_LTRA_PCT')
                  setSortDirectionTr('ascending')
                } else {
                  sortDirectionTr === 'ascending' ? setSortDirectionTr('descending') : setSortDirectionTr('ascending')
                }
              }}
            >LTRA</Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumnTr !== timeframe + '_GET_PCT') {
                  setSortColumnTr(timeframe + '_GET_PCT')
                  setSortDirectionTr('ascending')
                } else {
                  sortDirectionTr === 'ascending' ? setSortDirectionTr('descending') : setSortDirectionTr('ascending')
                }
              }}
            >Rental GET</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {trOpsStrSorted.map((data) => {
            return (
              <Table.Row>
                <Table.Cell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>
                  <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/specialtyprorental?strNbr=${data['STR_NBR']}`} >
                    {data['STR_NBR']}
                  </Link>
                </Table.Cell>
                <Table.Cell>{getPercentString(data[timeframe + '_DMG_WVR_PCT'])}</Table.Cell>
                <Table.Cell>{data['TTL_CAD_WO']}</Table.Cell>
                <Table.Cell>{data['CAD_NO_PARTS']}</Table.Cell>
                <Table.Cell style={{ color: data[timeframe + '_LTRA_PCT'] > 0.85 ? 'green' : 'red' }}>{getPercentString(data[timeframe + '_LTRA_PCT'])}</Table.Cell>
                <Table.Cell style={{ color: data[timeframe + '_GET_PCT'] > 0.85 ? 'green' : 'red' }}>{getPercentString(data[timeframe + '_GET_PCT'])}</Table.Cell>
              </Table.Row>
            )
          })}
          <Table.Row style={{ fontWeight: "bold" }}>
            <Table.Cell>Total</Table.Cell>
            <Table.Cell>{getPercentString(trOpsDstFilter[timeframe + '_DMG_WVR_PCT'])}</Table.Cell>
            <Table.Cell>{trOpsDstFilter['TTL_CAD_WO']}</Table.Cell>
            <Table.Cell>{trOpsDstFilter['CAD_NO_PARTS']}</Table.Cell>
            <Table.Cell style={{ color: trOpsDstFilter[timeframe + '_LTRA_PCT'] > 0.85 ? 'green' : 'red' }}>{getPercentString(trOpsDstFilter[timeframe + '_LTRA_PCT'])}</Table.Cell>
            <Table.Cell style={{ color: trOpsDstFilter[timeframe + '_GET_PCT'] > 0.85 ? 'green' : 'red' }}>{getPercentString(trOpsDstFilter[timeframe + '_GET_PCT'])}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table> : <Grid><Grid.Column textAlign='center'>No Rental data for this store.</Grid.Column></Grid>}
    </>
  )

}
)