import { Route } from 'react-router-dom';

export const ProtectedRoute = ({ children, validParams, ...props }) => {
  return (
    <Route
      {...props}
      render={({ match: { params }, history }) => {
        const invalidParams = Object.keys(params).some(key => !validParams[key]?.includes(params[key]));
        if (invalidParams) return history.goBack();

        return children;
      }}
    />
  );
};
