import { Header } from "semantic-ui-react"
import { DataTable } from "../Common/DataTable"
import { getCalloutStyle, getThousandDollarString } from "../Common/TableCellUtils"
import { INTRADAY_CONSTANTS } from "../../constants/intradayConstant"

export const IntradayDepartmentViewComponent = ({ queryData, level, levelNumber }) => {
    const dataObjIntradayView = {
        sortable: true,
        sortableDefault: 0,
        sortableColumns: [0],
        headers: [
            {
                name: 'Dept # ',
            },
            {
                name: 'Cumulative Sales $ (K)',
                element: <><label>Cumulative</label> <br />Sales $ (K) </>
            },
            { name: '$vPlan ' },
            {
                name: '% to Daily Plan ',
                element: <><label>% to</label> <br />Daily Plan </>
            },
            { name: 'Comp % ' },
        ],
        data: queryData.map(row => [
            {
                stringValue: row['DEPT_NM'],
                link: {
                    to: `/${level}/intraday/dept`,
                    params: {
                        [INTRADAY_CONSTANTS[level]['urlParam']]: levelNumber,
                        'deptNbr': row['DEPT_NM']
                    }
                }
            },
            {
                dollarValue: getThousandDollarString(row['CUMMU_SLS'], 1),
                customStyle: getCalloutStyle(row['CUMMU_SLS'])
            },
            {
                dollarValue: getThousandDollarString(row['DOL_TO_DAILY_PLN'], 1).replace('-', ''),
                prefix: '(',
                postfix: ')',
                customStyle: getCalloutStyle(row['DOL_TO_DAILY_PLN'])
            },
            {
                pctValue: String(row['PCT_TO_DAILY_PLN']).replace('-', ''),
                prefix: row['PCT_TO_DAILY_PLN'] < 0 ? '(' : undefined,
                postfix: row['PCT_TO_DAILY_PLN'] < 0 ? ')' : undefined,
                decimals: 1,
                customStyle: getCalloutStyle(row['PCT_TO_DAILY_PLN'])
            },
            {
                pctValue: String(row['COMP_PCT']).replace('-', ''),
                decimals: 1,
                customStyle: getCalloutStyle(row['COMP_PCT']),
                prefix: row['COMP_PCT'] < 0 ? '(' : undefined,
                postfix: row['COMP_PCT'] < 0 ? ')' : undefined,
            },
        ])
    }
    return (
        <>
            <Header textAlign='center' data-testid={'intraDayHeader' + level}>Department View</Header>
            <DataTable data-testid={'intraDayHeader' + level} data={dataObjIntradayView} />
        </>
    )
}