import useQueryApi from '../../../../../hooks/useQueryApi';

export const useSidekickskubayQueries = params => {
  const { fpToggle } = params;
  const queryParams = { fpToggle, isCore: true };

  const {
    data: osaSidekickSkuBayByCoreData,
    isError: isErrorOsaSidekickSkuBayByCore,
    isLoading: isLoadingOsaSidekickSkuBayByCore
  } = useQueryApi('queryOSASidekickSkuBayByCore', queryParams);

  const {
    data: sidekickExeScoresPlusOneByCoreData,
    isError: isErrorSidekickExeScoresPlusOneByCore,
    isLoading: isLoadingSidekickExeScoresPlusOneByCore
  } = useQueryApi('querySidekickExeScoresPlusOneByCore', queryParams);

  const isError = isErrorOsaSidekickSkuBayByCore || isErrorSidekickExeScoresPlusOneByCore;
  const isLoading = isLoadingOsaSidekickSkuBayByCore || isLoadingSidekickExeScoresPlusOneByCore;

  return {
    isError,
    isLoading,
    osaSidekickSkuBayByCoreData,
    sidekickExeScoresPlusOneByCoreData
  };
};
