import React from 'react'
import {
  Loader,
  Header,
  Grid,
} from 'semantic-ui-react'
import { DataTable } from '../Common/DataTable'
import { withRouter, Link } from 'react-router-dom'
import { TimeframeDropdown } from '../Common/TimeframeDropdown'
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon'

import { useQuery } from 'react-query'
import {
  getData
} from '../../service/DataService'
import { getThousandDollarString } from '../Common/TableCellUtils'

const calculateSmdVpBpsValue = (row, timeframe) => {
  return 10000 * (row[timeframe + '_SMD'] / row['TY_' + timeframe + '_SLS_AMT'] - row[timeframe + '_MKDN_PLN_AMT'] / row[timeframe + '_SLS_PLN_AMT']);
};

const calculateSmdVpValue = (row, timeframe) => {
  return row[timeframe + '_SMD'] - row[timeframe + '_MKDN_PLN_AMT'] * row['TY_' + timeframe + '_SLS_AMT'] / row[timeframe + '_SLS_PLN_AMT'];
};

export const SmdDstDept = withRouter(({ timeframe, settimeframe, fpToggle, location }) => {

  const search = new URLSearchParams(location.search)
  const dstNbr = search.has('dstNbr') ? search.get('dstNbr') : 0
  const deptNbrInt = search.has('deptNbr') ? search.get('deptNbr') : 0

  const smdDeptQuery = useQuery(['querySmdDeptByDst', { dstNbr, fpToggle }], getData)

  if (smdDeptQuery.isLoading) {
    return <Loader active>Loading...</Loader>
  }

  let dataFiltered = smdDeptQuery.data.filter(row => {
    return row && row['DEPT_NBR'].toString() === deptNbrInt && row['METRIC_LEVEL'] === 'STR'
  })

  const dataObj = {
    sortable: true,
    headers: [
      { name: 'Store # ' },
      { name: 'SMD $ ' },
      { name: 'SMD % ' },
      { name: 'SMD bps vP ' },
      { name: 'SMD $ Over Under ' },
    ],
    data: dataFiltered.map(row => {
      const smdVpBpsValue = calculateSmdVpBpsValue(row, timeframe);
      const smdVpValue = calculateSmdVpValue(row, timeframe);

      return [
        {
          stringValue: 'STR ' + row['STR_NBR'],
          link: {
            to: `/store/smd/dept`,
            params: {
              strNbr: row['STR_NBR'],
              deptNbr: row['DEPT_NBR']
            },
          }
        },
        {
          stringValue: getThousandDollarString(row[timeframe + '_SMD'], 1)
        },
        {
          decimals: 2,
          pctValue: row[timeframe + '_SMD'] / row['TY_' + timeframe + '_SLS_AMT'],
        },        
        {
          decimals: 0,
          postfix: ' bps',
          compareStyle: true,
          numValue: smdVpBpsValue,
        },
        {
          decimals: 1,
          compareStyle: true,
          numValue: smdVpValue,
        }
      ];
    })
  };

  return (
    <>
      <Grid>
        <Grid.Column width={8}>
          <Grid.Row>&nbsp;</Grid.Row>
          <Grid.Row>
            <Link style={{ color: '#EE7125' }} to={`/district/smd?dstNbr=${dstNbr}`} data-test='b2o-link'>Back to SMD Overview</Link>
          </Grid.Row>

        </Grid.Column>
        <Grid.Column width={8}>
          <Grid.Row>Timeframe:</Grid.Row>
          <Grid.Row>
            {new Date().getDay() === 1 ?
              <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} /> :
              <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />}
          </Grid.Row>
        </Grid.Column>
      </Grid>

      <Header textAlign='center'>{dataFiltered[0]['DEPT_NBR'] + '-' + dataFiltered[0]['DEPT_NM']}</Header>
      <DataTable data={dataObj} />
    </>
  )
})