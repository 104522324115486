import { useState } from "react";
import { Dropdown, Grid, Tab } from "semantic-ui-react";
import { DataView } from "../../../../../../components/Common/DataView";
import { UnitMenu } from "../../../../../../components/Common/UnitMenu";
import { allClassesTabBuilder, CLASSES_METRIC_OPTIONS } from "../../../../utils/sales";

export const AllClassesCoreTab = ({ coreClasses }) => {
  const [metric, setMetric] = useState('SLS');
  const [unit, setUnit] = useState('NOM');

  const dataObj = allClassesTabBuilder({ coreClasses, metric, unit })

  return (
    <Tab.Pane attached={false}>
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign='justified' width={8}>
            <Grid.Row>Metric:</Grid.Row>
            <Grid.Row>
              <Dropdown
                compact
                defaultValue={metric}
                selection
                scrolling
                size='tiny'
                options={CLASSES_METRIC_OPTIONS}
                onChange={(_, { value }) => {
                  setMetric(value);
                }}
              />
            </Grid.Row>
          </Grid.Column>
          <Grid.Column textAlign='justified' width={8}>
            <Grid.Row>&nbsp;</Grid.Row>
            <UnitMenu unit={unit} setUnit={setUnit} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <DataView className='mt-4'>
        <DataView.Title position="center">
          All Classes
        </DataView.Title>
        <DataView.DataTable data={dataObj} />
      </DataView>
    </Tab.Pane>
  )
}
