import { useMemo, useState } from 'react';
import { useQueryParams } from '../../../hooks/useQueryParams';

import { DataView } from '../../Common/DataView';
import { getSmdCompareStyle } from '../../Common/TableCellUtils';
import { UnitMenu } from '../../Common/UnitMenu';

export const SmdDivisionReasonCodesTab = ({ data, timeframe }) => {
  const [unit, setUnit] = useState('NOM');
  const { divNbr } = useQueryParams();
  const reasonCodesFiltered = useMemo(() => data.filter(row => row && row['MKDN_RSN_CD'] !== 7), [data]);
  const reasonCodeSeven = useMemo(() => data.filter(row => row && row['MKDN_RSN_CD'] === 7), [data]);

  const isUnitNom = unit === 'NOM';
  const HEADERS = [{ name: 'Reason Code' }, { name: isUnitNom ? 'SMD $ ' : 'SMD % ' }, { name: 'vLY' }];

  const dataObjReasonCodes = {
    data: reasonCodesFiltered.map(row => [
      {
        link: {
          params: {
            divNbr: divNbr,
            mkdnRsnCd: row.MKDN_RSN_CD
          },
          to: '/division/smd/reason'
        },
        orderBy: row.MKDN_RSN_CD,
        stringValue: `${row.MKDN_RSN_CD}-${row.MKDN_RSN_DESC?.toUpperCase()?.trim()}`
      },
      {
        decimals: isUnitNom ? 2 : 3,
        dollarValue: isUnitNom ? row[timeframe + '_SMD'] : undefined,
        orderBy: isUnitNom ? row[timeframe + '_SMD'] : row[timeframe + '_SMD_PCT'],
        pctValue: isUnitNom ? undefined : row[timeframe + '_SMD_PCT']
      },
      {
        customStyle: getSmdCompareStyle(isUnitNom ? row[timeframe + '_SMD_VLY_DOL'] : row[timeframe + '_SMD_VLY_BPS']),
        decimals: isUnitNom ? 2 : 3,
        dollarValue: isUnitNom ? row[timeframe + '_SMD_VLY_DOL'] : undefined,
        orderBy: isUnitNom ? row[timeframe + '_SMD_VLY_DOL'] : row[timeframe + '_SMD_VLY_BPS'],
        stringValue: !isUnitNom
          ? row[timeframe + '_SMD_VLY_BPS'].toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' bps'
          : undefined
      }
    ]),
    headers: HEADERS,
    sortable: true,
    sortableDefault: 0
  };

  const dataObjStoreUse = {
    data: reasonCodeSeven.map(row => [
      {
        link: {
          params: {
            divNbr: divNbr,
            mkdnRsnCd: row.MKDN_RSN_CD
          },
          to: '/division/smd/reason'
        },
        stringValue: `${row.MKDN_RSN_CD}-${row.MKDN_RSN_DESC?.toUpperCase()?.trim()}`
      },
      {
        decimals: isUnitNom ? 2 : 3,
        dollarValue: isUnitNom ? row[timeframe + '_SMD'] : undefined,
        orderBy: isUnitNom ? row[timeframe + '_SMD'] : row[timeframe + '_SMD_PCT'],
        pctValue: isUnitNom ? undefined : row[timeframe + '_SMD_PCT']
      },
      {
        customStyle: getSmdCompareStyle(isUnitNom ? row[timeframe + '_SMD_VLY_DOL'] : row[timeframe + '_SMD_VLY_BPS']),
        decimals: isUnitNom ? 2 : 3,
        dollarValue: isUnitNom ? row[timeframe + '_SMD_VLY_DOL'] : undefined,
        orderBy: isUnitNom ? row[timeframe + '_SMD_VLY_DOL'] : row[timeframe + '_SMD_VLY_BPS'],
        stringValue: !isUnitNom
          ? row[timeframe + '_SMD_VLY_BPS'].toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' bps'
          : undefined
      }
    ]),
    headers: HEADERS
  };

  return (
    <div>
      <DataView className='mt-3' testId='data-view-smd-detail-reason-codes'>
        <DataView.Controls>
          <p className='mb-0'>Units:</p>
          <UnitMenu unit={unit} setUnit={setUnit} />
        </DataView.Controls>

        <DataView.Title>SMD - Reason Codes</DataView.Title>

        <DataView.DataTable data={dataObjReasonCodes} />
      </DataView>

      <DataView className='my-3' testId='data-view-smd-detail-store-use-reason-code'>
        <DataView.Title>Reason Codes - Store Use</DataView.Title>

        <DataView.DataTable data={dataObjStoreUse} />
      </DataView>
    </div>
  );
};
