import React, { useState } from 'react'
import {
  Loader,
  Header,
  Grid,
} from 'semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'
import { UnitMenu } from '../Common/UnitMenu'
import { TimeframeDropdown } from '../Common/TimeframeDropdown'
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon'

import { useQuery } from 'react-query'
import {
  getData
} from '../../service/DataService'
import { DataTable } from '../Common/DataTable'

export const SmdDstRsn = withRouter(({ fpToggle, timeframe, settimeframe, location }) => {
  const search = new URLSearchParams(location.search)
  const dstNbr = search.has('dstNbr') ? search.get('dstNbr') : 0
  const mkdnRsnCd = search.has('mkdnRsnCd') ? search.get('mkdnRsnCd') : 0

  const [unit, setUnit] = useState('NOM')

  const smdRsnQuery = useQuery(['querySmdStrByDstRsnCd', { dstNbr, mkdnRsnCd, fpToggle }], getData)

  if (smdRsnQuery.isLoading) {
    return <Loader active>Loading...</Loader>
  }

  let smdRsnData = smdRsnQuery.data

  let rowOne = smdRsnData[0]


  const dataObj = {
    sortable: true,
    sortableDefault: 0,
    headers: [{ name: 'Store #' }, { name: 'SMD' }, { name: 'vLY' }],
    data: smdRsnData.map(row => [
      {
        stringValue: row['STR_NBR'],
        link: {
          to: '/store/smd/reason',
          params: {
            strNbr: row['STR_NBR'],
            mkdnRsnCd: row['MKDN_RSN_CD']
          }
        }
      },
      unit === 'NOM' ? {
        dollarValue: row[timeframe + '_SMD'],
        decimals: 2
      } : {
        pctValue: row[timeframe + '_SMD_PCT'],
        decimals: 1
      },
      unit === 'NOM' ? {
        dollarValue: row[timeframe + '_SMD_VLY_DOL'],
        compareStyle: true,
        compareStyleInvert: true
      } : {
        numValue: row[timeframe + '_SMD_VLY_BPS'],
        decimals: 0,
        postfix: ' bps',
        compareStyleInvert: true,
        compareStyle: true,
      },
    ]),
  }


  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <Grid.Row>
              <Link style={{ color: '#EE7125' }} to={`/district/smd?dstNbr=${dstNbr}`} data-test='b2o-link'>Back to SMD Detail</Link>
            </Grid.Row>
            <Grid.Row>&nbsp;</Grid.Row>
            <Grid.Row>Units:</Grid.Row>
            <Grid.Row>
              <UnitMenu unit={unit} setUnit={setUnit} />
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={8}>
            <Grid.Row>&nbsp;</Grid.Row>
            <Grid.Row>&nbsp;</Grid.Row>
            <Grid.Row>Timeframe:</Grid.Row>
            <Grid.Row>
              {new Date().getDay() === 1 ?
                <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} /> :
                <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />}
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Header textAlign='center'>
        {rowOne ? (rowOne['MKDN_RSN_CD'] + '-' + rowOne['MKDN_RSN_DESC']) : (`Reason Code ${mkdnRsnCd} - No markdowns found.`)}
      </Header>
      {rowOne && <DataTable data={dataObj} />}
    </>
  )

})